import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { GetTeamInvoiceOutput } from '@/api/team';
import { DefaultApi } from '@/api/team/api';
import { axiosInstance } from '@/utils/axiosInstance';

// 請求先情報取得API
export const fetchBillingInfo = async (): Promise<GetTeamInvoiceOutput> => {
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    const response: AxiosResponse<GetTeamInvoiceOutput> = await api.getTeamInvoice();
    return response.data as GetTeamInvoiceOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
