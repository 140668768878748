import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { MyMember, DefaultApi } from '@/api/member/api';
import { axiosInstance } from '@/utils/axiosInstance';

export const getMemberList = async (): Promise<MyMember[]> => {
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    const response: AxiosResponse<MyMember[]> = await api.getMembers();
    return response.data as MyMember[];
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
