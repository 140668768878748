import RightPic from './assets/bx-chevron-right.svg';
import CommentImage from './assets/bx-comment-detail.svg';
import EditImage from './assets/bx-edit-alt.svg';
import MenuImage from './assets/bx-menu-alt-left.svg';
import SampleImage from './assets/Sample-image.png';
import ImageEdit from './ImageEdit';

export interface ImageApproveProps {
  status: number;
  setStatus: React.Dispatch<React.SetStateAction<number>>;
  isImageEdit: boolean;
  setIsImageEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ImageApproveView(props: ImageApproveProps) {
  const imageEditClick = () => {
    props.setIsImageEdit(true);
  };
  const handleClick = () => {
    props.setStatus(3);
  };

  return (
    <div className="w-[1181px] px-[24px] pt-[24px]">
      {/* メイン↓ */}
      <div className="w-[1133px] rounded-lg border-b border-gray-200 bg-white p-[24px]">
        {/* イメージ案↓ */}
        <div className="flex items-center justify-between">
          <div className="text-base font-semibold leading-7 text-gray-700">イメージ案</div>
          <div className="flex">
            <button
              type="button"
              className="inline-flex h-[38px] w-[1８６px] items-center gap-x-1.5 rounded-md bg-[#007CC2] px-[17px] py-[9px] text-xs font-semibold text-white shadow-sm"
              onClick={() => {
                handleClick();
              }}
            >
              承認する
            </button>
            <button type="button" className="ml-[30px]" onClick={() => imageEditClick()}>
              <img src={EditImage} alt="" />
            </button>
            <div>{ImageEdit(props.isImageEdit, props.setIsImageEdit)}</div>
            <div className="ml-[30px]">
              <img src={CommentImage} alt="" />
            </div>
          </div>
        </div>
        {/* メディア↓ */}
        <div className="my-[24px]">
          <div className="text-sm font-semibold text-gray-700">メディア</div>
          <div className="mt-[8px] flex items-center">
            <div className="text-sm font-semibold text-gray-700">1 / 2枚目</div>
            <div>
              <img src={RightPic} alt="" />
            </div>
          </div>
          <div className="mt-[8px] flex py-[10px]">
            <div>
              <img src={SampleImage} alt="" />
            </div>
            <div className="ml-[80px] py-[10px]">
              <div className="flex">
                <img src={MenuImage} alt="" />
                <div className="ml-[8px] text-xs leading-4">画像 / 動画の説明</div>
              </div>
              <div className="mt-[20px]">
                <p className="text-xs font-normal leading-4 text-gray-700">
                  人物画でカメラを持った状態など、いきいきと撮影している場面が想像できるような写真を用意していただきたいです！
                  <br />
                  <br />
                  以下のことはおやめください。
                  <br />
                  <br />
                  ・危険な場所での撮影
                  <br />
                  ・他社製品と撮影すること
                  <br />
                  ・暗い場所での撮影（明るいイメージを希望しているため）
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* ボーダー↓ */}
        <div className="my-[24px] border-b-[1px] border-gray-200 " />
        <div className="text-sm font-semibold leading-5 text-gray-700">テキスト</div>
        <div className="p-[16px]">
          <p className="text-xs font-normal leading-4 text-gray-700">
            ①「Canon360」という商品名を入れてください。
            <br />
            <br />
            ②イベント情報入れてださい。以下の内容をいれていただければ、自由に文章を作っていただいて大丈夫です！文章のトーンは、絵文字などを使って、読みやすく明るい感じでお願いします。
            <br />
            <br />
            「バレンタインデーフォトイベント」
            <br />
            期間：2021/02/01 - 2/14
            <br />
            あなたの「愛するもの」をCanonのカメラで撮ってください！
            <br />
            愛するものはなんでも構いません。
            <br />
            タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
            <br />
            賞金15万円
          </p>
        </div>
      </div>
    </div>
  );
}
