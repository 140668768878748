import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { Input } from '@/components/Elements/Input';

import { Account } from './Account';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import { ReactComponent as PRTwoColorImage } from './assets/PR_Two Color 1.svg';

export const PRSTProductionManagementAccountView = () => {
  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen1(true);
  };

  const ProjectAddModal = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[228px] w-[512px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[180px] w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[110px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">施策を追加</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen1(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-[152px] w-[312px]">
                        <div className="mb-[24px] h-[66px] w-[312px]">
                          <div className="mb-[4px] h-[20px] w-[42px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">施策名</span>
                          </div>
                          <Input style={{ width: '440px', height: '38px' }} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen1(false)}
                      >
                        キャンセル
                      </Button>
                      <Link to="/PRST_production_management/list">
                        <Button
                          variant="primary"
                          size="md"
                          className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        >
                          登録する
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div>
      <div className="w-[1181px] border-b border-gray-200 p-[16px]">
        <p className="text-base font-semibold leading-7 text-gray-700">PRST(制作案件) 投稿制作管理一覧</p>
      </div>
      <div className="flex">
        <Account />
        <div className="w-[940px] px-[24px] py-[16px]">
          <div>
            <div className="mb-[16px] flex justify-between">
              <p className="text-base font-semibold leading-7 text-gray-700">施策一覧</p>
              <Button
                startIcon={<PlusImage className="h-[20px] w-[20px]" />}
                size="md"
                className="whitespace-nowrap text-sm font-medium leading-5"
                style={{
                  paddingTop: '9px',
                  paddingBottom: '9px',
                  paddingLeft: '10px',
                  paddingRight: '12px',
                }}
                onClick={handleClick1}
              >
                施策を追加
              </Button>
            </div>
            <div className="h-[525px] w-[892px] px-[20px] pb-[38px] pt-[24px]">
              <div className="flex h-[463px] w-[852px] items-center justify-center">
                <div>
                  <PRTwoColorImage />
                  <div className="flex justify-center">
                    <p className="w-[294px] text-center text-[14px] font-normal leading-6 text-[#6B7280]">
                      まだ施策がありません。
                      <br />
                      右上のボタンから施策を作成してみましょう！
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{ProjectAddModal()}</div>
    </div>
  );
};
