import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

export const taskType = {
  1: 'サンプリング',
  2: 'バイイング(店舗)',
  3: 'バイイング(EC)',
  4: 'イベント',
  5: 'WEBサービス・アプリ利用',
  10: 'Instagram(フィート投稿)',
  11: 'Instagram(ストーリーズ投稿)',
  12: 'Instagram(リール投稿)',
  20: 'X(ポスト投稿)',
  21: 'X(引用ポスト投稿)',
  30: 'TikTok',
  40: 'Youtube',
} as const;

export type TaskKeyType = keyof typeof taskType;
export const isValidKey = (key: number): key is TaskKeyType => {
  return key in taskType;
};
export interface HearingContent {
  task: string;
  contentItems: ContentItem[];
}
export interface ContentItem {
  description: string;
  optionItems: string[] | null;
}
export interface HearingModalProps {
  contents: HearingContent[];
  isOpen: boolean;
  setModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function HearingModal(props: HearingModalProps) {
  const closeModal = () => {
    props.setModal(false);
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[768px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">
                      {props.contents.length ? 'ヒアリング内容' : 'ヒアリング内容はありません'}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md  bg-white text-gray-400 hover:text-gray-500"
                    onClick={closeModal}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="mt-3 flex items-start gap-2 p-[1px]">
                  <div className="flex w-[768px] flex-col gap-6 self-stretch">
                    <div>
                      {props.contents.length ? (
                        props.contents.map((content: HearingContent, index: number) => {
                          return (
                            <div className="mb-8" key={index}>
                              <div className="rounded-[10px] border border-gray-200 bg-gray-50 p-3">{content.task}</div>
                              <div>
                                {content.contentItems.map((item: ContentItem, index: number) => {
                                  return (
                                    <div key={index}>
                                      <div className="mt-6 pl-6 font-semibold">選択肢の説明: {item.description}</div>
                                      {item.optionItems ? (
                                        item.optionItems.map((option: string, index: number) => {
                                          return (
                                            <ul className="mt-4 pl-6" key={index}>
                                              <li className="mb-2">
                                                第{index + 1}希望：{option}
                                              </li>
                                            </ul>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
