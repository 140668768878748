import { SnsType } from '@/api/analysisSpirit';
import { ReactComponent as BookmarkIcon } from '@/assets/icons/bx-bookmark.svg';
import { ReactComponent as LeftCircleIcon } from '@/assets/icons/bx-chevron-left-circle.svg';
import { ReactComponent as RightCircleIcon } from '@/assets/icons/bx-chevron-right-circle.svg';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as MalekIcon } from '@/assets/icons/bx-male.svg';
import { ReactComponent as PlayCircleIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as ReopostIcon } from '@/assets/icons/bx-repost.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as ShowIcon } from '@/assets/icons/bx-show.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/bx-user.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x-circle.svg';
import { Stat } from '@/components/Elements/Stat';
import { InstagramPostType } from '@/types';

// 投稿サマリーのアイコン・文字表示部品(いいね数、エンゲージメント率等)
export const SummaryPostCommon = (
  width: string,
  snsType: SnsType,
  stat1: string | number,
  stat2: string | number,
  stat3: string | number,
  stat4: string | number,
  stat5: string | number,
  stat6: string | number
) => {
  // 各SNSタイプごとにラベルを定義する関数
  const getLabelAndValue = (type: SnsType, index: number) => {
    const labels: { [key in SnsType]: string[] } = {
      [SnsType.Instagram]: [],
      [SnsType.X]: ['エンゲージメント率', 'エンゲージメント数', 'いいね数', '返信数', 'リポスト数', '合計視聴回数'],
      [SnsType.TikTok]: ['再生回数', 'エンゲージメント率', 'エンゲージメント数', 'いいね数', 'コメント数', 'シェア数'],
      [SnsType.Youtube]: ['視聴回数', 'エンゲージメント率', 'エンゲージメント数', '高評価数', 'コメント数', ''],
      other: [],
    };

    const values = [stat1, stat2, stat3, stat4, stat5, stat6];
    return { label: labels[type][index], value: values[index] };
  };

  // 各SNSタイプごとにアイコンを定義する関数
  const getIcon = (type: SnsType, index: number) => {
    const icons: { [key in SnsType]: JSX.Element[] } = {
      [SnsType.Instagram]: [],
      [SnsType.X]: [
        <HeartIcon key="x-heart1" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="x-heart2" width={24} height={24} fill="#FFFFFF" />,
        <LikeIcon key="x-like" width={24} height={24} fill="#FFFFFF" />,
        <CommentIcon key="x-comment" width={24} height={24} fill="#FFFFFF" />,
        <ReopostIcon key="x-repost" width={24} height={24} fill="#FFFFFF" />,
        <PlayCircleIcon key="x-play" width={24} height={24} fill="#FFFFFF" />,
      ],
      [SnsType.TikTok]: [
        <PlayCircleIcon key="tt-play" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="tt-heart1" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="tt-heart2" width={24} height={24} fill="#FFFFFF" />,
        <LikeIcon key="tt-like" width={24} height={24} fill="#FFFFFF" />,
        <CommentIcon key="tt-comment" width={24} height={24} fill="#FFFFFF" />,
        <ShareIcon key="tt-share" width={24} height={24} fill="#FFFFFF" />,
      ],
      [SnsType.Youtube]: [
        <PlayCircleIcon key="yt-play" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="yt-heart1" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="yt-heart2" width={24} height={24} fill="#FFFFFF" />,
        <LikeIcon key="yt-like" width={24} height={24} fill="#FFFFFF" />,
        <CommentIcon key="yt-comment" width={24} height={24} fill="#FFFFFF" />,
        <></>,
      ],
      other: [],
    };

    return icons[type][index];
  };

  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      {/* 横2つ、縦3つの形式で Stat コンポーネントを配置するために、縦の行数のループで処理 */}
      {[0, 1, 2].map((i) => (
        <div key={i} className="flex items-center gap-2 self-stretch">
          {snsType === SnsType.X && i === 0 ? (
            <>
              <Stat
                icon={getIcon(snsType, i)}
                lable={getLabelAndValue(snsType, i).label}
                value={getLabelAndValue(snsType, i).value.toString()}
                className={width}
              />
              <Stat
                icon={getIcon(snsType, i + 1)}
                lable={getLabelAndValue(snsType, i + 1).label}
                value={getLabelAndValue(snsType, i + 1).value.toString()}
                className={width}
              />
            </>
          ) : (
            <>
              <Stat
                icon={getIcon(snsType, i * 2)}
                lable={getLabelAndValue(snsType, i * 2).label}
                value={(getLabelAndValue(snsType, i * 2).value as number).toLocaleString()}
                className={width}
              />
              <Stat
                icon={getIcon(snsType, i * 2 + 1)}
                lable={getLabelAndValue(snsType, i * 2 + 1).label}
                value={(getLabelAndValue(snsType, i * 2 + 1).value as number).toLocaleString()}
                className={width}
              />
            </>
          )}
        </div>
      ))}
    </div>
  );
};

// SNSアカウント詳細_投稿インサイト_アイコン・文字表示部品(いいね数、エンゲージメント率等)
export const InsightPostCommon = (
  width: string,
  snsType: SnsType,
  stat1: string | number,
  stat2: string | number,
  stat3: string | number,
  stat4: string | number,
  stat5: string | number,
  stat6: string | number,
  stat7?: string | number,
  stat8?: string | number,
  stat9?: string | number,
  stat10?: string | number,
  postType?: InstagramPostType
) => {
  const getLabelAndValue = (type: SnsType, index: number) => {
    const labels: { [key in SnsType]: string[] } = {
      [SnsType.Instagram]:
        postType === 'Story'
          ? [
              'インプレッション数',
              'ユニークアカウント数',
              '「次へ」のタップ回数',
              '「戻る」のタップ回数',
              'メディアが閉じられた回数',
            ]
          : [
              'インプレッション数',
              'ユニークアカウント数',
              'エンゲージメント数',
              'エンゲージメント率',
              'いいね数',
              'コメント数',
              'シェア数',
              '保存数',
              'フリークエンシー',
            ],
      [SnsType.X]: ['エンゲージメント率', 'エンゲージメント数', 'いいね数', '返信数', 'リポスト数'],
      [SnsType.TikTok]: ['再生回数', 'エンゲージメント率', 'エンゲージメント数', 'いいね数', 'コメント数', 'シェア数'],
      [SnsType.Youtube]: ['視聴回数', 'エンゲージメント率', 'エンゲージメント数', '高評価数', 'コメント数'],
      other: [],
    };

    const values = [stat1, stat2, stat3, stat4, stat5, stat6, stat7, stat8, stat9, stat10];
    return { label: labels[type][index], value: values[index] };
  };

  const getIcon = (type: SnsType, index: number) => {
    const icons: { [key in SnsType]: JSX.Element[] } = {
      [SnsType.Instagram]:
        postType === 'Story'
          ? [
              <ShowIcon key="ig-show" width={24} height={24} fill="#FFFFFF" />,
              <GroupIcon key="ig-user" width={24} height={24} fill="#FFFFFF" />,
              <RightCircleIcon key="ig-right-circle" width={24} height={24} fill="#FFFFFF" />,
              <LeftCircleIcon key="ig-left-circle" width={24} height={24} fill="#FFFFFF" />,
              <CloseIcon key="ig-close" width={24} height={24} fill="#FFFFFF" />,
            ]
          : [
              <ShowIcon key="ig-show" width={24} height={24} fill="#FFFFFF" />,
              <UserIcon key="ig-user" width={24} height={24} fill="#FFFFFF" />,
              <HeartIcon key="ig-heart1" width={24} height={24} fill="#FFFFFF" />,
              <HeartIcon key="ig-heart2" width={24} height={24} fill="#FFFFFF" />,
              <LikeIcon key="ig-like" width={24} height={24} fill="#FFFFFF" />,
              <CommentIcon key="ig-comment" width={24} height={24} fill="#FFFFFF" />,
              <ShareIcon key="ig-share" width={24} height={24} fill="#FFFFFF" />,
              <BookmarkIcon key="ig-bookmark" width={24} height={24} fill="#FFFFFF" />,
              <MalekIcon key="ig-malek" width={24} height={24} fill="#FFFFFF" />,
            ],
      [SnsType.X]: [
        <HeartIcon key="x-heart1" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="x-heart2" width={24} height={24} fill="#FFFFFF" />,
        <LikeIcon key="x-like" width={24} height={24} fill="#FFFFFF" />,
        <CommentIcon key="x-comment" width={24} height={24} fill="#FFFFFF" />,
        <ReopostIcon key="x-repost" width={24} height={24} fill="#FFFFFF" />,
      ],
      [SnsType.TikTok]: [
        <PlayCircleIcon key="tt-play" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="tt-heart1" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="tt-heart2" width={24} height={24} fill="#FFFFFF" />,
        <LikeIcon key="tt-like" width={24} height={24} fill="#FFFFFF" />,
        <CommentIcon key="tt-comment" width={24} height={24} fill="#FFFFFF" />,
        <ShareIcon key="tt-share" width={24} height={24} fill="#FFFFFF" />,
      ],
      [SnsType.Youtube]: [
        <PlayCircleIcon key="yt-play" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="yt-heart1" width={24} height={24} fill="#FFFFFF" />,
        <HeartIcon key="yt-heart2" width={24} height={24} fill="#FFFFFF" />,
        <LikeIcon key="yt-like" width={24} height={24} fill="#FFFFFF" />,
        <CommentIcon key="yt-comment" width={24} height={24} fill="#FFFFFF" />,
        <></>,
      ],
      other: [],
    };

    return icons[type][index];
  };

  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      {/* 横2つ、縦5つの形式で Stat コンポーネントを配置するために、縦の行数のループで処理 */}

      {[0, 1, 2, 3, 4].map((i) => (
        <div key={i} className="flex items-center gap-2 self-stretch">
          {i === 0 ? (
            <>
              {getLabelAndValue(snsType, i).label && getLabelAndValue(snsType, i).value && (
                <Stat
                  icon={getIcon(snsType, i)}
                  lable={getLabelAndValue(snsType, i).label}
                  value={getLabelAndValue(snsType, i).value?.toString() || ''}
                  className={width}
                />
              )}
              {getLabelAndValue(snsType, i + 1).label && getLabelAndValue(snsType, i + 1).value && (
                <Stat
                  icon={getIcon(snsType, i + 1)}
                  lable={getLabelAndValue(snsType, i + 1).label}
                  value={getLabelAndValue(snsType, i + 1).value?.toString() || ''}
                  className={width}
                />
              )}
            </>
          ) : (
            <>
              {getLabelAndValue(snsType, i * 2).label && getLabelAndValue(snsType, i * 2).value && (
                <Stat
                  icon={getIcon(snsType, i * 2)}
                  lable={getLabelAndValue(snsType, i * 2).label}
                  value={(getLabelAndValue(snsType, i * 2).value as number).toLocaleString()}
                  className={width}
                />
              )}
              {getLabelAndValue(snsType, i * 2 + 1).label && getLabelAndValue(snsType, i * 2 + 1).value && (
                <Stat
                  icon={getIcon(snsType, i * 2 + 1)}
                  lable={getLabelAndValue(snsType, i * 2 + 1).label}
                  value={(getLabelAndValue(snsType, i * 2 + 1).value as number).toLocaleString()}
                  className={width}
                />
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};
