import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';

import { Button } from '@/components/Elements/Button/Button';

interface Props {
  open: boolean;
  onClose: (isSave: boolean) => void;
  isApplyed: boolean;
  setIsApplyed: React.Dispatch<React.SetStateAction<boolean>>;
}

export const DeliveryAgencyService = (props: Props) => {
  console.log('modal_in_props.isApplyed:' + props.isApplyed);
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.onClose(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative flex h-[622px] w-[768px] flex-col overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      props.onClose(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="pt-6">
                  <div className="h-[104px] w-[656px]">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      配信代行サービスを確認して申請
                    </Dialog.Title>
                    <div className="h-[20px] w-[616px]"></div>

                    <div className="mb-[15px] h-auto w-[810px] pt-[10px]">
                      {/* Instagram用配信テキスト */}
                      <div className="mb-[30px] h-auto w-[770px]">
                        <p className=" mb-[8px] mt-[10px] h-[20px] w-[730px] text-[15px] font-bold leading-5 text-gray-700">
                          貴社でご用意いただくもの
                        </p>
                        <p className="mb-[2px] ml-3 h-auto w-[630px] bg-gray-100 text-[14px] font-normal leading-6 text-gray-900">
                          1.テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                          <br />
                          1.テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                          <br />
                          1.テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                          <br />
                        </p>
                      </div>
                      <div className="mt-[1px] h-auto w-[770px]">
                        <p className="mb-[20px] h-auto w-[630px] text-[12px] font-bold leading-6 text-gray-900">
                          ※テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                          <br />
                          ※テキストテキストテキストテキストテキストテキストテキストテキストテキストテキスト
                          <br />
                        </p>
                      </div>
                      {/* お見積もり */}
                      {/* 固定で値を埋め込んでるので修正必要 */}
                      <div className="mb-[5px] h-[154px] w-[700px] space-y-2">
                        <p className="mb-[8px] mt-[10px] h-[20px] w-[650px] text-[15px] font-bold leading-5 text-gray-700">
                          お見積もり<span className="text-xs font-normal">※全て税別</span>
                        </p>
                        <div className="bg-gray-100">
                          <div className="ml-10 flex w-[630px] justify-between">
                            <span>スタンダードプラン</span>
                            <span>200,000円</span>
                          </div>
                          <div className="ml-10 flex w-[630px] justify-between">
                            <span>配信代行サービス</span>
                            <span>20,000円</span>
                          </div>
                          <div className="ml-10 mt-2 flex w-[630px] justify-between font-bold">
                            <span>合計</span>
                            <span>220,000円</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-2 mt-[350px] text-center">
                  <p>
                    {/* リンクは適当なので修正必要 */}
                    <Link
                      to="https://www.google.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500"
                    >
                      EMERALD POST利用規約
                    </Link>
                  </p>
                </div>
                <div className="mb-4 mt-auto flex justify-center">
                  <Button
                    className=""
                    onClick={() => {
                      props.setIsApplyed(!props.isApplyed);
                      props.onClose(true);
                    }}
                  >
                    {props.isApplyed ? '配信代行サービスを申請しない' : '利用規約に同意して配信代行サービスを申請する'}
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
