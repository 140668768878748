import { PROJECT_TYPES } from '@/config/index';
export const ROUTES = {
  HOME: '/home',
  PROJECT: '/project',
  CALENDAR: '/schedule/calendar',
  GANTT_CHART: '/schedule/gantt-chart',
  NORTIFICATION: '/notification_list',
  TEAM_BASIC_INFO: '/basicinfo',
  PLAN_SELECT: '/plan_select',
  BILLING: '/billing',
  MEMBER_ADMIN: '/member_admin',
  TERMS_OF_SERVICE: 'https://liddell.tokyo/terms/company/',
  PRIVACY_POLICY: 'https://liddell.tokyo/privacy/',
  ACCOUNT_SETTING: '/account_setting',
  AUTH_LOGIN: '/auth?type=login',

  //TODO &rows=50&size=200 API連携時に削除
  PROJECT_ANALYSIS_EMERALD_POST: `/project-analysis/list?type=${PROJECT_TYPES.EMERALD_POST}&rows=50&size=200`,
  PROJECT_ANALYSIS_SPIRIT: `/project-analysis/list?type=${PROJECT_TYPES.SPIRIT}&rows=50&size=200`,
  PROJECT_ANALYSIS_OTHER: `/project-analysis/list?type=${PROJECT_TYPES.OTHER}&rows=50&size=200`,
  PROJECT_ANALYSIS_JANE_JOHN: `/project-analysis/list?type=${PROJECT_TYPES.JANE_JOHN}&rows=50&size=200`,
  PROJECT_LIST_EMERALD_POST: `/project_management/list?type=${PROJECT_TYPES.EMERALD_POST}`,
  PROJECT_LIST_SPIRIT: `/project_management/list?type=${PROJECT_TYPES.SPIRIT}`,
  PROJECT_LIST_OTHER: `/project_management/list?type=${PROJECT_TYPES.OTHER}`,
  PROJECT_LIST_PRST: `/project_management/list?type=${PROJECT_TYPES.PRST}`,
  PROJECT_LIST_JANE_JOHN: `/project_management/list?type=${PROJECT_TYPES.JANE_JOHN}&size=300`,

  PROJECT_LIST_PROST_POST: `/PRST_manage_list`,
};
