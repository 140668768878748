/* tslint:disable */
/* eslint-disable */
/**
 * Influfect クライアント API
 * Influfect クライアント APIドキュメント
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountFollowerAttributes
 */
export interface AccountFollowerAttributes {
    /**
     * 
     * @type {FollowerAttrDistributions}
     * @memberof AccountFollowerAttributes
     */
    'ageDistribution': FollowerAttrDistributions;
    /**
     * 
     * @type {FollowerAttrDistributions}
     * @memberof AccountFollowerAttributes
     */
    'genreDistribution': FollowerAttrDistributions;
    /**
     * 
     * @type {FollowerAttrDistributions}
     * @memberof AccountFollowerAttributes
     */
    'valuesDistribution': FollowerAttrDistributions;
}
/**
 * 
 * @export
 * @interface AffectOutput
 */
export interface AffectOutput {
    /**
     * 
     * @type {string}
     * @memberof AffectOutput
     */
    'affected': string;
}
/**
 * 
 * @export
 * @interface AgeDistribution
 */
export interface AgeDistribution {
    /**
     * 
     * @type {AgeDistributionType}
     * @memberof AgeDistribution
     */
    'distribution': AgeDistributionType;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'mans': number;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'womans': number;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'others': number;
}


/**
 * 年齢層割合種別
 * @export
 * @enum {string}
 */

export const AgeDistributionType = {
    _1317: '13-17',
    _1824: '18-24',
    _2534: '25-34',
    _3544: '35-44',
    _4554: '45-54',
    _5564: '55-64',
    _65: '65+'
} as const;

export type AgeDistributionType = typeof AgeDistributionType[keyof typeof AgeDistributionType];


/**
 * 
 * @export
 * @interface AnalysisFollowerAttributes
 */
export interface AnalysisFollowerAttributes {
    /**
     * 
     * @type {InstagramFollowerAttributes}
     * @memberof AnalysisFollowerAttributes
     */
    'instagram'?: InstagramFollowerAttributes;
    /**
     * 
     * @type {AccountFollowerAttributes}
     * @memberof AnalysisFollowerAttributes
     */
    'account': AccountFollowerAttributes;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentInstagram
 */
export interface AnalysisSpiritPostContentInstagram {
    /**
     * 画像の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postImage'?: string | null;
    /**
     * 動画の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postVideo'?: string | null;
    /**
     * カルーセルの場合のみ。複数の場合はカンマ区切り
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postCarousel'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'carouselImages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'thumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postedAt': string | null;
    /**
     * メディアプロダクト種別
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'mediaProductType': AnalysisSpiritPostContentInstagramMediaProductTypeEnum | null;
    /**
     * メディア種別
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'mediaType': AnalysisSpiritPostContentInstagramMediaTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'followerOnPost': number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'url': string | null;
}

export const AnalysisSpiritPostContentInstagramMediaProductTypeEnum = {
    Ad: 'AD',
    Feed: 'FEED',
    Story: 'STORY',
    Reels: 'REELS'
} as const;

export type AnalysisSpiritPostContentInstagramMediaProductTypeEnum = typeof AnalysisSpiritPostContentInstagramMediaProductTypeEnum[keyof typeof AnalysisSpiritPostContentInstagramMediaProductTypeEnum];
export const AnalysisSpiritPostContentInstagramMediaTypeEnum = {
    CarouselAlbum: 'CAROUSEL_ALBUM',
    Image: 'IMAGE',
    Video: 'VIDEO'
} as const;

export type AnalysisSpiritPostContentInstagramMediaTypeEnum = typeof AnalysisSpiritPostContentInstagramMediaTypeEnum[keyof typeof AnalysisSpiritPostContentInstagramMediaTypeEnum];

/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentTikTok
 */
export interface AnalysisSpiritPostContentTikTok {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'postImage': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'postedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'followerOnPost': number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'url': string | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentX
 */
export interface AnalysisSpiritPostContentX {
    /**
     * 画像の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postImage'?: string | null;
    /**
     * 動画の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postVideo'?: string | null;
    /**
     * 複数メディアの場合のみ。複数の場合はカンマ区切り
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postMedias'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisSpiritPostContentX
     */
    'multiMediaImages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postedAt': string | null;
    /**
     * メディア種別
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'mediaType': AnalysisSpiritPostContentXMediaTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentX
     */
    'followerOnPost': number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'url': string | null;
}

export const AnalysisSpiritPostContentXMediaTypeEnum = {
    MultipleMedia: 'multipleMedia',
    Photo: 'photo',
    Video: 'video'
} as const;

export type AnalysisSpiritPostContentXMediaTypeEnum = typeof AnalysisSpiritPostContentXMediaTypeEnum[keyof typeof AnalysisSpiritPostContentXMediaTypeEnum];

/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentYoutube
 */
export interface AnalysisSpiritPostContentYoutube {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'postImage': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'postedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'followerOnPost': number | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'url': string | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightInstagram
 */
export interface AnalysisSpiritPostInsightInstagram {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'impressionCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'uniqueAccountCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'commentCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'shareCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'saveCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'frequency': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'tapsForwardCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'tapsBackCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'exitsCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightTikTok
 */
export interface AnalysisSpiritPostInsightTikTok {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'commentCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'shareCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightX
 */
export interface AnalysisSpiritPostInsightX {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'repostCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'replyCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightYoutube
 */
export interface AnalysisSpiritPostInsightYoutube {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'commentCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface ApiResponseError
 */
export interface ApiResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseError
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResponseError
     */
    'messages'?: Array<string>;
}
/**
 * 承認ステータス
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    Review: 'review',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface CalendarItem
 */
export interface CalendarItem {
    /**
     * 
     * @type {string}
     * @memberof CalendarItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarItem
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarItem
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {ProjectType}
     * @memberof CalendarItem
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {string}
     * @memberof CalendarItem
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof CalendarItem
     */
    'projectEndDate': string;
    /**
     * true:非表示（その他n件） false:表示（現在固定値を返却します）
     * @type {boolean}
     * @memberof CalendarItem
     */
    'isHidden': boolean;
}


/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {Array<EvaluationRate>}
     * @memberof Evaluation
     */
    'evaluationRates': Array<EvaluationRate>;
}
/**
 * 
 * @export
 * @interface EvaluationRate
 */
export interface EvaluationRate {
    /**
     * 
     * @type {number}
     * @memberof EvaluationRate
     */
    'rate': number;
    /**
     * 1〜5の整数値
     * @type {number}
     * @memberof EvaluationRate
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface FeedbackAdopterCommon
 */
export interface FeedbackAdopterCommon {
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'feedbackId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'adoptedInfluencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'applyingInfluencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountThumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'influencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'evaluationId': string;
    /**
     * 評価値
     * @type {number}
     * @memberof FeedbackAdopterCommon
     */
    'evaluation': FeedbackAdopterCommonEvaluationEnum;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'feedbackComment': string;
}

export const FeedbackAdopterCommonEvaluationEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FeedbackAdopterCommonEvaluationEnum = typeof FeedbackAdopterCommonEvaluationEnum[keyof typeof FeedbackAdopterCommonEvaluationEnum];

/**
 * フィードバックステータス
 * @export
 * @enum {string}
 */

export const FeedbackStatus = {
    Ready: 'ready',
    Client: 'client',
    Admin: 'admin'
} as const;

export type FeedbackStatus = typeof FeedbackStatus[keyof typeof FeedbackStatus];


/**
 * 
 * @export
 * @interface FollowerAttrDistribution
 */
export interface FollowerAttrDistribution {
    /**
     * 
     * @type {string}
     * @memberof FollowerAttrDistribution
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistribution
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistribution
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface FollowerAttrDistributions
 */
export interface FollowerAttrDistributions {
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistributions
     */
    'total': number;
    /**
     * 
     * @type {Array<FollowerAttrDistribution>}
     * @memberof FollowerAttrDistributions
     */
    'items': Array<FollowerAttrDistribution>;
}
/**
 * 端数処理種別
 * @export
 * @enum {string}
 */

export const FractionType = {
    Floor: 'floor',
    Ceiling: 'ceiling',
    Round: 'round'
} as const;

export type FractionType = typeof FractionType[keyof typeof FractionType];


/**
 * 
 * @export
 * @interface GanttItem
 */
export interface GanttItem {
    /**
     * 
     * @type {string}
     * @memberof GanttItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof GanttItem
     */
    'projectName': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof GanttItem
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {string}
     * @memberof GanttItem
     */
    'projectStartDate': string;
    /**
     * 
     * @type {string}
     * @memberof GanttItem
     */
    'projectEndDate': string;
    /**
     * 
     * @type {Array<ProjectSchedule>}
     * @memberof GanttItem
     */
    'schedules': Array<ProjectSchedule>;
}


/**
 * 
 * @export
 * @interface GenderDistribution
 */
export interface GenderDistribution {
    /**
     * 1:男性、2:女性、3:その他
     * @type {number}
     * @memberof GenderDistribution
     */
    'gender': number;
    /**
     * 
     * @type {number}
     * @memberof GenderDistribution
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof GenderDistribution
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface GenderDistributionWithTotal
 */
export interface GenderDistributionWithTotal {
    /**
     * 
     * @type {number}
     * @memberof GenderDistributionWithTotal
     */
    'total': number;
    /**
     * 
     * @type {Array<GenderDistribution>}
     * @memberof GenderDistributionWithTotal
     */
    'items': Array<GenderDistribution>;
}
/**
 * 
 * @export
 * @interface GetProjectGroups400Response
 */
export interface GetProjectGroups400Response {
    /**
     * 
     * @type {string}
     * @memberof GetProjectGroups400Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProjectGroups400Response
     */
    'messages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetProjectGroupsOutput
 */
export interface GetProjectGroupsOutput {
    /**
     * 
     * @type {number}
     * @memberof GetProjectGroupsOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectGroupsOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectGroupsOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectGroupsOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof GetProjectGroupsOutput
     */
    'totalPages': number;
    /**
     * 
     * @type {Array<ProjectGroup>}
     * @memberof GetProjectGroupsOutput
     */
    'rows': Array<ProjectGroup>;
}
/**
 * 案件概要
 * @export
 * @interface GetProjectOverviewOutput
 */
export interface GetProjectOverviewOutput {
    /**
     * 
     * @type {string}
     * @memberof GetProjectOverviewOutput
     */
    'marketBackground': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectOverviewOutput
     */
    'problem': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectOverviewOutput
     */
    'purpose': string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectOverviewOutput
     */
    'measure': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetProjectOverviewOutput
     */
    'kpi': Array<string>;
    /**
     * 
     * @type {number}
     * @memberof GetProjectOverviewOutput
     */
    'budget': number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectOverviewOutput
     */
    'scheduleStartedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectOverviewOutput
     */
    'scheduleEndedAt': string | null;
    /**
     * 
     * @type {OrderInfo}
     * @memberof GetProjectOverviewOutput
     */
    'orderInfo': OrderInfo;
}
/**
 * 
 * @export
 * @interface InstagramFollowerAttributes
 */
export interface InstagramFollowerAttributes {
    /**
     * 
     * @type {Array<AgeDistribution>}
     * @memberof InstagramFollowerAttributes
     */
    'ageDistribution': Array<AgeDistribution>;
    /**
     * 
     * @type {Array<PrefDistribution>}
     * @memberof InstagramFollowerAttributes
     */
    'prefDistribution': Array<PrefDistribution>;
    /**
     * 
     * @type {GenderDistributionWithTotal}
     * @memberof InstagramFollowerAttributes
     */
    'genderDistribution': GenderDistributionWithTotal;
}
/**
 * 
 * @export
 * @interface OrderInfo
 */
export interface OrderInfo {
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    'address': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    'represent': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    'established': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    'capital': string;
    /**
     * 
     * @type {string}
     * @memberof OrderInfo
     */
    'business': string;
}
/**
 * 
 * @export
 * @interface PaginationOutput
 */
export interface PaginationOutput {
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface PatchProjectAdoptionOutput
 */
export interface PatchProjectAdoptionOutput {
    /**
     * 
     * @type {boolean}
     * @memberof PatchProjectAdoptionOutput
     */
    'status': boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchProjectAdoptionOutput
     */
    'affectedIds': Array<string>;
}
/**
 * 
 * @export
 * @interface PostProjectAttachmentsInput
 */
export interface PostProjectAttachmentsInput {
    /**
     * 
     * @type {string}
     * @memberof PostProjectAttachmentsInput
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PostProjectAttachmentsInput
     */
    'url': string;
}
/**
 * 
 * @export
 * @interface PostProjectFeedbackOutput
 */
export interface PostProjectFeedbackOutput {
    /**
     * 
     * @type {boolean}
     * @memberof PostProjectFeedbackOutput
     */
    'status': boolean;
}
/**
 * 
 * @export
 * @interface PostProjectsInput
 */
export interface PostProjectsInput {
    /**
     * 
     * @type {string}
     * @memberof PostProjectsInput
     */
    'projectGroupName'?: string | null;
    /**
     * プロジェクトグループIDがnullの場合は新規プロジェクトグループ作成
     * @type {string}
     * @memberof PostProjectsInput
     */
    'projectGroupId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProjectsInput
     */
    'projectName': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof PostProjectsInput
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {SnsType}
     * @memberof PostProjectsInput
     */
    'sns'?: SnsType;
    /**
     * 
     * @type {number}
     * @memberof PostProjectsInput
     */
    'cost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostProjectsInput
     */
    'durations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof PostProjectsInput
     */
    'adoptionPlan': number | null;
    /**
     * 
     * @type {string}
     * @memberof PostProjectsInput
     */
    'marketBackground': string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProjectsInput
     */
    'problem': string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProjectsInput
     */
    'purpose': string | null;
    /**
     * 
     * @type {string}
     * @memberof PostProjectsInput
     */
    'measure': string | null;
    /**
     * 
     * @type {ProjectKpi}
     * @memberof PostProjectsInput
     */
    'kpi'?: ProjectKpi;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const PostingType = {
    Reel: 'reel',
    Feed: 'feed',
    Stories: 'stories',
    XImagePost: 'x_image_post',
    XPost: 'x_post',
    YoutubeShort: 'youtube_short',
    Youtube: 'youtube',
    TikTok: 'tik_tok',
    OtherVideo: 'other_video',
    Other: 'other'
} as const;

export type PostingType = typeof PostingType[keyof typeof PostingType];


/**
 * 
 * @export
 * @interface PrefDistribution
 */
export interface PrefDistribution {
    /**
     * 
     * @type {string}
     * @memberof PrefDistribution
     */
    'prefCode': string;
    /**
     * 
     * @type {number}
     * @memberof PrefDistribution
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ProjectAdoption
 */
export interface ProjectAdoption {
    /**
     * 
     * @type {string}
     * @memberof ProjectAdoption
     */
    'applyingInfluencerId': string;
    /**
     * applying:応募中 approved:採用 reserved:補欠 rejected:不採用
     * @type {string}
     * @memberof ProjectAdoption
     */
    'adoption': ProjectAdoptionAdoptionEnum;
}

export const ProjectAdoptionAdoptionEnum = {
    Applying: 'applying',
    Approved: 'approved',
    Reserved: 'reserved',
    Rejected: 'rejected'
} as const;

export type ProjectAdoptionAdoptionEnum = typeof ProjectAdoptionAdoptionEnum[keyof typeof ProjectAdoptionAdoptionEnum];

/**
 * 
 * @export
 * @interface ProjectApplicantCount
 */
export interface ProjectApplicantCount {
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'applicant': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'approved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'reserved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'canBeReserver': number;
}
/**
 * 
 * @export
 * @interface ProjectApplicantItem
 */
export interface ProjectApplicantItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'influencerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'thumbnail': string | null;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluation': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'follow': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'enthusiasm': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'operatorComment': string | null;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'selectionStatus': number;
    /**
     * 最終確認 unconfirmed: 未確認 confirming: 確認中 confirmed_ok: 確認OK confirmed_ng: 確認NG
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'isFinalConfirmation'?: ProjectApplicantItemIsFinalConfirmationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isGoodQuality'?: boolean | null;
    /**
     * フォロワー数/チャンネル登録者数
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'followers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'following'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'impressions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reach'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reachRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'preservations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagements'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagementRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'frequency'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reply'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'repost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'goodAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'shareAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'views'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'viewRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'commentsAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'highEvaluationAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'mediaCount'?: number | null;
}

export const ProjectApplicantItemIsFinalConfirmationEnum = {
    Unconfirmed: 'unconfirmed',
    Confirming: 'confirming',
    ConfirmedOk: 'confirmed_ok',
    ConfirmedNg: 'confirmed_ng'
} as const;

export type ProjectApplicantItemIsFinalConfirmationEnum = typeof ProjectApplicantItemIsFinalConfirmationEnum[keyof typeof ProjectApplicantItemIsFinalConfirmationEnum];

/**
 * 
 * @export
 * @interface ProjectAttachment
 */
export interface ProjectAttachment {
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachment
     */
    'attachmentId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachment
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachment
     */
    'url': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectAttachment
     */
    'createdAt': string;
}
/**
 * 
 * @export
 * @interface ProjectAttachmentsResult
 */
export interface ProjectAttachmentsResult {
    /**
     * 
     * @type {Array<ProjectAttachment>}
     * @memberof ProjectAttachmentsResult
     */
    'attachments'?: Array<ProjectAttachment>;
}
/**
 * 
 * @export
 * @interface ProjectCalculateReward
 */
export interface ProjectCalculateReward {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectCalculateReward
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'standard': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'minimum': number;
}
/**
 * 
 * @export
 * @interface ProjectDetailHeader
 */
export interface ProjectDetailHeader {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectName': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectDetailHeader
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectDetailHeader
     */
    'projectStatus': ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'teamId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'teamName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'teamManagerName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleStartedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleEndedAt': string | null;
    /**
     * 
     * @type {FeedbackStatus}
     * @memberof ProjectDetailHeader
     */
    'feedbackStatus': FeedbackStatus | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerName': string | null;
}


/**
 * 
 * @export
 * @interface ProjectDetailMediaInput
 */
export interface ProjectDetailMediaInput {
    /**
     * 
     * @type {Array<File>}
     * @memberof ProjectDetailMediaInput
     */
    'uploads'?: Array<File>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDetailMediaInput
     */
    'deleteIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectEstimate
 */
export interface ProjectEstimate {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectEstimate
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {ProjectEstimateItemGroups}
     * @memberof ProjectEstimate
     */
    'items': ProjectEstimateItemGroups;
    /**
     * オプション選択肢配列
     * @type {Array<ProjectOptionChoice>}
     * @memberof ProjectEstimate
     */
    'optionChoices': Array<ProjectOptionChoice>;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'taxRate': number;
    /**
     * 
     * @type {FractionType}
     * @memberof ProjectEstimate
     */
    'fractionType': FractionType;
    /**
     * 
     * @type {ProjectCalculateReward}
     * @memberof ProjectEstimate
     */
    'calculateReward': ProjectCalculateReward;
}


/**
 * 
 * @export
 * @interface ProjectEstimateItem
 */
export interface ProjectEstimateItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectEstimateItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'counts': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ProjectEstimateItemGroups
 */
export interface ProjectEstimateItemGroups {
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'fixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFreeItems': Array<ProjectEstimateItem>;
}
/**
 * 
 * @export
 * @interface ProjectGantt
 */
export interface ProjectGantt {
    /**
     * 
     * @type {string}
     * @memberof ProjectGantt
     */
    'projectGroupId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectGantt
     */
    'name': string | null;
    /**
     * 
     * @type {Array<GanttItem>}
     * @memberof ProjectGantt
     */
    'projects': Array<GanttItem>;
}
/**
 * 
 * @export
 * @interface ProjectGroup
 */
export interface ProjectGroup {
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    'projectGroupId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectGroup
     */
    'name': string | null;
}
/**
 * 
 * @export
 * @interface ProjectGroupsRows
 */
export interface ProjectGroupsRows {
    /**
     * 
     * @type {Array<ProjectGroup>}
     * @memberof ProjectGroupsRows
     */
    'rows': Array<ProjectGroup>;
}
/**
 * 
 * @export
 * @interface ProjectImageRow
 */
export interface ProjectImageRow {
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'objectKey': string;
}
/**
 * 
 * @export
 * @interface ProjectItem
 */
export interface ProjectItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectItem
     */
    'name': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectItem
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectItem
     */
    'status': ProjectStatus;
}


/**
 * 
 * @export
 * @interface ProjectKpi
 */
export interface ProjectKpi {
    /**
     * KPI項目配列
     * @type {Array<ProjectKpiItem>}
     * @memberof ProjectKpi
     */
    'items': Array<ProjectKpiItem>;
}
/**
 * 
 * @export
 * @interface ProjectKpiItem
 */
export interface ProjectKpiItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'comment': string;
    /**
     * POST時isFixedは指定しなくてもtrueになります。
     * @type {boolean}
     * @memberof ProjectKpiItem
     */
    'isFixed': boolean;
}
/**
 * 
 * @export
 * @interface ProjectOptionChoice
 */
export interface ProjectOptionChoice {
    /**
     * 
     * @type {string}
     * @memberof ProjectOptionChoice
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectOptionChoice
     */
    'price': number;
}
/**
 * 最終確定日など期間ではないものはfromにnullを設定する
 * @export
 * @interface ProjectSchedule
 */
export interface ProjectSchedule {
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'to': string | null;
}
/**
 * 案件スケジュールステータス
 * @export
 * @enum {string}
 */

export const ProjectScheduleStatus = {
    Before: 'before',
    During: 'during',
    Ending: 'ending'
} as const;

export type ProjectScheduleStatus = typeof ProjectScheduleStatus[keyof typeof ProjectScheduleStatus];


/**
 * <table> <tr><td>募集内容作成（下書き）</td><td>draft</td></tr> <tr><td>募集内容作成（申請中）</td><td>review</td></tr> <tr><td>募集〜選考</td><td>approved</td></tr> <tr><td>最終確認</td><td>final_confirmation</td></tr> <tr><td>実施中</td><td>implementation</td></tr> <tr><td>完了</td><td>completion</td></tr> <tr><td>募集完了(PRST)</td><td>accept_completion</td></tr> <tr><td>配信詳細作成（下書き）</td><td>post_draft</td></tr> <tr><td>配信詳細作成（申請中）</td><td>post_review</td></tr> <tr><td>配信待ち(EMERALD POST)</td><td>wait_post</td></tr> <tr><td>配信中(EMERALD POST)</td><td>posting</td></tr> <tr><td>配信完了(EMERALD POST)</td><td>post_completion</td></tr> </table>
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Draft: 'draft',
    Review: 'review',
    Approved: 'approved',
    FinalConfirmation: 'final_confirmation',
    WaitPost: 'wait_post',
    Implementation: 'implementation',
    PostDraft: 'post_draft',
    PostReview: 'post_review',
    Posting: 'posting',
    Completion: 'completion',
    PostCompletion: 'post_completion',
    AcceptCompletion: 'accept_completion'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface ProjectSummaryApplicationCondition
 */
export interface ProjectSummaryApplicationCondition {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minFollowers': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'gender': ProjectSummaryApplicationConditionGenderEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAge': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAge': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryApplicationCondition
     */
    'prefectures': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'job': number | null;
}

export const ProjectSummaryApplicationConditionGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProjectSummaryApplicationConditionGenderEnum = typeof ProjectSummaryApplicationConditionGenderEnum[keyof typeof ProjectSummaryApplicationConditionGenderEnum];

/**
 * 
 * @export
 * @interface ProjectSummaryBase
 */
export interface ProjectSummaryBase {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'adoptionPlan': number;
    /**
     * 0:適正相場 1:カスタム設定 （Spirit以外では無視されます）
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewardsType': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewards': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryBase
     */
    'genres': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryBase
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDetails
 */
export interface ProjectSummaryDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryDetails
     */
    'notice': Array<string> | null;
    /**
     * true:非公開 false:公開
     * @type {boolean}
     * @memberof ProjectSummaryDetails
     */
    'isSecret': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDetails
     */
    'companyName': string;
}
/**
 * 
 * @export
 * @interface ProjectTitleInput
 */
export interface ProjectTitleInput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTitleInput
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTitleInput
     */
    'tags': Array<string>;
    /**
     * 
     * @type {File}
     * @memberof ProjectTitleInput
     */
    'featuredImage'?: File;
}
/**
 * 案件タイプ
 * @export
 * @enum {string}
 */

export const ProjectType = {
    Spirit: 'spirit',
    EmeraldPost: 'emerald_post',
    Prst: 'prst',
    JaneJohn: 'jane_john',
    Other: 'other'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * 
 * @export
 * @interface ProjectTypeItem
 */
export interface ProjectTypeItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'endDate': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeItem
     */
    'status': number;
}
/**
 * 
 * @export
 * @interface ProjectTypeListCounts
 */
export interface ProjectTypeListCounts {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'draft': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'selection': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'finalConfirmation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'implementation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'completion': number;
}
/**
 * SNSタイプ
 * @export
 * @enum {string}
 */

export const SnsType = {
    Instagram: 'instagram',
    X: 'x',
    TikTok: 'tik_tok',
    Youtube: 'youtube',
    Other: 'other'
} as const;

export type SnsType = typeof SnsType[keyof typeof SnsType];


/**
 * 
 * @export
 * @interface UploadedImageOutput
 */
export interface UploadedImageOutput {
    /**
     * 
     * @type {string}
     * @memberof UploadedImageOutput
     */
    'uploadedFilePath'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 案件添付ファイルを削除するAPIです
         * @summary 案件添付ファイル削除API
         * @param {string} projectId 案件ID
         * @param {string} attachmentId 添付ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectAttachments: async (projectId: string, attachmentId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('deleteProjectAttachments', 'projectId', projectId)
            // verify required parameter 'attachmentId' is not null or undefined
            assertParamExists('deleteProjectAttachments', 'attachmentId', attachmentId)
            const localVarPath = `/projects/{projectId}/attachments/{attachmentId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"attachmentId"}}`, encodeURIComponent(String(attachmentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件添付ファイルを取得するAPIです
         * @summary 案件添付ファイル一覧取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAttachments: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectAttachments', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/attachments`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件カレンダーを返却します。 <br>月単位で表示するため、年月のパラメータを受け取ります。 <br>指定された月の前後７日が案件実施期間に含まれる案件の一覧を返却します。
         * @summary 案件カレンダー取得API
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCalendar: async (year: number, month: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getProjectCalendar', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getProjectCalendar', 'month', month)
            const localVarPath = `/projects/calendar/{year}/{month}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件スケジュールを返却します。<br>案件をまとめるプロジェクトグループ内に案件の一覧が格納され、案件ごとのスケジュールが格納されています。<br>月単位で表示するため、年月のパラメータを受け取ります。
         * @summary 案件ガントチャートAPI
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGantt: async (year: number, month: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('getProjectGantt', 'year', year)
            // verify required parameter 'month' is not null or undefined
            assertParamExists('getProjectGantt', 'month', month)
            const localVarPath = `/projects/gantt/{year}/{month}`
                .replace(`{${"year"}}`, encodeURIComponent(String(year)))
                .replace(`{${"month"}}`, encodeURIComponent(String(month)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * プロジェクトグループの一覧を返却します。
         * @summary プロジェクトグループ一覧取得API
         * @param {GetProjectGroupsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetProjectGroupsOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGroups: async (rows?: GetProjectGroupsRowsEnum, page?: number, order?: GetProjectGroupsOrderEnum, orderBy?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/project-groups`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rows !== undefined) {
                localVarQueryParameter['rows'] = rows;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件概要を取得するAPIです
         * @summary 案件概要取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectOverview: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getProjectOverview', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/overview`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件の採用者を確定します。
         * @summary 案件採用者確定API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectAdopterDecision: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchProjectAdopterDecision', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/adopterDecision`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件の採用設定するAPIです。 <br>一度に複数のインフルエンサーの採用設定を行えます。 <br>インフルエンサーIDと採用ステータスの配列を返却します。 <br>採用、不採用、補欠の３種類の採用ステータスに変更できます。 <br>補欠採用する場合、対象のアカウントが補欠採用を許可した応募になっている必要があります。 <br>補欠採用を許可していない場合、無視します。
         * @summary 案件採用設定API
         * @param {string} projectId 案件ID
         * @param {Array<ProjectAdoption>} [projectAdoption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectAdoption: async (projectId: string, projectAdoption?: Array<ProjectAdoption>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('patchProjectAdoption', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/adoption`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(projectAdoption, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件の内容を申請するAPIです。 <br> 案件がドラフト状態でない場合はエラーとなります。
         * @summary 案件内容申請API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectApplication: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postProjectApplication', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/application`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件添付ファイルを登録するAPIです
         * @summary 案件添付ファイル登録API
         * @param {string} projectId 案件ID
         * @param {PostProjectAttachmentsInput} [postProjectAttachmentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectAttachments: async (projectId: string, postProjectAttachmentsInput?: PostProjectAttachmentsInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postProjectAttachments', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/attachments`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProjectAttachmentsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 当該案件のインフルエンサーへのフィードバックを確定します。 <br>※ 当該案件が完了ステータスになっている必要があります。 <br>※ 管理画面から最終確定されている場合は実行不可となります。 <br>※ SPIRIT、JANE JOHN、その他の案件でのみフィードバックが可能です。
         * @summary フィードバック確定API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectFeedback: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postProjectFeedback', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/feedback`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した案件を複製します。<br>複製した案件は下書き状態で作成されます。<br>複製する対象はログインしているチームの案件のみです。
         * @summary 案件複製API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectReplication: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('postProjectReplication', 'projectId', projectId)
            const localVarPath = `/projects/{projectId}/replication`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 案件を作成します。<br>作成した案件は下書き状態で作成されます。 <div><b>プロジェクトグループ指定</b></div> ログイン中のチームのプロジェクトグループIDを指定した場合、指定したプロジェクトグループに紐づく形で案件が作成されます。 <div><b>プロジェクトグループ新規作成</b></div> プロジェクトグループIDが<b>null</b>でプロジェクトグループ名が設定してある場合、プロジェクトグループを作成したうえで、新規作成されたプロジェクトに紐づく形で案件を作成します。
         * @summary 案件作成API
         * @param {PostProjectsInput} [postProjectsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjects: async (postProjectsInput?: PostProjectsInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/projects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postProjectsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 案件添付ファイルを削除するAPIです
         * @summary 案件添付ファイル削除API
         * @param {string} projectId 案件ID
         * @param {string} attachmentId 添付ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteProjectAttachments(projectId: string, attachmentId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAttachmentsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteProjectAttachments(projectId, attachmentId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteProjectAttachments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件添付ファイルを取得するAPIです
         * @summary 案件添付ファイル一覧取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectAttachments(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAttachmentsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectAttachments(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjectAttachments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件カレンダーを返却します。 <br>月単位で表示するため、年月のパラメータを受け取ります。 <br>指定された月の前後７日が案件実施期間に含まれる案件の一覧を返却します。
         * @summary 案件カレンダー取得API
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectCalendar(year: number, month: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CalendarItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectCalendar(year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjectCalendar']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件スケジュールを返却します。<br>案件をまとめるプロジェクトグループ内に案件の一覧が格納され、案件ごとのスケジュールが格納されています。<br>月単位で表示するため、年月のパラメータを受け取ります。
         * @summary 案件ガントチャートAPI
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectGantt(year: number, month: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ProjectGantt>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectGantt(year, month, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjectGantt']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * プロジェクトグループの一覧を返却します。
         * @summary プロジェクトグループ一覧取得API
         * @param {GetProjectGroupsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetProjectGroupsOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectGroups(rows?: GetProjectGroupsRowsEnum, page?: number, order?: GetProjectGroupsOrderEnum, orderBy?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectGroupsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectGroups(rows, page, order, orderBy, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjectGroups']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件概要を取得するAPIです
         * @summary 案件概要取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getProjectOverview(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetProjectOverviewOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getProjectOverview(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getProjectOverview']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件の採用者を確定します。
         * @summary 案件採用者確定API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProjectAdopterDecision(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProjectAdopterDecision(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchProjectAdopterDecision']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件の採用設定するAPIです。 <br>一度に複数のインフルエンサーの採用設定を行えます。 <br>インフルエンサーIDと採用ステータスの配列を返却します。 <br>採用、不採用、補欠の３種類の採用ステータスに変更できます。 <br>補欠採用する場合、対象のアカウントが補欠採用を許可した応募になっている必要があります。 <br>補欠採用を許可していない場合、無視します。
         * @summary 案件採用設定API
         * @param {string} projectId 案件ID
         * @param {Array<ProjectAdoption>} [projectAdoption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchProjectAdoption(projectId: string, projectAdoption?: Array<ProjectAdoption>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PatchProjectAdoptionOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchProjectAdoption(projectId, projectAdoption, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchProjectAdoption']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件の内容を申請するAPIです。 <br> 案件がドラフト状態でない場合はエラーとなります。
         * @summary 案件内容申請API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectApplication(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectItem>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectApplication(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postProjectApplication']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件添付ファイルを登録するAPIです
         * @summary 案件添付ファイル登録API
         * @param {string} projectId 案件ID
         * @param {PostProjectAttachmentsInput} [postProjectAttachmentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectAttachments(projectId: string, postProjectAttachmentsInput?: PostProjectAttachmentsInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProjectAttachmentsResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectAttachments(projectId, postProjectAttachmentsInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postProjectAttachments']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 当該案件のインフルエンサーへのフィードバックを確定します。 <br>※ 当該案件が完了ステータスになっている必要があります。 <br>※ 管理画面から最終確定されている場合は実行不可となります。 <br>※ SPIRIT、JANE JOHN、その他の案件でのみフィードバックが可能です。
         * @summary フィードバック確定API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectFeedback(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostProjectFeedbackOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectFeedback(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postProjectFeedback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定した案件を複製します。<br>複製した案件は下書き状態で作成されます。<br>複製する対象はログインしているチームの案件のみです。
         * @summary 案件複製API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjectReplication(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjectReplication(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postProjectReplication']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 案件を作成します。<br>作成した案件は下書き状態で作成されます。 <div><b>プロジェクトグループ指定</b></div> ログイン中のチームのプロジェクトグループIDを指定した場合、指定したプロジェクトグループに紐づく形で案件が作成されます。 <div><b>プロジェクトグループ新規作成</b></div> プロジェクトグループIDが<b>null</b>でプロジェクトグループ名が設定してある場合、プロジェクトグループを作成したうえで、新規作成されたプロジェクトに紐づく形で案件を作成します。
         * @summary 案件作成API
         * @param {PostProjectsInput} [postProjectsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postProjects(postProjectsInput?: PostProjectsInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postProjects(postProjectsInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postProjects']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 案件添付ファイルを削除するAPIです
         * @summary 案件添付ファイル削除API
         * @param {string} projectId 案件ID
         * @param {string} attachmentId 添付ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProjectAttachments(projectId: string, attachmentId: string, options?: any): AxiosPromise<ProjectAttachmentsResult> {
            return localVarFp.deleteProjectAttachments(projectId, attachmentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件添付ファイルを取得するAPIです
         * @summary 案件添付ファイル一覧取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectAttachments(projectId: string, options?: any): AxiosPromise<ProjectAttachmentsResult> {
            return localVarFp.getProjectAttachments(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件カレンダーを返却します。 <br>月単位で表示するため、年月のパラメータを受け取ります。 <br>指定された月の前後７日が案件実施期間に含まれる案件の一覧を返却します。
         * @summary 案件カレンダー取得API
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectCalendar(year: number, month: number, options?: any): AxiosPromise<Array<CalendarItem>> {
            return localVarFp.getProjectCalendar(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件スケジュールを返却します。<br>案件をまとめるプロジェクトグループ内に案件の一覧が格納され、案件ごとのスケジュールが格納されています。<br>月単位で表示するため、年月のパラメータを受け取ります。
         * @summary 案件ガントチャートAPI
         * @param {number} year 
         * @param {number} month 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGantt(year: number, month: number, options?: any): AxiosPromise<Array<ProjectGantt>> {
            return localVarFp.getProjectGantt(year, month, options).then((request) => request(axios, basePath));
        },
        /**
         * プロジェクトグループの一覧を返却します。
         * @summary プロジェクトグループ一覧取得API
         * @param {GetProjectGroupsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetProjectGroupsOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectGroups(rows?: GetProjectGroupsRowsEnum, page?: number, order?: GetProjectGroupsOrderEnum, orderBy?: string, options?: any): AxiosPromise<GetProjectGroupsOutput> {
            return localVarFp.getProjectGroups(rows, page, order, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件概要を取得するAPIです
         * @summary 案件概要取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectOverview(projectId: string, options?: any): AxiosPromise<GetProjectOverviewOutput> {
            return localVarFp.getProjectOverview(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件の採用者を確定します。
         * @summary 案件採用者確定API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectAdopterDecision(projectId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchProjectAdopterDecision(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件の採用設定するAPIです。 <br>一度に複数のインフルエンサーの採用設定を行えます。 <br>インフルエンサーIDと採用ステータスの配列を返却します。 <br>採用、不採用、補欠の３種類の採用ステータスに変更できます。 <br>補欠採用する場合、対象のアカウントが補欠採用を許可した応募になっている必要があります。 <br>補欠採用を許可していない場合、無視します。
         * @summary 案件採用設定API
         * @param {string} projectId 案件ID
         * @param {Array<ProjectAdoption>} [projectAdoption] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchProjectAdoption(projectId: string, projectAdoption?: Array<ProjectAdoption>, options?: any): AxiosPromise<PatchProjectAdoptionOutput> {
            return localVarFp.patchProjectAdoption(projectId, projectAdoption, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件の内容を申請するAPIです。 <br> 案件がドラフト状態でない場合はエラーとなります。
         * @summary 案件内容申請API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectApplication(projectId: string, options?: any): AxiosPromise<ProjectItem> {
            return localVarFp.postProjectApplication(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件添付ファイルを登録するAPIです
         * @summary 案件添付ファイル登録API
         * @param {string} projectId 案件ID
         * @param {PostProjectAttachmentsInput} [postProjectAttachmentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectAttachments(projectId: string, postProjectAttachmentsInput?: PostProjectAttachmentsInput, options?: any): AxiosPromise<ProjectAttachmentsResult> {
            return localVarFp.postProjectAttachments(projectId, postProjectAttachmentsInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 当該案件のインフルエンサーへのフィードバックを確定します。 <br>※ 当該案件が完了ステータスになっている必要があります。 <br>※ 管理画面から最終確定されている場合は実行不可となります。 <br>※ SPIRIT、JANE JOHN、その他の案件でのみフィードバックが可能です。
         * @summary フィードバック確定API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectFeedback(projectId: string, options?: any): AxiosPromise<PostProjectFeedbackOutput> {
            return localVarFp.postProjectFeedback(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した案件を複製します。<br>複製した案件は下書き状態で作成されます。<br>複製する対象はログインしているチームの案件のみです。
         * @summary 案件複製API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjectReplication(projectId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postProjectReplication(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 案件を作成します。<br>作成した案件は下書き状態で作成されます。 <div><b>プロジェクトグループ指定</b></div> ログイン中のチームのプロジェクトグループIDを指定した場合、指定したプロジェクトグループに紐づく形で案件が作成されます。 <div><b>プロジェクトグループ新規作成</b></div> プロジェクトグループIDが<b>null</b>でプロジェクトグループ名が設定してある場合、プロジェクトグループを作成したうえで、新規作成されたプロジェクトに紐づく形で案件を作成します。
         * @summary 案件作成API
         * @param {PostProjectsInput} [postProjectsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postProjects(postProjectsInput?: PostProjectsInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postProjects(postProjectsInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 案件添付ファイルを削除するAPIです
     * @summary 案件添付ファイル削除API
     * @param {string} projectId 案件ID
     * @param {string} attachmentId 添付ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteProjectAttachments(projectId: string, attachmentId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteProjectAttachments(projectId, attachmentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件添付ファイルを取得するAPIです
     * @summary 案件添付ファイル一覧取得API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectAttachments(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectAttachments(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件カレンダーを返却します。 <br>月単位で表示するため、年月のパラメータを受け取ります。 <br>指定された月の前後７日が案件実施期間に含まれる案件の一覧を返却します。
     * @summary 案件カレンダー取得API
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectCalendar(year: number, month: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectCalendar(year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件スケジュールを返却します。<br>案件をまとめるプロジェクトグループ内に案件の一覧が格納され、案件ごとのスケジュールが格納されています。<br>月単位で表示するため、年月のパラメータを受け取ります。
     * @summary 案件ガントチャートAPI
     * @param {number} year 
     * @param {number} month 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectGantt(year: number, month: number, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectGantt(year, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * プロジェクトグループの一覧を返却します。
     * @summary プロジェクトグループ一覧取得API
     * @param {GetProjectGroupsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
     * @param {number} [page] 
     * @param {GetProjectGroupsOrderEnum} [order] 
     * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectGroups(rows?: GetProjectGroupsRowsEnum, page?: number, order?: GetProjectGroupsOrderEnum, orderBy?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectGroups(rows, page, order, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件概要を取得するAPIです
     * @summary 案件概要取得API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getProjectOverview(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getProjectOverview(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件の採用者を確定します。
     * @summary 案件採用者確定API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchProjectAdopterDecision(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchProjectAdopterDecision(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件の採用設定するAPIです。 <br>一度に複数のインフルエンサーの採用設定を行えます。 <br>インフルエンサーIDと採用ステータスの配列を返却します。 <br>採用、不採用、補欠の３種類の採用ステータスに変更できます。 <br>補欠採用する場合、対象のアカウントが補欠採用を許可した応募になっている必要があります。 <br>補欠採用を許可していない場合、無視します。
     * @summary 案件採用設定API
     * @param {string} projectId 案件ID
     * @param {Array<ProjectAdoption>} [projectAdoption] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchProjectAdoption(projectId: string, projectAdoption?: Array<ProjectAdoption>, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchProjectAdoption(projectId, projectAdoption, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件の内容を申請するAPIです。 <br> 案件がドラフト状態でない場合はエラーとなります。
     * @summary 案件内容申請API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProjectApplication(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProjectApplication(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件添付ファイルを登録するAPIです
     * @summary 案件添付ファイル登録API
     * @param {string} projectId 案件ID
     * @param {PostProjectAttachmentsInput} [postProjectAttachmentsInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProjectAttachments(projectId: string, postProjectAttachmentsInput?: PostProjectAttachmentsInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProjectAttachments(projectId, postProjectAttachmentsInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 当該案件のインフルエンサーへのフィードバックを確定します。 <br>※ 当該案件が完了ステータスになっている必要があります。 <br>※ 管理画面から最終確定されている場合は実行不可となります。 <br>※ SPIRIT、JANE JOHN、その他の案件でのみフィードバックが可能です。
     * @summary フィードバック確定API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProjectFeedback(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProjectFeedback(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した案件を複製します。<br>複製した案件は下書き状態で作成されます。<br>複製する対象はログインしているチームの案件のみです。
     * @summary 案件複製API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProjectReplication(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProjectReplication(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 案件を作成します。<br>作成した案件は下書き状態で作成されます。 <div><b>プロジェクトグループ指定</b></div> ログイン中のチームのプロジェクトグループIDを指定した場合、指定したプロジェクトグループに紐づく形で案件が作成されます。 <div><b>プロジェクトグループ新規作成</b></div> プロジェクトグループIDが<b>null</b>でプロジェクトグループ名が設定してある場合、プロジェクトグループを作成したうえで、新規作成されたプロジェクトに紐づく形で案件を作成します。
     * @summary 案件作成API
     * @param {PostProjectsInput} [postProjectsInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postProjects(postProjectsInput?: PostProjectsInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postProjects(postProjectsInput, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetProjectGroupsRowsEnum = {
    NUMBER_10: 10,
    NUMBER_30: 30,
    NUMBER_50: 50,
    NUMBER_100: 100
} as const;
export type GetProjectGroupsRowsEnum = typeof GetProjectGroupsRowsEnum[keyof typeof GetProjectGroupsRowsEnum];
/**
 * @export
 */
export const GetProjectGroupsOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetProjectGroupsOrderEnum = typeof GetProjectGroupsOrderEnum[keyof typeof GetProjectGroupsOrderEnum];


