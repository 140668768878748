import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  GetOtherDetailOutput,
  DefaultApi,
  GetOtherListStatusEnum,
  GetOtherListListTypeEnum,
  GetOtherListRowsEnum,
  GetOtherListOrderEnum,
  GetOtherListOutput,
  OtherProjectSchedule,
  ProjectSummaryBase,
} from '@/api/other';
import { axiosInstance } from '@/utils/axiosInstance';

import { useApiClients } from './useApiClients';

const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * その他案件一覧取得API
 */
export const getOtherList = async (
  status?: GetOtherListStatusEnum,
  listType?: GetOtherListListTypeEnum,
  rows?: GetOtherListRowsEnum,
  page?: number,
  order?: GetOtherListOrderEnum,
  orderBy?: string,
  noCache?: boolean
) => {
  try {
    const response: AxiosResponse<GetOtherListOutput> = await api.getOtherList(
      status,
      listType,
      rows,
      page,
      order,
      orderBy,
      noCache
        ? {
            params: {
              date: new Date().toISOString(),
            },
          }
        : undefined
    );
    return response.data as GetOtherListOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * その他案件詳細取得API
 */
export const getOtherDetail = async (projectId: string) => {
  try {
    const response: AxiosResponse<GetOtherDetailOutput> = await api.getOtherDetail(projectId);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/** その他案件スケジュール取得API
 * @param projectId
 */
export const getOtherSchedule = async (projectId: string) => {
  try {
    const response: AxiosResponse<OtherProjectSchedule[]> = await api.getOtherSchedule(projectId);
    return response.data as OtherProjectSchedule[];
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// その他案件概要設定API
export const PutOtherSummary = async (
  projectId: string,
  projectSummaryBase?: ProjectSummaryBase
): Promise<GetOtherDetailOutput> => {
  try {
    const { otherApi } = useApiClients();
    const response = await otherApi.putOtherSummary(projectId, projectSummaryBase);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
