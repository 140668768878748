import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  DefaultApi,
  GetNotificationsOrderEnum,
  GetNotificationsOutput,
  GetNotificationsRowsEnum,
  PatchNotificationSubscribeOutput,
} from '@/api/notification';
import { axiosInstance } from '@/utils/axiosInstance';
const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * 通知一覧取得API
 * @param rows
 * @param page
 * @param order
 * @returns
 */
export const fetchNoticesInfo = async (
  rows: GetNotificationsRowsEnum,
  page: number,
  order: GetNotificationsOrderEnum
): Promise<GetNotificationsOutput> => {
  try {
    const response: AxiosResponse<GetNotificationsOutput> = await api.getNotifications(rows, page, order);
    return response.data as GetNotificationsOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 通知購読API
 * @param
 * @returns
 */
export const noticeSubscription = async (): Promise<PatchNotificationSubscribeOutput> => {
  try {
    const response: AxiosResponse<PatchNotificationSubscribeOutput> = await api.patchNotificationSubscribe();
    return response.data as PatchNotificationSubscribeOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
