import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementListView } from '../components/PRSTProductionManagementListView';

export const PRSTProductionManagementList = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementListView />
    </MainLayout>
  );
};
