import React from 'react';

import { ReactComponent as ChatIcon } from '@/assets/icons/bx-chat.svg';
import { ReactComponent as MessageCheckIcon } from '@/assets/icons/bx-message-check.svg';
import { ReactComponent as RightArrowIcon } from '@/assets/icons/bx-right-arrow-alt.svg';
import { ReactComponent as SupportIcon } from '@/assets/icons/bx-support.svg';

const GUIDE = {
  SUPPORT: 'support',
  CHAT: 'chat',
  CHECK: 'check',
};

export default function projectGuide(): React.ReactElement {
  const guideIcon = (step: string) => {
    if (step === GUIDE.SUPPORT) {
      return <SupportIcon width={40} height={40} fill="#007CC2" />;
    } else if (step === GUIDE.CHAT) {
      return <ChatIcon width={40} height={40} fill="#007CC2" />;
    } else {
      return <MessageCheckIcon width={40} height={40} fill="#007CC2" />;
    }
  };

  const guideMessage = (step: string) => {
    if (step === GUIDE.SUPPORT) {
      return (
        <div className="h-[60px] w-[196px] text-center">
          <span className="text-sm font-normal leading-5 text-gray-900">
            下記ボタンより
            <br />
            詳細へ進むと、
            <br />
            弊社の専任の担当者が付きます
          </span>
        </div>
      );
    } else if (step === GUIDE.CHAT) {
      return (
        <div className="h-[80px] w-[224px] text-center">
          <span className="text-sm font-normal leading-5 text-gray-900">
            弊社担当者が、
            <br />
            お客様とチャット・ビデオ通話等で
            <br />
            コミュニケーションを取りながら、
            <br />
            案件の詳細事項を作成します
          </span>
        </div>
      );
    } else {
      return (
        <div className="h-[80px] w-[168px] text-center">
          <span className="text-sm font-normal leading-5 text-gray-900">
            作成した案件の詳細事項を <br />
            お客様にご確認いただき、 <br />
            ご承認いただきましたら <br />
            案件が開始となります
          </span>
        </div>
      );
    }
  };

  const guide = (step: string) => {
    return (
      <div className="flex flex-col items-center">
        <div className="flex h-[80px] w-[80px] flex-row items-center justify-center rounded-[72px] bg-gray-50">
          {guideIcon(step)}
        </div>
        {guideMessage(step)}
      </div>
    );
  };

  return (
    <div className="flex h-[224px] w-[860px] flex-col items-start">
      <div>
        <span className="text-lg font-semibold leading-7 text-gray-500">案件の始め方について</span>
      </div>
      <div className="flex h-[168px] flex-row items-center justify-between gap-12">
        <div>{guide(GUIDE.SUPPORT)}</div>
        <div>
          <RightArrowIcon width={40} height={40} fill="#6B7280" />
        </div>
        <div>{guide(GUIDE.CHAT)}</div>
        <div>
          <RightArrowIcon width={40} height={40} fill="#6B7280" />
        </div>
        <div>{guide(GUIDE.CHECK)}</div>
      </div>
    </div>
  );
}
