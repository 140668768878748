import MediaImage1 from './assets/Media-image1.svg';
import MediaImage2 from './assets/Media-image2.svg';
import MediaImage3 from './assets/Media-image3.svg';
import MediaImage4 from './assets/Media-image4.svg';
import { PostInfo } from './PostInfo';

export default function PostList() {
  const list_text_style = 'pt-[16px] pl-[24px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500';
  // const [screenFlag, setScreenFlag] = useState(false);
  // const [open, setOpen] = useState(true);

  return (
    <>
      <div className="bg-white">
        <div className="flex">
          {/* header 部分 → NO 確認要件 プロジェクト... */}
          <div className="h-[48px] w-[88px]">
            <div className={list_text_style}>No</div>
          </div>
          <div className="h-[48px] w-[218px]">
            <div className={list_text_style}>投稿予定日</div>
          </div>
          <div className="h-[48px] w-[141px]">
            <div className={list_text_style}>メディアイメージ</div>
          </div>
          <div className="h-[48px] w-[234px]">
            <div className={list_text_style}>テキストイメージ</div>
          </div>
          <div className="h-[48px] w-[118px]">
            <div className={list_text_style}>タイプ</div>
          </div>
          <div className="h-[48px] w-[157px]">
            <div className={list_text_style}>ステータス</div>
          </div>
          <div className="h-[48px] w-[120px]">
            <div className={list_text_style}>コメント</div>
          </div>
          <div className="h-[48px] w-[57px]"></div>
        </div>
        <div>
          <div className="h-[1px] w-[1133px] bg-gray-200"></div>
        </div>
      </div>
      {/* 1列目 */}
      <PostInfo
        postNo="0005"
        postPlanDate="2021年2月14日（金） 18:00"
        mediaSrc={MediaImage1}
        textImage="以下の強みを入れてください。..."
        type="フィード"
        status="コンテンツ制作待ち"
        comment={1}
      />
      {/* 2列目 */}
      <PostInfo
        postNo="0004"
        postPlanDate="2021年2月12日（金） 18:00"
        mediaSrc={MediaImage2}
        textImage="「Canon360」という商品名を入れてください。..."
        type="フィード"
        status="コンテンツ制作待ち"
        comment={10}
      />
      {/* 3列目 */}
      <PostInfo
        postNo="0003"
        postPlanDate="2021年2月10日（金） 18:00"
        mediaSrc={MediaImage3}
        textImage="「Canon360」という商品名を入れてください。..."
        type="ストーリー"
        status="完了"
        comment={6}
      />
      {/* 4列目 */}
      <PostInfo
        postNo="0002"
        postPlanDate="2021年2月8日（金） 18:00"
        mediaSrc={MediaImage4}
        textImage="「Canon360」という商品名を入れてください。..."
        type="リール"
        status="完了"
        comment={2}
      />
      {/* 5列目 */}
      <PostInfo
        postNo="0001"
        postPlanDate="2021年2月1日（金） 18:00"
        mediaSrc=""
        textImage="「Canon360」という商品名を入れてください。..."
        type="フィード"
        status="完了"
        comment={10}
      />
    </>
  );
}
