// YYYY/MM/DD HH:MM 形式に変換する関数
export const dateConvert = (dateStr: string) => {
  const date = new Date(dateStr);

  const dateString = date.toLocaleDateString('ja-JP', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });
  const timeString = date.toLocaleTimeString('ja-JP', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });

  const formattedDate = `${dateString} ${timeString}`;
  console.log(formattedDate);
  return formattedDate;
};
