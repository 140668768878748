import { ReactComponent as CalendarIcon } from './assets/bx-calendar.svg';

export const CalendarInput = () => {
  return (
    <>
      <div className="flex justify-start">
        <span className="text-left text-sm font-semibold leading-5 text-gray-700">投稿予定日</span>
      </div>
      <div className="relative mt-2 rounded-md shadow-sm">
        <input
          className="block w-full rounded-md border-0 px-[13px] py-[9px] text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-700 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          placeholder="2021年2月12日（金）"
        />
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
          <CalendarIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
      </div>
    </>
  );
};
