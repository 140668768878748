import { Dialog, Transition } from '@headlessui/react';
import { BookmarkIcon, XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SnsType } from '@/api/analysisSpirit';
import {
  SnsAccountInsightInstagram,
  SnsAccountInsightTikTok,
  SnsAccountInsightX,
  SnsAccountInsightYoutube,
} from '@/api/influencer/api';
import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PostIcon } from '@/assets/icons/bx-notepad.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as RepostIcon } from '@/assets/icons/bx-repost.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as ShowIcon } from '@/assets/icons/bx-show.svg';
import { ReactComponent as UploadIcon } from '@/assets/icons/bx-upload.svg';
import { ReactComponent as UserPlusIcon } from '@/assets/icons/bx-user-plus.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/bx-user.svg';
import { ReactComponent as StarStrokeIcon } from '@/assets/icons/star-stroke.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import { Badge } from '@/components/Elements/Badge';
import { InstagramPostType, stringToInstagramPostType } from '@/types';
import { snsIcon } from '@/utils/sns';

import {
  followerAttributesIf,
  InfuluencerAccountDetailBase,
  modalCommonProps,
  modalTwitterProps_SPIRIT,
  tableBodyCommonProps,
  TabProps,
} from './modalInterface';
import AccountInsight, { insightPair } from './tabs/AccountInsight';
import FollowerAttributeCommon from './tabs/FollwerAttributesCommon';
import AccountInsightCommon from './tabs/PostInsightCommon';
import RecruitmentInformation from './tabs/RecruitmentInformation';
import { InsightPostCommon } from './tabs/summaryCommon';

// SNSアカウント詳細モーダル表示部品
export default function ModalCommon(props: modalCommonProps) {
  const [tabKey, setTabKey] = useState<string>(props.initialTabKey);
  const [influencerSnsAccount, setInfluencerSnsAccount] = useState<InfuluencerAccountDetailBase | null>(null);
  const insight = influencerSnsAccount?.insight;
  const followerAttributes = influencerSnsAccount?.followerAttributes;
  const top3 = followerAttributes?.top3;
  const applyingComment = influencerSnsAccount?.applyingComment;
  const accountPr = influencerSnsAccount?.accountPr;
  const snsType = influencerSnsAccount?.snsType ?? SnsType.Other;
  const evaluation = influencerSnsAccount?.evaluation;
  const reservedString = applyingComment?.reserved ? 'あり' : 'なし';
  const search = useLocation().search;
  const xInsight = insight as SnsAccountInsightX;
  const tikTokInsight = insight as SnsAccountInsightTikTok;
  const youtubeInsight = insight as SnsAccountInsightYoutube;
  const instagramInsight = insight as SnsAccountInsightInstagram;
  const modalTypeIdEmeraldPost = props.modalType === 'emerald_post';

  const getFollowers = (): string => {
    if (!(insight && 'followers' in insight)) {
      return '';
    }

    switch (snsType) {
      case SnsType.X:
        return xInsight?.followers?.toLocaleString() ?? '';
      case SnsType.TikTok:
        return tikTokInsight?.followers?.toLocaleString() ?? '';
      case SnsType.Youtube:
        return youtubeInsight?.subscribers?.toLocaleString() ?? '';
      case SnsType.Instagram:
        return instagramInsight?.followers?.toLocaleString() ?? '';
      default:
        return '';
    }
  };

  console.log('props:' + JSON.stringify(props, null, 2));
  const spiritProps = props as unknown as modalTwitterProps_SPIRIT;
  console.log('spiritProps:' + JSON.stringify(spiritProps, null, 2));
  const followerAttributesValue: followerAttributesIf = {
    ageDistribution: followerAttributes?.ageDistribution,
    prefDistribution: followerAttributes?.prefDistribution,
    genderDistribution: followerAttributes?.genderDistribution
      ? followerAttributes.genderDistribution.map((genderDistribution) => {
          const gender = genderDistribution.gender === 1 ? 1 : genderDistribution.gender === 2 ? 2 : 3;
          return {
            ...genderDistribution,
            gender,
          };
        })
      : [],
    followers: followerAttributes?.followers,
    top3: {
      ageGender: top3?.ageGender ?? [],
      genre: top3?.genre ?? [],
      values: top3?.values ?? [],
    },
  };

  const selectTab = (): TabProps[] => {
    const selectTabs = [
      { name: 'アカウントインサイト', key: 'accountInsight' },
      { name: snsType === SnsType.Youtube ? 'チャンネル登録者属性' : 'フォロワー属性', key: 'follower' },
      { name: '採用前情報', key: 'information' },
    ];
    const query = new URLSearchParams(search);
    const type = query.get('type') ?? '';
    if (props.modalType === 'sns' && type.toLowerCase() === 'spirit') {
      selectTabs.unshift({ name: '投稿インサイト', key: 'postInsight' });
    } else if (props.modalType === 'emerald_post') {
      selectTabs.unshift({ name: '投稿情報', key: 'accoutPost' });
    }
    return selectTabs;
  };

  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };

  const handeRightTab = () => {
    const index = selectTab().findIndex((tab: TabProps) => tab.key == tabKey);
    if (index == selectTab().length - 1) {
      setTabKey(selectTab()[0].key);
    } else {
      setTabKey(selectTab()[index + 1].key);
    }
  };

  useEffect(() => {
    const fetchInfluencerApplicantDetailInfo = async () => {
      if (!props.accountInformation.id) {
        return;
      }
      try {
        console.log('accountInformation:' + JSON.stringify(props.accountInformation, null, 2));
        const data = await props.getInfluencerData(props.accountInformation.applyingInfluencerId ?? '');
        setInfluencerSnsAccount(data);
        console.log('setInfluencerSnsAccount:', data);
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    };

    fetchInfluencerApplicantDetailInfo();
  }, [props.accountInformation.id]);

  const getInsightValues = (
    snsType: SnsType,
    accountInformation: tableBodyCommonProps,
    postType?: InstagramPostType
  ): [
    string | number,
    string | number,
    string | number,
    string | number,
    string | number,
    string | number,
    string | number,
    string | number,
    string | number,
    string | number
  ] => {
    if (snsType === SnsType.X) {
      return [
        accountInformation.engagementRate ?? 0,
        numberToString(accountInformation.numberOfEngagements),
        numberToString(accountInformation.goods),
        numberToString(accountInformation.comment),
        numberToString(accountInformation.repost),
        '',
        '',
        '',
        '',
        '',
      ];
    }

    if (snsType === SnsType.TikTok) {
      return [
        numberToString(accountInformation.sumViewNum),
        accountInformation.engagementRate ?? 0,
        numberToString(accountInformation.numberOfEngagements),
        numberToString(accountInformation.goods),
        numberToString(accountInformation.comment),
        numberToString(accountInformation.repost),
        '',
        '',
        '',
        '',
      ];
    }

    if (snsType === SnsType.Youtube) {
      return [
        numberToString(accountInformation.sumViewNum),
        accountInformation.engagementRate ?? 0,
        numberToString(accountInformation.numberOfEngagements),
        numberToString(accountInformation.goods),
        numberToString(accountInformation.comment),
        '',
        '',
        '',
        '',
        '',
      ];
    }

    if (snsType == SnsType.Instagram && postType === 'Story') {
      return [
        numberToString(accountInformation.impressionCount),
        numberToString(accountInformation.uniqueAccountCount),
        numberToString(accountInformation.tapsForwardCount),
        numberToString(accountInformation.tapsBackCount),
        numberToString(accountInformation.exitsCount),
        '',
        '',
        '',
        '',
        '',
      ];
    }

    if (snsType == SnsType.Instagram && postType !== 'Story') {
      return [
        numberToString(accountInformation.impressionCount),
        numberToString(accountInformation.uniqueAccountCount),
        numberToString(accountInformation.engagementCount),
        accountInformation.engagementRate ?? 0,
        numberToString(accountInformation.goodCount),
        numberToString(accountInformation.commentCount),
        numberToString(accountInformation.shareCount),
        numberToString(accountInformation.saveCount),
        numberToString(accountInformation.frequency),
        '',
      ];
    }
    return ['', '', '', '', '', '', '', '', '', ''];
  };
  const insightValues = getInsightValues(snsType, props.accountInformation, props.postType as InstagramPostType);

  const selectContent = () => {
    if (!influencerSnsAccount) {
      return <div>Loading...</div>;
    }

    if (tabKey == 'postInsight') {
      return (
        <AccountInsightCommon
          id={props.accountInformation.id}
          insight={InsightPostCommon(
            'w-[285px]',
            snsType,
            ...insightValues,
            stringToInstagramPostType(props.postType ?? '')
          )}
          selected={`${props.accountInformation.postCount}回目`}
          body={props.accountInformation.mediaProps?.content.caption ?? ''}
          url={props.accountInformation.mediaProps?.content?.url ?? ''}
          mediaProps={props.accountInformation.mediaProps}
          snsType={snsType}
        />
      );
    } else if (tabKey == 'accoutPost' && (snsType === SnsType.X || snsType === SnsType.Youtube)) {
      return <></>;
    } else if (tabKey == 'accountInsight') {
      return (
        <AccountInsight
          insights={getAccountInsight(insight, snsType)}
          recentCount={influencerSnsAccount.posts.recentPosts.length}
          snsType={props.snsType ?? 'other'}
        />
      );
    } else if (tabKey == 'follower' || tabKey == 'subscriber') {
      return <FollowerAttributeCommon snsType={snsType} followerAttributes={followerAttributesValue} />;
    } else {
      return (
        <RecruitmentInformation
          enthusiasm={applyingComment?.influencerComment ?? ''}
          comments={applyingComment?.operatorComment ?? ''}
          introduction={accountPr?.characteristic ?? ''}
          strong={accountPr?.strongPoint ?? ''}
          achievements={accountPr?.actualResults ?? ''}
        />
      );
    }
  };

  // SNSアカウント詳細_アカウントインサイトのアイコン・文字表示内容取得部品(いいね数、エンゲージメント率等)
  const getAccountInsight = (
    snsAccountInsight:
      | SnsAccountInsightX
      | SnsAccountInsightTikTok
      | SnsAccountInsightYoutube
      | SnsAccountInsightInstagram
      | undefined,
    snsType: SnsType
  ): insightPair[] => {
    if (snsAccountInsight === undefined) {
      return [];
    }

    switch (snsType) {
      case SnsType.X || (modalTypeIdEmeraldPost && SnsType.X):
        return [
          {
            left: {
              icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フォロワー数',
              value: numberToString(xInsight.followers),
              className: 'w-[396px]',
            },
            right: {
              icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フォロー数',
              value: numberToString(xInsight.followings),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント率',
              value: `${numberToString(xInsight.engagementRate)}%`,
              className: 'w-[396px]',
            },
            right: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント数',
              value: numberToString(xInsight.engagements),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'いいね数（平均）',
              value: numberToString(xInsight.goodAvg),
              className: 'w-[396px]',
            },
            right: {
              icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
              label: '返信数（平均）',
              value: numberToString(xInsight.replyAvg),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <RepostIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'リポスト数（平均）',
              value: numberToString(xInsight.repostAvg),
              className: 'w-[396px]',
            },
            right: {
              icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'ポスト数',
              value: numberToString(xInsight.postCounts),
              className: 'w-[396px]',
            },
          },
        ];
      case SnsType.TikTok:
        return [
          {
            left: {
              icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フォロワー数',
              value: numberToString(tikTokInsight.followers),
              className: 'w-[396px]',
            },
            right: {
              icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フォロー数',
              value: numberToString(tikTokInsight.followings),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
              label: '再生回数',
              value: numberToString(tikTokInsight.play),
              className: 'w-[396px]',
            },
            right: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント率',
              value: `${numberToString(tikTokInsight.engagementRate)}%`,
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント数',
              value: numberToString(tikTokInsight.engagements),
              className: 'w-[396px]',
            },
            right: {
              icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'いいね数（平均）',
              value: numberToString(tikTokInsight.goodAvg),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'コメント数（平均）',
              value: numberToString(tikTokInsight.commentsAvg),
              className: 'w-[396px]',
            },
            right: {
              icon: <ShareIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'シェア数（平均）',
              value: numberToString(tikTokInsight.shareAvg),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
              label: '再生回数率',
              value: `${numberToString(tikTokInsight.playRate)}%`,
              className: 'w-[396px]',
            },
            right: {
              icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
              label: '投稿数',
              value: numberToString(tikTokInsight.postCounts),
              className: 'w-[396px]',
            },
          },
        ];
      case SnsType.Instagram || (modalTypeIdEmeraldPost && SnsType.Instagram):
        return [
          {
            left: {
              icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フォロワー数',
              value: numberToString(instagramInsight.followers),
              className: 'w-[396px]',
            },
            right: {
              icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フォロー数',
              value: numberToString(instagramInsight.followings),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <ShowIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'インプレッション数',
              value: numberToString(instagramInsight.impressions),
              className: 'w-[396px]',
            },
            right: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント率',
              value: `${numberToString(instagramInsight.engagementRate)}%`,
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント数',
              value: numberToString(instagramInsight.engagements),
              className: 'w-[396px]',
            },
            right: {
              icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'いいね数（平均）',
              value: numberToString(instagramInsight.goodAvg),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'コメント数（平均）',
              value: numberToString(instagramInsight.commentsAvg),
              className: 'w-[396px]',
            },
            right: {
              icon: <ShareIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'シェア数（平均）',
              value: numberToString(instagramInsight.shareAvg),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <BookmarkIcon width={24} height={24} fill="#FFFFFF" />,
              label: '保存数（平均）',
              value: numberToString(instagramInsight.preservationAvg),
              className: 'w-[396px]',
            },
            right: {
              icon: <UserIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'ユニークアカウント数',
              value: numberToString(instagramInsight.reach),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <UserIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'ユニークアカウント率',
              value: `${numberToString(instagramInsight.reachRate)}%`,
              className: 'w-[396px]',
            },
            right: {
              icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'フリークエンシー',
              value: numberToString(instagramInsight.frequency),
              className: 'w-[396px]',
            },
          },
          {
            left: {
              icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
              label: '投稿数',
              value: numberToString(instagramInsight.postCounts),
              className: 'w-[396px]',
            },
          },
        ];
      case SnsType.Youtube:
        return [
          {
            left: {
              icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'チャンネル登録者数',
              value: numberToString(youtubeInsight.subscribers),
              className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
            },
            right: {
              icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
              label: '視聴回数',
              value: numberToString(youtubeInsight.play),
              className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
            },
          },
          {
            left: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント率',
              value: `${numberToString(youtubeInsight.engagementRate)}%`,
              className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
            },
            right: {
              icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'エンゲージメント数',
              value: numberToString(youtubeInsight.engagements),
              className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
            },
          },
          {
            left: {
              icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
              label: '高評価数（平均）',
              value: numberToString(youtubeInsight.good),
              className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
            },
            right: {
              icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
              label: 'コメント数(平均)',
              value: numberToString(youtubeInsight.commentsAvg),
              className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
            },
          },
          {
            left: {
              icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
              label: '視聴回数率',
              value: `${numberToString(youtubeInsight.playRate)}%`,
              className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
            },
            right: {
              icon: <UploadIcon width={24} height={24} fill="#FFFFFF" />,
              label: '動画数',
              value: numberToString(youtubeInsight.movies),
              className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
            },
          },
        ];
      default:
        return [];
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">SNSアカウントの詳細</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-[1px]">
                  <div className="flex w-[1084px] flex-col gap-6 self-stretch">
                    {/* account information */}
                    <div className="flex h-[71px] items-start gap-[15px] self-stretch">
                      <img src={props.accountInformation.icon} alt="" className="h-16 w-16 rounded-full" />
                      <div className="flex flex-col items-start gap-[10px] self-stretch">
                        <div className="flex items-center gap-1">
                          <span className="text-sm font-medium text-gray-500">ID {props.accountInformation.id}</span>
                        </div>
                        <div className="flex items-center gap-[19px]">
                          <div className="flex items-center">
                            {starRating({ evaluation: evaluation?.evaluation ?? 0 })}
                            <div>
                              <span className="text-sm font-medium text-gray-500">{`(${evaluation?.evaluationCounts})`}</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-[2px]">
                            <span className="text-sm font-medium text-gray-500">SNS</span>
                            {snsIcon(snsType, 'h-[17.9px] w-[17.9px]')}
                          </div>
                          <div className="flex items-center gap-[6px]">
                            <div className="flex items-start gap-1">
                              <span className="text-sm font-medium text-gray-500">
                                {snsType === SnsType.Youtube ? 'チャンネル登録者' : 'フォロワー'}
                              </span>
                              <span className="text-sm font-medium text-gray-500">
                                {snsType === SnsType.Youtube
                                  ? numberToString(youtubeInsight?.subscribers)
                                  : getFollowers()}
                                人
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-500">補欠採用</span>
                            <Badge color="green" round="xl" className="px-[10px]">
                              {reservedString}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* tabs */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="inline-flex w-[1280px] items-start gap-8  border-b-[1px] border-gray-200">
                        {selectTab().map((tab: TabProps) => {
                          return (
                            <button
                              key={tab.key}
                              className={clsx('flex flex-col items-center justify-center')}
                              onClick={() => {
                                handleTabSelct(tab.key);
                              }}
                            >
                              <div
                                className={clsx(
                                  'flex items-center justify-center px-1 pb-4 pt-0',
                                  tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                )}
                              >
                                <span className="text-sm font-medium leading-5">{tab.name}</span>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {/* contents */}
                    {selectContent()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div>
              <button className="pl-[35.8px]" onClick={() => handeRightTab()}>
                <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

// 数値を文字列に変換する、存在しない場合は0を返す
function numberToString(value: number | null | undefined): string {
  value = value ?? 0;
  return value.toLocaleString();
}

// 星の評価を表示する
const starRating = ({ evaluation }: { evaluation: number }) => {
  const maxStars = 5;
  const fullStars = Math.min(evaluation, maxStars);
  const emptyStars = maxStars - fullStars;

  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(<StarIcon key={`full-${i}`} height={16} width={16} fill="#ECC94B" />);
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(<StarStrokeIcon key={`empty-${i}`} height={16} width={16} fill="#ECC94B" />);
  }

  return <div className="flex items-center gap-[3px]">{stars}</div>;
};
