import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementContentsApprovingTwitterImageView } from '../components/PRSTProductionManagementContentsApprovingTwitterImageView';

export const PRSTProductionManagementContentsApprovingTwitterImage = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementContentsApprovingTwitterImageView />
    </MainLayout>
  );
};
