import Auth0LockStatic, { Auth0Lock } from 'auth0-lock';

import AUTH0_CONFIG from '@/auth0-config';
import { getImageUrl } from '@/utils/image';
import { validationInputVoidCheck, validationMessageMinNumCheck } from '@/utils/inputMultiValidationCheck';
import { ValidationMessages } from '@/utils/message';
import './auth0-styles.css';
import storage from '@/utils/storage';

/**
 * ユーザー認証API
 */
export class AuthAPI {
  /**
   * Auth0Lock設定
   */
  lock: Auth0LockStatic;

  /**
   * Auth0Lock設定コンストラクタ
   * @param {string} container コンテナ要素
   */
  constructor(container: string, invitationQuery?: string) {
    console.log(
      'auth0 settings:',
      ' client id: ',
      AUTH0_CONFIG?.clientId,
      ' domain: ',
      AUTH0_CONFIG?.domain,
      ' redirect uri: ',
      AUTH0_CONFIG?.redirectUri
    );
    this.lock = new Auth0Lock(AUTH0_CONFIG?.clientId, AUTH0_CONFIG?.domain, {
      auth: {
        redirectUrl: invitationQuery ? `${AUTH0_CONFIG?.redirectUri}?${invitationQuery}` : AUTH0_CONFIG?.redirectUri,
        responseType: 'token id_token',
        params: {
          role: 'company_staff',
          state: new Date().getTime().toString(),
        },
        audience: AUTH0_CONFIG?.audience,
      },
      closable: false,
      defaultADUsernameFromEmailPrefix: false,
      language: AUTH0_CONFIG?.language,
      languageDictionary: {
        title: '- 企業様向けログイン -',
        signUpTitle: '',
        signUpTerms: `<a href='${AUTH0_CONFIG?.termsLink}' target='_new'>利用規約</a>および<a href='https://liddell.tokyo/privacy/' target='_new'>個人情報保護方針</a>に同意します。`,
      },
      theme: {
        logo: getImageUrl('assets/logo/row.png'),
        primaryColor: '#141730',
      },
      mustAcceptTerms: true,
      additionalSignUpFields: [
        {
          name: 'name',
          placeholder: 'お名前',
          storage: 'root',
          validator(name) {
            const isInputedValue = validationInputVoidCheck(name);
            const isLessLength = validationMessageMinNumCheck(name, 51);
            const hint = !isInputedValue
              ? ValidationMessages.inputMessage('お名前')
              : ValidationMessages.strMaxLenMessage('お名前', 50);
            return {
              valid: isInputedValue && isLessLength,
              hint: hint,
            };
          },
        },
        {
          name: 'companyName',
          placeholder: '企業名',
          validator(companyName) {
            const isInputedValue = validationInputVoidCheck(companyName);
            const isLessLength = validationMessageMinNumCheck(companyName, 51);
            const hint = !isInputedValue
              ? ValidationMessages.inputMessage('企業名')
              : ValidationMessages.strMaxLenMessage('企業名', 50);
            return {
              valid: isInputedValue && isLessLength,
              hint: hint,
            };
          },
        },
        {
          name: 'phoneNumber',
          placeholder: '電話番号',
          validator(phoneNumber) {
            const reg = /[0-9]{10,}/;
            const res = reg.test(phoneNumber);

            return {
              valid: res,
              hint: '半角数字のみを入力してください。（10桁以上）',
            };
          },
        },
      ],
      container,
    });
  }

  /**
   * ログイン要素を表示
   */
  login(loginType: string): void {
    this.lock.show({ initialScreen: loginType === 'login' ? 'login' : 'signUp' });
  }

  /**
   * ユーザー認証を確認
   * @returns {Promise<any>} 認証状態
   */
  isAuthenticated(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.lock.checkSession({}, (error, result) => {
        if (error) {
          reject(error);
          return;
        }

        const accessToken = result?.accessToken;
        if (accessToken) {
          storage.setToken(accessToken);
        } else {
          reject(new Error('AccessToken is missing or empty'));
          return;
        }
        resolve(result);
      });
    });
  }

  /**
   * ログアウト処理
   */
  logout() {
    this.lock.logout({
      returnTo: AUTH0_CONFIG?.returnTo,
    });
  }

  /**
   * ログイン要素を非表示
   */
  hide() {
    this.lock.hide();
  }
}

/**
 * メール確認画面のURLを取得
 */
// export function getConfirmEmailURL(): string {
//   return
// }
