import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import * as React from 'react';
import { Fragment } from 'react';

type itemProps = {
  icon: string;
  label: string;
  id: string;
  href?: string;
};

export type DropDownProps = React.InputHTMLAttributes<HTMLInputElement> & {
  icon: string;
  title?: string;
  items: itemProps[];
  selected: string;
};

export const DropDown = React.forwardRef<HTMLInputElement, DropDownProps>(({ className = '', ...props }, ref) => {
  return (
    <Menu as="div" className="relative inline-block text-left" ref={ref}>
      <div>
        <Menu.Button className="flex max-h-[32px] max-w-[32px] items-center">
          <img src={props.icon} alt="" className="aspect-square w-full rounded-md" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className={className}>
          {props.title && (
            <div className="flex flex-col items-start border-b border-gray-100 px-4 py-3">
              <p className="text-sm font-normal leading-5 text-gray-900">{props.title}</p>
            </div>
          )}
          <div className="py-1">
            {props.items.map((item: itemProps) => {
              return (
                <Menu.Item key={item.id}>
                  {({ active }) => (
                    <a
                      href={item.href}
                      className={clsx(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block py-2 text-sm')}
                    >
                      <div className="flex items-center gap-3 px-4">
                        <div
                          className={clsx(
                            item.id == props.selected
                              ? 'h-[38px] w-[38px] rounded-lg border-[1.5px] border-[#007CC2]'
                              : 'h-[38px] w-[38px]'
                          )}
                        >
                          {/* <img src={item.icon} alt="" className="h-full w-full p-[1.5px]" /> */}
                          <img
                            src={item.icon}
                            alt=""
                            className={clsx(
                              item.id == props.selected
                                ? 'h-full w-full rounded-md p-[1.5px]'
                                : 'h-full w-full rounded-md p-[3px]'
                            )}
                          />
                        </div>
                        <p className="text-sm font-normal leading-5">{item.label}</p>
                      </div>
                    </a>
                  )}
                </Menu.Item>
              );
            })}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
});

DropDown.displayName = 'DropDown';
