import { OtherSummary } from '@/api/other';
import { addComma, addCommaAmount } from '@/utils/format';

import { DescriptionItem } from './DescriptionItem';

type OverViewType = {
  isEdit: boolean;
  summary: OtherSummary;
  editFunc: () => void;
};

export const OverView = (props: OverViewType) => {
  return (
    <div className="h-[326+40px] w-[726.5px] gap-[16px] bg-white pl-[24px] pt-[40px]">
      <div className="flex h-[28px] w-[678.5px] justify-between">
        <div className="h-[28px] w-[344px] content-center">
          <span className="text-lg font-semibold leading-7 text-gray-500">案件概要</span>
        </div>
        {props.isEdit && (
          <button
            className="right-4 bg-transparent text-blue-500"
            onClick={() => {
              props.editFunc();
            }}
          >
            編集
          </button>
        )}
      </div>
      <DescriptionItem
        title="採用予定人数"
        value={props.summary.adoptionPlan ? addComma(props.summary.adoptionPlan) + '人' : '-'}
      />
      <DescriptionItem
        title="インフルエンサーへの報酬"
        value={props.summary.adoptionPlan ? addCommaAmount(props.summary.rewards) + '/月' : '-'}
      />
      <DescriptionItem title="歓迎条件" value={props.summary.genres ? props.summary.genres : '-'} />
      <DescriptionItem
        title="案件説明"
        value={props.summary.description ? props.summary.description : '-'}
        isUnderLine={true}
      />
    </div>
  );
};
