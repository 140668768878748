import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { GetTeamInvoiceOutput } from '@/api/team';
import { Button } from '@/components/Elements/Button';
import { Input } from '@/components/Elements/Input';
import { PostMemberInvite } from '@/hooks/PostTeamInvite';
import { isValidEmail } from '@/utils/inputMultiValidationCheck';
import { ValidationMessages } from '@/utils/message';

import { HumanProps } from './Dropdown';
export interface InviteErrorProps {
  modalError: boolean;
  planError: boolean;
  errorMessage: string;
  planErrorMessage: string;
}

export interface MemberInviteProps {
  open: boolean;
  mailAddress: string;
  displayMailAddress: string;
  invite: boolean;
  errorProps: InviteErrorProps;
  teamBillingInfo: GetTeamInvoiceOutput | null;
}

interface Props {
  memberInviteArgs: MemberInviteProps;
  setMemberInviteArgs: React.Dispatch<React.SetStateAction<MemberInviteProps>>;
  setMessageVal: React.Dispatch<
    React.SetStateAction<{
      successFlag: boolean;
      message: string;
    }>
  >;
  fetchData: () => Promise<void>;
  humanState: HumanProps[];
}

enum Planes {
  Light = 'light',
  Basic = 'basic',
  Enterprise = 'enterprise',
}

export const checkPlanConditionAddAction = (plan: string | undefined, memberListData: HumanProps[]): boolean => {
  const checkActiveUserNum = memberListData.filter(
    (member) =>
      (member.status === '' || member.status === null || member.status === 'pending') &&
      !checkLiddellMailAddress(member.mailAddress)
  ).length;
  const lightPlanCondition = plan === Planes.Light && checkActiveUserNum < 4;
  const basicPlanCondition = plan === Planes.Basic && checkActiveUserNum < 15;
  const enterprisePlanCondition = plan == Planes.Enterprise;
  return lightPlanCondition || basicPlanCondition || enterprisePlanCondition;
};

export const checkLiddellMailAddress = (mailAddress: string): boolean => {
  return mailAddress.includes('@liddell.tokyo');
};

export const checkPlanConditionChangeRole = (
  plan: string | undefined,
  memberListData: HumanProps[],
  option: string
): boolean => {
  const checkAdminNum = memberListData.filter(
    (member) => member.adminRole && !checkLiddellMailAddress(member.mailAddress)
  ).length;
  let lightPlanCondition = true;
  let basicPlanCondition = true;
  let enterprisePlanCondition = true;
  if (option == '管理者') {
    lightPlanCondition = plan === Planes.Light && checkAdminNum < 1;
    basicPlanCondition = plan === Planes.Basic && checkAdminNum < 5;
    enterprisePlanCondition = plan === Planes.Enterprise;
  }

  return lightPlanCondition || basicPlanCondition || enterprisePlanCondition;
};

export const MemberModal = (props: Props) => {
  const postProcess = async (memberListData: HumanProps[]): Promise<boolean> => {
    const plan = props.memberInviteArgs.teamBillingInfo?.plan;
    if (
      checkPlanConditionAddAction(plan, memberListData) ||
      checkLiddellMailAddress(props.memberInviteArgs.displayMailAddress)
    ) {
      await PostMemberInvite(props.memberInviteArgs.displayMailAddress, props.setMessageVal);
      console.log('L90: ', props.memberInviteArgs.displayMailAddress);
      console.log('L91: ', props.setMessageVal);
      return true;
    }

    return false;
  };

  const onCloseMemberInviteModal = async (isSave: boolean) => {
    const errorMessage =
      props.memberInviteArgs.displayMailAddress === ''
        ? ValidationMessages.inputMessage('メールアドレス')
        : !isValidEmail(props.memberInviteArgs.displayMailAddress)
        ? ValidationMessages.formatMessage('メールアドレス')
        : '';

    if (isSave) {
      // ここから下
      if (errorMessage !== '') {
        props.setMemberInviteArgs((prev) => {
          return {
            ...prev,
            errorProps: {
              ...prev.errorProps,
              modalError: true,
              errorMessage: errorMessage,
            },
          };
        });
        return;
      }

      const memberListData: HumanProps[] = props.humanState.filter(
        (member: { status: string | null }) =>
          member.status === null || member.status === 'pending' || member.status == ''
      );
      const postResult = await postProcess(memberListData);

      if (!postResult) {
        props.setMemberInviteArgs((prev) => ({
          ...prev,
          errorProps: {
            ...prev.errorProps,
            planError: true,
            planErrorMessage: ValidationMessages.overSendLimitMessage(),
          },
        }));
        return;
      }
    }

    props.setMemberInviteArgs((prev) => ({
      ...prev,
      open: false,
      displayMailAddress: '',
      errorProps: {
        modalError: false,
        planError: false,
        errorMessage: '',
        planErrorMessage: '',
      },
    }));
    await props.fetchData();
  };

  const handleChangeMailAdress = (mailAddress: string) => {
    if (isValidEmail(mailAddress)) {
      props.setMemberInviteArgs((prev) => {
        return { ...prev, modalError: false, errorMessage: '', planError: false, planErrorMessage: '' };
      });
    }

    props.setMemberInviteArgs((prev) => {
      return { ...prev, mailAddress: mailAddress, displayMailAddress: mailAddress };
    });
  };

  return (
    <Transition.Root show={props.memberInviteArgs.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onCloseMemberInviteModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[480px] overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[480px] sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <span className="sr-only">Close</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="#9CA3AF"
                    onClick={() => {
                      onCloseMemberInviteModal(false);
                    }}
                    className="cursor-pointer"
                  >
                    <path
                      d="M6.5 18.5L18.5 6.5M6.5 6.5L18.5 18.5"
                      stroke="#9CA3AF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[408px] text-lg font-medium leading-6 text-gray-900">
                      メンバーを招待する
                    </Dialog.Title>

                    <div className="flex flex-row justify-between pt-2 text-sm font-normal leading-5 text-gray-500">
                      招待したいメンバーのメールアドレスを入力してください。
                    </div>
                  </div>
                </div>

                <div className="pt-[24px]">
                  <label htmlFor="email" className="block text-sm font-semibold leading-5 text-gray-900">
                    メールアドレス
                  </label>
                  <div className="mt-2 w-[320px]">
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      width="320"
                      className="border-0 outline-none ring-0 focus:border-0 focus:outline-none"
                      isPlaceHolderRight={false}
                      placeholder="メールアドレス"
                      value={props.memberInviteArgs.displayMailAddress}
                      onChange={(e: any) => {
                        handleChangeMailAdress(e.target.value);
                      }}
                      isError={
                        props.memberInviteArgs.errorProps.modalError || props.memberInviteArgs.errorProps.planError
                      }
                    />
                  </div>
                </div>

                {props.memberInviteArgs.errorProps.modalError && (
                  <div className="mt-2">
                    <p className="text-sm font-normal leading-5 text-red-600" id="email-description">
                      {props.memberInviteArgs.errorProps.errorMessage}
                    </p>
                  </div>
                )}
                <div className="mt-5 gap-3 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    type="button"
                    className="mt-5 rounded-lg"
                    size="sm"
                    onClick={() => {
                      onCloseMemberInviteModal(true);
                    }}
                  >
                    <span className="text-sm font-medium leading-5">招待する</span>
                  </Button>

                  <Button
                    type="button"
                    className="mt-5 rounded-lg bg-white"
                    size="sm"
                    variant="white"
                    onClick={() => {
                      onCloseMemberInviteModal(false);
                    }}
                  >
                    <span className="text-sm font-medium leading-5 text-gray-700">キャンセル</span>
                  </Button>
                </div>
                {props.memberInviteArgs.errorProps.planError && (
                  <div className="mt-2">
                    <p className="flex justify-end text-sm font-normal leading-5 text-red-600">
                      {props.memberInviteArgs.errorProps.planErrorMessage}
                    </p>
                  </div>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
