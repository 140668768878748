import React from 'react';

/* eslint-disable */
type ScreenProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children: React.ReactNode;
  className?: string;
};

/**
 * ボタンコンポーネント
 */
export const Screen = React.forwardRef<HTMLDivElement, ScreenProps>(({ children, className = '' }, ref) => {
  let screenClassName = 'w-screen min-w-[1280px] mx-auto';

  if (className !== '') {
    screenClassName += ' ' + className;
  }

  return (
    <div ref={ref} className={screenClassName}>
      {children}
    </div>
  );
});
/* eslint-enable */
