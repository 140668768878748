import { useAccount } from './AccountContext';
import { AccountView } from './AccountView';
import { ProjectListView } from './ProjectListView';

export const Account = (): React.ReactElement => {
  const { accounts } = useAccount();

  return (
    <>
      <div className="flex bg-gray-50">
        <div className="ml-[5px] mt-[3px] h-[686px] w-[236px] border-r border-gray-200 pt-[24px]">
          <p className="ml-[16px] h-[16px] w-[98px] text-sm font-medium leading-4 text-[#000000]">アカウント一覧</p>
          <AccountView />
        </div>
        {accounts.length > 0 && <ProjectListView />}
      </div>
    </>
  );
};
