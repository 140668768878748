import { useState } from 'react';

import LeftPic from './assets/bx-chevron-left1.svg';
import ContentsMakeView from './ContentsMakeView';
import DotsDropdown from './DotsDropdown';
import ImageApproveView from './ImageApproveView';
import Step from './Step';

export default function MainView() {
  const [status, setStatus] = useState(2);
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [isContentsEdit, setIsContentsEdit] = useState(false);

  return (
    <div className="relative w-[1181px] bg-gray-50">
      {/* ヘッダー部分↓ */}
      <div className="h-[61px] w-[1181px] border-b-[1px] border-gray-200 ">
        <div className="flex w-[1149px] items-center p-[24px]">
          <img className="h-[24px] w-[24px]" src={LeftPic} alt="" />
          <p className="text-xs  text-gray-700">10月分 エメポスMVP施策</p>
          <p className="ml-[15px] text-base font-semibold leading-6  text-gray-700">No.0004</p>
          <p className="ml-[8px] w-[48px] whitespace-nowrap rounded-l  bg-red-100 text-xs font-medium leading-4 text-red-800">
            フィード
          </p>
          <p className="ml-[30px] whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
            投稿予定日：2021年2月12日(金) 18:00
          </p>
          <div className="ml-auto">
            <DotsDropdown />
          </div>
        </div>
      </div>
      {/* コンテンツ↓ */}
      <div className="w-[1181px] px-[24px] pt-[24px]">{Step(status)}</div>
      {status === 1 ? null : status === 2 ? (
        <ImageApproveView
          status={status}
          setStatus={setStatus}
          isImageEdit={isImageEdit}
          setIsImageEdit={setIsImageEdit}
        />
      ) : status === 3 ? (
        <ContentsMakeView
          status={status}
          setStatus={setStatus}
          isContentsEdit={isContentsEdit}
          setIsContentsEdit={setIsContentsEdit}
        />
      ) : null}
    </div>
  );
}
