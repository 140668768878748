import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import BasicInfoView from '../components/BasicInfoView';
export const BasicInfo = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.basicInfo,
        secondSideBarselected: secondSidebarSelect.basicInfo,
      }}
    >
      <BasicInfoView />
    </MainLayout>
  );
};
