import { DocumentTextIcon, PencilIcon, CalendarIcon, UsersIcon } from '@heroicons/react/24/outline';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectDetailHeader } from '@/api/janeJohn';
import { ReactComponent as BarIcon } from '@/assets/icons/bx-bar-chart-alt.svg';
import { ReactComponent as LeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { Button } from '@/components/Elements';

const tabs = [
  { name: '案件概要', href: 'outline_project', icon: DocumentTextIcon },
  { name: '募集内容詳細', href: 'detail_project', icon: PencilIcon },
  { name: 'スケジュール', href: 'schedule', icon: CalendarIcon },
  { name: '応募書', href: 'applicant', icon: UsersIcon },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(' ');
}

function formatDateYYYYMD(dateStr: string): string {
  if (!dateStr) return '';

  // 日付をパースする
  const date = new Date(dateStr);
  const year = date.getFullYear(); // 年を取得
  const month = date.getMonth() + 1; // 月を取得（getMonth()は0から始まるため+1する）
  const day = date.getDate(); // 日を取得

  // 日付をYYYY年M月D日形式でフォーマットする
  return `${year}年${month}月${day}日`;
}

export function PageHeader(
  projectType: string,
  queryStringRows: number,
  queryCurrentPage: number,
  queryStatus: string,
  queryDummySize: number,
  header: ProjectDetailHeader | undefined
): React.ReactElement {
  const navigate = useNavigate();
  const startedAt = header?.scheduleStartedAt ?? '';
  const endedAt = header?.scheduleEndedAt ?? '';
  return (
    <div>
      <div className="flex h-[60px] w-[1149px] flex-row pt-[10px]">
        <div className="cursor-pointer pl-[24px] pt-[16px]">
          <LeftIcon
            height={24}
            width={24}
            fill="#9CA3AF"
            onClick={() => {
              navigate(
                `/project_management/list?type=${projectType}&rows=${queryStringRows}&page=${queryCurrentPage}&filter=${queryStatus}&size=${queryDummySize}`
              );
            }}
          />
        </div>

        <div className="pl-[25.29px] pt-[5px]">
          <div className="h-[12px] w-[161px] text-xs font-normal leading-none">{header?.projectGroupName}</div>
          <div className="flex">
            <div className="pt-[4px] text-base font-bold leading-6">{header?.projectName}</div>
            <div className="pl-[8px] pt-[3px]">
              <span className="inline-flex items-center rounded bg-[#DBEAFE] px-2.5 py-0.5 text-xs font-medium text-[#1E40AF]">
                JANE JOHN
              </span>
            </div>
          </div>
        </div>

        <div className="h-[20px] content-center pl-[16px] pt-[13px]">
          <div className="text-sm font-normal leading-5 text-gray-500">
            実施期間：{formatDateYYYYMD(startedAt)} 〜 {formatDateYYYYMD(endedAt)}
          </div>
        </div>
        <div className="ml-auto pt-[5px]">
          <Button
            variant="white"
            size="md"
            disabled
            startIcon={<BarIcon height={20} width={20} fill="#D1D5DB" />}
            className="flex h-[38px] w-[158px]"
            style={{ padding: '0px' }}
          >
            <span className="text-sm font-medium leading-5">分析画面を開く</span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export function functionTab(current: string): React.ReactElement {
  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full rounded-md border-gray-300 focus:border-[#007CC2] focus:ring-[#007CC2]"
        >
          {tabs.map((tab) => (
            <option key={tab.href} selected={tab.href === current}>
              {tab.name}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8 pl-[40px]" aria-label="Tabs">
            {tabs.map((tab) => (
              <a
                key={tab.name}
                href={`#${tab.href}`}
                className={classNames(
                  tab.href == current
                    ? 'border-[#007CC2] text-[#007CC2]'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'flex w-[120px] flex-row items-center justify-center whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                )}
                aria-current={tab.href == current ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.href == current ? 'text-[#007CC2]' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{tab.name}</span>
              </a>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
}
