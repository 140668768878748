import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { ReactComponent as RightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { PROJECT_TYPES } from '@/config/index';

import { projectInformation, projectTableInformation } from '../../interfaces/projectInterface';
import { projectLabel, statusLabel, snsIcon } from '../labels/label';

interface projectTableProps {
  projects?: projectInformation[];
  projectType: string;
  tableInfo: projectTableInformation;
  setOpen: React.Dispatch<React.SetStateAction<{ id: string; flag: boolean }>>;
  setTableInformation?: React.Dispatch<React.SetStateAction<projectTableInformation>>;
}

interface tableHeaderProps {
  title: string;
  width: string;
  name: string;
}

export default function ProjectTable(props: projectTableProps): React.ReactElement {
  const navigate = useNavigate();
  const search = useLocation().search;
  console.log('L33: ', search);
  const headers = (): tableHeaderProps[] => {
    if (props.projectType === PROJECT_TYPES.EMERALD_POST) {
      return [
        { title: 'No', width: 'w-[88px]', name: 'no' },
        { title: 'プロジェクト', width: 'w-[250px]', name: 'project' },
        { title: '案件名', width: 'w-[174px]', name: 'projectName' },
        { title: 'タイプ', width: 'w-[165px]', name: 'projectType' },
        { title: 'ステータス', width: 'w-[118px]', name: 'status' },
        { title: '実施期間', width: 'w-[201px]', name: 'span' },
        { title: '', width: 'w-[80px]', name: 'copy' },
        { title: '', width: 'w-[57px]', name: 'arrow' },
      ];
    } else if (props.projectType === PROJECT_TYPES.PRST) {
      return [
        { title: 'No', width: 'w-[88px]', name: 'no' },
        { title: 'プロジェクト', width: 'w-[256px]', name: 'project' },
        { title: '案件名', width: 'w-[174px]', name: 'projectName' },
        { title: 'タイプ', width: 'w-[118px]', name: 'projectType' },
        { title: 'ステータス', width: 'w-[118px]', name: 'status' },
        { title: 'SNS', width: 'w-[89px]', name: 'sns' },
        { title: '実施期間', width: 'w-[159px]', name: 'span' },
        { title: '', width: 'w-[80px]', name: 'copy' },
        { title: '', width: 'w-[57px]', name: 'arrow' },
      ];
    } else if (props.projectType === PROJECT_TYPES.OTHER) {
      return [
        { title: 'No', width: 'w-[86px]', name: 'no' },
        { title: 'プロジェクト', width: 'w-[254px]', name: 'project' },
        { title: '案件名', width: 'w-[172px]', name: 'projectName' },
        { title: 'タイプ', width: 'w-[116px]', name: 'projectType' },
        { title: 'ステータス', width: 'w-[111px]', name: 'status' },
        { title: '実施期間', width: 'w-[247px]', name: 'span' },
        { title: '', width: 'w-[78px]', name: 'copy' },
        { title: '', width: 'w-[55px]', name: 'arrow' },
      ];
    } else {
      return [
        { title: 'No', width: 'w-[88px]', name: 'no' },
        { title: 'プロジェクト', width: 'w-[256px]', name: 'project' },
        { title: '案件名', width: 'w-[174px]', name: 'projectName' },
        { title: 'タイプ', width: 'w-[118px]', name: 'projectType' },
        { title: 'ステータス', width: 'w-[118px]', name: 'status' },
        { title: 'SNS', width: 'w-[89px]', name: 'sns' },
        { title: '実施期間', width: 'w-[159px]', name: 'span' },
        { title: '', width: 'w-[80px]', name: 'copy' },
        { title: '', width: 'w-[57px]', name: 'arrow' },
      ];
    }
  };

  // ヘッダーからwidthを取得する
  const getHeaderWidthByName = (name: string): string => {
    const header = headers().find((header) => header.name === name);
    if (header) {
      return header.width;
    } else {
      return '';
    }
  };
  const pages: number[] = [];
  const totalPages = Math.ceil(props.tableInfo.total / props.tableInfo.perPage);
  const pageNationCount = totalPages > 7 ? 7 : totalPages;
  const endpageNumber = pageNationCount + props.tableInfo.startPageNumber;
  for (let i = 0; i < pageNationCount; i++) {
    if (totalPages < props.tableInfo.startPageNumber + i) break;
    pages.push(props.tableInfo.startPageNumber + i);
  }

  const handleClick = (id: string) => {
    props.setOpen({ id, flag: true });
  };

  const handlePageFirst = () => {
    if (props.tableInfo.currentPage == 1) return;
    props.tableInfo.setStartPageNumber(1);
    props.tableInfo.setCurrentPage(1);
    handlePageParameter(1);
  };

  const handlePageDown = () => {
    if (props.tableInfo.currentPage == 1) return;
    if (props.tableInfo.currentPage == props.tableInfo.startPageNumber) {
      props.tableInfo.setStartPageNumber(props.tableInfo.startPageNumber - 1);
    }

    const page = props.tableInfo.currentPage - 1;

    props.tableInfo.setCurrentPage(page);
    handlePageParameter(page);
  };

  const handlePageUp = () => {
    if (props.tableInfo.currentPage == Math.ceil(props.tableInfo.total / props.tableInfo.perPage)) return;
    if (props.tableInfo.currentPage == endpageNumber - 1) {
      props.tableInfo.setStartPageNumber(props.tableInfo.startPageNumber + 1);
    }
    const page = props.tableInfo.currentPage + 1;
    props.tableInfo.setCurrentPage(page);
    handlePageParameter(page);
  };
  const handlePageLast = () => {
    if (props.tableInfo.currentPage == totalPages) return;
    if (totalPages - 7 > 0) {
      props.tableInfo.setStartPageNumber(totalPages - 6);
    } else {
      props.tableInfo.setStartPageNumber(1);
    }

    props.tableInfo.setCurrentPage(totalPages);
    handlePageParameter(totalPages);
  };

  const handlePageParameter = (page: number) => {
    const queryString = new URLSearchParams(search);
    queryString.set('page', page.toString());
    history.pushState(null, '', `?${queryString.toString()}`);
  };

  const handleRedirect = (projectId: string, status: string, sns = '') => {
    const queryString = `&page=${props.tableInfo.currentPage}&perPage=${props.tableInfo.perPage}&filter=${props.tableInfo.status}&size=${props.tableInfo.total}`;
    // TODO 遷移先のURLは現状の設定を範囲、変更する場合はここを修正する。
    if (props.projectType === PROJECT_TYPES.SPIRIT) {
      navigate(`/spirit?type=SPIRIT${queryString}&projectId=${projectId}&status=${status}&sns=${sns}`);
    } else if (props.projectType === PROJECT_TYPES.EMERALD_POST) {
      navigate(`/emerald_project?type=EmeraldPost&projectId=${projectId}&status=${status}${queryString}`);
    } else if (props.projectType === PROJECT_TYPES.PRST) {
      const prstQuery = `&projectId=${projectId}&status=${status}&sns=${sns}`;
      navigate(`/PRST_project?type=PRST${prstQuery}`);
    } else if (props.projectType === PROJECT_TYPES.JANE_JOHN) {
      navigate(`/jane_john?type=JANEJOHN&projectId=${projectId}&status=${status}&sns=${sns}${queryString}`);
    } else if (props.projectType === PROJECT_TYPES.OTHER) {
      navigate(`/other_project?type=OTHER&projectId=${projectId}&status=${status}`);
    }
  };

  const replaceDate = (date: string) => {
    const newDate = date.replace(/-/g, '/');
    return newDate;
  };

  return (
    <div className="mt-8 flow-root pl-4">
      <div className="-mx-4 -my-2 w-[1133px] ">
        <div className="inline-block min-w-full rounded-lg border align-middle">
          <table className="w-[1133px] divide-y divide-gray-200 rounded-t-lg bg-white">
            <thead className="flex">
              <tr>
                {headers().map((header: tableHeaderProps, index: number) => {
                  return (
                    <th key={index} scope="col" className={clsx('sticky px-6 py-4 text-left', header.width)}>
                      <span className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        {header.title}
                      </span>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="flex h-[413px] w-[1133px] flex-col  divide-y divide-gray-200 overflow-x-hidden  overflow-y-scroll bg-white">
              {props.projects && props.projects.length ? (
                props.projects.map((project: projectInformation, index: number) => (
                  <tr key={index} className="cursor-pointer hover:bg-gray-100">
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            if (project.projectType === PROJECT_TYPES.OTHER) {
                              handleRedirect(project.projectId, project.status);
                            } else {
                              handleRedirect(project.projectId, project.status, project.sns);
                            }
                          } else {
                            handleRedirect('', '');
                          }
                        }}
                        className={clsx('h-[72px]', getHeaderWidthByName('no'))}
                      >
                        <div className="flex items-center">
                          <div className="px-6 py-4">
                            <div className="text-sm font-normal leading-5 text-gray-500">{project.no}</div>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            if (project.projectType === PROJECT_TYPES.OTHER) {
                              handleRedirect(project.projectId, project.status);
                            } else {
                              handleRedirect(project.projectId, project.status, project.sns);
                            }
                          } else {
                            handleRedirect('', '', '');
                          }
                        }}
                        className={clsx('h-[72px] text-left', getHeaderWidthByName('project'))}
                      >
                        <div className="flex items-start">
                          <div className="line-clamp-2 px-6 py-4">
                            <span className="line-clamp-2 break-words text-sm font-medium leading-5 text-gray-900">
                              {project.project}
                            </span>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            if (project.projectType === PROJECT_TYPES.OTHER) {
                              handleRedirect(project.projectId, project.status);
                            } else {
                              handleRedirect(project.projectId, project.status, project.sns);
                            }
                          } else {
                            handleRedirect('', '', '');
                          }
                        }}
                        className={clsx('h-[72px] text-left', getHeaderWidthByName('projectName'))}
                      >
                        <div className="flex items-start">
                          <div className="line-clamp-2 px-6 py-4">
                            <span className="line-clamp-2  break-words text-sm font-medium leading-5 text-gray-900">
                              {project.projectName}
                            </span>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            if (project.projectType === PROJECT_TYPES.OTHER) {
                              handleRedirect(project.projectId, project.status);
                            } else {
                              handleRedirect(project.projectId, project.status, project.sns);
                            }
                          } else {
                            handleRedirect('', '', '');
                          }
                        }}
                        className={clsx(
                          'flex h-[72px] items-center justify-items-start pl-4',
                          getHeaderWidthByName('projectType')
                        )}
                      >
                        {projectLabel(project.projectType)}
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            if (project.projectType === PROJECT_TYPES.OTHER) {
                              handleRedirect(project.projectId, project.status);
                            } else {
                              handleRedirect(project.projectId, project.status, project.sns);
                            }
                          } else {
                            handleRedirect('', '', '');
                          }
                        }}
                        className={clsx('h-[72px] pl-[23px]', getHeaderWidthByName('status'))}
                      >
                        {statusLabel(project.status, project.projectType)}
                      </button>
                    </td>
                    {(props.projectType === PROJECT_TYPES.SPIRIT ||
                      props.projectType === PROJECT_TYPES.PRST ||
                      props.projectType === PROJECT_TYPES.JANE_JOHN) && (
                      <td>
                        <button
                          onClick={() => {
                            if (project.projectId) {
                              handleRedirect(project.projectId, project.status, project.sns);
                            } else {
                              handleRedirect('', '', '');
                            }
                          }}
                          className="h-[72px] w-[72px]"
                        >
                          <div className="py-4 pl-2">{snsIcon(project.sns ? project.sns : 'instagram')}</div>
                        </button>
                      </td>
                    )}
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            if (project.projectType === PROJECT_TYPES.OTHER) {
                              handleRedirect(project.projectId, project.status);
                            } else {
                              handleRedirect(project.projectId, project.status, project.sns);
                            }
                          } else {
                            handleRedirect('', '', '');
                          }
                        }}
                        className={clsx('h-[72px]', getHeaderWidthByName('span'))}
                      >
                        <div className="flex items-start">
                          <div className="inline-block px-6 py-4 text-left">
                            <span className="text-sm font-normal leading-5 text-gray-500">
                              {project.start && project.end ? (
                                <>
                                  {replaceDate(project.start)} 〜 {replaceDate(project.end)}
                                </>
                              ) : (
                                '-'
                              )}
                            </span>
                          </div>
                        </div>
                      </button>
                    </td>
                    <td>
                      <button
                        onClick={() => {
                          if (project.projectId) {
                            handleClick(project.projectId);
                          } else {
                            handleClick('');
                          }
                        }}
                        className={clsx('h-[72px] whitespace-nowrap px-3 py-5 text-sm', getHeaderWidthByName('copy'))}
                      >
                        <span className="text-sm font-normal leading-5  text-[#007CC2]">複製</span>
                      </button>
                    </td>
                    <td>
                      <div className={clsx('whitespace-nowrap px-3 py-5 text-sm', getHeaderWidthByName('arrow'))}>
                        <RightIcon
                          height={24}
                          width={24}
                          fill="#9CA3AF"
                          onClick={() => {
                            if (project.projectId) {
                              if (project.projectType === PROJECT_TYPES.OTHER) {
                                handleRedirect(project.projectId, project.status);
                              } else {
                                handleRedirect(project.projectId, project.status, project.sns);
                              }
                            } else {
                              handleRedirect('', '', '');
                            }
                          }}
                        ></RightIcon>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={headers().length}>
                    <div className="flex h-[410px] w-[1133px] items-center justify-center">
                      <span className="text-base font-normal leading-6 text-gray-500">まだ案件がありません。</span>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {props.projects && props.projects.length ? (
            <div className="flex items-center justify-between rounded-b-lg border-t border-gray-200 bg-white px-4 py-3 sm:px-6">
              <div className="flex flex-1 justify-between sm:hidden">
                <a
                  href="#"
                  className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Previous
                </a>
                <a
                  href="#"
                  className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Next
                </a>
              </div>
              <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
                <div className="flex flex-row items-center gap-2">
                  <span className="text-xs font-normal leading-4 text-gray-500">１ページあたりの行数:</span>
                  <div className="text-xs font-normal leading-4 sm:col-span-3">
                    <select
                      id="perPage"
                      name="perPage"
                      className="border-none text-xs font-normal leading-4 text-gray-500"
                      defaultValue={props.tableInfo.perPage}
                      onChange={(e) => {
                        props.tableInfo.setPerPage(+e.target.value);
                        props.tableInfo.setStartPageNumber(1);
                        props.tableInfo.setCurrentPage(1);
                      }}
                    >
                      <option>30</option>
                      <option>50</option>
                      <option>100</option>
                    </select>
                  </div>
                  <span className="text-sm text-gray-500">
                    {(props.tableInfo.currentPage - 1) * props.tableInfo.perPage + 1} -
                    {(props.tableInfo.currentPage - 1) * props.tableInfo.perPage + props.tableInfo.perPage} of{' '}
                    {props.tableInfo.total}
                  </span>
                </div>
                {props.tableInfo.total > props.tableInfo.perPage && (
                  <div>
                    <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                      <button
                        onClick={handlePageFirst}
                        className={clsx(
                          'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                          props.tableInfo.currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        )}
                      >
                        <ChevronDoubleLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill={props.tableInfo.currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                        />
                      </button>
                      <button
                        onClick={handlePageDown}
                        className={clsx(
                          'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                          props.tableInfo.currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        )}
                      >
                        <ChevronLeftIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill={props.tableInfo.currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                        />
                      </button>
                      {pages.map((page) => {
                        if (page == props.tableInfo.currentPage) {
                          return (
                            <button
                              key={page}
                              className="relative z-10 inline-flex items-center border border-[#007CC2] bg-[#E0F4FF] px-4 py-2"
                            >
                              <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                            </button>
                          );
                        } else {
                          return (
                            <button
                              key={page}
                              className="relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                              onClick={() => {
                                props.tableInfo.setCurrentPage(page);
                                console.log(props.tableInfo.currentPage);
                                handlePageParameter(page);
                              }}
                            >
                              <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                            </button>
                          );
                        }
                      })}
                      <button
                        onClick={handlePageUp}
                        className={clsx(
                          'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                          props.tableInfo.currentPage == totalPages
                            ? ''
                            : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        )}
                      >
                        <ChevronRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill={props.tableInfo.currentPage == totalPages ? '#D1D5DB' : '#6B7280'}
                        />
                      </button>
                      <button
                        onClick={handlePageLast}
                        className={clsx(
                          'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                          props.tableInfo.currentPage == totalPages
                            ? ''
                            : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                        )}
                      >
                        <ChevronDoubleRightIcon
                          className="h-5 w-5"
                          aria-hidden="true"
                          fill={props.tableInfo.currentPage == totalPages ? '#D1D5DB' : '#6B7280'}
                        />
                      </button>
                    </nav>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
