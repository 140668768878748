import { GetInfluencerSnsAccountOutput } from '@/api/influencer';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import NoImage from '@/assets/images/NoImage.png';
import { Stat } from '@/components/Elements/Stat';
import { formatNumber } from '@/utils/format';

import { PostInfoModalProps, AppealPostsProps, RecentPostsProps } from '../../Youtube/YoutubeAll_Interface';
import Modal from '../Modal';

interface modalInfoAllProps {
  appealPosts: AppealPostsProps[];
  recentPosts: RecentPostsProps[];
  postinfoModal: PostInfoModalProps;
  influencerInfo?: GetInfluencerSnsAccountOutput;
}

let applealPostsFlag = false;

export default function ApplicantInfo(props: modalInfoAllProps) {
  const handleOpen = (index: number, kind: string, isDeleteArrow?: boolean) => {
    applealPostsFlag = kind === 'appeal';
    props.postinfoModal.setModalIndex(index);
    props.postinfoModal.setPostType(kind);
    props.postinfoModal.setModalOpen(true);
    if (isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(true);
    } else if (!isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(false);
    }
  };

  let youtube_modal_element_component: JSX.Element[];

  if (props.postinfoModal.postType === 'appeal') {
    youtube_modal_element_component = [
      <Stat
        key={0}
        icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
        lable="視聴回数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.viewCounts || 0)}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.appealPosts[props.postinfoModal.modalindex]?.engagementRate?.toFixed(2) + '%' || '0%'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.engagementCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="高評価数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.likeCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,

      <Stat
        key={4}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="コメント数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.commentCounts || 0)}
        className="h-[100px] w-[300px] rounded-lg  border border-slate-200"
      />,
    ];
  } else {
    youtube_modal_element_component = [
      <Stat
        key={0}
        icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
        lable="視聴回数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.viewCounts || 0)}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.recentPosts[props.postinfoModal.modalindex]?.engagementRate?.toFixed(2) + '%' || '0%'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.engagementCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="高評価数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.likeCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={4}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="コメント数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.commentCounts || 0)}
        className="h-[100px] w-[300px] rounded-lg  border border-slate-200"
      />,
    ];
  }

  function calculateDaysAgo(postedAt: string | null): string {
    if (!postedAt) return 'Unknown'; // nullの場合の処理

    // 日付フォーマットが正しいことを確認
    const postDate = new Date(postedAt.replace(' ', 'T'));
    const currentDate = new Date();

    // 両方のタイムスタンプの差をミリ秒で取得
    const diffTime = Math.abs(currentDate.getTime() - postDate.getTime());

    // ミリ秒を日に変換
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return `${diffDays}`;
  }

  return (
    <>
      <div className="w-[889px] pt-[10px]">
        {/* ボタンの css */}

        <>
          <div className="text-sm font-semibold leading-5 text-gray-900">アピール投稿</div>
          <div>
            <div className="flex gap-[6px] pl-[40px] pt-[16px]">
              {new Array(props.appealPosts?.length).fill(0).map((value1, index1) => {
                return (
                  <>
                    <div role="presentation" onClick={() => handleOpen(index1, 'appeal', true)} className="px-[20px]">
                      <div className="h-[160px] w-[240px]">
                        <img
                          src={props.appealPosts[index1]?.postImage || NoImage}
                          alt=""
                          className="h-[100%] w-[100%] cursor-pointer object-cover"
                        />
                      </div>
                      <div className="flex gap-1 pt-[8px]">
                        <div className="whitespace-pre-wrap text-sm font-normal leading-5 text-gray-700">
                          {props.appealPosts[index1]?.title ? (
                            <span className="block w-[100%]">
                              {props.appealPosts[index1]?.title?.replace(/\n/g, '')}
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                      <div>
                        <div className="text-sm font-semibold leading-5">{`${new Intl.NumberFormat('ja-JP').format(
                          props.appealPosts[index1]?.viewCounts ?? 0
                        )}回視聴・${calculateDaysAgo(props.appealPosts[index1]?.postedAt)}日前`}</div>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>

            <div className="pt-[40px]">
              {/* <div className="text-sm font-semibold leading-5 text-gray-900">{`直近の${props.recentPosts?.length}投稿`}</div> */}
              <div className="text-sm font-semibold leading-5 text-gray-900">直近の9投稿</div>
              <div className="px-[20px]">
                <div>
                  {new Array(Math.ceil(props.recentPosts?.length / 3)).fill(0).map((_, rowIndex) => (
                    <div key={rowIndex} className="flex gap-[6px] pt-4">
                      {Array.from(Array(3).keys()).map((_, colIndex) => {
                        const postIndex = rowIndex * 3 + colIndex;
                        const post = props.recentPosts?.[postIndex];
                        if (post) {
                          console.log(post);
                          return (
                            <div
                              key={postIndex}
                              role="presentation"
                              onClick={() => handleOpen(postIndex, 'recent')}
                              className="px-[20px]"
                            >
                              <div className="h-[160px] w-[240px]">
                                <img
                                  src={post.postImage || NoImage}
                                  alt=""
                                  className="h-[100%] w-[100%] cursor-pointer object-cover"
                                />
                              </div>
                              <div className="pt-4">
                                <div className="w-[240px] whitespace-pre-wrap text-sm font-normal leading-5 text-gray-700">
                                  {post.title ? (
                                    <span className="block w-[100%]">{post.title?.replace(/\n/g, '')}</span>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              </div>
                              <div>
                                <div className="pb-8 text-sm font-semibold leading-5">{`${new Intl.NumberFormat(
                                  'ja-JP'
                                ).format(post?.viewCounts ?? 0)}回視聴・${calculateDaysAgo(post?.postedAt)}日前`}</div>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>

        {/* モーダル */}
        {props.postinfoModal.modalOpen && (
          <div>
            <Modal
              open={props.postinfoModal.modalOpen}
              setOpen={props.postinfoModal.setModalOpen}
              accountInfo={applealPostsFlag ? props.appealPosts : props.recentPosts}
              modalIndex={props.postinfoModal.modalindex}
              setModalIndex={props.postinfoModal.setModalIndex}
              Component_Element={youtube_modal_element_component}
              sns_name="youtube"
              deleteFlag={props.postinfoModal.deleteFlag}
            />
          </div>
        )}
      </div>
    </>
  );
}
