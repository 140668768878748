import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { apiRequest } from '@/api/ApiUtils';
import {
  DefaultApi,
  PostTeamJoinInput,
  PostTeamJoinOutput,
  GetTeamsOutput,
  GetTeamOutput,
  UploadedImageOutput,
  GetTeamInvoiceOutput,
} from '@/api/team';
import {
  CreateTeamInfoRequest,
  CreateTeamInfoResponse,
  // FetchTeamInfoResponse,
  // FetchTeamsInfoResponse,
  TeamCheckoutRequest,
  TeamCheckoutResponse,
  TeamInviteRequest,
  TeamInviteResponse,
  UpdateTeamInfoRequest,
  UpdateTeamInfoResponse,
} from '@/types/TeamInfo';
import { axiosInstance } from '@/utils/axiosInstance';
const api = new DefaultApi(undefined, undefined, axiosInstance);

// チーム一覧取得API
export const fetchTeamsInfo = async (): Promise<GetTeamsOutput> => {
  try {
    const response: AxiosResponse<GetTeamsOutput> = await api.getTeams();
    return response.data as GetTeamsOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// チーム詳細取得API
export const fetchTeamInfo = async (teamId: string): Promise<GetTeamOutput> => {
  try {
    const response: AxiosResponse<GetTeamOutput> = await api.getTeam(teamId);
    return response.data as GetTeamOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// チーム作成API
export const createTeamInfo = (request: CreateTeamInfoRequest): Promise<CreateTeamInfoResponse> => {
  return apiRequest<CreateTeamInfoResponse, CreateTeamInfoRequest>(
    '/teams',
    { method: 'POST', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// チーム更新API
export const updateTeamInfo = (request: UpdateTeamInfoRequest): Promise<UpdateTeamInfoResponse> => {
  return apiRequest<UpdateTeamInfoResponse, UpdateTeamInfoRequest>(
    '/teams',
    { method: 'PATCH', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// チーム参加API
export const teamJoin = async (teamId: string, request: PostTeamJoinInput): Promise<PostTeamJoinOutput> => {
  try {
    const response: AxiosResponse<PostTeamJoinOutput> = await api.postTeamJoin(teamId, request);
    return response.data as PostTeamJoinOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// チーム招待API
export const teamInvite = (request: TeamInviteRequest): Promise<TeamInviteResponse> => {
  return apiRequest<TeamInviteResponse, TeamInviteRequest>(
    '/teams/invite',
    { method: 'POST', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// チーム切り替えAPI
export const teamCheckout = (request: TeamCheckoutRequest): Promise<TeamCheckoutResponse> => {
  return apiRequest<TeamCheckoutResponse, TeamCheckoutRequest>(
    '/teams/checkout',
    { method: 'POST', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// チーム画像アップロードAPI
export const uploadTeamImage = async (file: File): Promise<UploadedImageOutput> => {
  try {
    const response: AxiosResponse<UploadedImageOutput> = await api.postTeamUploadImage(file);
    return response.data as UploadedImageOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

//チーム請求情報取得API
export const fetchTeamInvoiceInfo = async (): Promise<GetTeamInvoiceOutput> => {
  try {
    const response: AxiosResponse<GetTeamInvoiceOutput> = await api.getTeamInvoice();
    return response.data as GetTeamInvoiceOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
