import Star from '../Pic/star.png';
import NoStar from '../Pic/star_no_color.png';

export type EvaluationCellProps = {
  evaluation: number;
  evaluationCount: number;
  style?: string;
};

const DISPLAY_EVALUATION_COUNT = 5;
export const EvaluationCell = (props: EvaluationCellProps) => {
  const stars = Array.from({ length: DISPLAY_EVALUATION_COUNT }, (_, index) => (
    <img
      key={index}
      src={index < props.evaluation ? Star : NoStar}
      alt={index < props.evaluation ? 'star' : 'no star'}
      className="h-[16px] w-[16px]"
    />
  ));

  const style = props.style ? props.style : 'flex justify-center px-[24px]';

  return (
    <div className={style}>
      {stars}
      <span className="ml-[5px]">{`(${props.evaluationCount})`}</span>
    </div>
  );
};
