import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter as Router } from 'react-router-dom';

import { Button } from '@/components/Elements/Button';
import queryClient from '@/lib/react-query';

function ErrorFallback(): React.ReactElement {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <h1 className="mb-4 text-3xl">エラーが発生しました</h1>
      <p>
        <Button variant="primary" onClick={() => window.location.assign(window.location.origin)}>
          リロード
        </Button>
      </p>
    </div>
  );
}

type AppProviderProps = {
  children: React.ReactNode;
};

export default function AppProvider({ children }: AppProviderProps): React.ReactElement {
  return (
    <React.Suspense
      fallback={
        <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
          <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-l-gray-500" />
        </div>
      }
    >
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HelmetProvider>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'test' && <ReactQueryDevtools />}
            <Router>{children}</Router>
          </QueryClientProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
}
