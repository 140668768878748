import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ROUTES } from '@/config/path';
import { secondSidebarSelect } from '@/config/sidebar';
import { AuthAPI } from '@/lib/auth';
import storage from '@/utils/storage';

interface sidebarProps {
  selected: string;
}
export function SecondSideBarSetting(props: sidebarProps) {
  const handleLogout = () => {
    storage.clearToken();
    const auth: AuthAPI = new AuthAPI('auth0Container');
    auth.logout();
  };
  return (
    <>
      <div className="top-0 h-screen w-[196px] border-r border-gray-200 bg-gray-100">
        <div className="h-[432px] w-[196px]">
          <div className="w-[196px] border-b border-gray-200 p-[16px]">
            <p className="text-lg font-bold leading-7 text-[#000000]">設定</p>
          </div>
          <div className="w-[196px] px-[8px] pt-[16px]">
            <div className="mb-[4px] p-[8px]">
              <p className="text-[14px] font-semibold leading-5 text-gray-500">チーム</p>
            </div>
            <Link to={ROUTES.TEAM_BASIC_INFO}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.basicInfo ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">基本情報</p>
              </div>
            </Link>
            <Link to={ROUTES.MEMBER_ADMIN}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.member ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">メンバー</p>
              </div>
            </Link>
            <Link to={ROUTES.PLAN_SELECT}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.plan ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">プラン</p>
              </div>
            </Link>
            <Link to={ROUTES.BILLING}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.billing ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">請求管理</p>
              </div>
            </Link>
            <Link to={ROUTES.ACCOUNT_SETTING}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[8px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.personal ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="text-[14px] font-semibold leading-5 text-gray-500">個人アカウント</p>
              </div>
            </Link>
            <Link to={ROUTES.TERMS_OF_SERVICE} target="_blank">
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[8px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]'
                )}
              >
                <p className="text-[14px] font-normal leading-5 text-[#007CC2]">利用規約</p>
              </div>
            </Link>
            <Link to={ROUTES.PRIVACY_POLICY} target="_blank">
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[8px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]'
                )}
              >
                <p className="text-[14px] font-normal leading-5 text-[#007CC2]">プライバシーポリシー</p>
              </div>
            </Link>
            <button className="border-0 bg-transparent px-0  shadow-none drop-shadow-none" onClick={handleLogout}>
              <div className="mb-[4px] p-[8px] hover:rounded-md hover:bg-[#141730]/[.08]">
                <p className="text-[14px] font-medium leading-5 text-[#007CC2]">ログアウト</p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
