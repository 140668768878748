import React from 'react';

import { Input } from '@/components/Elements/Input/Input';
import { isMorehanValue, validationHalfWidthCheck, validationIntegerCheck } from '@/utils/inputMultiValidationCheck';
import { MessageNewPlanString } from '@/utils/message';

import period from '../../assets/period.png';
import { FormProps } from '../../interfaces/project';
import { Recomend } from '../Recomend';

export default function Priod(props: FormProps): React.ReactElement {
  const lowest_times = 6;
  const standard_times = 12;
  const recommend_times = 24;

  const validationCheck = (value: string) => {
    let isError = false;
    let errorMessage = '';
    let setValue: number | undefined = undefined;
    if (!validationHalfWidthCheck(value) || !validationIntegerCheck(Number(value))) {
      isError = true;
      errorMessage = MessageNewPlanString.integralMessage;
      props.setPayload((state) => {
        return {
          ...state,
          isPeriodError: true,
          periodErrorMessage: MessageNewPlanString.integralMessage,
          period: undefined,
        };
      });
    } else if (!isMorehanValue(+value, lowest_times)) {
      isError = true;
      errorMessage = MessageNewPlanString.lowestPeriodMessage;
      setValue = +value;
    } else {
      setValue = value == '' ? undefined : +value;
    }
    props.setPayload((state) => {
      return {
        ...state,
        isPeriodError: isError,
        periodErrorMessage: errorMessage,
        period: setValue,
      };
    });
  };

  return (
    <div>
      <div className="mx-auto flex w-[800px] flex-col items-center gap-16">
        <div className="pt-12">
          {Recomend({
            src: period,
            title: '推奨期間',
            value: recommend_times.toString(),
            unit: 'ヶ月',
            description: '推奨期間は他社を含めた過去の案件実施の実績に基づいて算出されています。',
          })}
        </div>
      </div>

      <div className="mx-auto flex w-[800px] flex-col items-center  gap-y-[20px] pt-[64px] text-xs font-normal leading-5">
        <span className="text-xs font-normal leading-5 text-gray-400">最低期間:{lowest_times}ヶ月</span>
        <span className="text-xs font-normal leading-5 text-gray-400">標準期間:{standard_times}ヶ月</span>
        <span className="text-xs font-normal leading-5 text-blue-500">推奨期間:{recommend_times}ヶ月</span>
      </div>

      <div className="mx-auto flex w-[800px] items-center justify-center gap-4 pt-[64px] text-gray-600">
        <span className="w-auto text-sm font-normal leading-8">予定している期間</span>
        <span>
          <span>
            <Input
              type="text"
              className="h-[48px] gap-2 rounded border-gray-300 text-2xl text-gray-500 outline-none focus:border-white"
              onChange={(e: any) => {
                validationCheck(e.target.value);
              }}
              value={props.payload.period == undefined ? '' : props.payload.period.toString()}
              width="64px"
              isError={props.payload.isPeriodError}
            ></Input>
          </span>
        </span>
        <span className="text-sm font-normal leading-8 ">ヶ月</span>
      </div>
      {props.payload.isPeriodError && (
        <span className="m-auto flex w-[800px] justify-center pb-0 pl-[100px] pt-[6px] text-red-400">
          {props.payload.periodErrorMessage}
        </span>
      )}
    </div>
  );
}
