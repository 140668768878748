import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementProductListView } from '../components/PRSTProductionManagementProductListView';

export const PRSTProductionManagementProductList = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementProductListView />
    </MainLayout>
  );
};
