import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { PROJECT_TYPES } from '@/config';
import { scheduleToString } from '@/utils/format';

type PageHeaderProps = {
  projectScheduleStartedAt: string;
  projectScheduleEndedAt: string;
};

export function PageHeader(
  projectGroupName: string,
  projectName: string,
  projectScheduleDate: PageHeaderProps
): React.ReactElement {
  const navigate = useNavigate();

  const projectTextOmission = (text: string) => {
    let newText = '';
    const textLength = 50;

    if (text.length > textLength) {
      newText = text?.substring(0, textLength) + '…';
      return newText;
    }
    return text;
  };

  return (
    <div>
      <div className="flex h-[60px] w-[1149px] flex-row pt-[10px]">
        <div className="cursor-pointer pl-[24px] pt-[16px]">
          <LeftIcon
            height={24}
            width={24}
            fill="#9CA3AF"
            onClick={() => {
              navigate(`/project_management/list?type=${PROJECT_TYPES.SPIRIT}`);
            }}
          />
        </div>

        <div className="pl-[25.29px] pt-[5px]">
          <div className="h-[12px] w-[161px] whitespace-nowrap text-xs font-normal leading-none">
            {projectTextOmission(projectGroupName)}
          </div>
          <div className="flex">
            <div className="whitespace-nowrap pt-[4px] text-sm font-bold leading-6">
              {projectTextOmission(projectName)}
            </div>
            <div className="pl-[8px] pt-[3px]">
              <span className="inline-flex items-center rounded bg-pink-100 px-2.5 py-0.5 text-xs font-medium text-pink-800">
                SPIRIT
              </span>
            </div>
          </div>
        </div>

        <div className="ml-auto h-[20px] content-center pl-[16px] pt-[13px]">
          <div className="text-sm font-normal leading-5 text-gray-500">
            実施期間：
            {scheduleToString(projectScheduleDate.projectScheduleStartedAt, projectScheduleDate.projectScheduleEndedAt)}
          </div>
        </div>
      </div>
    </div>
  );
}
