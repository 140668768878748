import clsx from 'clsx';
import * as React from 'react';

export type StatProps = React.HTMLAttributes<HTMLDivElement> & {
  icon: React.ReactElement<any, any>;
  lable: string;
  value: string;
};

export const Stat = React.forwardRef<HTMLDivElement, StatProps>(({ className = '', ...props }, ref) => {
  return (
    <div ref={ref} className={clsx('flex flex-col items-start rounded-lg bg-white shadow-sm', className)} {...props}>
      <div className="flex items-center gap-5 self-stretch p-6">
        <div className="flex items-center justify-center rounded-md bg-[#007CC2] p-3">{props.icon}</div>
        <div className="flex flex-1 flex-col items-start">
          <span className="self-stretch text-sm font-medium leading-5 text-gray-500">{props.lable}</span>
          <div className="flex gap-2">
            <span className="text-2xl font-semibold leading-8 text-gray-900">{props.value}</span>
          </div>
        </div>
      </div>
    </div>
  );
});

Stat.displayName = 'BaStatdge';
