import React from 'react';

// import facebook from '@/assets/facebook.png';
import EMERALDPOST_Mark from '@/assets/EMERALDPOST_Mark_RGB.svg';
//import { ReactComponent as SpeakerPhoneIcon } from '@/assets/icons/speakerphone.svg';
import instagram from '@/assets/instagram.png';
import JANEJOHN_Mark from '@/assets/JANEJOHN_Mark_RGB.svg';
import PRST_Mark from '@/assets/PRST_Mark_RGB.svg';
import SPIRIT_Mark from '@/assets/SPIRIT_Mark_RGB.svg';
import tiktok from '@/assets/tiktok.png';
import twitter from '@/assets/TwitterLogo.png';
import youtube from '@/assets/youtube.png';
import { PROJECT_TYPES, SNS_TYPES } from '@/config/index';

import { PROJECT_IMAGE, MEASURE_CONTENT } from '../constants/path';
import { FormData } from '../interfaces/project';
import {
  decideService,
  decideMeasureContent,
  displayKpi,
  displayTotalCost,
  displayRecruitmentInfluencers,
} from '../utils/logic';

export interface DetailProps {
  payload: FormData;
}

export default function projectDetail(props: DetailProps): React.ReactElement {
  // 利用するサービス
  const service = decideService(props.payload);
  // 施策内容
  const content = decideMeasureContent(props.payload);
  const getProjectType = () => {
    if (service === PROJECT_TYPES.SPIRIT) {
      return 'SPIRIT案件（広告）';
    } else if (service === PROJECT_TYPES.EMERALD_POST) {
      return 'EMEPOS案件（広報）';
    } else if (service === PROJECT_TYPES.PRST) {
      return 'PRST案件（制作）';
    } else if (service === PROJECT_TYPES.JANE_JOHN) {
      return 'JANE JOHN案件（調査）';
    } else {
      return 'その他案件';
    }
  };

  const measuresDetail = () => {
    const image = props.payload.image;
    if (content === MEASURE_CONTENT.TIE_UP) {
      return 'インフルエンサーによるPR投稿';
    } else if (content === MEASURE_CONTENT.AMBASSADOR) {
      return 'アンバサダーによるPR投稿';
    } else if (content === MEASURE_CONTENT.HEART5) {
      return 'インフルエンサーによるリリースの配信';
    } else if (content === MEASURE_CONTENT.ACCOUNT_OPERATION) {
      return 'インフルエンサーによるSNSアカウント運用';
    } else if (content === MEASURE_CONTENT.SPOT_CONSULTING) {
      return 'インフルエンサーによるSNSアカウント相談(1時間)';
    } else if (content === MEASURE_CONTENT.ENQUETE) {
      return 'インフルエンサーへのアンケートの実施';
    } else {
      if (image === PROJECT_IMAGE.IMPORTANT_QUANTITY) {
        return 'インフルエンサーへのグループインタビューの実施（5人1組）';
      } else {
        return 'インフルエンサーへのグループインタビューの実施';
      }
    }
  };

  const selectSnsIcon = () => {
    const sns = props.payload.sns;
    if (sns === SNS_TYPES.INSTAGRAM) {
      return instagram;
    } else if (sns === SNS_TYPES.TWITTER) {
      return twitter;
    } else if (sns === SNS_TYPES.TIK_TOK) {
      return tiktok;
    } else if (sns === SNS_TYPES.YOUTUBE) {
      return youtube;
    } else {
      return '';
    }
  };

  const selectServiceMarkIcon = () => {
    if (service === PROJECT_TYPES.SPIRIT) {
      return <img src={SPIRIT_Mark} alt="instagram" className="h-12 w-12" />;
    } else if (service === PROJECT_TYPES.EMERALD_POST) {
      return <img src={EMERALDPOST_Mark} alt="instagram" className="h-12 w-12" />;
    } else if (service === PROJECT_TYPES.PRST) {
      return <img src={PRST_Mark} alt="instagram" className="h-12 w-12" />;
    } else if (service === PROJECT_TYPES.JANE_JOHN) {
      return <img src={JANEJOHN_Mark} alt="instagram" className="h-12 w-12" />;
    } else {
      return <img src={SPIRIT_Mark} alt="instagram" className="h-12 w-12" />;
    }
  };

  return (
    <div className="flex h-[314px] w-[860px] flex-row items-start justify-center gap-2.5 rounded-lg border border-gray-300">
      <div className="flex flex-row items-start pt-6">
        <div id="project_detail_left" className="flex h-[242px] w-[425px] flex-col items-start">
          <div className="pb-6">
            <div>{selectServiceMarkIcon()}</div>
            <div>
              <span className="text-2xl font-bold leading-7">{getProjectType()}</span>
            </div>
          </div>
          <div className="pb-6">
            <span className="text-gray-500">施策内容</span>
            <div className="flex flex-row items-center">
              {decideService(props.payload) != PROJECT_TYPES.EMERALD_POST && (
                <div className="flex h-10 w-10 flex-row items-center justify-center rounded-[100px] bg-gray-100">
                  <img src={selectSnsIcon()} alt="instagram" className="h-5 w-5" />
                </div>
              )}
              <div className="pl-2">
                <span>{measuresDetail()}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="project_detail_right" className="flex flex-col">
          <div className="pb-2.5">
            <div className="flex h-[82px] w-[377px] flex-row items-center rounded-lg bg-gray-50">
              <div className="flex h-[82px] w-[345px] flex-col items-start p-4">
                <div>
                  <span className="text-sm font-medium leading-5 text-gray-500">採用インフルエンサー</span>
                </div>
                <div>
                  <span className="text-lg font-semibold leading-7">
                    {displayRecruitmentInfluencers(props.payload)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-2.5">
            <div className="flex h-[82px] w-[377px] flex-row items-center rounded-lg bg-gray-50">
              <div className="flex h-[82px] w-[345px] flex-col items-start p-4">
                <div>
                  <span className="text-sm font-medium leading-5 text-gray-500">KPI</span>
                </div>
                <div>
                  <span className="text-lg font-semibold leading-7">{displayKpi(props.payload)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="pb-2.5">
            <div className="flex h-[82px] w-[377px] flex-row items-center rounded-lg bg-gray-50">
              <div className="flex h-[82px] w-[345px] flex-col items-start p-4">
                <div>
                  <span className="text-sm font-medium leading-5 text-gray-500">費用概算</span>
                </div>
                <div>
                  <span className="text-lg font-semibold leading-7">{displayTotalCost(props.payload)}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
