import storage from '@/utils/storage';

// APIの基本設定を行う
// TODO 開発環境のURLを指定しているので、環境ごとに変更できる様にする必要あり
const apiConfig = {
  baseUrl: process.env.REACT_APP_API_URL,
};

// 汎用的なAPIリクエスト関数
// GET以外はconfig引数にContent-Typeを指定する事と、body引数にリクエストボディを指定する事を忘れないようにする
export const apiRequest = async <T, U = undefined>(endpoint: string, config: RequestInit, body?: U): Promise<T> => {
  console.log('apiRequest', endpoint, config, apiConfig);
  console.log('body:', body);
  const token = storage.getToken();
  console.log('token:', token);
  const requestUrl = `${apiConfig.baseUrl}${endpoint}`;
  console.log('requestUrl:', requestUrl);

  const headers = {
    // 既存のヘッダーを展開して、Authorizationを追加する
    ...config.headers,
    Authorization: `Bearer ${token}`,
  };

  const fetchConfig = {
    // 引数の設定を展開して、ヘッダーを追加する
    ...config,
    headers,
    body: body !== undefined ? JSON.stringify(body) : null,
  };

  console.log('fetchConfig:', fetchConfig);
  const response = await fetch(requestUrl, fetchConfig);

  if (!response.ok) {
    try {
      // サーバーがJSON形式のエラーメッセージを返すことを期待してパースを試みる
      const errorBody = await response.json();
      throw new Error(`HTTP error! status: ${response.status}, message: ${errorBody.messages.join(',')}`);
    } catch (parseError: any) {
      // JSON形式でない場合は、ステータステキストを使用する
      throw new Error(`HTTP error! status: ${response.status}, text: ${parseError.message}`);
    }
  }
  console.log('★★★★★');
  console.log('response:', response);

  return response.json();
};
