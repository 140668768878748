import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { SignIn } from './SignIn';
import { SignInPost } from './SignInPost';
import { SignOutPost } from './SignOutPost';

export function Auth(): React.ReactElement {
  return (
    <Routes>
      <Route path="/" element={<SignIn />} />
      <Route path="/auth/sign-in-post" element={<SignInPost />} />
      <Route path="/auth/*" element={<SignIn />} />
      <Route path="/sign-in" element={<SignIn />} />
      <Route path="/sign-up" element={<SignIn />} />
      <Route path="/sign-in-post" element={<SignInPost />} />
      <Route path="/sign-out-post" element={<SignOutPost />} />
    </Routes>
  );
}
