import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import cn from 'classnames';
import { cloneDeep } from 'lodash';
import React, { Fragment, useState, useEffect } from 'react';

import { ProjectType } from '@/api/project';
import UnderArrow from '@/assets/icons/chevron-down.svg';
import UpArrow from '@/assets/icons/chevron-up-gray.svg';
import { ReactComponent as XIcon } from '@/assets/icons/remove.svg';
import { CheckBox } from '@/components/Elements/CheckBox';

export const noticeString = {
  Sampling: [
    '期間内に商品が必ず受け取れ、投稿ができる方のみご応募ください。',
    'SPIRITにご登録の住所が商品発送先の住所として正しいかを必ず事前にご確認ください。',
    '在庫の関係上、ご希望に添えない可能性がございますので予めご了承ください。',
    'お渡しする製品の転売は固く禁止させていただいております。',
  ],
  Event: [
    '本案件は指定日に実施されるイベントに参加可能な方のみご応募ください。',
    '当日、会場の写真がWEBサイトや公式SNSに掲載される場合があります。ご了承いただける方のみご応募ください。',
    'イベント内容及び時間等は変更する場合がございますので、予めご了承ください。',
    '本案件は同伴不可となっておりますので予めご了承ください。',
    '本案件は同伴人数に制限がありますのでご注意ください。',
    '同伴者様のギフトはご用意がございませんので、予めご了承ください。',
    '本案件のお子様の同伴は不可となっております。',
    '本案件はクロークのご用意がございませんので、手荷物は最小限にしてお越しください。',
    'イベント中は途中退室が出来兼ねますので、最後まで滞在可能な方のみご応募ください。',
    '本案件はご飲酒いただくイベント内容のため、ご妊娠されている方やその可能性のある方、お車でいらっしゃるご予定のある方は同伴者含めご遠慮ください。',
  ],
  Buying: [
    '当案件はWEBサイトで商品を購入いただく内容となり、報酬額に購入代金が含まれております。',
    '当案件は店舗で商品を購入いただく内容となり、報酬額に購入代金が含まれております。',
    '必ず指定のWEBサイトより、指定の商品を購入してください。',
    '必ず指定の店舗にて、指定の商品を購入してください。',
    '必ず期間内に商品を購入し、投稿ができる方のみご応募ください。',
    '購入対象外の商品がございますので、事前に案件概要を必ずご確認ください。',
  ],
  Other: [
    '当案件は指定したアカウントでリポストいたします。ご了承いただける方のみご応募くださいませ。',
    '当案件はストーリーズハイライトに設置いただく案件となっております。',
    '当案件はストーリーズでURLをスワイプアップできる方のみご応募くださいませ。',
  ],
};

export const ButtonNames = {
  SamplingButton: 'samplingButton',
  EventButton: 'eventButton',
  BuyingButton: 'buyingButton',
  OtherButton: 'otherButton',
  FreeInputButton: 'freeInputButton',
} as const;

export type ButtonType = (typeof ButtonNames)[keyof typeof ButtonNames];

export interface ButtonDetail {
  name: string;
  type: ButtonType;
  isSelect: boolean;
}

export interface DetailSettings {
  open: boolean;
  projectType: ProjectType;
  companyName: string;
  isSecret: boolean;
  notice: string[];
}

interface Props {
  detailSettings: DetailSettings;
  setDetailSettings: React.Dispatch<React.SetStateAction<DetailSettings>>;
  PutProjectNotice: (isPublic: boolean, notice: string[]) => Promise<void>;
}

export function PublicAttention(props: Props) {
  const [displayPublic, setDisplayPublic] = useState<boolean>(props.detailSettings.isSecret);
  const [displayNotices, setDisplayNotices] = useState<string[]>(props.detailSettings.notice);

  if (!displayNotices.includes('応募期間終了後のキャンセルは出来かねます。')) {
    displayNotices.unshift('応募期間終了後のキャンセルは出来かねます。');
  }
  if (!displayNotices.includes('採用後、必ず期間内に連絡が取れる方のみご応募ください。')) {
    displayNotices.unshift('採用後、必ず期間内に連絡が取れる方のみご応募ください。');
  }

  useEffect(() => {
    if (props.detailSettings.open) {
      setDisplayPublic(cloneDeep(props.detailSettings.isSecret));
      setDisplayNotices(cloneDeep(props.detailSettings.notice));
    }
  }, [props.detailSettings.open]);

  const buttonInit = (notice: string[]): ButtonDetail[] => {
    const buttonSampling = noticeString.Sampling.map((notice) => {
      return { name: notice, type: ButtonNames.SamplingButton, isSelect: displayNotices.includes(notice) };
    });
    const buttonEvent = noticeString.Event.map((notice) => {
      return { name: notice, type: ButtonNames.EventButton, isSelect: displayNotices.includes(notice) };
    });
    const buttonBuying = noticeString.Buying.map((notice) => {
      return { name: notice, type: ButtonNames.BuyingButton, isSelect: displayNotices.includes(notice) };
    });
    const buttonOther = noticeString.Other.map((notice) => {
      return { name: notice, type: ButtonNames.OtherButton, isSelect: displayNotices.includes(notice) };
    });
    const freeNotice = notice.filter(
      (notice) =>
        [...buttonSampling, ...buttonEvent, ...buttonBuying, ...buttonOther]
          .map((button) => button.name)
          .indexOf(notice) == -1
    );
    const buttonFree = freeNotice.map((notice) => {
      return { name: notice, type: ButtonNames.FreeInputButton, isSelect: true };
    });
    return [...buttonSampling, ...buttonEvent, ...buttonBuying, ...buttonOther, ...buttonFree];
  };
  const [displayButtons, setDisplayButtons] = useState<ButtonDetail[]>(buttonInit(props.detailSettings.notice));
  const [selectAccordion, setSelectAccordion] = useState<{
    [T in ButtonType]: boolean;
  }>({
    samplingButton: false,
    eventButton: false,
    buyingButton: false,
    otherButton: false,
    freeInputButton: false,
  });
  const [inputFreeNotice, setInputFreeNotice] = useState<string>('');
  const [noticeError, setNoticeError] = useState<{
    maxNoticeSampling: string;
    maxNoticeEvent: string;
    maxNoticeBuying: string;
    maxNoticeOther: string;
    maxNoticeFree: string;
    maxString: string;
  }>({
    maxNoticeSampling: '',
    maxNoticeEvent: '',
    maxNoticeBuying: '',
    maxNoticeOther: '',
    maxNoticeFree: '',
    maxString: '',
  });

  const addFixedStrings = (inputButtons: ButtonDetail[]) => {
    // checkBoxStrings の中からチェックされているものを抽出し、fixedSamplingStrings に格納する
    setDisplayNotices((prev) => {
      const updateStrings = inputButtons
        .filter((button) => !displayNotices.includes(button.name))
        .map((button) => button.name);
      return updateStrings.length > 0 ? [...prev, ...updateStrings] : prev;
    });
  };

  const addFreeNotice = (input: string) => {
    if (displayNotices.length >= 20 || input.length >= 100) {
      setNoticeError((prev) => {
        const maxNotices = displayNotices.length >= 20 ? '注意事項は最大20個までしか追加できません。' : '';
        const maxString = input.length > 100 ? '100文字以内で入力してください。' : '';
        return {
          ...prev,
          maxNotices,
          maxString,
        };
      });
      return;
    }
    setNoticeError((prev) => {
      return {
        ...prev,
        maxNotices: '',
        maxString: '',
      };
    });
    setDisplayNotices((prev) => {
      return prev.includes(input) ? prev : [...prev, input];
    });
    setInputFreeNotice('');
  };

  const onCloseSettingsModal = async (isSave: boolean) => {
    if (isSave) {
      await props.PutProjectNotice(displayPublic, displayNotices);
      props.setDetailSettings((prev) => ({
        ...prev,
        open: false,
        isSecret: displayPublic,
        notice: displayNotices,
      }));
    } else {
      props.setDetailSettings((prev) => ({
        ...prev,
        open: false,
        isSecret: props.detailSettings.isSecret,
        notice: props.detailSettings.notice,
      }));
    }
  };

  return (
    <Transition.Root show={props.detailSettings.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          //props.onClose(false, return_strings);
          onCloseSettingsModal(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      //props.onClose(false, return_strings);
                      onCloseSettingsModal(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      詳細設定
                    </Dialog.Title>
                    <div className="pt-[24px]">
                      <div className="text-sm font-semibold leading-5">企業名公開設定</div>
                      <div className="flex gap-[16px] pt-[16px] text-sm font-medium leading-5">
                        <label>
                          <input
                            type="radio"
                            name="visibility"
                            id="visibility"
                            className="mr-1"
                            checked={!displayPublic}
                            onChange={() => {
                              setDisplayPublic(false);
                            }}
                          />
                          募集時から全体に公開（推奨）
                        </label>
                        <label>
                          <input
                            type="radio"
                            name="visibility"
                            id="visibility"
                            className="mr-1"
                            checked={displayPublic}
                            onChange={() => {
                              setDisplayPublic(true);
                            }}
                          />
                          採用者のみ公開
                        </label>
                      </div>
                    </div>
                    <div className="pt-[16px] text-sm leading-5 text-gray-500">公開する企業名：</div>
                    <div className="text-sm font-medium leading-5 text-[#111827]">
                      {props.detailSettings.companyName}
                    </div>
                    <div className="pt-[32px] text-sm font-semibold leading-5 text-gray-700">注意事項</div>
                    <div>
                      <p className="pt-[4px] text-sm font-normal leading-5 text-gray-500">
                        案件全体に対する、注意事項を追加することができます。※初期設定の注意事項は削除できません。
                      </p>
                    </div>
                    <div className="col-span-full">
                      <span>
                        <span className="flex w-[130px] items-center justify-between gap-[20px] pt-4">
                          <div className="text-sm font-normal leading-5 text-gray-800">サンプリング</div>
                          <button
                            onClick={() =>
                              setSelectAccordion((prev) => {
                                return {
                                  ...prev,
                                  samplingButton: !prev.samplingButton,
                                };
                              })
                            }
                          >
                            {selectAccordion.samplingButton ? (
                              <img src={UpArrow} alt="上矢印" />
                            ) : (
                              <img src={UnderArrow} alt="下矢印" />
                            )}
                          </button>
                        </span>
                        {selectAccordion.samplingButton && (
                          <div className="mt-2 rounded-lg border-[1px] border-gray-300">
                            <div className="p-4">
                              <span>
                                {displayButtons
                                  .filter((button) => button.type === ButtonNames.SamplingButton)
                                  .map((button, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={cn('flex items-center gap-[8px]', index == 0 ? '' : 'pt-4')}
                                        role="presentation"
                                        onClick={() => {
                                          setDisplayButtons((prev) => {
                                            return prev.map((item) => {
                                              if (item.name === button.name) {
                                                return { ...item, isSelect: !item.isSelect };
                                              }
                                              return item;
                                            });
                                          });
                                        }}
                                      >
                                        <span className="flex cursor-pointer items-center">
                                          <span>
                                            <CheckBox
                                              values={[{ value: '1', label: '' }]}
                                              name="hashtag"
                                              value={String(button.isSelect ? 1 : 0)}
                                              className="h-[16px] w-[16px] rounded-md"
                                            />
                                          </span>
                                          <span className="text-sm font-medium leading-5 text-gray-700">
                                            {button.name}
                                          </span>
                                        </span>
                                      </div>
                                    );
                                  })}
                              </span>
                              <span className="pl-[500px]">
                                <button
                                  type="button"
                                  className="mt-4 cursor-pointer rounded-md bg-white px-3 py-2 text-sm font-medium leading-5 text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 active:bg-blue-300"
                                  onClick={() => {
                                    const addNotices = displayButtons
                                      .filter((button) => button.type === ButtonNames.SamplingButton && button.isSelect)
                                      .filter((button) => !displayNotices.includes(button.name));
                                    if (displayNotices.length + addNotices.length > 20) {
                                      setNoticeError((prev) => {
                                        return {
                                          ...prev,
                                          maxNoticeSampling: '注意事項は最大20個までしか追加できません。',
                                        };
                                      });
                                      return;
                                    }
                                    setNoticeError((prev) => {
                                      return {
                                        ...prev,
                                        maxNoticeSampling: '',
                                      };
                                    });
                                    addFixedStrings(
                                      displayButtons.filter(
                                        (button) => button.type === ButtonNames.SamplingButton && button.isSelect
                                      )
                                    );
                                  }}
                                >
                                  チェックした項目を追加
                                </button>
                              </span>
                              <div className="px-4 pb-[10px]">
                                {noticeError.maxNoticeSampling.length > 0 && (
                                  <p className="text-red-600">{noticeError.maxNoticeSampling}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </span>

                      <span>
                        <span className="flex w-[130px] items-center justify-between gap-[20px] pt-4">
                          <div className="text-sm font-normal leading-5 text-gray-800">イベント</div>
                          <button
                            onClick={() =>
                              setSelectAccordion((prev) => {
                                return {
                                  ...prev,
                                  eventButton: !prev.eventButton,
                                };
                              })
                            }
                          >
                            {selectAccordion.eventButton ? (
                              <img src={UpArrow} alt="上矢印" />
                            ) : (
                              <img src={UnderArrow} alt="下矢印" />
                            )}
                          </button>
                        </span>
                        {selectAccordion.eventButton && (
                          <div className="mt-2 rounded-lg border-[1px] border-gray-300">
                            <div className="p-4">
                              <span>
                                {displayButtons
                                  .filter((button) => button.type === ButtonNames.EventButton)
                                  .map((button, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={cn('flex items-center gap-[8px]', index == 0 ? '' : 'pt-4')}
                                        role="presentation"
                                        onClick={() => {
                                          setDisplayButtons((prev) => {
                                            return prev.map((item) => {
                                              if (item.name === button.name) {
                                                return { ...item, isSelect: !item.isSelect };
                                              }
                                              return item;
                                            });
                                          });
                                        }}
                                      >
                                        <span className="flex cursor-pointer items-center">
                                          <span>
                                            <CheckBox
                                              values={[{ value: '1', label: '' }]}
                                              name="hashtag"
                                              value={String(button.isSelect ? 1 : 0)}
                                              className="h-[16px] w-[16px] rounded-md"
                                            />
                                          </span>
                                          <span className="text-sm font-medium leading-5">{button.name}</span>
                                        </span>
                                      </div>
                                    );
                                  })}
                              </span>
                              <span className="pl-[500px]">
                                <button
                                  type="button"
                                  className="mt-4 cursor-pointer rounded-md bg-white px-3 py-2 text-sm font-medium leading-5 text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 active:bg-blue-300"
                                  onClick={() => {
                                    const addNotices = displayButtons
                                      .filter((button) => button.type === ButtonNames.EventButton && button.isSelect)
                                      .filter((button) => !displayNotices.includes(button.name));
                                    if (displayNotices.length + addNotices.length > 20) {
                                      setNoticeError((prev) => {
                                        return {
                                          ...prev,
                                          maxNoticeEvent: '注意事項は最大20個までしか追加できません。',
                                        };
                                      });
                                      return;
                                    }
                                    setNoticeError((prev) => {
                                      return {
                                        ...prev,
                                        maxNoticeEvent: '',
                                      };
                                    });
                                    addFixedStrings(
                                      displayButtons.filter(
                                        (button) => button.type === ButtonNames.EventButton && button.isSelect
                                      )
                                    );
                                  }}
                                >
                                  チェックした項目を追加
                                </button>
                              </span>
                              <div className="px-4 pb-[10px]">
                                {noticeError.maxNoticeEvent.length > 0 && (
                                  <p className="text-red-600">{noticeError.maxNoticeEvent}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </span>

                      <span>
                        <span className="flex w-[130px] items-center justify-between gap-[20px] pt-4">
                          <div className="text-sm font-normal leading-5 text-gray-800">バイイング</div>
                          <button
                            onClick={() =>
                              setSelectAccordion((prev) => {
                                return {
                                  ...prev,
                                  buyingButton: !prev.buyingButton,
                                };
                              })
                            }
                          >
                            {selectAccordion.buyingButton ? (
                              <img src={UpArrow} alt="上矢印" />
                            ) : (
                              <img src={UnderArrow} alt="下矢印" />
                            )}
                          </button>
                        </span>
                        {selectAccordion.buyingButton == true && (
                          <div className="mt-2 rounded-lg border-[1px] border-gray-300">
                            <div className="p-4">
                              <span>
                                {displayButtons
                                  .filter((button) => button.type === ButtonNames.BuyingButton)
                                  .map((button, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={cn('gap-[8px]', index == 0 ? '' : 'pt-4')}
                                        role="presentation"
                                        onClick={() => {
                                          setDisplayButtons((prev) => {
                                            return prev.map((item) => {
                                              if (item.name === button.name) {
                                                return { ...item, isSelect: !item.isSelect };
                                              }
                                              return item;
                                            });
                                          });
                                        }}
                                      >
                                        <span className="flex cursor-pointer items-center">
                                          <span>
                                            <CheckBox
                                              values={[{ value: '1', label: '' }]}
                                              name="hashtag"
                                              value={String(button.isSelect ? 1 : 0)}
                                              className="h-[16px] w-[16px] cursor-pointer rounded-md"
                                            />
                                          </span>
                                          <span className="text-sm font-medium leading-5">{button.name}</span>
                                        </span>
                                      </div>
                                    );
                                  })}
                              </span>
                              <span className="pl-[500px]">
                                <button
                                  type="button"
                                  className="mt-4 cursor-pointer rounded-md bg-white px-3 py-2 text-sm font-medium leading-5 text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 active:bg-blue-300"
                                  onClick={() => {
                                    const addNotices = displayButtons
                                      .filter((button) => button.type === ButtonNames.BuyingButton && button.isSelect)
                                      .filter((button) => !displayNotices.includes(button.name));
                                    if (displayNotices.length + addNotices.length > 20) {
                                      setNoticeError((prev) => {
                                        return {
                                          ...prev,
                                          maxNoticeBuying: '注意事項は最大20個までしか追加できません。',
                                        };
                                      });
                                      return;
                                    }
                                    setNoticeError((prev) => {
                                      return {
                                        ...prev,
                                        maxNoticeBuying: '',
                                      };
                                    });
                                    addFixedStrings(
                                      displayButtons.filter(
                                        (button) => button.type === ButtonNames.BuyingButton && button.isSelect
                                      )
                                    );
                                  }}
                                >
                                  チェックした項目を追加
                                </button>
                              </span>
                              <div className="px-4 pb-[10px]">
                                {noticeError.maxNoticeBuying.length > 0 && (
                                  <p className="text-red-600">{noticeError.maxNoticeBuying}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </span>

                      <span>
                        <span className="flex w-[130px] items-center justify-between gap-[20px] pt-4">
                          <div className="text-sm font-normal leading-5 text-gray-800">その他</div>
                          <button
                            onClick={() =>
                              setSelectAccordion((prev) => {
                                return {
                                  ...prev,
                                  otherButton: !prev.otherButton,
                                };
                              })
                            }
                          >
                            {selectAccordion.otherButton ? (
                              <img src={UpArrow} alt="上矢印" />
                            ) : (
                              <img src={UnderArrow} alt="下矢印" />
                            )}
                          </button>
                        </span>

                        {selectAccordion.otherButton && (
                          <div className="mt-2 rounded-lg border-[1px] border-gray-300">
                            <div className="p-4">
                              <span>
                                {displayButtons
                                  .filter((button) => button.type === ButtonNames.OtherButton)
                                  .map((button, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className={cn('flex items-center gap-[8px]', index == 0 ? '' : 'pt-4')}
                                        role="presentation"
                                        onClick={() => {
                                          setDisplayButtons((prev) => {
                                            return prev.map((item) => {
                                              if (item.name === button.name) {
                                                return { ...item, isSelect: !item.isSelect };
                                              }
                                              return item;
                                            });
                                          });
                                        }}
                                      >
                                        <span className="flex cursor-pointer items-center">
                                          <span>
                                            <CheckBox
                                              values={[{ value: '1', label: '' }]}
                                              name="hashtag"
                                              value={String(button.isSelect ? 1 : 0)}
                                              className="h-[16px] w-[16px] cursor-pointer rounded-md"
                                            />
                                          </span>
                                          <span className="text-sm font-medium leading-5">{button.name}</span>
                                        </span>
                                      </div>
                                    );
                                  })}
                              </span>
                              <span className="pl-[500px]">
                                <button
                                  type="button"
                                  className="mt-4 cursor-pointer rounded-md bg-white px-3 py-2 text-sm font-medium leading-5 text-blue-500 shadow-sm ring-1 ring-inset ring-blue-500 active:bg-blue-300"
                                  onClick={() => {
                                    const addNotices = displayButtons
                                      .filter((button) => button.type === ButtonNames.OtherButton && button.isSelect)
                                      .filter((button) => !displayNotices.includes(button.name));
                                    if (displayNotices.length + addNotices.length > 20) {
                                      setNoticeError((prev) => {
                                        return {
                                          ...prev,
                                          maxNoticeOther: '注意事項は最大20個までしか追加できません。',
                                        };
                                      });
                                      return;
                                    }
                                    setNoticeError((prev) => {
                                      return {
                                        ...prev,
                                        maxNoticeOther: '',
                                      };
                                    });
                                    addFixedStrings(
                                      displayButtons.filter(
                                        (button) => button.type === ButtonNames.OtherButton && button.isSelect
                                      )
                                    );
                                  }}
                                >
                                  チェックした項目を追加
                                </button>
                              </span>
                              <div className="px-4 pb-[10px]">
                                {noticeError.maxNoticeOther.length > 0 && (
                                  <p className="text-red-600">{noticeError.maxNoticeOther}</p>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </span>

                      <span>
                        <span className="flex w-[130px] items-center justify-between gap-[20px] pt-4">
                          <div className="text-sm font-normal leading-5 text-gray-800">自由入力</div>
                          <button
                            onClick={() =>
                              setSelectAccordion((prev) => {
                                return {
                                  ...prev,
                                  freeInputButton: !prev.freeInputButton,
                                };
                              })
                            }
                          >
                            {selectAccordion.freeInputButton ? (
                              <img src={UpArrow} alt="上矢印" />
                            ) : (
                              <img src={UnderArrow} alt="下矢印" />
                            )}
                          </button>
                        </span>
                      </span>

                      {selectAccordion.freeInputButton && (
                        <div className="mt-2 rounded-lg border-[1px] border-gray-300">
                          <div className="flex items-center gap-4 px-4 pb-[12px] pt-[10px]">
                            <input
                              type="text"
                              name="consideration"
                              id="consideration"
                              className="grow rounded-md border-0 py-1.5 pl-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              placeholder=""
                              value={inputFreeNotice}
                              onChange={(e) => {
                                //setConsideration(e.target.value);
                                setInputFreeNotice(e.target.value);
                              }}
                            />
                            <button
                              type="button"
                              className="inline-flex justify-center rounded-md border-[1px] border-blue-500 bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-blue-500 shadow-sm hover:bg-blue-200 sm:ml-3 sm:w-auto"
                              onClick={() => {
                                addFreeNotice(inputFreeNotice);
                              }}
                            >
                              追加
                            </button>
                          </div>
                          <div className="px-4 pb-[10px]">
                            {noticeError.maxNoticeFree.length > 0 && (
                              <p className="text-red-600">{noticeError.maxNoticeFree}</p>
                            )}
                            {noticeError.maxString.length > 0 && (
                              <p className="text-red-600">{noticeError.maxString}</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {displayNotices.length > 0 &&
                  displayNotices.map((item: string, index: number) => (
                    <div key={index} className="flex flex-row pt-2">
                      <div className="flex flex-row items-center  bg-gray-100">
                        <div className="px-3 py-0.5 text-sm font-medium text-gray-800">・ {item}</div>
                        {index !== 0 && index !== 1 && (
                          <div className="cursor-pointer px-3 py-0.5">
                            <XIcon
                              fill="#9CA3AF"
                              onClick={() => {
                                setDisplayNotices((prev) => {
                                  const notices = prev.filter((notice) => notice !== item);
                                  return notices.length > 0 ? notices : [];
                                });
                                if (
                                  noticeString.Sampling.includes(item) ||
                                  noticeString.Event.includes(item) ||
                                  noticeString.Buying.includes(item) ||
                                  noticeString.Other.includes(item)
                                ) {
                                  setDisplayButtons((prev) => {
                                    return prev.map((button) => {
                                      if (button.name === item) {
                                        return { ...button, isSelect: false };
                                      }
                                      return button;
                                    });
                                  });
                                }
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  ))}

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      onCloseSettingsModal(true);
                    }}
                  >
                    保存する
                  </button>

                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      onCloseSettingsModal(false);
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
