import { addDays, isSameDay, isBefore, isAfter } from 'date-fns';

import { MessageNewPlanString, ValidationMessages } from './message';

export function validationMinNumCheck(value: number, minNum: number) {
  if (value < minNum) {
    return false;
  }
  return true;
}
/**
 * 比較先が比較元以上の値か判定する
 * @param target 比較される数値
 * @param source 比較元数値
 * @returns true: 比較元以上 false: 比較元未満
 */
export function isMorehanValue(target: number, source: number) {
  return target >= source;
}

export function validationMaxNumCheck(value: number, maxNum: number) {
  if (value > maxNum) {
    return false;
  }
  return true;
}

export function validationHalfWidthCheck(text: string) {
  // valueが半角数字になっているか確認
  if (text.match(/[^0-9]+(\.[0-9]+)?$/)) {
    return false;
  }
  return true;
}

export function validationInputHalfWidthCheck(text: string) {
  // 入力された文字が半角になっているか確認
  if (text.match(/[^\u0020-\u007E\uFF61-\uFF9F]/)) {
    return false;
  }
  return true;
}

export function validationInputHyphensCheck(text: string) {
  // 入力された文字がハイフンを含んでいないか確認
  if (text.match(/[-ー]/)) {
    return false;
  }
  return true;
}

export function validationInputNumberCheck(text: string) {
  // 入力された文字が数字になっているか確認
  if (text.match(/[^0-9０-９]/)) {
    return false;
  }
  return true;
}

export function validationInputZeroStartCheck(text: string) {
  // 入力された文字が0から始まっていないか確認
  if (text.match(/^0/)) {
    return false;
  }
  return true;
}

export function validationIntegerCheck(value: number) {
  if (Number.isInteger(value) == false) {
    return false;
  }
  return true;
}

export function validationMessageMaxNumCheck(text: string, num: number) {
  // タイトルが {num} 文字以内になっているか確認
  if (text.length > num) {
    return false;
  }

  return true;
}

export function validationMessageMinNumCheck(text: string, num: number) {
  // タイトルが {num} 文字以下になっているか確認
  if (text.length < num) {
    return true;
  }
  return false;
}
export function validationInputVoidCheck(text: string) {
  // タイトルが空文字になっているか確認
  if (text.length === 0 || text == undefined || text === '') {
    return false;
  }
  return true;
}

export function validationImageCheck(image: File) {
  // 画像が30MB以下のjpeg / jpg / gif / pngファイルになっているか確認
  if (image.size > 30000000) {
    return false;
  }
  if (
    image.type !== 'image/jpeg' &&
    image.type !== 'image/jpg' &&
    image.type !== 'image/gif' &&
    image.type !== 'image/png'
  ) {
    return false;
  }
  return true;
}

export function isValidUrl(text: string) {
  // URLの形式を確認する正規表現
  const urlPattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
  return urlPattern.test(text);
}

export function returnPeriodErrorMessage(value: number, minNum: number) {
  // 上の３個の関数を使ってエラーメッセージを返す
  if (validationMinNumCheck(value, minNum) === false) {
    return MessageNewPlanString.lowestPeriodMessage;
  }
  if (validationIntegerCheck(value) === false) {
    return MessageNewPlanString.integralMessage;
  }
  return '';
}

export function returnMeasureErrorMessage(value: number, minNum: number) {
  // 上の３個の関数を使ってエラーメッセージを返す
  if (validationMinNumCheck(value, minNum) === false) {
    return MessageNewPlanString.lowestMeasureMessage;
  }
  if (validationIntegerCheck(value) === false) {
    return MessageNewPlanString.integralMessage;
  }
  return '';
}

export function returnBudgetErrorMessage(value: number, minNum: number) {
  // 上の３個の関数を使ってエラーメッセージを返す
  if (validationMinNumCheck(value, minNum) === false) {
    return MessageNewPlanString.lowestBudgetMessage;
  }
  if (validationIntegerCheck(value) === false) {
    return MessageNewPlanString.integralMessage;
  }
  return '';
}

// 空の値をチェックするバリデーション関数
export function validationEmptyCheck(value: string, label: string): string {
  // valueが空文字列の場合、エラーメッセージを返す
  if (value === '') {
    return ValidationMessages.inputMessage(label);
  }
  // 問題がなければ空文字列を返す
  return '';
}

// 最大文字数をチェックするバリデーション関数
export function validationMaxCheck(value: string, max: number, label: string): string {
  // valueの長さがmaxを超える場合、エラーメッセージを返す
  if (value.length > max) {
    return ValidationMessages.strMaxLenMessage(label, max);
  }
  // 問題がなければ空文字列を返す
  return '';
}

// URLのフォーマットをチェックするバリデーション関数
export function validateUrlFormat(value: string, label: string): string {
  // URLのフォーマットに適合する正規表現
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  // valueがURLフォーマットに適合しない場合、エラーメッセージを返す
  if (value && !urlRegex.test(value)) {
    return ValidationMessages.invalidUrlMessage(label);
  }
  // 問題がなければ空文字列を返す
  return '';
}
// 期間のフォーマットをチェックするバリデーション関数
export function validatePeriodInput(startedAt: string | null, endedAt: string | null): string {
  // 期間が空の場合、エラーメッセージを返す
  if (startedAt === null) {
    return ValidationMessages.inputMessage('配信開始日');
  } else if (endedAt === null) {
    return ValidationMessages.inputMessage('配信終了日');
  }
  // 問題がなければ空文字列を返す
  return '';
}
// 期間のフォーマットをチェックするバリデーション関数
export function validatePeriodStarted(startedAt: Date | null): string {
  // 期間がフォーマットに適合しない場合、エラーメッセージを返す
  if (startedAt) {
    if (isBefore(startedAt, new Date()) && !isSameDay(startedAt, new Date())) {
      return ValidationMessages.invaildTodayPeriodMessage();
    }
  }
  // 問題がなければ空文字列を返す
  return '';
}
// 期間のフォーマットをチェックするバリデーション関数
export function validatePeriodFormat(startedAt: Date | null, endedAt: Date | null, max: number): string {
  // 期間がフォーマットに適合しない場合、エラーメッセージを返す
  if (startedAt && endedAt) {
    const maxPeriod = addDays(startedAt, max);
    if (!isAfter(endedAt, startedAt) || (!isBefore(endedAt, maxPeriod) && !isSameDay(maxPeriod, endedAt))) {
      return ValidationMessages.formatPeriodMessage(max);
    }
  }
  // 問題がなければ空文字列を返す
  return '';
}

export function urlFormatValidation(value: string): boolean {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  if (value && !urlRegex.test(value)) {
    return false;
  }
  return true;
}

export function isValidEmail(email: string): boolean {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

/**
 * 数値のみ判定(半角全角の区別なし)
 * @param target 検査対象文字れる
 */
export function isUseNumber(target: string): boolean {
  const numberRegex = /^[0-9]+$/;
  const fullNumberRegex = /^[０-９]+$/g;

  return numberRegex.test(target) || fullNumberRegex.test(target);
}

/**
 * ハイフンを含んでいるかの判定
 * @param target 検査対象の文字列
 * @returns true: 含んでいる false: 含んでいない
 */
export function hasHyphenCahracter(target: string): boolean {
  return hasCharacterValue(target, '-');
}

/**
 * 指定された文字列を含んでいるかの判定
 * @param target 検査対象の文字列
 * @param char 検査文字
 * @returns true: 含んでいる false: 含んでいない
 */
export function hasCharacterValue(target: string, char: string): boolean {
  return target.indexOf(char) > -1;
}

/**
 * 電話番号の形式 0スタートで10または１１桁の数字
 * @param phoneNumber 検査対象の電話番号
 * @returns true: 電話番号の形式に合致 false: 電話番号の形式に合致しない
 */
export function isValidPhoneNumber(phoneNumber: string): boolean {
  const phoneNumberRegex = /^0[0-9]{9,10}$/;
  return phoneNumberRegex.test(phoneNumber);
}
