import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
export default function FeedbackStars(evaluation: number) {
  return (
    <div className="flex items-start">
      <StarIcon fill={0 < evaluation ? '#FCD34D' : '#D1D5DB'} width={16} height={16} />
      <StarIcon fill={1 < evaluation ? '#FCD34D' : '#D1D5DB'} width={16} height={16} />
      <StarIcon fill={2 < evaluation ? '#FCD34D' : '#D1D5DB'} width={16} height={16} />
      <StarIcon fill={3 < evaluation ? '#FCD34D' : '#D1D5DB'} width={16} height={16} />
      <StarIcon fill={4 < evaluation ? '#FCD34D' : '#D1D5DB'} width={16} height={16} />
    </div>
  );
}
