import { GetInformationDetailOutput, GetInformationOutput } from '@/api/information';
// import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { Button } from '@/components/Elements/Button/Button';

import { RowData } from '../../components/OneList';
import Pagenation, { projectTableInformation } from '../../components/Pagenation';
import { dateConvert } from '../../utils/dateUtils';

export function Notification(
  tableInformation: projectTableInformation,
  selectedTab: string,
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>,
  isDisplayListView: boolean,
  setIsDisplayListView: React.Dispatch<React.SetStateAction<boolean>>,
  handleSelectNotification: (id: string) => void,
  fetchInfomations: GetInformationOutput | null,
  infomationSubscriptioned: boolean,
  notice: GetInformationDetailOutput | undefined,
  setNotice: React.Dispatch<React.SetStateAction<GetInformationDetailOutput | undefined>>
  // setNoticeDetailIndex: React.Dispatch<React.SetStateAction<number>>
  // notice: FetchInformationInfoResponse | undefined,
  // setNotice: React.Dispatch<React.SetStateAction<FetchInformationInfoResponse | undefined>>
): React.ReactElement {
  const backToNoriceList = () => {
    setSelectedTab('notification');
    setIsDisplayListView(true);
    setNotice(undefined);
  };

  const displayDate = (date: string) => {
    const d = new Date(date);
    return `${d.getFullYear()}/${d.getMonth() + 1}/${d.getDate()}`;
  };

  return (
    <>
      {isDisplayListView ? (
        <div>
          <ul className="ml-[50px] h-[413px] divide-y divide-gray-200 overflow-x-hidden overflow-y-scroll border-t border-gray-200 bg-white">
            {fetchInfomations?.rows?.map((item, index) => (
              <li key={index} className="flex h-[60px]">
                <RowData
                  item_contents={item.title}
                  selectedTab={selectedTab}
                  date={dateConvert(item.createdAt)}
                  subscriptioned={infomationSubscriptioned}
                  onClick={() => {
                    handleSelectNotification(item.id);
                    // setIsDisplayListView(false);
                  }}
                ></RowData>
              </li>
            ))}
          </ul>
          <div className="ml-[50px] h-[1px] w-[calc(100%_-_50px)] bg-gray-200"></div>
          <div className="w-full">
            <div className="ml-[50px]">{Pagenation(tableInformation)}</div>
          </div>
        </div>
      ) : (
        <>
          {notice && (
            <div>
              <div className="ml-[50px] h-[597px] w-[1059px] bg-white">
                <div className="h-[108px] w-[684px] whitespace-pre-wrap pl-[24px] pt-[24px] text-3xl font-bold leading-9">
                  <span>{`${notice.title}`}</span>
                </div>
                <div className="h-[20px] w-[684px] pl-[24px] text-sm font-normal leading-5">
                  <div>{displayDate(notice.createdAt)}</div>
                </div>

                <div>
                  <nav className="-mb-px ml-[24px] w-[1010px] border-b-[1px] border-gray-200 pt-[25px]"></nav>
                </div>
                <div className="pt-[12px]"></div>
                <div className="w-[684px] pl-[24px] pt-[20px] text-base font-normal leading-7">{notice?.body}</div>
              </div>

              <nav className="-mb-px ml-[50px] w-[calc(100%_-_98px)] border-b-[1px] border-gray-200 pt-[25px]"></nav>

              <div className="pl-[50px] pt-[25px]">
                <Button onClick={() => backToNoriceList()} size="smNopadding" variant="white" className="w-[174px]">
                  <div className="flex items-center justify-center gap-2 py-[9px]">
                    <ChevronRightIcon width={16} height={16} fill="#111827"></ChevronRightIcon>
                    <div>お知らせ一覧へ戻る</div>
                  </div>
                </Button>
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
}
