import React from 'react';
import { RouteObject } from 'react-router-dom';

import { Landing } from '@/features/misc';
import lazyImport from '@/utils/lazyImport';

const { Auth } = lazyImport(() => import('@/features/auth'), 'Auth');

/**
 * ログアウト状態のルート
 */
const publicRoutes: Array<RouteObject> = [
  { path: '/', element: <Landing isAuthenticated={false} /> },
  {
    path: '/auth/*',
    element: <Auth />,
  },
];

export default publicRoutes;
