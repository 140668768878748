import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as NotepadIcon } from '@/assets/icons/bx-notepad.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as UserPlusIcon } from '@/assets/icons/bx-user-plus.svg';
import { Stat } from '@/components/Elements/Stat';

import { TiktokInsightProps } from '../../Tiktok/TiktokAll_Interface';

export default function ApplicantInfo(insightPosts: TiktokInsightProps, recentPostNum: number) {
  return (
    <>
      <div className="pb-[52px]">
        <div className="w-[333px] text-sm font-semibold leading-5 text-gray-900">{`直近${recentPostNum}投稿での平均インサイト`}</div>
        <div>
          <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-4">
            <Stat
              lable={`フォロワー数`}
              value={new Intl.NumberFormat('ja-JP').format(insightPosts.followers || 0)}
              icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
            <Stat
              lable={`フォロー数`}
              value={new Intl.NumberFormat('ja-JP').format(insightPosts.followings || 0)}
              icon={<UserPlusIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
          </div>
          {/* 2 行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-[8px]">
              <Stat
                lable="再生回数"
                value={new Intl.NumberFormat('ja-JP').format(insightPosts.play || 0)}
                icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable="エンゲージメント率"
                value={(Number(insightPosts.engagementRate) || 0) + '%'}
                icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
          {/* 3行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-[8px]">
              <Stat
                lable="エンゲージメント数"
                value={new Intl.NumberFormat('ja-JP').format(insightPosts.engagements || 0)}
                icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable="いいね数(平均)"
                value={new Intl.NumberFormat('ja-JP').format(insightPosts.goodAvg || 0)}
                icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
          {/* 4行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-[8px]">
              <Stat
                lable={`コメント数（平均）`}
                value={new Intl.NumberFormat('ja-JP').format(insightPosts.commentsAvg || 0)}
                icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable={`シェア数（平均）`}
                value={new Intl.NumberFormat('ja-JP').format(insightPosts.shareAvg || 0)}
                icon={<ShareIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
          {/* 5行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-[8px]">
              <Stat
                lable="再生回数率"
                value={(Number(insightPosts.playRate) || 0) + '%'}
                icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable="投稿数"
                value={new Intl.NumberFormat('ja-JP').format(insightPosts.postCounts || 0)}
                icon={<NotepadIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
