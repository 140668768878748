import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as LeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { PROJECT_TYPES } from '@/config';

import expectedProjectDurationDisplay from '../../utils/ExpectedProjectDurationDisplay';

export interface phaseInfo {
  name: string;
  value: 'create' | 'selection' | 'check' | 'doing' | 'done';
  isCurrent: boolean;
  isDone: boolean;
}

export interface HeaderProps {
  headerSmallTitle: string;
  headerBigTitle: string;
  implementationPeriod: {
    from: string;
    to: string;
  };
}

export function PageHeader(headerProps: HeaderProps): React.ReactElement {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex h-[60px] w-[1149px] flex-row pt-[10px]">
        <div className="cursor-pointer pl-[24px] pt-[16px]">
          <LeftIcon
            height={24}
            width={24}
            fill="#9CA3AF"
            onClick={() => {
              navigate(`/project_management/list?type=${PROJECT_TYPES.PRST}`);
            }}
          />
        </div>

        <div className="pl-[25.29px] pt-[5px]">
          <div className="h-[12px] w-[161px] text-xs font-normal leading-none">{headerProps.headerSmallTitle}</div>
          <div className="flex">
            <div className="pt-[4px] text-base font-bold leading-6">{headerProps.headerBigTitle}</div>
            <div className="pl-[8px] pt-[3px]">
              <span className="mr-[16px] h-[20px] w-[51px] whitespace-nowrap rounded bg-purple-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-[#065F46]">
                PRST
              </span>
            </div>
          </div>
        </div>

        <div className="h-[20px] content-center pl-[16px] pt-[13px]">
          <div className="text-sm font-normal leading-5 text-gray-500">
            実施期間：
            {expectedProjectDurationDisplay(headerProps.implementationPeriod.from, headerProps.implementationPeriod.to)}
          </div>
        </div>
      </div>
    </div>
  );
}
