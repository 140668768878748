import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import * as React from 'react';

import { ReactComponent as CheckIcon } from '@/assets/icons/bx-check.svg';
import { ReactComponent as DownIcon } from '@/assets/icons/bx-chevron-down.svg';

export interface SelectOption {
  value: string;
  label: string;
  children?: React.ReactNode;
}

export type SelectMenusProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  title: string;
  helperText?: string;
  options: SelectOption[];
  value?: string;
  widthSize?: string;
  onChange: any;
  buttonClass?: string;
};

export const SelectMenus = React.forwardRef<HTMLSelectElement, SelectMenusProps>(
  ({ className = '', ...props }, ref) => {
    const [selected, setSelected] = React.useState<SelectOption>();

    React.useEffect(() => {
      if (props.value) {
        setSelected(props.options.filter((option) => option.value === props.value)[0]);
      }
    }, [props.value, props.options]);

    // インフルエンサーへの評価を変更する関数
    const handleChange = (option: SelectOption) => {
      setSelected(option);
      if (props.onChange) {
        props.onChange(option);
      }
    };

    return (
      <>
        <div className="flex flex-col items-start p-0">
          <div>
            <span className="text-sm font-semibold leading-5 text-gray-700">{props.title}</span>
          </div>
          <div className="flex flex-col items-start">
            <Listbox value={selected} onChange={handleChange} ref={ref}>
              {({ open }) => (
                <>
                  <div className="flex flex-col items-start">
                    <Listbox.Button
                      className={clsx(
                        'flex h-[38px] cursor-default items-center justify-between rounded-md bg-white text-left align-middle text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6',
                        props.widthSize ? props.widthSize : 'w-[320px]',
                        props.buttonClass ? props.buttonClass : ''
                      )}
                      // 伝搬を止める
                      onClick={(e) => e.stopPropagation()}
                    >
                      {selected?.children ? (
                        <div className="justify-center">{selected.children}</div>
                      ) : (
                        <span className="pl-[13px] text-sm font-normal leading-5 text-black">
                          {selected?.label || ''}
                        </span>
                      )}

                      <span className="pointer-events-none inset-y-0 right-0 ml-[10px] flex items-center pr-2">
                        <DownIcon fill="#6B7280" />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={React.Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options
                        // 伝搬を止める
                        onClick={(e) => e.stopPropagation()}
                        className={clsx(
                          'absolute z-50 flex-row justify-between rounded-md border-[1px] border-gray-300 bg-white',
                          props.widthSize ? props.widthSize : 'w-[320px]',
                          className
                        )}
                      >
                        {props.options.map((item: SelectOption) => (
                          <Listbox.Option
                            key={item.value}
                            className={({ active }) =>
                              clsx(
                                active ? 'bg-gray-100 text-gray-900' : 'text-gray-900',
                                'relative h-[38px] w-full cursor-default select-none py-2 pl-8  pr-4'
                              )
                            }
                            value={item}
                          >
                            {({ selected, active }) => (
                              <>
                                {selected ? (
                                  <span
                                    className={clsx(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 left-0 flex w-full items-center pl-1.5'
                                    )}
                                  >
                                    <CheckIcon fill="#007CC2" />
                                  </span>
                                ) : null}
                                <span
                                  className={clsx(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'absolute block w-full truncate'
                                  )}
                                >
                                  {item.children ? item.children : item.label}
                                </span>
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
            {props.helperText && (
              <div>
                <span className="text-sm font-medium leading-5 text-gray-400">{props.helperText}</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

SelectMenus.displayName = 'SelectMenues';
