export interface rowProps {
  item_contents: string;
  selectedTab: string;
  date: string;
  subscriptioned: boolean;
  onClick: () => void;
}

export function RowData(props: rowProps) {
  return (
    <div className="h-[61px] w-[1059px] ">
      <div
        className="flex cursor-pointer items-center justify-between gap-3 px-4 py-5"
        role="presentation"
        onClick={props.onClick}
      >
        <div className="flex items-start">
          {props.subscriptioned ? <></> : <span className="h-[8px] w-[8px] rounded-[19px] bg-red-500"></span>}
          <span className="text-gray-900">{props.item_contents}</span>
        </div>
        <div className="justify-end whitespace-nowrap text-gray-400">
          <span>{props.date}</span>
        </div>
      </div>
    </div>
  );
}
