import { default as dayjs } from 'dayjs';

export const formatDate = (date: number) => dayjs(date).format('MMMM D, YYYY h:mm A');
const numberFormat = new Intl.NumberFormat('ja-JP');

// 日付整形(YYYY/MM/DD)
export function formatYYYYMMDD(dateTime: string): string {
  const date = dayjs(dateTime);
  if (!date.isValid()) {
    return '';
  }
  return dayjs(dateTime).format('YYYY/MM/DD');
}

// 実施期間のフォーマット整形
export const scheduleToString = (scheduleStartedAt: string | null, scheduleEndedAt: string | null): string => {
  let formatScheduleStartedAt = '';
  let formatScheduleEndedAt = '';
  if (scheduleStartedAt) {
    formatScheduleStartedAt = formatYYYYMMDD(scheduleStartedAt);
  }
  if (scheduleEndedAt) {
    formatScheduleEndedAt = formatYYYYMMDD(scheduleEndedAt);
  }

  if (formatScheduleStartedAt && formatScheduleEndedAt) {
    return `${formatScheduleStartedAt} 〜 ${formatScheduleEndedAt}`;
  } else if (formatScheduleStartedAt) {
    return `${formatScheduleStartedAt} 〜 `;
  } else if (formatScheduleEndedAt) {
    return ` 〜 ${formatScheduleEndedAt}`;
  } else {
    return '-';
  }
};

// 数値フォーマット
export const formatNumber = (value: number) => {
  return numberFormat.format(value);
};
// カンマ区切りでフォーマットする
export const addComma = (target: number): string => `${String(target).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

export const addCommaAmount = (target: number): string => `¥${String(target).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
