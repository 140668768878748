import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import imgFukidasiFv from '@/assets/img_fukidasi_fv.png';
import imgFv from '@/assets/img_fv.png';
import { Button } from '@/components/Elements/Button';
import { Screen } from '@/components/Elements/Screen';
import { TopBar } from '@/components/Elements/TopBar';
import { ROUTES } from '@/config/path';

export type LandingProps = {
  isAuthenticated: boolean;
};

export function Landing(props: LandingProps): React.ReactElement {
  const navigate = useNavigate();
  useEffect(() => {
    if (props.isAuthenticated) {
      navigate(ROUTES.HOME);
    }
  }, []);
  return (
    <>
      <TopBar />
      <Screen>
        <div className="relative bg-slate-200 pb-32 pl-20 pt-24">
          <img src={imgFv} alt="パソコン" width="769" className="absolute right-0 top-20" />
          <img src={imgFukidasiFv} alt="吹き出し" className="absolute left-[620px] top-[240px] h-[99px] w-[128px]" />
          <div className="relative">
            <h1 className="text-4xl font-bold">
              これからは量から<span className="text-5xl">質</span>へ
            </h1>
            <h1 className="text-4xl font-bold">
              国内No.1<span className="text-2xl">の</span>インフルエンサーマーケティング
            </h1>
            <p className="mb-8 mt-4">
              <span className="bg-white px-2 py-1 font-bold">インフルエンサー3万人登録</span>
            </p>
            <p className="text-xl font-bold">SNS課題を数値化し、効率的にシステムで解決。</p>
            <p className="text-xl font-bold">売上を上げる施策をインフルエンサーにおまかせ下さい。</p>
            <p className="mt-16">
              <Button
                className="h-9 w-44 rounded-[20px] bg-[#141730] py-0 text-center font-bold leading-8 text-white"
                style={{ padding: 0 }}
              >
                お問い合わせ
              </Button>
            </p>
          </div>
        </div>
        <div className="h-32"></div>
        <div className="bg-slate-800 py-24 text-center font-bold text-white">
          <h4 className="text-2xl">インフルエンサーマーケティングは</h4>
          <h4 className="text-2xl">LIDDELL INFLUENCER WORKSPACE / LIWひとつでOK</h4>
          <p className="mb-7 mt-9 text-sm">LIWとは、SNS課題を数値化し、効率的に解決できるワークスペースです。</p>
          <p className="text-sm">SNSから売上につなげるための活動を5つのメイン機能で、</p>
          <p className="text-sm">認知獲得から購入、さらにファン化まで対応します。</p>
          <p className="text-sm">誰でも簡単にSNS・インフルエンサーマーケティングを一元管理できます。</p>
        </div>
      </Screen>
    </>
  );
}
