import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChenverUp } from '@/assets/icons/chevron-up.svg';
import { SelectMenus } from '@/components/Elements/SelectMenus';
import { InstagramPostType } from '@/types/index';

import { modalArgsEnum } from '../../interfaces/modalInterface';

import ModalEmerald from './modals/modalEmerald';

export interface tableBody {
  tablebody: tableBodyProps[];
}

export interface tableBodyProps {
  sns: string;
  snsWord: string;
  image: string;
  icon: string;
  id: string;
  follower: string;
  impressions: string;
  text: string;
  goodquality: string;
}

interface ascInfoProps {
  id: boolean;
  follower: boolean;
  impressions: boolean;
  interactionRate: boolean;
  numberOfEngagements: boolean;
  goods: boolean;
  comments: boolean;
  save: boolean;
  numberOfImpressions: boolean;
  uniqueAccount: boolean;
  frequency: boolean;
}

export default function PostContent(props: tableBody) {
  const search = useLocation().search;

  const [rowTableBody, setRowTableBody] = useState<tableBodyProps[]>([]);
  const [tableBody, setTableBody] = useState<tableBodyProps[]>([]);
  const [selectedPost, setSelectedPost] = useState<InstagramPostType>('Story');
  const [ascInfo, setAscInfo] = useState<ascInfoProps>({
    id: true,
    follower: true,
    impressions: false,
    interactionRate: true,
    numberOfEngagements: true,
    goods: true,
    comments: true,
    save: true,
    numberOfImpressions: true,
    uniqueAccount: true,
    frequency: true,
  });

  const [perPage, setPerPage] = useState<number>(30);
  // const [perPage, setPerPage] = useState<number>([30, 50, 100].includes(queryStringRows) ? queryStringRows : 30);
  const [startPage, setStartPage] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // const [currentPage, setCurrentPage] = useState<number>(queryCurrentPage);
  // 初期画面に表示するテーブルデータ作成用ステート;
  const [currentData, setCurrentData] = useState<tableBodyProps[]>([]);
  const [open, setOpen] = useState<boolean>(false);
  const [posted_method, setPosted_method] = useState<modalArgsEnum>(modalArgsEnum.VIDEO);
  const [modalInformation, setModalInformation] = useState<tableBodyProps>({
    sns: '',
    snsWord: '',
    image: '',
    icon: '',
    id: '',
    follower: '',
    impressions: '',
    text: '',
    goodquality: '',
  });

  const handleSort = (name: keyof tableBodyProps, value: boolean) => {
    const tmp = rowTableBody;
    if (value) {
      tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] > b[name] ? 1 : -1));
    } else {
      tmp.sort((a: tableBodyProps, b: tableBodyProps) => (a[name] < b[name] ? 1 : -1));
    }
    setTableBody(tmp);
    const newtmp = tmp.slice(0, perPage);
    setCurrentData(newtmp);

    setAscInfo({ ...ascInfo, [name]: value });
  };
  const moveRef_header = useRef<HTMLDivElement>(null);
  const moveRef_body = useRef<HTMLDivElement>(null);
  const scrollLimit = 1070;

  const scroll_event = (e: any) => {
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
      moveRef_body.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
    }
  };

  const pages: number[] = [];
  const totalPages = Math.ceil(rowTableBody.length / perPage);
  const pageNationCount = totalPages > 7 ? 7 : totalPages;
  const endpageNumber = pageNationCount + startPage;
  for (let i = 0; i < pageNationCount; i++) {
    if (totalPages < startPage + i) break;
    pages.push(startPage + i);
  }

  // const handlePageFirst = () => {
  //   if (currentPage == 1) return;
  //   setStartPage(1);
  //   setCurrentPage(1);
  //   setTableBody(rowTableBody.slice(1, 1 + perPage));
  // };

  const handlePageFirst = () => {
    if (currentPage == 1) return;
    setStartPage(1);
    setCurrentPage(1);
    // setTableBody(rowTableBody.slice(0, 0 + perPage));
    setCurrentData(rowTableBody.slice(0, 0 + perPage));
  };

  // const handlePageDown = () => {
  //   if (currentPage == 1) return;
  //   if (currentPage == startPage) {
  //     setStartPage(startPage - 1);
  //   }
  //   const page = currentPage - 1;
  //   setCurrentPage(page);
  //   const index = (page - 1) * perPage;
  //   setTableBody(rowTableBody.slice(index, index + perPage));
  // };

  const handlePageDown = () => {
    if (currentPage == 1) return;
    if (currentPage == startPage) {
      setStartPage(startPage - 1);
    }
    const page = currentPage - 1;
    setCurrentPage(page);
    const index = (page - 1) * perPage;
    // setTableBody(rowTableBody.slice(index, index + perPage));
    setCurrentData(rowTableBody.slice(index, index + perPage));
  };

  // const handlePageUp = () => {
  //   if (currentPage == Math.ceil(rowTableBody.length / perPage)) return;
  //   if (currentPage == endpageNumber - 1) {
  //     setStartPage(startPage + 1);
  //   }
  //   const page = currentPage + 1;
  //   setCurrentPage(page);
  //   const index = (page - 1) * perPage;
  //   setTableBody(rowTableBody.slice(index, index + perPage));
  // };

  const handlePageUp = () => {
    if (currentPage == Math.ceil(rowTableBody.length / perPage)) return;
    if (currentPage == endpageNumber - 1) {
      setStartPage(startPage + 1);
    }
    const page = currentPage + 1;
    setCurrentPage(page);
    const index = (page - 1) * perPage;
    setTableBody(rowTableBody.slice(index, index + perPage));
    setCurrentData(rowTableBody.slice(index, index + perPage));
  };

  // const handlePageLast = () => {
  //   if (currentPage == totalPages) return;
  //   if (totalPages - 7 > 0) {
  //     setStartPage(totalPages - 6);
  //   } else {
  //     setStartPage(1);
  //   }

  //   setCurrentPage(totalPages);
  //   const index = (totalPages - 1) * perPage;
  //   setTableBody(rowTableBody.slice(index, index + perPage));
  // };

  const handlePageLast = () => {
    if (currentPage == totalPages) return;
    if (totalPages - 7 > 0) {
      setStartPage(totalPages - 6);
    } else {
      setStartPage(1);
    }

    setCurrentPage(totalPages);
    const index = (totalPages - 1) * perPage;
    setTableBody(rowTableBody.slice(index, index + perPage));
    setCurrentData(rowTableBody.slice(index, index + perPage));
  };

  const url = new URLSearchParams(window.location.search);
  const query_posted_method = url.get('posted_method');
  // console.log(query_posted_method);

  const handleOpenModal = (info: tableBodyProps) => {
    setModalInformation(info);
    setOpen(true);
    setSelectedPost(selectedPost);
    //TODO: API でどの投稿タイプかを取得するので、仮で設定。実際は分岐処理が必要
    if (query_posted_method == 'video') {
      setPosted_method(modalArgsEnum.VIDEO);
    } else if (query_posted_method == 'image') {
      setPosted_method(modalArgsEnum.IMAGE);
    }
  };

  useEffect(() => {
    const bodies = props.tablebody;
    setTableBody(bodies.sort((a: tableBodyProps, b: tableBodyProps) => (a['impressions'] < b['impressions'] ? 1 : -1)));
    setRowTableBody(bodies);
    console.log(tableBody);

    // 初期画面に表示するテーブルデータ作成
    const data = bodies.slice(0, perPage);
    setCurrentData(data);
  }, [perPage]);

  return (
    <div className="flex flex-col items-start gap-6 self-stretch p-6">
      <div className="flex flex-col items-start gap-10 self-stretch">
        <div className="flex flex-col items-start gap-10 self-stretch">
          <div className="flex flex-col items-start gap-4 self-stretch">
            <span className="text-sm font-bold">投稿一覧</span>
            <div className="flex flex-col items-start self-stretch">
              <div className="flex flex-col items-start gap-2">
                <div>
                  <div className="divide-y divide-gray-200 rounded-t-lg bg-white">
                    <div
                      className="w-[1050px] overflow-auto scrollbar-hide"
                      ref={moveRef_header}
                      onScroll={(event) => scroll_event(event)}
                    >
                      <div className="sticky top-0 inline-block">
                        <div className="flex h-[56px]">
                          <div className="w-[74px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                SNS
                              </span>
                            </div>
                          </div>
                          <div className="w-[112px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                サムネイル
                              </span>
                            </div>
                          </div>
                          <div className="w-[98px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                アイコン
                              </span>
                            </div>
                          </div>
                          <div className="w-[152px] items-center bg-white px-[17px] py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                ID
                              </span>
                            </div>
                          </div>
                          <div className="w-[200px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                フォロワー数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.follower ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('follower', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('follower', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[215px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                インプレッション数
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.impressions ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('impressions', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('impressions', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[280px] items-center bg-white px-[7px] py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                テキスト
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.interactionRate ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('text', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('text', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[192px] items-center bg-white px-6 py-4">
                            <div className="flex flex-1 items-center gap-2">
                              <span className="text-left text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                                GOOD QUARITY ボーナス
                              </span>
                              <div className="rounded-[4px] bg-gray-200">
                                <div className="flex h-5 w-5 items-center  justify-center ">
                                  {ascInfo.numberOfEngagements ? (
                                    <ChenverDown
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('goodquality', false)}
                                      stroke="#9CA3AF"
                                    />
                                  ) : (
                                    <ChenverUp
                                      width={14}
                                      height={14}
                                      onClick={() => handleSort('goodquality', true)}
                                      stroke="#9CA3AF"
                                    />
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="h-[210px] w-[1050px] divide-y divide-gray-200 overflow-auto"
                      onScroll={(event) => scroll_event(event)}
                      ref={moveRef_body}
                    >
                      {/* {tableBody.map((body: tableBodyProps, index: number) => { */}
                      {currentData.map((body: tableBodyProps, index: number) => {
                        function handleChage(value: any, index: number) {
                          setTableBody((tableBody) => [...tableBody, (tableBody[index].goodquality = value)]);
                          setCurrentData((tableBody) => [...tableBody, (tableBody[index].goodquality = value)]);
                        }

                        return (
                          <div
                            key={index}
                            onClick={(e: any) => {
                              // handleOpenModal(body);

                              e.target.textContent === '候補にしない'
                                ? setOpen(false)
                                : e.target.textContent === '候補にする'
                                ? setOpen(false)
                                : handleOpenModal(body);
                            }}
                            onKeyDown={() => handleOpenModal(body)}
                            className="flex w-[1299px] items-center odd:bg-white even:bg-gray-50 hover:cursor-pointer hover:bg-gray-100"
                            role="presentation"
                          >
                            <div className="w-[74px] px-6 py-4">
                              <img src={body.sns} alt="" />
                            </div>
                            <div className="w-[112px] px-6 py-4">
                              <img src={body.image} alt="" />
                            </div>
                            <div className="w-[98px]  px-6 py-4">
                              <img src={body.icon} alt="" />
                            </div>
                            <div className="w-[152px] px-6 py-4">
                              <span className="text-sm">{body.id}</span>
                            </div>
                            <div className="w-[200px] py-6 pl-8">
                              <span className="text-sm">{body.follower}</span>
                            </div>
                            <div className="w-[215px] px-[40px] py-4">
                              {/* <span className="text-sm">{body.impressions}</span> */}
                              {body.snsWord === 'instagram' ? (
                                <span className="text-sm">{body.impressions}</span>
                              ) : (
                                <span className="text-sm">-</span>
                              )}
                            </div>
                            <div className="w-[280px] px-6 py-4">
                              <span className="text-sm">{body.text || '-'}</span>
                            </div>
                            <div className="relative w-[192px] px-[40px] py-4">
                              <SelectMenus
                                title={''}
                                options={[
                                  { value: 'false', label: '候補にしない' },
                                  { value: 'true', label: '候補にする' },
                                ]}
                                className="absolute"
                                widthSize="w-[142px]"
                                value={body.goodquality}
                                onChange={(e: any) => {
                                  handleChage(e.value, index);
                                }}
                              ></SelectMenus>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full items-center justify-between rounded-b-lg border border-gray-200 bg-white px-4 py-3 sm:px-6">
                <div className="flex flex-1 justify-between sm:hidden">
                  <a
                    href="#"
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Previous
                  </a>
                  <a
                    href="#"
                    className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
                  >
                    Next
                  </a>
                </div>
                <div className="sm:flex sm:flex-1 sm:items-center sm:justify-between">
                  <div className="flex flex-row items-center gap-2">
                    <span className="text-xs font-normal leading-4 text-gray-500">１ページあたりの行数:</span>
                    <div className="text-xs font-normal leading-4 sm:col-span-3">
                      <select
                        id="perPage"
                        name="perPage"
                        className="border-none text-xs font-normal leading-4 text-gray-500"
                        defaultValue={perPage}
                        // onChange={(e) => {
                        //   setPerPage(+e.target.value);
                        //   setStartPage(1);
                        //   setCurrentPage(1);
                        // }}
                        onChange={(e) => {
                          setPerPage(+e.target.value);
                          setStartPage(1);
                          setCurrentPage(1);
                          const queryString = new URLSearchParams(search);
                          queryString.set('rows', e.target.value);
                          queryString.set('page', '1');
                          history.pushState(null, '', `?${queryString.toString()}`);
                        }}
                      >
                        <option>30</option>
                        <option>50</option>
                        <option>100</option>
                      </select>
                    </div>
                    <span className="text-sm text-gray-500">
                      {(currentPage - 1) * perPage + 1} - {(currentPage - 1) * perPage + perPage} of{' '}
                      {rowTableBody.length}
                    </span>
                  </div>
                  {rowTableBody.length > 30 && (
                    <div>
                      <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
                        <button
                          onClick={handlePageFirst}
                          className={clsx(
                            'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                            currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronDoubleLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                        <button
                          onClick={handlePageDown}
                          className={clsx(
                            'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                            currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronLeftIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                        {pages.map((page) => {
                          if (page == currentPage) {
                            return (
                              <button
                                key={page}
                                className="relative z-10 inline-flex items-center border border-[#007CC2] bg-[#E0F4FF] px-4 py-2"
                              >
                                <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                              </button>
                            );
                          } else {
                            return (
                              <button
                                key={page}
                                className="relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                                // onClick={() => {
                                //   setCurrentPage(page);
                                //   const index = (page - 1) * perPage;
                                //   setTableBody(rowTableBody.slice(index, index + perPage));
                                // }}
                                onClick={() => {
                                  setCurrentPage(page);
                                  const index = (page - 1) * perPage;
                                  // setTableBody(rowTableBody.slice(index, index + perPage));
                                  setCurrentData(rowTableBody.slice(index, index + perPage));
                                }}
                              >
                                <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                              </button>
                            );
                          }
                        })}
                        <button
                          onClick={handlePageUp}
                          className={clsx(
                            'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                            currentPage == totalPages ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == totalPages ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                        <button
                          onClick={handlePageLast}
                          className={clsx(
                            'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                            currentPage == totalPages ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                          )}
                        >
                          <ChevronDoubleRightIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                            fill={currentPage == totalPages ? '#D1D5DB' : '#6B7280'}
                          />
                        </button>
                      </nav>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ModalEmerald
        open={open}
        setOpen={setOpen}
        accountInformation={modalInformation}
        posted_method={posted_method}
        postType={selectedPost}
      />
    </div>
  );
}
