import { useState, useEffect } from 'react';

import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import ContentView from '../components/ContentView';

export const PRSTPostList = () => {
  const [headerFlag, setHeaderFlag] = useState(false);

  useEffect(() => {
    const t = setInterval(() => setHeaderFlag(false), 5000);
    return () => clearInterval(t);
  }, [headerFlag]);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <ContentView />
    </MainLayout>
  );
};
