import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementImageApprovingView } from '../components/PRSTProductionManagementImageApprovingView';

export const PRSTProductionManagementImageApproving = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementImageApprovingView />
    </MainLayout>
  );
};
