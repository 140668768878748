import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { MemberListInvitation } from '../components/MemberListInvitation';

export function MemberInvitation(): React.ReactElement {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.basicInfo,
        secondSideBarselected: secondSidebarSelect.member,
      }}
    >
      <MemberListInvitation />
    </MainLayout>
  );
}
