import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PlayCircleIcon } from '@/assets/icons/bx-play-circle.svg';
import { Stat } from '@/components/Elements/Stat';

// Dupricated:共通版の /workspace/src/features/project_analysis/components/posts/modals/tabs/summaryCommon.tsx を使用してください
export const SummaryPost = (
  width: string,
  impressions: number,
  engagementRate: number,
  engagement: number,
  goods: number,
  comments: number
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<PlayCircleIcon width={24} height={24} fill="#FFFFFF" />}
          lable="視聴回数"
          value={impressions.toLocaleString()}
          className={width}
        />
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント率"
          value={engagementRate.toLocaleString() === '集計中' ? `${engagementRate}` : `${engagementRate}%`}
          className={`${width} h-[120px] justify-center whitespace-pre-wrap`}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント数"
          value={engagement.toLocaleString()}
          className={width}
        />
        <Stat
          icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
          lable="高評価数"
          value={goods.toLocaleString()}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
          lable="コメント数"
          value={comments.toLocaleString()}
          className={width}
        />
      </div>
    </div>
  );
};

export const InsightPost = (
  width: string,
  impressions: number,
  engagementRate: number,
  engagement: number,
  goods: number,
  comments: number
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<PlayCircleIcon width={24} height={24} fill="#FFFFFF" />}
          lable="視聴回数"
          value={impressions.toLocaleString()}
          className={width}
        />
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント率"
          value={`${engagementRate}%`}
          className={`${width} h-[120px] justify-center whitespace-pre-wrap`}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント数"
          value={engagement.toLocaleString()}
          className={width}
        />
        <Stat
          icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
          lable="高評価数"
          value={goods.toLocaleString()}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
          lable="コメント数"
          value={comments.toLocaleString()}
          className={width}
        />
        <></>
      </div>
    </div>
  );
};
