import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';

import { AnalysisProjectFeedbackAnswer, GetAnalysisProjectFeedbackOutput } from '@/api/analysis';
import { getAnalysisProjectFeedback } from '@/hooks/Analysis';

interface QuestionnaireResultsProps {
  projectId: string;
}
export default function QuestionnaireResults(props: QuestionnaireResultsProps) {
  const [feedBackQuestionnaire, setFeedBackQuestionnaire] = useState<GetAnalysisProjectFeedbackOutput['result']>();
  useEffect(() => {
    const requestGetAnalysisProjectFeedback = async () => {
      const response = await getAnalysisProjectFeedback(props.projectId);
      setFeedBackQuestionnaire(response.result);
    };
    requestGetAnalysisProjectFeedback();
  }, [props.projectId]);

  return (
    <div className="w-[880px]">
      <div className="self-stretch">
        <div className="mb-[24px]">
          <span className="text-sm font-semibold leading-5 text-gray-700">アンケート集計結果</span>
        </div>
      </div>
      <div className="flex flex-wrap items-start gap-6">
        {feedBackQuestionnaire?.map((feedBackItem, index) => {
          const sortAnswer = feedBackItem.answers.sort((a, b) => b.answer - a.answer);
          return (
            <div id="result-up" className="flex items-start gap-6" key={index}>
              {resultGraph(
                feedBackItem.question.content,
                `0「${feedBackItem.question.lowText}」から、10「${feedBackItem.question.highText}」の11段階`,
                sortAnswer,
                index
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

const legend = (color: string, label: string, rate: number, index: number) => {
  return (
    <React.Fragment key={index}>
      <div className="flex items-center gap-2">
        <div className="flex items-center gap-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
            <circle cx="6" cy="6" r="6" fill={color} />
          </svg>
          <span className="break-normal text-sm font-normal leading-5 text-gray-700">{label}</span>
        </div>
        <span className="break-normal text-sm font-normal leading-5 text-gray-700">{rate}%</span>
      </div>
    </React.Fragment>
  );
};

const resultGraph = (
  title: string,
  description: string,
  answerData: AnalysisProjectFeedbackAnswer[],
  index: number
) => {
  const createLegendParam = (answer: number) => {
    const ANSWER_LEBEL_ONE = 1;
    const ANSWER_LEBEL_TWO = 2;
    const ANSWER_LEBEL_THREE = 3;
    const ANSWER_LEBEL_FOUR = 4;
    const ANSWER_LEBEL_FIVE = 5;

    switch (answer) {
      case ANSWER_LEBEL_ONE:
        return { label: '0〜1', color: '#EF4444' };
      case ANSWER_LEBEL_TWO:
        return { label: '2〜3', color: '#F87171' };
      case ANSWER_LEBEL_THREE:
        return { label: '4〜5', color: '#FCA5A5' };
      case ANSWER_LEBEL_FOUR:
        return { label: '6〜7', color: '#F59E0B' };
      case ANSWER_LEBEL_FIVE:
        return { label: '8〜10', color: '#10B981' };
      default:
        return { label: '', color: '' };
    }
  };
  const option = {
    dataLabels: {
      enabled: true,
      show: true,
      formatter: function (val: number) {
        return val.toFixed(1).toString() + '%';
      },
    },
    stroke: { width: 0 },
    colors: ['#10B981', '#F59E0B', '#FCA5A5', '#F87171', '#EF4444'],
    chart: {
      sparkline: {
        enabled: true,
      },
    },
    tooltip: {
      enabled: true,
      x: {
        show: false,
      },
      y: {
        formatter: function (val: any) {
          return val + '%';
        },
        title: {
          formatter: function () {
            return '';
          },
        },
      },
    },
    legend: {
      show: false,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            name: {
              show: false,
            },
            value: {
              show: false,
            },
            total: {
              show: false,
            },
          },
        },
      },
    },
  };

  const rateArr = answerData.map((data) => data.rate);
  return (
    <React.Fragment key={index}>
      <div className="flex flex-col items-start gap-6 rounded-lg border border-gray-200 p-6">
        <div className="flex h-[400px] w-[340px] flex-col items-start gap-6">
          <div className="flex items-start gap-4 self-stretch">
            <div className="flex flex-1 flex-col items-start gap-2">
              <span className="text-sm font-semibold leading-5 text-gray-700">{title}</span>
              <span className="text-sm leading-5 text-gray-500">{description}</span>
            </div>
          </div>
          <div className="flex items-start justify-between self-stretch">
            <Chart type="donut" options={option} series={rateArr} height={224} width={224} />
            <div className="flex flex-col items-start gap-1">
              {answerData.map((data, index) =>
                legend(createLegendParam(data.answer).color, createLegendParam(data.answer).label, data.rate, index)
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
