import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as LeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as RightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { GanttChart as GanttChartComponent } from '@/components/Calendars/GanttChart';
import { Button } from '@/components/Elements/Button';
import { MainLayout } from '@/components/Layout/MainLayout';
import { ROUTES } from '@/config/path';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

export function GanttChart(): React.ReactElement {
  const search = useLocation().search;
  const location = useLocation();
  const navigate = useNavigate();
  const queryString = new URLSearchParams(search);
  const condition: string = queryString.get('condition') || '';

  const [year, setYear] = useState<number>(location.state?.year);
  const [month, setMonth] = useState<number>(location.state?.month);
  const [labelYear, setLabelYear] = useState<number>();
  const [labelMonth, setLabelMonth] = useState<number>();
  const [isEmpty, setIsEmpty] = useState<boolean>(false);

  const handleNewProject = () => {
    navigate(ROUTES.PROJECT);
  };

  const handleCurrentMonth = () => {
    const displayDate = new Date();
    setYear(displayDate.getFullYear());
    setMonth(displayDate.getMonth() + 1);
  };

  const handlePrevMonth = () => {
    let tmpMonth = month;
    let tmpYear = year;
    if (!tmpYear || !tmpMonth) {
      const displayDate = new Date();
      tmpYear = displayDate.getFullYear();
      tmpMonth = displayDate.getMonth() + 1;
    }

    if (month == 1) {
      tmpMonth = 12;
      tmpYear -= 1;
    } else {
      tmpMonth -= 1;
    }

    setYear(tmpYear);
    setMonth(tmpMonth);
    setLabelYear(tmpYear);
    setLabelMonth(tmpMonth);
  };

  const handlenextMonth = () => {
    let tmpMonth = month;
    let tmpYear = year;
    if (!tmpYear || !tmpMonth) {
      const displayDate = new Date();
      tmpYear = displayDate.getFullYear();
      tmpMonth = displayDate.getMonth() + 1;
    }

    if (tmpMonth == 12) {
      tmpMonth = 1;
      tmpYear += 1;
    } else {
      tmpMonth += 1;
    }

    setYear(tmpYear);
    setMonth(tmpMonth);
    setLabelYear(tmpYear);
    setLabelMonth(tmpMonth);
  };
  const handleCalendar = () => {
    navigate(ROUTES.CALENDAR);
  };

  useEffect(() => {
    if (condition === 'empty') {
      setIsEmpty(true);
      return;
    }
    const displayDate = new Date();
    setLabelYear(year ? year : displayDate.getFullYear());
    setLabelMonth(month ? month : displayDate.getMonth() + 1);
  }, []);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.schedule,
      }}
    >
      <div id="schedule" className="flex w-[1181px] flex-col items-start gap-6 bg-gray-50 pb-0">
        <div className="flex h-[76px] flex-col items-start  border-b border-gray-200">
          <div className="flex w-[1181px] flex-1 px-4">
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center gap-[10px]" id="left">
                <Button
                  variant="white"
                  className="flex h-[30px] w-[106px] items-center justify-center"
                  size="xsNopadding"
                  onClick={handleCalendar}
                >
                  <span className="text-xs font-normal leading-4 text-gray-500">カレンダー</span>
                </Button>
                <Button
                  variant="white"
                  className="flex h-[30px] w-[46px] items-center justify-center"
                  size="xsNopadding"
                  onClick={handleCurrentMonth}
                >
                  <span className="text-xs font-normal leading-4 text-gray-500">今月</span>
                </Button>
                <div className="flex rounded-md">
                  <Button
                    variant="white"
                    className="inline-flex h-[30px] w-[38px] items-center justify-center"
                    size="xsNopadding"
                    onClick={handlePrevMonth}
                  >
                    <LeftIcon width={20} height={20} fill="#6B7280" />
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex h-[30px] w-[38px] items-center justify-center"
                    size="xsNopadding"
                    onClick={handlenextMonth}
                  >
                    <RightIcon width={20} height={20} fill="#6B7280" />
                  </Button>
                </div>
                <span className="text-lg font-semibold leading-6 text-gray-500">
                  {labelYear}年{labelMonth}月
                </span>
              </div>
              <div className="" id="right">
                <Button
                  size="sm"
                  className="h-9 w-[210px] font-bold"
                  startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
                  onClick={handleNewProject}
                >
                  新しい案件を作成
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id="gantt-chart" className="px-4">
          <GanttChartComponent isEmpty={isEmpty} year={year} month={month} isHomeView={false}></GanttChartComponent>
        </div>
      </div>
    </MainLayout>
  );
}
