import { getImageUrl } from '@/utils/image';

import { STEPS } from '../constants/path';

export function Header() {
  return (
    <div id="header" className="flex h-10 flex-col items-center justify-center self-stretch bg-black">
      <img src={getImageUrl('assets/logo/row_white_2.png')} alt="" width="113.19" height="34.37" />
    </div>
  );
}

export function PageTitle(step: string, five_option?: boolean) {
  return (
    <div className="text-center">
      <p className="w-[1280px] text-4xl font-extrabold leading-10 tracking-tight">
        {GetTitleByStep(step, five_option)}
      </p>
    </div>
  );
}

function HeaderContent(title: string, subTitle: string) {
  return (
    <div className="text-center">
      <p className="w-[1280px] text-4xl font-extrabold leading-10 tracking-tight">{title}</p>
      <p className="w-[1280px] pt-4 text-xl font-normal leading-7 text-gray-500">{subTitle}</p>
    </div>
  );
}
function GetTitleByStep(step: string, five_option?: boolean) {
  if (step === STEPS.GOAL) {
    return HeaderContent(
      '今回の施策で達成したいことは何ですか？',
      '今回施策を実施する中で達成したい目標に近いものを1つ選択してください。'
    );
  } else if (step === STEPS.IMAGE) {
    return HeaderContent(
      '具体的な施策のイメージを教えてください',
      '今回の施策に最も近いイメージを1つ選択してください。'
    );
  } else if (step === STEPS.IMPORTANT) {
    return HeaderContent('次のうち、最も重視したいものを教えてください', '1つ選択ください。');
  } else if (step === STEPS.SNS) {
    return HeaderContent('どのSNSを使用したいですか？', '1つ選択ください。');
  } else if (step === STEPS.PERIOD) {
    if (five_option) {
      return HeaderContent('どれくらいの施策回数で検討されていますか？', '予定している施策回数を入力してください。');
    }
    return HeaderContent('どれぐらいの期間で検討されていますか？', '予定している期間を入力してください。');
  } else if (step === STEPS.BUDGET) {
    return HeaderContent('最後に、今回の施策の予算感を教えてください', '予算金額を入力してください。');
  } else if (step === STEPS.FINISH) {
    return HeaderContent('このような案件内容はいかがですか？', '');
  } else {
    return <></>;
  }
}
