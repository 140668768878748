import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectType } from '@/api/project';
import { MainLayout } from '@/components/Layout';
import { firstSidebarSelect } from '@/config/sidebar';
import { getJaneJohnList } from '@/hooks/JaneJohn';
import { getOtherList } from '@/hooks/OtherProject';
import { getSpiritList } from '@/hooks/SPIRIT';
import { selectedSecondSidebarValue } from '@/utils/pureUtils';

import AnalysisTable from '../components/AnalysisTable';
import { listHeader } from '../components/layouts/header';
import { ProjectInformation } from '../interfaces/analysisInterface';

export function AnalysisList(): React.ReactElement {
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const convertProjectType = (type: string | null): ProjectType => {
    switch (type) {
      case ProjectType.Spirit:
        return ProjectType.Spirit;
      case ProjectType.EmeraldPost:
        return ProjectType.EmeraldPost;
      case ProjectType.JaneJohn:
        return ProjectType.JaneJohn;
      case ProjectType.Prst:
        return ProjectType.Prst;
      default:
        return ProjectType.Other;
    }
  };
  const projectType = convertProjectType(queryString.get('type'));

  const [projects, setProjects] = useState<ProjectInformation>();

  const updateProjectList = useCallback(
    (perPage: 30 | 50 | 100 | 10, currentPage: number) => {
      switch (projectType) {
        case ProjectType.Spirit:
          getSpiritList(undefined, 'analysis', perPage, currentPage).then((data) => {
            setProjects({ type: ProjectType.Spirit, list: data });
          });
          break;
        case ProjectType.JaneJohn:
          getJaneJohnList(undefined, perPage, currentPage).then((data) => {
            setProjects({ type: ProjectType.JaneJohn, list: data });
          });
          break;
        case ProjectType.Other:
          getOtherList(undefined, 'analysis', perPage, currentPage).then((data) => {
            setProjects({ type: ProjectType.Other, list: data });
          });
          break;
        default:
      }
    },
    [projectType]
  );

  console.log('projects', projects);

  return (
    <>
      <MainLayout
        sidebarProps={{
          firstSideBarselected: firstSidebarSelect.analysis,
          secondSideBarselected: selectedSecondSidebarValue(projectType),
        }}
      >
        <div className="h-[auto] w-[1181px] bg-gray-50">
          <div>{listHeader(projectType)}</div>
          <div>
            <div className="pt-6">
              <div className="flex justify-center">
                <div className="flex flex-col">
                  <AnalysisTable
                    projectType={projectType}
                    projects={projects}
                    updateProjectList={updateProjectList}
                  ></AnalysisTable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    </>
  );
}
