import donutChart, { labelColor } from './donutChart';
interface Props {
  project_title?: string;
}

export default function FollowerAttributeTwitter({ project_title = 'SPIRIT' }: Props) {
  const folloerSeries = [25, 21, 17, 13, 8, 4, 2, 2, 2, 2, 2, 2];

  const followerLabels: labelColor[] = [
    {
      label: '10代女性 (25%)',
      color: '#004A75',
    },
    {
      label: '20代女性 (21%)',
      color: '#006199',
    },
    {
      label: '30代女性 (17%)',
      color: '#007CC2',
    },
    {
      label: '40代女性 (13%)',
      color: '#00A2FF',
    },
    {
      label: '50代女性 (8%)',
      color: '#42BAFF',
    },
    {
      label: '60代以上女性 (4%)',
      color: '#80D0FF',
    },
    {
      label: '10代男性 (2%)',
      color: '#C2E9FF',
    },
    {
      label: '20代男性(2%)',
      color: '#D1D5DB',
    },
    {
      label: '30代男性 (2%)',
      color: '#D1D5DB',
    },
    {
      label: '40代男性 (2%)',
      color: '#D1D5DB',
    },
    {
      label: '50代男性 (2%)',
      color: '#D1D5DB',
    },
    {
      label: '60代以上男性 (2%)',
      color: '#D1D5DB',
    },
  ];

  const genreSeries = [24, 20, 16, 12, 8, 4, 2, 4, 4, 4];
  const genreLabels: labelColor[] = [
    {
      label: 'ライフスタイル (24%)',
      color: '#004A75',
    },
    {
      label: 'ママ・ファミリー (20%)',
      color: '#006199',
    },
    {
      label: 'ライフスタイル (16%)',
      color: '#007CC2',
    },
    {
      label: 'ビューティー (12%)',
      color: '#00A2FF',
    },
    {
      label: 'ファッション (8%)',
      color: '#42BAFF',
    },
    {
      label: 'ママ・ファミリー (4%)',
      color: '#80D0FF',
    },
    {
      label: 'ライフスタイル (2%)',
      color: '#C2E9FF',
    },
    {
      label: 'ビューティー (4%)',
      color: '#D1D5DB',
    },
    {
      label: 'トラベル (4%)',
      color: '#D1D5DB',
    },
    {
      label: 'ファッション (4%)',
      color: '#D1D5DB',
    },
  ];

  const valuesSeries = [24, 20, 16, 12, 8, 4, 2, 4, 4, 4];
  const valuesLabels: labelColor[] =
    project_title == 'JANEJOHN' || project_title == 'OTHER'
      ? [
          {
            label: '完全でありたい人 (24%)',
            color: '#004A75',
          },
          {
            label: '成功を追い求める人 (20%)',
            color: '#006199',
          },
          {
            label: '人の助けになりたい人 (16%)',
            color: '#007CC2',
          },
          {
            label: '特別な存在であろうとする人 (12%)',
            color: '#00A2FF',
          },
          {
            label: '成功を追い求める人 (8%)',
            color: '#42BAFF',
          },
          {
            label: '人の助けになりたい人 (4%)',
            color: '#80D0FF',
          },
          {
            label: '完全でありたい人 (2%)',
            color: '#C2E9FF',
          },
          {
            label: '成功を追い求める人 (4%)',
            color: '#D1D5DB',
          },
          {
            label: '知識を得て観察する人 (4%)',
            color: '#D1D5DB',
          },
          {
            label: '完全でありたい人 (4%)',
            color: '#D1D5DB',
          },
        ]
      : [
          {
            label: '完全でありたい人 (24%)',
            color: '#004A75',
          },
          {
            label: '成功を追い求める人 (20%)',
            color: '#006199',
          },
          {
            label: '特別な存在であろうとする人 (12%)',
            color: '#007CC2',
          },
          {
            label: '成功を追い求める人 (8%)',
            color: '#00A2FF',
          },
          {
            label: '完全でありたい人 (2%)',
            color: '#42BAFF',
          },
          {
            label: '成功を追い求める人 (4%)',
            color: '#80D0FF',
          },
          {
            label: '知識を得て観察する人 (4%)',
            color: '#C2E9FF',
          },
          {
            label: '完全でありたい人 (4%)',
            color: '#D1D5DB',
          },
        ];

  return (
    <div className="flex flex-col items-start gap-10 self-stretch p-6">
      <div className="flex items-start gap-8 self-stretch">
        {donutChart({
          title: 'フォロワーの男女比/年齢分布',
          series: folloerSeries,
          labels: followerLabels,
        })}
        {donutChart({
          title: 'フォロワーのジャンル',
          series: genreSeries,
          labels: genreLabels,
        })}
      </div>
      <div className="flex items-start gap-8 self-stretch">
        {donutChart({
          title: '価値観',
          series: valuesSeries,
          labels: valuesLabels,
        })}
        <></>
      </div>
    </div>
  );
}
