import * as Sentry from '@sentry/react';

import {
  DefaultApi as AnalisysDefaultApi,
  GetAnalysisFeedbackOutput,
  GetAnalysisProjectFeedbackOutput,
} from '@/api/analysis/api';
import {
  DefaultApi as OtherDefaultApi,
  GetAnalysisOtherFeedbackAdoptersOutput,
  GetAnalysisOtherFeedbackAdoptersRowsEnum,
  GetAnalysisOtherAdoptersOutput,
  GetAnalysisOtherAdoptersRowsEnum,
  GetAnalysisOtherAdoptersOrderEnum,
} from '@/api/analysisOther';
import { AnalysisSpiritPostTask, GetAnalysisSpiritPostTaskPostsOutput } from '@/api/analysisSpirit';
import {
  DefaultApi as SpiritDefaultApi,
  GetAnalysisSpiritSummaryOutput,
  GetAnalysisSpiritFeedbackAdoptersOutput,
  GetAnalysisSpiritFeedbackAdoptersRowsEnum,
} from '@/api/analysisSpirit/api';
import { axiosInstance } from '@/utils/axiosInstance';

const api = new SpiritDefaultApi(undefined, undefined, axiosInstance);
const analysisApi = new AnalisysDefaultApi(undefined, undefined, axiosInstance);
const otherAnalysisApi = new OtherDefaultApi(undefined, undefined, axiosInstance);

/**
 * 案件分析 - サマリー取得API SPRIT
 */
export const getSummary = async (projectId: string): Promise<GetAnalysisSpiritSummaryOutput> => {
  try {
    const response = await api.getAnalysisSpiritSummary(projectId);
    const responseData: GetAnalysisSpiritSummaryOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - 投稿稼働一覧取得API SPIRIT
 */
export const getSpritPostTasks = async (projectId: string): Promise<AnalysisSpiritPostTask[]> => {
  try {
    const response = await api.getAnalysisSpiritPostTasks(projectId);
    const responseData: AnalysisSpiritPostTask[] = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - 投稿一覧取得API SPIRIT
 */
export const getSpritPostsByTaskId = async (taskId: string): Promise<GetAnalysisSpiritPostTaskPostsOutput> => {
  try {
    const response = await api.getAnalysisSpiritPostTaskPosts(taskId);
    const responseData: GetAnalysisSpiritPostTaskPostsOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - Feedback採用者一覧取得API
 */
export const getAnalysisSpiritFeedbackAdopters = async (
  projectId: string,
  rows?: GetAnalysisSpiritFeedbackAdoptersRowsEnum,
  page?: number
): Promise<GetAnalysisSpiritFeedbackAdoptersOutput> => {
  try {
    const response = await api.getAnalysisSpiritFeedbackAdopters(projectId, rows, page);
    const responseData: GetAnalysisSpiritFeedbackAdoptersOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - Feedback取得API
 */
export const getAnalysisFeedback = async (feedbackId: string): Promise<GetAnalysisFeedbackOutput> => {
  try {
    const response = await analysisApi.getAnalysisFeedback(feedbackId);
    const responseData: GetAnalysisFeedbackOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - 案件フィードバック取得API analysis
 */

export const getAnalysisProjectFeedback = async (projectId: string): Promise<GetAnalysisProjectFeedbackOutput> => {
  try {
    const response = await analysisApi.getAnalysisProjectFeedback(projectId);
    const responseData = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - 採用者一覧取得API
 */
export const getAnalysisOtherAdopters = async (
  projectId: string,
  rows?: GetAnalysisOtherAdoptersRowsEnum,
  page?: number,
  order?: GetAnalysisOtherAdoptersOrderEnum,
  orderBy?: string
): Promise<GetAnalysisOtherAdoptersOutput> => {
  try {
    const response = await otherAnalysisApi.getAnalysisOtherAdopters(projectId, rows, page, order, orderBy);
    const responseData: GetAnalysisOtherAdoptersOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件分析 - Feedback採用者一覧取得API
 */
export const getAnalysisOtherFeedbackAdopters = async (
  projectId: string,
  rows?: GetAnalysisOtherFeedbackAdoptersRowsEnum,
  page?: number
): Promise<GetAnalysisOtherFeedbackAdoptersOutput> => {
  try {
    const response = await otherAnalysisApi.getAnalysisOtherFeedbackAdopters(projectId, rows, page);
    const responseData: GetAnalysisOtherFeedbackAdoptersOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
