import React from 'react';

export default function OneList(content_left: string, content_right: string, is_center: boolean): React.ReactElement {
  const text_right = content_right.split(/(\n)/).map((item, index) => {
    return <React.Fragment key={index}>{item.match(/\n/) ? <br /> : item}</React.Fragment>;
  });
  return (
    <>
      <div className={`flex h-auto min-h-[78px] ${is_center ? 'items-center' : ''}`}>
        <div className={`h-auto w-[326px] text-gray-700 ${is_center ? '' : 'pt-[20px]'}`}>{content_left}</div>
        <div className="h-auto w-[482px] whitespace-pre-wrap py-[20px] text-sm font-normal leading-5 text-gray-900">
          {text_right}
        </div>
      </div>
      <div className="h-[1px] w-[963px] ">
        <div className="border-b-[1px] border-gray-200 "></div>
      </div>
    </>
  );
}
