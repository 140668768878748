import React from 'react';

import DotsDropdown from './DotsDropdown';
interface PostInfoProps {
  manageName: string;
  createDate: string;
}

export const PostInfo: React.FC<PostInfoProps> = ({ manageName, createDate }) => {
  return (
    <>
      <div className="flex border-[0.5px] border-gray-200  bg-white">
        <div className="inline-flex h-[72px] w-[232px] items-center py-[16px] pl-[24px]">
          <div className="text-sm font-normal leading-5 tracking-wider text-gray-500">{manageName}</div>
        </div>
        <div className="inline-flex h-[72px] w-[215px] items-center whitespace-nowrap py-[16px] pl-[24px]">
          <div className="text-sm font-normal leading-5 tracking-wider text-gray-500">{createDate}</div>
        </div>
        <div className="inline-flex h-[72px] w-[57px] items-center py-[16px] pl-[380px]">
          <DotsDropdown />
        </div>
      </div>
    </>
  );
};
