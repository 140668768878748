import { ReactComponent as SeparatorImage } from './assets/Seperator.svg';
import StepImage from './assets/Step.png';

export const StepPanel_ImageApproving = () => {
  return (
    <>
      <div className="mb-[25px] h-[72px] w-[1133px] bg-[#FFFFFF] px-[24px]">
        {/* パネル↓ */}
        <div className="mb-[24px] flex h-[72px] w-[1133px] items-center rounded border-[1px] border-gray-300">
          {/* 01イメージ案制作 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <img src={StepImage} alt="" className="h-[40px] w-[40px]" />
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-[#006199]">イメージ案制作</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 02イメージ案承認 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-[#006199]">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-[#006199]">02</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">イメージ案承認</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 03コンテンツ制作 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">03</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">コンテンツ制作</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 04コンテンツ承認 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">04</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">コンテンツ承認</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 05完了 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">05</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">完了</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
