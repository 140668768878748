import PlusImage from './assets/bx-plus-circle.svg';
import InstagramImage from './assets/icon-instagram.svg';
import TwitterImage from './assets/icon-twitter.svg';
import PostAddImage from './assets/PostAdd.svg';
import Pagenation from './Pagenation';
import PostList from './PostList';

export default function MainView() {
  return (
    <div className="relative w-[1181px] bg-gray-50">
      {/* ヘッダー部分↓ */}
      <div className="flex h-[61px] w-[1149px] border-b border-gray-200">
        <p className="my-[16px] ml-[16px] h-[28px] w-[285px] text-base font-semibold leading-7 text-gray-700">
          PRST(制作案件) 投稿制作管理一覧
        </p>
      </div>
      <div className="flex flex-row">
        <div className="h-[529px] w-[203px] border-r border-gray-200 p-[16px]">
          <div className="flex flex-col items-start">
            <p className="text-sm font-medium leading-4">アカウント一覧</p>
            <div className="mt-[17px] flex flex-row items-center justify-items-center gap-[16px] rounded-[8px] bg-gray-200 p-[8px]">
              <img src={InstagramImage} alt="" className="h-[20px] w-[20px]" />
              <p className="text-sm font-medium leading-4">Leslie Alexander</p>
            </div>
            <div className="mt-[17px] flex flex-row items-center justify-items-center gap-[16px] rounded-[8px] bg-gray-50 p-[8px]">
              <img src={TwitterImage} alt="" className="h-[20px] w-[20px]" />
              <p className="text-sm font-medium leading-4">Leslie Alexander</p>
            </div>
            <div className="mt-[17px] flex flex-row items-center justify-items-center gap-[16px] rounded-[8px] bg-gray-50 p-[8px]">
              <img src={PlusImage} alt="" className="h-[20px] w-[20px]" />
              <p className="text-sm font-medium leading-4 text-gray-400">アカウント追加</p>
            </div>
          </div>
        </div>

        {/* メイン↓ */}
        <div className="w-[940px] px-[24px] pt-[24px]">
          <div className="flex justify-between">
            <div className="text-base font-semibold leading-7">施策一覧</div>
            <button
              type="button"
              className="inline-flex h-[38px] w-[1８６px] items-center gap-x-1.5 rounded-md bg-[#007CC2] px-[17px] py-[9px] text-xs font-semibold text-white shadow-sm"
            >
              <img src={PostAddImage} alt="" className="h-5 w-5" />
              施策を追加
            </button>
          </div>
          <div>
            <PostList />
          </div>
          {/*page 移動を行う場所 何件中、何件を表示*/}
          <div>
            <div>
              <Pagenation />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
