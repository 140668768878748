import { scheduleToString } from '@/utils/format';

import { projectOverview } from '../Interfaces/ProjectOverViewInterfaces';

import OneList from './OneList';

export default function ProjectOverview(props: projectOverview): React.ReactElement {
  const kpiToString = (kpi: string[]): string => kpi.join('');
  const budgetToString = (budget: number): string => `￥${budget.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  const orderToString = (orderInfo: projectOverview['orderInfo']): string => {
    return `社名：${orderInfo.companyName}\n住所：${orderInfo.address}\n代表者：${orderInfo.represent}\n設立：${orderInfo.established}\n資本金：${orderInfo.capital}\n事業内容：${orderInfo.business}`;
  };

  // 「・」を付ける関数
  const addBulletPoints = (text: string): string => {
    // テキストが空の場合
    if (!text) {
      return '-';
    }
    return text;
  };
  // SPIRITのKPIのみ「・」と改行があるため
  const replaceKpi = (kpi: string): string => {
    let result = kpi;
    if (result.startsWith('・')) {
      result = result.replace(/\n/g, '');
      result = result.replace('・', '');
    }
    return result;
  };

  return (
    <div>
      <div className="h-[24+49px] w-[1027px] pl-[64px]">
        <div className="text-lg font-medium leading-6 text-gray-900">
          {'案件概要'}
          {props.otherFlg && (
            <span className="ml-4 font-medium">
              {'※案件によって様々な条件になるので別途営業担当とすり合わせてください'}
            </span>
          )}
        </div>
      </div>
      <div className="w-[1027px] pl-[64px] pt-[20px]">
        <div className=" border-[1px] border-gray-200 "></div>
        <span>
          <div>{OneList('市場背景', addBulletPoints(props.marketBackground))}</div>
          <div>{OneList('課題•問題点', addBulletPoints(props.problem))}</div>
          <div>{OneList('施策目的', addBulletPoints(props.purpose))}</div>
          <div>{OneList('施策内容', addBulletPoints(props.measure))}</div>
          {/* <div>{OneList('評価項目（KPI）', kpiToString(props.kpi.map((kpi: string) => `・${replaceKpi(kpi)}\n`)))}</div> */}
          <div>
            {OneList(
              '評価項目（KPI）',
              props.kpi.length > 0 ? kpiToString(props.kpi.map((kpi: string) => `・${replaceKpi(kpi)}\n`)) : '-'
            )}
          </div>
          {/* <div>{OneList('予算', budgetToString(props.budget))}</div> */}
          <div>{OneList('予算', props.budget > 0 ? budgetToString(props.budget) : '-')}</div>
          <div>{OneList('施策全体スケジュール', scheduleToString(props.scheduleStartedAt, props.scheduleEndedAt))}</div>
          <div>{OneList('発注先の情報', orderToString(props.orderInfo))}</div>
        </span>
      </div>
    </div>
  );
}
