export enum modalArgsEnum {
  VIDEO,
  IMAGE,
  MULTIPLEMEDIA,
  CAROUSEL,
  NONE,
}

// 文字列定数の定義
export const VIDEO_STRING = 'VIDEO';
export const IMAGE_STRING = 'IMAGE';
export const MULTIPLEMEDIA_STRING = 'MULTIPLEMEDIA';
export const CAROUSEL_STRING = 'CAROUSEL';

// Enumの数値値に対応する文字列定義
export const modalArgsStrings = {
  [modalArgsEnum.VIDEO]: VIDEO_STRING,
  [modalArgsEnum.IMAGE]: IMAGE_STRING,
  [modalArgsEnum.MULTIPLEMEDIA]: MULTIPLEMEDIA_STRING,
  [modalArgsEnum.CAROUSEL]: CAROUSEL_STRING,
  [modalArgsEnum.NONE]: '',
};

// 文字列とEnumを比較する関数
export const isMatchingEnum = (str: string, enumValue: modalArgsEnum): boolean => {
  return modalArgsStrings[enumValue] === str;
};

// 文字列からEnumへの変換関数
export const stringToEnum = (str: string): modalArgsEnum => {
  switch (str.toUpperCase()) {
    case VIDEO_STRING:
      return modalArgsEnum.VIDEO;
    case IMAGE_STRING:
      return modalArgsEnum.IMAGE;
    case MULTIPLEMEDIA_STRING:
      return modalArgsEnum.MULTIPLEMEDIA;
    case CAROUSEL_STRING:
      return modalArgsEnum.CAROUSEL;
    default:
      return modalArgsEnum.NONE;
  }
};

// Enumから文字列への変換関数
export const enumToString = (enumValue: modalArgsEnum): string => {
  return modalArgsStrings[enumValue];
};
