import { FirstSidebar } from '@/components/Elements/FirstSideBar/FirstSidebar';
import { SecondSidebar } from '@/components/Elements/SecondSideBar';
import { SecondSideBarAnalysis } from '@/components/Elements/SecondSideBar_analysis/SecondSideBarAnalysis';
import { SecondSideBarSetting } from '@/components/Elements/SecondSideBar_setting/SecondSideBarSetting';
import { firstSidebarSelect } from '@/config/sidebar';

export interface SidebarProps {
  firstSideBarselected: string;
  secondSideBarselected: string;
}

export default function Sidebar(props: SidebarProps) {
  const selectSecondSideBar = () => {
    if (props.firstSideBarselected === firstSidebarSelect.calendar) {
      return <SecondSidebar selected={props.secondSideBarselected} />;
    } else if (props.firstSideBarselected === firstSidebarSelect.analysis) {
      return <SecondSideBarAnalysis selected={props.secondSideBarselected} />;
    } else if (props.firstSideBarselected === firstSidebarSelect.basicInfo) {
      return <SecondSideBarSetting selected={props.secondSideBarselected} />;
    } else {
      return <></>;
    }
  };

  return (
    <div className="flex w-[258px]">
      <span className="fixed left-0 z-50 h-full w-[62px]">
        <FirstSidebar selected={props.firstSideBarselected} />
      </span>
      <span className="ml-[62px]"></span>
      <span className="fixed left-[62px] z-0 w-[196px]">{selectSecondSideBar()}</span>
      <span className="ml-[196px]"></span>
    </div>
  );
}
