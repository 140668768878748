import { SxProps, Theme } from '@mui/material';

export const ButtonStyleBase: SxProps<Theme> = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
};

export const ButtonStyleSmall: SxProps<Theme> = {
  ...ButtonStyleBase,
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '16px',
  width: '98px',
  height: '40px',
};

export const ButtonStyleMedium: SxProps<Theme> = {
  ...ButtonStyleBase,
  fontWeight: 400,
  fontSize: '18px',
  lineHeight: '16px',
  width: '216px',
  height: '40px',
};
