import clsx from 'clsx';
import { useEffect, useState } from 'react';

import {
  AnalysisOtherAdopterItem,
  GetAnalysisOtherAdoptersOutput,
  GetAnalysisOtherAdoptersRowsEnum,
} from '@/api/analysisOther';
import { SnsType } from '@/api/influencer';
import NoImage from '@/assets/images/NoImage.png';
import { MIN_PAGE_NUMBER, Pagenation } from '@/components/Elements/Pagenation/Pagenation';
import { EvaluationCell } from '@/components/ProjectManagements/Applicant/Contents/common/Parts/EvaluationCell';
import { ListCell } from '@/components/ProjectManagements/Applicant/Contents/common/Parts/ListCell';
import {
  arrow_css_type,
  arrow_up_css_type,
} from '@/components/ProjectManagements/Applicant/Contents/common/Types/common_types';
import { getAnalysisOtherAdopters } from '@/hooks/Analysis';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { getImageUrlIfRelative } from '@/utils/image';

import ModalCommon from '../posts/modals/modalCommon';
import { tableBodyCommonProps } from '../posts/modals/modalInterface';

export type InfluencerTableItemInfo = {
  applicationOrder: number;
  icon: string;
  id: string; // SNSAccountNo
  evaluation: number;
  evaluationCount: number;
  applicantId: string;
};

const arrow_under_css: arrow_css_type = {
  display: 'block',
  width: '9px',
  height: '9px',
  borderTop: 'solid 2px #4B5563',
  borderRight: 'solid 2px #4B5563',
  borderColor: '#4B5563 #4B5563 transparent transparent',
  transform: 'rotate(135deg)',
};
const arrow_up_css: arrow_up_css_type = {
  display: 'block',
  width: '9px',
  height: '9px',
  borderTop: 'solid 2px #4B5563',
  borderRight: 'solid 2px #4B5563',
  borderColor: '#4B5563 #4B5563 transparent transparent',
  transform: 'rotate(315deg)',
  marginTop: '4px',
};

type SortStatus = {
  isClicked: 'evaluation' | 'applicantIndex' | 'none';
  applicantIndex?: 'asc' | 'desc';
  evaluation?: 'asc' | 'desc';
};

type InfluencerListProps = {
  projectId: string;
  snsType: SnsType;
};

export const InfluencerList = (props: InfluencerListProps) => {
  const [sortItem, setSortItem] = useState<SortStatus>({ isClicked: 'none' });
  const [currentPageNumber, setCurrentPageNumber] = useState(MIN_PAGE_NUMBER);
  const [currentPageSize, setCurrentPageSize] = useState<GetAnalysisOtherAdoptersRowsEnum>(10);
  const [displayData, setDisplayData] = useState<GetAnalysisOtherAdoptersOutput>({
    from: 0,
    perPage: 0,
    rows: [],
    to: 0,
    totalPages: 0,
    totalRows: 0,
  });
  const [isCompleteLoading, setIsCompleteLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const [modalInformation, setModalInformation] = useState<tableBodyCommonProps>({
    icon: '', // アイコン
    id: '', //ID
    applyingInfluencerId: '', // 応募者ID
    image: '', //サムネイル
    numberOfEngagements: 0, //エンゲージメント数
  });

  const handleOpenModal = (info: AnalysisOtherAdopterItem) => {
    const modalData: tableBodyCommonProps = {
      icon: info.thumbnail ?? NoImage,
      id: info.snsAccountId,
      applyingInfluencerId: info.applyingInfluencerId,
      image: info.thumbnail ?? NoImage,
      numberOfEngagements: 0,
    };
    setModalInformation(modalData);
    setIsOpen(true);
    //setIndex(index);
  };

  useEffect(() => {
    const order =
      sortItem.isClicked === 'applicantIndex'
        ? sortItem.applicantIndex
        : sortItem.isClicked === 'evaluation'
        ? sortItem.evaluation
        : undefined;
    const orderBy = sortItem.isClicked === 'evaluation' ? 'evaluation' : undefined;
    getAnalysisOtherAdopters(props.projectId, currentPageSize, currentPageNumber, order, orderBy).then((response) => {
      setIsCompleteLoading(true);
      setDisplayData(response);
    });
  }, [props.projectId, sortItem, currentPageNumber, currentPageSize]);

  return (
    <div className="m-4 w-full">
      <div>
        <div className="font-bold">インフルエンサー一覧</div>
      </div>
      <div className="mt-5 w-full overflow-auto rounded-[8px] border-[1px] border-gray-200">
        <table className="w-[1007px] overflow-auto">
          <thead className="block border-b border-gray-300 bg-white">
            <tr className="h-[72px] text-sm font-medium leading-4 tracking-wider text-gray-500">
              <th className="h-full w-[114px]">
                <div className="flex justify-center">
                  <span className="mr-1">応募順</span>
                  <div className="flex h-[19px] w-[20px] items-center justify-center rounded bg-gray-200">
                    {sortItem.isClicked === 'none' || sortItem.applicantIndex === 'desc' ? (
                      <button
                        onClick={() => setSortItem({ isClicked: 'applicantIndex', applicantIndex: 'asc' })}
                        style={arrow_under_css}
                      ></button>
                    ) : (
                      <button
                        onClick={() => setSortItem({ isClicked: 'applicantIndex', applicantIndex: 'desc' })}
                        style={arrow_up_css}
                      ></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="h-full w-[114px]">アイコン</th>
              <th className="h-full w-[114px]">ID</th>
              <th className="h-full w-[665px] pl-[12px]">
                <div className="flex">
                  <span className="mr-1">評価</span>
                  <div className="flex h-[19px] w-[20px] items-center justify-center rounded bg-gray-200">
                    {sortItem.isClicked === 'none' || sortItem.evaluation === 'desc' ? (
                      <button
                        onClick={() => setSortItem({ isClicked: 'evaluation', evaluation: 'asc' })}
                        style={arrow_under_css}
                      ></button>
                    ) : (
                      <button
                        onClick={() => setSortItem({ isClicked: 'evaluation', evaluation: 'desc' })}
                        style={arrow_up_css}
                      ></button>
                    )}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody
            className={`block max-h-[432px] w-[1007px] overflow-x-hidden ${
              displayData.rows.length > 6 ? 'overflow-y-scroll' : ''
            }`}
          >
            {displayData.rows.length === 0 ? (
              <tr className="h-[72px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                <td colSpan={4} className="pl-10 text-center">
                  {isCompleteLoading ? 'データがありません' : 'データ取得中です'}
                </td>
              </tr>
            ) : (
              displayData.rows.map((value: AnalysisOtherAdopterItem, index: number) => {
                return (
                  <tr
                    key={value.adoptedInfluencerId}
                    className={clsx(
                      index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
                      'h-[72px] cursor-pointer text-xs font-medium uppercase leading-4 tracking-wider text-gray-500'
                    )}
                    onClick={() => {
                      handleOpenModal(value);
                    }}
                  >
                    <td className="h-full w-[114px]">
                      <ListCell value={value.applicantIndex} />
                    </td>
                    <td className="h-full w-[114px]">
                      <div className="flex w-full justify-center">
                        <img
                          src={getImageUrlIfRelative(value.thumbnail ?? NoImage)}
                          alt="icon"
                          className="h-[40px] w-[40px] justify-center rounded-full"
                        />
                      </div>
                    </td>
                    <td className="h-full w-[114px]">
                      <ListCell value={value.snsAccountNo} />
                    </td>
                    <td className="h-full w-[665px]">
                      <EvaluationCell
                        style={'flex pl-[12px]'}
                        evaluation={value.evaluation ?? 0}
                        evaluationCount={value.evaluationCounts}
                      />
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
        {displayData.rows.length !== 0 && (
          <Pagenation
            currentPageNumber={currentPageNumber}
            currentPageSize={currentPageSize}
            togalDataCount={displayData.totalRows}
            totalPageNumber={displayData.totalPages}
            setPageNumber={setCurrentPageNumber}
            setPageSize={setCurrentPageSize}
          />
        )}
      </div>
      <ModalCommon
        open={isOpen}
        snsType={props.snsType}
        setOpen={setIsOpen}
        accountInformation={modalInformation}
        initialTabKey="accountInsight"
        modalType={'sns'}
        getInfluencerData={fetchInfluencerApplicantDetail}
      />
    </div>
  );
};

// const mockModalData = async (snsType: string, applicantId: string): Promise<InfuluencerAccountDetailBase> => {
//   console.log(applicantId);
//   return {
//     insight: mockSnsAccountInsightData,
//     followerAttributes: mockFollowerAttribute,
//     applyingComment: mockApplyingComment,
//     accountPr: mockAccountPr,
//     snsType: snsType as SnsType,
//     evaluation: mockEvaluation,
//     posts: mockPosts,
//   };
// };

// const accountInfomation = {
//   icon: NoImage,
//   id: 'mock_id',
//   mediaType: 'image',
//   applyingInfluencerId: 'mock_applyingInfluencerId',
//   engagementRate: '100',
//   numberOfEngagements: 100,
//   goods: 100,
//   comment: 100,
//   repost: 100,
//   sumViewNum: 100,
//   postCount: 100,
//   frequency: 100,
//   uniqueAccountCount: 100,
//   impressionCount: 100,
//   tapsForwardCount: 100,
//   tapsBackCount: 100,
//   exitsCount: 100,
//   engagementCount: 100,
//   goodCount: 100,
//   commentCount: 100,
//   saveCount: 100,
//   shareCount: 100,
//   viewCount: 100,
// };
// const mockPosts = {
//   appealPosts: [],
//   recentPosts: [
//     {
//       id: 'mock_id',
//       thumbnail: null,
//       postedAt: null,
//       mediaProductType: PostDetailInstagramMediaProductTypeEnum.Ad,
//       mediaType: PostDetailInstagramMediaTypeEnum.Image,
//       caption: null,
//       url: '',
//       impressions: null,
//       likeCounts: null,
//       commentCounts: null,
//       shareCounts: null,
//       savedCounts: null,
//       engagementCounts: null,
//       engagementRate: null,
//       reachCounts: null,
//       frequency: null,
//     },
//   ],
// };
// const mockEvaluation = {
//   evaluation: 5,
//   evaluationCounts: 100,
//   evaluationRates: [
//     {
//       rate: 100,
//       value: 5,
//     },
//   ],
// };
// const mockAccountPr = {
//   characteristic: 'mock_characteristic',
//   strongPoint: 'mock_strongPoint',
//   actualResults: 'mock_actualResults',
// };
// const mockApplyingComment = {
//   influencerComment: 'mockData_influencerComment',
//   operatorComment: 'mockData_operatorComment',
//   reserved: true,
// };
// const mockFollowerAttribute = {
//   ageDistribution: [
//     {
//       distribution: AgeDistributionType._1317,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//     {
//       distribution: AgeDistributionType._1824,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//     {
//       distribution: AgeDistributionType._2534,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//     {
//       distribution: AgeDistributionType._3544,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//     {
//       distribution: AgeDistributionType._4554,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//     {
//       distribution: AgeDistributionType._5564,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//     {
//       distribution: AgeDistributionType._65,
//       mans: 100,
//       womans: 100,
//       others: 100,
//     },
//   ],
//   prefDistribution: [
//     {
//       prefCode: '01',
//       value: 100,
//     },
//     {
//       prefCode: '02',
//       value: 100,
//     },
//     {
//       prefCode: '03',
//       value: 100,
//     },
//     {
//       prefCode: '04',
//       value: 100,
//     },
//   ],
//   genderDistribution: [
//     {
//       gender: 1,
//       value: 100,
//       rate: 33,
//     },
//     {
//       gender: 2,
//       value: 100,
//       rate: 33,
//     },
//     {
//       gender: 3,
//       value: 100,
//       rate: 33,
//     },
//   ],
//   followers: {
//     mans: 100,
//     womans: 100,
//     others: 100,
//   },
//   top3: {
//     ageGender: ['1001', '1002', '1003'],
//     genre: ['1001', '1002', '1003'],
//     values: ['1001', '1002', '1003'],
//   },
// };
// const mockSnsAccountInsightData = {
//   followers: 100,
//   followings: 100,
//   impressions: 100,
//   reach: 100,
//   reachRate: 100,
//   good: 100,
//   goodAvg: 100,
//   comments: 100,
//   commentsAvg: 100,
//   share: 100,
//   shareAvg: 100,
//   preservations: 100,
//   preservationAvg: 100,
//   engagements: 100,
//   engagementRate: 100,
//   frequency: 100,
//   postCounts: 100,
//   // tiktok youtube
//   play: 100,
//   playRate: 100,
//   // x
//   reply: 100,
//   replyAvg: 100,
//   repost: 100,
//   repostAvg: 100,
//   // youtube
//   subscribers: 100,
//   movies: 100,
// };
