import { SnsType } from '@/api/influencer';
import { Stat } from '@/components/Elements/Stat';
import { isInstagram } from '@/utils/sns';

interface insightProps {
  icon: React.ReactElement<any, any>;
  label: string;
  value: string;
  className: string;
}

export interface insightPair {
  left: insightProps;
  right?: insightProps;
}

export interface accountInsightProps {
  insights: insightPair[];
  recentCount?: number;
  snsType?: SnsType;
}

// SNSアカウント詳細_アカウントインサイトのアイコン・文字表示部品(いいね数、エンゲージメント率等)
export default function AccountInsight(props: accountInsightProps) {
  return (
    <div className="flex flex-col items-start gap-2">
      <div>
        <div className="text-sm font-semibold leading-5 text-gray-900">
          直近{props.recentCount ?? 0}投稿での平均インサイト
        </div>
        {isInstagram(props.snsType ?? 'other') && (
          <div className="text-sm font-semibold leading-5 text-gray-900">
            ※インプレッション数は、リール投稿以外での平均値
          </div>
        )}
      </div>
      {props.insights.map((insight: insightPair, index: number) => {
        return (
          <div key={index} className="flex items-center gap-2 self-stretch">
            <Stat
              icon={insight.left.icon}
              lable={insight.left.label}
              value={insight.left.value}
              className={insight.left.className}
            />
            {insight.right && (
              <Stat
                icon={insight.right.icon}
                lable={insight.right.label}
                value={insight.right.value}
                className={insight.right.className}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}
