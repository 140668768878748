import clsx from 'clsx';
export interface LeadingContentProps {
  icon: React.ReactElement<any, any>;
  label: string;
  value: string;
  type?: string;
  postValue?: {
    X: number;
    instagramStories: number;
    instagram: number;
  };
}

export default function LeadingContent(props: LeadingContentProps) {
  return (
    <div className="flex w-[362px] items-center gap-2 rounded-md border border-gray-200 bg-white shadow-sm">
      <div className="flex w-[362px] shrink-0 items-center gap-5 self-stretch p-6">
        <div className="flex flex-1 flex-col items-start gap-2">
          <div className="flex items-center gap-5">
            <div className="flex items-center justify-center rounded-md bg-[#007CC2] p-3">{props.icon}</div>
            <div
              className={clsx(
                'flex flex-col items-start self-stretch',
                props.type == 'post' ? 'w-[70px]' : 'w-[250px]'
              )}
            >
              <span className="self-stretch text-sm font-medium leading-5 text-gray-500">{props.label}</span>
              <span className="text-2xl font-semibold leading-8 text-gray-900">
                {props.type == 'post' && props.postValue
                  ? props.postValue?.instagram + props.postValue?.instagramStories + props.postValue?.X
                  : props.value}
              </span>
            </div>
            {props.type == 'post' ? (
              <div className="text-sm text-[#6B7280]">
                <div>
                  X <span className="font-bold text-[#111827]">{props.postValue?.X}</span>
                </div>
                <div>
                  Instagram Stories{' '}
                  <span className="font-bold text-[#111827]">{props.postValue?.instagramStories}</span>
                </div>
                <div>
                  Instagram <span className="font-bold text-[#111827]">{props.postValue?.instagram}</span>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
