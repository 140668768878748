import CheckImage from './assets/check-circle.svg';

export function InvitationResendAlert(): React.ReactElement {
  return (
    <>
      <img src={CheckImage} alt="" className="mr-[13.67px]" />
      <p className="text-sm font-medium leading-5 text-[#065F46]">
        「cody.fisher@example.com」に招待メールを再送しました
      </p>
    </>
  );
}
