import { useState, useEffect, useRef } from 'react';

import DropdownImage from './assets/Dropdown.svg';

export function Dropdown({ selected, setSelected }: any): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const insideRef = useRef<HTMLDivElement>(null);
  const options = ['管理者', '一般'];

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsActive(false);
      } else {
        return;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [insideRef]);

  return (
    <div ref={insideRef} className="relative inline-block text-left">
      <button
        className="inline-flex h-[38px] w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-[19px] py-[8px] text-sm font-medium leading-5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
        onClick={() => setIsActive(!isActive)}
      >
        {selected}
        <img src={DropdownImage} alt="" className="ml-[5.24px] h-[5px] w-[9px]" />
      </button>
      {isActive && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <a
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                href="#"
                key={option}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
