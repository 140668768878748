import React, { createContext, useContext, useState, useEffect } from 'react';

import {
  Comment,
  AccountContextType,
  Account,
  Project,
  Post,
  Reply,
} from '../interfaces/PRSTProductionManagementInterface';

export const AccountContext = createContext<AccountContextType | undefined>(undefined);

type Props = {
  children?: React.ReactNode;
};

export const AccountProvider: React.FC<Props> = ({ children }) => {
  const [accounts, setAccounts] = useState<Account[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [posts, setPosts] = useState<Post[]>([]);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(null);
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const [imageComments, setImageComments] = useState<Comment[]>([]);
  // const [selectedImageComment, setSelectedImageComment] = useState<Comment | null>(null);
  const [imageCommentReplys, setImageCommentReplys] = useState<Reply[]>([]);
  const [contentComments, setContentComments] = useState<Comment[]>([]);
  const [contentCommentReplys, setContentCommentReplys] = useState<Reply[]>([]);

  const addAccount = (account: Account) => {
    setAccounts((prev) => [...prev, account]);
    console.log('---AccountContext.tsx---');
    console.log('新しく作成されたアカウント（account）:', account);
    // setSelectedAccount(account);
  };

  const addSelectedAccount = (account: Account | null) => {
    setSelectedAccount(account);
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
  };

  const addProject = (project: Project) => {
    if (selectedAccount) {
      const index = accounts.findIndex((account) => account.accountName === selectedAccount.accountName);
      if (index !== -1) {
        const newAccounts = [...accounts];
        newAccounts[index] = {
          ...newAccounts[index],
          projects: [...newAccounts[index].projects, project],
        };
        setAccounts(newAccounts);
      }
    }
    setProjects((prev) => [...prev, project]);
    setSelectedProject(project);
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
  };

  const addPost = (post: Post) => {
    if (selectedAccount) {
      const index = accounts.findIndex((account) => account.accountName === selectedAccount.accountName);
      const projectIndex = selectedAccount.projects.findIndex(
        (project) => project.projectName === selectedProject?.projectName
      );
      if (index !== -1) {
        const newAccounts = [...accounts];
        if (selectedProject) {
          const projectIndex = newAccounts[index].projects.findIndex(
            (project) => project.projectName === selectedProject.projectName
          );
          if (projectIndex !== -1) {
            newAccounts[index].projects[projectIndex] = {
              ...newAccounts[index].projects[projectIndex],
              posts: [...newAccounts[index].projects[projectIndex].posts, post],
            };
          }
        }
        setAccounts(newAccounts);
      }
      if (projectIndex !== -1) {
        const newProjects = [...selectedAccount.projects];
        newProjects[projectIndex] = {
          ...newProjects[projectIndex],
          posts: [...newProjects[projectIndex].posts, post],
        };
        setSelectedAccount(() => ({
          ...selectedAccount,
          projects: newProjects,
        }));
      }
    }
    setPosts((prev) => [...prev, post]);
    setSelectedPost(post);
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたアカウント（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
  };

  const addSelectedProject = (project: Project) => {
    if (selectedAccount) {
      setSelectedAccount(() => ({
        ...selectedAccount,
        projects: [...selectedAccount.projects, project],
      }));
    }
    setSelectedProject(project);
    console.log('---AccountContext.tsx---');
    console.log('選択されたアカウント（selectedProject）:', selectedProject);
  };

  const changeSelectedProject = (project: Project) => {
    setSelectedProject(project);
    console.log('---AccountContext.tsx---');
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
  };

  const addSelectedPost = (post: Post) => {
    if (selectedProject) {
      setSelectedProject(() => ({
        ...selectedProject,
        posts: [...selectedProject.posts, post],
      }));
    }
  };

  const changeSelectedPost = (post: Post) => {
    setSelectedPost(post);
    console.log('---AccountContext.tsx---');
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
  };

  const addImageComments = (comment: Comment) => {
    setImageComments((prev) => [...prev, comment]);
    console.log('追加されたイメージコメント全て', imageComments);
  };

  const addImageCommentReplys = (reply: Reply, index: number) => {
    imageComments[index].replys.push(reply);
    setImageCommentReplys((prev) => [...prev, reply]);
  };

  const addContentComments = (comment: Comment) => {
    setContentComments((prev) => [...prev, comment]);
    console.log('追加されたコンテンツコメント全て', contentComments);
  };

  const addContentCommentReplys = (reply: Reply, index: number) => {
    contentComments[index].replys.push(reply);
    setContentCommentReplys((prev) => [...prev, reply]);
  };
  // const addSelectedImageComment = (comment: Comment) => {
  //   setSelectedImageComment(comment);
  // };

  useEffect(() => {
    console.log('---AccountContext.tsx---');
    console.log('現在登録されているアカウント全て（accounts）:', accounts);
  }, [selectedAccount, accounts]);

  return (
    <AccountContext.Provider
      value={{
        accounts,
        addAccount,
        selectedAccount,
        setSelectedAccount,
        addSelectedAccount,
        projects,
        selectedProject,
        addProject,
        addSelectedProject,
        changeSelectedProject,
        posts,
        addPost,
        selectedPost,
        addSelectedPost,
        changeSelectedPost,
        // imageComments: [],
        imageComments,
        addImageComments,
        // contentComments: [],
        imageCommentReplys,
        addImageCommentReplys,
        contentComments,
        addContentComments,
        contentCommentReplys,
        addContentCommentReplys,
        // selectedImageComment,
        // addSelectedImageComment,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error('useAccount must be used within an AccountProvider');
  }
  return context;
};
