import { KPIItem } from './KPIItem';
const KPIItemDescription = {
  リーチ数: ['投稿を見たユニークアカウント数'],
  エンゲージメント数: ['各SNSでのフォロワーからの反応の合計'],
  エンゲージメント率: ['フォロワー数に対してのエンゲージメント数の割合', 'エンゲージメント数/フォロワー数'],
  フォロワー増加数: ['フォロワー数の増加数'],
};

const selectDescription = (key: string): string[] => {
  return key in KPIItemDescription ? KPIItemDescription[key as keyof typeof KPIItemDescription] : [];
};

export interface KPIProps {
  name: string;
  comment: string;
  descriptionList?: string[];
  isFontBold?: boolean;
}
export default function KpiComponent(props: KPIProps[]) {
  return (
    <div className="h-[326+40px] w-[726.5px] gap-[16px] rounded-lg bg-white py-[40px] pl-[24px]">
      <div className="flex h-[28px] w-[678.75px] justify-between">
        <div className="h-[28px] w-[344px] content-center">
          <span className="text-lg font-semibold leading-7 text-gray-500">KPI</span>
        </div>
      </div>
      <div className="flex h-[36px] w-[678.75px] justify-between pt-[16px]">
        <div className="h-[28px] w-[344px] content-center">
          <span className="text-sm font-semibold leading-7 text-gray-400">指標</span>
        </div>
      </div>
      {props.map((item: KPIProps) => {
        return (
          <div key={item.name}>
            <KPIItem
              name={item.name}
              comment={item.comment ?? '-'}
              descriptionList={selectDescription(item.name)}
              isFontBold={item.isFontBold}
            />
          </div>
        );
      })}
    </div>
  );
}
