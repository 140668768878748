import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { apiRequest } from '@/api/ApiUtils';
import {
  AffectOutput,
  DefaultApi,
  PatchProjectAdoptionOutput,
  ProjectAdoption,
  PostProjectFeedbackOutput,
} from '@/api/project';
import {
  FetchProjectOverviewResponse,
  projectPostApplicationResponseType,
  projectPostCreateRequestBodyType,
  projectPostCreateResponseType,
} from '@/types/project';
import { axiosInstance } from '@/utils/axiosInstance';
const api = new DefaultApi(undefined, undefined, axiosInstance);

// CreateAPI
export const insertPostCreateInfo = async (
  request: projectPostCreateRequestBodyType
): Promise<projectPostCreateResponseType> => {
  return apiRequest<projectPostCreateResponseType, projectPostCreateRequestBodyType>(
    `/projects`,
    { method: 'POST', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// 複製API
export const insertPostReplicationInfo = async (projectId: string): Promise<projectPostCreateResponseType> => {
  return apiRequest<projectPostCreateResponseType>(`/projects/${projectId}/replication`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

// 案件内容申請API
export const insertPostApplicationInfo = async (projectId: string): Promise<projectPostApplicationResponseType> => {
  return apiRequest<projectPostApplicationResponseType>(`/projects/${projectId}/application`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
  });
};

// 案件概要取得API
export const FetchProjectOverviewInfo = async (projectId: string): Promise<FetchProjectOverviewResponse> => {
  return apiRequest<FetchProjectOverviewResponse>(`/projects/${projectId}/overview`, {
    method: 'GET',
  });
};

// 案件採用者設定API
export const patchProjectAdoption = async (
  projectId: string,
  adoptionDataList: ProjectAdoption[]
): Promise<PatchProjectAdoptionOutput> => {
  try {
    const response: AxiosResponse<PatchProjectAdoptionOutput> = await api.patchProjectAdoption(
      projectId,
      adoptionDataList
    );
    return response.data as PatchProjectAdoptionOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// 案件採用者確定API
export const patchProjectAdoptionDecision = async (projectId: string): Promise<AffectOutput> => {
  try {
    const response: AxiosResponse<AffectOutput> = await api.patchProjectAdopterDecision(projectId);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// フィードバック確定API
export const feedbackConfirm = async (projectId: string): Promise<PostProjectFeedbackOutput> => {
  try {
    const response: AxiosResponse<PostProjectFeedbackOutput> = await api.postProjectFeedback(projectId);
    return response.data as PostProjectFeedbackOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
