import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ProjectDetailHeader } from '@/api/emeraldPost';
import { ReactComponent as LeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { PROJECT_TYPES } from '@/config';
import { scheduleToString } from '@/utils/format';

export function PageHeader(props: ProjectDetailHeader): React.ReactElement {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex h-[60px] w-[1149px] flex-row pt-[10px]">
        <div className="cursor-pointer pl-[24px] pt-[16px]">
          <LeftIcon
            height={24}
            width={24}
            fill="#9CA3AF"
            onClick={() => {
              navigate(`/project_management/list?type=${PROJECT_TYPES.EMERALD_POST}`);
            }}
          />
        </div>

        <div className="pl-[25.29px] pt-[5px]">
          <div className="h-[12px] w-[161px] text-xs font-normal leading-none">{props.projectGroupName}</div>
          <div className="flex">
            <div className="pt-[4px] text-base font-bold leading-6">{props.projectName}</div>
            <div className="pl-[8px] pt-[3px]">
              <span className="mr-[16px] h-[20px] w-[113px] whitespace-nowrap rounded bg-[#D1FAE5] px-[10px] py-[2px] text-xs font-medium leading-4 text-[#065F46]">
                EMERALD POST
              </span>
            </div>
          </div>
        </div>

        <div className="h-[20px] content-center pl-[16px] pt-[13px]">
          <div className="text-sm font-normal leading-5 text-gray-500">
            実施期間：{scheduleToString(props.scheduleStartedAt, props.scheduleStartedAt)}
          </div>
        </div>
      </div>
    </div>
  );
}
