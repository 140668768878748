import { GetAnalysisSpiritSummaryOutput, SpiritXSummaryInsights } from '@/api/analysisSpirit';
import { ReactComponent as CoinIcon } from '@/assets/icons/bx-coin.svg';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as RepostIcon } from '@/assets/icons/bx-repost.svg';
import { Stat } from '@/components/Elements/Stat';

import LeadingContent from './LeadingContent';

export interface AnalysisTwiiterProps {
  summary: GetAnalysisSpiritSummaryOutput;
}

export default function AnalysisTwitter(props: AnalysisTwiiterProps) {
  const insights = props.summary.insights as SpiritXSummaryInsights;
  return (
    <div className="flex flex-1 flex-col items-start gap-10">
      <div id="kpi-section" className="flex flex-col items-start gap-3 self-stretch">
        <div className="flex items-center gap-[16px] self-stretch">
          <span>計測値</span>
          <span className="text-sm">※実施期間終了後、1週間程度は数値が変動します。</span>
        </div>
        <div className="flex items-start gap-4 self-stretch">
          <div className="flex flex-col items-start gap-2">
            <LeadingContent
              icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
              label="総フォロワー数"
              value={props.summary.summary.followers?.toLocaleString() ?? '集計中'}
            />
            <div className="flex w-[362px] items-start gap-[10px] rounded-lg bg-gray-100 p-4">
              <span className="flex-1 whitespace-pre-line text-sm font-medium leading-5 text-gray-700">
                {props.summary?.summary.followersComment ?? '担当者の入力待ち'}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-start gap-2">
            <LeadingContent
              icon={<HeartIcon fill="#FFFFFF" />}
              label="エンゲージメント数"
              value={props.summary.summary.engagements?.toLocaleString() ?? '集計中'}
            />
            <div className="flex w-[362px] items-start gap-[10px] rounded-lg bg-gray-100 p-4">
              <span className="flex-1 whitespace-pre-line text-sm font-medium leading-5 text-gray-700">
                {props.summary?.summary.engagementsComment ?? '担当者の入力待ち'}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="insight-section" className="flex w-[740px] flex-col items-start gap-4">
        <div className="flex flex-col items-start gap-2 self-stretch">
          <div className="flex flex-col items-start gap-1">
            <span>インサイト</span>
          </div>
          <div className="flex items-center gap-4">
            <span className="whitespace-pre-line text-sm font-normal leading-5 text-gray-500">
              {`※案件サマリーのインサイトの項目は、全投稿の共通項目のみ表示されます。\nユニークアカウント数は、全投稿の合算のため本来のユニーク数ではありません。`}
            </span>
          </div>
        </div>
        <div className="flex w-[740px] flex-col items-start gap-10">
          <div id="insight" className="flex flex-col items-start gap-2">
            <div className="flex w-[740px] items-center gap-2">
              <Stat
                icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
                lable="平均フォロワー数"
                value={insights.followerAvg?.toLocaleString() ?? '集計中'}
                className="w-[366px]"
              />
              <Stat
                icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
                lable="エンゲージメント率"
                value={insights.engagementRate ? `${insights.engagementRate.toLocaleString()}%` : '集計中'}
                className="w-[366px]"
              />
            </div>
            <div className="flex w-[740px] items-center gap-2">
              <Stat
                icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
                lable="いいね数"
                value={insights.goodCount?.toLocaleString() ?? '集計中'}
                className="w-[366px]"
              />
              <Stat
                icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
                lable="返信数"
                value={insights.sendCount?.toLocaleString() ?? '集計中'}
                className="w-[366px]"
              />
            </div>
            <div className="flex w-[740px] items-center gap-2">
              <Stat
                icon={<RepostIcon width={24} height={24} fill="#FFFFFF" />}
                lable="リポスト数"
                value={insights.repostCount?.toLocaleString() ?? '集計中'}
                className="w-[366px]"
              />
              <Stat
                icon={<CoinIcon width={24} height={24} fill="#FFFFFF" />}
                lable="フォロワー単価"
                value={insights.followerUnitPrice ? `￥${insights.followerUnitPrice.toLocaleString()}` : '集計中'}
                className="w-[366px]"
              />
            </div>
            <div className="flex w-[740px] items-center gap-2">
              <Stat
                icon={<CoinIcon width={24} height={24} fill="#FFFFFF" />}
                lable="広告換算額"
                value={insights.adConversionPrice ? `￥${insights.adConversionPrice.toLocaleString()}` : '集計中'}
                className="w-[366px]"
              />
            </div>
          </div>
        </div>
        <div className="flex w-[740px] flex-col items-start gap-10">
          <div className="flex flex-col items-start gap-6 self-stretch">
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex h-6 items-center justify-between self-stretch">
                <span className="text-sm font-semibold leading-5 text-gray-900">総括</span>
              </div>
              <div className="flex items-start gap-[10px] self-stretch rounded-lg bg-gray-100 p-4">
                <span className="whitespace-pre-line text-sm font-medium leading-5 text-gray-700">
                  {props.summary?.generalize ?? '担当者の入力待ち'}
                </span>
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex h-6 items-center justify-between self-stretch">
                <span className="text-sm font-semibold leading-5 text-gray-900">次回のご提案</span>
              </div>
              <div className="flex items-start gap-[10px] self-stretch rounded-lg bg-gray-100 p-4">
                <span className="whitespace-pre-line text-sm font-medium leading-5 text-gray-700">
                  {props.summary?.nextProposal ?? '担当者の入力待ち'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
