import BarChart, { labelProps } from '../../common/Graph/BarChart';
import { AccountFollowerAttributes } from '../../Instagram/InstagramAll_Interface';
import PieChart from '../Graph/DonutChart';

// export interface Prefecture {
//   label: string;
//   value: string;
// }

export interface MasterJsonData {
  label: string;
  value: string;
}

export default function ApplicantInfo(
  followerAttribute: AccountFollowerAttributes,
  prefecturesJsonData: MasterJsonData[],
  ageGenderJsonData: MasterJsonData[],
  genreJsonData: MasterJsonData[],
  valueJsonData: MasterJsonData[]
) {
  const followerLabel: labelProps = {
    yaxisValue: '歳',
    toolTipYaxisValue: '人',
  };

  const perfectureLabel: labelProps = {
    yaxisValue: '',
    toolTipYaxisValue: '人',
  };

  const bar_main_data = [
    //followerAttribute.ageDistribution の mans, womans,others の data
    [
      {
        name: '男性',
        data: followerAttribute.ageDistribution?.map((human) => human.mans) || [],
      },
      {
        name: '女性',
        data: followerAttribute.ageDistribution?.map((human) => human.womans) || [],
      },
      {
        name: 'その他',
        data: followerAttribute.ageDistribution?.map((human) => human.others) || [],
      },
    ],

    // followerAttribute.prefectureDistribution の data
    [
      {
        name: '',
        data: followerAttribute.prefDistribution?.map((prefecture) => prefecture.value) || [],
      },
    ],
  ];

  const changeValueToPrefLabel = (prefCodes: string[]) => {
    return prefCodes.map((prefCode) => {
      return prefecturesJsonData.find((pref) => pref.value === prefCode)?.label || '';
    });
  };

  const changeValueToAgeGenderLabel = (ageGenderCodes: string[]) => {
    return ageGenderCodes.map((ageGenderCode) => {
      return ageGenderJsonData.find((ageGender) => ageGender.value === ageGenderCode)?.label || ageGenderCode;
    });
  };

  const changeValueToGenreLabel = (genreCodes: string[]) => {
    return genreCodes.map((genreCode) => {
      return genreJsonData.find((genre) => genre.value === genreCode)?.label || genreCode;
    });
  };

  const changeValueToValueLabel = (valueCodes: string[]) => {
    return valueCodes.map((valueCode) => {
      return valueJsonData.find((value) => value.value === valueCode)?.label || valueCode;
    });
  };

  const bar_categories = [
    followerAttribute.ageDistribution?.map((distribtion) => distribtion.distribution) || [],

    changeValueToPrefLabel(
      followerAttribute.prefDistribution?.map((distribtion) => distribtion.prefCode) as string[]
    ) || [],
  ];

  const top3_ageGender = changeValueToAgeGenderLabel(followerAttribute.top3?.ageGender as string[]);
  const top3_genre = changeValueToGenreLabel(followerAttribute.top3?.genre as string[]);
  const top3_values = changeValueToValueLabel(followerAttribute.top3?.values as string[]);

  const bar_colors = [['#007CC2', '#80D0FF', '#D1D5DB'], ['#007CC2']];

  const pie_main_data = [
    followerAttribute.followers?.mans,
    followerAttribute.followers?.womans,
    followerAttribute.followers?.others,
  ];

  const pie_colors = ['#007CC2', '#80D0FF', '#D1D5DB'];

  return (
    <>
      <div className="pb-10">
        <div className="flex w-[800px]">
          <div>
            <div className="w-[470px] text-sm font-semibold leading-5 text-gray-700">フォロワーの年齢分布</div>
            <div className="w-[417px]">
              {BarChart(bar_main_data[0], bar_categories[0], bar_colors[0], followerLabel)}
            </div>
          </div>
          <div>
            <div className="w-[424px] pl-7 text-sm font-semibold leading-5 text-gray-700">フォロワーの男女比</div>
            {<div>{PieChart(pie_main_data as number[], pie_colors)}</div>}
          </div>
        </div>
      </div>

      <div>
        <div className="text-sm font-semibold leading-5 text-gray-700">フォロワーの都道府県</div>
        <div className="w-[417px]">{BarChart(bar_main_data[1], bar_categories[1], bar_colors[1], perfectureLabel)}</div>
      </div>
      <div className="flex w-[880px] gap-[10px]">
        <div className="h-[140px] w-[296px] flex-col">
          <div className="text-sm font-semibold leading-5 text-gray-900">年代・性別</div>
          {top3_ageGender.map((ageGender, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">{ageGender}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="h-[140px] w-[296px]">
          <div className="text-sm font-semibold leading-5 text-gray-900">ジャンル</div>
          {top3_genre.map((genre, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">{genre}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="h-[140px] w-[296px]">
          <div className="text-sm font-semibold leading-5 text-gray-900">価値観</div>
          {top3_values.map((value, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">{value}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
