import 'chart.js/auto';
import 'chartjs-plugin-datalabels';

import type { LayoutPosition } from 'chart.js';
import { Bar, Doughnut } from 'react-chartjs-2';

import { STEPS } from '../constants/path';

export interface GraphProps {
  step: string;
}

export function Graph(props: GraphProps) {
  if (props.step === STEPS.BUDGET) {
    return BarGraph(props);
  } else if (props.step === STEPS.PERIOD) {
    return BarGraph(props);
  } else if (props.step === STEPS.SNS) {
    return DoughnutGraph(props);
  } else {
    return <></>;
  }
}

function BarGraph(props: GraphProps) {
  const data = {
    // x 軸のラベル
    labels: [
      '',
      '',
      '10',
      '',
      '',
      '',
      '',
      '50',
      '',
      '',
      '100',
      '',
      '',
      '200',
      '',
      '',
      '250',
      '',
      '',
      '300',
      '350',
      '400',
      '500',
    ],
    datasets: [
      {
        data: [0.25, 0.5, 1, 1.25, 1.7, 2.5, 3.2, 4, 5, 6, 6.5, 7, 8, 8.5, 8.5, 7, 6, 5, 4.5, 4, 3, 2, 1],
        backgroundColor: ['#007CC2'],
        barThickness: 4,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          major: {
            enabled: true,
          },
          maxRotation: 0,
        },
        min: 0,
        max: 500,
      },
      y: {
        max: 10,
      },
    },
  };

  const drawInnerText = (chart: any) => {
    const ctx = chart.ctx;
    ctx.fillStyle = '#6B7280';
    ctx.font = 'normal 13.9899px Inter';
    ctx.textAlign = 'left';
    ctx.fillText('(%)', 16, 10);
    ctx.textAlign = 'right';
    chart.width = 531;
    ctx.height = 298;
    ctx.fillText(props.step === STEPS.BUDGET ? '(円)' : '(日)', chart.width + 24, chart.height - 25);
    ctx.restore();
  };

  return (
    <div className="bg-gray-50" style={{ width: '576px', height: '374.38px' }}>
      <p className="pt-6 text-center text-sm font-semibold leading-5 text-gray-700">{getTitle(props.step)}</p>
      <div id="graph_frame" className="m-auto pt-1">
        <Bar
          id="bar"
          data={data}
          options={options}
          plugins={[
            {
              id: 'afterDraw',
              afterDraw: (chart) => {
                drawInnerText(chart);
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

function DoughnutGraph(props: GraphProps) {
  const data = {
    // x 軸のラベル
    labels: ['Instagram 68%', 'X 24%', 'TikTok 4%', 'YouTube 2%', 'その他 2%'],
    datasets: [
      {
        data: [68, 24, 4, 2, 2],
        backgroundColor: ['#F472B6', '#42BAFF', '#34D399', '#EF4444', '#9CA3AF'],
        borderWidth: 0,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right' as LayoutPosition,
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
        },
      },
      tooltip: {
        enabled: false,
        displayColors: false,
        external: function (context: any) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }
          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('above', 'below', 'no-transform');
          if (tooltipModel.yAlign) {
            tooltipEl.classList.add(tooltipModel.yAlign);
          } else {
            tooltipEl.classList.add('no-transform');
          }

          function getBody(bodyItem: any) {
            return bodyItem.lines;
          }

          // Set Text
          if (tooltipModel.body) {
            const bodyLines = tooltipModel.body.map(getBody);

            let innerHtml = '<thead><tr><th></th></tr></thead>';
            innerHtml += '<tbody>';
            bodyLines.forEach(function (body: string, i: number) {
              const colors = tooltipModel.labelColors[i];
              const span = `<span style="">${body}%</span>`;
              let style = 'background:' + colors.backgroundColor;
              style += '; color: #FFFFFF';
              style += '; width: 42.7px';
              style += '; height: 35.4px';
              style += '; border-radius: 4px';
              style += '; text-align: center';
              style += '; display: table-cell';
              style += '; vertical-align: middle';
              innerHtml += '<tr><td style="' + style + '">' + span + '</td></tr>';
            });
            innerHtml += '</tbody>';
            const tableRoot = tooltipEl.querySelector('table');
            if (tableRoot) {
              tableRoot.innerHTML = innerHtml;
            }
          }
          const position = context.chart.canvas.getBoundingClientRect();
          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';
          tooltipEl.style.left = position.left + context.tooltip.caretX + 'px';
          tooltipEl.style.top = position.top + context.tooltip.caretY / 3 + 'px';
          tooltipEl.style.pointerEvents = 'none';
        },
      },
    },
  };

  const drawInnerText = (chart: any) => {
    if (chart.tooltip.body) {
      const tooltip = chart.tooltip;
      tooltip.title = [];
      tooltip.labelPointStyles[0] = {
        hidden: true,
      };
    }

    const ctx = chart.ctx;
    chart.data.datasets.forEach((dataset: any, i: number) => {
      let dataSum = 0;
      dataset.data.forEach((element: number) => {
        dataSum += element;
      });

      const meta = chart.getDatasetMeta(i);
      if (!meta.hidden) {
        meta.data.forEach(function (element: any, index: number) {
          // フォントの設定
          ctx.fillStyle = '#FFFFFF';
          // ラベルをパーセント表示に変更
          const calcPercent = Math.round((dataset.data[index] / dataSum) * 100);
          const dataString = calcPercent.toString() + '%';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.font = '14.5714px Inter';
          const position = element.tooltipPosition();
          if (calcPercent > 2) {
            ctx.fillText(dataString, position.x, position.y);
          }
        });
      }
    });
  };

  return (
    <div className="w-[370px] bg-gray-50">
      <p className="pl-6 text-left text-sm font-semibold leading-5 text-gray-700">{getTitle(props.step)}</p>
      <div id="graph_frame" className="m-auto w-11/12 pt-1">
        <Doughnut
          id="doughnut"
          data={data}
          options={options}
          plugins={[
            {
              id: 'afterDraw',
              afterDraw: (chart) => {
                drawInnerText(chart);
              },
            },
          ]}
        />
      </div>
    </div>
  );
}

function getTitle(step: string): string {
  if (step === STEPS.BUDGET) {
    return '参考：企業がインフルエンサーマーケティング施策に使う予算分布グラフ';
  } else if (step === STEPS.PERIOD) {
    return '参考：企業のインフルエンサーマーケティング施策の期間分布グラフ';
  } else if (step === STEPS.SNS) {
    return '弊社サービス内での状況';
  } else if (step === STEPS.GOAL) {
    return '';
  } else {
    return '';
  }
}
