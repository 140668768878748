import React from 'react';

import { getImageUrl } from '@/utils/image';

interface AvatarProps {
  userName: string;
  avator: string;
}

export const Avatar: React.FC<AvatarProps> = ({ userName, avator }) => {
  console.log('L9: ', avator);
  return (
    <div className="h-[48px] gap-4 rounded-[40px] border border-gray-200 bg-white px-4 py-2">
      <div className="flex flex-row items-center">
        <div className="mr-[15px] h-8 w-8">
          <img alt="" src={getImageUrl(avator)} className="h-full w-full rounded-[50%]"></img>
        </div>
        <div>
          <span className="text-sm font-semibold leading-5">{userName}</span>
          <span className="ml-[10px] text-xs font-normal leading-5 text-gray-500">さん</span>
        </div>
      </div>
    </div>
  );
};
