import instagram from '@/assets/instagram.png';
import tiktok from '@/assets/tiktok.png';
import x from '@/assets/TwitterLogo.png';
import youtube from '@/assets/youtube.png';
import { SNS_TYPES } from '@/config';
import { InstagramPostType } from '@/types/index';

// アイコンの画像のみを返す
export function renderIconImg(src: string, alt: string, size: string) {
  return <img src={src} alt={alt} className={size} />;
}

// SNSのアイコンを返す, sizeが指定されていない場合はデフォルトのサイズが指定される
export function snsIcon(snsType: string, size = 'h-[22.34px] w-[22.34px]') {
  const lowerSnsType = snsType ? snsType.toLowerCase() : '';

  switch (lowerSnsType) {
    case 'instagram':
      return renderIconImg(instagram, 'instagram', size);
    case 'x':
      return renderIconImg(x, 'x', size);
    case 'tiktok':
    case 'tik_tok':
      return renderIconImg(tiktok, 'tiktok', size);
    case 'youtube':
      return renderIconImg(youtube, 'youtube', size);
    default:
      return <></>;
  }
}

export const isInstagram = (snsType: string) => {
  return snsType === SNS_TYPES.INSTAGRAM;
};

export const isTwitter = (snsType: string) => {
  return snsType === SNS_TYPES.TWITTER;
};

export const isTikTok = (snsType: string) => {
  return snsType === SNS_TYPES.TIK_TOK;
};

export const isYouTube = (snsType: string) => {
  return snsType === SNS_TYPES.YOUTUBE;
};

export const convertInstgrampostType = (postType: InstagramPostType) => {
  return postType == 'Feed' ? 'フィード' : postType == 'Story' ? 'ストーリーズ' : 'リール';
};

export const convertInstgramPostTaskType = (taskType: number) => {
  if (taskType == 10) {
    return 'Feed' as InstagramPostType;
  } else if (taskType == 11) {
    return 'Story' as InstagramPostType;
  } else {
    return 'Reel' as InstagramPostType;
  }
};

export const isPostTaskTypeFeed = (taskType: number) => {
  return taskType == 10;
};

export const isPostTaskTypeStories = (taskType: number) => {
  return taskType == 11;
};

export const isPostTypeReel = (taskType: number) => {
  return taskType == 12;
};
