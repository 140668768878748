export type BaseEntity = {
  id: string;
  createdAt: number;
};

export type InstagramPostType = 'Feed' | 'Story' | 'Reel' | 'None';

// 定数としての文字列
export const INSTAGRAM_POST_TYPE_FEED = 'Feed';
export const INSTAGRAM_POST_TYPE_STORY = 'Story';
export const INSTAGRAM_POST_TYPE_REEL = 'Reel';
export const INSTAGRAM_POST_TYPE_NONE = 'None';

// 文字列からInstagramPostTypeへの変換関数
export function stringToInstagramPostType(value: string): InstagramPostType {
  switch (value) {
    case INSTAGRAM_POST_TYPE_FEED:
      return 'Feed';
    case INSTAGRAM_POST_TYPE_STORY:
      return 'Story';
    case INSTAGRAM_POST_TYPE_REEL:
      return 'Reel';
    default:
      return INSTAGRAM_POST_TYPE_NONE;
  }
}

// InstagramPostTypeから文字列への変換関数
export function instagramPostTypeToString(value: InstagramPostType): string {
  return value;
}
