import * as Sentry from '@sentry/react';
import React, { useState, useEffect } from 'react';
import { useRoutes, RouteObject, useNavigate } from 'react-router-dom';

import AccountInstaDetail from '@/components/ProjectManagements/Applicant/Contents/Instagram/AccountDetail';
import AccountTwitterDetail from '@/components/ProjectManagements/Applicant/Contents/Twitter/AccountDetail';
import { ROUTES } from '@/config/path';
import { Auth } from '@/features/auth';
import { BasicInfo } from '@/features/basicInfo';
import { BillingManagement } from '@/features/billing_management';
import { CreateTeam } from '@/features/create_team';
import { Home } from '@/features/home';
import { Home as HomeLink } from '@/features/homelink';
import { JaneJohn } from '@/features/jane_john';
import { MemberAdmin } from '@/features/member_admin';
//import { MemberDelete } from '@/features/member_delete';
import { Landing } from '@/features/misc';
import { OtherProject } from '@/features/other_project';
import { PlanSelect } from '@/features/plan_select';
import { Project } from '@/features/project';
import { AnalysisList } from '@/features/project_analysis';
import { Calendar, GanttChart } from '@/features/schedule';
import { TeamSelect } from '@/features/team_select';
import { AuthAPI } from '@/lib/auth';
import { parseJWT } from '@/utils/parser';
import storage from '@/utils/storage';

import AccountTiktokDetail from '../components/ProjectManagements/Applicant/Contents/Tiktok/AccoutDetail';
import AccountYoutubeDetail from '../components/ProjectManagements/Applicant/Contents/Youtube/AccountDetail';
import { AccountSetting } from '../features/account_setting/routes/AccountSetting';
import { AskTeam } from '../features/ask_team/routes/AskTeam';
import { SignInPost } from '../features/auth/routes/SignInPost';
import { EmeraldProject } from '../features/emerald_project';
import { InformLeader } from '../features/inform_leader/routes/InformLeader';
import { InvitationResend } from '../features/invitation_resend/routes/InvitationResend';
import { MakeTeam } from '../features/make_team/routes/MakeTeam';
import { MemberInvitation } from '../features/member_invitation/routes/MemberInvitation';
import { NotificationList } from '../features/notification_list/routes/NotificationList';
import { ProjectManagementList } from '../features/project_management/routes/ProjectList';
import { PRSTManageList } from '../features/PRST_manage_list/routes/PRSTManageList';
import { PRSTPostDetail } from '../features/PRST_post_detail/routes/PRSTPostDetail';
import { PRSTPostList } from '../features/PRST_post_list/routes/PRSTPostList';
import {
  PRSTProductionManagementAccount,
  PRSTProductionManagementNoAccount,
  PRSTProductionManagementNoContent,
  PRSTProductionManagementList,
  PRSTProductionManagementProductList,
  PRSTProductionManagementNoImage,
  PRSTProductionManagementImageApproving,
  PRSTProductionManagementImageApproved,
  PRSTProductionManagementContentsApproving,
  PRSTProductionManagementContentsApproved,
  PRSTProductionManagementContentsApprovingInstagramReel,
  PRSTProductionManagementContentsApprovingInstagramStory,
  PRSTProductionManagementContentsApprovingTwitterImage,
  PRSTProductionManagementContentsApprovingTwitterNoImage,
  PRSTProductionManagementContentsApprovingYouTube,
  PRSTProductionManagementContentsApprovingYouTubeShort,
  PRSTProductionManagementContentsApprovingTikTok,
  PRSTProductionManagementContentsApprovingOther,
  Management,
} from '../features/PRST_production_management';
import { PrstProject } from '../features/PRST_project';
import { Spirit } from '../features/spirit/routes/Spirit';

import protectedRoutes from './protected';
import publicRoutes from './public';
const auth: AuthAPI = new AuthAPI('auth0Container');
export default function AppRoutes(): React.ReactElement {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const commonRoutes: Array<RouteObject> = [
    { path: '/', element: <Landing isAuthenticated={isAuthenticated} /> },
    { path: '/auth/sign-in-post', element: <SignInPost /> },
    { path: '/auth', element: <Auth /> },
    { path: '/home', element: <Home /> },
    { path: '/project', element: <Project /> },
    { path: '/make_team', element: <MakeTeam /> },
    { path: '/ask_team', element: <AskTeam /> },
    { path: '/inform_leader', element: <InformLeader /> },
    { path: '/notification_list', element: <NotificationList /> },
    { path: '/basicinfo', element: <BasicInfo /> },
    { path: '/plan_select', element: <PlanSelect /> },
    { path: '/member_admin', element: <MemberAdmin /> },
    { path: '/member_invitation', element: <MemberInvitation /> },
    { path: '/invitation_resend', element: <InvitationResend /> },
    { path: '/account_setting', element: <AccountSetting /> },
    { path: '/emerald_project', element: <EmeraldProject /> },
    { path: '/jane_john', element: <JaneJohn /> },
    { path: '/other_project', element: <OtherProject /> },
    { path: '/project_management/list', element: <ProjectManagementList /> },
    { path: '/PRST_project', element: <PrstProject /> },
    { path: '/PRST_post_detail', element: <PRSTPostDetail /> },
    { path: '/PRST_post_list', element: <PRSTPostList /> },
    { path: '/PRST_manage_list', element: <PRSTManageList /> },
    { path: '/PRST_production_management/noaccount', element: <PRSTProductionManagementNoAccount /> },
    { path: '/PRST_production_management/noaccount/contents', element: <PRSTProductionManagementNoAccount /> },
    { path: '/PRST_production_management/account', element: <PRSTProductionManagementAccount /> },
    { path: '/PRST_production_management/list', element: <PRSTProductionManagementList /> },
    { path: '/PRST_production_management/nocontent', element: <PRSTProductionManagementNoContent /> },
    { path: '/PRST_production_management', element: <Management /> },
    { path: '/PRST_production_management/ProductionList', element: <PRSTProductionManagementProductList /> },
    { path: '/PRST_production_management/noimage', element: <PRSTProductionManagementNoImage /> },
    { path: '/PRST_production_management/image', element: <PRSTProductionManagementImageApproving /> },
    { path: '/PRST_post_list', element: <PRSTPostList /> },
    { path: '/billing', element: <BillingManagement /> },
    { path: '/team-select', element: <TeamSelect /> },
    { path: '/spirit', element: <Spirit /> },
    { path: '/account_instagram_detail', element: <AccountInstaDetail /> },
    { path: '/account_twitter_detail', element: <AccountTwitterDetail /> },
    { path: '/account_tiktok_detail', element: <AccountTiktokDetail /> },
    { path: '/account_youtube_detail', element: <AccountYoutubeDetail /> },
    { path: '/project-analysis/list', element: <AnalysisList /> },
    { path: '/PRST_production_management/image_approving', element: <PRSTProductionManagementImageApproving /> },
    { path: '/PRST_production_management/image_approved', element: <PRSTProductionManagementImageApproved /> },
    { path: '/PRST_production_management/contents_approving', element: <PRSTProductionManagementContentsApproving /> },
    {
      path: '/PRST_production_management/contents_approving/instagram_story',
      element: <PRSTProductionManagementContentsApprovingInstagramStory />,
    },
    {
      path: '/PRST_production_management/contents_approving/instagram_reel',
      element: <PRSTProductionManagementContentsApprovingInstagramReel />,
    },
    {
      path: '/PRST_production_management/contents_approving/twitter_image',
      element: <PRSTProductionManagementContentsApprovingTwitterImage />,
    },
    {
      path: '/PRST_production_management/contents_approving/twitter_noimage',
      element: <PRSTProductionManagementContentsApprovingTwitterNoImage />,
    },
    {
      path: '/PRST_production_management/contents_approving/youtube',
      element: <PRSTProductionManagementContentsApprovingYouTube />,
    },
    {
      path: '/PRST_production_management/contents_approving/youtube_short',
      element: <PRSTProductionManagementContentsApprovingYouTubeShort />,
    },
    {
      path: '/PRST_production_management/contents_approving/tictok',
      element: <PRSTProductionManagementContentsApprovingTikTok />,
    },
    {
      path: '/PRST_production_management/contents_approving/other',
      element: <PRSTProductionManagementContentsApprovingOther />,
    },
    { path: '/PRST_production_management/image_approving', element: <PRSTProductionManagementImageApproving /> },
    { path: '/PRST_production_management/image_approved', element: <PRSTProductionManagementImageApproved /> },
    { path: '/PRST_production_management/contents_approved', element: <PRSTProductionManagementContentsApproved /> },
    { path: '/schedule/calendar', element: <Calendar /> },
    { path: '/schedule/gantt-chart', element: <GanttChart /> },
    { path: '/create_team', element: <CreateTeam /> },
    { path: '/homelink', element: <HomeLink /> },
  ];
  const routes: Array<RouteObject> = isAuthenticated ? protectedRoutes : publicRoutes;
  const element = useRoutes(isAuthenticated ? [...routes, ...commonRoutes] : [...routes]) as React.ReactElement;
  const navigate = useNavigate();
  const validateAuthenticated = async () => {
    try {
      const token = storage.getToken();
      if (!token) {
        setIsAuthenticated(false);
        return;
      } else {
        setIsAuthenticated(true);
      }
      const result = await auth.isAuthenticated();
      if (result) {
        const accessToken = result?.accessToken;
        if (accessToken) {
          const jwt = parseJWT(accessToken);
          if (new Date().getTime() > new Date(jwt.exp * 1000).getTime()) {
            setIsAuthenticated(false);
            storage.clearToken();
            navigate(ROUTES.AUTH_LOGIN);
          } else {
            storage.setToken(accessToken);
          }
        } else {
          setIsAuthenticated(false);
          storage.clearToken();
          navigate(ROUTES.AUTH_LOGIN);
        }
      }
    } catch (ex: any) {
      console.log('catch (ex: any) :' + JSON.stringify(ex, null, 2));
      Sentry.captureException(ex);
      const exCode = ex.code;
      if (exCode === 'unauthorized' || exCode === 'login_required') {
        setIsAuthenticated(false);
        storage.clearToken();
        console.log(ex);
        navigate(ROUTES.AUTH_LOGIN);
      } else if (exCode === 'invalid_token') {
        setIsAuthenticated(true);
        return;
      }
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    validateAuthenticated();
  }, [element]);

  if (isLoading) {
    return (
      <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
        <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-l-gray-500" />
      </div>
    );
  }

  if (!element && !isAuthenticated) {
    return <Landing isAuthenticated={isAuthenticated} />;
  }

  return element;
}
