import React, { useState } from 'react';

import { Radio } from '@/components/Elements/Radio';

import engagement from '../../assets/engagement.png';
import follower from '../../assets/follower.png';
import investigation from '../../assets/investigation.png';
import reach from '../../assets/reach.png';
import { goalsCombination } from '../../constants/choices';
import { PROJECT_GOAL } from '../../constants/path';
import { FormProps, SelectProps } from '../../interfaces/project';

export default function Goal(props: FormProps): React.ReactElement {
  const [tmpGoal, setTmpGoal] = useState<string>();
  const onHover = (value: string) => {
    setTmpGoal(value);
  };

  const selectDom = () => {
    return (
      <>
        {goalsCombination.map((item: SelectProps) => {
          return (
            <div key={item.value} onMouseOver={() => onHover(item.value)} onFocus={() => {}}>
              <Radio
                inputId={item.value}
                inputValue={item.value}
                label={item.label}
                isSelect={props.payload.goal === item.value}
                onClick={() => {
                  props.setPayload((state) => {
                    return {
                      ...state,
                      goal: item.value,
                      image: '',
                      important: '',
                      sns: '',
                      period: undefined,
                      isPeriodError: false,
                      periodErrorMessage: '',
                      budget: undefined,
                      isBudgetError: false,
                      budgetErrorMessage: '',
                    };
                  });
                }}
                className="w-96"
              ></Radio>
            </div>
          );
        })}
      </>
    );
  };

  const selectImage = (goal: string) => {
    if (goal === PROJECT_GOAL.REACH) {
      return <img src={reach} alt="reach" />;
    } else if (goal === PROJECT_GOAL.ENGAGEMENT) {
      return <img src={engagement} alt="engagement" />;
    } else if (goal === PROJECT_GOAL.FOLLOWER) {
      return <img src={follower} alt="follower" />;
    } else if (goal === PROJECT_GOAL.INTERVIEW) {
      return <img src={investigation} alt="interview" />;
    } else {
      return <img src={reach} alt="reach" />;
    }
  };

  const imageDescription = (title: string, description: string) => {
    return (
      <div className="w-[184px]">
        <div className="h-5">
          <span className="text-sm font-semibold leading-5 text-gray-700">{title}</span>
        </div>
        <div>
          <span className="text-sm font-normal leading-5 text-gray-500">{description}</span>
        </div>
      </div>
    );
  };

  const selectImageText = (goal: string) => {
    if (goal === PROJECT_GOAL.REACH) {
      return imageDescription('リーチ', 'より多くのユーザーに情報を届けることを目的とします。');
    } else if (goal === PROJECT_GOAL.ENGAGEMENT) {
      return imageDescription(
        'エンゲージメント',
        'いいねやコメントといったエンゲージメントを高めて、より多くの共感を得ることを目的とします。'
      );
    } else if (goal === PROJECT_GOAL.FOLLOWER) {
      return imageDescription('フォロワー', 'より多くのフォロワーを獲得することを目的とします。');
    } else if (goal === PROJECT_GOAL.INTERVIEW) {
      return imageDescription(
        '調査',
        'インフルエンサーからの意見やアドバイスを得て、今後の施策に活かすことを目的とします。'
      );
    } else {
      return imageDescription('リーチ', 'より多くのユーザーに情報を届けることを目的とします。');
    }
  };

  const tmoDescription = () => {
    if (tmpGoal) {
      return (
        <div className="w-[232px] bg-[#F9FAFB]">
          <div className="px-4 py-6">
            <div>{selectImage(tmpGoal)}</div>
            <div className="pt-4">{selectImageText(tmpGoal)}</div>
          </div>
        </div>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div
      id="project_goal"
      className="mx-auto mt-12 flex flex-row items-start justify-between"
      style={{ width: '662px', height: '316px' }}
    >
      <div className="mr-6 w-[406px]">
        <div className="mx-auto w-[576px] pr-6">{selectDom()}</div>
      </div>
      {props.payload.goal ? (
        <div className="w-[232px] bg-[#F9FAFB]">
          <div className="px-4 py-6">
            <div>{selectImage(props.payload.goal)}</div>
            <div className="pt-4">{selectImageText(props.payload.goal)}</div>
          </div>
        </div>
      ) : (
        tmoDescription()
      )}
    </div>
  );
}
