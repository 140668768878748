import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

import { ReactComponent as AddIcon } from '@/assets/icons/bx-add-to-queue.svg';
import { ReactComponent as LinkIcon } from '@/assets/icons/bx-link.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { ReactComponent as CalendarIcon } from '@/assets/icons/fill-calendar.svg';
import { Button } from '@/components/Elements';

import { AttachmentFiles } from './AttachmentFiles';

export interface AttachmentFileData {
  title: string;
  url: URL;
}

export default function AnalysisResult() {
  const [fileModalIsOpen, setFileModalIsOpen] = useState(false);
  const initialFiles: AttachmentFileData[] = [
    {
      title: 'アンケート結果サマリー.pptx',
      url: new URL('https://ambient-lab.co.jp'),
    },
    {
      title: '',
      url: new URL(
        'https://docs.google.com/forms/d/e/1FAIpQLSc8YqMteEw-r6avzJx5gLXvHmjRXGFJPkyQaPySLEf8xc2jGw/viewform'
      ),
    },
  ];
  const [dialogAttachmentFiles, setDialogAttachmentFiles] = useState<AttachmentFileData[]>(initialFiles);
  const onCloseAttachmentFiles = () => {
    setFileModalIsOpen(false);
  };

  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    //    setOpen(true);
    setFileModalIsOpen(true);
  };
  const deleteFile = (index: number) => {
    setDialogAttachmentFiles(dialogAttachmentFiles.filter((_, i) => i !== index));
  };

  const AddImageModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[500px] w-[700px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="flex flex-row">
                    <div className="mb-[40px] flex h-[24px] w-[696px] items-center justify-start">
                      <span className="text-lg font-medium leading-6 text-gray-900">添付ファイルを追加</span>
                    </div>
                    <div>
                      <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center">
                    <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                      <div className="h-[120px] w-[220px] pb-[4px]">
                        <div className="flex items-center justify-center">
                          <div className="flex h-[48px] w-[48px] items-center justify-center">
                            <AddIcon width={36} height={36} />
                          </div>
                        </div>
                        <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                          <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                            <span className="text-sm font-medium leading-5 text-gray-600">
                              ドラッグ&ドロップもしくは
                            </span>
                          </div>
                          <div className="flex items-center justify-center">
                            <span className="text-sm font-medium leading-5 text-[#007CC2]">アップロード</span>
                          </div>
                        </div>
                        <div className="flex items-center justify-center">
                          <span className="text-xs font-normal leading-4 text-gray-500">
                            対応ファイルはPNG, JPG, GIF,mp4
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="mt-[10px] items-center justify-center">
                      <Button
                        startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                        variant="secoundary"
                        size="smNopadding"
                        className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick}
                      >
                        添付ファイルを追加する
                      </Button>
                    </div>
                  </div>
                  <div className="mt-[50px] flex items-center justify-end">
                    <Button
                      variant="white"
                      size="md"
                      className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                      onClick={() => setOpen(false)}
                    >
                      キャンセル
                    </Button>
                    <Button
                      variant="primary"
                      size="md"
                      className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                      onClick={handleClick}
                    >
                      保存する
                    </Button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="w-[800px] flex-col items-center justify-between gap-[5px]">
      <div className="flex items-center justify-between gap-[2px] py-[10px] pb-[16px]">
        <div>
          <span className="font-bold">結果</span>
        </div>
        <div>
          <Button
            startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
            variant="secoundary"
            size="smNopadding"
            className="h-[38px] w-[140px] whitespace-nowrap text-sm font-medium leading-5"
            onClick={handleClick}
          >
            添付する
          </Button>
        </div>
      </div>
      {dialogAttachmentFiles.map((file, index) => {
        return (
          <div
            key={index}
            className="flex flex-row justify-between rounded-md border-[1px] border-gray-200 py-[16px] pl-[24px] pr-[8px]"
          >
            <a href={file.url.href} className="flex flex-row items-center" target="_blank" rel="noreferrer">
              {file.title !== '' ? (
                <>
                  <LinkIcon fill="#007CC2" width={20} height={20} className="mr-[8px]" />
                  <p className="w-[544px]  text-sm text-blue-500">{file.title}</p>
                </>
              ) : (
                <>
                  <LinkIcon fill="#007CC2" width={20} height={20} className="mr-[8px]" />
                  <p className="w-[544px] truncate text-sm  text-blue-500">{file.url.href}</p>
                </>
              )}
            </a>
            <div className="flex items-center gap-4">
              <div className="flex">
                <CalendarIcon width={20} height={20} />
                <div className="ml-1 text-sm text-gray-500">2023/2/3</div>
              </div>
              <button
                onClick={() => {
                  deleteFile(index);
                }}
              >
                <CloseIcon width={24} height={24} fill="#9CA3AF" />
              </button>
            </div>
          </div>
        );
      })}

      <div className="relative">{AddImageModal()}</div>
      <AttachmentFiles
        open={fileModalIsOpen}
        onClose={onCloseAttachmentFiles}
        files={dialogAttachmentFiles}
        setFiles={setDialogAttachmentFiles}
      ></AttachmentFiles>
    </div>
  );
}
