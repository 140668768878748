import { ProjectScheduleStatus } from '@/api/project';
import { ReactComponent as CalenderIcon } from '@/assets/icons/bx-calendar.svg';
import { FeeadBack } from '@/components/Elements/FeedBack';

const STATUS_COLORS = {
  before: 'text-[#92400E]',
  during: 'text-[#1E40AF]',
  ending: 'text-[#1F2937]',
} as const;

const STATUS_BG_COLORS = {
  before: 'bg-[#FEF3C7]',
  during: 'bg-[#DBEAFE]',
  ending: 'bg-[#F3F4F6]',
} as const;

const STATUS = {
  before: '実施前',
  during: '実施中',
  ending: '終了',
} as const;

export interface ProjectSchedule {
  label: string;
  from: string;
  to: string;
  status: ProjectScheduleStatus;
}

export interface ScheduleProps {
  scheduleData: ProjectSchedule[];
  projectStatus: number;
}

export default function ScheduleContent(props: ScheduleProps) {
  // 日付フォーマット
  const formatDateTime = (dateTimeString: string) => {
    return new Intl.DateTimeFormat('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(dateTimeString));
  };

  return (
    <div>
      {props.scheduleData.length === 0 ? (
        <FeeadBack
          icon={<CalenderIcon fill="#9CA3AF"></CalenderIcon>}
          supportText="スケジュールは、案件詳細が確定したら表示されます。"
        />
      ) : (
        <div className="px-[59px] py-6">
          <div className="w-[1063px] rounded-lg bg-white ring-1 ring-gray-200">
            <table className="min-w-full divide-y divide-gray-200">
              <thead>
                <tr>
                  <th className="w-[288px] items-center py-4 pl-6">
                    <div className="flex flex-row">
                      <span className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        タイトル
                      </span>
                    </div>
                  </th>
                  <th className="w-[341px] items-center py-4 pl-6">
                    <div className="flex flex-row">
                      <span className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">期間</span>
                    </div>
                  </th>
                  <th className="w-[217px] items-center py-4 pl-6">
                    <div className="flex flex-row">
                      <span className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                        ステータス
                      </span>
                    </div>
                  </th>
                  <th className="w-[217px] items-center py-4 pl-6">
                    <div className="flex flex-row"></div>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 ">
                {props.scheduleData.map((schedule, dataIndex) => {
                  return (
                    <tr key={dataIndex}>
                      <td className="w-[288px] items-center py-4 pl-6">
                        <span className="whitespace-nowrap text-sm font-normal uppercase leading-5 tracking-wider text-[#6B7280]">
                          {schedule.label}
                        </span>
                      </td>
                      {schedule.from ? (
                        <td className="w-[341px] items-center py-4 pl-6">
                          <span className="text-sm font-medium uppercase leading-5 tracking-wider text-gray-900">
                            {`${formatDateTime(schedule.from)} ~ ${formatDateTime(schedule.to)}`}
                          </span>
                        </td>
                      ) : (
                        <td className="w-[341px] items-center py-4 pl-6">
                          <span className="text-sm font-medium uppercase leading-5 tracking-wider text-gray-900">
                            {`${formatDateTime(schedule.to)}`}
                          </span>
                        </td>
                      )}
                      <td className="w-[217px] items-center  py-4 pl-6">
                        <div
                          className={`flex h-5 w-14 flex-row items-center justify-center rounded-[10px] ${
                            STATUS_BG_COLORS[schedule.status]
                          }`}
                        >
                          <span
                            className={`text-xs font-medium uppercase leading-4 tracking-wider ${
                              STATUS_COLORS[schedule.status]
                            }`}
                          >
                            {STATUS[schedule.status]}
                          </span>
                        </div>
                      </td>
                      <td className="w-[217px] items-center  py-4 pl-6"></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
