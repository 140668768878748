import { projectDetails } from '../../interfaces/analysisInterface';

import OneList from './oneList';

export default function ProjectDetails(props: projectDetails) {
  return (
    <div>
      <div className="h-[24+49px] w-[1027px] pl-[64px]">
        <div className="text-lg font-medium leading-6 text-gray-900">{'案件概要'}</div>
      </div>
      <div className="w-[1027px] pl-[64px] pt-[20px]">
        <div className=" border-b-[1px] border-gray-200 "></div>

        <span>
          <div>{OneList('市場背景', props.markeValue, true)}</div>
          <div>{OneList('課題', props.theme, false)}</div>
          <div>{OneList('目的', props.purpose, true)}</div>
          <div>{OneList('内容', props.description, true)}</div>
          <div>{OneList('評価項目', props.evaluationItem, true)}</div>
          <div>{OneList('予算', props.budget, true)}</div>
          <div>{OneList('発注先の情報', props.supplierInformation, false)}</div>
        </span>
      </div>
    </div>
  );
}
