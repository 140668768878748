// Dupricated:共通版の src/features/project_analysis/components/posts/modals/tabs/FollwerAttributesCommon.tsx を使用してください
export default function FollowerAttribute() {
  const rankingContent = (label: string, values: string[]) => {
    return (
      <div className="flex flex-1 flex-col items-start gap-2">
        <span className="text-sm font-semibold leading-5 text-gray-900">{label}</span>
        {values.map((value: string, index: number) => {
          return (
            <div className="flex items-center gap-2 self-stretch " key={index}>
              <span className="text-sm font-semibold leading-5">{index + 1}.</span>
              <div className="flex h-8 flex-1 items-center px-[13px] py-[9px]">
                <span className="text-sm font-medium leading-4">{value}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start gap-[64px] self-stretch">
      <div className="flex flex-col items-start gap-6 self-stretch">
        <div className="flex items-start gap-10 self-stretch">
          {/* ランキング 年代・性別 */}
          {rankingContent('年代・性別', ['20代女性', '30代女性', '40代女性'])}
          {/* ランキング ジャンル */}
          {rankingContent('ジャンル', ['#ダイエット', '#ボディメイク', '#フィットネス'])}
          {/* ランキング 価値観 */}
          {rankingContent('価値観', ['主張が強い', '協調性がある', 'こだわりがある'])}
        </div>
      </div>
    </div>
  );
}
