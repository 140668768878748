import React, { createContext, useContext, useState } from 'react';

interface UIContextProps {
  isProject: boolean;
  setIsProject: React.Dispatch<React.SetStateAction<boolean>>;
  isProjectDetail: boolean;
  setIsProjectDetail: React.Dispatch<React.SetStateAction<boolean>>;
  isImage: boolean;
  setIsImage: React.Dispatch<React.SetStateAction<boolean>>;
  projectStatus: number;
  setProjectStatus: React.Dispatch<React.SetStateAction<number>>;
  addProjectStatus: (status: number) => void;
  imageComment: boolean;
  setImageComment: React.Dispatch<React.SetStateAction<boolean>>;
  contentComment: boolean;
  setContentComment: React.Dispatch<React.SetStateAction<boolean>>;
}

interface UIProviderProps {
  children: React.ReactNode;
}

const UIContext = createContext<UIContextProps>({
  isProject: false,
  setIsProject: () => {},
  isProjectDetail: false,
  setIsProjectDetail: () => {},
  isImage: true,
  setIsImage: () => {},
  projectStatus: 1,
  setProjectStatus: () => {},
  addProjectStatus: () => {},
  imageComment: false,
  setImageComment: () => {},
  contentComment: false,
  setContentComment: () => {},
});

export const UIProvider: React.FC<UIProviderProps> = ({ children }) => {
  const [isProject, setIsProject] = useState<boolean>(false);
  const [isProjectDetail, setIsProjectDetail] = useState<boolean>(false);
  const [isImage, setIsImage] = useState<boolean>(false);
  const [projectStatus, setProjectStatus] = useState<number>(1);
  const [imageComment, setImageComment] = useState<boolean>(false);
  const [contentComment, setContentComment] = useState<boolean>(false);

  const addProjectStatus = (status: number) => {
    status + 2;
    setProjectStatus(status);
  };
  return (
    <UIContext.Provider
      value={{
        isProject,
        setIsProject,
        isProjectDetail,
        setIsProjectDetail,
        isImage,
        setIsImage,
        projectStatus,
        setProjectStatus,
        addProjectStatus,
        imageComment,
        setImageComment,
        contentComment,
        setContentComment,
      }}
    >
      {children}
    </UIContext.Provider>
  );
};

export const useUI = () => {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error('useUI must be used within a UIProvider');
  }
  return context;
};
