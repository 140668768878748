import { Account } from './Account';

export const ManagementView = () => {
  return (
    <>
      <div className="h-[749px] w-[1181px] bg-gray-50">
        <div className="w-[1181px] border-b border-gray-200 p-[16px]">
          <p className="text-base font-semibold leading-7 text-gray-700">PRST(制作案件) 投稿制作管理一覧</p>
        </div>
        <Account />
      </div>
    </>
  );
};
