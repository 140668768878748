export const STEPS = {
  GOAL: 'goal',
  IMAGE: 'image',
  IMPORTANT: 'important',
  SNS: 'sns',
  PERIOD: 'period',
  BUDGET: 'budget',
  FINISH: 'finish',
  MEASURE: 'measure',
};

// どの SNS を使用したいですか？
export const SNS = {
  INSTAGRAM: 'Instagram',
  TWITTER: 'X',
  YOUTUBE: 'YouTube',
  TIK_TOK: 'TikTok',
  FACEBOOK: 'Facebook',
  OTHER: 'other',
};

// 今回の施策で達成したいことは何ですか？
export const PROJECT_GOAL = {
  REACH: 'reach',
  ENGAGEMENT: 'engagement',
  FOLLOWER: 'follower',
  INTERVIEW: 'interview',
};

export const PROJECT_METHOD = {
  OWN_SNS: 'ownSns',
  PR: 'pr',
  SNS_AD: 'snsAd',
  CAMPAIGN: 'campaign',
};

export const PROJECT_DETAIL = {
  XXX: 'ownSns',
  XXY: 'pr',
  XXZ: 'snsAd',
  XXW: 'campaign',
};

// 具体的な施策イメージの選択値
export const PROJECT_IMAGE = {
  IMPORTANT_QUANTITY: 'important_quantity',
  IMPORTANT_QUALITY: 'important_quality',
  PR_BY_INFLUENCER: 'pr_by_influencer',
  TAKE_ADVANTAGE_OF_OWN_ACCOUNT: 'take_advantage_of_own_account',
  CO_CREATE_INFLUENCER: 'co_create_influencer',
  OPERATE_OWN_SNS_ACCOUNT: 'operate_own_sns_account',
  OPERATE_SNS_CAMPAIGN: 'operate_sns_campaign',
  ADVICE_IMPORTANT_QUANTITY: 'advice_important_quantity',
  ADVICE_IMPORTANT_QUALITY: 'advice_important_quality',
};

// 最も重視したい選択肢の選択値
export const PROJECT_IMPORTANT = {
  COST_PERFORMANCE: 'cost_performance',
  COLLECT_FEATURED: 'collect_featured',
  PR_BY_MANY_INFLUENCERS: 'pr_by_many_influencers',
  PR_BY_COMPATIBILITY_INFLUENCERS: 'pr_by_compatibility_influencers',
  ATTRACTING_CUSTOMERS_OPERATING_SNS_ACCOUNT: 'attracting_customers_operating_sns_account',
  PR_BY_INFLUENCER_HAS_MANY_FOLLOWER: 'pr_by_influencer_has_many_followers',
  PR_BY_NAME_RECOGNITION_TALENT: 'pr_by_name_recognition_talent',
  ACQUISITION_SNS_ACCOUNT_OWN_COMPANY: 'acquisition_sns_account_own_company',
  ADVICE_FOR_OPERATING_SNS_ACCOUNT: 'advice_for_operating_sns_account',
  INTERVIEW_INFLUENCERS: 'interview_influencers',
};

// 施策内容
export const MEASURE_CONTENT = {
  HEART5: 'heart5', // EMERALD POST
  TIE_UP: 'tie_up', // タイアップ
  AMBASSADOR: 'ambassador', // アンバサダー
  ACCOUNT_OPERATION: 'account_operation', // アカウント運用
  GROUP_INTERVIEW: 'group_interview', // グループインタビュー
  SPOT_CONSULTING: 'spot_consulting', // スポットコンサル
  ENQUETE: 'enquete', // アンケート
};
