import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { TextArea } from '@/components/Elements/TextArea';

import { AlertContentsApprove } from './AlertContentsApprove';
import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
import { ReactComponent as HashIcon } from './assets/bx-hash.svg';
import { ReactComponent as MenuLeftIcon } from './assets/bx-menu-alt-left.svg';
import CommentIcon from './assets/CommentIcon.png';
import InstaReelImage from './assets/InstaReelImage.png';
import InstaStoryPlayIcon from './assets/InstaStoryPlayIcon.png';
import SampleMediaImage from './assets/SampleMediaImage.png';
import SampleMediaImage2 from './assets/SampleMediaImage2.png';
import { Steppanel_ContentsApproving } from './Steppanel_ContentsApproving';

export const PRSTProductionManagementContentsApprovingInstagramReelView = () => {
  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen1(true);
  };

  const EditContentsModal = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[40px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">コンテンツを編集</span>
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="mb-[32px] h-full w-[320px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">画像/動画</span>
                            </div>
                            <div className="flex w-[498px] items-center justify-between">
                              <div className="w-[211px]">
                                <div className="mb-[8px] flex w-[211px] justify-between">
                                  <span className="h-[19px] w-[35px] text-sm font-semibold leading-5 text-gray-700">
                                    1枚目
                                  </span>
                                  <div className="flex h-[28px] w-[64px] items-center justify-between">
                                    <ChevronLeftIcon width={28} height={28} fill="#E5E7EB" />
                                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                                  </div>
                                </div>
                                <div className="h-[209px] w-[211px]">
                                  <img src={SampleMediaImage2} alt="" className="h-[209px] w-[211px]" />
                                </div>
                              </div>
                              <div className="h-[168px] border-l border-gray-200"></div>
                              <div className="w-[239px]">
                                <div className="mb-[8px] flex w-[211px] justify-between">
                                  <span className="h-[19px] w-[35px] whitespace-nowrap text-sm font-semibold leading-5 text-gray-700">
                                    2枚目
                                  </span>
                                  <div className="flex h-[28px] w-[64px] items-center justify-between">
                                    <ChevronLeftIcon width={28} height={28} fill="#374151" />
                                    <ChevronRightIcon width={28} height={28} fill="#E5E7EB" />
                                  </div>
                                </div>
                                <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-300">
                                  <div className="h-[120px] w-[220px] pb-[4px]">
                                    <div className="flex items-center justify-center">
                                      <div className="flex h-[48px] w-[48px] items-center justify-center">
                                        <AddIcon width={36} height={36} />
                                      </div>
                                    </div>
                                    <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                      <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-gray-600">
                                          ドラッグ&ドロップもしくは
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                          アップロード
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <span className="text-xs font-normal leading-4 text-gray-500">
                                        対応ファイルはPNG, JPG, GIF,mp4
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-[693px]">
                          <div className="mb-[4px] h-[20px] w-[56px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                          </div>
                          <TextArea className="h-[192px] w-[693px] px-[16px] py-[8px]" helperText="#ハッシュタグ数 0" />
                          <button className="mt-[24px] flex items-center justify-start">
                            <span className="text-sm font-medium leading-5 text-red-700">削除する</span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen1(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen1(false)}
                      >
                        キャンセル
                      </Button>
                      <Link to="/PRST_production_management/contents_approving">
                        <Button
                          variant="primary"
                          size="md"
                          className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                          onClick={() => setOpen1(false)}
                        >
                          保存する
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div className="h-[1828px] w-[1179px] bg-gray-50">
        <div className="w-[1181px] px-[24px]">
          <Steppanel_ContentsApproving />
          <AlertContentsApprove />
          <div className="mt-[25px] w-[1131px] px-[40px] py-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="mb-[24px] flex h-[31px] w-[1051px] items-center justify-between">
              <div className="flex h-[28px] w-[92px] items-center">
                <span className="text-lg font-semibold leading-7 text-gray-500">投稿案</span>
              </div>
              <div className="flex h-[38px] w-[206px] items-center justify-between">
                <Link to="/PRST_production_management/contents_approved">
                  <Button className="text-sm font-medium leading-5">承認する</Button>
                </Link>
                <button onClick={handleClick1}>
                  <EditIcon width={24} height={24} fill="#6B7280" />
                </button>
                <img src={CommentIcon} alt="" />
              </div>
            </div>
            <div className="w-[1051px]">
              <span className="mb-[4px] h-[20px] w-[70px] text-sm font-semibold leading-5 text-gray-700">
                プレビュー
              </span>
              <div className="flex w-[1051px] justify-between py-[10px]">
                <div className="h-[531px]">
                  <img src={InstaReelImage} alt="" className="mb-[20px]" />
                  <img src={InstaStoryPlayIcon} alt="" />
                </div>
                <div className="h-[519px] border-l border-gray-200"></div>
                <div className="w-[748px]">
                  <div className="w-[748px]">
                    <div className="mb-[15px] flex h-[20px] w-[76px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <span className="text-xs font-normal leading-4 text-gray-800">テキスト</span>
                    </div>
                    <div className="mb-[40px] h-[256px] w-[681px] text-xs font-normal leading-4 text-gray-800">
                      「Canon360」
                      <br />
                      <br />
                      来週からイベントを開催！
                      <br />
                      賞金もあります...💌
                      <br />
                      <br />
                      --------------------------------------------------------------------------------
                      <br />
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません!
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                      <br />
                      <br />
                      -----------------------------------------------------------------------------------
                      <br />
                      ぜひ参加してくださいね！
                    </div>
                    <div className="h-[50px] w-[748px]">
                      <div className="mb-[15px] flex h-[20px] w-[100px] items-center justify-between">
                        <HashIcon width={20} height={20} fill="#374151" />
                        <div className="h-[16px] w-[72px]">
                          <p className="h-[16px] w-[72px] text-xs font-normal leading-4 text-gray-700">ハッシュタグ</p>
                        </div>
                      </div>
                      <div className="h-[16px] w-[681px] text-xs font-normal leading-4 text-gray-700">
                        #LANVINenBleu,#LANVINenBleu2023ss,#new,#newcollection
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[25px] h-[755px] w-[1133px] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[707px] w-[1085px]">
              <div className="mb-[24px] flex h-[38px] w-[1085px] items-center justify-between">
                <div className="flex h-[28px] w-[92px] items-center">
                  <span className="text-lg font-semibold leading-7 text-gray-500">イメージ案</span>
                </div>
                <div className="flex h-[38px] w-[206px] items-center justify-between ">
                  <div className="flex h-[24px] w-[76px] items-center justify-between bg-green-100 px-[8px] py-[2px]">
                    <span className="text-sm font-medium leading-5 text-green-800">承認済</span>
                    <button>
                      <ChevronDownIcon fill="#10B981" width={16} height={16} />
                    </button>
                  </div>
                  <EditIcon width={24} height={24} fill="#6B7280" />
                  <img src={CommentIcon} alt="" />
                </div>
              </div>
              <div className="h-[32px] w-[1085px]">
                <div className="mb-[8px] flex h-[20px] w-[56px] items-center">
                  <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                </div>
                <div className="mb-[8px] flex h-[28px] w-[162px] items-center">
                  <div className="mr-[5px] flex h-[20px] w-[56px] items-center">
                    <span className="text-sm font-semibold leading-5 text-gray-700">1 / 2枚目</span>
                  </div>
                  <div className="h-[28px] w-[101px]">
                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                  </div>
                </div>
                <div className="mb-[24px] flex h-[298px] w-[1085px] justify-between py-[10px]">
                  <div className="w-[290px]">
                    <img src={SampleMediaImage} alt="" className="w-[290px]" />
                  </div>
                  <div className="h-[236px] w-[715px] py-[10px]">
                    <div className="mb-[20px] flex h-[20px] w-[124px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <div className="flex h-[16px] w-[96px] items-center">
                        <span className="text-xs font-normal leading-4 text-gray-800">画像 / 動画の説明</span>
                      </div>
                    </div>
                    <div className="h-[176px] w-[715px] text-xs font-normal leading-4 text-gray-800">
                      <span>
                        人物画でカメラを持った状態など、いきいきと撮影している場面が想像できるような写真を用意していただきたいです！
                        <br />
                        <br />
                        以下のことはおやめください。
                        <br />
                        <br />
                        ・危険な場所での撮影
                        <br />
                        ・他社製品と撮影すること
                        <br />
                        ・暗い場所での撮影（明るいイメージを希望しているため）
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-[24px] h-[1px] w-[1085px] border-b border-[#DDDDDD]"></div>
                <div className="h-[242px] w-[1085px]">
                  <div className="mb-[8px] h-[20px] w-[1085px] px-[16px]">
                    <span className="h-[20px] w-[56px] text-sm font-semibold leading-5 text-gray-800">テキスト</span>
                  </div>
                  <div className="h-[214px] w-[1085px] p-[16px]">
                    <span className="h-[182px] w-[1053px] text-xs font-normal leading-4 text-gray-800">
                      ①「Canon360」という商品名を入れてください。
                      <br />
                      ②
                      イベント情報入れてださい。以下の内容をいれていただければ、自由に文章を作っていただいて大丈夫です！文章のトーンは、絵文字などを使って、読みやすく明るい感じでお願いします。
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません。
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{EditContentsModal()}</div>
    </>
  );
};
