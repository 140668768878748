import { useState } from 'react';

import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play.svg';

export type VideoProps = {
  url: string;
  className?: string;
};
export const Video = (props: VideoProps) => {
  const [isShow, setIsShow] = useState(false);

  const renderContent = isShow ? (
    <video controls src={props.url} className={`${props.className} rounded-lg`}>
      <track kind="captions" />
    </video>
  ) : (
    <div className={`flex ${props.className} items-center justify-center rounded-lg bg-[#D3DADF]`}>
      <PlayIcon height={132} width={132} fill="white" onClick={() => setIsShow(true)} />
    </div>
  );

  return renderContent;
};
