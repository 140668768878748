import { apiRequest } from '@/api/ApiUtils';
import { FetchPRSTListResponse, FetchPRSTQueryParamater } from '@/types/PRST';

// 案件一覧取得API
export const fetchPRSTListInfo = async (query: FetchPRSTQueryParamater): Promise<FetchPRSTListResponse> => {
  const queryParam = {};
  query.status && Object.assign(queryParam, { status: query.status.toString() });
  Object.assign(queryParam, { rows: query.rows.toString() });
  Object.assign(queryParam, { page: query.page.toString() });
  Object.assign(queryParam, { order: query.order.toString() });
  if (query.noChache) {
    Object.assign(queryParam, { date: new Date().toISOString() });
  }
  query.orderBy && Object.assign(queryParam, { orderBy: query.orderBy.toString() });
  const queryString = new URLSearchParams(queryParam).toString();
  return apiRequest<FetchPRSTListResponse>(`/projects/prst?${queryString}`, {
    method: 'GET',
  });
};
