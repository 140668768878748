import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const TableScrollToTop = () => {
  const search = useLocation().search;
  const query = new URLSearchParams(search);
  const type = query.get('type');

  useEffect(() => {
    // document.getElementsByTagName('tbody')[0].scrollTo(0, 0);
    const element = document.getElementsByTagName('tbody')[0];
    if (element) {
      element.scrollTo(0, 0);
    }
  }, [type]);

  return null;
};

export default TableScrollToTop;
