import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  DefaultApi,
  GetInformationRowsEnum,
  GetInformationOrderEnum,
  GetInformationOutput,
  GetInformationDetailOutput,
} from '@/api/information';
import { axiosInstance } from '@/utils/axiosInstance';
const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * お知らせ一覧取得API
 * @param rows
 * @param page
 * @param order
 * @returns
 */
export const fetchInformationsInfo = async (
  rows: GetInformationRowsEnum,
  page: number,
  order: GetInformationOrderEnum
): Promise<GetInformationOutput> => {
  try {
    const response: AxiosResponse<GetInformationOutput> = await api.getInformation(rows, page, order);
    return response.data as GetInformationOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * お知らせ詳細取得API
 * @param requestId
 * @returns
 */
export const fetchInformationInfo = async (requestId: string): Promise<GetInformationDetailOutput> => {
  try {
    const response: AxiosResponse<GetInformationDetailOutput> = await api.getInformationDetail(requestId);
    return response.data as GetInformationDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
