import NoImage from '@/assets/images/NoImage.png';
import { Badge } from '@/components/Elements/Badge/Badge';
import { getImageUrlIfRelative } from '@/utils/image';

type ProjectTitleType = {
  isEdit: boolean;
  image: string;
  title: string;
  tags: string[];
  editFunc: () => void;
};

export const ProjectTitle = (props: ProjectTitleType) => {
  return (
    <div className="w-[726.5px]">
      <div className="h-[44+48px] rounded-lg bg-white pl-[24px] pt-[24px]">
        <div className="flex h-[44+16px] w-[678.5px] justify-between">
          <div className="h-[28px] w-[344px] content-center">
            <span className="text-lg font-semibold leading-7 text-gray-500">案件募集タイトル</span>
          </div>
          {props.isEdit && (
            <button className="right-4 bg-transparent text-blue-500" onClick={() => props.editFunc()}>
              編集
            </button>
          )}
        </div>
      </div>
      <div className="flex flex-row bg-white pl-4 pt-6">
        <div>
          <img
            className="h-[116px] w-[174.12px]"
            src={props.image ? getImageUrlIfRelative(props.image) : NoImage}
            alt=""
          />
        </div>
        <div className="flex h-[116+24px] w-[695px] pl-[16px]">
          <div className="h-[28px] w-[504.88px] content-center pl-[16px]">
            <div className="text-base font-semibold leading-7 ">{props.title ? props.title : '-'}</div>
            <div className="flex gap-x-4 pt-[13px]">
              <div className="flex gap-x-[10px]">
                {props.tags.length ? (
                  props.tags.map((tag, index) => {
                    return (
                      <div key={index} className="pb-2">
                        <Badge color="gray" round="sm" size="xs" className="w-fit">
                          {tag}
                        </Badge>
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
