import { Account } from './Account';
import { AccountListStateProvider, useStateContext } from './AccountListStateContext';

export const PRSTProductionManagementNoAccountView = () => {
  const { state } = useStateContext();
  return (
    <>
      <AccountListStateProvider>
        {state ? (
          <div className="h-[749px] w-[1181px]">
            <div className="w-[1181px] border-b border-gray-200 p-[16px]">
              <p className="text-base font-semibold leading-7 text-gray-700">PRST(制作案件) 投稿制作管理一覧</p>
            </div>
            <Account />
          </div>
        ) : null}
      </AccountListStateProvider>
    </>
  );
};
