interface Props {
  images: string;
  caption: string;
}

export const PreviewFeed = (images: string[]) => {
  return (
    <div>
      <div className="flex h-[938px] w-[360px] flex-col items-start gap-[15px] rounded-lg">
        <div className="flex w-[360px] items-center justify-between"></div>
        <div id="preview">
          <img src={images[0]} alt="" className="rounded-lg" />
        </div>
        <div id="comment-area" className="overflow-y-auto">
          <div className="whitespace-pre-wrap text-sm font-normal leading-5">{`
イーロン・マスクによるTwitter買収の投資家ピッチの内容🔥
- 売上$5bn→$28bn
- 広告売上比率50%以下
- ユーザー数を2.1億人→ 9.3億人
- サブスク型新プロダクトを1億人
- ペイメント売上を1.3bn

a16z、Binance、Fidelityがこれに乗った🤯


`}</div>
        </div>
      </div>
    </div>
  );
};

// Storyとリールは同じ

export const PreviewStory = (props: Props) => {
  return (
    <div>
      <div className="flex h-[752px] w-[360px] flex-col items-start gap-[15px] rounded-lg ">
        <div className="flex gap-[10px] rounded bg-white pb-3">
          <div className="flex w-[360px] flex-col items-start gap-[5px]">
            <div className="flex flex-col items-start gap-[10px]">
              <div className="flex flex-col items-start">
                <div id="preview">
                  <img src={props.images} alt="" className="rounded-lg" />
                </div>
                <div id="comment-area" className="overflow-y-auto">
                  <div className="whitespace-pre-wrap text-sm font-normal leading-5">{`${props.caption}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const PreviewReal = (props: Props) => {
  return (
    <div>
      <div className="flex h-[752px] w-[360px] flex-col items-start gap-[15px] rounded-lg ">
        <div className="flex gap-[10px] rounded bg-white pb-3">
          <div className="flex w-[360px] flex-col items-start gap-[5px]">
            <div className="flex flex-col items-start gap-[10px]">
              <div className="flex flex-col items-start">
                <div id="preview">
                  <img src={props.images} alt="" className="rounded-lg" />
                </div>
                <div id="comment-area" className="overflow-y-auto">
                  <div className="whitespace-pre-wrap text-sm font-normal leading-5">{`${props.caption}`}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
