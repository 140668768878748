export interface RecruitmentInformationProps {
  enthusiasm: string;
  comments: string;
  introduction: string;
  strong: string;
  achievements: string;
}

// SNSアカウント詳細_採用前情報表示部品
export default function RecruitmentInformation(props: RecruitmentInformationProps) {
  const frameData = (title: string, description: string) => {
    return (
      <div className="flex flex-col items-start gap-[5px] self-stretch">
        <span className="text-sm font-semibold leading-5 text-gray-700">{title}</span>
        <div className="flex h-[99px] w-[1028px] items-start gap-[10px] self-stretch rounded-lg bg-gray-100 px-[10px] py-[5px] shadow-sm">
          <span className="whitespace-pre-wrap text-base font-normal text-gray-600">{description}</span>
        </div>
      </div>
    );
  };

  return (
    <div className="flex w-[1084px]  flex-col items-start gap-6">
      {frameData('案件への意気込み', props.enthusiasm)}
      {frameData('弊社担当からのコメント', props.comments)}
      {frameData('SNSアカウントの特徴紹介', props.introduction)}
      {frameData('SNSアカウントの強み', props.strong)}
      {frameData('SNSアカウントの実績', props.achievements)}
    </div>
  );
}
