import React from 'react';

import { Radio } from '@/components/Elements/Radio';

import {
  imagesReachCombination,
  imagesEngagementCombination,
  imagesIncreaseFollowerCombination,
  imagesReasearchCombination,
} from '../../constants/choices';
import { PROJECT_GOAL } from '../../constants/path';
import { FormProps, SelectProps } from '../../interfaces/project';

export default function SpecificImage(props: FormProps): React.ReactElement {
  const selectChoice = (): SelectProps[] => {
    const goal = props.payload.goal;

    if (goal === PROJECT_GOAL.REACH) {
      return imagesReachCombination;
    } else if (goal === PROJECT_GOAL.ENGAGEMENT) {
      return imagesEngagementCombination;
    } else if (goal === PROJECT_GOAL.FOLLOWER) {
      return imagesIncreaseFollowerCombination;
    } else {
      return imagesReasearchCombination;
    }
  };

  const selectDom = () => {
    return (
      <div className="mx-auto">
        {selectChoice().map((item: SelectProps) => {
          return (
            <Radio
              inputId={item.value}
              inputValue={item.value}
              label={item.label}
              isSelect={props.payload.image === item.value}
              onClick={() => {
                props.setPayload((state) => {
                  return {
                    ...state,
                    image: item.value,
                    important: '',
                    sns: '',
                    period: undefined,
                    isPeriodError: false,
                    periodErrorMessage: '',
                    budget: undefined,
                    isBudgetError: false,
                    budgetErrorMessage: '',
                  };
                });
              }}
              className="w-[576px]"
              key={item.value}
            ></Radio>
          );
        })}
      </div>
    );
  };

  return (
    <>
      <div id="specific_image" className="pt-12">
        <div className="mx-auto w-[576px] pr-6">{selectDom()}</div>
      </div>
    </>
  );
}
