import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementContentsApprovingInstagramStoryView } from '../components/PRSTProductionManagementContentsApprovingInstagramStoryView';

export const PRSTProductionManagementContentsApprovingInstagramStory = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementContentsApprovingInstagramStoryView />
    </MainLayout>
  );
};
