import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementContentsApprovingView } from '../components/PRSTProductionManagementContentsApprovingView';

export const PRSTProductionManagementContentsApproving = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementContentsApprovingView />
    </MainLayout>
  );
};
