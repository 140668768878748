import Chart from 'react-apexcharts';

import { SnsType } from '@/api/influencer';
import { SNS_TYPES } from '@/config';
import { ResponseDefaultJson, useAssetDataJson } from '@/hooks/AssetDataJson';

import donutChart, { labelColor } from '../../../followerAttribute/donutChart';
import { followerAttributesIf } from '../modalInterface';

interface FollowerAttributeProps {
  followerAttributes: followerAttributesIf;
  snsType: string;
}

// SNSアカウント詳細_フォロワー属性タブ表示部品
export default function FollowerAttributeCommon({ followerAttributes, snsType }: FollowerAttributeProps) {
  let byAgeSeries: { name: string; data: number[] }[] = [];
  let byAgeOptions: any = {};
  let raitionSeries: number[] = [];
  let totalFollowers = 0;
  let raitionLabels: labelColor[] = [];
  let prefecturesSeries: { data: number[] }[] = [];
  let prefecturesOptions: any = {};

  const ageGenderJson = useAssetDataJson('/follower_attr/age_gender');
  const genreJson = useAssetDataJson('/follower_attr/genres');
  const valuesJson = useAssetDataJson('/follower_attr/values');
  const prefectureJson = useAssetDataJson('/prefectures');
  const convertAgeGender = (ageGender: string, asset: ResponseDefaultJson | undefined) => {
    const value = asset?.find((data) => data.value === ageGender)?.label;
    return value ?? '';
  };
  const convertPrefecture = (prefCode: string, asset: ResponseDefaultJson | undefined) => {
    const value = asset?.find((data) => data.value === prefCode)?.label;
    return value ?? '';
  };

  if (snsType === SnsType.Instagram) {
    const ageDistribution = followerAttributes.ageDistribution || [];
    byAgeSeries = [
      { name: '男性', data: ageDistribution.map((age) => age.mans) },
      { name: '女性', data: ageDistribution.map((age) => age.womans) },
      { name: 'その他', data: ageDistribution.map((age) => age.others) },
    ];

    byAgeOptions = {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'right',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: ageDistribution.map((age) => `${age.distribution}歳`),
        labels: {
          style: {
            colors: ['#6B7280'],
          },
        },
      },
      yaxis: {
        min: 0,
        max: 200,
        labels: {
          style: {
            colors: ['#6B7280'],
          },
        },
      },
      colors: ['#007CC2', '#80D0FF', '#D1D5DB'],
      legend: {
        horizontalAlign: 'right' as 'right' | 'center' | 'left' | undefined,
        labels: {
          colors: ['#6B7280', '#6B7280', '#6B7280'],
        },
        itemMargin: {
          horizontal: 12,
          vertical: 0,
        },
      },
    };

    const followers = followerAttributes.followers || { mans: 0, womans: 0, others: 0 };
    raitionSeries = [followers.mans, followers.womans, followers.others];
    totalFollowers = raitionSeries.reduce((acc, value) => acc + value, 0);
    raitionLabels = [
      {
        label: `男性 (${((followers.mans / totalFollowers) * 100).toFixed(1)}%)`,
        color: '#004A75',
      },
      {
        label: `女性 (${((followers.womans / totalFollowers) * 100).toFixed(1)}%)`,
        color: '#007CC2',
      },
      {
        label: `その他 (${((followers.others / totalFollowers) * 100).toFixed(1)}%)`,
        color: '#D1D5DB',
      },
    ];

    const prefDistribution = followerAttributes.prefDistribution || [];
    prefecturesSeries = [{ data: prefDistribution.map((pref) => pref.value) }];
    prefecturesOptions = {
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: prefDistribution.map((pref) => convertPrefecture(pref.prefCode, prefectureJson)),
        labels: {
          style: {
            colors: ['#6B7280'],
          },
        },
      },
      yaxis: {
        min: 0,
        max: 200,
        labels: {
          style: {
            colors: ['#6B7280'],
          },
        },
      },
      colors: ['#007CC2'],
    };
  }

  const rankingContent = (label: string, values: string[], asset: ResponseDefaultJson | undefined) => {
    return (
      <div className="flex flex-1 flex-col items-start gap-2">
        <span className="text-sm font-semibold leading-5 text-gray-900">{label}</span>
        {values.map((value: string, index: number) => {
          return (
            <div className="flex items-center gap-2 self-stretch " key={index}>
              <span className="text-sm font-semibold leading-5">{index + 1}.</span>
              <div className="flex h-8 flex-1 items-center px-[13px] py-[9px]">
                <span className="text-sm font-medium leading-4">{convertAgeGender(value, asset)}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const ranking = () => {
    return (
      <div className="flex w-full flex-row justify-between">
        {/* ランキング 年代・性別 */}
        {rankingContent('年代・性別', followerAttributes.top3.ageGender, ageGenderJson)}
        {/* ランキング ジャンル */}
        {rankingContent('ジャンル', followerAttributes.top3.genre, genreJson)}
        {/* ランキング 価値観 */}
        {rankingContent('価値観', followerAttributes.top3.values, valuesJson)}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start gap-10 self-stretch p-6">
      {snsType === SNS_TYPES.INSTAGRAM ? (
        <>
          <div className="flex items-start gap-8 self-stretch">
            <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
              <span className="text-sm font-semibold leading-5 text-gray-900">フォロワーの年齢分布</span>
              <div className="flex h-[258px] w-[424px] gap-8">
                <Chart type="bar" options={byAgeOptions} series={byAgeSeries} height={259} width={417} />
              </div>
            </div>
            {donutChart({
              title: 'フォロワーの男女比',
              series: raitionSeries,
              labels: raitionLabels,
              totalInformation: {
                label: '合計',
                value: totalFollowers.toLocaleString(),
              },
            })}
          </div>
          <div className="flex items-start gap-8 self-stretch">
            <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
              <span className="text-sm font-semibold leading-5 text-gray-900">フォロワーの都道府県</span>
              <div className="flex h-[258px] w-[424px] gap-8">
                <Chart type="bar" options={prefecturesOptions} series={prefecturesSeries} height={231} width={417} />
              </div>
            </div>
          </div>
          <div className="flex items-start gap-10 self-stretch py-10">{ranking()}</div>
        </>
      ) : snsType === SNS_TYPES.TWITTER ? (
        <div>
          <div className="flex">
            <div>
              {donutChart({
                title: 'フォロワーの男女比/年齢分布',
                series: raitionSeries,
                labels: raitionLabels,
                totalInformation: {
                  label: '合計',
                  value: totalFollowers.toLocaleString(),
                },
              })}
            </div>
            <div>
              {donutChart({
                title: 'フォロワーのジャンル',
                series: raitionSeries,
                labels: raitionLabels,
                totalInformation: {
                  label: '合計',
                  value: totalFollowers.toLocaleString(),
                },
              })}
            </div>
          </div>

          <div>
            {donutChart({
              title: '価値観',
              series: raitionSeries,
              labels: raitionLabels,
              totalInformation: {
                label: '合計',
                value: totalFollowers.toLocaleString(),
              },
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col items-start gap-[64px] self-stretch">
          <div className="flex flex-col items-start gap-6 self-stretch">
            <div>
              <span className="text-sm font-bold">フォロワーのタイプ</span>
            </div>
            <div className="flex w-full flex-row justify-between">{ranking()}</div>
          </div>
        </div>
      )}
    </div>
  );
}
