import Chart from 'react-apexcharts';

import donutChart, { labelColor } from '../../../followerAttribute/donutChart';

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/tabs/FollwerAttributesCommon.tsx を使用してください
export default function FollowerAttribute() {
  const byAgeSeries = [
    { name: ' 男性', data: [160, 120, 110, 80, 50] },
    { name: ' 女性', data: [120, 110, 180, 30, 30] },
    { name: ' その他', data: [60, 40, 30, 20, 35] },
  ];

  const byAgeOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'right',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['13-17歳', '18-24歳', '25-34歳', '35-44歳', '45-54歳'],
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    yaxis: {
      min: 0,
      max: 200,
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    colors: ['#007CC2', '#80D0FF', '#D1D5DB'],
    legend: {
      horizontalAlign: 'right' as 'right' | 'center' | 'left' | undefined,
      labels: {
        colors: ['#6B7280', '#6B7280', '#6B7280'],
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
  };
  const raitionSeries = [29580, 14709, 4903];
  const raitionLabels: labelColor[] = [
    {
      label: '男性 (60%)',
      color: '#004A75',
    },
    {
      label: '女性 (30%)',
      color: '#007CC2',
    },
    {
      label: 'その他 (10%)',
      color: '#D1D5DB',
    },
  ];

  const prefecturesSeries = [{ data: [190, 160, 120, 70, 50, 40, 20] }];

  const prefecturesOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      // TODO: followerAttributesのprefDistributionのprefCodeの値を反映
      categories: ['東京都', '神奈川県', '大阪府', '愛知県', '埼玉県', '千葉県', '福岡県'],
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    yaxis: {
      min: 0,
      max: 200,
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    colors: ['#007CC2'],
  };

  const rankingContent = (label: string, values: string[]) => {
    return (
      <div className="flex flex-1 flex-col items-start gap-2">
        <span className="text-sm font-semibold leading-5 text-gray-900">{label}</span>
        {values.map((value: string, index: number) => {
          return (
            <div className="flex items-center gap-2 self-stretch " key={index}>
              <span className="text-sm font-semibold leading-5">{index + 1}.</span>
              <div className="flex h-8 flex-1 items-center px-[13px] py-[9px]">
                <span className="text-sm font-medium leading-4">{value}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start gap-10 self-stretch p-6">
      <div className="flex items-start gap-8 self-stretch">
        <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
          <span className="text-sm font-semibold leading-5 text-gray-900">フォロワーの年齢分布</span>
          <div className="flex h-[258px] w-[424px] gap-8">
            <Chart type="bar" options={byAgeOptions} series={byAgeSeries} height={259} width={417} />
          </div>
        </div>
        {donutChart({
          title: 'フォロワーの男女比',
          series: raitionSeries,
          labels: raitionLabels,
          totalInformation: {
            label: '合計',
            value: '49,303',
          },
        })}
      </div>
      <div className="flex items-start gap-8 self-stretch">
        <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
          <span className="text-sm font-semibold leading-5 text-gray-900">フォロワーの都道府県</span>
          <div className="flex h-[258px] w-[424px] gap-8">
            <Chart type="bar" options={prefecturesOptions} series={prefecturesSeries} height={231} width={417} />
          </div>
        </div>
      </div>
      <div className="flex items-start gap-10 self-stretch py-10">
        {/* ランキング 年代・性別 */}
        {rankingContent('年代・性別', ['20代女性', '30代女性', '40代女性'])}
        {/* ランキング ジャンル */}
        {rankingContent('ジャンル', ['#ダイエット', '#ボディメイク', '#フィットネス'])}
        {/* ランキング 価値観 */}
        {rankingContent('価値観', ['主張が強い', '協調性がある', 'こだわりがある'])}
      </div>
    </div>
  );
}
