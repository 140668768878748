import React from 'react';

import { Badge } from '@/components/Elements/Badge';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';
import { Input } from '@/components/Elements/Input';
import { SelectMenus } from '@/components/Elements/SelectMenus/SelectMenus';
import { TextArea } from '@/components/Elements/TextArea';
import { ResponseDefaultJson } from '@/hooks/AssetDataJson';

import { SelectType } from '../../PopupInterfaces';
import Layout, { Step } from '../Layout';

export default function Reel(
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  isTop: boolean,
  isLast: boolean,
  advertisementOption: ResponseDefaultJson
): React.ReactElement {
  const description = () => {
    return (
      <div className="pt-6">
        <Step seq="01" title="稼働概要"></Step>
        <div className="pt-6">
          <div className="text-sm font-semibold leading-5 text-gray-700">投稿期間*</div>
          <div className="flex">
            <DatePicker
              selectedDate={selected[index].postPeriodBegin}
              onChange={(date: any) => {
                const newSelected = [...selected];
                newSelected[index].postPeriodBegin = date;
                setSelected(newSelected);
                postPeriodBeginValidationCheck(newSelected, index, setSelected);
              }}
              className={
                (selected[index].postPeriodBeginError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : '') + 'pl-[11px] focus:border-white'
              }
              width="240px"
            />
            <span className="px-2 pt-2">〜</span>
            <DatePicker
              selectedDate={selected[index].postPeriodEnd}
              onChange={(date: any) => {
                const newSelected = [...selected];
                newSelected[index].postPeriodEnd = date;
                postPeriodEndValidationCheck(selected, index, setSelected);
              }}
              className={
                (selected[index].postPeriodEndError !== undefined
                  ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                  : '') + 'pl-[11px] focus:border-white'
              }
              width="240px"
            />
          </div>
          {selected[index].postPeriodBeginError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].postPeriodBeginError}</div>
          )}
          {selected[index].postPeriodEndError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].postPeriodEndError}</div>
          )}
        </div>
        <div className="pt-6">
          <TextArea
            title="投稿依頼内容*"
            helperText={`${selected[index].postRequestContent.length}/2000`}
            maxLength={2000}
            rows={4}
            placeholder="・〇〇を載せてください"
            value={selected[index].postRequestContent}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].postRequestContent = e.target.value;
              setSelected(newSelected);
              postRequestContentValidationCheck(selected, index, setSelected);
            }}
            className={
              (selected[index].postRequestContentError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + '"h-[105px] w-[630px]'
            }
          ></TextArea>
          {selected[index].postRequestContentError !== undefined && (
            <div className="pt-2 text-base text-red-400">{selected[index].postRequestContentError}</div>
          )}
        </div>
      </div>
    );
  };

  const item = () => {
    if (selected[index].advertisement == '') {
      selected[index].advertisement = 'PR';
      setSelected(selected);
    }
    // 投稿に含める広告表示を変更する関数
    const handleadvertisementChange = (value: string) => {
      const newSelected = [...selected];
      newSelected[index].advertisement = value;
      setSelected(newSelected);
    };
    // ハッシュタグを変更する関数
    const handleHashTagsChange = (e: any) => {
      const newSelected = [...selected];
      newSelected[index].hashTag = e.target.value;
      setSelected(newSelected);
    };
    // ハッシュタグを追加する関数
    const addHashTags = () => {
      const newSelected = [...selected];
      newSelected[index].hashTags.push(newSelected[index].hashTag);
      newSelected[index].hashTag = '';
      setSelected(newSelected);
    };
    const handleHashTagsRemove = (index: number, tagIndex: number) => {
      if (!selected[index].hashTags) return;
      const tmpTags = selected[index].hashTags;
      const newSelected = [...selected];
      newSelected[index].hashTags = tmpTags.filter((_, i) => i !== tagIndex);
      setSelected(newSelected);
    };
    const hashTagValidationCheck = () => {
      const newSelected = [...selected];
      if (newSelected[index].hashTag.length > 50) {
        newSelected[index].hashTagsError = '投稿に含める必須のハッシュタグは50文字以内で入力してください。';
        setSelected(newSelected);
        return false;
      } else {
        newSelected[index].hashTagsError = '';
        setSelected(newSelected);
        return true;
      }
    };
    // 任意ハッシュタグを変更する関数
    const handleOptionalHashTagsChange = (e: any) => {
      const newSelected = [...selected];
      newSelected[index].optionalHashTag = e.target.value;
      setSelected(newSelected);
    };
    // 任意ハッシュタグを追加する関数
    const addOptionalHashTags = () => {
      const newSelected = [...selected];
      newSelected[index].optionalHashTags.push(selected[index].optionalHashTag);
      newSelected[index].optionalHashTag = '';
      setSelected(newSelected);
    };
    const handleOptionalHashTagsRemove = (index: number, tagIndex: number) => {
      if (!selected[index].hashTags) return;
      const tmpTags = selected[index].optionalHashTags;
      const newSelected = [...selected];
      newSelected[index].optionalHashTags = tmpTags.filter((_, i) => i !== tagIndex);
      setSelected(newSelected);
    };
    const optionalHashTagValidationCheck = () => {
      const newSelected = [...selected];
      if (newSelected[index].optionalHashTag.length > 50) {
        newSelected[index].optionalHashTagError = '投稿に含める任意のハッシュタグは50文字以内で入力してください。';
        setSelected(newSelected);
        return false;
      } else {
        newSelected[index].optionalHashTagError = undefined;
        setSelected(newSelected);
        return true;
      }
    };
    // アカウントタグを変更する関数
    const handleAccountTagsChange = (e: any) => {
      const newSelected = [...selected];
      newSelected[index].accountTag = e.target.value;
      setSelected(newSelected);
    };
    // アカウントタグを追加する関数
    const addAccountTags = () => {
      const newSelected = [...selected];
      newSelected[index].accountTags.push(selected[index].accountTag);
      newSelected[index].accountTag = '';
      setSelected(newSelected);
    };
    const handleAccountTagsRemove = (index: number, tagIndex: number) => {
      if (!selected[index].accountTags) return;
      const tmpTags = selected[index].accountTags;
      const newSelected = [...selected];
      newSelected[index].accountTags = tmpTags.filter((_, i) => i !== tagIndex);
      setSelected(newSelected);
    };
    const accountTagValidationCheck = () => {
      const newSelected = [...selected];
      if (selected[index].accountTag.length > 100) {
        newSelected[index].accountTagError = '投稿に含めるアカウントタグは100文字以内で入力してください。';
        setSelected(newSelected);
        return false;
      } else {
        newSelected[index].accountTagError = undefined;
        setSelected(newSelected);
        return true;
      }
    };

    return (
      <div className="pt-6">
        <Step seq="02" title="投稿に含める情報"></Step>
        <div className="flex w-full flex-col items-start pt-6">
          <label>{'投稿に含める広告表示(#)*'}</label>
          <SelectMenus
            title=""
            options={advertisementOption}
            value={selected[index].advertisement}
            onChange={(option: any) => handleadvertisementChange(option.value)}
          />
        </div>
        <div className="flex items-center pt-[10px]">
          <div style={{ color: '#6B7280' }}>タイアップ投稿ラベルも必ずつきます。</div>
        </div>
        <div className="flex w-full flex-col items-start pt-6">
          <div className="text-sm font-semibold leading-5 text-gray-700">投稿に含めるハッシュタグ(#)</div>
          <div className="w-full">
            <div className="mb-[10px] flex items-center">
              <Input
                placeholder="キャプションに含めたい必須のハッシュタグ"
                isPlaceHolderRight={false}
                value={selected[index].hashTag}
                onChange={(e: any) => {
                  handleHashTagsChange(e);
                  hashTagValidationCheck();
                }}
                className={
                  (selected[index].hashTagsError !== undefined && selected[index].hashTagsError !== ''
                    ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                    : '') + 'w-[100%] px-[10px]'
                }
              />
              <button
                className="ml-[10px] inline-flex h-[40px] w-[60px] items-center justify-center rounded-md border border-blue-500 py-[5px] text-sm font-medium leading-5 text-white"
                style={{ color: '#3b82f6' }}
                onClick={() => {
                  if (hashTagValidationCheck() && selected[index].hashTag !== '') addHashTags();
                }}
              >
                追加
              </button>
            </div>
            {selected[index].hashTagsError !== undefined && selected[index].hashTagsError !== '' && (
              <div className="text-base text-red-400">{selected[index].hashTagsError}</div>
            )}
          </div>
          <div className="flex gap-[10px] ">
            {selected[index].hashTags &&
              selected[index].hashTags.map((tag, tagIndex) => {
                return (
                  <div key={index} className="pb-2">
                    <Badge
                      color="gray"
                      round="xl"
                      size="xs"
                      className="w-fit px-2 py-3"
                      isRemovable={true}
                      handleRemove={() => {
                        handleHashTagsRemove(index, tagIndex);
                      }}
                    >
                      {tag}
                    </Badge>
                  </div>
                );
              })}
          </div>
          <div className="w-full">
            <div className="mb-[10px] flex items-center">
              <Input
                placeholder="キャプションに含めたい任意のハッシュタグ"
                isPlaceHolderRight={false}
                value={selected[index].optionalHashTag}
                onChange={(e: any) => {
                  handleOptionalHashTagsChange(e);
                  optionalHashTagValidationCheck();
                }}
                className={
                  (selected[index].optionalHashTagError !== undefined && selected[index].optionalHashTagError !== ''
                    ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                    : '') + 'w-[100%] px-[10px]'
                }
              />
              <button
                className="ml-[10px] inline-flex h-[40px] w-[60px] items-center justify-center rounded-md border border-blue-500 py-[5px] text-sm font-medium leading-5 text-white"
                style={{ color: '#3b82f6' }}
                onClick={() => {
                  if (optionalHashTagValidationCheck() && selected[index].optionalHashTag !== '') addOptionalHashTags();
                }}
              >
                追加
              </button>
            </div>
            {selected[index].optionalHashTagError !== undefined && selected[index].optionalHashTagError !== '' && (
              <div className="text-base text-red-400">{selected[index].optionalHashTagError}</div>
            )}
          </div>
          <div className="flex gap-[10px] ">
            {selected[index].optionalHashTags &&
              selected[index].optionalHashTags.map((tag, tagIndex) => {
                return (
                  <div key={index} className="pb-2">
                    <Badge
                      color="gray"
                      round="xl"
                      size="xs"
                      className="w-fit px-2 py-3"
                      isRemovable={true}
                      handleRemove={() => {
                        handleOptionalHashTagsRemove(index, tagIndex);
                      }}
                    >
                      {tag}
                    </Badge>
                  </div>
                );
              })}
          </div>
        </div>
        <label className="items-center">
          <input
            type="checkbox"
            className="mr-[10px]"
            checked={selected[index].showToHireeOnlyHashTags}
            onChange={() => {
              const newSelected = [...selected];
              newSelected[index].showToHireeOnlyHashTags = !newSelected[index].showToHireeOnlyHashTags;
              setSelected(newSelected);
            }}
          />
          ハッシュタグを採用者のみ公開
        </label>
        <div className="flex w-full flex-col items-start pt-6">
          <div className="text-sm font-semibold leading-5 text-gray-700">投稿に含めるアカウントタグ(@)</div>
          <div className="w-full">
            <div className="mb-[10px] flex items-center">
              <Input
                placeholder="投稿に含める必須のアカウントタグ"
                isPlaceHolderRight={false}
                value={selected[index].accountTag}
                onChange={(e: any) => {
                  handleAccountTagsChange(e);
                  accountTagValidationCheck();
                }}
                className={
                  (selected[index].accountTagsError !== undefined && selected[index].accountTagError !== ''
                    ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                    : '') + 'w-[100%] px-[10px]'
                }
              />
              <button
                className="ml-[10px] inline-flex h-[40px] w-[60px] items-center justify-center rounded-md border border-blue-500 py-[5px] text-sm font-medium leading-5 text-white"
                style={{ color: '#3b82f6' }}
                onClick={() => {
                  if (accountTagValidationCheck() && selected[index].accountTag !== '') addAccountTags();
                }}
              >
                追加
              </button>
            </div>
            {selected[index].accountTagError !== undefined && selected[index].accountTagError !== '' && (
              <div className="text-base text-red-400">{selected[index].accountTagError}</div>
            )}
          </div>
          <div className="flex gap-[10px] ">
            {selected[index].accountTags &&
              selected[index].accountTags.map((tag, tagIndex) => {
                return (
                  <div key={index} className="pb-2">
                    <Badge
                      color="gray"
                      round="xl"
                      size="xs"
                      className="w-fit px-2 py-3"
                      isRemovable={true}
                      handleRemove={() => {
                        handleAccountTagsRemove(index, tagIndex);
                      }}
                    >
                      {tag}
                    </Badge>
                  </div>
                );
              })}
          </div>
        </div>
        <label className="items-center">
          <input
            type="checkbox"
            className="mr-[10px]"
            checked={selected[index].showToHireeOnlyAccountTags}
            onChange={() => {
              const newSelected = [...selected];
              newSelected[index].showToHireeOnlyAccountTags = !newSelected[index].showToHireeOnlyAccountTags;
              setSelected(newSelected);
            }}
          />
          アカウントタグを採用者のみ公開
        </label>
        <div className="flex w-full flex-col items-start pt-6">
          <div className="text-sm font-semibold leading-5 text-gray-700">投稿に含める位置情報</div>
          <Input
            placeholder="投稿に含める必須の位置情報"
            isPlaceHolderRight={false}
            value={selected[index].locationInfo}
            onChange={(e: any) => {
              const newSelected = [...selected];
              newSelected[index].locationInfo = e.target.value;
              setSelected(newSelected);
              locationInfoValidationCheck(selected, index, setSelected);
            }}
            className={
              (selected[index].lacationInfoError !== undefined
                ? 'border-red-400 outline-none ring-red-400 focus:ring-red-400 active:ring-red-400'
                : '') + ' w-[95%] px-[10px]'
            }
          />
        </div>
        {selected[index].lacationInfoError !== undefined && (
          <div className="pt-2 text-base text-red-400">{selected[index].lacationInfoError}</div>
        )}
        <label className="items-center">
          <input
            type="checkbox"
            className="mr-[10px]"
            checked={selected[index].showToHireeOnlyLocationInfo}
            onChange={() => {
              const newSelected = [...selected];
              newSelected[index].showToHireeOnlyLocationInfo = !newSelected[index].showToHireeOnlyLocationInfo;
              setSelected(newSelected);
            }}
          />
          位置情報を採用者のみ公開
        </label>
      </div>
    );
  };

  const itemDetail = () => {
    return <div className="pt-6">{item()}</div>;
  };

  return (
    <>
      <Layout
        key={selected[index].id}
        titile="リール投稿"
        isTop={isTop}
        isLast={isLast}
        selected={selected}
        index={index}
        setSelected={setSelected}
      >
        <div className="w-[696px] border-x border-b px-6 pb-6">
          {description()}
          {itemDetail()}
        </div>
      </Layout>
    </>
  );
}

const postPeriodBeginValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (!selected[index].postPeriodBegin) {
    const newSelected = [...selected];
    newSelected[index].postPeriodBeginError = '投稿期間（開始日）を入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].postPeriodEnd && selected[index].postPeriodBegin! > selected[index].postPeriodEnd!) {
    const newSelected = [...selected];
    newSelected[index].postPeriodBeginError = '投稿期間（開始日）は投稿期間（終了日）より前の日付を入力してください。';
    newSelected[index].postPeriodEndError = undefined;
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].postPeriodBeginError = undefined;
    if (selected[index].postPeriodEnd) {
      newSelected[index].postPeriodEndError = undefined;
    }
    setSelected(newSelected);
  }

  return validResult;
};

const postPeriodEndValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (!selected[index].postPeriodEnd) {
    const newSelected = [...selected];
    newSelected[index].postPeriodEndError = '投稿期間（終了日）を入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].postPeriodBegin && selected[index].postPeriodBegin! > selected[index].postPeriodEnd!) {
    const newSelected = [...selected];
    newSelected[index].postPeriodEndError = '投稿期間（終了日）は投稿期間（開始日）より後の日付を選択してください。';
    newSelected[index].postPeriodBeginError = undefined;
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].postPeriodEndError = undefined;
    if (selected[index].postPeriodBegin) {
      newSelected[index].postPeriodBeginError = undefined;
    }
    setSelected(newSelected);
  }

  return validResult;
};

const postRequestContentValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].postRequestContent === '') {
    const newSelected = [...selected];
    newSelected[index].postRequestContentError = '投稿依頼内容を入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else if (selected[index].postRequestContent.length > 2000) {
    const newSelected = [...selected];
    newSelected[index].postRequestContentError = '投稿依頼内容は2000文字以内で入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].postRequestContentError = undefined;
    setSelected(newSelected);
  }

  return validResult;
};

const locationInfoValidationCheck = (
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
) => {
  if (selected[index].locationInfo.length > 50) {
    const newSelected = [...selected];
    newSelected[index].lacationInfoError = '投稿に含める位置情報は50文字以内で入力してください。';
    setSelected(newSelected);
    validResult = false;
  } else {
    const newSelected = [...selected];
    newSelected[index].lacationInfoError = undefined;
    setSelected(newSelected);
  }

  return validResult;
};

export function reelValidationCheck(
  selected: SelectType[],
  index: number,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>,
  validResult = true
): boolean {
  validResult = postPeriodBeginValidationCheck(selected, index, setSelected, validResult);
  validResult = postPeriodEndValidationCheck(selected, index, setSelected, validResult);
  validResult = postRequestContentValidationCheck(selected, index, setSelected, validResult);
  validResult = locationInfoValidationCheck(selected, index, setSelected, validResult);
  return validResult;
}
