import clsx from 'clsx';
import * as React from 'react';

export type TextAreaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  title?: string;
  placeholder?: string;
  helperText?: string;
  onChange?: any;
  width?: string;
  isError?: boolean;
  count?: boolean;
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className = '', width = 'w-[320px]', ...props }, ref) => {
    return (
      <>
        <div className="flex flex-col items-start gap-2 p-0">
          {props.title && (
            <div>
              <span className="text-sm font-medium leading-5 text-gray-700">{props.title}</span>
            </div>
          )}
          <div className="flex flex-col items-start">
            <div className="relative flex flex-col items-start">
              <textarea
                ref={ref}
                placeholder={props.placeholder ? props.placeholder : ''}
                className={clsx(
                  'flex flex-row justify-between rounded-md border-[1px] border-gray-300',
                  className,
                  width,
                  props.isError ? 'border-red-400 ring-red-400 focus:ring-red-400 active:ring-red-400' : ''
                )}
                onChange={props.onChange}
                value={props.value || ''}
                rows={props.rows}
                cols={props.cols}
              ></textarea>
              {props.count && (
                <span
                  className={clsx(
                    'absolute bottom-4 right-5 text-sm font-normal leading-5 text-gray-400',
                    typeof props.value === 'string' && props.maxLength && props.value.length > props.maxLength
                      ? 'text-red-600'
                      : ''
                  )}
                >
                  {typeof props.value === 'string' ? props.value.length : '0'}/{props.maxLength ? props.maxLength : '-'}
                </span>
              )}
            </div>
            {props.helperText && (
              <div>
                <span className="text-sm font-medium leading-5 text-gray-400">{props.helperText}</span>
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
);

TextArea.displayName = 'TextArea';
