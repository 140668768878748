import { phaseInfo } from '../Interfaces/PhaseNavigationInterfaces';

import { perPhase } from './PerPhase';

export function PhaseNavigation(props: phaseInfo[]): React.ReactElement {
  return (
    <nav aria-label="Progress">
      <ol className="divide-y divide-gray-300 rounded-md border border-gray-300 md:flex md:divide-y-0">
        {props.map((pahse: phaseInfo, index: number) => (
          <li key={index} className="relative md:flex md:flex-1">
            {perPhase(pahse, index)}
            {index !== props.length - 1 ? (
              <>
                {/* Arrow separator for lg screens and up */}
                <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : (
              <></>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
}
