import { useState } from 'react';

import LeftPic from './assets/bx-chevron-left1.svg';
import PostAddImage from './assets/PostAdd.svg';
import SortPreviewImage from './assets/SortPreview.svg';
import Pagenation from './Pagenation';
import PostList from './PostList';
import Preview from './Preview';

// const items = [
//   { id: 1, title: 'Back End Developer', department: 'Engineering', type: 'Full-time', location: 'Remote' },
//   { id: 2, title: 'Front End Developer', department: 'Engineering', type: 'Full-time', location: 'Remote' },
//   { id: 3, title: 'User Interface Designer', department: 'Design', type: 'Full-time', location: 'Remote' },
// ];

export default function ContentView() {
  const [isActive, setIsActive] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const previewClick = () => {
    setIsPreview(true);
  };

  return (
    <div className="relative w-[1181px] bg-gray-50">
      {/* ヘッダー部分↓ */}
      <div className="flex h-[40px] w-[1149px]">
        <div className="pl-[24px] pt-[25px]">
          <img className="h-[11.41px] w-[7.12px]" src={LeftPic} alt="" />
        </div>
        <p className="my-[16px] ml-[16px] h-[28px] w-[285px] text-base font-semibold leading-7 text-gray-700">
          10月分 エメポスMVP施策
        </p>
      </div>
      <div className="flex h-[76px] w-[1181px] border-b border-gray-200">
        <p className="ml-[16px] mt-[24px] h-[28px] w-[127px] text-base font-semibold leading-7 text-gray-700">
          投稿制作一覧
        </p>
        <button
          type="button"
          className="ml-[670px] mt-[19px] inline-flex h-[38px] w-[1８６px] items-center gap-x-1.5 rounded-md bg-[#ffffff] px-[17px] py-[9px] text-xs font-semibold text-gray-500 shadow-sm"
          onClick={() => previewClick()}
        >
          <img src={SortPreviewImage} alt="" className="h-5 w-5" />
          並び順プレビュー
        </button>
        <div>{Preview(isPreview, setIsPreview)}</div>
        <button
          type="button"
          className="ml-[10px] mt-[19px] inline-flex h-[38px] w-[1８６px] items-center gap-x-1.5 rounded-md bg-[#007CC2] px-[17px] py-[9px] text-xs font-semibold text-white shadow-sm"
          onClick={() => setIsActive(!isActive)}
        >
          <img src={PostAddImage} alt="" className="h-5 w-5" />
          投稿制作を新規追加
        </button>
      </div>
      {/* メイン↓ */}
      <div className="w-[1181px] px-[24px] pt-[24px]">
        <PostList />
      </div>
      {/*page 移動を行う場所 何件中、何件を表示*/}
      <div>
        <div className="w-[1157px] pl-[24px]">
          <Pagenation />
        </div>
      </div>
    </div>
  );
}
