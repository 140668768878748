import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { Banner, DefaultApi } from '@/api/advertisement';
import { axiosInstance } from '@/utils/axiosInstance';

const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * 企業バナー取得API
 */
export const getCompanyBanners = async (): Promise<Banner[]> => {
  try {
    const response: AxiosResponse<Banner[]> = await api.getCompanyBanners();
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
