import React from 'react';

import {
  goalsCombination,
  imagesReachCombination,
  imagesEngagementCombination,
  imagesIncreaseFollowerCombination,
  imagesReasearchCombination,
  importantQuantityCombination,
  importantQualityCombination,
  importantIncreaseFollowerCombination,
  importantAdviceCombination,
} from '../constants/choices';
import { PROJECT_GOAL, PROJECT_IMAGE } from '../constants/path';
import { FormData } from '../interfaces/project';
import { isMeasure } from '../utils/logic';

export interface DetailProps {
  payload: FormData;
}

export default function projectInputDetail(props: DetailProps): React.ReactElement {
  //"このような案件内容はいかがですか？"のsns画面ウィザード表記用
  let displaySns = props.payload.sns;
  switch (props.payload.sns) {
    case 'instagram':
      displaySns = 'Instagram';
      break;
    case 'tik_tok':
      displaySns = 'TikTok';
      break;
    case 'youtube':
      displaySns = 'YouTube';
      break;
    case 'x':
      displaySns = 'X';
      break;
    default:
      props.payload.sns;
  }

  const budget: number = props.payload.budget ?? 0;

  const selectImplementationMethodLabel = (): string => {
    const goal = props.payload.goal;

    if (goal === PROJECT_GOAL.REACH) {
      return imagesReachCombination.filter((image) => image.value == props.payload.image)[0].label;
    } else if (goal === PROJECT_GOAL.ENGAGEMENT) {
      return imagesEngagementCombination.filter((image) => image.value == props.payload.image)[0].label;
    } else if (goal === PROJECT_GOAL.FOLLOWER) {
      return imagesIncreaseFollowerCombination.filter((image) => image.value == props.payload.image)[0].label;
    } else {
      return imagesReasearchCombination.filter((image) => image.value == props.payload.image)[0].label;
    }
  };

  const selectImportantLabel = (): string => {
    const important = props.payload.important;
    const image = props.payload.image;
    if (important == '') return '-';
    if (image === PROJECT_IMAGE.IMPORTANT_QUANTITY) {
      return importantQuantityCombination.filter((item) => item.value == important)[0].label;
    } else if (image === PROJECT_IMAGE.IMPORTANT_QUALITY) {
      return importantQualityCombination.filter((item) => item.value == important)[0].label;
    } else if (image === PROJECT_IMAGE.PR_BY_INFLUENCER) {
      return importantIncreaseFollowerCombination.filter((item) => item.value == important)[0].label;
    } else if (image === PROJECT_IMAGE.ADVICE_IMPORTANT_QUALITY) {
      return importantAdviceCombination.filter((item) => item.value == important)[0].label;
    } else {
      return '-';
    }
  };

  const card = (title: string, value: string) => {
    return (
      <div className="flex w-[281px] items-start self-stretch rounded-lg bg-gray-50">
        <div className="flex flex-row items-center gap-5 p-4">
          <div className="flex flex-col items-start  p-0">
            <div>
              <span className="text-sm font-medium leading-5 text-gray-500">{title}</span>
            </div>
            <div>
              <span className="text-sm font-semibold leading-5 text-gray-900">{value}</span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-[210px] w-[860px] flex-col items-start gap-2">
      <div className="flex-none">
        <span className="text-lg font-semibold not-italic leading-7 text-gray-500">入力内容</span>
      </div>
      <div className="flex h-[92px] flex-row items-start gap-2 pb-2">
        {card(
          '目的',
          props.payload.goal == '' ? '' : goalsCombination.filter((goal) => goal.value == props.payload.goal)[0].label
        )}
        {card('実施方法', selectImplementationMethodLabel())}
        {card('重視する点', selectImportantLabel())}
      </div>
      <div className="flex h-[74px] flex-row items-start gap-2">
        {card('SNS', displaySns === '' ? 'その他' : displaySns)}
        {card(
          isMeasure(props.payload) ? '回数' : '期間',
          `${props.payload.period}${isMeasure(props.payload) ? '回' : 'ヶ月'}`
        )}
        {card('予算', `￥${budget.toLocaleString()}`)}
      </div>
    </div>
  );
}
