interface RecomendProps {
  src: string;
  title: string;
  value: string;
  unit: string;
  description: string;
}

export const Recomend = (props: RecomendProps) => {
  return (
    <div className="flex w-[472px] items-center gap-8 bg-gray-50 p-8">
      <div>
        <img src={props.src} alt="" className="h-[120px] w-[120px] object-cover"></img>
      </div>
      <div className="h-[112px] w-[320px]  pl-4">
        <div className="gap-4">
          <div className="flex h-10 items-center gap-2 pb-4 text-gray-700">
            <span className="text-sm font-normal leading-5">{props.title}</span>
            <span className="text-4xl font-medium leading-10">{props.value}</span>
            <span className="text-base font-semibold leading-6">{props.unit}</span>
          </div>
          <div className="w-[320px] border-t border-gray-300"></div>
          <div className="pt-4">
            <span className="text-sm font-normal leading-5 text-gray-500">{props.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};
