import { useState, useEffect, useRef } from 'react';

import TrashImage from './assets/bx-trash-alt.svg';
import DotsHorizontalImage from './assets/DotsHorizontal.svg';
import Popup from './Popup';

export default function DotsDropdown(): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const insideRef = useRef<HTMLButtonElement>(null);
  // const options = [{ text: '削除する', link: '/PRST_post_delete' }];
  const [open, setOpen] = useState(false);

  const change_status = () => {
    setOpen(true);
  };

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsActive(false);
      } else {
        return;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [insideRef]);

  return (
    <div className="relative inline-block h-[72px] text-left">
      <button
        ref={insideRef}
        className="mt-[35px] h-[4px] w-[16px] gap-x-1.5 bg-white text-sm font-medium leading-5 text-gray-700 hover:bg-gray-50"
        onClick={() => setIsActive(!isActive)}
      >
        <img src={DotsHorizontalImage} alt="" className="h-full w-full" />
        <div>{Popup(open, setOpen)}</div>
      </button>
      {isActive && (
        <div className="absolute right-[26px] top-0 z-10 mt-[28px] w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none">
          <div className="py-3 pl-3">
            <a href="#" onClick={() => change_status()}>
              <div className="flex">
                <div>
                  <img src={TrashImage} alt="" />
                </div>
                <div className="pl-4 text-sm text-red-600">削除する</div>
              </div>
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
