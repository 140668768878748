import React, { Suspense } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';

import { NotFound } from '@/features/misc';
import lazyImport from '@/utils/lazyImport';

const { SignOutPost } = lazyImport(() => import('@/features/sign-out'), 'SignOutPost');

/**
 * ログイン状態のレイアウト
 * @returns {React.ReactElement} レイアウトの要素
 */
function App(): React.ReactElement {
  return (
    <main>
      <Suspense
        fallback={
          <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-l-gray-500" />
          </div>
        }
      >
        <Outlet />
      </Suspense>
    </main>
  );
}

/**
 * ログイン状態のルート
 */
const protectedRoutes: Array<RouteObject> = [
  {
    path: '/app',
    element: <App />,
    children: [{ path: '*', element: <NotFound /> }],
  },
  {
    path: '/auth/sign-out-post',
    element: <SignOutPost />,
  },
];

export default protectedRoutes;
