import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';

import { ReactComponent as FileIcon } from '@/assets/icons/bx-file.svg';
// import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as ImagesIcon } from '@/assets/icons/bx-images.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { Badge } from '@/components/Elements/Badge';

import twitter from '../../../../../src/assets/TwitterLogo.png';
import EmeraldContent from '../Analysis/emeraldContent';
import EmeraldFollower from '../followerAttribute/emeraldFollower';
import avator1 from '../posts/assets/Avatar1.png';
import avator2 from '../posts/assets/Avatar2.png';
import avator3 from '../posts/assets/Avatar3.png';
import instagram from '../posts/assets/instagram.png';
import sample1 from '../posts/assets/sample1.png';
import sample2 from '../posts/assets/sample2.png';
import sample3 from '../posts/assets/sample3.png';
// import tiktok from '../posts/assets/tiktok.png';
// import youtube from '../posts/assets/youtube.png';
import EmeraldPost from '../posts/emeraldPost';
import GoodQuality from '../posts/goodQuality';

export interface SummaryProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sns: string;
  projectType: string;
  id: string;
  project: string;
  projectName: string;
  span: string;
}

interface TabProps {
  name: string;
  key: string;
  icon: React.ReactElement<any, any>;
  activeIcon: React.ReactElement<any, any>;
}

interface tableBodyProps {
  sns: string;
  snsWord: string; //instagram以外は、インプレッション数を取ってこないので、インプレッション数を取得するための判定用
  image: string;
  icon: string;
  id: string;
  follower: string;
  impressions: string;
  text: string;
  goodquality: string;
}

export default function Summary(props: SummaryProps) {
  const [tabKey, setTabKey] = useState<string>('summary');
  const [tableBody, setTableBody] = useState<tableBodyProps[]>([]);

  useEffect(() => {
    const tableBodyContents: tableBodyProps[] = [];
    for (let i = 1; i < 301; i++) {
      const id = ('00000' + i).slice(-4);
      const sns = i % 4 === 0 ? twitter : i % 3 === 0 ? instagram : i % 2 === 0 ? twitter : instagram;
      const snsWord = i % 4 === 0 ? 'twitter' : i % 3 === 0 ? 'instagram' : i % 2 === 0 ? 'twitter' : 'instagram';
      const icon = i % 3 === 0 ? avator3 : i % 2 === 0 ? avator2 : avator1;
      const image = i % 3 === 0 ? sample3 : i % 2 === 0 ? sample2 : sample1;
      tableBodyContents.push({
        sns: sns,
        snsWord: snsWord,
        icon: icon,
        id: `A${id}`,
        image: image,
        follower: `${Math.floor(Math.random() * 101)}`,
        impressions: `${Math.floor(Math.random() * 101)}`,
        text: '投稿テキスト投稿テキスト投稿テキスト投稿テキスト投稿テキスト...',
        goodquality: i % 2 === 0 ? 'true' : 'false',
      });
    }
    setTableBody(tableBodyContents);
  }, []);

  const displanContent = (): any => {
    if (tabKey == 'summary') {
      return resultSummaryContent();
    } else if (tabKey == 'follwer') {
      return fllowerAttribute();
    } else if (tabKey == 'post') {
      return postContent();
    } else {
      return goodQualityContent();
    }
  };

  const resultSummaryContent = () => {
    return <EmeraldContent id={props.id} isAggregating={+props.id % 2 == 0} />;
  };

  const fllowerAttribute = () => {
    return <EmeraldFollower />;
  };

  const postContent = () => {
    return <EmeraldPost tablebody={tableBody} />;
  };

  const goodQualityContent = () => {
    return <GoodQuality tablebody={tableBody} />;
  };

  const selectTab = (): TabProps[] => {
    return [
      {
        name: '結果サマリーと考察',
        key: 'summary',
        icon: <FileIcon width={20} height={20} fill="#9CA3AF" />,
        activeIcon: <FileIcon width={20} height={20} fill="#007CC2" />,
      },
      // {
      //   name: 'フォロワー属性',
      //   key: 'follwer',
      //   icon: <GroupIcon width={20} height={20} fill="#9CA3AF" />,
      //   activeIcon: <GroupIcon width={20} height={20} fill="#007CC2" />,
      // },
      {
        name: '投稿',
        key: 'post',
        icon: <ImagesIcon width={20} height={20} fill="#9CA3AF" />,
        activeIcon: <ImagesIcon width={20} height={20} fill="#007CC2" />,
      },
      {
        name: 'GOOD QUALITYボーナス',
        key: 'feedback',
        icon: <LikeIcon width={24} height={24} fill="#9CA3AF" />,
        activeIcon: <LikeIcon width={20} height={20} fill="#007CC2" />,
      },
    ];
  };
  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };
  const onClose = () => {
    setTabKey('summary');
    props.setOpen(false);
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-[1110px]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="flex flex-col items-start gap-1 self-stretch p-6">
                      <div className="flex items-center gap-2 self-stretch">
                        <div className="flex flex-1 items-center justify-between self-stretch">
                          <div className="flex items-center gap-4">
                            <div className="flex flex-col items-start gap-1">
                              <div className="flex items-center gap-[10px]">
                                <span className="text-xs font-normal leading-none text-gray-700">{props.project}</span>
                              </div>
                              <div className="flex items-center gap-2">
                                <span className="text-base font-bold leading-6 text-gray-700">{props.projectName}</span>
                                <Badge color="green" size="sm" round="sm" className="p-2">
                                  EMERALD POST
                                </Badge>
                              </div>
                            </div>
                          </div>
                          <span className="text-sm font-normal leading-5 text-gray-500">実施期間：{props.span}</span>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center self-stretch px-4 py-0">
                      <div className="flex h-[38px] items-center justify-center self-stretch">
                        <div className="flex h-[38px] w-[1041px] items-center gap-8 border-b-[1px]  border-gray-200 pl-0">
                          {selectTab().map((tab: TabProps) => {
                            return (
                              <button
                                key={tab.key}
                                className={clsx('flex flex-col items-center justify-center')}
                                disabled={tab.key == 'summary' ? false : +props.id % 2 == 0 ? true : false} // TODO 集計中の判定は別途行う必要あり
                                onClick={() => {
                                  handleTabSelct(tab.key);
                                }}
                              >
                                <div
                                  className={clsx(
                                    'flex items-center justify-center gap-2  px-1 pb-4 pt-0',
                                    tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                  )}
                                >
                                  {tabKey == tab.key ? tab.activeIcon : tab.icon}
                                  {tab.name}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div id="main-frame" className="flex items-start gap-2 self-stretch p-6">
                      {displanContent()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
