import { PreviewFeed, PreviewStory, PreviewReal } from '@/components/Preview/instagram';
import { InstagramPostType } from '@/types/index';

import sampleInstagram from '../../../../../../../src/components/ProjectManagements/Applicant/Contents/common/Pic/beauty.png';
export interface accountInsightProps {
  id: string;
  avator: string;
  postType: InstagramPostType;
  insight: JSX.Element;
  selected: number | string;
  image?: string;
  caption?: string;
}

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/tabs/PostInsightCommon.tsx を使用してください
export default function AccountInsightInstagram(props: accountInsightProps) {
  const href = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams.get('type');
  console.log(window.location.href.includes('emerald_post') ? 'emepo' : 'not emepo');

  const selectPreview = () => {
    if (props.postType == 'Feed') {
      return PreviewFeed([sampleInstagram]);
    } else if (props.postType == 'Story') {
      return <PreviewStory images={props.image ?? ''} caption={props.caption ?? ''} />;
    } else {
      return <PreviewReal images={props.image ?? ''} caption={props.caption ?? ''} />;
    }
  };
  return (
    <div className="flex w-[1094px] flex-col items-start gap-10">
      <div className="flex flex-col items-start gap-6 self-stretch">
        <span className="text-base font-semibold leading-6">{`${props.selected}回目`}</span>
        {href.includes('project-analysis') && type === 'emerald_post' ? (
          <div className="flex h-[938px] items-start justify-center gap-10 self-stretch">
            {/* preview */}
            {selectPreview()}

            {/* stat */}
            <div className="hidden flex-1 flex-col items-start gap-4">{props.insight}</div>
          </div>
        ) : (
          <div className="flex h-[938px] items-start gap-10 self-stretch">
            {/* preview */}
            {selectPreview()}

            {/* stat */}
            <div className="flex flex-1 flex-col items-start gap-4">{props.insight}</div>
          </div>
        )}
      </div>
    </div>
  );
}
