import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
// import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';

// import { ProjectsArray } from '../interfaces/PRSTProductionManagementInterface';

// import { useAccount } from './AccountContext';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import { ReactComponent as PRTwoColorImage } from './assets/PR_Two Color 1.svg';
// import { PRSTProductionManagementListView } from './PRSTProductionManagementListView';

export const PRSTProductionManagementNoContentView = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
  };

  // const { accountInfo, setAccountInfo } = useAccount();
  const [inputProjectName, setInputProjectName] = useState<string>('');

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputProjectName(e.target.value);
    console.log(inputProjectName);
  };

  // const addProject = () => {
  //   if (accountInfo && inputProjectName) {
  //     const newProject: ProjectsArray = { projectName: inputProjectName };
  //     const updatedAccountInfo = { ...accountInfo, projects: [...(accountInfo.projects || []), newProject] };
  //     // updatedAccountInfo[0].projects = inputProjectName;
  //     // const newAccountInfo: accountInfoArray = {
  //     //   projectName: inputProjectName,
  //     // };
  //     setAccountInfo(updatedAccountInfo);

  //     console.log(accountInfo);
  //     console.log(updatedAccountInfo);
  //     // console.log(updatedAccountInfo.projectName);

  //     setInputProjectName('');

  //     setOpen(false);
  //   }
  // };

  const ProjectAddModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[228px] w-[512px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[180px] w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[110px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">施策を追加</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-[62px] w-[440px]">
                        <div>
                          <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                            施策名
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              value={inputProjectName}
                              onChange={handleProjectNameChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      {/* <Link to="/PRST_production_management/list"> */}
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        // onClick={addProject}
                      >
                        登録する
                      </Button>
                      {/* </Link> */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div className="w-[940px] px-[24px] py-[16px]">
        <div>
          <div className="mb-[16px] flex justify-between">
            <p className="text-base font-semibold leading-7 text-gray-700">施策一覧</p>
            <Button
              startIcon={<PlusImage className="h-[20px] w-[20px]" />}
              size="md"
              className="whitespace-nowrap text-sm font-medium leading-5"
              style={{
                paddingTop: '9px',
                paddingBottom: '9px',
                paddingLeft: '10px',
                paddingRight: '12px',
              }}
              onClick={handleClick}
            >
              施策を追加
            </Button>
          </div>
          <div className="h-[525px] w-[892px] px-[20px] pb-[38px] pt-[24px]">
            <div className="flex h-[463px] w-[852px] items-center justify-center">
              <div>
                <PRTwoColorImage />
                <div className="flex justify-center">
                  <p className="w-[294px] text-center text-[14px] font-normal leading-6 text-[#6B7280]">
                    まだ施策がありません。
                    <br />
                    右上のボタンから施策を作成してみましょう！
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{ProjectAddModal()}</div>
    </>
  );
};
