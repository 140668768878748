export type Value = string | number | undefined | null;
export type ListCellProps = {
  value: Value;
  className?: string;
  fractionDigits?: number;
};
export const ListCell = (props: ListCellProps) => {
  const text: string = (() => {
    if (props.value == null) return '-';

    if (typeof props.value === 'string') return props.value;

    if (props.fractionDigits) props.value.toFixed(props.fractionDigits) + '%';

    return props.value.toLocaleString();
  })();

  return <div className={`text-center ${props.className}`}>{text}</div>;
};
