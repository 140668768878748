import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { apiRequest } from '@/api/ApiUtils';
import { DefaultApi, GetAccountOutput, PatchAccountInput, AffectOutput, UploadedImageOutput } from '@/api/member';
import { InsertAccountInfoRequest, InsertAccountInfoResponse } from '@/types/AccountInfo';
import { axiosInstance } from '@/utils/axiosInstance';
const api = new DefaultApi(undefined, undefined, axiosInstance);

// アカウント取得API
export const fetchAccountInfo = async (): Promise<GetAccountOutput> => {
  try {
    const response: AxiosResponse<GetAccountOutput> = await api.getAccount();
    return response.data as GetAccountOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// アカウント情報登録API
export const insertAccountInfo = (request: InsertAccountInfoRequest): Promise<InsertAccountInfoResponse> => {
  return apiRequest<InsertAccountInfoResponse, InsertAccountInfoRequest>(
    '/accounts',
    { method: 'POST', headers: { 'Content-Type': 'application/json' } },
    request
  );
};

// アカウント更新API
export const updateAccountInfo = async (request: PatchAccountInput): Promise<AffectOutput> => {
  try {
    const response: AxiosResponse<AffectOutput> = await api.patchAccount(request);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// アカウント画像アップロードAPI
export const uploadThumbnail = async (file: File): Promise<UploadedImageOutput> => {
  try {
    const response: AxiosResponse<UploadedImageOutput> = await api.postAccountUploadImage(file);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
