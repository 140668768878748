import { useEffect, useState } from 'react';

import { PROJECT_STATUS } from '@/config';
import { getImageUrlIfRelative } from '@/utils/image';

import { AdoptionValue, ProjectApplicantItem } from '../ApplicantContent';
import { EvaluationCell } from '../common/Parts/EvaluationCell';
import { ListCell } from '../common/Parts/ListCell';

interface Tiktok_Content_Row_Props {
  Tiktok_Column_Content: ProjectApplicantItem;
  project_status: string;
  index: number;
  changeFollowStatus: (influencerId: string) => Promise<void>;
  changeAdoption: (influencerId: string, status: AdoptionValue) => Promise<void>;
  showHearingModal: (snsAccountId: string) => Promise<void>;
}

export default function Tiktok_Content_Row({
  Tiktok_Column_Content,
  project_status,
  index,
  changeFollowStatus,
  changeAdoption,
  showHearingModal,
}: Tiktok_Content_Row_Props) {
  const [image, setImage] = useState<string | undefined>('');

  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    setImage(getImageUrlIfRelative(Tiktok_Column_Content.thumbnail || '') || '');
  }, [Tiktok_Column_Content.thumbnail]);

  // ヒアリング項目を表示するかどうか（SPIRITのみ表示、それ以外は表示しないため）
  const url = window.location.search;
  const params = new URLSearchParams(url);
  const type = params.get('type');

  const handleRowClick = (influencerId: string) => {
    // window.open(`/account_instagram_detail?id=${influencerId}`, '_blank', 'noopener, noreferrer');
    window.open(`/account_tiktok_detail?id=${influencerId}`, '_blank', 'noopener, noreferrer');
  };

  return (
    <tr
      onClick={() => {
        handleRowClick(Tiktok_Column_Content.id);
      }}
      className={classNames(
        index % 2 === 0 ? 'bg-white' : 'bg-gray-50',
        'cursor-pointer text-xs font-medium uppercase leading-4 tracking-wider text-gray-500'
      )}
    >
      <td className="whitespace-nowrap">
        <div className="flex items-center justify-center gap-[8px] px-[24px]">
          <input
            type="checkbox"
            id="scales"
            className="h-[16px] w-[16ox] rounded-md"
            checked={Tiktok_Column_Content.follow}
            onClick={(e) => {
              e.stopPropagation();
              changeFollowStatus(Tiktok_Column_Content.id);
            }}
          />
        </div>
      </td>
      <td className="whitespace-nowrap">
        <div className="flex justify-center px-[24px]">{Tiktok_Column_Content.applicantIndex}</div>
      </td>
      <td className="whitespace-nowrap">
        <div className="flex justify-center px-[24px]">
          {image ? <img src={image} alt="icon" className="h-[40px] w-[40px] rounded-full" /> : '-'}
        </div>
      </td>
      <td className="whitespace-nowrap">
        <div className="flex justify-center px-[24px]">{Tiktok_Column_Content.snsAccountNo}</div>
      </td>
      <td className="whitespace-nowrap">
        <EvaluationCell
          evaluation={Tiktok_Column_Content.evaluation || 0}
          evaluationCount={Tiktok_Column_Content.evaluationCounts}
        />
      </td>
      <td className="whitespace-nowrap">
        <div className="flex px-[24px]">{Tiktok_Column_Content.enthusiasm}</div>
      </td>
      <td className="whitespace-nowrap">
        <div className="flex px-[24px]">{Tiktok_Column_Content.operatorComment}</div>
      </td>
      {type === 'SPIRIT' && (
        <td className="whitespace-nowrap">
          <div
            className="flex w-[170px] px-[24px]"
            onClick={(e) => {
              e.stopPropagation();
              showHearingModal(Tiktok_Column_Content.snsAccountId);
            }}
            role="presentation"
          >
            <div className="text-blue-500 hover:text-blue-400">ヒアリング内容</div>
          </div>
        </td>
      )}
      <td className="whitespace-nowrap">
        <div className="inline-flex items-center px-[24px]">
          <select
            id="location"
            className="block w-[92px] rounded-md border-0 py-1.5 pl-[10px] pr-2 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
            onChange={(e) => {
              e.stopPropagation();
              changeAdoption(Tiktok_Column_Content.id, e.target.value as AdoptionValue);
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
            disabled={project_status != PROJECT_STATUS.APPROVED ? true : false}
          >
            <option selected={Tiktok_Column_Content.selectionStatus == 1} value={'approved'}>
              採用
            </option>
            {Tiktok_Column_Content.isAlternate && (
              <option selected={Tiktok_Column_Content.selectionStatus == 2} value={'reserved'}>
                補欠
              </option>
            )}
            <option selected={Tiktok_Column_Content.selectionStatus == 3} value={'rejected'}>
              不採用
            </option>
          </select>
        </div>
      </td>
      <td className="whitespace-nowrap">
        {Tiktok_Column_Content.isAlternate ? (
          <div className="text-center">
            <span className="inline-flex items-center rounded-full bg-[#D1FAE5] px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-gray-500/10">
              あり
            </span>
          </div>
        ) : (
          <div className="text-center">
            <span className="inline-flex items-center rounded-full bg-[#F9FAFB] px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-500/10">
              なし
            </span>
          </div>
        )}
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.followers} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.following} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.views} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.engagementRate} fractionDigits={2} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.engagements} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.goodAvg} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.commentsAvg} className="px-[24px]" />
      </td>
      <td>
        <ListCell value={Tiktok_Column_Content.shareAvg} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.viewRate} className="px-[24px]" />
      </td>
      <td className="whitespace-nowrap">
        <ListCell value={Tiktok_Column_Content.mediaCount} className="px-[24px]" />
      </td>
    </tr>
  );
}
