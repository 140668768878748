import { DocumentTextIcon, PencilIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  ProjectImageRow,
  EmeraldPostInformation,
  EmeraldPostPlan,
  EmeraldPostSummaryOption,
  EmeraldPostTerm,
  ProjectDetailHeader,
  ProjectType,
  ProjectStatus,
} from '@/api/emeraldPost';
import { FractionType, ProjectEstimate, ProjectKpi } from '@/api/project';
import { MainLayout } from '@/components/Layout/MainLayout';
import { PhaseNavigation } from '@/components/ProjectManagements/PhaseNavigation/Components/PhaseNavigation';
import { TabView } from '@/components/ProjectManagements/PhaseNavigation/Components/TabView';
import { phaseInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/PhaseNavigationInterfaces';
import { TabInfo } from '@/components/ProjectManagements/PhaseNavigation/Interfaces/TabViewInterfaces';
import ProjectOverview from '@/components/ProjectManagements/ProjectOverview/Components/ProjectOverview';
import { projectOverview } from '@/components/ProjectManagements/ProjectOverview/Interfaces/ProjectOverViewInterfaces';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { GetEmeraldPost } from '@/hooks/EmeraldPost';
import { FetchProjectOverviewInfo } from '@/hooks/project';
import { getImageUrl } from '@/utils/image';
import { convertStatusToStepForEmeraldPost, convertStatusToDisplayLabelForEmeraldPost } from '@/utils/projectUtils';

import Image from '../assets/samples/Image.png';
import Recruitment from '../components/details/Recruitment';
import { PageHeader } from '../components/layouts/headers';
import { projectDetail, snsDeliveryDetailsModalIStates } from '../interfaces/emeraldProjectInterface';

export function EmeraldProject(): React.ReactElement {
  const search = useLocation().search;

  const searchParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const queryString = new URLSearchParams(search);
  const isStatus = (status: string): status is ProjectStatus => {
    return (
      status === ProjectStatus.PostDraft ||
      status === ProjectStatus.PostReview ||
      status === ProjectStatus.Review ||
      status === ProjectStatus.WaitPost ||
      status === ProjectStatus.Posting ||
      status === ProjectStatus.PostCompletion
    );
  };
  const projectStatus: string = queryString.get('status') || ProjectStatus.PostDraft;
  const queryTab: string = queryString.get('tab') || 'overview';
  const queryProjectId: string = queryString.get('projectId') || '';

  const [projectFlow, setProjectFlow] = useState<ProjectStatus>(
    isStatus(projectStatus) ? projectStatus : ProjectStatus.PostDraft
  );
  const [outlineInfo, setOutlineInfo] = useState<projectOverview>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    scheduleStartedAt: null,
    scheduleEndedAt: null,
    budget: 0,
    kpi: [],
    orderInfo: {
      companyName: '',
      address: '',
      capital: '',
      represent: '',
      established: '',
      business: '',
    },
  });
  const [projectHeader, setProjectHeader] = useState<ProjectDetailHeader>({
    managerId: null,
    managerName: null,
    projectGroupName: '',
    projectName: '',
    projectStatus: ProjectStatus.PostDraft,
    projectType: ProjectType.EmeraldPost,
    scheduleEndedAt: null,
    scheduleStartedAt: null,
    feedbackStatus: null,
  });
  const [isApplyed, setIsApplyed] = useState<boolean | undefined>(undefined);
  const [image, setImage] = useState<typeof Image | undefined>(undefined);
  const [tabInfo, setTabInfo] = useState<TabInfo[]>([]);
  const [plans, setPlans] = useState<EmeraldPostPlan[]>([]);
  const [selectPlanId, setSelectPlanId] = useState<string | null>(null);
  const [images, setImages] = useState<ProjectImageRow[]>([]);
  const [announcementDetails, setAnnouncementDetails] = useState<EmeraldPostInformation>({
    title: null,
    notice: null,
  });
  const [postTerm, setPostTerm] = useState<EmeraldPostTerm>({
    startedAt: null,
    endedAt: null,
  });
  const [snsDeliveryDetailsModalIStates, setSnsDeliveryDetailsModalIStates] = useState<snsDeliveryDetailsModalIStates>({
    snsDeliveryDetailsModalIsOpen: false,
    snsDeliveryDetails: {
      instagram: {
        accountTag: '',
        hashTags: [],
        text: '',
        storiesUrl: null,
      },
      x: {
        accountTag: '',
        hashTags: [],
        text: '',
        url: null,
      },
    },
  });
  const [summaryOption, setSummaryOption] = useState<EmeraldPostSummaryOption>({
    goodQuality: 0,
    rewrite: 0,
    deliverImage: 0,
  });
  const [kpi, setKpi] = useState<ProjectKpi>({
    items: [],
  });

  const [estimate, setEstimate] = useState<ProjectEstimate>({
    isCalculated: true,
    items: {
      fixedItems: [],
      optionFixedItems: [],
      optionFreeItems: [],
    },
    subtotal: 0,
    tax: 0,
    taxRate: 10,
    total: 0,
    fractionType: FractionType.Floor,
    calculateReward: {
      isCalculated: true,
      minimum: 0,
      standard: 0,
    },
    optionChoices: [],
  });

  const pahse: phaseInfo[] = [
    {
      name: convertStatusToDisplayLabelForEmeraldPost(ProjectStatus.PostDraft),
      isCurrent: ProjectStatus.PostDraft == projectStatus,
      isDone: 1 < convertStatusToStepForEmeraldPost(projectStatus),
    },
    {
      name: convertStatusToDisplayLabelForEmeraldPost(ProjectStatus.PostReview),
      isCurrent: ProjectStatus.PostReview == projectStatus || ProjectStatus.Review == projectStatus,
      isDone: 2 < convertStatusToStepForEmeraldPost(projectStatus),
    },
    {
      name: convertStatusToDisplayLabelForEmeraldPost(ProjectStatus.WaitPost),
      isCurrent: ProjectStatus.WaitPost == projectStatus,
      isDone: 3 < convertStatusToStepForEmeraldPost(projectStatus),
    },
    {
      name: convertStatusToDisplayLabelForEmeraldPost(ProjectStatus.Posting),
      isCurrent: ProjectStatus.Posting == projectStatus,
      isDone: 4 < convertStatusToStepForEmeraldPost(projectStatus),
    },
    {
      name: convertStatusToDisplayLabelForEmeraldPost(ProjectStatus.PostCompletion),
      isCurrent: ProjectStatus.PostCompletion == projectStatus,
      isDone: 5 < convertStatusToStepForEmeraldPost(projectStatus),
    },
  ];

  useEffect(() => {
    if (!(queryProjectId === '')) {
      (async () => {
        const overviewData = await FetchProjectOverviewInfo(queryProjectId);
        console.log(overviewData);
        const data = await GetEmeraldPost(queryProjectId);
        console.log(data);
        setOutlineInfo(overviewData);
        setProjectHeader(data.header);
        setIsApplyed(convertStatusToStepForEmeraldPost(projectStatus) !== 1 ? false : isApplyed);
        setPlans(data.plan.plans);
        setSelectPlanId(data.plan.selectedPlanId);
        setAnnouncementDetails(data.information);
        setSnsDeliveryDetailsModalIStates({
          snsDeliveryDetailsModalIsOpen: snsDeliveryDetailsModalIStates.snsDeliveryDetailsModalIsOpen,
          snsDeliveryDetails: data.snsDetail,
        });
        setPostTerm(data.postTerm);
        setSummaryOption(data.options);
        setImage(convertStatusToStepForEmeraldPost(projectStatus) !== 1 ? Image : image);
        setImages(
          data.postImages.map((image) => {
            return {
              objectKey: getImageUrl(image.objectKey),
              id: image.id,
            };
          })
        );
        setKpi(data.kpi);
        setEstimate(data.estimate);

        //案件一覧でステータス変わらないので暫定
        if (data.header.projectStatus !== projectFlow) {
          setProjectFlow(data.header.projectStatus);
          searchParams.set('status', data.header.projectStatus);
          navigate('?' + searchParams.toString());
        }
      })();
    }
  }, [projectFlow]);

  useEffect(() => {
    setTabInfo([
      {
        title: '案件概要',
        icon: DocumentTextIcon,
        component: (props: projectOverview) => <ProjectOverview {...props} />,
        componentProps: outlineInfo,
        query: 'overview',
        isActive: queryTab === 'overview',
      },
      {
        title: '配信詳細',
        icon: PencilIcon,
        component: (props: projectDetail) => <Recruitment {...props} />,
        componentProps: {
          status: projectFlow,
          isApplyed,
          setIsApplyed,

          plans,
          selectPlanId,
          setSelectPlanId,
          announcementDetails,
          setAnnouncementDetails,
          deliveryDetails: snsDeliveryDetailsModalIStates,
          setDeliveryDetails: setSnsDeliveryDetailsModalIStates,
          images,
          setImages,
          postTerm,
          setPostTerm,
          summaryOption,
          setSummaryOption,
          kpi,
          estimate,

          setProjectFlow,
        },
        query: 'detail',
        isActive: queryTab === 'detail',
      },
    ]);
  }, [
    projectFlow,
    outlineInfo,
    isApplyed,
    plans,
    selectPlanId,
    announcementDetails,
    snsDeliveryDetailsModalIStates,
    images,
    postTerm,
    summaryOption,
    queryTab,
    kpi,
    estimate,
  ]);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.emeraldPost,
      }}
    >
      <div className="flex h-[auto] w-[1440px] bg-gray-50">
        <div className="h-full w-[1181px] bg-gray-50">
          <div>{PageHeader(projectHeader)}</div>
          <div>{PhaseNavigation(pahse)}</div>
          <div className="pt-[10px] ">
            <div className="h-full w-[1181px] border-[1px] border-gray-200">
              {tabInfo.length > 0 && <TabView tabInfo={tabInfo} />}
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
