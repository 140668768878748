import clsx from 'clsx';
import * as React from 'react';

export interface RadioValueProps {
  value: string;
  label: string;
}

export type CheckBoxProps = React.InputHTMLAttributes<HTMLInputElement> & {
  title?: string;
  description?: string;
  helperText?: string;
  values: RadioValueProps[];
  name: string;
  onClick?: any;
  value?: string;
};

export const CheckBox = React.forwardRef<HTMLInputElement, CheckBoxProps>(({ className = '', ...props }, ref) => {
  return (
    <>
      <div className="flex flex-col items-start gap-2 p-0">
        {props.title && (
          <div>
            <span className="text-sm font-medium leading-5 text-gray-700">{props.title}</span>
          </div>
        )}
        <div className="flex flex-col items-start">
          {props.description && (
            <div className="pb-4">
              <span className="text-sm font-medium leading-5 text-gray-400">{props.description}</span>
            </div>
          )}
          <div className="flex flex-col items-center">
            <div className="flex gap-10">
              {props.values.map((item: RadioValueProps, index: number) => {
                return (
                  <div key={index} className="flex flex-row items-center gap-2 p-0">
                    <input
                      type="checkbox"
                      ref={ref}
                      value={item.value}
                      id={item.value}
                      className={clsx('h-4 w-4 border-gray-300 text-[#007CC2] focus:ring-[#007CC2]', className)}
                      name={props.name}
                      checked={props.value === item.value}
                      onClick={props.onClick}
                    />

                    <label htmlFor={item.value} className={clsx('flex items-center justify-center')}>
                      <span className="text-sm font-medium leading-5 text-gray-700">{item.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
            {props.helperText && (
              <div>
                <span className="text-sm font-medium leading-5 text-gray-400">{props.helperText}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

CheckBox.displayName = 'CheckBox';
