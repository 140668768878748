import { PreviewYoutube } from '@/components/Preview/youtube';
export interface accountInsightProps {
  id: string;
  insight: JSX.Element;
  image: string;
  selected: string;
  video: boolean;
  URL: string;
}

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/tabs/PostInsightCommon.tsx を使用してください
export default function AccountInsightYoutube(props: accountInsightProps) {
  return (
    <div className="flex flex-col items-start gap-10">
      <div className="flex flex-col items-start gap-6 self-stretch">
        <span className="text-base font-semibold leading-6">{props.selected}</span>
        <div className="flex items-start gap-10 self-stretch">
          {/* preview */}
          {PreviewYoutube(
            props.image,
            'Compiltation | Everything Belongs to Allah 33 Mins | omar...',
            props.video,
            props.URL
          )}
          {/* stat */}
          <div className="flex w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
        </div>
      </div>
    </div>
  );
}
