import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog as UIDialog, IconButton, Typography } from '@mui/material';
import * as React from 'react';

import { ButtonStyleSmall } from '@/components/Style/ButtonStyle';

export type DialogProps = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  isButton?: boolean;
  triggerButton?: React.ReactElement | undefined;
  initialize?: () => void;
  confirmButtonText?: string;
  handleConfirm?: () => void;
  width?: number;
  height?: number;
  title: string;
  description?: string | undefined;
  description2?: string | undefined;
  cancelButtonText?: string;
  children?: React.ReactNode | undefined;
};

export const Dialog = ({
  isOpen,
  open,
  close,
  isButton,
  triggerButton,
  initialize = () => {},
  confirmButtonText,
  handleConfirm = () => {},
  width = 300,
  height = 260,
  title,
  description,
  description2,
  cancelButtonText,
  children,
}: DialogProps) => {
  const buttonHeight = isButton ? 54 : 0;
  const openDialog = () => {
    initialize();
    open();
  };
  const handleConfirmClick = () => {
    handleConfirm();
    close();
  };

  return (
    <>
      {triggerButton &&
        React.cloneElement(triggerButton, {
          onClick: openDialog,
        })}
      <UIDialog open={isOpen} onClose={close} maxWidth={'xl'} scroll={'body'}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            padding: '24px',
            width: width,
            height: height,
            background: '#FFFFFF',
            boxShadow: '0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)',
            borderRadius: '8px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              padding: '0px',
              gap: '16px',
              width: width - 48,
              height: height - 48,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'flex-start',
                padding: '0px',
                width: width - 48,
                height: height - 48 - buttonHeight,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  padding: '30px 20px',
                  gap: '32px',
                  width: width - 48 - 24,
                  height: height - 48 - buttonHeight,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    padding: '0px',
                    gap: '8px',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: '20px',
                      lineHeight: '24px',
                      color: '#111827',
                    }}
                  >
                    {title}
                  </Typography>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-start',
                    }}
                  >
                    {description && (
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: '#6B7280',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {description}
                      </Typography>
                    )}
                    {description2 && (
                      <Typography
                        sx={{
                          fontWeight: 'bold',
                          fontSize: '14px',
                          lineHeight: '24px',
                          color: '#6B7280',
                          whiteSpace: 'pre-wrap',
                        }}
                      >
                        {description2}
                      </Typography>
                    )}
                  </div>
                </Box>
                {children}
              </Box>
              {close && (
                <IconButton
                  aria-label="close"
                  onClick={close}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0px',
                    width: '24px',
                    height: '24px',
                    background: '#FFFFFF',
                    borderRadius: '6px',
                  }}
                >
                  <CloseIcon sx={{ width: '24px', height: '24px', color: '#9CA3AF' }} />
                </IconButton>
              )}
            </Box>
            {isButton && (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                  padding: '0px',
                  gap: '12px',
                  width: width - 48,
                  height: '38px',
                }}
              >
                <Button variant="outlined" onClick={close} sx={{ ...ButtonStyleSmall, width: 'auto' }}>
                  {cancelButtonText}
                </Button>
                <Button variant="contained" onClick={handleConfirmClick} sx={{ ...ButtonStyleSmall, width: 'auto' }}>
                  {confirmButtonText}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
      </UIDialog>
    </>
  );
};
Dialog.defaultProps = {
  isButton: false,
  triggerButton: undefined,
  initialize: () => {},
  confirmButtonText: '確認',
  handleConfirm: () => {},
  width: 768,
  height: 246,
  description: undefined,
  cancelButtonText: 'キャンセル',
  children: undefined,
};
