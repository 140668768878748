import { useEffect, useRef, useState } from 'react';

import { GetOtherApplicantsOrderEnum, GetOtherApplicantsRowsEnum } from '@/api/other';

import { AdoptionValue, ProjectApplicantItem } from '../ApplicantContent';
import Pagenation from '../common/Parts/Pagenation';
import { arrow_css_type, arrow_up_css_type } from '../common/Types/common_types';

import Other_Content_Row from './Other_Content_Row';

const Other_Content = ({
  rows,
  status,
  changeFollowStatus,
  changeAdoption,
  showHearingModal,
  totalPageCount,
  getApplicantData,
  totalRows,
  sortData,
  setSortData,
  setOrderWord,
  applicantListCurrentPage,
  setApplicantListCurrentPage,
  setPerPage,
  sortStateArray,
  setSortStateArray,
}: {
  rows: ProjectApplicantItem[];
  status: string;
  changeFollowStatus: (influencerId: string) => Promise<void>;
  changeAdoption: (influencerId: string, status: AdoptionValue) => Promise<void>;
  showHearingModal: (snsAccountId: string) => Promise<void>;
  totalPageCount: number;
  getApplicantData: (rows?: GetOtherApplicantsRowsEnum, page?: number) => void;
  totalRows: number;
  sortData: GetOtherApplicantsOrderEnum;
  setSortData: React.Dispatch<React.SetStateAction<GetOtherApplicantsOrderEnum>>;
  setOrderWord: React.Dispatch<React.SetStateAction<string>>;
  applicantListCurrentPage: number;
  setApplicantListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  sortStateArray: string[];
  setSortStateArray: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const arrow_under_css: arrow_css_type = {
    display: 'block',
    width: '9px',
    height: '9px',
    borderTop: 'solid 2px #4B5563',
    borderRight: 'solid 2px #4B5563',
    borderColor: '#4B5563 #4B5563 transparent transparent',
    transform: 'rotate(135deg)',
  };
  const arrow_up_css: arrow_up_css_type = {
    display: 'block',
    width: '9px',
    height: '9px',
    borderTop: 'solid 2px #4B5563',
    borderRight: 'solid 2px #4B5563',
    borderColor: '#4B5563 #4B5563 transparent transparent',
    transform: 'rotate(315deg)',
    marginTop: '4px',
  };

  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const [sortedData, setSortedData] = useState<ProjectApplicantItem[]>([]);

  useEffect(() => {
    setSortedData(rows);
  }, [rows]);

  const syncScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (headerRef.current && bodyRef.current) {
      headerRef.current.scrollLeft = e.currentTarget.scrollLeft;
      bodyRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }
  };

  const handleSort = (word: string, array_index: number) => {
    setSortData((prev) => {
      return prev === 'asc' ? 'desc' : 'asc';
    });

    setOrderWord(word);

    const newSortStateArray = new Array(20).fill('ascending');
    newSortStateArray[array_index] = sortData === 'asc' ? 'descending' : 'ascending';
    setSortStateArray(newSortStateArray);
  };

  return (
    <div className="rounded-[8px] border-[1px] border-gray-200">
      <div className="overflow-auto rounded-[8px]" ref={headerRef} onScroll={syncScroll}>
        <table>
          <thead className="sticky top-0 border-b border-gray-300 bg-white">
            <tr className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">気になる</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[0] === 'ascending' ? (
                      <button onClick={() => handleSort('follow', 0)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('follow', 0)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">応募順</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[1] === 'ascending' ? (
                      <button onClick={() => handleSort('idx', 1)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('idx', 1)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">アイコン</th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">ID</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[2] === 'ascending' ? (
                      <button onClick={() => handleSort('id', 2)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('id', 2)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">評価</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[3] === 'ascending' ? (
                      <button onClick={() => handleSort('eval', 3)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('eval', 3)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">案件への意気込み</div>
                </div>
              </th>
              <th className="min-w-[126px] whitespace-nowrap px-6 py-3">弊社担当からのコメント</th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">選定</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[5] === 'ascending' ? (
                      <button onClick={() => handleSort('selection', 5)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('selection', 5)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">補欠採用</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[6] === 'ascending' ? (
                      <button onClick={() => handleSort('isReserved', 6)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('isReserved', 6)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
            </tr>
          </thead>

          <tbody>
            {sortedData.length === 0 ? (
              <tr className=" bg-white text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                <td colSpan={22}>
                  <div className="flex h-[200px] place-items-center pl-[300px]">
                    <span className="text-base font-normal leading-6 text-gray-500">
                      応募中のインフルエンサーはいません
                    </span>
                  </div>
                </td>
              </tr>
            ) : (
              sortedData.map((row: ProjectApplicantItem, index: number) => {
                return (
                  <Other_Content_Row
                    Other_Column_Content={row}
                    key={index}
                    project_status={status}
                    index={index}
                    changeFollowStatus={changeFollowStatus}
                    changeAdoption={changeAdoption}
                    showHearingModal={showHearingModal}
                    //応募者のsnsタイプ追加
                    snsType={row.snsType}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
      <Pagenation
        totalPageCount={totalPageCount}
        getApplicantData={getApplicantData}
        totalRows={totalRows}
        applicantListCurrentPage={applicantListCurrentPage}
        setApplicantListCurrentPage={setApplicantListCurrentPage}
        setPerPage={setPerPage}
      />
    </div>
  );
};

export default Other_Content;
