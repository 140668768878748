/**
 * JWTトークンを読み込み
 * @param {string} token トークン
 * @returns JSONトークン
 */
export function parseJWT(token: string): any {
  const [_, base64Url] = token.split('.');
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  console.log(_);

  return JSON.parse(jsonPayload);
}
