import { Followers } from '@/api/influencer';
import { ProjectType, SnsType } from '@/api/project';
import { Badge } from '@/components/Elements/Badge';
import { snsIcon } from '@/utils/sns';

import FeedbackStars from './feesbackStar';

export type AccountInfoProps = {
  projectType: ProjectType;
  thumbnail: string;
  evaluation: number;
  evaluationCount: number;
  snsType: SnsType;
  followers: Followers;
  isReserved: boolean;
  snsAccountNumber: string;
};

// フォロワー数合計
const CalcAllFollowerCount = (followers: Followers) => {
  return (followers.mans ?? 0) + (followers.womans ?? 0) + (followers.others ?? 0);
};

export const followersInit: Followers = {
  mans: 0,
  womans: 0,
  others: 0,
};

/**
 * アカウント情報コンポーネント
 */
export const AccountInfo = (props: AccountInfoProps) => {
  return (
    <div
      className={
        'flex content-center items-start gap-[15px] self-stretch' + props.projectType == ProjectType.JaneJohn
          ? 'h-[45px]'
          : 'h-[71px]'
      }
    >
      <div className="flex items-center gap-[15px]">
        <div className="flex aspect-square w-[65px] items-center">
          <img src={props.thumbnail} alt="" />
        </div>
        <div className="flex items-center gap-[10px] self-stretch">
          <div className="flex items-end gap-[19px]">
            <div className="flex flex-col">
              <div>ID {props.snsAccountNumber}</div>
              <div className="flex items-center">
                <div className="flex items-center gap-[3px]">{FeedbackStars(props.evaluation)}</div>
                <div>
                  <span className="text-sm font-medium text-gray-500">（{props.evaluationCount}）</span>
                </div>
              </div>
            </div>
            <div className="flex h-max items-end gap-3">
              <div className="flex items-center gap-[2px]">
                <span className="text-sm font-medium text-gray-500">SNS</span>
                {snsIcon(props.snsType, 'h-[17.9px] w-[17.9px]')}
              </div>
              <div className="flex items-center gap-[6px]">
                <div className="flex items-start gap-1">
                  <span className="text-sm font-medium text-gray-500">フォロワー</span>
                  <span className="text-sm font-medium text-gray-500">
                    {CalcAllFollowerCount(props.followers).toLocaleString()}人
                  </span>
                </div>
              </div>
              <div className="flex items-center gap-2">
                <span className="text-sm font-medium text-gray-500">補欠採用</span>
                {props.isReserved ? (
                  <Badge color="green" round="xl" className="px-[10px]">
                    あり
                  </Badge>
                ) : (
                  <Badge color="red" round="xl" className="px-[10px]">
                    なし
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
