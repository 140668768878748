import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  GetSpiritListOutput,
  SPIRITApi,
  GetSpiritListStatusEnum,
  GetSpiritListRowsEnum,
  GetSpiritListOrderEnum,
  PutSpiritNoticeInput,
  GetSpiritDetailOutput,
  PutSpiritSummaryInput,
  SpiritSummaryOption,
  SpiritTaskItem,
  SpiritSchedule,
  SpiritHearingOption,
  GetSpiritListListTypeEnum,
} from '@/api/spirit';
import { axiosInstance } from '@/utils/axiosInstance';

const api = new SPIRITApi(undefined, undefined, axiosInstance);

/**
 * SPIRIT案件一覧取得API
 */
export const getSpiritList = async (
  status?: GetSpiritListStatusEnum,
  listType?: GetSpiritListListTypeEnum,
  rows?: GetSpiritListRowsEnum,
  page?: number,
  order?: GetSpiritListOrderEnum,
  orderby?: string,
  noCache?: boolean
) => {
  try {
    const response: AxiosResponse<GetSpiritListOutput> = await api.getSpiritList(
      status,
      listType,
      rows,
      page,
      order,
      orderby,
      noCache
        ? {
            params: {
              date: new Date().toISOString(),
            },
          }
        : undefined
    );
    return response.data as GetSpiritListOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * SPIRIT案件詳細取得API
 */
export const getSpiritDetail = async (projectId: string) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.getSpiritDetail(projectId);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/** SPIRIT案件一覧取得API
 * @param projectId
 */
export const getSpiritSchedule = async (projectId: string) => {
  try {
    const response: AxiosResponse<SpiritSchedule[]> = await api.getSpiritSchedule(projectId);
    return response.data as SpiritSchedule[];
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * SPIRIT案件応募者ヒアリング取得API
 * @param projectId
 * @param snsAccountId
 * @returns
 */
export const getSpiritApplicantHearing = async (projectId: string, snsAccountId: string) => {
  try {
    const response: AxiosResponse<SpiritHearingOption[]> = await api.getSpiritApplicantHearing(projectId, snsAccountId);
    return response.data as SpiritHearingOption[];
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件概要設定API
 * @param projectId
 * @param putSpiritSummaryInput
 * @returns
 */
export const putSpiritSummary = async (projectId: string, putSpiritSummaryInput: PutSpiritSummaryInput) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.putSpiritSummary(projectId, putSpiritSummaryInput);
    return response.data as GetSpiritDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/*
 * SPIRIT案件募集タイトル作成/編集API
 */
export const putSpiritTitle = async (projectId: string, title: string, tags: string[], featuredImage?: File) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.putSpiritTitle(
      projectId,
      title,
      tags,
      featuredImage
    );
    return response.data as GetSpiritDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件注意事項/公開設定
 * @param projectId
 * @param putSpiritNoticeInput
 * @returns
 * 詳細画像設定API
 */
export const putSpiritMedia = async (projectId: string, uploads?: File[], deleteIds?: string[]) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.putSpiritMedia(projectId, uploads, deleteIds);
    return response.data as GetSpiritDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件注意事項/公開設定API
 */
export const putSpiritNotice = async (projectId: string, putSpiritNoticeInput: PutSpiritNoticeInput) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.putSpiritNotice(projectId, putSpiritNoticeInput);
    return response.data as GetSpiritDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件オプション設定
 * @param projectId
 * @param spiritSummaryOption
 * @returns
 */
export const putSpiritPostOption = async (projectId: string, spiritSummaryOption: SpiritSummaryOption) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.putSpiritOptions(projectId, spiritSummaryOption);
    return response.data as GetSpiritDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件稼働詳細設定
 * @param projectId
 * @param spiritOperationDetails
 * @returns
 */
export const putSpiritOperationDetails = async (projectId: string, spiritOperationDetails: Array<SpiritTaskItem>) => {
  try {
    const response: AxiosResponse<GetSpiritDetailOutput> = await api.putSpiritTasks(projectId, spiritOperationDetails);
    return response.data as GetSpiritDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
