export const PostingtypeInput = () => {
  return (
    <>
      <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
        <span className="text-sm font-semibold leading-5 text-gray-700">投稿タイプ</span>
      </div>
      <select
        id="location"
        name="location"
        className="mt-2 block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        defaultValue="Canada"
      >
        <option>フィード</option>
      </select>
    </>
  );
};
