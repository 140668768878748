import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SnsAccountInsightTikTok } from '@/api/influencer';
import { ProjectType } from '@/api/project';
import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PostIcon } from '@/assets/icons/bx-notepad.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as UserPlusIcon } from '@/assets/icons/bx-user-plus.svg';
import NoImage from '@/assets/images/NoImage.png';
import tiktok from '@/assets/tiktok.png';
import { Badge } from '@/components/Elements/Badge';
import { getImageUrl } from '@/utils/image';

import FeedbackStars from '../../feedback/feesbackStar';
import Ellipse from '../assets/Ellipse.png';
import TikTokLocation from '../assets/TikTokLocation.png';
import { InsightPost } from '../tiktok/summary';

import { modaTiktokProps_Janejone_Other, modalTiktokProps_SPIRIT, TabProps } from './modalInterface';
import AccountInsight from './tabs/AccountInsight';
import AccountInsightTikTok from './tabs/PostInsightTikTok';
import RecruitmentInformation from './tabs/RecruitmentInformation';
import FollowerAttribute from './tabs/twiiter/FollwerAttributes';

export default function ModalTikTok(props: modaTiktokProps_Janejone_Other | modalTiktokProps_SPIRIT) {
  const [tabKey, setTabKey] = useState(props.initialTabKey);
  const accountInsight = props.accountInformation?.insight as SnsAccountInsightTikTok;
  const followers = (() => {
    const follower = {
      mans: props.accountInformation?.followerAttributes.followers?.mans ?? 0,
      womans: props.accountInformation?.followerAttributes.followers?.womans ?? 0,
      others: props.accountInformation?.followerAttributes.followers?.others ?? 0,
    };
    return follower.mans + follower.womans + follower.others;
  })();
  const search = useLocation().search;
  const selectTab = (): TabProps[] => {
    const selectTabs = [
      { name: 'アカウントインサイト', key: 'accountInsight' },
      { name: 'フォロワー属性', key: 'follower' },
      { name: '採用前情報', key: 'information' },
    ];
    const query = new URLSearchParams(search);
    const type = query.get('type');
    if (type == ProjectType.Spirit) {
      selectTabs.unshift({ name: '投稿インサイト', key: 'postInsight' });
    }
    return selectTabs;
  };
  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };

  const handeRightTab = () => {
    const index = selectTab().findIndex((tab: TabProps) => tab.key == tabKey);
    if (index == selectTab().length - 1) {
      setTabKey(selectTab()[0].key);
    } else {
      setTabKey(selectTab()[index + 1].key);
    }
  };

  const selectContent = () => {
    if (tabKey == 'postInsight') {
      return (
        <AccountInsightTikTok
          id={props.postInformation.account.snsAccountId}
          avator={props.postInformation.account.snsAccountThumbnail ?? NoImage}
          insight={InsightPost(
            'w-[294px]',
            props.postInformation.insight?.viewCount ?? 0,
            props.postInformation.insight?.engagementRate ?? 0,
            props.postInformation.insight?.engagementCount ?? 0,
            props.postInformation.insight?.goodCount ?? 0,
            props.postInformation.insight?.commentCount ?? 0,
            props.postInformation.insight?.shareCount ?? 0
          )}
          selected={`${props.postTaskIndex + 1}回目`}
          image={
            props.postInformation.content?.postImage ? getImageUrl(props.postInformation.content?.postImage) : NoImage
          }
          icon={Ellipse}
          location={TikTokLocation}
          body={`${props.postInformation.content?.title ?? ''}${props.postInformation.content?.caption ?? ''}`}
          video={props.postInformation.content?.postImage?.includes('video') ?? false}
          URL={(props.postInformation as unknown as modalTiktokProps_SPIRIT).accountInformation?.thumbnailUrl ?? ''}
        />
      );
    } else if (tabKey == 'accountInsight') {
      return (
        <AccountInsight
          recentCount={props.accountInformation?.posts.recentPosts.length}
          insights={[
            {
              left: {
                icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'フォロワー数',
                value: accountInsight?.followers ? accountInsight.followers.toString() : '0',
                className: 'w-[396px]',
              },
              right: {
                icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'フォロー数',
                value: accountInsight?.followings ? accountInsight.followings.toString() : '0',
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
                label: '再生回数',
                value: accountInsight?.play ? accountInsight.play.toString() : '0',
                className: 'w-[396px]',
              },
              right: {
                icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'エンゲージメント率',
                value: accountInsight?.engagementRate ? `${accountInsight.engagementRate}%` : '0%',
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'エンゲージメント数',
                value: accountInsight?.engagements ? accountInsight.engagements.toString() : '0',
                className: 'w-[396px]',
              },
              right: {
                icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'いいね数（平均）',
                value: accountInsight?.good ? accountInsight.good.toString() : '0',
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'コメント数（平均）',
                value: accountInsight?.comments ? accountInsight.comments.toString() : '0',
                className: 'w-[396px]',
              },
              right: {
                icon: <ShareIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'シェア数（平均）',
                value: accountInsight?.share ? accountInsight.share.toString() : '0',
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
                label: '再生回数率',
                value: accountInsight?.playRate ? `${accountInsight.playRate}%` : '0%',
                className: 'w-[396px]',
              },
              right: {
                icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
                label: '投稿数',
                value: accountInsight?.postCounts ? accountInsight.postCounts.toString() : '0',
                className: 'w-[396px]',
              },
            },
          ]}
        />
      );
    } else if (tabKey == 'follower') {
      return <FollowerAttribute top3={props.accountInformation?.followerAttributes.top3} />;
    } else {
      return (
        <RecruitmentInformation
          enthusiasm={props.accountInformation?.applyingComment.influencerComment ?? ''}
          comments={props.accountInformation?.applyingComment.operatorComment ?? ''}
          introduction={props.accountInformation?.accountPr.characteristic ?? ''}
          strong={props.accountInformation?.accountPr.strongPoint ?? ''}
          achievements={props.accountInformation?.accountPr.actualResults ?? ''}
        />
      );
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">SNSアカウントの詳細</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-[1px]">
                  <div className="flex w-[848px] flex-col gap-6 self-stretch">
                    {/* account information */}
                    <div className="flex h-[71px] items-start gap-[15px] self-stretch">
                      <div className="flex aspect-square w-[65px] items-center">
                        <img src={props.postInformation.account.snsAccountThumbnail ?? NoImage} alt="" />
                      </div>
                      <div className="flex flex-col items-start gap-[10px] self-stretch">
                        <div className="flex items-center gap-1">
                          <span className="text-sm font-medium text-gray-500">
                            ID {props.postInformation.account.snsAccountId}
                          </span>
                        </div>
                        <div className="flex items-center gap-[19px]">
                          <div className="flex items-center">
                            <div className="flex items-center gap-[3px]">
                              {FeedbackStars(props.accountInformation?.evaluation.evaluation ?? 0)}
                            </div>
                            <div>
                              <span className="text-sm font-medium text-gray-500">
                                （{props.accountInformation?.evaluation.evaluationCounts ?? 0}）
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center gap-[2px]">
                            <span className="text-sm font-medium text-gray-500">SNS</span>
                            <img src={tiktok} alt="" className="h-[17.9px] w-[17.9px]" />
                          </div>
                          <div className="flex items-center gap-[6px]">
                            <div className="flex items-start gap-1">
                              <span className="text-sm font-medium text-gray-500">フォロワー</span>
                              <span className="text-sm font-medium text-gray-500">{followers}</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-500">補欠採用</span>
                            <Badge color="green" round="xl" className="px-[10px]">
                              {props.accountInformation?.applyingComment.reserved ? 'あり' : 'なし'}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* tabs */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="inline-flex w-[1280px] items-start gap-8  border-b-[1px] border-gray-200">
                        {selectTab().map((tab: TabProps) => {
                          return (
                            <button
                              key={tab.key}
                              className={clsx('flex flex-col items-center justify-center')}
                              onClick={() => {
                                handleTabSelct(tab.key);
                              }}
                            >
                              <div
                                className={clsx(
                                  'flex items-center justify-center px-1 pb-4 pt-0',
                                  tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                )}
                              >
                                <span className="text-sm font-medium leading-5">{tab.name}</span>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {/* contents */}
                    {selectContent()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div>
              <button className="pl-[35.8px]" onClick={() => handeRightTab()}>
                <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
