import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as ReopostIcon } from '@/assets/icons/bx-repost.svg';
import { Stat } from '@/components/Elements/Stat';

import { addCommasToNumberString } from '../modals/modalCommonParts';

// Dupricated:共通版の /workspace/src/features/project_analysis/components/posts/modals/tabs/summaryCommon.tsx を使用してください
export const SummaryPost = (
  width: string,
  engagementRate: string,
  numberOfEngagements: string,
  goods: string,
  comments: string,
  repost: string,
  sumViewNum: string
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント率"
          value={`${engagementRate}`}
          className={width}
        />
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント数"
          value={numberOfEngagements}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
          lable="いいね数"
          value={goods}
          className={width}
        />
        <Stat
          icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
          lable="コメント数"
          value={comments}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<ReopostIcon width={24} height={24} fill="#FFFFFF" />}
          lable="リポスト数"
          value={repost}
          className={width}
        />
        {/* 合計視聴回数はテキスト投稿の場合は「'-'」表示にする必要があります */}
        <Stat
          icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
          lable="合計視聴回数"
          value={sumViewNum}
          className={width}
        />
      </div>
    </div>
  );
};

export const InsightPost = (
  width: string,
  engagementRate: string,
  numberOfEngagements: string,
  goods: string,
  comments: string,
  repost: string,
  sumViewNum: string
) => {
  return (
    <div className="flex flex-col items-start gap-2 self-stretch">
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント率"
          value={engagementRate}
          className={width}
        />
        <Stat
          icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
          lable="エンゲージメント数"
          value={addCommasToNumberString(numberOfEngagements)}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<LikeIcon width={24} height={24} fill="#FFFFFF" />}
          lable="いいね数"
          value={addCommasToNumberString(goods)}
          className={width}
        />
        <Stat
          icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
          lable="コメント数"
          value={addCommasToNumberString(comments)}
          className={width}
        />
      </div>
      <div className="flex items-center gap-2 self-stretch">
        <Stat
          icon={<ReopostIcon width={24} height={24} fill="#FFFFFF" />}
          lable="リポスト数"
          value={addCommasToNumberString(repost)}
          className={width}
        />
        <Stat
          icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
          lable="合計視聴回数"
          value={addCommasToNumberString(sumViewNum)}
          className={width}
        />
      </div>
    </div>
  );
};
