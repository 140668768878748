import * as React from 'react';

function SvgTrash(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M10.5 0.167969H5.5C4.58083 0.167969 3.83333 0.915469 3.83333 1.83464V3.5013H0.5V5.16797H2.16667V15.168C2.16667 16.0871 2.91417 16.8346 3.83333 16.8346H12.1667C13.0858 16.8346 13.8333 16.0871 13.8333 15.168V5.16797H15.5V3.5013H12.1667V1.83464C12.1667 0.915469 11.4192 0.167969 10.5 0.167969ZM5.5 1.83464H10.5V3.5013H5.5V1.83464ZM12.1667 15.168H3.83333V5.16797H12.1667V15.168Z" />
    </svg>
  );
}

export default SvgTrash;
