import { Editor } from 'tinymce';

import { useDisclosure } from '@/hooks/useDisclosure';
import { useFiles } from '@/hooks/useFiles';

export const useNoticeContentInsertImage = (targetEditor: Editor | null) => {
  const { close, open, isOpen } = useDisclosure();
  const { files, setFiles } = useFiles();

  const handleOpenInsertImageDialog = () => {
    setFiles([]);
    open();
  };

  const handleInsertImage = () => {
    if (targetEditor && files.length > 0) {
      const url = URL.createObjectURL(files[0]);
      targetEditor.insertContent(`<img src="${url}" />`);
    }
  };

  return {
    isOpen,
    open,
    close,
    files,
    setFiles,
    handleOpenInsertImageDialog,
    handleInsertImage,
  };
};
