/* eslint-disable tailwindcss/no-custom-classname */
//import { useState } from 'react';

import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { createTeamInfo } from '@/hooks/TeamInfo';

import { CreateTeamInfoRequest } from '../../../types/TeamInfo';
import Steps from '../components/Steps';

interface State {
  companyName: string;
  companyPhoneNumber: string;
  companyUrl: string;
  teamName: string;
}

export function InformLeader(): React.ReactElement {
  const navigate = useNavigate();
  const location = useLocation();
  const { companyName, companyPhoneNumber, companyUrl, teamName } = location.state as State;

  const [plan, setPlan] = useState('');

  // TODO プラン選択画面から情報を持ってきて設定
  useEffect(() => {
    setPlan('light');
  }, []);

  const [userName, setUserName] = useState('');
  const [division, setDivision] = useState('');
  const [post, setPost] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mailAddress, setMailAddress] = useState('');
  const [postType] = useState([
    '経営者・役員',
    '部長',
    '課長',
    '係長',
    '主任',
    '一般社員',
    '契約・嘱託・派遣等',
    '個人',
    'その他',
  ]);
  const Add = postType.map((Add) => Add);

  const executeCreateTeamInfo = async () => {
    const createTeamInfoRequest: CreateTeamInfoRequest = {
      plan: plan,
      teamName: teamName,
      companyName: companyName,
      companyUrl: companyUrl,
      companyPhoneNumber: companyPhoneNumber,
      userName: userName,
      division: division,
      post: post,
      phoneNumber: phoneNumber,
      mailAddress: mailAddress,
    };

    console.log('createTeamInfoRequest:', createTeamInfoRequest);

    try {
      // チーム作成APIリクエスト
      const data = await createTeamInfo(createTeamInfoRequest);
      console.log('executeCreateTeamInfo:', data);
      return true;
    } catch (error) {
      Sentry.captureException(error);
      console.error('API call failed:', error);
      return false;
    }
  };

  return (
    <>
      <div className="container">
        <div className="flex h-[40px] w-[1440px] justify-center bg-black">
          <p className="h-[29px] w-[82px] pt-1.5 text-xl font-extrabold leading-7 text-white">Influfect</p>
        </div>

        <div className="flex w-[1460px] flex-row justify-center pt-[49px]">
          <div className="order-1">{Steps()}</div>
        </div>

        <div className="flex w-[1460px] justify-center pt-[32px]">
          <p className="text-3xl font-bold leading-9">最後に、チームの代表者について教えてください</p>
        </div>

        <div>
          <form className="flex w-[1440px] flex-col items-center justify-center" action="" method="post">
            {/* flex-col でしか上手くいかない */}
            <div className="pt-[48px]">
              <div>
                <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                  お名前
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="お名前"
                    value={userName}
                    onChange={(e) => {
                      setUserName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-[40px]">
              <div className="flex">
                <div>
                  <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                    部署
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="division"
                      id="division"
                      className="block h-[38px] w-[228px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="部署"
                      value={division}
                      onChange={(e) => {
                        setDivision(e.target.value);
                      }}
                    />
                  </div>
                </div>
                <div className="mt-2 -space-y-px rounded-md shadow-sm last-of-type:ml-[24px]">
                  <div>
                    <label htmlFor="country" className="block text-sm font-semibold leading-6 text-gray-900">
                      役職
                    </label>
                    <select
                      id="country"
                      name="country"
                      autoComplete="country-name"
                      className="relative block h-[38px] w-[228px] rounded-md px-[13px] py-[9px] text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      value={post}
                      onChange={(e) => {
                        // const selectIndex = parseInt(e.target.value, 10);
                        setPost(e.target.value);
                      }}
                    >
                      {Add.map((post) => (
                        <option key={post}>{post}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-[40px]">
              <div>
                <label htmlFor="phoneNumber" className="block text-sm font-semibold leading-6 text-gray-900">
                  電話番号
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="number"
                    name="phoneNumber"
                    id="phoneNumber"
                    className="block h-[38px] w-[480px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="電話番号"
                    value={phoneNumber}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-[40px]">
              <div>
                <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                  メールアドレス
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="email"
                    name="mailAddress"
                    id="mailAddress"
                    className="block h-[38px] w-[480px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="メールアドレス"
                    value={mailAddress}
                    onChange={(e) => {
                      setMailAddress(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* flex-col 入れる */}
          </form>
          <div className="flex w-[1440px] justify-center pt-[48px]">
            <div className="h-[38px] w-[62px] font-medium">
              <Link
                to="../../ask_team"
                state={{ companyName: companyName, companyPhoneNumber: companyPhoneNumber, companyUrl: companyUrl }}
              >
                <input
                  className="rounded-md border bg-sky-600 px-[17px] py-[9px] text-sm text-white"
                  style={{ background: '#FFFFFF', color: '#374151' }}
                  type="button"
                  value="戻る"
                />
              </Link>
            </div>
            <div className="h-[38px] w-[146px] font-medium last-of-type:ml-[272px]">
              <input
                className="rounded-md border bg-sky-600 px-[17px] py-[9px] text-sm text-white"
                style={{ background: '#007CC2' }}
                type="submit"
                value="チームを作成する"
                onClick={async () => {
                  const success = await executeCreateTeamInfo();
                  if (success) {
                    navigate('/home', { state: { shouldOpenModal: true } });
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
