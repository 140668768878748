import Chart from 'react-apexcharts';
export interface labelColor {
  label: string;
  color: string;
}
interface totalProps {
  label: string;
  value: string;
}
export interface donutChartProps {
  title: string;
  series: number[];
  labels: labelColor[];
  totalInformation?: totalProps;
}
export default function donutChart(props: donutChartProps) {
  const isDisplayTotalLabel = props.totalInformation ? true : false;
  const graphOptions = (info: labelColor[]) => {
    return {
      dataLabels: {
        enabled: false,
        show: false,
      },
      stroke: { width: 0 },
      labels: info.map((labelColor) => {
        return labelColor.label;
      }),
      colors: info.map((labelColor) => {
        return labelColor.color;
      }),
      chart: {
        sparkline: {
          enabled: true,
        },
      },
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: isDisplayTotalLabel,
              name: {
                show: isDisplayTotalLabel,
                fontSize: '14.163px',
                fontFamily: 'Inter',
                fontWeight: 700,
                color: '#6B7280',
                offsetY: 30,
                formatter: () => {
                  return props.totalInformation?.label || '';
                },
              },
              value: {
                show: isDisplayTotalLabel,
                fontSize: '26.555px',
                fontFamily: 'Inter',
                fontWeight: 700,
                color: '#111827',
                offsetY: -10,
              },
              total: {
                show: isDisplayTotalLabel,
                fontSize: '14.163px',
                fontFamily: 'Inter',
                fontWeight: 700,
                color: '#6B7280',
                label: props.totalInformation?.label || '',
                formatter: () => {
                  return props.totalInformation?.value || '';
                },
              },
            },
          },
        },
      },
    };
  };
  return (
    <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
      <span className="text-sm font-semibold leading-5 text-gray-900">{props.title}</span>
      <div className="flex h-[258px] w-[424px] gap-8">
        <Chart type="donut" options={graphOptions(props.labels)} series={props.series} height={224} width={224} />
        <div className="flex flex-col items-start gap-[6px]">
          {props.labels.map((labelColor, index) => {
            return (
              <div key={index} className="flex w-[217px] items-center gap-1">
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                  <circle cx="6" cy="6" r="6" fill={labelColor.color} />
                </svg>
                <span className="break-normal text-xs font-normal leading-4 text-gray-500">{labelColor.label}</span>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
