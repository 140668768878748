import { QuestionaireColor } from '@/utils/questionaire';

export type QuestionaireResult = {
  evaluation: number;
  content: string;
  questionaireColor: QuestionaireColor;
  description: string;
  key: number;
};

/**
 * アンケート結果コンポーネント
 */
export const QuestionaireContent = (props: QuestionaireResult) => {
  return (
    <div>
      <div className="flex flex-col items-start gap-2">
        <span className="text-sm font-medium leading-5 text-gray-700">{props.content}</span>
        <span className="text-sm font-medium leading-5 text-gray-500">{props.description}</span>
      </div>
      <div
        className={`mt-2 flex h-[47px]  w-[47px] justify-center gap-[10px] rounded-full ${props.questionaireColor.backGroundColor} p-[10px]`}
      >
        <span className={`text-xl font-bold leading-7 ${props.questionaireColor.fontColor}`}>{props.evaluation}</span>
      </div>
    </div>
  );
};
