import React from 'react';

import { Radio } from '@/components/Elements/Radio';

import {
  importantQuantityCombination,
  importantQualityCombination,
  importantIncreaseFollowerCombination,
  importantAdviceCombination,
} from '../../constants/choices';
import { PROJECT_IMAGE } from '../../constants/path';
import { FormProps, SelectProps } from '../../interfaces/project';

export default function Important(props: FormProps): React.ReactElement {
  const selectChoice = (): SelectProps[] => {
    const target = props.payload.image;

    if (target === PROJECT_IMAGE.IMPORTANT_QUANTITY) {
      return importantQuantityCombination;
    } else if (target === PROJECT_IMAGE.IMPORTANT_QUALITY) {
      return importantQualityCombination;
    } else if (target === PROJECT_IMAGE.PR_BY_INFLUENCER) {
      return importantIncreaseFollowerCombination;
    } else if (target === PROJECT_IMAGE.ADVICE_IMPORTANT_QUALITY) {
      return importantAdviceCombination;
    } else {
      return [];
    }
  };

  const selectDom = () => {
    return (
      <>
        {selectChoice().map((item: SelectProps) => {
          return (
            <Radio
              inputId={item.value}
              inputValue={item.value}
              label={item.label}
              isSelect={props.payload.important === item.value}
              onClick={() => {
                props.setPayload((state) => {
                  return {
                    ...state,
                    important: item.value,
                    sns: '',
                    period: undefined,
                    isPeriodError: false,
                    periodErrorMessage: '',
                    budget: undefined,
                    isBudgetError: false,
                    budgetErrorMessage: '',
                  };
                });
              }}
              className="w-[576px]"
              key={item.value}
            ></Radio>
          );
        })}
      </>
    );
  };

  return (
    <>
      <div id="project_important" className="pt-12">
        <div className="mx-auto w-[576px] pr-6">{selectDom()}</div>
      </div>
    </>
  );
}
