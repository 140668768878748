import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { Fragment, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SnsType } from '@/api/analysisSpirit';
import { GetInfluencerSnsAccountOutput, SnsAccountInsightX } from '@/api/influencer/api';
import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PostIcon } from '@/assets/icons/bx-notepad.svg';
import { ReactComponent as RepostIcon } from '@/assets/icons/bx-repost.svg';
import { ReactComponent as UserPlusIcon } from '@/assets/icons/bx-user-plus.svg';
import { ReactComponent as StarStrokeIcon } from '@/assets/icons/star-stroke.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import { Badge } from '@/components/Elements/Badge';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { snsIcon } from '@/utils/sns';

import {
  modalTwitterProps_Janejone_Other,
  modalTwitterProps_SPIRIT,
  followerAttributesIf,
  TabProps,
} from './modalInterface';
import AccountInsight from './tabs/AccountInsight';
import FollowerAttributeCommon from './tabs/FollwerAttributesCommon';
import AccountInsightCommon from './tabs/PostInsightCommon';
import RecruitmentInformation from './tabs/RecruitmentInformation';
import { InsightPostCommon } from './tabs/summaryCommon';

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/modalCommon.tsx を使用してください
export default function ModalTwitter(props: modalTwitterProps_Janejone_Other | modalTwitterProps_SPIRIT) {
  const [tabKey, setTabKey] = useState(props.initialTabKey);
  const [influencerSnsAccount, setInfluencerSnsAccount] = useState<GetInfluencerSnsAccountOutput | null>(null);
  const insight = influencerSnsAccount?.insight as SnsAccountInsightX;
  const followerAttributes = influencerSnsAccount?.followerAttributes;
  const top3 = followerAttributes?.top3;
  const applyingComment = influencerSnsAccount?.applyingComment;
  const accountPr = influencerSnsAccount?.accountPr;
  const snsType = influencerSnsAccount?.snsType ?? '';
  const evaluation = influencerSnsAccount?.evaluation;
  const reservedString = applyingComment?.reserved ? 'あり' : 'なし';
  const search = useLocation().search;
  const spiritProps = props as modalTwitterProps_SPIRIT;
  console.log('spiritProps:' + JSON.stringify(spiritProps, null, 2));
  const accountInformation = spiritProps.accountInformation;
  const followerAttributesValue: followerAttributesIf = {
    top3: {
      ageGender: top3?.ageGender ?? [],
      genre: top3?.genre ?? [],
      values: top3?.values ?? [],
    },
  };

  const selectTab = (): TabProps[] => {
    const selectTabs = [
      { name: 'アカウントインサイト', key: 'accountInsight' },
      { name: 'フォロワー属性', key: 'follower' },
      { name: '採用前情報', key: 'information' },
    ];
    const query = new URLSearchParams(search);
    const type = query.get('type') ?? '';
    if (type.toLowerCase() == 'spirit') {
      selectTabs.unshift({ name: '投稿インサイト', key: 'postInsight' });
    }
    return selectTabs;
  };
  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };

  const handeRightTab = () => {
    const index = selectTab().findIndex((tab: TabProps) => tab.key == tabKey);
    if (index == selectTab().length - 1) {
      setTabKey(selectTab()[0].key);
    } else {
      setTabKey(selectTab()[index + 1].key);
    }
  };

  // インフルエンサー応募者詳細取得API実行
  useEffect(() => {
    const fetchInfluencerApplicantDetailInfo = async () => {
      if (!accountInformation.id) {
        return;
      }
      try {
        console.log('accountInformation:' + JSON.stringify(accountInformation, null, 2));
        const data = await fetchInfluencerApplicantDetail(accountInformation.applyingInfluencerId);
        setInfluencerSnsAccount(data);
        console.log('setInfluencerSnsAccount:', data);
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    };

    fetchInfluencerApplicantDetailInfo();
  }, [accountInformation.id]);

  const selectContent = () => {
    if (!influencerSnsAccount) {
      return <div>Loading...</div>;
    }

    if (tabKey == 'postInsight') {
      return (
        <AccountInsightCommon
          id={accountInformation.id}
          insight={InsightPostCommon(
            'w-[285px]',
            SnsType.X,
            accountInformation.engagementRate,
            String(accountInformation.numberOfEngagements),
            String(accountInformation.goods),
            String(accountInformation.comment),
            String(accountInformation.repost),
            String(accountInformation.sumViewNum)
          )}
          selected={`${accountInformation.postCount}回目`}
          body={accountInformation.mediaProps.content.caption ?? ''}
          mediaProps={accountInformation.mediaProps}
          snsType={SnsType.X}
        />
      );
    } else if (tabKey == 'accoutPost') {
      return <></>;
    } else if (tabKey == 'accountInsight') {
      return (
        <AccountInsight
          insights={[
            {
              left: {
                icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'フォロワー数',
                value: numberToString(insight.followers),
                className: 'w-[396px]',
              },
              right: {
                icon: <UserPlusIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'フォロー数',
                value: numberToString(insight.followings),
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'エンゲージメント率',
                value: numberToString(insight.engagementRate),
                className: 'w-[396px]',
              },
              right: {
                icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'エンゲージメント数',
                value: numberToString(insight.engagements),
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'いいね数（平均）',
                value: numberToString(insight.goodAvg),
                className: 'w-[396px]',
              },
              right: {
                icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
                label: '返信数（平均）',
                value: numberToString(insight.replyAvg),
                className: 'w-[396px]',
              },
            },
            {
              left: {
                icon: <RepostIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'リポスト数（平均）',
                value: numberToString(insight.repostAvg),
                className: 'w-[396px]',
              },
              right: {
                icon: <PostIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'ポスト数',
                value: numberToString(insight.postCounts),
                className: 'w-[396px]',
              },
            },
          ]}
        />
      );
    } else if (tabKey == 'follower') {
      return <FollowerAttributeCommon snsType={snsType} followerAttributes={followerAttributesValue} />;
    } else {
      return (
        <RecruitmentInformation
          enthusiasm={applyingComment?.influencerComment ?? ''}
          comments={applyingComment?.operatorComment ?? ''}
          introduction={accountPr?.characteristic ?? ''}
          strong={accountPr?.strongPoint ?? ''}
          achievements={accountPr?.actualResults ?? ''}
        />
      );
    }
  };

  if (!influencerSnsAccount) {
    return <div>Loading...</div>;
  }

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">SNSアカウントの詳細</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-[1px]">
                  <div className="flex w-[1084px] flex-col gap-6 self-stretch">
                    {/* account information */}
                    <div className="flex h-[71px] items-start gap-[15px] self-stretch">
                      <img src={accountInformation.icon} alt="" className="h-16 w-16 rounded-full" />
                      <div className="flex flex-col items-start gap-[10px] self-stretch">
                        <div className="flex items-center gap-1">
                          <span className="text-sm font-medium text-gray-500">ID {accountInformation.id}</span>
                        </div>
                        <div className="flex items-center gap-[19px]">
                          <div className="flex items-center">
                            {starRating({ evaluation: evaluation?.evaluation ?? 0 })}
                            <div>
                              <span className="text-sm font-medium text-gray-500">{`(${evaluation?.evaluationCounts})`}</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-[2px]">
                            <span className="text-sm font-medium text-gray-500">SNS</span>
                            {snsIcon(snsType, 'h-[17.9px] w-[17.9px]')}
                          </div>
                          <div className="flex items-center gap-[6px]">
                            <div className="flex items-start gap-1">
                              <span className="text-sm font-medium text-gray-500">フォロワー</span>
                              <span className="text-sm font-medium text-gray-500">{insight.followers}人</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-500">補欠採用</span>
                            <Badge color="green" round="xl" className="px-[10px]">
                              {reservedString}
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* tabs */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="inline-flex w-[1280px] items-start gap-8  border-b-[1px] border-gray-200">
                        {selectTab().map((tab: TabProps) => {
                          return (
                            <button
                              key={tab.key}
                              className={clsx('flex flex-col items-center justify-center')}
                              onClick={() => {
                                handleTabSelct(tab.key);
                              }}
                            >
                              <div
                                className={clsx(
                                  'flex items-center justify-center px-1 pb-4 pt-0',
                                  tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                )}
                              >
                                <span className="text-sm font-medium leading-5">{tab.name}</span>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {/* contents */}
                    {selectContent()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div>
              <button className="pl-[35.8px]" onClick={() => handeRightTab()}>
                <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

// 数値を文字列に変換する、nullの場合は0を返す
function numberToString(value: number | null): string {
  value = value ?? 0;
  return value.toLocaleString();
}

// 星の評価を表示する
const starRating = ({ evaluation }: { evaluation: number }) => {
  const maxStars = 5;
  const fullStars = Math.min(evaluation, maxStars);
  const emptyStars = maxStars - fullStars;

  const stars = [];

  for (let i = 0; i < fullStars; i++) {
    stars.push(<StarIcon key={`full-${i}`} height={16} width={16} fill="#ECC94B" />);
  }

  for (let i = 0; i < emptyStars; i++) {
    stars.push(<StarStrokeIcon key={`empty-${i}`} height={16} width={16} fill="#ECC94B" />);
  }

  return <div className="flex items-center gap-[3px]">{stars}</div>;
};
