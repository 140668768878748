import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementContentsApprovingYouTubeShortView } from '../components/PRSTProductionManagementContentsApprovingYouTubeShortView';

export const PRSTProductionManagementContentsApprovingYouTubeShort = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementContentsApprovingYouTubeShortView />
    </MainLayout>
  );
};
