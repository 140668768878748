import AddPhotoAlternateOutlinedIcon from '@mui/icons-material/AddPhotoAlternateOutlined';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import Dropzone from 'react-dropzone';

import { Dialog } from '@/components/Elements/Dialog';

export type NoticeContentInsertImageDialogProps = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
  handleInsertImage: () => void;
};

export const NoticeContentInsertImageDialog = ({
  isOpen,
  close,
  files,
  setFiles,
  handleInsertImage,
}: NoticeContentInsertImageDialogProps) => {
  return (
    <Dialog
      isOpen={isOpen}
      open={open}
      close={close}
      isButton={true}
      confirmButtonText={'画像を挿入'}
      handleConfirm={handleInsertImage}
      width={768}
      height={623}
      title={'画像を挿入'}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          padding: '0px',
          gap: '32px',
          width: '656px',
          height: '200px',
        }}
      >
        <Dropzone onDrop={(acceptedFiles) => setFiles(acceptedFiles)}>
          {({ getRootProps, getInputProps, open }) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                width: '328px',
                height: '200px',
                border: '1px dashed #BDBDBD',
                borderRadius: '4px',
                cursor: 'pointer',
              }}
              {...getRootProps()}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '0px 0px 4px',
                  width: '100%',
                  height: '100%',
                }}
              >
                <AddPhotoAlternateOutlinedIcon sx={{ width: '36px', height: '36px', color: '#000000' }} />
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: '14px',
                    lineHeight: '20px',
                    color: '#4B5563',
                  }}
                >
                  ドラッグ＆ドロップもしくは
                </Typography>
                <Box>
                  <input {...getInputProps()} />
                  <Button
                    onClick={open}
                    variant="text"
                    sx={{
                      fontWeight: '500',
                      fontSize: '14px',
                      lineHeight: '20px',
                      color: '#007CC2',
                    }}
                  >
                    画像をアップロード
                  </Button>
                </Box>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    color: '#6B7280',
                  }}
                >
                  対応ファイルは10MB以内のPNG, JPG, GIF
                </Typography>
              </Box>
            </Box>
          )}
        </Dropzone>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '328px',
            height: '200px',
          }}
        >
          {files.length > 0 && (
            <img
              src={URL.createObjectURL(files[0])}
              alt="アップロード画像"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />
          )}
        </Box>
      </Box>
    </Dialog>
  );
};
