import axios from 'axios';
import { useEffect } from 'react';

import { ErrorType } from '@/components/Elements/ErrorMessage/ErrorMessage';
import { useErrorContext } from '@/providers/errorProvider';
import storage from '@/utils/storage';

const baseURL = process.env.REACT_APP_API_URL;
export const axiosInstance = axios.create({
  baseURL,
});

export const AxiosInterceptor = ({ children }: any) => {
  const { showErrorMessage } = useErrorContext();
  useEffect(() => {
    const resInterceptor = (response: any) => {
      showErrorMessage?.(false, undefined);
      return response;
    };

    const errInterceptor = (error: { response: { status: any; data: any } }) => {
      let errorType: ErrorType = undefined;
      if (error.response) {
        if (error.response.status === 400 || error.response.status === 401) {
          errorType = 'client';
        } else if (error.response.status === 500) {
          errorType = 'server';
        }
      }
      showErrorMessage?.(true, errorType);
      throw error;
    };

    axiosInstance.interceptors.request.use((config) => {
      const accessToken = storage.getToken();
      config.headers.Authorization = accessToken ? `Bearer ${accessToken}` : '';
      return config;
    });

    const interceptor = axiosInstance.interceptors.response.use(resInterceptor, errInterceptor);
    return () => axiosInstance.interceptors.response.eject(interceptor);
  }, [showErrorMessage]);

  return children;
};
