/* tslint:disable */
/* eslint-disable */
/**
 * Influfect クライアント API
 * Influfect クライアント APIドキュメント
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountFollowerAttributes
 */
export interface AccountFollowerAttributes {
    /**
     * 
     * @type {FollowerAttrDistributions}
     * @memberof AccountFollowerAttributes
     */
    'ageDistribution': FollowerAttrDistributions;
    /**
     * 
     * @type {FollowerAttrDistributions}
     * @memberof AccountFollowerAttributes
     */
    'genreDistribution': FollowerAttrDistributions;
    /**
     * 
     * @type {FollowerAttrDistributions}
     * @memberof AccountFollowerAttributes
     */
    'valuesDistribution': FollowerAttrDistributions;
}
/**
 * 
 * @export
 * @interface AffectOutput
 */
export interface AffectOutput {
    /**
     * 
     * @type {string}
     * @memberof AffectOutput
     */
    'affected': string;
}
/**
 * 
 * @export
 * @interface AgeDistribution
 */
export interface AgeDistribution {
    /**
     * 
     * @type {AgeDistributionType}
     * @memberof AgeDistribution
     */
    'distribution': AgeDistributionType;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'mans': number;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'womans': number;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'others': number;
}


/**
 * 年齢層割合種別
 * @export
 * @enum {string}
 */

export const AgeDistributionType = {
    _1317: '13-17',
    _1824: '18-24',
    _2534: '25-34',
    _3544: '35-44',
    _4554: '45-54',
    _5564: '55-64',
    _65: '65+'
} as const;

export type AgeDistributionType = typeof AgeDistributionType[keyof typeof AgeDistributionType];


/**
 * 
 * @export
 * @interface AnalysisFeedbackEvaluation
 */
export interface AnalysisFeedbackEvaluation {
    /**
     * 
     * @type {AnalysisFeedbackEvaluationQuestion}
     * @memberof AnalysisFeedbackEvaluation
     */
    'question': AnalysisFeedbackEvaluationQuestion;
    /**
     * 0〜10
     * @type {number}
     * @memberof AnalysisFeedbackEvaluation
     */
    'answer': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisFeedbackEvaluationQuestion
 */
export interface AnalysisFeedbackEvaluationQuestion {
    /**
     * 
     * @type {string}
     * @memberof AnalysisFeedbackEvaluationQuestion
     */
    'content': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisFeedbackEvaluationQuestion
     */
    'lowText': string;
    /**
     * 
     * @type {string}
     * @memberof AnalysisFeedbackEvaluationQuestion
     */
    'highText': string;
}
/**
 * 
 * @export
 * @interface AnalysisFollowerAttributes
 */
export interface AnalysisFollowerAttributes {
    /**
     * 
     * @type {InstagramFollowerAttributes}
     * @memberof AnalysisFollowerAttributes
     */
    'instagram'?: InstagramFollowerAttributes;
    /**
     * 
     * @type {AccountFollowerAttributes}
     * @memberof AnalysisFollowerAttributes
     */
    'account': AccountFollowerAttributes;
}
/**
 * 
 * @export
 * @interface AnalysisProjectFeedbackAnswer
 */
export interface AnalysisProjectFeedbackAnswer {
    /**
     * 1: 0〜1, 2: 2〜3, 3: 4〜5, 4: 6〜7, 5: 8〜10
     * @type {number}
     * @memberof AnalysisProjectFeedbackAnswer
     */
    'answer': number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisProjectFeedbackAnswer
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof AnalysisProjectFeedbackAnswer
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface AnalysisProjectFeedbackResult
 */
export interface AnalysisProjectFeedbackResult {
    /**
     * 
     * @type {AnalysisFeedbackEvaluationQuestion}
     * @memberof AnalysisProjectFeedbackResult
     */
    'question': AnalysisFeedbackEvaluationQuestion;
    /**
     * 
     * @type {Array<AnalysisProjectFeedbackAnswer>}
     * @memberof AnalysisProjectFeedbackResult
     */
    'answers': Array<AnalysisProjectFeedbackAnswer>;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentInstagram
 */
export interface AnalysisSpiritPostContentInstagram {
    /**
     * 画像の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postImage'?: string | null;
    /**
     * 動画の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postVideo'?: string | null;
    /**
     * カルーセルの場合のみ。複数の場合はカンマ区切り
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postCarousel'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'carouselImages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'thumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'postedAt': string | null;
    /**
     * メディアプロダクト種別
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'mediaProductType': AnalysisSpiritPostContentInstagramMediaProductTypeEnum | null;
    /**
     * メディア種別
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'mediaType': AnalysisSpiritPostContentInstagramMediaTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentInstagram
     */
    'followerOnPost': number | null;
}

export const AnalysisSpiritPostContentInstagramMediaProductTypeEnum = {
    Ad: 'AD',
    Feed: 'FEED',
    Story: 'STORY',
    Reels: 'REELS'
} as const;

export type AnalysisSpiritPostContentInstagramMediaProductTypeEnum = typeof AnalysisSpiritPostContentInstagramMediaProductTypeEnum[keyof typeof AnalysisSpiritPostContentInstagramMediaProductTypeEnum];
export const AnalysisSpiritPostContentInstagramMediaTypeEnum = {
    CarouselAlbum: 'CAROUSEL_ALBUM',
    Image: 'IMAGE',
    Video: 'VIDEO'
} as const;

export type AnalysisSpiritPostContentInstagramMediaTypeEnum = typeof AnalysisSpiritPostContentInstagramMediaTypeEnum[keyof typeof AnalysisSpiritPostContentInstagramMediaTypeEnum];

/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentTikTok
 */
export interface AnalysisSpiritPostContentTikTok {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'postImage': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'postedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentTikTok
     */
    'followerOnPost': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentX
 */
export interface AnalysisSpiritPostContentX {
    /**
     * 画像の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postImage'?: string | null;
    /**
     * 動画の場合のみ
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postVideo'?: string | null;
    /**
     * 複数メディアの場合のみ。複数の場合はカンマ区切り
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postMedias'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof AnalysisSpiritPostContentX
     */
    'multiMediaImages'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'postedAt': string | null;
    /**
     * メディア種別
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'mediaType': AnalysisSpiritPostContentXMediaTypeEnum | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentX
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentX
     */
    'followerOnPost': number | null;
}

export const AnalysisSpiritPostContentXMediaTypeEnum = {
    MultipleMedia: 'multipleMedia',
    Photo: 'photo',
    Video: 'video'
} as const;

export type AnalysisSpiritPostContentXMediaTypeEnum = typeof AnalysisSpiritPostContentXMediaTypeEnum[keyof typeof AnalysisSpiritPostContentXMediaTypeEnum];

/**
 * 
 * @export
 * @interface AnalysisSpiritPostContentYoutube
 */
export interface AnalysisSpiritPostContentYoutube {
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'postImage': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'postedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'title': string | null;
    /**
     * 
     * @type {string}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'caption': string | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostContentYoutube
     */
    'followerOnPost': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightInstagram
 */
export interface AnalysisSpiritPostInsightInstagram {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'impressionCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'uniqueAccountCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'commentCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'shareCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'saveCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'frequency': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'tapsForwardCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'tapsBackCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightInstagram
     */
    'exitsCount': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightTikTok
 */
export interface AnalysisSpiritPostInsightTikTok {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'commentCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'shareCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightTikTok
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightX
 */
export interface AnalysisSpiritPostInsightX {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'repostCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'replyCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightX
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface AnalysisSpiritPostInsightYoutube
 */
export interface AnalysisSpiritPostInsightYoutube {
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'engagementCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'engagementRate': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'goodCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'commentCount': number | null;
    /**
     * 
     * @type {number}
     * @memberof AnalysisSpiritPostInsightYoutube
     */
    'viewCount': number | null;
}
/**
 * 
 * @export
 * @interface ApiResponseError
 */
export interface ApiResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseError
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResponseError
     */
    'messages'?: Array<string>;
}
/**
 * 承認ステータス
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    Review: 'review',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {Array<EvaluationRate>}
     * @memberof Evaluation
     */
    'evaluationRates': Array<EvaluationRate>;
}
/**
 * 
 * @export
 * @interface EvaluationRate
 */
export interface EvaluationRate {
    /**
     * 
     * @type {number}
     * @memberof EvaluationRate
     */
    'rate': number;
    /**
     * 1〜5の整数値
     * @type {number}
     * @memberof EvaluationRate
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface FeedbackAdopterCommon
 */
export interface FeedbackAdopterCommon {
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'feedbackId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'adoptedInfluencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'applyingInfluencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountThumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'influencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'evaluationId': string;
    /**
     * 評価値
     * @type {number}
     * @memberof FeedbackAdopterCommon
     */
    'evaluation': FeedbackAdopterCommonEvaluationEnum;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'feedbackComment': string;
}

export const FeedbackAdopterCommonEvaluationEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FeedbackAdopterCommonEvaluationEnum = typeof FeedbackAdopterCommonEvaluationEnum[keyof typeof FeedbackAdopterCommonEvaluationEnum];

/**
 * フィードバックステータス
 * @export
 * @enum {string}
 */

export const FeedbackStatus = {
    Client: 'client',
    Admin: 'admin'
} as const;

export type FeedbackStatus = typeof FeedbackStatus[keyof typeof FeedbackStatus];


/**
 * 
 * @export
 * @interface FollowerAttrDistribution
 */
export interface FollowerAttrDistribution {
    /**
     * 
     * @type {string}
     * @memberof FollowerAttrDistribution
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistribution
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistribution
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface FollowerAttrDistributions
 */
export interface FollowerAttrDistributions {
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistributions
     */
    'total': number;
    /**
     * 
     * @type {Array<FollowerAttrDistribution>}
     * @memberof FollowerAttrDistributions
     */
    'items': Array<FollowerAttrDistribution>;
}
/**
 * 端数処理種別
 * @export
 * @enum {string}
 */

export const FractionType = {
    Floor: 'floor',
    Ceiling: 'ceiling',
    Round: 'round'
} as const;

export type FractionType = typeof FractionType[keyof typeof FractionType];


/**
 * 
 * @export
 * @interface GenderDistribution
 */
export interface GenderDistribution {
    /**
     * 1:男性、2:女性、3:その他
     * @type {number}
     * @memberof GenderDistribution
     */
    'gender': number;
    /**
     * 
     * @type {number}
     * @memberof GenderDistribution
     */
    'value': number;
    /**
     * 
     * @type {number}
     * @memberof GenderDistribution
     */
    'rate': number;
}
/**
 * 
 * @export
 * @interface GenderDistributionWithTotal
 */
export interface GenderDistributionWithTotal {
    /**
     * 
     * @type {number}
     * @memberof GenderDistributionWithTotal
     */
    'total': number;
    /**
     * 
     * @type {Array<GenderDistribution>}
     * @memberof GenderDistributionWithTotal
     */
    'items': Array<GenderDistribution>;
}
/**
 * 
 * @export
 * @interface GetAnalysisFeedback400Response
 */
export interface GetAnalysisFeedback400Response {
    /**
     * 
     * @type {string}
     * @memberof GetAnalysisFeedback400Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetAnalysisFeedback400Response
     */
    'messages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetAnalysisFeedbackOutput
 */
export interface GetAnalysisFeedbackOutput {
    /**
     * 
     * @type {string}
     * @memberof GetAnalysisFeedbackOutput
     */
    'body': string;
    /**
     * 
     * @type {Array<AnalysisFeedbackEvaluation>}
     * @memberof GetAnalysisFeedbackOutput
     */
    'evaluations': Array<AnalysisFeedbackEvaluation>;
}
/**
 * 
 * @export
 * @interface GetAnalysisProjectFeedbackOutput
 */
export interface GetAnalysisProjectFeedbackOutput {
    /**
     * 
     * @type {Array<AnalysisProjectFeedbackResult>}
     * @memberof GetAnalysisProjectFeedbackOutput
     */
    'result': Array<AnalysisProjectFeedbackResult>;
}
/**
 * 
 * @export
 * @interface InstagramFollowerAttributes
 */
export interface InstagramFollowerAttributes {
    /**
     * 
     * @type {Array<AgeDistribution>}
     * @memberof InstagramFollowerAttributes
     */
    'ageDistribution': Array<AgeDistribution>;
    /**
     * 
     * @type {Array<PrefDistribution>}
     * @memberof InstagramFollowerAttributes
     */
    'prefDistribution': Array<PrefDistribution>;
    /**
     * 
     * @type {GenderDistributionWithTotal}
     * @memberof InstagramFollowerAttributes
     */
    'genderDistribution': GenderDistributionWithTotal;
}
/**
 * 
 * @export
 * @interface PaginationOutput
 */
export interface PaginationOutput {
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PostingType = {
    Reel: 'reel',
    Feed: 'feed',
    Stories: 'stories',
    XImagePost: 'x_image_post',
    XPost: 'x_post',
    YoutubeShort: 'youtube_short',
    Youtube: 'youtube',
    TikTok: 'tik_tok',
    OtherVideo: 'other_video',
    Other: 'other'
} as const;

export type PostingType = typeof PostingType[keyof typeof PostingType];


/**
 * 
 * @export
 * @interface PrefDistribution
 */
export interface PrefDistribution {
    /**
     * 
     * @type {string}
     * @memberof PrefDistribution
     */
    'prefCode': string;
    /**
     * 
     * @type {number}
     * @memberof PrefDistribution
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface ProjectApplicantCount
 */
export interface ProjectApplicantCount {
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'applicant': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'approved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'reserved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'canBeReserver': number;
}
/**
 * 
 * @export
 * @interface ProjectApplicantItem
 */
export interface ProjectApplicantItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'influencerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'thumbnail': string | null;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluation': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'follow': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'enthusiasm': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'operatorComment': string | null;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'selectionStatus': number;
    /**
     * 最終確認 unconfirmed: 未確認 confirming: 確認中 confirmed_ok: 確認OK confirmed_ng: 確認NG
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'isFinalConfirmation'?: ProjectApplicantItemIsFinalConfirmationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isGoodQuality'?: boolean | null;
    /**
     * フォロワー数/チャンネル登録者数
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'followers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'following'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'impressions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reach'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reachRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'preservations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagements'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagementRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'frequency'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reply'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'repost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'goodAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'shareAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'views'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'viewRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'commentsAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'highEvaluationAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'mediaCount'?: number | null;
}

export const ProjectApplicantItemIsFinalConfirmationEnum = {
    Unconfirmed: 'unconfirmed',
    Confirming: 'confirming',
    ConfirmedOk: 'confirmed_ok',
    ConfirmedNg: 'confirmed_ng'
} as const;

export type ProjectApplicantItemIsFinalConfirmationEnum = typeof ProjectApplicantItemIsFinalConfirmationEnum[keyof typeof ProjectApplicantItemIsFinalConfirmationEnum];

/**
 * 
 * @export
 * @interface ProjectCalculateReward
 */
export interface ProjectCalculateReward {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectCalculateReward
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'standard': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'minimum': number;
}
/**
 * 
 * @export
 * @interface ProjectDetailHeader
 */
export interface ProjectDetailHeader {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectName': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectDetailHeader
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectDetailHeader
     */
    'projectStatus': ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleStartedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleEndedAt': string | null;
    /**
     * 
     * @type {FeedbackStatus}
     * @memberof ProjectDetailHeader
     */
    'feedbackStatus': FeedbackStatus | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerName': string | null;
}


/**
 * 
 * @export
 * @interface ProjectDetailMediaInput
 */
export interface ProjectDetailMediaInput {
    /**
     * 
     * @type {Array<File>}
     * @memberof ProjectDetailMediaInput
     */
    'uploads'?: Array<File>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDetailMediaInput
     */
    'deleteIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectEstimate
 */
export interface ProjectEstimate {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectEstimate
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {ProjectEstimateItemGroups}
     * @memberof ProjectEstimate
     */
    'items': ProjectEstimateItemGroups;
    /**
     * オプション選択肢配列
     * @type {Array<ProjectOptionChoice>}
     * @memberof ProjectEstimate
     */
    'optionChoices': Array<ProjectOptionChoice>;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'taxRate': number;
    /**
     * 
     * @type {FractionType}
     * @memberof ProjectEstimate
     */
    'fractionType': FractionType;
    /**
     * 
     * @type {ProjectCalculateReward}
     * @memberof ProjectEstimate
     */
    'calculateReward': ProjectCalculateReward;
}


/**
 * 
 * @export
 * @interface ProjectEstimateItem
 */
export interface ProjectEstimateItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectEstimateItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'counts': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ProjectEstimateItemGroups
 */
export interface ProjectEstimateItemGroups {
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'fixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFreeItems': Array<ProjectEstimateItem>;
}
/**
 * 
 * @export
 * @interface ProjectImageRow
 */
export interface ProjectImageRow {
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'objectKey': string;
}
/**
 * 
 * @export
 * @interface ProjectKpi
 */
export interface ProjectKpi {
    /**
     * KPI項目配列
     * @type {Array<ProjectKpiItem>}
     * @memberof ProjectKpi
     */
    'items': Array<ProjectKpiItem>;
}
/**
 * 
 * @export
 * @interface ProjectKpiItem
 */
export interface ProjectKpiItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'comment': string;
    /**
     * POST時isFixedは指定しなくてもtrueになります。
     * @type {boolean}
     * @memberof ProjectKpiItem
     */
    'isFixed': boolean;
}
/**
 * 
 * @export
 * @interface ProjectOptionChoice
 */
export interface ProjectOptionChoice {
    /**
     * 
     * @type {string}
     * @memberof ProjectOptionChoice
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectOptionChoice
     */
    'price': number;
}
/**
 * 最終確定日など期間ではないものはfromにnullを設定する
 * @export
 * @interface ProjectSchedule
 */
export interface ProjectSchedule {
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'to': string | null;
}
/**
 * 案件スケジュールステータス
 * @export
 * @enum {string}
 */

export const ProjectScheduleStatus = {
    Before: 'before',
    During: 'during',
    Ending: 'ending'
} as const;

export type ProjectScheduleStatus = typeof ProjectScheduleStatus[keyof typeof ProjectScheduleStatus];


/**
 * <table> <tr><td>募集内容作成（下書き）</td><td>draft</td></tr> <tr><td>募集内容作成（申請中）</td><td>review</td></tr> <tr><td>募集〜選考</td><td>approved</td></tr> <tr><td>最終確認</td><td>final_confirmation</td></tr> <tr><td>実施中</td><td>implementation</td></tr> <tr><td>完了</td><td>completion</td></tr> <tr><td>募集完了(PRST)</td><td>accept_completion</td></tr> <tr><td>配信詳細作成（下書き）</td><td>post_draft</td></tr> <tr><td>配信詳細作成（申請中）</td><td>post_review</td></tr> <tr><td>配信待ち(EMERALD POST)</td><td>wait_post</td></tr> <tr><td>配信中(EMERALD POST)</td><td>posting</td></tr> <tr><td>配信完了(EMERALD POST)</td><td>post_completion</td></tr> </table>
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Draft: 'draft',
    Review: 'review',
    Approved: 'approved',
    FinalConfirmation: 'final_confirmation',
    WaitPost: 'wait_post',
    Implementation: 'implementation',
    PostDraft: 'post_draft',
    PostReview: 'post_review',
    Posting: 'posting',
    Completion: 'completion',
    PostCompletion: 'post_completion',
    AcceptCompletion: 'accept_completion'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface ProjectSummaryApplicationCondition
 */
export interface ProjectSummaryApplicationCondition {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minFollowers': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'gender': ProjectSummaryApplicationConditionGenderEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAge': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAge': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryApplicationCondition
     */
    'prefectures': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'job': number | null;
}

export const ProjectSummaryApplicationConditionGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProjectSummaryApplicationConditionGenderEnum = typeof ProjectSummaryApplicationConditionGenderEnum[keyof typeof ProjectSummaryApplicationConditionGenderEnum];

/**
 * 
 * @export
 * @interface ProjectSummaryBase
 */
export interface ProjectSummaryBase {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'adoptionPlan': number;
    /**
     * 0:適正相場 1:カスタム設定 （Spirit以外では無視されます）
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewardsType': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewards': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryBase
     */
    'genres': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryBase
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDetails
 */
export interface ProjectSummaryDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryDetails
     */
    'notice': Array<string> | null;
    /**
     * true:非公開 false:公開
     * @type {boolean}
     * @memberof ProjectSummaryDetails
     */
    'isSecret': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDetails
     */
    'companyName': string;
}
/**
 * 
 * @export
 * @interface ProjectTitleInput
 */
export interface ProjectTitleInput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTitleInput
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTitleInput
     */
    'tags': Array<string>;
    /**
     * 
     * @type {File}
     * @memberof ProjectTitleInput
     */
    'featuredImage'?: File;
}
/**
 * 案件タイプ
 * @export
 * @enum {string}
 */

export const ProjectType = {
    Spirit: 'spirit',
    EmeraldPost: 'emerald_post',
    Prst: 'prst',
    JaneJohn: 'jane_john',
    Other: 'other'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * 
 * @export
 * @interface ProjectTypeItem
 */
export interface ProjectTypeItem {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeItem
     */
    'no': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'endDate': string | null;
}
/**
 * 
 * @export
 * @interface ProjectTypeListCounts
 */
export interface ProjectTypeListCounts {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'draft': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'review': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'approved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'finalConfirmation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'implementation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'completion': number;
}
/**
 * SNSタイプ
 * @export
 * @enum {string}
 */

export const SnsType = {
    Instagram: 'instagram',
    X: 'x',
    TikTok: 'tik_tok',
    Youtube: 'youtube',
    Other: 'other'
} as const;

export type SnsType = typeof SnsType[keyof typeof SnsType];


/**
 * 
 * @export
 * @interface UploadedImageOutput
 */
export interface UploadedImageOutput {
    /**
     * 
     * @type {string}
     * @memberof UploadedImageOutput
     */
    'uploadedFilePath'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したフィードバックを返却します。
         * @summary 案件分析 - フィードバック取得API
         * @param {string} feedbackId フィードバックID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisFeedback: async (feedbackId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'feedbackId' is not null or undefined
            assertParamExists('getAnalysisFeedback', 'feedbackId', feedbackId)
            const localVarPath = `/analysis/feedback/{feedbackId}`
                .replace(`{${"feedbackId"}}`, encodeURIComponent(String(feedbackId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した案件のフィードバックを返却します。
         * @summary 案件フィードバック取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisProjectFeedback: async (projectId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'projectId' is not null or undefined
            assertParamExists('getAnalysisProjectFeedback', 'projectId', projectId)
            const localVarPath = `/analysis/project/{projectId}/feedback`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したフィードバックを返却します。
         * @summary 案件分析 - フィードバック取得API
         * @param {string} feedbackId フィードバックID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisFeedback(feedbackId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnalysisFeedbackOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisFeedback(feedbackId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAnalysisFeedback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定した案件のフィードバックを返却します。
         * @summary 案件フィードバック取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAnalysisProjectFeedback(projectId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAnalysisProjectFeedbackOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAnalysisProjectFeedback(projectId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAnalysisProjectFeedback']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 指定したフィードバックを返却します。
         * @summary 案件分析 - フィードバック取得API
         * @param {string} feedbackId フィードバックID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisFeedback(feedbackId: string, options?: any): AxiosPromise<GetAnalysisFeedbackOutput> {
            return localVarFp.getAnalysisFeedback(feedbackId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した案件のフィードバックを返却します。
         * @summary 案件フィードバック取得API
         * @param {string} projectId 案件ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAnalysisProjectFeedback(projectId: string, options?: any): AxiosPromise<GetAnalysisProjectFeedbackOutput> {
            return localVarFp.getAnalysisProjectFeedback(projectId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 指定したフィードバックを返却します。
     * @summary 案件分析 - フィードバック取得API
     * @param {string} feedbackId フィードバックID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnalysisFeedback(feedbackId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAnalysisFeedback(feedbackId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した案件のフィードバックを返却します。
     * @summary 案件フィードバック取得API
     * @param {string} projectId 案件ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAnalysisProjectFeedback(projectId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAnalysisProjectFeedback(projectId, options).then((request) => request(this.axios, this.basePath));
    }
}



