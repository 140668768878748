import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  DefaultApi,
  GetBillingInformationOutput,
  // BillingTeamInfo,
  PutBillingInformationOutput,
  PutBillingInformationInput,
} from '@/api/billing';
import { axiosInstance } from '@/utils/axiosInstance';

const api = new DefaultApi(undefined, undefined, axiosInstance);

// 請求先情報取得API
export const fetchBillingInfo = async (): Promise<GetBillingInformationOutput> => {
  try {
    const response: AxiosResponse<GetBillingInformationOutput> = await api.getBillingInformation();
    return response.data as GetBillingInformationOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

// 請求先情報更新/登録API
export const upsertBillingInfo = async (request: PutBillingInformationInput): Promise<PutBillingInformationOutput> => {
  try {
    const response: AxiosResponse<PutBillingInformationOutput> = await api.putBillingInformation(request);
    return response.data as PutBillingInformationOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
