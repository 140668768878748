import React from 'react';
import { IconContext } from 'react-icons';
import { BiBell } from 'react-icons/bi';

export default function NotificationListNone(functionName: string) {
  return (
    <div>
      <div className="flex w-full items-center justify-center self-stretch py-12">
        <div className="flex flex-col items-center gap-2">
          <IconContext.Provider value={{ color: '#9CA3AF', size: '50px' }}>
            <BiBell />
          </IconContext.Provider>
          <div className="text-sm font-normal text-gray-900">{functionName}はありません</div>
        </div>
      </div>
    </div>
  );
}
