import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

interface Props {
  isOpen: boolean;
  titleText: string;
  contentText: string;
  completeButtonText: string;
  cancelButtonText: string;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  completeFunc: () => Promise<void>;
}

export default function ConfirmModal(props: Props) {
  const setIsClose = () => {
    props.setIsOpen(false);
  };

  return (
    <Transition.Root show={props.isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 justify-center overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[464px] gap-6 overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="mb-4 flex items-center justify-between">
                  <div className="text-lg font-medium">{props.titleText}</div>
                  <button onClick={setIsClose} className="text-gray-400 hover:text-gray-600 focus:outline-none">
                    <XMarkIcon className="h-6 w-6" />
                  </button>
                </div>
                <div className="mb-4 flex items-center justify-start">
                  <div className="text-sm text-gray-500">{props.contentText}</div>
                </div>
                <div className="flex justify-end gap-3">
                  <button
                    type="button"
                    className="rounded-md border border-gray-300 px-[17px] py-[9px] text-sm font-medium leading-5"
                    onClick={setIsClose}
                  >
                    {props.cancelButtonText}
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white"
                    style={{ background: '#007CC2' }}
                    onClick={() => props.completeFunc()}
                  >
                    {props.completeButtonText}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
