import { useState } from 'react';

import avatarImage from './assets/Avatar.svg';
import avatarImage2 from './assets/Avatar2.svg';
import avatarImage3 from './assets/Avatar3.svg';
import avatarImage4 from './assets/Avatar4.svg';
import MemberAddImage from './assets/MemberAdd.svg';
import { DotsDropdown1 } from './DotsDropdown1';
import { DotsDropdown2 } from './DotsDropdown2';
import { Dropdown } from './Dropdown';
import { InvitationResendAlert } from './InvitationResendAlert';

export function MemberListAdmin(): React.ReactElement {
  const [selected1, setSelected1] = useState('');
  const [selected2, setSelected2] = useState('');
  const [selected3, setSelected3] = useState('');
  const [selected4, setSelected4] = useState('');
  const [selected5, setSelected5] = useState('');

  return (
    <>
      <div className="relative h-[749px] w-[1181px] bg-gray-50">
        <div className="flex h-[76px] w-[1181px] border-b border-gray-200">
          <p className="ml-[16px] mt-[24px] h-[28px] w-[127px] text-base font-semibold leading-7 text-gray-700">
            チーム - メンバー
          </p>
          <button
            type="button"
            className="ml-[864px] mt-[19px] inline-flex h-[38px] w-[158px] items-center gap-x-1.5 rounded-md bg-[#007CC2] px-[17px] py-[9px] text-sm font-semibold text-white shadow-sm"
          >
            <img src={MemberAddImage} alt="" className="h-5 w-5" />
            メンバーを招待
          </button>
        </div>
        <div className="absolute left-[73px] top-[15px] z-10 flex w-[896px] border-l-4 border-green-400 bg-green-50 p-[16px]">
          <InvitationResendAlert />
        </div>
        <div className="ml-[24px] mt-[24px] h-[486px] w-[1059px] bg-white">
          <div className="flex h-[48px] w-[1059px] border-b border-gray-200">
            <p className="h-[48px] w-[353px] pl-[24px] pt-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              名前
            </p>
            <p className="h-[48px] w-[317px] pl-[24px] pt-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              権限
            </p>
            <p className="h-[48px] w-[317px] pl-[24px] pt-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              ステータス
            </p>
          </div>

          <div className="flex h-[72px] w-[1059px] border-b border-gray-200">
            <div className="flex h-[72px] w-[353px]">
              <img src={avatarImage} alt="" className="ml-[24px] mt-[16px] h-[40px] w-[40px]" />
              <div className="my-[16px] ml-[16px]">
                <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">Jane Cooper</p>
                <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                  jane.cooper@example.com
                </p>
              </div>
            </div>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">管理者</p>
          </div>
          <div className="flex h-[72px] w-[1059px] border-b border-gray-200">
            <div className="flex h-[72px] w-[353px]">
              <img src={avatarImage2} alt="" className="ml-[24px] mt-[16px] h-[40px] w-[40px]" />
              <div className="my-[16px] ml-[16px]">
                <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">-</p>
                <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                  cody.fisher@example.com
                </p>
              </div>
            </div>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">
              <Dropdown selected={selected1} setSelected={setSelected1} />
            </p>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">招待中</p>
            <DotsDropdown1 />
          </div>
          <div className="flex h-[72px] w-[1059px] border-b border-gray-200">
            <div className="flex h-[72px] w-[353px]">
              <img src={avatarImage2} alt="" className="ml-[24px] mt-[16px] h-[40px] w-[40px]" />
              <div className="my-[16px] ml-[16px]">
                <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">-</p>
                <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                  esther.howard@example.com
                </p>
              </div>
            </div>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">
              <Dropdown selected={selected2} setSelected={setSelected2} />
            </p>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">招待中</p>
            <DotsDropdown1 />
          </div>
          <div className="flex h-[72px] w-[1059px] border-b border-gray-200">
            <div className="flex h-[72px] w-[353px]">
              <img src={avatarImage2} alt="" className="ml-[24px] mt-[16px] h-[40px] w-[40px]" />
              <div className="my-[16px] ml-[16px]">
                <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">-</p>
                <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                  jenny.wilson@example.com
                </p>
              </div>
            </div>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">
              <Dropdown selected={selected3} setSelected={setSelected3} />
            </p>
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">招待中</p>
            <DotsDropdown1 />
          </div>
          <div className="flex h-[72px] w-[1059px] border-b border-gray-200">
            <div className="flex h-[72px] w-[353px]">
              <img src={avatarImage3} alt="" className="ml-[24px] mt-[16px] h-[40px] w-[40px]" />
              <div className="my-[16px] ml-[16px]">
                <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">Kristin Watson</p>
                <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                  kristin.watson@example.com
                </p>
              </div>
            </div>
            {/* タブ始まり */}
            <div className="h-[72px] w-[317px] pl-[24px] pt-[17px]">
              <Dropdown selected={selected4} setSelected={setSelected4} />
            </div>
            {/* タブここまで */}
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500"></p>
            <DotsDropdown2 />
          </div>
          <div className="flex h-[72px] w-[1059px] border-b border-gray-200">
            <div className="flex h-[72px] w-[353px]">
              <img src={avatarImage4} alt="" className="ml-[24px] mt-[16px] h-[40px] w-[40px]" />
              <div className="my-[16px] ml-[16px]">
                <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">Cameron Williamson</p>
                <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                  cameron.williamson@example.com
                </p>
              </div>
            </div>
            {/* タブ始まり */}
            <div className="h-[72px] w-[317px] pl-[24px] pt-[17px]">
              <Dropdown selected={selected5} setSelected={setSelected5} />
            </div>
            {/* タブここまで */}
            <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500"></p>
            <DotsDropdown2 />
          </div>
        </div>
      </div>
    </>
  );
}
