import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import logo from '@/assets/liddell-logo.svg';
import { Screen } from '@/components/Elements/Screen';
import { AuthAPI } from '@/lib/auth';
import storage from '@/utils/storage';

const auth: AuthAPI = new AuthAPI('auth0Container');

export const TopBar = React.forwardRef<HTMLButtonElement>(function TopBar() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const token = storage.getToken();
    if (token) {
      return setIsAuthenticated(true);
    }
  }, []);

  const handleLogoutClick = () => {
    storage.clearToken();
    auth.logout();
  };

  const handleSignup = () => {
    navigate('/auth?type=signup');
  };

  return (
    <div className="sticky left-0 top-0">
      <Screen className="flex h-20 px-6">
        <div className="flex items-center">
          <img src={logo} alt="Liddell Influencer Workspace" className="inline-block" />
          <Link to="#" className="ml-16 font-bold">
            LIWとは
          </Link>
          <Link to="#" className="ml-7 font-bold">
            機能一覧
          </Link>
          <Link to="#" className="ml-7 font-bold">
            料金
          </Link>
          <Link to="#" className="ml-7 font-bold">
            導入事例
          </Link>
          <Link to="#" className="ml-7 font-bold">
            お知らせ
          </Link>
          <Link to="#" className="ml-7 font-bold">
            セミナー
          </Link>
        </div>
        <div className="ml-auto flex flex-row items-center gap-4 p-0">
          {!isAuthenticated ? (
            <Link to="/auth?type=login" className="text-sm font-bold not-italic">
              ログイン
            </Link>
          ) : (
            <button className="py-0 text-center font-bold leading-8" onClick={handleLogoutClick}>
              ログアウト
            </button>
          )}
          <button className="h-9 w-[200px] rounded-[20px] border border-[#141730] bg-white font-bold text-[#262626]">
            お問い合わせ
          </button>
          {!isAuthenticated ? (
            <button
              className="inline-block h-9 w-[200px] rounded-[20px] border border-[#141730] bg-[#141730] py-1 text-center font-bold text-white"
              onClick={handleSignup}
            >
              新規登録
            </button>
          ) : null}
        </div>
      </Screen>
    </div>
  );
});
