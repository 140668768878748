import { useState, useEffect, useRef } from 'react';

//import { deleteMember } from '@/hooks/DeleteMember';

import { PostMemberInvite } from '@/hooks/PostTeamInvite';

import DotsHorizontalImage from './assets/DotsHorizontal.svg';
import { CheckDeleteModal } from './CheckDeleteModal';
import { HumanProps } from './Dropdown';
import { NotDeleteModal } from './NotDeleteModal';

interface Props {
  human: HumanProps;
  updatePartHumanState: (partState: HumanProps, selected: string) => void;
  deletePartHumanState: (partState: HumanProps) => void;
  option_flag: number;
  humanNum: number;
  setMessageVal: React.Dispatch<
    React.SetStateAction<{
      successFlag: boolean;
      message: string;
    }>
  >;
  snackbar: boolean;
  setSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  deleteMailAdress: string;
  setDeleteMailAdress: React.Dispatch<React.SetStateAction<string>>;
}

export function DotsDropdown1(props: Props): React.ReactElement {
  console.log('L26: ', props);
  const [isActive, setIsActive] = useState(false);
  const insideRef = useRef<HTMLButtonElement>(null);

  let options: string[] = [''];
  if (props.option_flag == 1) {
    options = ['招待メールを再送する'];
    // props.setOption('1');
  } else if (props.option_flag == 2) {
    options = ['メンバーを削除する'];
    // props.setOption('2');
  } else if (props.option_flag == 3) {
    options = ['招待メールを再送する', 'メンバーを削除する'];
    // props.setOption('3');
  }

  // console.log('L44: ', props.option);

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsActive(false);
      } else {
        return;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [insideRef]);

  const DeleteModalProps = {
    open: false,
    memberId: props.human.memberId,
    memberMailAddress: props.human.mailAddress,
    human: props.human,
    deletePartHumanState: props.deletePartHumanState,
  };

  const [DeleteModalArgs, setDeleteModalArgs] = useState(DeleteModalProps);
  const [NotDeleteModalArgs, setNotDeleteModalArgs] = useState(false);

  return (
    <div className="relative inline-block h-[72px] text-left">
      <button
        ref={insideRef}
        className="mt-[35px] h-[4px] w-[16px] gap-x-1.5 bg-white text-sm font-medium leading-5 text-gray-700 hover:bg-gray-50"
        onClick={() => setIsActive(!isActive)}
      >
        <img src={DotsHorizontalImage} alt="" className="h-full w-full" />
      </button>
      {isActive && (
        <div className="absolute right-[26px] top-0 z-10 mt-[28px] w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <div
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                style={option === 'メンバーを削除する' ? { color: '#B91C1C' } : {}}
                key={option}
                onClick={() => {
                  setIsActive(false);
                  if (option === '招待メールを再送する') {
                    PostMemberInvite(props.human.mailAddress, props.setMessageVal);
                    // human の status を「招待中」に更新する
                    props.updatePartHumanState(props.human, 'pending');
                  } else if (option === 'メンバーを削除する') {
                    // human の配列を削除する
                    if (props.human && props.humanNum != 1) {
                      setDeleteModalArgs((prev) => ({ ...prev, open: true }));
                    } else if (props.human && props.humanNum == 1) {
                      setNotDeleteModalArgs(true);
                    }
                  }
                }}
                role="presentation"
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
      <CheckDeleteModal
        deleteModalArgs={DeleteModalArgs}
        setDeleteModalArgs={setDeleteModalArgs}
        human={props.human}
        snackbar={props.snackbar}
        setSnackbar={props.setSnackbar}
        deleteMailAdress={props.deleteMailAdress}
        setDeleteMailAdress={props.setDeleteMailAdress}
      />
      <NotDeleteModal open={NotDeleteModalArgs} setOpen={setNotDeleteModalArgs} />
    </div>
  );
}
