import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';

import { Button } from '@/components/Elements/Button';
import { deleteMember } from '@/hooks/DeleteMember';

import { HumanProps } from './Dropdown';
export interface DeleteModalProps {
  open: boolean;
  memberId: string;
  memberMailAddress: string;
  human: HumanProps;
  deletePartHumanState: (partState: HumanProps) => void;
}

interface Props {
  deleteModalArgs: DeleteModalProps;
  setDeleteModalArgs: React.Dispatch<React.SetStateAction<DeleteModalProps>>;
  human: HumanProps;
  snackbar: boolean;
  setSnackbar: React.Dispatch<React.SetStateAction<boolean>>;
  deleteMailAdress: string;
  setDeleteMailAdress: React.Dispatch<React.SetStateAction<string>>;
}

export const CheckDeleteModal = (props: Props) => {
  const onCloseMemberInviteModal = (isActive: boolean) => {
    props.setDeleteModalArgs({ ...props.deleteModalArgs, open: false });
    if (isActive) {
      props.deleteModalArgs.deletePartHumanState(props.deleteModalArgs.human);
      deleteMember(props.deleteModalArgs.human.memberId);
      props.setDeleteMailAdress(props.human.mailAddress);
    }
  };

  const showSnackbar = () => {
    props.setSnackbar(true);
    setTimeout(() => {
      props.setSnackbar(false);
    }, 3000);
  };

  return (
    <Transition.Root show={props.deleteModalArgs.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => onCloseMemberInviteModal(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[550px] overflow-hidden rounded-lg bg-white pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-[550px] sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <span className="sr-only">Close</span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    viewBox="0 0 25 25"
                    fill="#9CA3AF"
                    onClick={() => {
                      onCloseMemberInviteModal(false);
                    }}
                    className="cursor-pointer"
                  >
                    <path
                      d="M6.5 18.5L18.5 6.5M6.5 6.5L18.5 18.5"
                      stroke="#9CA3AF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <span className="flex gap-4">
                      <Dialog.Title as="h2" className="h-[24px] w-[408px] text-lg font-medium leading-6 text-gray-900">
                        <div className="flex items-center gap-4">
                          <div className="inline-block rounded-full bg-red-100 p-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="25"
                              viewBox="0 0 25 25"
                              fill="none"
                            >
                              <path
                                d="M11.501 10.5H13.501V15.5H11.501V10.5ZM11.5 16.5H13.5V18.5H11.5V16.5Z"
                                fill="#DC2626"
                              />
                              <path
                                d="M14.2689 4.70262C13.9209 4.04762 13.2429 3.64062 12.5009 3.64062C11.7589 3.64062 11.0809 4.04763 10.7329 4.70363L3.39493 18.5666C3.23209 18.8711 3.15143 19.2127 3.1609 19.5578C3.17037 19.9029 3.26964 20.2396 3.44893 20.5346C3.62562 20.831 3.87657 21.0762 4.17697 21.2459C4.47738 21.4157 4.81687 21.5042 5.16193 21.5026H19.8399C20.5479 21.5026 21.1889 21.1406 21.5539 20.5346C21.7329 20.2395 21.832 19.9028 21.8415 19.5578C21.851 19.2127 21.7705 18.8711 21.6079 18.5666L14.2689 4.70262ZM5.16193 19.5026L12.5009 5.63963L19.8449 19.5026H5.16193Z"
                                fill="#DC2626"
                              />
                            </svg>
                          </div>

                          <span>メンバーを削除する</span>
                        </div>
                      </Dialog.Title>
                    </span>

                    <div className="flex flex-row justify-between whitespace-pre-line pl-10 pt-2 text-sm font-normal leading-5 text-gray-500">
                      {`「${props.deleteModalArgs.memberMailAddress}」をチームメンバーから削除しても\nよろしいですか？`}
                    </div>
                  </div>
                </div>

                <div className="gap-3 sm:flex sm:flex-row-reverse">
                  <Button
                    type="button"
                    className="mt-5 rounded-lg"
                    size="sm"
                    variant="red"
                    onClick={() => {
                      onCloseMemberInviteModal(true);
                      showSnackbar();
                    }}
                  >
                    <span className="text-sm font-medium leading-5">削除する</span>
                  </Button>

                  <Button
                    type="button"
                    className="mt-5 rounded-lg bg-white"
                    size="sm"
                    variant="white"
                    onClick={() => {
                      onCloseMemberInviteModal(false);
                    }}
                  >
                    <span className="text-sm font-medium leading-5 text-gray-700">キャンセル</span>
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
