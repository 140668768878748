import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import { Fragment, useEffect, useState } from 'react';

import { ProjectType } from '@/api/analysis';
import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { Badge } from '@/components/Elements/Badge';
import { getAnalysisFeedback } from '@/hooks/Analysis';

import { AccountInfo, AccountInfoProps } from './AccountInfo';
import { BackGroundedText } from './BackGroundedText';
import { DetailPopver } from './DetailPopver';
import { AdopterSlider } from './feedbackCommon';
import FeedbackStars from './feesbackStar';
import { createQuestionaireResultList, Questionaire, QuestionaireList } from './Questionaire';

export interface CompleteFeedbackModalProps {
  open: boolean;
  feedbackId: string;
  adopterInfluencerId: string;
  evaluation: number;
  possibleGoodQualityCount: number;
  isGoodQualityCanadiate: boolean;
  goodQualityReason?: string;
  accountInfo: AccountInfoProps;
  adopterSlider: AdopterSlider;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

type FedebackResult = {
  feedback: string;
  QuestionaireList: QuestionaireList;
};

const tooltipTextEvaluation =
  'インフルエンサーの仕事を5段階の星評価でお願いします。\n評価する際の基準は以下の通りです。\n<評価基準例>\n・投稿が目的に合致していて効果的だったか。\n・クリエイティブが、期待を超えるオリジナリティある見せ方をしていたか。\n・秀逸なハッシュタグが使用されており、表現方法が参考になったか。\n・テキストに商材への熱意が伝わり、ユーザーの共感を呼ぶ内容だったか。\nなど。';

const tooltipTextBonus =
  '企業やブランドがインフルエンサーに対して商品やサービスをPRしてもらう場合に、企業やブランドとの関係性を明らかにするために必要です。また、そのまま広告として配信できるので、実際の使用感などを合わせた効果的な広告配信が行えます。';

export const CompleteFeedbackModal = (props: CompleteFeedbackModalProps) => {
  const [feedback, setFeedback] = useState<FedebackResult>();

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!props.feedbackId) return;
        // インフルエンサーからのフィードバック取得
        const response = await getAnalysisFeedback(props.feedbackId);
        const result: FedebackResult = {
          feedback: response.body,
          QuestionaireList: createQuestionaireResultList(response.evaluations),
        };
        setFeedback(result);
      } catch (e) {
        Sentry.captureException(e);
        return undefined;
      }
    };

    fetchData();
  }, [props.feedbackId, setFeedback]);

  const onClose = () => {
    props.setOpen(false);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">フィードバック</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={onClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-[1px]">
                  <div className="flex w-[1084px] flex-col gap-6 self-stretch">
                    {/* アカウント情報 */}
                    <AccountInfo
                      projectType={props.accountInfo.projectType as ProjectType}
                      thumbnail={props.accountInfo.thumbnail}
                      evaluation={props.accountInfo.evaluation}
                      evaluationCount={props.accountInfo.evaluationCount}
                      snsType={props.accountInfo.snsType}
                      followers={props.accountInfo.followers}
                      isReserved={props.accountInfo.isReserved}
                      snsAccountNumber={props.accountInfo.snsAccountNumber}
                    />
                    {/* インフルエンサーからの評価 */}
                    <div className="flex flex-col items-start gap-6">
                      <div className="flex w-[1049px] flex-col items-start gap-4">
                        <div className="flex items-start gap-1 self-stretch">
                          <span className="text-sm font-semibold leading-5">インフルエンサーからのフィードバック</span>
                        </div>
                        <div className="flex items-start gap-4 self-stretch">
                          <BackGroundedText text={feedback?.feedback ?? ''} />
                        </div>
                      </div>
                      {/* アンケート結果 */}
                      <Questionaire questionaireList={feedback?.QuestionaireList ?? []} />
                      {/* インフルエンサーへの評価 */}
                      <DetailPopver
                        title={'インフルエンサーへの評価'}
                        description={'インフルエンサーへの評価はデフォルトで5段階評価中4で設定されています。'}
                        tooltipContent={tooltipTextEvaluation}
                        optionalElement={FeedbackStars(props.evaluation ?? 4)}
                      />
                      {props.possibleGoodQualityCount > 0 && props.accountInfo.projectType == ProjectType.Spirit && (
                        <>
                          <DetailPopver
                            title={'Good Quality ボーナスを付与する'}
                            description={`Good Quality ボーナスを${props.possibleGoodQualityCount}人に付与することができます。付与されたインフルエンサーはプラスで報酬を得られれます。`}
                            tooltipContent={tooltipTextBonus}
                            optionalElement={
                              <Badge
                                color={props.isGoodQualityCanadiate ? 'green' : 'red'}
                                round="custom"
                                className="px-[10px]"
                              >
                                {props.isGoodQualityCanadiate ? '付与' : '付与なし'}
                              </Badge>
                            }
                          />
                          <div className="flex w-full flex-col items-start gap-4">
                            <div className="flex flex-col items-start gap-2 self-stretch">
                              <div className="flex items-start gap-2">
                                <span className="text-sm font-semibold leading-5">
                                  Good Quality ボーナスを付与理由（任意）
                                </span>
                              </div>
                            </div>
                            <BackGroundedText text={props.goodQualityReason ?? ''} />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            {props.adopterSlider.canNext && (
              <div className="z-50">
                <button
                  className="pl-[35.8px]"
                  onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault();
                    e.stopPropagation();
                    props.adopterSlider.nextAdopter(props.adopterInfluencerId);
                  }}
                >
                  <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
                </button>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
