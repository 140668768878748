import { AccountFollowerAttributes } from '../../Tiktok/TiktokAll_Interface';

export interface MasterJsonData {
  label: string;
  value: string;
}

export default function ApplicantInfo(
  followerAttribute: AccountFollowerAttributes,
  ageGenderJsonData: MasterJsonData[],
  genreJsonData: MasterJsonData[],
  valueJsonData: MasterJsonData[]
) {
  const changeValueToAgeGenderLabel = (ageGenderCodes: string[]) => {
    return ageGenderCodes.map((ageGenderCode) => {
      return ageGenderJsonData.find((ageGender) => ageGender.value === ageGenderCode)?.label || ageGenderCode;
    });
  };

  const changeValueToGenreLabel = (genreCodes: string[]) => {
    return genreCodes.map((genreCode) => {
      return genreJsonData.find((genre) => genre.value === genreCode)?.label || genreCode;
    });
  };

  const changeValueToValueLabel = (valueCodes: string[]) => {
    return valueCodes.map((valueCode) => {
      return valueJsonData.find((value) => value.value === valueCode)?.label || valueCode;
    });
  };

  const top3_ageGender = changeValueToAgeGenderLabel(followerAttribute.top3?.ageGender as string[]);
  const top3_genre = changeValueToGenreLabel(followerAttribute.top3?.genre as string[]);
  const top3_values = changeValueToValueLabel(followerAttribute.top3?.values as string[]);

  return (
    <>
      <div className="flex gap-[10px]">
        <div className="h-[140px] w-[296px] flex-col">
          <div className="text-sm font-semibold leading-5 text-gray-900">年代・性別</div>
          {/* {new Array(3).fill(0).map((value, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">
                    {followerAttribute?.top3?.ageGender[index]}
                  </div>
                </div>
              </div>
            );
          })} */}
          {top3_ageGender.map((ageGender, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">{ageGender}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="h-[140px] w-[296px]">
          <div className="text-sm font-semibold leading-5 text-gray-900">ジャンル</div>
          {/* {new Array(3).fill(0).map((value, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">
                    {followerAttribute?.top3?.genre[index]}
                  </div>
                </div>
              </div>
            );
          })} */}
          {top3_genre.map((genre, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">{genre}</div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="h-[140px] w-[296px]">
          <div className="text-sm font-semibold leading-5 text-gray-900">価値観</div>
          {/* {new Array(3).fill(0).map((value, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">
                    {followerAttribute?.top3?.values[index]}
                  </div>
                </div>
              </div>
            );
          })} */}
          {top3_values.map((value, index) => {
            return (
              <div className="flex gap-[8px] pt-[8px]" key={index}>
                <div className="w-[16px] pt-[2px]">{index + 1}.</div>
                <div className="h-[32px] w-[166px]">
                  <div className="px-[13px] pt-[6px] text-sm font-medium leading-4 text-black">{value}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}
