import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as PencilImage } from '@/assets/icons/bx-pencil.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { TextArea } from '@/components/Elements/TextArea';

import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as TrashIcon } from './assets/bx-trash-alt.svg';
import { ReactComponent as InformationImage } from './assets/Information carousel_Two Color 1.svg';
import SampleMediaImage from './assets/SampleMediaImage.png';
import { StepPanel } from './StepPanel';

export const PRSTProductionManagementNoImageView = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
    setOpen1(false);
  };

  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen(false);
    setOpen1(true);
  };

  const AddImageModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[723px] w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[675px] w-[720px] bg-white">
                    <div className="mb-[24px] flex h-[613px] w-[720px]">
                      <div className="h-[613] w-[696]">
                        <div className="mb-[40px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="h-[549px] w-[696px]">
                          <div className="mb-[24px] h-[367px] w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-[261px] w-[693px]">
                              <div className="mb-[8px] flex h-[44px] w-[693px] items-center justify-start">
                                <span className="text-sm font-semibold leading-5 text-gray-700">1枚目</span>
                              </div>
                              <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                                  <div className="h-[120px] w-[220px] pb-[4px]">
                                    <div className="flex items-center justify-center">
                                      <div className="flex h-[48px] w-[48px] items-center justify-center">
                                        <AddIcon width={36} height={36} />
                                      </div>
                                    </div>
                                    <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                      <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-gray-600">
                                          ドラッグ&ドロップもしくは
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                          アップロード
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <span className="text-xs font-normal leading-4 text-gray-500">
                                        対応ファイルはPNG, JPG, GIF,mp4
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="h-[209px] w-[416px]">
                                  <div className="mb-[4px] h-[20px] w-[111px]">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">
                                      画像 / 動画の説明
                                    </span>
                                  </div>
                                  <TextArea className="h-[185px] w-[416px]" />
                                </div>
                              </div>
                              <div className="h-[70px] w-[693px] p-[16px]">
                                <Button
                                  startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                  variant="secoundary"
                                  size="smNopadding"
                                  className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                                  onClick={handleClick1}
                                >
                                  メディアイメージを追加する
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="h-[158px] w-[696px]">
                            <div className="mb-[4px] h-[20px] w-[56px]">
                              <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                            </div>
                            <TextArea className="h-[134px] w-[696px]" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick1}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const AddImageModal1 = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[1003px] w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[955px] w-[720px] bg-white">
                    <div className="mb-[16px] flex h-[900px] w-[720px]">
                      <div className="h-[900px] w-[720px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="h-[853px] w-[696px]">
                          <div className="mb-[32px] h-[605px] w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-[577px] w-[693px]">
                              <div className="mb-[16px] h-[262px] w-[693px] border-b border-gray-200 pb-[16px]">
                                <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                  <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">1枚目</span>
                                  </div>
                                  <div className="flex w-[110px] items-center justify-around ">
                                    <ChevronDownIcon width={28} height={28} fill="#374151" />
                                    <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
                                    <TrashIcon width={22} height={22} fill="#6B7280" />
                                  </div>
                                </div>
                                <div className="flex h-[210px] w-[695px] items-center justify-between">
                                  <img src={SampleMediaImage} alt="" />
                                  <div className="h-[209px] w-[416px]">
                                    <div className="mb-[4px] h-[20px] w-[111px]">
                                      <span className="text-sm font-semibold leading-5 text-gray-700">
                                        画像 / 動画の説明
                                      </span>
                                    </div>
                                    <TextArea className="h-[185px] w-[416px]" />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-[16px] h-[245px] w-[693px]">
                                <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                  <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">2枚目</span>
                                  </div>
                                  <div className="flex w-[110px] items-center justify-around ">
                                    <ChevronDownIcon width={28} height={28} fill="#374151" />
                                    <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
                                    <TrashIcon width={22} height={22} fill="#6B7280" />
                                  </div>
                                </div>
                                <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                  <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                                    <div className="h-[120px] w-[220px] pb-[4px]">
                                      <div className="flex items-center justify-center">
                                        <div className="flex h-[48px] w-[48px] items-center justify-center">
                                          <AddIcon width={36} height={36} />
                                        </div>
                                      </div>
                                      <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                        <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                          <span className="text-sm font-medium leading-5 text-gray-600">
                                            ドラッグ&ドロップもしくは
                                          </span>
                                        </div>
                                        <div className="flex items-center justify-center">
                                          <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                            アップロード
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-xs font-normal leading-4 text-gray-500">
                                          対応ファイルはPNG, JPG, GIF,mp4
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="h-[209px] w-[416px]">
                                    <div className="mb-[4px] h-[20px] w-[111px]">
                                      <span className="text-sm font-semibold leading-5 text-gray-700">
                                        画像 / 動画の説明
                                      </span>
                                    </div>
                                    <TextArea className="h-[185px] w-[416px]" />
                                  </div>
                                </div>
                              </div>
                              <Button
                                startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                variant="secoundary"
                                size="smNopadding"
                                className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                              >
                                メディアイメージを追加する
                              </Button>
                            </div>
                          </div>
                          <div className="h-[216px] w-[693px]">
                            <div className="mb-[4px] h-[20px] w-[56px]">
                              <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                            </div>
                            <TextArea className="h-[192px] w-[693px]" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen1(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen1(false)}
                      >
                        キャンセル
                      </Button>
                      <Link to="/PRST_production_management/image">
                        <Button
                          variant="primary"
                          size="md"
                          className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        >
                          保存する
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div className="h-[1676px] w-[1181px] bg-gray-50">
      <div className="h-[1458px] w-[1181px] px-[24px]">
        <StepPanel />
        <div className="w-[1133px] py-[25px]">
          <div className="flex h-[525px] w-[1133px] items-center justify-center rounded bg-[#FFFFFF] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[478px] w-[1085px]">
              <div className="mb-[24px] h-[28px] w-[92px]">
                <p className="text-lg font-semibold leading-7 text-gray-500">イメージ案</p>
              </div>
              <div className="flex h-[326px] w-[1085px] items-center justify-center py-[48px]">
                <div className="h-[230px] w-[252px] pb-[4px]">
                  <div className="mb-[24px] h-[164px] w-[252px]">
                    <InformationImage width={252} height={136} />
                    <div className="mt-[8px] flex h-[20px] w-[252px] justify-center">
                      <p className="w-[252px] text-center text-[14px] font-normal leading-5 text-[#6B7280]">
                        イメージ案がまだ制作されていません。
                      </p>
                    </div>
                  </div>
                  <Button
                    startIcon={<PencilImage fill="#FFFFFF" className="h-[20px] w-[20px]" />}
                    onClick={handleClick}
                  >
                    イメージ案を制作する
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{AddImageModal()}</div>
      <div className="relative">{AddImageModal1()}</div>
    </div>
  );
};
