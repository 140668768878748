import { PROJECT_TYPES, SNS_TYPES } from '@/config/index';
import { isInstagram, isTwitter, isTikTok } from '@/utils/sns';

import { PROJECT_GOAL, PROJECT_IMAGE, PROJECT_IMPORTANT, MEASURE_CONTENT } from '../constants/path';
import { FormData, KpiValue, budgetSpanInformation } from '../interfaces/project';

const AVERAGE_FOLLOER_INFLUENCER = {
  Instagram: 15000,
  Twitter: 10000,
  TikTok: 10000,
  YouTube: 10000,
};

/** 利用サービ判定 */
export const decideService = (payload: FormData) => {
  const goal = payload.goal;
  const image = payload.image;
  const important = payload.important;
  // 今回の施策による分岐
  if (isGoalReach(goal)) {
    // 商品・ブランドのことを広く知ってもらいたい
    if (isImageImportantQuantity(image)) {
      return PROJECT_TYPES.EMERALD_POST;
    } else {
      if (isImportantPrByManyInfluencers(important)) {
        return PROJECT_TYPES.SPIRIT;
      } else if (isImportantPrByCompatibilityInfluencers(important)) {
        return PROJECT_TYPES.SPIRIT;
      } else {
        return PROJECT_TYPES.PRST;
      }
    }
  } else if (isGoalEngagement(goal)) {
    // 商品・ブランドに対する関心を高めたい
    if (isImagePrInfluencer(image)) {
      return PROJECT_TYPES.SPIRIT;
    } else if (isImageTakeAdvantageOfOwnAccount(image)) {
      return PROJECT_TYPES.PRST;
    } else {
      return PROJECT_TYPES.JANE_JOHN;
    }
  } else if (isGoalFollower(goal)) {
    // 商品・ブランドのフォロワーを増やしたい
    return PROJECT_TYPES.PRST;
  } else {
    return PROJECT_TYPES.JANE_JOHN;
  }
};

/** 施策内容 */
export const decideMeasureContent = (payload: FormData) => {
  const image = payload.image;
  const important = payload.important;
  const service = decideService(payload);

  if (service === PROJECT_TYPES.SPIRIT) {
    if (important === PROJECT_IMPORTANT.PR_BY_MANY_INFLUENCERS) {
      return MEASURE_CONTENT.TIE_UP;
    } else {
      return MEASURE_CONTENT.AMBASSADOR;
    }
  } else if (service === PROJECT_TYPES.EMERALD_POST) {
    return MEASURE_CONTENT.HEART5;
  } else if (service === PROJECT_TYPES.PRST) {
    return MEASURE_CONTENT.ACCOUNT_OPERATION;
  } else {
    if (image === PROJECT_IMAGE.IMPORTANT_QUANTITY || image === PROJECT_IMAGE.ADVICE_IMPORTANT_QUANTITY) {
      return MEASURE_CONTENT.ENQUETE;
    } else {
      if (important === PROJECT_IMPORTANT.ADVICE_FOR_OPERATING_SNS_ACCOUNT) {
        return MEASURE_CONTENT.SPOT_CONSULTING;
      } else {
        return MEASURE_CONTENT.GROUP_INTERVIEW;
      }
    }
  }
};

/** 期間・金額の判定 */
export const isMeasure = (payload: FormData) => {
  if (isGoalReach(payload.goal)) {
    // リーチ
    if (isImageImportantQuantity(payload.image)) {
      return true;
    }

    if (isImportantPrByManyInfluencers(payload.important)) {
      return true;
    } else {
      return false;
    }
  } else if (isGoalEngagement(payload.goal)) {
    // エンゲージメント
    if (isImagePrByManyInfluencers(payload.image)) {
      if (isImportantPrByManyInfluencers(payload.important)) {
        return true;
      } else {
        return false;
      }
    } else if (isImageOperateOwnSnsAccount(payload.image)) {
      return false;
    } else {
      return true;
    }
  } else if (isGoalFollower(payload.goal)) {
    return false;
  } else {
    return true;
  }
};

/**
 * 今回の施策で達成したいことがリーチ
 * @param value 施策の選択肢
 * @returns
 */
export const isGoalReach = (value: string): boolean => {
  return value === PROJECT_GOAL.REACH;
};

/**
 * 今回の施策で達成したいことがエンゲージメント
 * @param value 施策の選択肢
 * @returns
 */
const isGoalEngagement = (value: string): boolean => {
  return value === PROJECT_GOAL.ENGAGEMENT;
};

/**
 * 今回の施策で達成したいことがフォロワー
 * @param value 施策の選択肢
 * @returns
 */
const isGoalFollower = (value: string): boolean => {
  return value === PROJECT_GOAL.FOLLOWER;
};

/**
 * 具体的な施策のイメージがとにかく多くの人に知ってもらいたい（質よりも量が重要）
 * @param value 具体的な施策のイメージの選択肢
 * @returns
 */
const isImageImportantQuantity = (value: string): boolean => {
  return value == PROJECT_IMAGE.IMPORTANT_QUANTITY;
};

/**
 * 具体的な施策のイメージがインフルエンサーにPR投稿をお願いしたい
 * @param value 具体的な施策のイメージの選択肢
 * @returns
 */
const isImagePrInfluencer = (value: string): boolean => {
  return value == PROJECT_IMAGE.PR_BY_INFLUENCER;
};

/**
 * 具体的な施策のイメージが自社のSNSアカウントを活用したい
 * @param value 具体的な施策のイメージの選択肢
 * @returns
 */
const isImageTakeAdvantageOfOwnAccount = (value: string): boolean => {
  return value == PROJECT_IMAGE.TAKE_ADVANTAGE_OF_OWN_ACCOUNT;
};

/**
 * 具体的な施策のイメージがインフルエンサーにPR投稿をお願いしたい
 * @param value 次のうち、最も重要視したいものの選択肢
 * @returns
 */
const isImagePrByManyInfluencers = (value: string): boolean => {
  return value == PROJECT_IMAGE.PR_BY_INFLUENCER;
};

/**
 * 具体的な施策のイメージが自社のSNSアカウントの運用をしてほしい
 * @param value 次のうち、最も重要視したいものの選択肢
 * @returns
 */
const isImageOperateOwnSnsAccount = (value: string): boolean => {
  return value == PROJECT_IMAGE.TAKE_ADVANTAGE_OF_OWN_ACCOUNT;
};

/**
 * 次のうち、最も重要視したいもののたくさんのインフルエンサーにお願いしたい
 * @param value 次のうち、最も重要視したいものの選択肢
 * @returns
 */
const isImportantPrByManyInfluencers = (value: string): boolean => {
  return value == PROJECT_IMPORTANT.PR_BY_MANY_INFLUENCERS;
};

/**
 * 次のうち、最も重要視したいものの関係性を持ったインフルエンサーに継続的にお願いしたいかどうか
 * @param value 次のうち、最も重要視したいものの選択肢
 * @returns
 */
const isImportantPrByCompatibilityInfluencers = (value: string): boolean => {
  return value == PROJECT_IMPORTANT.PR_BY_COMPATIBILITY_INFLUENCERS;
};

/**
 * 施策内容決定がタイアップ施策
 * @param value
 * @returns
 */
const isMeasureContentTieUp = (value: string): boolean => {
  return value == MEASURE_CONTENT.TIE_UP;
};

/**
 * 施策内容決定がアンバサダー施策
 * @param value
 * @returns
 */
const isMeasureContentAmbassador = (value: string): boolean => {
  return value == MEASURE_CONTENT.AMBASSADOR;
};

/**
 * 施策内容決定がアカウント運用
 * @param value
 * @returns
 */
const isMeasureContentAccountOperation = (value: string): boolean => {
  return value == MEASURE_CONTENT.ACCOUNT_OPERATION;
};

/**
 * 施策内容決定がスポットコンサル
 * @param value
 * @returns
 */
const isMeasureContentSpotConsulting = (value: string): boolean => {
  return value == MEASURE_CONTENT.SPOT_CONSULTING;
};

/**
 * 施策内容決定がアンケート
 * @param value
 * @returns
 */
const isMeasureContentEnquete = (value: string): boolean => {
  return value == MEASURE_CONTENT.ENQUETE;
};

/**
 * このような案件内容に表示するKPI
 * @param payload 入力値
 * @returns KPIの出力文字列
 */
export const displayKpi = (payload: FormData) => {
  const kpi: KpiValue | undefined = getKpiValues(payload);
  if (!kpi) {
    // kpiが未設定の場合、-を返す
    return '-';
  }
  if (kpi.type === 'フォロワー数') {
    return `${kpi.type} +${kpi.minValue.toLocaleString()}〜+${kpi.maxValue.toLocaleString()}`;
  } else if (kpi.type === 'エンゲージメント率') {
    return `${kpi.type} ${kpi.minValue}%〜${kpi.maxValue}%`;
  } else {
    return `${kpi.type} ${kpi.minValue.toLocaleString()}〜${kpi.maxValue.toLocaleString()}`;
  }
};

/**
 * KPIの最小値・最大値・タイプを取得
 * @param payload 入力値
 * @returns
 */
export const getKpiValues = (payload: FormData): KpiValue | undefined => {
  const service = decideService(payload);
  const period = payload.period ?? 0;
  if (service === PROJECT_TYPES.EMERALD_POST) {
    return {
      minValue: 400000 * period,
      maxValue: 800000 * period,
      type: 'リーチ数',
    };
  }

  if (isGoalReach(payload.goal)) {
    return getKpiValuesByReach(payload);
  } else if (isGoalEngagement(payload.goal)) {
    return getKpiValuesByEngagement(payload);
  } else if (isGoalFollower(payload.goal)) {
    return getKpiValuesByFollower(payload);
  }
  return undefined;
};

/**
 * 重要KPIがリーチの時のKPIの最小値・最大値・タイプを取得
 * @param payload 入力値
 * @returns
 */
const getKpiValuesByReach = (payload: FormData): KpiValue | undefined => {
  const content = decideMeasureContent(payload);
  let minValue: number | undefined = undefined;
  let maxValue: number | undefined = undefined;
  const period = payload.period ?? 0;
  const recruitmentInfluencers = getRecruitmentInfluencers(payload) ?? 0;
  if (isMeasureContentTieUp(content)) {
    if (isInstagram(payload.sns)) {
      minValue = 0.2 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
      maxValue = 0.3 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      minValue = 0.1 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
      maxValue = 0.2 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      minValue = 0.3 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
      maxValue = 0.4 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      minValue = 0.4 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
      maxValue = 0.5 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else if (isMeasureContentAmbassador(content)) {
    if (isInstagram(payload.sns)) {
      minValue = 0.2 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
      maxValue = 0.3 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      console.log(recruitmentInfluencers, AVERAGE_FOLLOER_INFLUENCER.Twitter, payload.budget);
      minValue = 0.1 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
      maxValue = 0.2 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      minValue = 0.3 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
      maxValue = 0.4 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      minValue = 0.4 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
      maxValue = 0.5 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else {
    minValue = 10000 * period;
    maxValue = 30000 * period;
  }

  return {
    minValue: minValue,
    maxValue: maxValue,
    type: 'リーチ数',
  };
};

/**
 * 重要KPIがエンジェージメントの時のKPIの最小値・最大値・タイプを取得
 * @param payload 入力値
 * @returns
 */
export const getKpiValuesByEngagement = (payload: FormData): KpiValue | undefined => {
  const content = decideMeasureContent(payload);
  let minValue: number | undefined = undefined;
  let maxValue: number | undefined = undefined;
  let kpiType: string | undefined = undefined;
  const period = payload.period ?? 0;
  const recruitmentInfluencers = getRecruitmentInfluencers(payload) ?? 0;
  if (isMeasureContentTieUp(content)) {
    kpiType = 'エンゲージメント数';
    if (isInstagram(payload.sns)) {
      minValue = 0.005 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
      maxValue = 0.08 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      minValue = 0.005 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
      maxValue = 0.03 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      minValue = 0.03 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
      maxValue = 0.05 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      minValue = 0.005 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
      maxValue = 0.05 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else if (isMeasureContentAmbassador(content)) {
    kpiType = 'エンゲージメント数';
    if (isInstagram(payload.sns)) {
      minValue = 0.01 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
      maxValue = 0.03 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      minValue = 0.005 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
      maxValue = 0.01 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      minValue = 0.03 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
      maxValue = 0.05 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      minValue = 0.02 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
      maxValue = 0.04 * recruitmentInfluencers * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else if (isMeasureContentAccountOperation(content)) {
    kpiType = 'エンゲージメント率';
    if (isInstagram(payload.sns)) {
      minValue = 1.0;
      maxValue = 3.0;
    } else if (isTwitter(payload.sns)) {
      minValue = 0.5;
      maxValue = 1.0;
    } else if (isTikTok(payload.sns)) {
      minValue = 2.0;
      maxValue = 4.0;
    } else {
      minValue = 1.0;
      maxValue = 3.0;
    }
  } else {
    return undefined;
  }
  return {
    minValue: minValue,
    maxValue: maxValue,
    type: kpiType as KpiValue['type'],
  };
};

/**
 * 重要KPIがエンジェージメントの時のKPIの最小値・最大値・タイプを取得
 * @param payload 入力値
 * @returns
 */
const getKpiValuesByFollower = (payload: FormData): KpiValue | undefined => {
  let minValue: number | undefined = undefined;
  let maxValue: number | undefined = undefined;
  const budget = payload.budget ?? 0;
  if (isInstagram(payload.sns)) {
    minValue = Math.floor(budget / 3000);
    maxValue = Math.floor(budget / 1000);
  } else if (isTwitter(payload.sns)) {
    minValue = Math.floor(budget / 2500);
    maxValue = Math.floor(budget / 750);
  } else if (isTikTok(payload.sns)) {
    minValue = Math.floor(budget / 5000);
    maxValue = Math.floor(budget / 2000);
  } else {
    minValue = Math.floor(budget / 5000);
    maxValue = Math.floor(budget / 2000);
  }

  return {
    minValue: minValue,
    maxValue: maxValue,
    type: 'フォロワー数',
  };
};

/**
 * このような案件内容はかかがですがに表示する金額総額
 * @param payload 入力内容
 * @returns
 */
export const displayTotalCost = (payload: FormData): string => {
  let cost = 0;
  const service = decideService(payload);
  const budget = payload.budget ?? 0;
  const period = payload.period ?? 0;
  if (service === PROJECT_TYPES.EMERALD_POST) {
    const budgetPerPeriod = budget / period;
    cost =
      budgetPerPeriod >= 300000
        ? 300000
        : budgetPerPeriod >= 250000
        ? 250000
        : budgetPerPeriod >= 200000
        ? 200000
        : budgetPerPeriod >= 150000
        ? 150000
        : 100000;
  }
  if (isGoalReach(payload.goal)) {
    cost = getTotalCostByReach(payload);
  } else if (isGoalEngagement(payload.goal)) {
    cost = getTotalCostByEngagement(payload);
  } else if (isGoalFollower(payload.goal)) {
    cost = getTotalCostByFollower(payload);
  } else {
    cost = getTotalCost(payload);
  }
  return `¥${cost.toLocaleString()}`;
};

/**
 * このような案件内容はかかがですがに表示する金額総額 重要KPIがリーチの時
 * @param payload 入力内容
 * @returns
 */
const getTotalCostByReach = (payload: FormData): number => {
  const content = decideMeasureContent(payload);
  const period = payload.period ?? 0;
  const recruitmentInfluencers = getRecruitmentInfluencers(payload) ?? 0;
  if (isMeasureContentTieUp(content)) {
    if (isInstagram(payload.sns)) {
      return recruitmentInfluencers * 2 * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      return recruitmentInfluencers * 2 * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      return recruitmentInfluencers * 4 * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      return recruitmentInfluencers * 5 * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else if (isMeasureContentAmbassador(content)) {
    if (isInstagram(payload.sns)) {
      return recruitmentInfluencers * 1.5 * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      return recruitmentInfluencers * 1.5 * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      return recruitmentInfluencers * 3.5 * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      return recruitmentInfluencers * 4.5 * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else {
    return payload.budget ?? 0;
  }
};

/**
 * このような案件内容はかかがですがに表示する金額総額 重要KPIがエンゲージメントの時
 * @param payload 入力内容
 * @returns
 */
const getTotalCostByEngagement = (payload: FormData): number => {
  const content = decideMeasureContent(payload);
  const period = payload.period ?? 0;
  const recruitmentInfluencers = getRecruitmentInfluencers(payload) ?? 0;
  if (isMeasureContentTieUp(content)) {
    if (isInstagram(payload.sns)) {
      return recruitmentInfluencers * 2 * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      return recruitmentInfluencers * 2 * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      return recruitmentInfluencers * 4 * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      return recruitmentInfluencers * 5 * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else if (isMeasureContentAmbassador(content)) {
    if (isInstagram(payload.sns)) {
      return recruitmentInfluencers * 1.5 * AVERAGE_FOLLOER_INFLUENCER.Instagram * period;
    } else if (isTwitter(payload.sns)) {
      return recruitmentInfluencers * 1.5 * AVERAGE_FOLLOER_INFLUENCER.Twitter * period;
    } else if (isTikTok(payload.sns)) {
      return recruitmentInfluencers * 3.5 * AVERAGE_FOLLOER_INFLUENCER.TikTok * period;
    } else {
      return recruitmentInfluencers * 4.5 * AVERAGE_FOLLOER_INFLUENCER.YouTube * period;
    }
  } else {
    return payload.budget ?? 0;
  }
};

/**
 * このような案件内容はかかがですがに表示する金額総額 重要KPIがフォロワーの時
 * @returns
 */
const getTotalCostByFollower = (payload: FormData): number => {
  return payload.budget ?? 0;
};

/**
 * このような案件内容はかかがですがに表示する金額総額 重要KPIがーの時
 * @returns
 */
const getTotalCost = (payload: FormData): number => {
  const content = decideMeasureContent(payload);
  const period = payload.period ?? 0;
  return isMeasureContentSpotConsulting(content) ? 20000 * period : payload.budget ?? 0;
};

/**
 * このような案件内容はかかがですがに表示する採用インフルエンサー数
 * @param payload
 */
export const displayRecruitmentInfluencers = (payload: FormData): string => {
  const recruitmentInfluencers: number | undefined = getRecruitmentInfluencers(payload);

  return recruitmentInfluencers ? `${recruitmentInfluencers}名` : '-';
};

/**
 * 採用インフルエンサー数
 * @param payload
 */
export const getRecruitmentInfluencers = (payload: FormData): number | undefined => {
  const content = decideMeasureContent(payload);
  let recruitmentInfluencers: number | undefined = undefined;
  const budget = payload.budget ?? 0;
  if (isMeasureContentTieUp(content)) {
    if (isInstagram(payload.sns)) {
      recruitmentInfluencers = Math.floor(budget / (2 * AVERAGE_FOLLOER_INFLUENCER.Instagram));
    } else if (isTwitter(payload.sns)) {
      recruitmentInfluencers = Math.floor(budget / (2 * AVERAGE_FOLLOER_INFLUENCER.Twitter));
    } else if (isTikTok(payload.sns)) {
      recruitmentInfluencers = Math.floor(budget / (4 * AVERAGE_FOLLOER_INFLUENCER.TikTok));
    } else {
      recruitmentInfluencers = Math.floor(budget / (5 * AVERAGE_FOLLOER_INFLUENCER.YouTube));
    }
  } else if (isMeasureContentAmbassador(content)) {
    if (isInstagram(payload.sns)) {
      recruitmentInfluencers = Math.floor(budget / (1.5 * AVERAGE_FOLLOER_INFLUENCER.Instagram));
    } else if (isTwitter(payload.sns)) {
      recruitmentInfluencers = Math.floor(budget / (1.5 * AVERAGE_FOLLOER_INFLUENCER.Twitter));
    } else if (isTikTok(payload.sns)) {
      recruitmentInfluencers = Math.floor(budget / (3.5 * AVERAGE_FOLLOER_INFLUENCER.TikTok));
    } else {
      recruitmentInfluencers = Math.floor(budget / (4.5 * AVERAGE_FOLLOER_INFLUENCER.YouTube));
    }
  } else if (isMeasureContentEnquete(content)) {
    const period = payload.period ?? 0;
    recruitmentInfluencers = Math.round(Math.round(budget / period) / 6600);
  }
  return recruitmentInfluencers;
};

/**
 * 予算の幅を取得する
 * @param payload 入力内容
 * @returns
 */
export const getBudgetSpan = (payload: FormData): budgetSpanInformation => {
  // sns表記をウィザード画面の「Instagram, TikTok, YouTube, X」のままだとロジックが変わってしまうので
  //ロジックで使用用の表記に戻す
  switch (payload.sns) {
    case 'Instagram':
      payload.sns = 'instagram';
      break;
    case 'TikTok':
      payload.sns = 'tik_tok';
      break;
    case 'YouTube':
      payload.sns = 'youtube';
      break;
    case 'X':
      payload.sns = 'x';
      break;
    default:
      payload.sns;
  }

  // 利用するサービス
  const service = decideService(payload);
  // 施策内容
  const content = decideMeasureContent(payload);
  // EMERALD POSTの値をデフォルト値に設定

  const sns = payload.sns;
  const period = payload.period ?? 0;
  let minCost = period * 100000;
  let averageCost = period * 200000;
  let recommendationCost = period * 300000;
  if (service == PROJECT_TYPES.SPIRIT) {
    if (content == MEASURE_CONTENT.TIE_UP) {
      if (sns == SNS_TYPES.INSTAGRAM) {
        minCost = period * 200000;
        averageCost = period * 1500000;
        recommendationCost = period * 3000000;
      } else if (sns == SNS_TYPES.TWITTER) {
        minCost = period * 200000;
        averageCost = period * 1500000;
        recommendationCost = period * 3000000;
      } else if (sns == SNS_TYPES.TIK_TOK) {
        minCost = period * 400000;
        averageCost = period * 3000000;
        recommendationCost = period * 5000000;
      } else {
        minCost = period * 300000;
        averageCost = period * 5000000;
        recommendationCost = period * 10000000;
      }
    } else if (content == MEASURE_CONTENT.AMBASSADOR) {
      if (sns == SNS_TYPES.INSTAGRAM) {
        minCost = period * 200000;
        averageCost = period * 1500000;
        recommendationCost = period * 3000000;
      } else if (sns == SNS_TYPES.TWITTER) {
        minCost = period * 200000;
        averageCost = period * 1500000;
        recommendationCost = period * 3000000;
      } else if (sns == SNS_TYPES.TIK_TOK) {
        minCost = period * 400000;
        averageCost = period * 1500000;
        recommendationCost = period * 3000000;
      } else {
        minCost = period * 300000;
        averageCost = period * 3000000;
        recommendationCost = period * 5000000;
      }
    }
  } else if (service == PROJECT_TYPES.PRST) {
    if (sns == SNS_TYPES.INSTAGRAM) {
      minCost = period * 300000;
      averageCost = period * 900000;
      recommendationCost = period * 1500000;
    } else if (sns == SNS_TYPES.TWITTER) {
      minCost = period * 300000;
      averageCost = period * 900000;
      recommendationCost = period * 1500000;
    } else if (sns == SNS_TYPES.TIK_TOK) {
      minCost = period * 600000;
      averageCost = period * 1500000;
      recommendationCost = period * 2000000;
    } else {
      minCost = period * 800000;
      averageCost = period * 3000000;
      recommendationCost = period * 5000000;
    }
  } else if (service == PROJECT_TYPES.JANE_JOHN) {
    if (content == MEASURE_CONTENT.GROUP_INTERVIEW) {
      minCost = period * 250000;
      averageCost = period * 600000;
      recommendationCost = period * 3000000;
    } else if (content == MEASURE_CONTENT.SPOT_CONSULTING) {
      minCost = period * 20000;
      averageCost = period * 20000;
      recommendationCost = period * 20000;
    } else {
      minCost = period * 350000;
      averageCost = period * 700000;
      recommendationCost = period * 1500000;
    }
  } else {
    minCost = period * 100000;
    averageCost = period * 200000;
    recommendationCost = period * 300000;
  }

  return {
    minCost: minCost,
    averageCost: averageCost,
    recommendationCost: recommendationCost,
  };
};
