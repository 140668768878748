import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { PRSTProductionManagementContentsApprovingYouTubeView } from '../components/PRSTProductionManagementContentsApprovingYouTubeView';

export const PRSTProductionManagementContentsApprovingYouTube = () => {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.prst,
      }}
    >
      <PRSTProductionManagementContentsApprovingYouTubeView />
    </MainLayout>
  );
};
