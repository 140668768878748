// import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ArrowDropDownIcon } from '@/assets/icons/ArrowDropDown.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as ChevronsLeftIcon } from '@/assets/icons/bx-chevrons-left.svg';
import { ReactComponent as ChevronsRightIcon } from '@/assets/icons/bx-chevrons-right.svg';
import { ReactComponent as SortUpIcon } from '@/assets/icons/bx-sort-up.svg';
import { Button } from '@/components/Elements';

// import { AccountContext } from './AccountContext';
// import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as GritIcon } from './assets/bx-grid.svg';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import Media0002Image from './assets/MediaImage0002.png';
import Media0003Image from './assets/MediaImage0003.png';
import Media0004Image from './assets/MediaImage0004.png';
import { ReactComponent as Media0005Image } from './assets/MediaImage0005.svg';
import DotsDropdown from './DotsDropdown';

const numbers = [1, 2, 3, 4, 5, 6, 7];

export const PRSTProductionManagementProductListView = () => {
  // const accountInfo = useContext(AccountContext);

  // const projectName = accountInfo.accountInfo[0].projectName;

  return (
    <div className="h-[749px] w-[1181px] bg-gray-50">
      <div className="mb-[24px] flex w-[1181px] items-center border-b border-gray-200 py-[18px] pl-[16px]">
        {/* <ChevronLeft width={24} height={24} fill="#9CA3AF" /> */}
        <p className="ml-[16px] whitespace-nowrap text-base font-bold leading-6 text-gray-700">aaa</p>
      </div>
      <div className="w-[1181px] px-[24px]">
        <div className="mb-[16px] flex h-[38px] w-[1133px] justify-between">
          <p className="text-base font-semibold leading-7 text-gray-700">投稿制作一覧</p>
          <div className="flex justify-end">
            <Button
              startIcon={<GritIcon className="h-[20px] w-[20px]" />}
              size="md"
              variant="white"
              className="whitespace-nowrap text-sm font-medium leading-5"
              style={{
                marginRight: '16px',
                paddingTop: '9px',
                paddingBottom: '9px',
                paddingLeft: '10px',
                paddingRight: '12px',
              }}
            >
              並び順プレビュー
            </Button>
            <Link to="/PRST_production_management/noimage">
              <Button
                startIcon={<PlusImage className="h-[20px] w-[20px]" />}
                size="md"
                className="whitespace-nowrap text-sm font-medium leading-5"
                style={{
                  paddingTop: '9px',
                  paddingBottom: '9px',
                  paddingLeft: '10px',
                  paddingRight: '12px',
                }}
              >
                投稿制作を新規追加
              </Button>
            </Link>
          </div>
        </div>
        <div className="h-[475px] w-[1133px] rounded-lg border border-gray-200 bg-[#FFFFFF] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
          <div className="h-[413px] w-[1133px]">
            {/* 1列目 */}
            <div className="flex h-[49px] w-full border-b border-gray-200">
              <div className="h-[48px] w-[88px] px-[24px] py-[16px] text-xs font-normal leading-4 text-gray-500">
                <div className="flex h-[16px] w-[43px] items-center justify-between">
                  <p className="h-[16px] w-[19px]">NO</p>
                  <SortUpIcon width={16} height={16} />
                </div>
              </div>
              <div className="h-[48px] w-[218px] px-[24px] py-[16px]">
                <div className="flex h-[16px] w-[87px] items-center justify-between">
                  <p className="h-[16px] w-[63px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                    投稿予定日
                  </p>
                  <SortUpIcon width={16} height={16} />
                </div>
              </div>
              <p className="h-[48px] w-[141px] whitespace-nowrap px-[24px] py-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                メディアイメージ
              </p>
              <p className="h-[48px] w-[234px] whitespace-nowrap px-[24px] py-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                テキストイメージ
              </p>
              <div className="h-[48px] w-[118px] px-[24px] py-[16px]">
                <div className="flex h-[16px] w-[62px] items-center justify-between">
                  <p className="h-[16px] w-[38px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                    タイプ
                  </p>
                  <SortUpIcon width={16} height={16} />
                </div>
              </div>
              <div className="h-[48px] w-[157px] px-[24px] py-[16px]">
                <div className="flex h-[16px] w-[87px] items-center justify-between">
                  <p className="h-[16px] w-[63px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                    ステータス
                  </p>
                  <SortUpIcon width={16} height={16} />
                </div>
              </div>
              <div className="h-[48px] w-[122px] px-[24px] py-[16px]">
                <div className="flex h-[16px] w-[74px] items-center justify-between">
                  <p className="h-[16px] w-[50px] whitespace-nowrap text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                    コメント
                  </p>
                  <SortUpIcon width={16} height={16} />
                </div>
              </div>
            </div>
            {/* 2列目 */}
            <div className="flex h-[73px] w-full border-b border-gray-200">
              <p className="h-[72px] w-[88px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                0005
              </p>
              <p className="h-[72px] w-[218px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                2021年2月14日(金) 18:00
              </p>
              <div className="flex h-[72px] w-[141px] items-center px-[24px] py-[16px]">
                <Media0005Image width={40} height={40} />
              </div>
              <div className="flex h-[72px] w-[234px] items-center px-[24px] py-[16px]">
                <p className="align-middle text-xs font-normal leading-4 text-gray-700">
                  以下の強みを入れてください。...
                </p>
              </div>
              <div className="h-[72px] w-[118px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  フィード
                </p>
              </div>
              <div className="h-[72px] w-[157px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[128px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  コンテンツ制作待ち
                </p>
              </div>
              <p className="h-[72px] w-[120px] whitespace-nowrap px-[24px] py-[26px] text-center text-sm font-normal leading-5 text-gray-700">
                1
              </p>
              <div className="flex h-[72px] w-[57px] items-center px-[9px] py-[24px]">
                {DotsDropdown('この投稿制作')}
              </div>
            </div>
            {/* 3列目 */}
            <div className="flex h-[73px] w-full border-b border-gray-200">
              <p className="h-[72px] w-[88px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                0004
              </p>
              <p className="h-[72px] w-[218px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                2021年2月12日(金) 18:00
              </p>
              <div className="flex h-[72px] w-[141px] items-center px-[24px] py-[16px]">
                <img src={Media0004Image} alt="" />
              </div>
              <div className="flex h-[72px] w-[234px] items-center px-[24px] py-[16px]">
                <p className="align-middle text-xs font-normal leading-4 text-gray-700">
                  「Canon360」という商品名を入れてください。...
                </p>
              </div>
              <div className="h-[72px] w-[118px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  フィード
                </p>
              </div>
              <div className="h-[72px] w-[157px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[128px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  コンテンツ制作待ち
                </p>
              </div>
              <p className="h-[72px] w-[120px] whitespace-nowrap px-[24px] py-[26px] text-center text-sm font-normal leading-5 text-gray-700">
                10
              </p>
              <div className="flex h-[72px] w-[57px] items-center px-[9px] py-[24px]">
                {DotsDropdown('この投稿制作')}
              </div>
            </div>
            {/* 4列目 */}
            <div className="flex h-[73px] w-full border-b border-gray-200">
              <p className="h-[72px] w-[88px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                0003
              </p>
              <p className="h-[72px] w-[218px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                2021年2月10日(金) 18:00
              </p>
              <div className="flex h-[72px] w-[141px] items-center px-[24px] py-[16px]">
                <img src={Media0003Image} alt="" />
              </div>
              <div className="flex h-[72px] w-[234px] items-center px-[24px] py-[16px]">
                <p className="align-middle text-xs font-normal leading-4 text-gray-700">
                  「Canon360」という商品名を入れてください。...
                </p>
              </div>
              <div className="h-[72px] w-[118px] py-[26px] pl-[24px]">
                <p className="h-[20px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  ストーリーズ
                </p>
              </div>
              <div className="h-[72px] w-[157px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[44px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-center text-xs font-medium leading-4 text-gray-700">
                  完了
                </p>
              </div>
              <p className="h-[72px] w-[120px] whitespace-nowrap px-[24px] py-[26px] text-center text-sm font-normal leading-5 text-gray-700">
                6
              </p>
              <div className="flex h-[72px] w-[57px] items-center px-[9px] py-[24px]">
                {DotsDropdown('この投稿制作')}
              </div>
            </div>
            {/* 5列目 */}
            <div className="flex h-[73px] w-full border-b border-gray-200">
              <p className="h-[72px] w-[88px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                0002
              </p>
              <p className="h-[72px] w-[218px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                2021年2月8日(金) 18:00
              </p>
              <div className="flex h-[72px] w-[141px] items-center px-[24px] py-[16px]">
                <img src={Media0002Image} alt="" />
              </div>
              <div className="flex h-[72px] w-[234px] items-center px-[24px] py-[16px]">
                <p className="align-middle text-xs font-normal leading-4 text-gray-700">
                  「Canon360」という商品名を入れてください。...
                </p>
              </div>
              <div className="h-[72px] w-[118px] py-[26px] pl-[24px]">
                <p className="h-[20px] w-[56px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  リール
                </p>
              </div>
              <div className="h-[72px] w-[157px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[44px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-center text-xs font-medium leading-4 text-gray-700">
                  完了
                </p>
              </div>
              <p className="h-[72px] w-[120px] whitespace-nowrap px-[24px] py-[26px] text-center text-sm font-normal leading-5 text-gray-700">
                2
              </p>
              <div className="flex h-[72px] w-[57px] items-center px-[9px] py-[24px]">
                {DotsDropdown('この投稿制作')}
              </div>
            </div>
            {/* 6列目 */}
            <div className="flex h-[73px] w-full border-b border-gray-200">
              <p className="h-[72px] w-[88px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                0002
              </p>
              <p className="h-[72px] w-[218px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                2021年2月8日(金) 18:00
              </p>
              <div className="flex h-[72px] w-[141px] items-center px-[24px] py-[16px]">
                <img src={Media0002Image} alt="" />
              </div>
              <div className="flex h-[72px] w-[234px] items-center px-[24px] py-[16px]">
                <p className="align-middle text-xs font-normal leading-4 text-gray-700">
                  「Canon360」という商品名を入れてください。...
                </p>
              </div>
              <div className="h-[72px] w-[118px] py-[26px] pl-[24px]">
                <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                  フィード
                </p>
              </div>
              <div className="h-[72px] w-[157px] px-[24px] py-[26px]">
                <p className="h-[20px] w-[44px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-center text-xs font-medium leading-4 text-gray-700">
                  完了
                </p>
              </div>
              <p className="h-[72px] w-[120px] whitespace-nowrap px-[24px] py-[26px] text-center text-sm font-normal leading-5 text-gray-700">
                10
              </p>
              <div className="flex h-[72px] w-[57px] items-center px-[9px] py-[24px]">
                {DotsDropdown('この投稿制作')}
              </div>
            </div>
          </div>
          {/* aaa */}
          <div className="flex h-[62px] items-center justify-between px-[24px] py-[12px]">
            <div className="flex h-[24px] w-[268px] items-center justify-between">
              <div className="flex h-[24px] w-[172px] items-center justify-between">
                <p className="h-[16px] w-[124px] align-middle text-xs font-normal leading-4 text-gray-500">
                  １ページあたりの行数:
                </p>
                <div className="flex h-[24px] w-[40px] items-center justify-between">
                  <p className="h-[16px] w-[16px] align-middle text-xs font-normal leading-4">30</p>
                  <ArrowDropDownIcon width={24} height={24} />
                </div>
              </div>
              <p className="h-[20px] w-[88px] align-middle text-sm font-normal leading-5 text-gray-500">31-60 of 300</p>
            </div>
            <div className="flex h-[38px] items-center border-[1px] border-gray-200">
              <div className="flex h-[38px] w-[40px] items-center justify-center border-r-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                <ChevronsLeftIcon width={20} height={20} fill="#6B7280" />
              </div>
              <div className="flex h-[38px] w-[40px] items-center justify-center border-r-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                <ChevronLeftIcon width={20} height={20} fill="#6B7280" />
              </div>
              {numbers.map((value: any, index: any) => (
                <li
                  key={index}
                  className="flex h-[38px] w-[40px] items-center justify-center border-r-[1px] border-gray-200 text-sm font-medium leading-5 text-gray-500 hover:bg-[#E0F4FF]"
                >
                  {value}
                </li>
              ))}
              <div className="flex h-[38px] w-[40px] items-center justify-center hover:bg-[#E0F4FF]">
                <ChevronRightIcon width={20} height={20} fill="#6B7280" />
              </div>
              <div className="flex h-[38px] w-[40px] items-center justify-center border-l-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                <ChevronsRightIcon width={20} height={20} fill="#6B7280" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
