/* tslint:disable */
/* eslint-disable */
/**
 * Influfect クライアント API
 * Influfect クライアント APIドキュメント
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountPr
 */
export interface AccountPr {
    /**
     * 
     * @type {string}
     * @memberof AccountPr
     */
    'characteristic': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPr
     */
    'strongPoint': string;
    /**
     * 
     * @type {string}
     * @memberof AccountPr
     */
    'actualResults': string;
}
/**
 * 
 * @export
 * @interface AffectOutput
 */
export interface AffectOutput {
    /**
     * 
     * @type {string}
     * @memberof AffectOutput
     */
    'affected': string;
}
/**
 * 
 * @export
 * @interface AgeDistribution
 */
export interface AgeDistribution {
    /**
     * 
     * @type {string}
     * @memberof AgeDistribution
     */
    'distribution': AgeDistributionDistributionEnum;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'mans': number;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'womans': number;
    /**
     * 
     * @type {number}
     * @memberof AgeDistribution
     */
    'others': number;
}

export const AgeDistributionDistributionEnum = {
    _13And17: 'between_13_and_17',
    _18And24: 'between_18_and_24',
    _25And34: 'between_25_and_34',
    _35And44: 'between_35_and_44',
    _45And54: 'between_45_and_54'
} as const;

export type AgeDistributionDistributionEnum = typeof AgeDistributionDistributionEnum[keyof typeof AgeDistributionDistributionEnum];

/**
 * 
 * @export
 * @interface ApiResponseError
 */
export interface ApiResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseError
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResponseError
     */
    'messages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface AppealPost
 */
export interface AppealPost {
    /**
     * 
     * @type {string}
     * @memberof AppealPost
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof AppealPost
     */
    'imageUrl': string;
}
/**
 * 承認ステータス
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    Review: 'review',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface CommonApplicant
 */
export interface CommonApplicant {
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'snsAccountNo': string;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof CommonApplicant
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommonApplicant
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof CommonApplicant
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof CommonApplicant
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'operatorComment': string;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'hearingItem1': string;
    /**
     * 
     * @type {string}
     * @memberof CommonApplicant
     */
    'hearingItem2': string;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof CommonApplicant
     */
    'selectionStatus': number;
    /**
     * 
     * @type {boolean}
     * @memberof CommonApplicant
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CommonApplicant
     */
    'follow': boolean;
}
/**
 * 
 * @export
 * @interface EmeraldPostSummaryOption
 */
export interface EmeraldPostSummaryOption {
    /**
     * 0:なし 1:あり
     * @type {number}
     * @memberof EmeraldPostSummaryOption
     */
    'goodQuality'?: number;
    /**
     * 0:なし 1:あり
     * @type {number}
     * @memberof EmeraldPostSummaryOption
     */
    'rewrite'?: number;
    /**
     * 0:なし 1:あり
     * @type {number}
     * @memberof EmeraldPostSummaryOption
     */
    'deliverImage'?: number;
}
/**
 * フォロワー層割合（Instagram以外はnull）
 * @export
 * @interface Followers
 */
export interface Followers {
    /**
     * 
     * @type {number}
     * @memberof Followers
     */
    'mans': number;
    /**
     * 
     * @type {number}
     * @memberof Followers
     */
    'womans': number;
    /**
     * 
     * @type {number}
     * @memberof Followers
     */
    'others': number;
}
/**
 * 
 * @export
 * @interface GetSnsAccount400Response
 */
export interface GetSnsAccount400Response {
    /**
     * 
     * @type {string}
     * @memberof GetSnsAccount400Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetSnsAccount400Response
     */
    'messages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetSnsAccountOutput
 */
export interface GetSnsAccountOutput {
    /**
     * 
     * @type {string}
     * @memberof GetSnsAccountOutput
     */
    'id': string;
    /**
     * 
     * @type {SnsType}
     * @memberof GetSnsAccountOutput
     */
    'sns': SnsType;
    /**
     * 
     * @type {string}
     * @memberof GetSnsAccountOutput
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof GetSnsAccountOutput
     */
    'thumbnail': string;
    /**
     * 
     * @type {Array<InfluencerEvaluation>}
     * @memberof GetSnsAccountOutput
     */
    'evaluations': Array<InfluencerEvaluation>;
    /**
     * 
     * @type {number}
     * @memberof GetSnsAccountOutput
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof GetSnsAccountOutput
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof GetSnsAccountOutput
     */
    'operatorComment': string;
    /**
     * 
     * @type {boolean}
     * @memberof GetSnsAccountOutput
     */
    'reserved': boolean;
    /**
     * 
     * @type {SnsAccountPosts}
     * @memberof GetSnsAccountOutput
     */
    'posts': SnsAccountPosts;
    /**
     * 
     * @type {SnsAccountInsight}
     * @memberof GetSnsAccountOutput
     */
    'insight': SnsAccountInsight;
    /**
     * 
     * @type {SnsAccountFollowerAttributes}
     * @memberof GetSnsAccountOutput
     */
    'followerAttributes': SnsAccountFollowerAttributes;
    /**
     * 
     * @type {AccountPr}
     * @memberof GetSnsAccountOutput
     */
    'accountPr': AccountPr;
}


/**
 * 
 * @export
 * @interface InfluencerEvaluation
 */
export interface InfluencerEvaluation {
    /**
     * 
     * @type {number}
     * @memberof InfluencerEvaluation
     */
    'rate': number;
    /**
     * 1〜5の整数値
     * @type {number}
     * @memberof InfluencerEvaluation
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface InstagramApplicant
 */
export interface InstagramApplicant {
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'snsAccountNo': string;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof InstagramApplicant
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'operatorComment': string;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'hearingItem1': string;
    /**
     * 
     * @type {string}
     * @memberof InstagramApplicant
     */
    'hearingItem2': string;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof InstagramApplicant
     */
    'selectionStatus': number;
    /**
     * 
     * @type {boolean}
     * @memberof InstagramApplicant
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InstagramApplicant
     */
    'follow': boolean;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'followers': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'impressions': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'reach': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'good': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'comments': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'preservations': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'engagements': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'followerEngagementRate': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'impressionsEngagementRate': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'interactionRate': number;
    /**
     * 
     * @type {number}
     * @memberof InstagramApplicant
     */
    'frequency': number;
}
/**
 * 
 * @export
 * @interface OtherSnsApplicant
 */
export interface OtherSnsApplicant {
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'snsAccountNo': string;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OtherSnsApplicant
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof OtherSnsApplicant
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof OtherSnsApplicant
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'operatorComment': string;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'hearingItem1': string;
    /**
     * 
     * @type {string}
     * @memberof OtherSnsApplicant
     */
    'hearingItem2': string;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof OtherSnsApplicant
     */
    'selectionStatus': number;
    /**
     * 
     * @type {boolean}
     * @memberof OtherSnsApplicant
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof OtherSnsApplicant
     */
    'follow': boolean;
    /**
     * 
     * @type {number}
     * @memberof OtherSnsApplicant
     */
    'followers': number;
}
/**
 * 
 * @export
 * @interface PaginationOutput
 */
export interface PaginationOutput {
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PostingType = {
    Reel: 'reel',
    Feed: 'feed',
    Stories: 'stories',
    XImagePost: 'x_image_post',
    XPost: 'x_post',
    YoutubeShort: 'youtube_short',
    Youtube: 'youtube',
    TikTok: 'tik_tok',
    OtherVideo: 'other_video',
    Other: 'other'
} as const;

export type PostingType = typeof PostingType[keyof typeof PostingType];


/**
 * 
 * @export
 * @interface PrefDistribution
 */
export interface PrefDistribution {
    /**
     * 
     * @type {string}
     * @memberof PrefDistribution
     */
    'prefCode': string;
    /**
     * 
     * @type {number}
     * @memberof PrefDistribution
     */
    'value': number;
}
/**
 * 最終確定日など期間ではないものはfromにnullを設定する
 * @export
 * @interface ProjectSchedule
 */
export interface ProjectSchedule {
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'to': string | null;
}
/**
 * <table> <tr><td>募集内容作成（下書き）</td><td>draft</td></tr> <tr><td>募集内容作成（申請中）</td><td>review</td></tr> <tr><td>募集〜選考</td><td>approved</td></tr> <tr><td>最終確認</td><td>final_confirmation</td></tr> <tr><td>実施中</td><td>implementation</td></tr> <tr><td>完了</td><td>completion</td></tr> <tr><td>募集完了(PRST)</td><td>completion</td></tr> <tr><td>配信待ち(EMERALD POST)</td><td>wait_post</td></tr> <tr><td>配信中(EMERALD POST)</td><td>posting</td></tr> <tr><td>配信完了(EMERALD POST)</td><td>post_completion</td></tr> </table>
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Draft: 'draft',
    Review: 'review',
    FinalConfirmation: 'final_confirmation',
    WaitPost: 'wait_post',
    Implementation: 'implementation',
    Posting: 'posting',
    Completion: 'completion',
    PostCompletion: 'post_completion',
    AcceptCompletion: 'accept_completion'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface ProjectSummaryApplicationCondition
 */
export interface ProjectSummaryApplicationCondition {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minFollowers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'gender'?: ProjectSummaryApplicationConditionGenderEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'age'?: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof ProjectSummaryApplicationCondition
     */
    'prefectures'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'annualIncome'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryApplicationCondition
     */
    'job'?: string | null;
}

export const ProjectSummaryApplicationConditionGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProjectSummaryApplicationConditionGenderEnum = typeof ProjectSummaryApplicationConditionGenderEnum[keyof typeof ProjectSummaryApplicationConditionGenderEnum];

/**
 * 
 * @export
 * @interface ProjectSummaryBase
 */
export interface ProjectSummaryBase {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'adoptionPlan': number;
    /**
     * 0:適正相場 1:カスタム設定 （Spirit以外では無視されます）
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewardsType'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewards': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryBase
     */
    'genres': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryBase
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDetails
 */
export interface ProjectSummaryDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryDetails
     */
    'notice': Array<string>;
    /**
     * true:非公開 false:公開
     * @type {boolean}
     * @memberof ProjectSummaryDetails
     */
    'isSecret': boolean;
}
/**
 * 
 * @export
 * @interface ProjectSummaryKpi
 */
export interface ProjectSummaryKpi {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryKpi
     */
    'reachCounts': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryKpi
     */
    'engCounts': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryKpi
     */
    'engRate': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryKpi
     */
    'followerCounts': number;
}
/**
 * 案件タイプ
 * @export
 * @enum {string}
 */

export const ProjectType = {
    Spirit: 'spirit',
    EmeraldPost: 'emerald_post',
    Prst: 'prst',
    JaneJohn: 'jane_john',
    Other: 'other'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * 
 * @export
 * @interface ProjectTypeItem
 */
export interface ProjectTypeItem {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeItem
     */
    'no': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectGroupName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'startDate': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'endDate': string;
}
/**
 * 
 * @export
 * @interface ProjectTypeListCounts
 */
export interface ProjectTypeListCounts {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'draft': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'selection'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'finalConfirmation'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'implementation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'completion': number;
}
/**
 * 
 * @export
 * @interface RecentPost
 */
export interface RecentPost {
    /**
     * 
     * @type {string}
     * @memberof RecentPost
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof RecentPost
     */
    'postUrl': string;
}
/**
 * 
 * @export
 * @interface SnsAccountFollowerAttributes
 */
export interface SnsAccountFollowerAttributes {
    /**
     * 
     * @type {Array<AgeDistribution>}
     * @memberof SnsAccountFollowerAttributes
     */
    'ageDistribution': Array<AgeDistribution>;
    /**
     * 
     * @type {Array<PrefDistribution>}
     * @memberof SnsAccountFollowerAttributes
     */
    'prefDistribution': Array<PrefDistribution>;
    /**
     * 
     * @type {Followers}
     * @memberof SnsAccountFollowerAttributes
     */
    'followers': Followers;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'ageGender1st': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'ageGender2nd': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'ageGender3rd': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'genre1st': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'genre2nd': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'genre3rd': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'values1st': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'values2nd': string;
    /**
     * 
     * @type {string}
     * @memberof SnsAccountFollowerAttributes
     */
    'values3rd': string;
}
/**
 * @type SnsAccountInsight
 * @export
 */
export type SnsAccountInsight = SnsAccountInsightInstagram | SnsAccountInsightTikTok | SnsAccountInsightX | SnsAccountInsightYoutube;

/**
 * 
 * @export
 * @interface SnsAccountInsightInstagram
 */
export interface SnsAccountInsightInstagram {
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'followers': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'impressions': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'reach': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'good': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'comments': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'preservations': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'engagements': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'followerEngagementRate': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'impressionsEngagementRate': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'interactionRate': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightInstagram
     */
    'frequency': number;
}
/**
 * 
 * @export
 * @interface SnsAccountInsightTikTok
 */
export interface SnsAccountInsightTikTok {
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'followers': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'good': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'goodAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'comments': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'shareAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'play': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'playAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightTikTok
     */
    'playMax': number;
}
/**
 * 
 * @export
 * @interface SnsAccountInsightX
 */
export interface SnsAccountInsightX {
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'followers': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'impressions': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'good': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'reply': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'retweet': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'urlClicks': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'profileClicks': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'engagements': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'followerEngagementRate': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightX
     */
    'impressionsEngagementRate': number;
}
/**
 * 
 * @export
 * @interface SnsAccountInsightYoutube
 */
export interface SnsAccountInsightYoutube {
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'subscribers': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'movies': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'comments': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'commentsAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'play': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'playAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'playMax': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'highEvaluationUsers': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'highEvaluationUsersAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'lowEvaluationUsers': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'lowEvaluationUsersAvg': number;
    /**
     * 
     * @type {number}
     * @memberof SnsAccountInsightYoutube
     */
    'favoriteUsersAvg': number;
}
/**
 * @type SnsAccountPosts
 * @export
 */
export type SnsAccountPosts = SnsAccountPostsInstagram | SnsAccountPostsTikTok | SnsAccountPostsX | SnsAccountPostsYoutube;

/**
 * 
 * @export
 * @interface SnsAccountPostsInstagram
 */
export interface SnsAccountPostsInstagram {
    /**
     * 
     * @type {Array<AppealPost>}
     * @memberof SnsAccountPostsInstagram
     */
    'appealPosts': Array<AppealPost>;
    /**
     * 
     * @type {Array<RecentPost>}
     * @memberof SnsAccountPostsInstagram
     */
    'recentPosts': Array<RecentPost>;
}
/**
 * 
 * @export
 * @interface SnsAccountPostsTikTok
 */
export interface SnsAccountPostsTikTok {
    /**
     * 
     * @type {Array<AppealPost>}
     * @memberof SnsAccountPostsTikTok
     */
    'appealPosts': Array<AppealPost>;
    /**
     * 
     * @type {Array<RecentPost>}
     * @memberof SnsAccountPostsTikTok
     */
    'recentPosts': Array<RecentPost>;
}
/**
 * 
 * @export
 * @interface SnsAccountPostsX
 */
export interface SnsAccountPostsX {
    /**
     * 
     * @type {Array<AppealPost>}
     * @memberof SnsAccountPostsX
     */
    'appealPosts': Array<AppealPost>;
    /**
     * 
     * @type {Array<RecentPost>}
     * @memberof SnsAccountPostsX
     */
    'recentPosts': Array<RecentPost>;
}
/**
 * 
 * @export
 * @interface SnsAccountPostsYoutube
 */
export interface SnsAccountPostsYoutube {
    /**
     * 
     * @type {Array<AppealPost>}
     * @memberof SnsAccountPostsYoutube
     */
    'appealPosts': Array<AppealPost>;
    /**
     * 
     * @type {Array<RecentPost>}
     * @memberof SnsAccountPostsYoutube
     */
    'recentPosts': Array<RecentPost>;
}
/**
 * SNSタイプ
 * @export
 * @enum {string}
 */

export const SnsType = {
    Instagram: 'instagram',
    X: 'x',
    TikTok: 'tik_tok',
    Youtube: 'youtube',
    Other: 'other'
} as const;

export type SnsType = typeof SnsType[keyof typeof SnsType];


/**
 * 
 * @export
 * @interface SpiritSummaryOption
 */
export interface SpiritSummaryOption {
    /**
     * 0:なし 1:あり
     * @type {number}
     * @memberof SpiritSummaryOption
     */
    'goodQuality'?: number;
    /**
     * 0:なし 1:あり
     * @type {number}
     * @memberof SpiritSummaryOption
     */
    'secondaryUse'?: number;
    /**
     * 0:なし 1:あり
     * @type {number}
     * @memberof SpiritSummaryOption
     */
    'adoptionOutsourced'?: number;
}
/**
 * 
 * @export
 * @interface TikTokApplicant
 */
export interface TikTokApplicant {
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'snsAccountNo': string;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof TikTokApplicant
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'operatorComment': string;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'hearingItem1': string;
    /**
     * 
     * @type {string}
     * @memberof TikTokApplicant
     */
    'hearingItem2': string;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof TikTokApplicant
     */
    'selectionStatus': number;
    /**
     * 
     * @type {boolean}
     * @memberof TikTokApplicant
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof TikTokApplicant
     */
    'follow': boolean;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'followers': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'good': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'goodAvg': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'comments': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'share': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'shareAvg': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'play': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'playAvg': number;
    /**
     * 
     * @type {number}
     * @memberof TikTokApplicant
     */
    'playMax': number;
}
/**
 * 
 * @export
 * @interface UploadedImageOutput
 */
export interface UploadedImageOutput {
    /**
     * 
     * @type {string}
     * @memberof UploadedImageOutput
     */
    'uploadedFilePath'?: string;
}
/**
 * 
 * @export
 * @interface XApplicant
 */
export interface XApplicant {
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'snsAccountNo': string;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof XApplicant
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'operatorComment': string;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'hearingItem1': string;
    /**
     * 
     * @type {string}
     * @memberof XApplicant
     */
    'hearingItem2': string;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof XApplicant
     */
    'selectionStatus': number;
    /**
     * 
     * @type {boolean}
     * @memberof XApplicant
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof XApplicant
     */
    'follow': boolean;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'followers': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'impressions': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'good': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'reply': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'retweet': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'urlClicks': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'profileClicks': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'engagements': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'followerEngagementRate': number;
    /**
     * 
     * @type {number}
     * @memberof XApplicant
     */
    'impressionsEngagementRate': number;
}
/**
 * 
 * @export
 * @interface YoutubeApplicant
 */
export interface YoutubeApplicant {
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'snsAccountNo': string;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'thumbnail': string;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'influencerComment': string;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'operatorComment': string;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'hearingItem1': string;
    /**
     * 
     * @type {string}
     * @memberof YoutubeApplicant
     */
    'hearingItem2': string;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'selectionStatus': number;
    /**
     * 
     * @type {boolean}
     * @memberof YoutubeApplicant
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof YoutubeApplicant
     */
    'follow': boolean;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'subscribers': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'movies': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'comments': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'commentsAvg': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'play': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'playAvg': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'playMax': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'highEvaluationUsers': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'highEvaluationUsersAvg': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'lowEvaluationUsers': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'lowEvaluationUsersAvg': number;
    /**
     * 
     * @type {number}
     * @memberof YoutubeApplicant
     */
    'favoriteUsersAvg': number;
}

/**
 * SNSApi - axios parameter creator
 * @export
 */
export const SNSApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したインフルエンサーのSNSアカウントの詳細情報情報を取得し返却します。<br> influencer APIに移設します
         * @summary SNSアカウント詳細情報取得API
         * @param {string} snsAccountId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSnsAccount: async (snsAccountId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'snsAccountId' is not null or undefined
            assertParamExists('getSnsAccount', 'snsAccountId', snsAccountId)
            const localVarPath = `/sns-accounts/{snsAccountId}`
                .replace(`{${"snsAccountId"}}`, encodeURIComponent(String(snsAccountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SNSApi - functional programming interface
 * @export
 */
export const SNSApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SNSApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したインフルエンサーのSNSアカウントの詳細情報情報を取得し返却します。<br> influencer APIに移設します
         * @summary SNSアカウント詳細情報取得API
         * @param {string} snsAccountId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async getSnsAccount(snsAccountId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSnsAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSnsAccount(snsAccountId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['SNSApi.getSnsAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * SNSApi - factory interface
 * @export
 */
export const SNSApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SNSApiFp(configuration)
    return {
        /**
         * 指定したインフルエンサーのSNSアカウントの詳細情報情報を取得し返却します。<br> influencer APIに移設します
         * @summary SNSアカウント詳細情報取得API
         * @param {string} snsAccountId 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        getSnsAccount(snsAccountId: string, options?: any): AxiosPromise<GetSnsAccountOutput> {
            return localVarFp.getSnsAccount(snsAccountId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SNSApi - object-oriented interface
 * @export
 * @class SNSApi
 * @extends {BaseAPI}
 */
export class SNSApi extends BaseAPI {
    /**
     * 指定したインフルエンサーのSNSアカウントの詳細情報情報を取得し返却します。<br> influencer APIに移設します
     * @summary SNSアカウント詳細情報取得API
     * @param {string} snsAccountId 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof SNSApi
     */
    public getSnsAccount(snsAccountId: string, options?: RawAxiosRequestConfig) {
        return SNSApiFp(this.configuration).getSnsAccount(snsAccountId, options).then((request) => request(this.axios, this.basePath));
    }
}



