import { Link } from 'react-router-dom';

// Dupricated:共通版の src/components/Preview/previewCommon.tsx を使用してください
export const PreviewYoutube = (image: string, title: string, video: boolean, URL: string) => {
  return (
    <div className="flex flex-col items-start gap-[15px]">
      <div className="h-[402px] w-[360px] rounded-lg">
        {video ? (
          <div className="h-[230px] w-[360px] shrink-0">
            <div>
              <img src={image} alt="" className="rounded-lg" />
            </div>
            <div className="flex flex-col gap-7 pb-2 pt-4">
              <div className="flex gap-2">
                <div className="flex flex-col items-start gap-2">
                  <div className="w-[308px]">
                    <span className="text-base font-medium">{title}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="h-[230px] w-[360px] shrink-0">
            <div>
              <img src={image} alt="" className="rounded-lg" />
            </div>
            <div className="flex flex-col gap-7 pb-2 pt-4">
              <div className="flex gap-2">
                <div className="flex flex-col items-start gap-2">
                  <div className="mb-6 w-[308px]">
                    <span className="text-base font-medium">{title}</span>
                  </div>
                  {/* <div className="mt-6 w-[308px]">
                    <span className="text-base font-medium">{URL}</span>
                  </div> */}
                  <Link to={URL} target="_blank" className="break-all text-blue-500">
                    {URL}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
