import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';

import { EmeraldPostSnsDetail } from '@/api/emeraldPost';
import { Badge } from '@/components/Elements/Badge';
import { Button } from '@/components/Elements/Button/Button';
import { Input } from '@/components/Elements/Input';
import { TextArea } from '@/components/Elements/TextArea';
import { validateUrlFormat, validationEmptyCheck, validationMaxCheck } from '@/utils/inputMultiValidationCheck';

import { snsDeliveryDetailsModalIStates } from '../../interfaces/emeraldProjectInterface';

interface Props {
  snsDeliveryDetailsModalIStates: snsDeliveryDetailsModalIStates;
  setSnsDeliveryDetailsModalIStates: React.Dispatch<React.SetStateAction<snsDeliveryDetailsModalIStates>>;
  PutPostSnsUpdate: (snsDetail: EmeraldPostSnsDetail) => Promise<void>;
}
interface modalDatas {
  instagramText: string;
  instagramTextError: string;
  instagramIncludedHashTag: string;
  instagramIncludedHashTags: string[];
  instagramIncludedHashTagsError: string;
  instagramIncludedAccountTag: string;
  instagramIncludedAccountTagError: string;
  twitterText: string;
  twitterTextError: string;
  twitterIncludedHashTag: string;
  twitterIncludedHashTags: string[];
  twitterIncludedHashTagsError: string;
  twitterIncludedAccountTag: string;
  twitterIncludedAccountTagError: string;
  storiesProvidedUrl: string;
  storiesProvidedUrlError: string;
  providedUrl: string;
  providedUrlError: string;
  instagramTotalCountError: string;
  twitterTotalCountError: string;
}

export const SnsDeliveryDetails = (props: Props) => {
  // modalDatasの状態を初期化するためのuseStateフック
  const [modalDatas, setModalDatas] = useState<modalDatas>({
    // Instagram用のテキストとそのエラーメッセージ
    instagramText: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.text || '',
    instagramTextError: '',

    // Instagramに含めるハッシュタグとそのエラーメッセージ
    instagramIncludedHashTag: '',
    instagramIncludedHashTags: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.hashTags || [],
    instagramIncludedHashTagsError: '',

    // Instagramに含めるアカウントタグ（メンション）とそのエラーメッセージ
    instagramIncludedAccountTag: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.accountTag || '',
    instagramIncludedAccountTagError: '',

    // Twitter用のテキストとそのエラーメッセージ
    twitterText: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.text || '',
    twitterTextError: '',

    // Twitterに含めるハッシュタグとそのエラーメッセージ
    twitterIncludedHashTag: '',
    twitterIncludedHashTags: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.hashTags || [],
    twitterIncludedHashTagsError: '',

    // Twitterに含めるアカウントタグ（メンション）とそのエラーメッセージ
    twitterIncludedAccountTag: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.accountTag || '',
    twitterIncludedAccountTagError: '',

    // Instagramストーリーズ用のURLとそのエラーメッセージ
    storiesProvidedUrl: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.storiesUrl || '',
    storiesProvidedUrlError: '',

    // 全般的なURL（共通）とそのエラーメッセージ
    providedUrl: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.url || '',
    providedUrlError: '',

    // InstagramとTwitterの全体的なテキスト長のエラーメッセージ
    instagramTotalCountError: '',
    twitterTotalCountError: '',
  });

  // propsからsnsDeliveryDetailsModalIStatesの値を取得
  const snsDeliveryDetailsModalIStatesProps = props.snsDeliveryDetailsModalIStates;

  // Instagramのハッシュタグを追加する関数
  const handleAddInstagramIncludedHashtags = (): void => {
    if (modalDatas.instagramIncludedHashTag.length === 0 || modalDatas.instagramIncludedHashTagsError) return;
    setModalDatas({
      ...modalDatas,
      instagramIncludedHashTags: [...modalDatas.instagramIncludedHashTags, `#${modalDatas.instagramIncludedHashTag}`],
      instagramIncludedHashTag: '',
    });
  };

  // Instagramのハッシュタグを削除する関数
  const handleRemoveInstagramIncludedHashtags = (index: number): void => {
    const tmpTags = modalDatas.instagramIncludedHashTags;
    setModalDatas({
      ...modalDatas,
      instagramIncludedHashTags: tmpTags.filter((_, i) => i !== index),
    });
  };

  // Twitterのハッシュタグを追加する関数
  const handleAddTwitterIncludedHashtags = (): void => {
    if (modalDatas.twitterIncludedHashTag.length === 0 || modalDatas.twitterIncludedHashTagsError) return;
    setModalDatas({
      ...modalDatas,
      twitterIncludedHashTags: [...modalDatas.twitterIncludedHashTags, `#${modalDatas.twitterIncludedHashTag}`],
      twitterIncludedHashTag: '',
    });
  };

  // Twitterのハッシュタグを削除する関数
  const handleRemoveTwitterIncludedHashtags = (index: number): void => {
    const tmpTags = modalDatas.twitterIncludedHashTags;
    setModalDatas({
      ...modalDatas,
      twitterIncludedHashTags: tmpTags.filter((_, i) => i !== index),
    });
  };

  // モーダルを閉じる関数
  const close = (): void => {
    // 全てのフィールドを初期化
    setModalDatas({
      instagramText: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.text || '',
      instagramTextError: '',
      instagramIncludedHashTag: '',
      instagramIncludedHashTags: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.hashTags || [],
      instagramIncludedHashTagsError: '',
      instagramIncludedAccountTag: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.accountTag || '',
      instagramIncludedAccountTagError: '',
      twitterText: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.text || '',
      twitterTextError: '',
      twitterIncludedHashTag: '',
      twitterIncludedHashTags: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.hashTags || [],
      twitterIncludedHashTagsError: '',
      twitterIncludedAccountTag: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.accountTag || '',
      twitterIncludedAccountTagError: '',
      storiesProvidedUrl: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.instagram.storiesUrl || '',
      storiesProvidedUrlError: '',
      providedUrl: props.snsDeliveryDetailsModalIStates.snsDeliveryDetails.x.url || '',
      providedUrlError: '',
      instagramTotalCountError: '',
      twitterTotalCountError: '',
    });

    props.setSnsDeliveryDetailsModalIStates({
      ...snsDeliveryDetailsModalIStatesProps,
      snsDeliveryDetailsModalIsOpen: false,
    });
  };

  // 保存処理を実行する関数
  const save = async (): Promise<void> => {
    // 入力データのバリデーションチェック
    const instagramTextError =
      validationEmptyCheck(modalDatas.instagramText, '') ||
      validationMaxCheck(modalDatas.instagramText, 140, 'Instagram用配信テキスト');
    const instagramIncludedAccountTagError = validationMaxCheck(
      modalDatas.instagramIncludedAccountTag,
      50,
      'アカウントタグ'
    );
    const storiesProvidedUrlError = validateUrlFormat(modalDatas.storiesProvidedUrl, 'ストーリーズ用記載するURL');
    const twitterTextError =
      validationEmptyCheck(modalDatas.twitterText, '') ||
      validationMaxCheck(modalDatas.twitterText, 140, 'X用配信テキスト');
    const twitterIncludedAccountTagError = validationMaxCheck(
      modalDatas.twitterIncludedAccountTag,
      50,
      'アカウントタグ'
    );
    const providedUrlError = validateUrlFormat(modalDatas.providedUrl, '記載するURL');

    setModalDatas({
      ...modalDatas,
      instagramTextError,
      instagramIncludedAccountTagError,
      storiesProvidedUrlError,
      twitterTextError,
      twitterIncludedAccountTagError,
      providedUrlError,
    });

    // 全てのバリデーションエラーがない場合、APIにデータを送信
    if (
      instagramTextError == '' &&
      modalDatas.instagramIncludedHashTagsError == '' &&
      instagramIncludedAccountTagError == '' &&
      storiesProvidedUrlError == '' &&
      twitterTextError == '' &&
      modalDatas.twitterIncludedHashTagsError == '' &&
      twitterIncludedAccountTagError == '' &&
      providedUrlError == '' &&
      modalDatas.instagramTotalCountError == '' &&
      modalDatas.twitterTotalCountError == ''
    ) {
      // APIに送信 TODO
      props.PutPostSnsUpdate({
        instagram: {
          text: modalDatas.instagramText,
          hashTags: modalDatas.instagramIncludedHashTags,
          accountTag: modalDatas.instagramIncludedAccountTag,
          storiesUrl: modalDatas.storiesProvidedUrl === '' ? null : modalDatas.storiesProvidedUrl,
        },
        x: {
          text: modalDatas.twitterText,
          hashTags: modalDatas.twitterIncludedHashTags,
          accountTag: modalDatas.twitterIncludedAccountTag,
          url: modalDatas.providedUrl === '' ? null : modalDatas.providedUrl,
        },
      });
    }
  };

  // InstagramとTwitterのデフォルトレビューテキスト
  const defaultInstagramReviewValue = `▶︎Supported by @emerald_post`;
  const defaultTwitterReviewValue = `▶︎Supported by @Emerald_Post`;

  // Instagram用のレビューテキストを生成
  const instagramTextReviewValue: string = modalDatas.instagramText ? `${modalDatas.instagramText}\n` : '';
  const instagramIncludedHashTagsReviewValue: string = modalDatas.instagramIncludedHashTags.length
    ? `${modalDatas.instagramIncludedHashTags}\n`
    : '';
  const instagramIncludedAccountTagReviewValue: string = modalDatas.instagramIncludedAccountTag
    ? `${modalDatas.instagramIncludedAccountTag}\n`
    : '';
  const instagramIncludedURL: string = modalDatas.storiesProvidedUrl ? `${modalDatas.storiesProvidedUrl}\n` : '';

  // Instagram用の組み合わせたレビューテキスト
  const combinedInstagramReviewValue: string = [
    instagramTextReviewValue,
    instagramIncludedHashTagsReviewValue,
    instagramIncludedAccountTagReviewValue,
    instagramIncludedURL,
    defaultInstagramReviewValue,
  ].join('');

  // Twitter用のレビューテキストを生成
  const twitterTextReviewValue: string = modalDatas.twitterText ? `${modalDatas.twitterText}\n` : '';
  const twitterIncludedHashTagsReviewValue: string = modalDatas.twitterIncludedHashTags.length
    ? `${modalDatas.twitterIncludedHashTags}\n`
    : '';
  const twitterIncludedAccountTagReviewValue: string = modalDatas.twitterIncludedAccountTag
    ? `${modalDatas.twitterIncludedAccountTag}\n`
    : '';
  const twitterIncludedURL: string = modalDatas.providedUrl ? `${modalDatas.providedUrl}\n` : '';

  // Twitter用の組み合わせたレビューテキスト
  const combinedtwitterReviewValue: string = [
    twitterTextReviewValue,
    twitterIncludedHashTagsReviewValue,
    twitterIncludedAccountTagReviewValue,
    twitterIncludedURL,
    defaultTwitterReviewValue,
  ].join('');

  // Instagramのレビューテキストの文字数を計算
  const instagramReviewCount: number = 140 - combinedInstagramReviewValue.length + defaultInstagramReviewValue.length;
  useEffect(() => {
    // 文字数が140文字を超えた場合にエラーメッセージを設定
    if (instagramReviewCount < 0) {
      setModalDatas({
        ...modalDatas,
        instagramTotalCountError: '140文字以内で入力してください',
      });
    } else {
      setModalDatas({
        ...modalDatas,
        instagramTotalCountError: '',
      });
    }
  }, [instagramReviewCount]);

  // Twitterのレビューテキストの文字数を計算
  const twitterReviewCount: number = 140 - combinedtwitterReviewValue.length + defaultTwitterReviewValue.length;
  useEffect(() => {
    // 文字数が140文字を超えた場合にエラーメッセージを設定
    if (twitterReviewCount < 0) {
      setModalDatas({
        ...modalDatas,
        twitterTotalCountError: '140文字以内で入力してください',
      });
    } else {
      setModalDatas({
        ...modalDatas,
        twitterTotalCountError: '',
      });
    }
  }, [twitterReviewCount]);

  // Instagramのテキストの文字数を計算
  const checkInstagramText = (text: string) => {
    if (modalDatas.instagramTextError == 'Instagram用配信テキストは必須です' && text.length > 0) {
      setModalDatas({
        ...modalDatas,
        instagramTextError: '',
      });
    }
    setModalDatas({
      ...modalDatas,
      instagramTextError: validationMaxCheck(text, 140, 'Instagram用配信テキスト'),
      instagramText: text,
    });
  };

  // Instagramのハッシュタグの文字数を計算
  const checkInstagramHashTag = (text: string) => {
    setModalDatas({
      ...modalDatas,
      instagramIncludedHashTagsError: validationMaxCheck(text, 50, 'ハッシュタグ'),
      instagramIncludedHashTag: text,
    });
  };

  // Instagramのアカウントタグの文字数を計算
  const checkInstagramAccountTag = (text: string) => {
    setModalDatas({
      ...modalDatas,
      instagramIncludedAccountTagError: validationMaxCheck(text, 50, 'アカウントタグ'),
      instagramIncludedAccountTag: text,
    });
  };

  // 共通のURLの文字形式をチェック
  const checkStoriesProvidedUrl = (text: string) => {
    setModalDatas({
      ...modalDatas,
      storiesProvidedUrlError: validateUrlFormat(text, 'ストーリーズ用記載するURL'),
      storiesProvidedUrl: text,
    });
  };

  const checkXText = (text: string) => {
    if (modalDatas.twitterTextError == 'X用配信テキストは必須です' && text.length > 0) {
      setModalDatas({
        ...modalDatas,
        twitterTextError: '',
      });
    }
    setModalDatas({
      ...modalDatas,
      twitterTextError: validationMaxCheck(text, 140, 'X用配信テキスト'),
      twitterText: text,
    });
  };

  // Xのハッシュタグの文字数を計算
  const checkXHashTag = (text: string) => {
    setModalDatas({
      ...modalDatas,
      twitterIncludedHashTagsError: validationMaxCheck(text, 50, 'ハッシュタグ'),
      twitterIncludedHashTag: text,
    });
  };

  // Xのアカウントタグの文字数を計算
  const checkXAccountTag = (text: string) => {
    setModalDatas({
      ...modalDatas,
      twitterIncludedAccountTagError: validationMaxCheck(text, 50, 'アカウントタグ'),
      twitterIncludedAccountTag: text,
    });
  };

  const checkProvidedUrl = (text: string) => {
    setModalDatas({
      ...modalDatas,
      providedUrlError: validateUrlFormat(text, '記載するURL'),
      providedUrl: text,
    });
  };

  const addButtonClassName =
    'w-[64px] whitespace-nowrap rounded-md px-4 py-2 text-sm font-medium text-blue-500 ring-1 ring-inset ring-blue-500 hover:bg-gray-50';

  console.log(`snsDeliveryDetailsModalIStates: ${props.snsDeliveryDetailsModalIStates.snsDeliveryDetailsModalIsOpen}`);
  return (
    <Transition.Root show={props.snsDeliveryDetailsModalIStates.snsDeliveryDetailsModalIsOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          close();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-[858px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      close();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title
                      as="h2"
                      className="mb-[32px] h-[24px] w-[696px] text-xl font-semibold leading-6 text-gray-900"
                    >
                      SNS配信詳細
                    </Dialog.Title>
                    {/* 配信タイトル */}
                    {/* <div className="mb-[15px] flex h-[80px] w-[810px] items-center py-[10px]">
                      <p className="h-[20px] w-[120px] text-[16px] font-normal leading-5 text-[#000000]">
                        配信タイトル
                      </p>
                      <TextArea
                        title=""
                        helperText=""
                        maxLength={100}
                        className="h-[60px] w-[600px] rounded-md border-[1px] border-gray-300 py-[9px] text-[15px] font-normal leading-5 text-gray-900"
                        rows={2}
                        onChange={(e: { target: { value: any } }) => setDeliveryTitle(e.target.value)}
                        value={title}
                      ></TextArea>
                    </div> */}
                    {/* Instagram用 */}
                    <div className="mb-[15px] h-[741px] w-[100%]">
                      <p className="mb-[5px] h-[20px] w-[100%] text-[16px] font-semibold leading-5 text-gray-500">
                        Instagram用
                      </p>
                      {/* Instagram用配信テキスト */}
                      <div className="mx-1 mb-[5px] w-[100%]">
                        <div className="mx-5 h-[173px]">
                          <div className="mb-[8px] mt-[10px] h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            Instagram用配信テキスト
                          </div>
                          <div className="mb-[8px] h-[48px] w-[100%] text-[14px] font-normal leading-6 text-gray-500">
                            例）商品情報やキャンペーン情報など、to<span> </span>
                            Cに拡散したい内容を140文字以内でご記載ください。140文字カウントは下記部レビューをご参照ください。
                          </div>
                          <TextArea
                            title=""
                            helperText=""
                            maxLength={140}
                            className="h-[60px] rounded-md border-[1px] border-gray-300 px-[13px] py-[9px] text-[15px] font-normal leading-5 text-gray-900"
                            value={modalDatas.instagramText}
                            rows={2}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                              checkInstagramText(e.target.value);
                            }}
                            width="w-[760px]"
                            isError={modalDatas.instagramTextError !== ''}
                          ></TextArea>
                          <span className="text-[14px] text-[#F00]">{modalDatas.instagramTextError || ''}</span>
                        </div>
                        {/* 投稿に含まれるハッシュタグ(#) */}
                        <div className="mb-1 h-[118px] w-[100%] px-[20px] py-[10px]">
                          <p className="h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            投稿に含まれるハッシュタグ(#)
                          </p>
                          <div className="flex gap-[16px] pt-2">
                            <Input
                              label=""
                              isPlaceHolderRight={false}
                              placeholder="キャプションに含めたい任意のハッシュタグ"
                              style={{ width: '100%' }}
                              classnamelabel="block rounded-md border-0 py-1.5 pl-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={modalDatas.instagramIncludedHashTag}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                checkInstagramHashTag(e.target.value);
                              }}
                              isError={modalDatas.instagramIncludedHashTagsError !== ''}
                            ></Input>
                            <Button
                              variant="secoundary"
                              className={addButtonClassName}
                              onClick={handleAddInstagramIncludedHashtags}
                              disabled={modalDatas.instagramIncludedHashTag.length === 0}
                            >
                              追加
                            </Button>
                          </div>
                          <div className="mt-1 flex flex-wrap space-x-2">
                            {modalDatas.instagramIncludedHashTags.map((tag, index) => {
                              return (
                                <div key={index}>
                                  <Badge
                                    color="gray"
                                    round="sm"
                                    size="xs"
                                    className="w-fit"
                                    isRemovable={true}
                                    handleRemove={() => {
                                      handleRemoveInstagramIncludedHashtags(index);
                                    }}
                                  >
                                    {tag}
                                  </Badge>
                                </div>
                              );
                            })}
                          </div>
                          <div className="pb-2 text-[14px] text-[#F00]">
                            {modalDatas.instagramIncludedHashTagsError || ''}
                          </div>
                        </div>
                        {/* 投稿に含めるアカウントタグ(メンション) */}
                        <div className="mb-1 h-[118px] w-[100%] px-[20px] py-[10px]">
                          <p className="h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            投稿に含めるアカウントタグ(メンション)
                          </p>
                          <div className="flex gap-[16px] pt-2">
                            <Input
                              label=""
                              style={{ width: '100%' }}
                              isPlaceHolderRight={false}
                              placeholder="投稿に紐づける必須のアカウントタグ"
                              classnamelabel="block rounded-md border-0 py-1.5 pl-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={modalDatas.instagramIncludedAccountTag}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                checkInstagramAccountTag(e.target.value);
                              }}
                              isError={modalDatas.instagramIncludedAccountTagError !== ''}
                            ></Input>
                          </div>
                          <span className="text-[14px] text-[#F00]">
                            {modalDatas.instagramIncludedAccountTagError || ''}
                          </span>
                        </div>
                        {/* ストーリーズ用記載するURL */}
                        <div className="mx-auto mb-[5px] h-[134px] w-[100%] px-[20px] py-[10px]">
                          <p className="mx-auto mb-[8px] mt-[10px] h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            ストーリーズ用記載するURL
                          </p>
                          <p className="mx-auto mb-[8px] h-[60px] w-[100%] text-[14px] font-normal leading-5 text-gray-500">
                            ※ストーリーズ投稿にリンクが追加できる「リンクスタンプ」が導入されました。
                            <br />
                            詳細：https://about.fb.com/ja/news/2021/10/linkaccessstickers/
                            <br />
                            ※リンクスタンプはInstagramストーリーズの機能になります。ストーリーズ投稿のみ適用となります。
                          </p>
                          <div className="mx-auto flex h-[38px] w-[100%]">
                            <Input
                              label=""
                              style={{ width: '100%' }}
                              isPlaceHolderRight={false}
                              placeholder="ストーリーズ投稿 リンクスタンプ"
                              className="mb-[8px] h-[40px] w-[100%] rounded-md border-[1px] border-gray-300 px-[13px] py-[9px] text-[14px] font-normal leading-5 placeholder:text-gray-500"
                              value={modalDatas.storiesProvidedUrl}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                checkStoriesProvidedUrl(e.target.value);
                              }}
                              isError={modalDatas.storiesProvidedUrlError !== ''}
                            ></Input>
                          </div>
                          <span className="text-[14px] text-[#F00]">{modalDatas.storiesProvidedUrlError || ''}</span>
                        </div>
                        {/* レビュー */}
                        <div className="mx-auto mb-[5px] h-[138px] w-[770px] py-[30px]">
                          <p className="mx-auto mb-[8px] mt-[10px] h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            レビュー
                          </p>
                          <div className="h-[90px] w-[100%]">
                            <TextArea
                              title=""
                              helperText=""
                              placeholder="▶︎Supported by @emerald_post"
                              maxLength={140}
                              disabled={true}
                              className="mb-[8px] h-[58px] rounded-md border-[1px] border-gray-300 bg-gray-200 py-[19px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-gray-900"
                              rows={4}
                              value={combinedInstagramReviewValue}
                              width="w-[760px]"
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                console.log(e.target.value);
                              }}
                              isError={modalDatas.instagramTotalCountError !== ''}
                            ></TextArea>
                            <span className="text-[14px] text-[#F00]">{modalDatas.instagramTotalCountError || ''}</span>
                            <p className="mx-auto h-[24px] w-[100%] text-right text-[14px] font-normal leading-6">
                              {!modalDatas.instagramTotalCountError && `あと${instagramReviewCount}文字`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* X用 */}
                    <div className="h-[630px] w-[100%] pt-[10px]">
                      <p className="mb-[5px] h-[20px] w-[100%] text-[16px] font-semibold leading-5 text-gray-500">
                        X用
                      </p>
                      {/* Twitter用配信テキスト */}
                      <div className="mx-1 mb-[5px] w-[100%]">
                        <div className="mx-5 my-[10px] h-[142px]">
                          <p className="mb-[8px] mt-[10px] h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            X用配信テキスト
                          </p>
                          <p className="mb-[8px] h-[48px] w-[100%] text-[14px] font-normal leading-6 text-gray-500">
                            例）商品情報やキャンペーン情報など、to<span> </span>
                            Cに拡散したい内容を140文字以内でご記載ください。140文字カウントは下記部レビューをご参照ください。
                          </p>
                          <TextArea
                            title=""
                            helperText=""
                            maxLength={140}
                            className="h-[40px] w-[100%] rounded-md border-[1px] border-gray-300 px-[13px] py-[9px] text-[15px] font-normal leading-5 text-gray-900 placeholder:text-gray-900"
                            value={modalDatas.twitterText}
                            rows={2}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                              checkXText(e.target.value);
                            }}
                            isError={modalDatas.twitterTextError !== ''}
                            width="w-[760px]"
                          ></TextArea>
                          <span className="text-[14px] text-[#F00]">{modalDatas.twitterTextError || ''}</span>
                        </div>
                        {/* 投稿に含まれるハッシュタグ(#) */}
                        <div className="mb-1 h-[118px] w-[100%] px-[20px] py-[10px]">
                          <p className="h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            投稿に含まれるハッシュタグ(#)
                          </p>
                          <div className="flex gap-[16px] pt-2">
                            <Input
                              label=""
                              style={{ width: '100%' }}
                              isPlaceHolderRight={false}
                              placeholder="キャプションに含めたい任意のハッシュタグ"
                              classnamelabel="block rounded-md border-0 py-1.5 pl-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={modalDatas.twitterIncludedHashTag}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                checkXHashTag(e.target.value);
                              }}
                              isError={modalDatas.twitterIncludedHashTagsError !== ''}
                            ></Input>
                            <Button
                              variant="secoundary"
                              className={addButtonClassName}
                              onClick={handleAddTwitterIncludedHashtags}
                              disabled={modalDatas.twitterIncludedHashTag.length === 0}
                            >
                              追加
                            </Button>
                          </div>
                          <div className="mt-1 flex space-x-2">
                            {modalDatas.twitterIncludedHashTags.map((tag, index) => {
                              return (
                                <div key={index}>
                                  <Badge
                                    color="gray"
                                    round="sm"
                                    size="xs"
                                    className="w-fit"
                                    isRemovable={true}
                                    handleRemove={() => {
                                      handleRemoveTwitterIncludedHashtags(index);
                                    }}
                                  >
                                    {tag}
                                  </Badge>
                                </div>
                              );
                            })}
                          </div>
                          <span className="pb-2 text-[14px] text-[#F00]">
                            {modalDatas.twitterIncludedHashTagsError || ''}
                          </span>
                        </div>
                        {/* 投稿に含めるアカウントタグ(メンション) */}
                        <div className="mb-1 h-[86px] w-[100%] px-[20px]">
                          <p className="mt-3 h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            投稿に含めるアカウントタグ(メンション)
                          </p>
                          <div className="flex gap-[16px] pt-2">
                            <Input
                              label=""
                              style={{ width: '100%' }}
                              isPlaceHolderRight={false}
                              placeholder="投稿に紐づける必須のアカウントタグ"
                              classnamelabel="block rounded-md border-0 py-1.5 pl-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                              value={modalDatas.twitterIncludedAccountTag}
                              onChange={(e: any) => {
                                checkXAccountTag(e.target.value);
                              }}
                              isError={modalDatas.twitterIncludedAccountTagError !== ''}
                            ></Input>
                          </div>
                          <span className="text-[14px] text-[#F00]">
                            {modalDatas.twitterIncludedAccountTagError || ''}
                          </span>
                        </div>
                        {/* 記載するURL */}
                        <div className="mx-auto mb-[5px] h-[86px] w-[100%] px-[20px]">
                          <p className="mx-auto mb-[8px] mt-[10px] h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            記載するURL
                          </p>
                          <div className="mx-auto flex h-[38px] w-[100%]">
                            <Input
                              label=""
                              style={{ width: '680px' }}
                              isPlaceHolderRight={false}
                              placeholder="記載するURL"
                              classnamelabel="mb-[8px] h-[40px] w-[100%] rounded-md border-[1px] border-gray-300 px-[13px] py-[9px] text-[14px] font-normal leading-5 text-gray-500 placeholder:text-gray-400"
                              value={modalDatas.providedUrl}
                              onChange={(e: any) => {
                                checkProvidedUrl(e.target.value);
                              }}
                              isError={modalDatas.providedUrlError !== ''}
                            ></Input>
                          </div>
                          <span className="text-[14px] text-[#F00]">{modalDatas.providedUrlError || ''}</span>
                        </div>
                        {/* レビュー */}
                        <div className="mx-auto mb-[5px] h-[100px] w-[100%] px-[20px]">
                          <p className="mx-auto mb-[8px] mt-[10px] h-[20px] w-[100%] text-[15px] font-semibold leading-5 text-gray-700">
                            レビュー
                          </p>

                          <div className="h-[90px] w-[100%]">
                            <TextArea
                              title=""
                              helperText=""
                              maxLength={140}
                              disabled={true}
                              className="mb-[8px] h-[58px] rounded-md border-[1px] border-gray-300 bg-gray-200 px-[13px] py-[19px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-gray-900"
                              rows={4}
                              value={combinedtwitterReviewValue}
                              width="w-[760px]"
                              onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                console.log(e.target.value);
                              }}
                              isError={modalDatas.twitterTotalCountError !== ''}
                            ></TextArea>
                            <span className="text-[14px] text-[#F00]">{modalDatas.twitterTotalCountError || ''}</span>
                            <p className="mx-auto w-[100%] text-right text-[14px] font-normal leading-6">
                              {!modalDatas.twitterTotalCountError && `あと${twitterReviewCount}文字`}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => {
                      save();
                    }}
                  >
                    保存する
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      close();
                    }}
                  >
                    キャンセル
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
