import clsx from 'clsx';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { CalendarItem } from '@/api/project';
import { ReactComponent as LeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as RightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { Badge } from '@/components/Elements/Badge';
import { Button } from '@/components/Elements/Button';
import { MainLayout } from '@/components/Layout/MainLayout';
import { PROJECT_TYPES } from '@/config';
import { ROUTES } from '@/config/path';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { fetchProjectCalenderInfo } from '@/hooks/ProjectInfo';
// import { projectCalendarType } from '@/types/project';

interface dayInformation {
  day: number;
  month: number;
  year: number;
  isToday: boolean;
  isLeft: boolean;
  isLastLeft: boolean;
  isLastRight: boolean;
}

interface projectBar {
  projectType: string;
  days: number;
  index: number;
  title: string;
  tag: string;
  project: CalendarItem;
  isHidden: boolean;
}

// TODO データの取得は案件カレンダー取得APIを使用する
export function Calendar(): React.ReactElement {
  const navigate = useNavigate();

  const [year, setYear] = useState(new Date().getFullYear());
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [daysInformation, setDaysInformation] = useState<dayInformation[][]>();
  // const [projectCalendar, setProjectCalendar] = useState<projectCalendarType[]>(); // TODO APIから取得する
  const [isExistProjectsData, setIsExistProjectsData] = useState<boolean>(false);
  const [projectCalendarBar, setProjectCalendarBar] = useState<projectBar[]>();
  const [calendaerData, setCalendaerData] = useState<CalendarItem[]>();

  const handleNewProject = () => {
    navigate(ROUTES.PROJECT);
  };

  // const handleGanttChart = () => {
  //   navigate(ROUTES.GANTT_CHART);
  // };

  const overWeekDisplayCalendarInformation = (
    data: CalendarItem,
    beforeWeekDate: Date,
    index: number,
    displayLastDate: Date
  ): projectBar[] => {
    const result: projectBar[] = [];
    // startDate = 週末 週末+1 => 次の週の日曜日
    beforeWeekDate.setDate(beforeWeekDate.getDate() + 1);
    const projectStartDate = `${beforeWeekDate.getFullYear()}-${
      beforeWeekDate.getMonth() + 1
    }-${beforeWeekDate.getDate()}`;
    beforeWeekDate.setDate(beforeWeekDate.getDate() + 6);

    if (displayLastDate.getTime() < beforeWeekDate.getTime()) {
      return result;
    }

    if (beforeWeekDate.getTime() < new Date(data.projectEndDate).getTime()) {
      // 週を跨いでいる
      result
        .concat(overWeekDisplayCalendarInformation(data, beforeWeekDate, index, displayLastDate))
        .forEach((overProject) => {
          result.push(overProject);
        });
    }
    result.push({
      projectType: data.projectType,
      days: 0,
      index: index,
      title: data.projectName,
      tag: data.projectGroupName == null ? '' : data.projectGroupName,
      project: {
        projectId: data.projectId,
        projectName: data.projectName,
        projectType: data.projectType,
        projectStartDate: projectStartDate,
        projectEndDate: data.projectEndDate,
        projectGroupName: data.projectGroupName,
        isHidden: data.isHidden,
      } as CalendarItem,
      isHidden: data.isHidden,
    });
    return result;
  };

  const getDate = (dateString: string): Date => {
    const tmp = dateString.split('-');
    return new Date(+tmp[0], +tmp[1] - 1, +tmp[2]);
  };

  const calcDisplayIndex = (currentProject: CalendarItem, compareProjects: CalendarItem[]): number => {
    // 表示するインデックスを求める
    let result = 0;
    [
      PROJECT_TYPES.EMERALD_POST,
      PROJECT_TYPES.SPIRIT,
      PROJECT_TYPES.PRST,
      PROJECT_TYPES.JANE_JOHN,
      PROJECT_TYPES.OTHER,
    ].forEach((projectType) => {
      compareProjects
        .filter((project) => project.projectType === projectType)
        .forEach((project) => {
          if (project.isHidden) {
            return;
          }
          if (project.projectId === currentProject.projectId) {
            // projectのIDが同じ場合、スキップ
            return;
          }
          const projectStartTime = getDate(project.projectStartDate).getTime();

          const currentProjectEndTime = getDate(currentProject.projectEndDate).getTime();
          if (projectStartTime < currentProjectEndTime) {
            result++;
          }
        });
    });
    return result;
  };

  const displayCalendarInformation = (data: CalendarItem[], displayFirstDate: Date, displayLastDate: Date) => {
    const tmpCalendarBar: projectBar[] = [];
    const compareProjects: CalendarItem[] = [];
    [
      PROJECT_TYPES.EMERALD_POST,
      PROJECT_TYPES.SPIRIT,
      PROJECT_TYPES.PRST,
      PROJECT_TYPES.JANE_JOHN,
      PROJECT_TYPES.OTHER,
    ].forEach((projectType) => {
      data
        .filter((project) => project.projectType === projectType)
        .forEach((project: CalendarItem) => {
          if (project.isHidden) {
            // 非表示の場合、スキップ
            return;
          }

          // APIの戻り値を単純に指定すると時間が9時間ずれるため、ここで調整
          // new Dateの月は１月で0, 12月で11を指定する必要があるので、-1する
          const startDate = project.projectStartDate.split('-');
          const projectStartDate = new Date(+startDate[0], +startDate[1] - 1, +startDate[2]);
          const endDate = project.projectEndDate.split('-');
          const projectEndDate = new Date(+endDate[0], +endDate[1] - 1, +endDate[2]);
          if (
            !(
              displayLastDate.getTime() < projectStartDate.getTime() ||
              projectEndDate.getTime() < displayFirstDate.getTime()
            )
          ) {
            // カレンダーのにデータある場合、カレンダーを表示するフラグをtrue
            setIsExistProjectsData(true);
          } else {
            // カレンダーに表示しないデータはスキップ
            return;
          }

          // 表示するインデックスを求める
          const dispalyIndex = calcDisplayIndex(project, compareProjects);

          //  週末を取得する
          const daysUntilLastDay = 6 - projectStartDate.getDay();
          const weekendDate = new Date(
            projectStartDate.getFullYear(),
            projectStartDate.getMonth(),
            projectStartDate.getDate() + daysUntilLastDay
          );
          // 週を跨いでいる場合、データを複製する
          if (weekendDate.getTime() < projectEndDate.getTime()) {
            // 週を跨いでいる

            overWeekDisplayCalendarInformation(project, weekendDate, dispalyIndex, displayLastDate).forEach(
              (overProject) => {
                tmpCalendarBar.push(overProject);
              }
            );
          }
          tmpCalendarBar.push({
            projectType: projectType,
            days: 0,
            index: dispalyIndex,
            title: project.projectName,
            tag: project.projectGroupName == null ? '' : project.projectGroupName,
            project: project,
            isHidden: project.isHidden,
          });

          compareProjects.push(project);
        });
    });
    setProjectCalendarBar(tmpCalendarBar);
  };

  const displayProjectsData = (month: number, day: number, weekday: number): projectBar[] => {
    if (!projectCalendarBar) return [];
    const displayProjects: projectBar[] = [];
    projectCalendarBar.forEach((projectCalendar) => {
      const startDate = new Date(projectCalendar.project.projectStartDate);
      const startWeekday = startDate.getDay();
      const startMonth = startDate.getMonth() + 1;
      const startDay = startDate.getDate();
      const endDate = new Date(projectCalendar.project.projectEndDate);
      if (month == startMonth && day == startDay) {
        // 開始日が一致
        const weekendDate = startDate;
        weekendDate.setDate(weekendDate.getDate() + 6 - weekday);
        const days =
          weekendDate < endDate
            ? 7 - weekday
            : weekendDate.getDay() - endDate.getDay() == 0
            ? 0
            : endDate.getDay() - startWeekday + 1;
        console.log(startDate);
        console.log(startDate.getDay());
        console.log(weekendDate < endDate);
        console.log(projectCalendar.project.projectEndDate);
        console.log(weekendDate);
        console.log(endDate);
        console.log(days);
        if (days > 0) {
          displayProjects.push({
            projectType: projectCalendar.projectType,
            days: days,
            index: projectCalendar.index,
            title: projectCalendar.title,
            tag: projectCalendar.tag,
            project: projectCalendar.project,
            isHidden: projectCalendar.isHidden,
          });
        }
      }
    });
    return displayProjects;
  };

  const countHiddenProjects = (year: number, month: number, day: number): number => {
    if (!calendaerData) return 0;
    const targetTime = new Date(year, month - 1, day).getTime();
    let counts = 0;
    calendaerData.forEach((projectCalendar) => {
      if (!projectCalendar.isHidden) {
        return;
      }
      const startTime = new Date(projectCalendar.projectStartDate);

      startTime.setDate(startTime.getDate() - 1);
      const endTime = new Date(projectCalendar.projectEndDate).getTime();

      if (startTime.getTime() < targetTime && targetTime < endTime) {
        counts++;
      }
    });
    return counts;
  };

  useEffect(() => {
    const displayDate = new Date(year, month - 1, 1);
    const lastDate = new Date(year, month, 0);
    const lastWeek = lastDate.getDay();

    displayDate.setDate(displayDate.getDate() - displayDate.getDay());
    lastDate.setDate(lastDate.getDate() + (6 - lastWeek));
    // 表示する日数が作成される
    const displayDays = Math.floor((lastDate.getTime() - displayDate.getTime()) / 86400000);
    const daysPerWeek: dayInformation[][] = [];
    let week: dayInformation[] = [];
    const displayFirstDate: Date = displayDate;
    let displayLastDate: Date = displayDate;
    for (let i = 0; i <= displayDays; i++) {
      if (i > 0 && i % 7 == 0) {
        daysPerWeek.push(week);
        week = [];
      }
      const date = new Date(displayDate.getFullYear(), displayDate.getMonth(), displayDate.getDate() + i);
      displayLastDate = date;
      week.push({
        day: date.getDate(),
        month: date.getMonth() + 1,
        year: date.getFullYear(),
        isToday: date.getDate() == new Date().getDate() && date.getMonth() == new Date().getMonth(),
        isLeft: week.length == 0,
        isLastLeft: false,
        isLastRight: false,
      });
    }
    if (week.length > 0) {
      week[0].isLastLeft = true;
      week[week.length - 1].isLastRight = true;
      daysPerWeek.push(week);
    }
    // 案件情報を表示する処理
    setDaysInformation(daysPerWeek);

    fetchProjectCalenderInfo(year, month).then((data: CalendarItem[]) => {
      setIsExistProjectsData(false);
      if (data.length > 0) {
        setCalendaerData(data);
        displayCalendarInformation(data, displayFirstDate, displayLastDate);
      } else {
        setProjectCalendarBar(undefined);
      }
    });
  }, [month, year]);

  const handleCurrentMonth = () => {
    setMonth(new Date().getMonth() + 1);
    setYear(new Date().getFullYear());
  };

  const handlePrevMonth = () => {
    if (month == 1) {
      setMonth(12);
      setYear(year - 1);
    } else {
      setMonth(month - 1);
    }
  };

  const handlenextMonth = () => {
    if (month == 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  const emptyDataContent = () => {
    return (
      <div className="flex  rounded-b-lg border-x border-b border-gray-200 bg-white">
        <div className="flex h-[552px] w-[1149px] flex-1  items-center justify-center self-stretch">
          <span className="whitespace-pre-wrap text-center text-base font-normal leading-6 text-gray-500">
            指定された期間の案件がありません。
          </span>
        </div>
      </div>
    );
  };

  const planElement = (
    projectType: string,
    index: number,
    days: number,
    title: string,
    tag: string,
    projectId: string
  ) => {
    const color =
      projectType === PROJECT_TYPES.EMERALD_POST
        ? 'green'
        : projectType === PROJECT_TYPES.SPIRIT
        ? 'pink'
        : projectType === PROJECT_TYPES.JANE_JOHN
        ? 'blue'
        : projectType === PROJECT_TYPES.PRST
        ? 'yellow'
        : 'gray';
    const backgroundColor =
      projectType === PROJECT_TYPES.EMERALD_POST
        ? 'bg-green-400'
        : projectType === PROJECT_TYPES.SPIRIT
        ? 'bg-pink-400'
        : projectType === PROJECT_TYPES.JANE_JOHN
        ? 'bg-blue-400'
        : projectType === PROJECT_TYPES.PRST
        ? 'bg-yellow-400'
        : 'bg-gray-400';

    const top = index == 0 ? 'top-[36px]' : index == 1 ? 'top-[63px]' : 'top-[90px]';

    const handleRedirect = () => {
      if (projectType === PROJECT_TYPES.SPIRIT) {
        navigate(`/spirit?type=SPIRIT&projectId=${projectId}`);
      } else if (projectType === PROJECT_TYPES.EMERALD_POST) {
        navigate(`/emerald_project?type=EmeraldPost&projectId=${projectId}`);
      } else if (projectType === PROJECT_TYPES.PRST) {
        navigate(`/PRST_project?type=PRST&projectId=${projectId}`);
      } else if (projectType === PROJECT_TYPES.JANE_JOHN) {
        navigate(`/jane_john?type=JANEJOHN&projectId=${projectId}`);
      } else if (projectType === PROJECT_TYPES.OTHER) {
        navigate(`/other_project?type=OTHER`);
      }
    };
    return (
      <button
        className={clsx('absolute z-[30] flex h-6 items-center rounded-[4px] px-2', backgroundColor, top)}
        style={{ width: 164 * days }}
        onClick={handleRedirect}
      >
        <div className="flex items-center justify-between truncate" style={{ width: 164 * days }}>
          <div className="flex items-center gap-2 whitespace-nowrap">
            <span className="text-sm font-semibold leading-5 text-white">{title}</span>
            <span className="text-xs font-normal leading-4 text-white">{tag}</span>
          </div>
          <Badge color={color} size="sm" round="sm" className="h-4 p-2">
            {projectType}
          </Badge>
        </div>
      </button>
    );
  };

  const calenderDay = (day: dayInformation, index: number) => {
    const displayData = displayProjectsData(day.month, day.day, index);
    const hiddenProjectCounts = countHiddenProjects(day.year, day.month, day.day);
    // const hiddenProjectCounts = 0;
    return (
      <div className="relative">
        <div
          className={clsx(
            'flex h-[151px] w-[164px] items-start justify-end self-stretch border-b border-r border-gray-200 pr-2 pt-[6px]',
            day.isLeft && 'border-l',
            day.isLastLeft && 'rounded-bl-lg',
            day.isLastRight && 'rounded-br-lg'
          )}
        >
          {day.isToday ? (
            <div className="flex h-6 w-6 flex-col items-center justify-center rounded-[20px] bg-[#007CC2] ">
              <span className="text-sm font-medium leading-4 text-white">{day.day}</span>
            </div>
          ) : (
            <span className="text-sm font-medium leading-4 text-gray-500">{day.day}</span>
          )}
        </div>
        {displayData.map((project, index) => {
          return (
            <div key={index}>
              {planElement(
                project.projectType,
                project.index,
                project.days,
                project.title,
                project.tag,
                project.project.projectId
              )}
            </div>
          );
        })}
        {hiddenProjectCounts > 0 && (
          <div className="absolute bottom-0 w-[162px] px-2 py-[3px]">
            {/* <button
              onClick={() => {
                navigate(ROUTES.GANTT_CHART, { state: { year: day.year, month: day.month } });
              }}
            > */}
            <span className="text-xs font-medium leading-4 text-[#212121]">他{hiddenProjectCounts}件</span>
            {/* </button> */}
          </div>
        )}
      </div>
    );
  };

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.calendar,
        secondSideBarselected: secondSidebarSelect.schedule,
      }}
    >
      <div id="schedule" className="flex w-[1181px] flex-col items-start gap-6 bg-gray-50 pb-0">
        <div className="flex h-[76px] flex-col items-start  border-b border-gray-200">
          <div className="flex w-[1181px] flex-1 px-4">
            <div className="flex flex-1 items-center justify-between">
              <div className="flex items-center gap-[10px]" id="left">
                {/*<Button
                  variant="white"
                  className="flex h-[30px] w-[106px] items-center justify-center"
                  size="xsNopadding"
                  onClick={handleGanttChart}
                >
                  <span className="text-xs font-normal leading-4 text-gray-500">ガントチャート</span>
                </Button> */}
                <Button
                  variant="white"
                  className="flex h-[30px] w-[46px] items-center justify-center"
                  size="xsNopadding"
                  onClick={handleCurrentMonth}
                >
                  <span className="text-xs font-normal leading-4 text-gray-500">今月</span>
                </Button>
                <div className="flex rounded-md">
                  <Button
                    variant="white"
                    className="inline-flex h-[30px] w-[38px] items-center justify-center"
                    size="xsNopadding"
                    onClick={handlePrevMonth}
                  >
                    <LeftIcon width={20} height={20} fill="#6B7280" />
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex h-[30px] w-[38px] items-center justify-center"
                    size="xsNopadding"
                    onClick={handlenextMonth}
                  >
                    <RightIcon width={20} height={20} fill="#6B7280" />
                  </Button>
                </div>
                <span className="text-lg font-semibold leading-6 text-gray-500">
                  {year}年{month}月
                </span>
              </div>
              <div className="" id="right">
                <Button
                  size="sm"
                  className="h-9 w-[210px] font-bold"
                  startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
                  onClick={handleNewProject}
                >
                  新しい案件を作成
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div id="calender">
          <div className="flex flex-col items-center self-center px-4">
            <div className="flex items-start self-stretch rounded-t-lg border border-gray-200 text-center">
              <div className="w-[164px] rounded-tl-lg border-r border-gray-200 bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">日</span>
              </div>
              <div className="w-[164px] border-r border-gray-200 bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">月</span>
              </div>
              <div className="w-[164px] border-r border-gray-200 bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">火</span>
              </div>
              <div className="w-[164px] border-r border-gray-200 bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">水</span>
              </div>
              <div className="w-[164px] border-r border-gray-200 bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">木</span>
              </div>
              <div className="w-[164px] border-r border-gray-200 bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">金</span>
              </div>
              <div className="w-[164px] rounded-tr-lg  bg-white py-2">
                <span className="text-xs font-normal leading-4 text-gray-500">土</span>
              </div>
            </div>
            {isExistProjectsData ? (
              <div className="bg-white">
                {daysInformation &&
                  daysInformation.map((week, index) => {
                    return (
                      <div key={index} className="flex">
                        {week.map((day, index) => {
                          return <div key={index}>{calenderDay(day, index)}</div>;
                        })}
                      </div>
                    );
                  })}
              </div>
            ) : (
              emptyDataContent()
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
