import React from 'react';

import { PROJECT_TYPES } from '@/config/index';

export function listHeader(projectType: string): React.ReactElement {
  const title = () => {
    if (projectType === PROJECT_TYPES.SPIRIT) {
      return 'SPIRIT案件 分析一覧';
    } else if (projectType === PROJECT_TYPES.EMERALD_POST) {
      return 'EMERALD POST案件 分析一覧';
    } else if (projectType === PROJECT_TYPES.JANE_JOHN) {
      return 'JANE JOHN案件 分析一覧';
    } else if (projectType === PROJECT_TYPES.OTHER) {
      return 'その他の案件 分析一覧';
    } else {
      return '';
    }
  };
  return (
    <div>
      <div className="flex h-[60px] w-[1149px] border-b py-[11px]">
        <div className="flex flex-row items-center">
          <div className="flex h-[28px] flex-row items-center pl-4">
            <span className="text-base font-semibold leading-7 text-gray-700">{title()}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
