/** 選択肢に関する設定 */
import { SelectProps } from '../interfaces/project';

import { PROJECT_GOAL, PROJECT_IMAGE, PROJECT_IMPORTANT } from './path';

/** 今回の施策で達成したいことは何ですか？の選択肢 */
export const goalsCombination: SelectProps[] = [
  {
    label: '商品・ブランドのことを広く知ってもらいたい',
    value: PROJECT_GOAL.REACH,
  },
  {
    label: '商品・ブランドのエンゲージメントを高めたい',
    value: PROJECT_GOAL.ENGAGEMENT,
  },
  {
    label: '自社SNSのフォロワーを増やしたい',
    value: PROJECT_GOAL.FOLLOWER,
  },
  {
    label: 'インタビューなどの調査をしたい',
    value: PROJECT_GOAL.INTERVIEW,
  },
];

/** 具体的な施策のイメージを教えてくださいの選択肢 */
export const imagesReachCombination: SelectProps[] = [
  {
    label: 'とにかく多くの人に知ってもらいたい（質よりも量が重要）',
    value: PROJECT_IMAGE.IMPORTANT_QUANTITY,
  },
  {
    label: '親和性のある人に知ってもらいたい（量よりも質が重要）',
    value: PROJECT_IMAGE.IMPORTANT_QUALITY,
  },
];

export const imagesEngagementCombination: SelectProps[] = [
  {
    label: 'インフルエンサーにPR投稿をお願いしたい',
    value: PROJECT_IMAGE.PR_BY_INFLUENCER,
  },
  {
    label: '自社のSNSアカウントを活用したい',
    value: PROJECT_IMAGE.TAKE_ADVANTAGE_OF_OWN_ACCOUNT,
  },
  {
    label: 'インフルエンサーをファン化させたい',
    value: PROJECT_IMAGE.CO_CREATE_INFLUENCER,
  },
];

export const imagesIncreaseFollowerCombination: SelectProps[] = [
  {
    label: '自社のSNSアカウントの運用をしてほしい',
    value: PROJECT_IMAGE.OPERATE_OWN_SNS_ACCOUNT,
  },
  /*{
    label: 'SNSキャンペーンの運用をお願いしたい',
    value: PROJECT_IMAGE.OPERATE_SNS_CAMPAIGN,
  },*/
];

export const imagesReasearchCombination: SelectProps[] = [
  {
    label: 'その分野に詳しい人の具体的なアドバイスが欲しい（量よりも質が重要）',
    value: PROJECT_IMAGE.ADVICE_IMPORTANT_QUALITY,
  },
  {
    label: 'とにかく多くのインフルエンサーの意見を集めたい（質よりも量が重要）',
    value: PROJECT_IMAGE.ADVICE_IMPORTANT_QUANTITY,
  },
];

/** 次のうち、最も重要視したいものを教えてください */
// とにかく多くの人に知ってもらいたい（質よりも量が重要）
export const importantQuantityCombination: SelectProps[] = [
  {
    label: 'コスパを重視したい',
    value: PROJECT_IMPORTANT.COST_PERFORMANCE,
  },
  {
    label: '話題性を重視したい',
    value: PROJECT_IMPORTANT.COLLECT_FEATURED,
  },
];

// 親和性のある人にしってもらいたい（量よりも質が重要）
export const importantQualityCombination: SelectProps[] = [
  {
    label: 'たくさんのインフルエンサーにお願いしたい',
    value: PROJECT_IMPORTANT.PR_BY_MANY_INFLUENCERS,
  },
  {
    label: '関係性を持ったインフルエンサーに継続的にお願いしたい',
    value: PROJECT_IMPORTANT.PR_BY_COMPATIBILITY_INFLUENCERS,
  },
  {
    label: '自社のSNSアカウントにユーザーを集客したい',
    value: PROJECT_IMPORTANT.ATTRACTING_CUSTOMERS_OPERATING_SNS_ACCOUNT,
  },
];

// インフルエンサーにPR投稿をお願いしたい
export const importantIncreaseFollowerCombination: SelectProps[] = [
  {
    label: 'たくさんのインフルエンサーにお願いしたい',
    value: PROJECT_IMPORTANT.PR_BY_MANY_INFLUENCERS,
  },
  {
    label: '関係性を持ったインフルエンサーに継続的にお願いしたい',
    value: PROJECT_IMPORTANT.PR_BY_INFLUENCER_HAS_MANY_FOLLOWER,
  },
];

// その分野に詳しい人の具体的なアドバイスが欲しい（量よりも質が重要）
export const importantAdviceCombination: SelectProps[] = [
  {
    label: '自社のSNS施策についてアドバイスが欲しい',
    value: PROJECT_IMPORTANT.ADVICE_FOR_OPERATING_SNS_ACCOUNT,
  },
  {
    label: 'インフルエンサーの生の声が聞きたい',
    value: PROJECT_IMPORTANT.INTERVIEW_INFLUENCERS,
  },
];
