import React from 'react';

import { Input } from '@/components/Elements/Input/Input';
import { isMorehanValue, validationHalfWidthCheck, validationIntegerCheck } from '@/utils/inputMultiValidationCheck';
import { MessageNewPlanString } from '@/utils/message';

import budget from '../../assets/budget.png';
import { FormProps } from '../../interfaces/project';
import { getBudgetSpan } from '../../utils/logic';
import { Recomend } from '../Recomend';

export default function Budget(props: FormProps): React.ReactElement {
  const budgetSpan = getBudgetSpan(props.payload);
  const validationCheck = (value: string) => {
    const minNum = budgetSpan.minCost;
    let isError = false;
    let errorMessage = '';
    let setValue: number | undefined = undefined;
    if (!validationHalfWidthCheck(value) || !validationIntegerCheck(Number(value))) {
      isError = true;
      errorMessage = MessageNewPlanString.integralMessage;
    } else if (!isMorehanValue(+value, minNum)) {
      isError = true;
      errorMessage = MessageNewPlanString.lowestBudgetMessage;
      setValue = +value;
    } else {
      setValue = value == '' ? undefined : +value;
    }
    props.setPayload((state) => {
      return {
        ...state,
        budget: setValue,
        isBudgetError: isError,
        budgetErrorMessage: errorMessage,
      };
    });
  };

  return (
    <div className="mx-auto flex w-[800px] flex-col items-center pt-12">
      <div className="pt-12">
        {Recomend({
          src: budget,
          title: '推奨金額',
          value: budgetSpan.averageCost.toLocaleString(),
          unit: '円',
          description: '推奨金額は他社を含めた過去の案件実施の実績に基づいて算出されています。',
        })}
      </div>

      <div className="mx-auto flex w-[800px] flex-col items-center justify-center gap-y-[20px]  pt-[32px] text-xs font-normal leading-5">
        <span className="text-xs font-normal leading-5 text-gray-400">
          最低金額：{budgetSpan.minCost.toLocaleString()} 円
        </span>
        <span className="text-xs font-normal leading-5 text-blue-500">
          推奨金額：{budgetSpan.averageCost.toLocaleString()} 円
        </span>
        <span className="text-xs font-normal leading-5 text-gray-400">
          最大金額：{budgetSpan.recommendationCost.toLocaleString()} 円
        </span>
      </div>

      <div className="flex items-center justify-center gap-4 pt-10">
        <div>
          <span className="text-sm font-normal leading-8 text-gray-600">今回の施策にかける金額</span>
        </div>
        <div>
          <span>
            <Input
              type="text"
              className="h-[48px] gap-2 rounded border-gray-300 text-2xl text-gray-500 outline-none focus:border-white"
              onChange={(e: any) => {
                validationCheck(e.target.value.replace(/[^\d.]/g, ''));
              }}
              value={props.payload.budget ? props.payload.budget.toLocaleString() : ''}
              width="160px"
            ></Input>
          </span>
        </div>
        <div>
          <span className="text-sm font-normal leading-8 text-gray-600">円</span>
        </div>
      </div>
      {props.payload.isBudgetError && (
        <span className="m-auto flex w-[800px] justify-center pl-[135px] pt-[10px] text-red-400">
          {props.payload.budgetErrorMessage}
        </span>
      )}
    </div>
  );
}
