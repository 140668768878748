import instagram from '@/assets/instagram.png';
import tiktok from '@/assets/tiktok.png';
import x from '@/assets/TwitterLogo.png';
import youtube from '@/assets/youtube.png';
import { Badge } from '@/components/Elements/Badge';
import { PROJECT_TYPES } from '@/config';
import {
  convertStatusToDisplayLabelForSpirit,
  convertStatusToDisplayLabelForEmeraldPost,
  convertStatusToDisplayLabelForPrst,
  convertStatusToDisplayLabelForJaneJohn,
} from '@/utils/projectUtils';

export function projectLabel(projectType: string) {
  let projectTypeText = '';
  switch (projectType) {
    case PROJECT_TYPES.OTHER:
      projectTypeText = 'その他';
      break;
    case PROJECT_TYPES.SPIRIT:
      projectTypeText = 'SPIRIT';
      break;
    default:
      projectTypeText = projectType;
  }

  return (
    <div className="inline-block items-center">
      <Badge color={getColorByProjectType(projectType)} size="xs" round="normal" className="px-[10px]">
        {projectTypeText}
      </Badge>
    </div>
  );
}

const getColorByProjectType = (projectType: string) => {
  if (projectType === PROJECT_TYPES.SPIRIT) {
    return 'pink';
  } else if (projectType === PROJECT_TYPES.EMERALD_POST) {
    return 'green';
  } else if (projectType === PROJECT_TYPES.PRST) {
    return 'purple';
  } else if (projectType === PROJECT_TYPES.JANE_JOHN) {
    return 'blue';
  } else if (projectType === PROJECT_TYPES.OTHER) {
    return 'gray';
  } else {
    return 'gray';
  }
};

/**
 * APIのステータスを和名ラベルに変換する
 * @param status ステータス APIの値
 * @param projectType 案件種別
 * @returns ステータスを表示するラベルに変換する
 */
const convertStatusToDisplayLabel = (status: string, projectType: string): string => {
  if (projectType === PROJECT_TYPES.SPIRIT) {
    return convertStatusToDisplayLabelForSpirit(status);
  } else if (projectType === PROJECT_TYPES.EMERALD_POST) {
    return convertStatusToDisplayLabelForEmeraldPost(status);
  } else if (projectType === PROJECT_TYPES.PRST) {
    return convertStatusToDisplayLabelForPrst(status);
  } else {
    return convertStatusToDisplayLabelForJaneJohn(status);
  }
};

export function statusLabel(status: string, projectType: string) {
  const label = convertStatusToDisplayLabel(status, projectType).split('');
  const labelArray = label.slice(7, 10);
  let newLabel = '';
  for (let i = 0; i < labelArray.length; i++) {
    newLabel += labelArray[i];
  }

  if (status === 'draft' || status === 'post_draft' || status === 'review') {
    return (
      <Badge color="gray" size="xs" round="normal" className="w-[76px]">
        {newLabel}
      </Badge>
    );
  } else {
    return (
      <Badge color="gray" size="xs" round="normal" className="w-[76px]">
        {convertStatusToDisplayLabel(status, projectType)}
      </Badge>
    );
  }
}

export function snsIcon(snsType: string) {
  const lowerSnsType = snsType ? snsType.toLowerCase() : '';
  if (lowerSnsType === 'instagram') {
    return <img src={instagram} alt="instagram" className="m-0 h-[22px] w-[22px]" />;
  } else if (lowerSnsType === 'x') {
    return <img src={x} alt="x" className="m-0 h-[22px] w-[22px]" />;
  } else if (lowerSnsType === 'tik_tok') {
    return <img src={tiktok} alt="tiktok" className="m-0 h-[22px] w-[22px]" />;
  } else if (lowerSnsType === 'youtube') {
    return <img src={youtube} alt="youtube" className="m-0 h-[22px] w-[22px]" />;
  } else {
    return <></>;
  }
}

export function pagenationLabel(rows: number) {
  return (
    <div>
      <span className="text-[#374151]">1ページあたりの行数</span>
      <span>{rows.toString()}</span>
    </div>
  );
}
