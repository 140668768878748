import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import './assets/Swiper.css';
import { Video } from './Video';

export type SliderProps = {
  mediaDatas: string[];
  height: number;
  width: number;
};
export const Slider = (props: SliderProps) => {
  const heightCss = `h-[${props.height}px]`;
  const widthCss = `w-[${props.width}px]`;

  return (
    <div className={`${heightCss} ${widthCss}`}>
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true, el: '.swiper-pagination', type: 'bullets' }}
        spaceBetween={50}
        slidesPerView={'auto'}
        autoHeight
        direction={'horizontal'}
      >
        <div className={`flex items-center justify-center ${heightCss} ${widthCss}`}>
          {props.mediaDatas.map((src, index) => {
            const isVideo = src.endsWith('.mp4');
            const content = isVideo ? (
              <Video url={src} className={`${widthCss} ${heightCss}`} />
            ) : (
              <img src={src} alt={`media-${index}`} className={`${widthCss} ${heightCss} rounded-lg`} />
            );
            return <SwiperSlide key={index}>{content}</SwiperSlide>;
          })}
        </div>
      </Swiper>
      <div className="swiper-pagination" />
    </div>
  );
};
