import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';

import NoImage from '@/assets/images/NoImage.png';

import Arrow_Left from '../common/Pic/Arrow_Left.png';
import Arrow_Right from '../common/Pic/Arrow_Right.png';

import { Slider } from './Parts/Slider';

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  accountInfo: any; // InstagramAccoutInfoProps[] | TiktokAccoutInfoProps[];
  modalIndex: number;
  setModalIndex: React.Dispatch<React.SetStateAction<number>>;
  Component_Element: JSX.Element[];
  sns_name: string;
  caption?: string;
  deleteFlag?: boolean;
}

export default function Modal(props: ModalProps) {
  const handleClose = (event: any) => {
    // button の場合には props.setOpen()したくない
    if (event.target.name == undefined) {
      props.setOpen(false);
    }

    return;
  };

  const moveRight = () => {
    if (props.modalIndex < props.accountInfo.length - 1) {
      props.setModalIndex(props.modalIndex + 1);
    }
    return;
  };
  const moveLeft = () => {
    if (props.modalIndex > 0) {
      props.setModalIndex(props.modalIndex - 1);
    }
    return;
  };

  const instagram_dialog_css =
    'relative w-[937px] overflow-hidden rounded-lg bg-white pb-4  text-left shadow-xl transition-all sm:w-full sm:max-w-[937px] sm:p-6';
  const tiktok_dialog_css =
    'relative w-[1090px] rounded-lg bg-white pb-4  text-left shadow-xl transition-all sm:w-full sm:max-w-[1090px] sm:p-6';
  const youtube_dialog_css =
    'relative w-[1097px] overflow-hidden rounded-lg bg-white pb-4  text-left shadow-xl transition-all sm:w-full sm:max-w-[950px] sm:p-6';
  const twitter_dialog_css =
    'relative w-[1050px] overflow-hidden rounded-lg bg-white pb-4  text-left shadow-xl transition-all sm:w-full sm:max-w-[1050px] sm:p-6';
  const insta_pic_height_css = 'w-[280px] rounded-lg';
  const twitter_pic_height_css = 'w-[280px] rounded-lg';
  const tiktok_pic_height_css = 'w-[280px] rounded-lg';
  const youtube_pic_height_css = 'w-[280px] rounded-lg';
  let dialog_css = '';
  let pic_height_css = insta_pic_height_css;

  if (props.sns_name == 'instagram') {
    dialog_css = instagram_dialog_css;
    pic_height_css = insta_pic_height_css;
  } else if (props.sns_name == 'tiktok') {
    dialog_css = tiktok_dialog_css;
    pic_height_css = tiktok_pic_height_css;
  } else if (props.sns_name == 'youtube') {
    dialog_css = youtube_dialog_css;
    pic_height_css = youtube_pic_height_css;
  } else if (props.sns_name == 'twitter') {
    dialog_css = twitter_dialog_css;
    pic_height_css = twitter_pic_height_css;
  }

  // swiperのpagination
  const addIndex = 100;

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10 border-none outline-none"
        onClose={() => {
          return;
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-90"
          leave="ease-in duration-200"
          leaveFrom="opacity-90"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 border-none bg-gray-500 bg-opacity-[75%] outline-none transition-opacity" />
        </Transition.Child>

        <div
          className="fixed inset-0 z-10 overflow-y-auto border-0 border-none pb-[400px] pt-[140px] outline-none"
          role="presentation"
          onClick={(event) => handleClose(event)}
        >
          <div className="flex min-h-full items-end justify-center border-0 border-none text-center outline-none sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-90 translate-y-0 sm:scale-90"
              leave="ease-in duration-200"
              leaveFrom="opacity-90 translate-y-0 sm:scale-90"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className={dialog_css}>
                <div className="flex gap-[15px]">
                  {/* modal の内容ここから */}
                  <span className="pb-[10px]">
                    <div>
                      <div className={pic_height_css}>
                        {props.sns_name == 'instagram' ? (
                          <span>
                            {props.accountInfo[props.modalIndex]?.mediaType === 'VIDEO' ? (
                              <div className="pt-[6px]">
                                <video controls className="h-full w-full rounded-lg">
                                  <source src={props.accountInfo[props.modalIndex].postVideo} type="video/mp4" />
                                  <track
                                    src="url_of_your_caption_track.vtt"
                                    kind="captions"
                                    srcLang="en"
                                    label="English captions"
                                  />
                                  お使いのブラウザは動画をサポートしていません。
                                </video>
                              </div>
                            ) : props.accountInfo[props.modalIndex]?.mediaType === 'IMAGE' ? (
                              <div className="pt-[6px]">
                                <img
                                  src={props.accountInfo[props.modalIndex]?.postImage}
                                  alt=""
                                  className="h-full w-full rounded-lg"
                                />
                              </div>
                            ) : props.accountInfo[props.modalIndex]?.mediaType === 'CAROUSEL_ALBUM' ? (
                              <div className="pt-[6px]">
                                <Slider
                                  mediaDatas={props.accountInfo[props.modalIndex]?.postCarousel}
                                  index={props.modalIndex + addIndex}
                                  width={617}
                                  height={400}
                                />
                              </div>
                            ) : (
                              <div className="pt-[6px]">
                                <img src={NoImage} alt="" className="h-full w-full rounded-lg" />
                              </div>
                            )}
                          </span>
                        ) : props.sns_name == 'twitter' ? (
                          <span>
                            {props.accountInfo[props.modalIndex].mediaType === 'video' ? (
                              <div className="pt-[6px]">
                                <video controls className="h-full w-full rounded-lg">
                                  <source src={props.accountInfo[props.modalIndex].postVideo} type="video/mp4" />
                                  <track
                                    src="url_of_your_caption_track.vtt"
                                    kind="captions"
                                    srcLang="en"
                                    label="English captions"
                                  />
                                  お使いのブラウザは動画をサポートしていません。
                                </video>
                              </div>
                            ) : props.accountInfo[props.modalIndex].mediaType === 'photo' ? (
                              <div className="pt-[6px]">
                                <img
                                  src={props.accountInfo[props.modalIndex].postImage}
                                  alt=""
                                  className="h-full w-full rounded-lg"
                                />
                              </div>
                            ) : props.accountInfo[props.modalIndex].mediaType === 'multipleMedia' ? (
                              <div className="pt-[6px]">
                                <Slider
                                  mediaDatas={props.accountInfo[props.modalIndex].postMedias}
                                  index={props.modalIndex + addIndex}
                                  width={617}
                                  height={400}
                                />
                              </div>
                            ) : (
                              <div className="pt-[6px]">
                                <img src={NoImage} alt="" className="h-full w-full rounded-lg" />
                              </div>
                            )}
                          </span>
                        ) : props.sns_name == 'tiktok' ? (
                          <span>
                            {props.accountInfo[props.modalIndex].video === true ? (
                              <div>
                                <div>
                                  <img
                                    src={props.accountInfo[props.modalIndex].postImage}
                                    alt=""
                                    className="h-full w-full rounded-lg"
                                  />
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div>
                                  <img
                                    src={props.accountInfo[props.modalIndex].postImage}
                                    alt=""
                                    className="h-full w-full rounded-lg"
                                  />
                                </div>
                              </div>
                            )}
                          </span>
                        ) : props.sns_name == 'youtube' ? (
                          <span>
                            {props.accountInfo[props.modalIndex].video === true ? (
                              <div>
                                <div>
                                  <img
                                    src={props.accountInfo[props.modalIndex].postImage}
                                    alt=""
                                    className="h-full w-full rounded-lg"
                                  />
                                </div>
                                <div className=" mt-2 w-[230px]">{props.accountInfo[props.modalIndex].videoTitle}</div>
                              </div>
                            ) : (
                              <div>
                                <div>
                                  <img
                                    src={props.accountInfo[props.modalIndex].postImage}
                                    alt=""
                                    className="h-full w-full rounded-lg"
                                  />
                                </div>
                                <div className="mt-2 w-[230px]">
                                  {props.accountInfo[props.modalIndex].videoTitle}
                                  <br />
                                </div>
                              </div>
                            )}
                          </span>
                        ) : (
                          <div></div>
                        )}
                        <div className="mt-6 whitespace-pre-line pl-[15px] pt-[6px] text-sm leading-3">
                          {props.accountInfo[props.modalIndex].caption
                            .split(/[\s]/)
                            .map((value: string, index: number) => {
                              return (
                                <span key={index} className="block">
                                  {value}
                                </span>
                              );
                            })}
                        </div>

                        {props.sns_name === 'youtube' && (
                          <div className="mt-6 pl-[15px] pt-[6px] text-sm leading-3">
                            <Link
                              to={props.accountInfo[props.modalIndex].url}
                              target="_blank"
                              className="text-blue-500"
                            >
                              {props.accountInfo[props.modalIndex].url}
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                  </span>
                  <span>
                    {props.Component_Element.map((value, index) => {
                      if (index % 2 == 1) return;
                      return (
                        <div className="flex h-[115px] w-[700px] items-center gap-2 pb-[14px]" key={index}>
                          {props.Component_Element[index]}
                          {props.Component_Element[index + 1]}
                        </div>
                      );
                    })}
                  </span>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
          <div className="absolute left-5 top-1/2 ml-20 translate-y-[-50%] border-none outline-none">
            {props.modalIndex == 0 ? (
              <div className="h-[40px] w-[40px] pr-[35.8px]"></div>
            ) : (
              <button
                className="border-0 border-none pr-[35.8px] outline-none"
                name="ignore"
                onMouseDown={() => moveLeft()}
              >
                <img src={Arrow_Left} alt="" className="h-[40px] w-[40px]" />
              </button>
            )}
          </div>
          <div className="absolute right-5 top-1/2 mr-20 translate-y-[-50%] border-none outline-none">
            {(props.deleteFlag == true && props.modalIndex == 2) || // 上側のモーダルの右矢印削除の条件
            (props.deleteFlag == true && props.sns_name == 'twitter' && props.modalIndex == 0) || // 上側のモーダルの右矢印削除の条件
            props.modalIndex == props.accountInfo.length - 1 ? ( // 下側のモーダルの右矢印削除の条件
              <div></div>
            ) : (
              <button className="border-none pl-[35.8px] outline-none" name="ignore" onMouseDown={() => moveRight()}>
                <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
              </button>
            )}
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
