import clsx from 'clsx';
import { useState, createContext, Dispatch, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectStatus, ProjectType, SnsType } from '@/api/project';
import {
  ProjectImageRow,
  GetSpiritDetailOutput,
  SpiritSummaryOption,
  PutSpiritSummaryInput,
  PutSpiritSummaryInputGenderEnum,
  TaskInstagramFeed,
  TaskInstagramStories,
  TaskInstagramReel,
  TaskSampling,
  TaskBuying,
  TaskEvent,
  TaskWebApp,
  TaskXPost,
  TaskYoutubeFeed,
  TaskTikTokFeed,
} from '@/api/spirit';
import SpiritDetailBuyingIcon from '@/assets/icons/spirit_detail_buying_icon.png';
import SpiritDetailEventIcon from '@/assets/icons/spirit_detail_event_icon.png';
import SpiritDetailInstagramIcon from '@/assets/icons/spirit_detail_instagram_icon.png';
import SpiritDetailSampleIcon from '@/assets/icons/spirit_detail_sample_icon.png';
import SpiritDetailTiktokIcon from '@/assets/icons/spirit_detail_tiktok_icon.png';
import SpiritDetailWebAppIcon from '@/assets/icons/spirit_detail_webapp_icon.png';
import SpiritDetailXIcon from '@/assets/icons/spirit_detail_x_icon.png';
import SpiritDetailYoutubeIcon from '@/assets/icons/spirit_detail_youtube_icon.png';
import NoImage from '@/assets/images/NoImage.png';
import SmartPhone from '@/assets/images/SmartPhone.png';
import instagram from '@/assets/instagram.png';
import tiktok from '@/assets/tiktok.png';
import x from '@/assets/TwitterLogo.png';
import youtube from '@/assets/youtube.png';
import { Button } from '@/components/Elements';
import { Badge } from '@/components/Elements/Badge';
import { OptionMapType, OptionAllType } from '@/components/ProjectManagements/Applicant/Contents/common/Types/ArgsType';
import Estimate from '@/components/ProjectManagements/Components/Estimate';
import KpiComponent from '@/components/ProjectManagements/Components/KPI';
import { DetailImages } from '@/components/ProjectManagements/Modals/DetailImages';
import { FinalAgreeModal } from '@/components/ProjectManagements/Modals/FinalAgreeModal';
import Option from '@/components/ProjectManagements/Modals/Option';
import { ProjectOverview, ProjectOverviewProps } from '@/components/ProjectManagements/Modals/ProjectOverview';
import {
  ProjectRecruitingTitle,
  ProjectRecruitingState,
} from '@/components/ProjectManagements/Modals/ProjectRecruitingTitle';
import {
  SelectType,
  itemDetails,
  selectItem,
  SNS_MENUES,
} from '@/components/ProjectManagements/Modals/ProjectSplitOperationDetails/PopupInterfaces';
import {
  ProjectSplitOperationDetails,
  ProjectOperationDetailsProps,
} from '@/components/ProjectManagements/Modals/ProjectSplitOperationDetails/ProjectSplitOperationDetails';
import { PublicAttention, DetailSettings } from '@/components/ProjectManagements/Modals/PublicAttention';
import { useAssetDataJson, useAssetNumberJson } from '@/hooks/AssetDataJson';
import { insertPostApplicationInfo } from '@/hooks/project';
import {
  putSpiritNotice,
  putSpiritPostOption,
  putSpiritMedia,
  putSpiritTitle,
  putSpiritSummary,
  getSpiritDetail,
} from '@/hooks/SPIRIT';
import { getImageUrl } from '@/utils/image';
import { ValidationMessages } from '@/utils/message';

import { projectDetailSpirit } from '../../routes';
import ErrorImage from '../png/samples/bx-error-circle.svg';

export const PopupDetail = createContext({
  buttonState: {},
  buttonDispatch: {} as Dispatch<any>,
});

export const PopupProjectRecruitingTitle = createContext({
  projectRecruitingTitleState: {},
  projectRecruitingTitleDispatch: {} as Dispatch<any>,
});

export default function Recruitment(props: projectDetailSpirit) {
  const [finishAgreeModalIsOpen, setFinishAgreeModalIsOpen] = useState(false);
  const [agreeErrorMessage, setAgreeErrorMessage] = useState('');

  const selected: SelectType[] = [];

  const projectRecruitingTitleProps: ProjectRecruitingState = {
    open: false,
    title: '',
    sns: props.projectDetailSpirit.snsType,
    file: '',
    displayTitle: props.projectDetailSpirit.title ? props.projectDetailSpirit.title : '-',
    tags: [],
    tag: '',
    displayTags: props.projectDetailSpirit.tags ?? [],
    displayImage:
      props.projectDetailSpirit.featuredImage && props.projectDetailSpirit.featuredImage !== ''
        ? getImageUrl(props.projectDetailSpirit.featuredImage)
        : NoImage,
    projectName: 'spirit',
    titleError: false,
    tagError: false,
    imageError: false,
    validationTitleMessage: '',
    validationTagMessage: '',
    validationImageMessage: '',
  };

  const [projectTitleState, setProjectTitleState] = useState<ProjectRecruitingState>(projectRecruitingTitleProps);

  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const projectId: string = queryString.get('projectId') || '';
  const [projectStatus, setProjectStatus] = useState(
    props.projectDetailSpirit.header ? props.projectDetailSpirit.header.projectStatus : ProjectStatus.Draft
  );
  // 申請ボタン表示用のステート

  const [isRequesting, setIsRequesting] = useState(projectStatus !== ProjectStatus.Draft);

  // 案件概要用変数修正版
  const ProjectOverviewArgs: ProjectOverviewProps = {
    open: false,
    recruitmentPlanCount: 0,
    displayRecruitmentPlanCount: '0',
    rewardAmount: 0,
    displayRewardAmount: '0',
    tags: [],
    displayTags: [],
    tag: '',
    description: '',
    displayDescription: '',
    project: {
      displayAdoption: '0',
      adoption: '0',
      displayReward: '0',
      reward: '0',
      displayFollower: '1000',
      follower: '1000',
      displayGender: 'none',
      gender: 'none',
      displayMinAge: 'none',
      minAge: 'none',
      displayMaxAge: 'none',
      maxAge: 'none',
      displayMinIncome: 'none',
      minIncome: 'none',
      displayMaxIncome: 'none',
      maxIncome: 'none',
      displayJob: 'none',
      job: 'none',
    },
    projectName: ProjectType.Spirit,
    radioBoxValue: '0',
    displaySelectedPrefectures: [],
    selectedPrefectures: [],
    prefecturesRadioBoxValue: 'from_off',
    application_criteria: '',
  };

  const [projectOverviewProps, setProjectOverviewProps] = useState<ProjectOverviewProps>(ProjectOverviewArgs);
  const [displayRewards, setDisplayRewards] = useState<number | undefined>();
  //詳細画像用変数

  const [imageModalIsOpen, setImageModalIsOpen] = useState<boolean>(false);

  const [option, setOption] = useState<SpiritSummaryOption>({
    goodQuality: 0,
    secondaryUse: 1,
    adoptionOutsourced: 1,
  });

  // 稼働内容詳細用変数
  const operationDetailArgs = {
    open: false,
    snsMenuOpen: false,
    selected: selected,
    menuId: 0,
    sns: props.snsType,
    projectId: projectId,
  };

  const [operationDetailProps, setOperationDetailProps] = useState<ProjectOperationDetailsProps>(operationDetailArgs);

  // 詳細設定用変数

  const detailSettingArgs: DetailSettings = {
    open: false,
    projectType: ProjectType.Spirit,
    companyName: props.conpanyName,
    isSecret: false,
    notice: [],
  };
  const [detailSettingProps, setDetailSettingProps] = useState<DetailSettings>(detailSettingArgs);

  const initRecruitingTitle = (detail: GetSpiritDetailOutput) => {
    setProjectTitleState((prev) => ({
      ...prev,
      title: detail.title ? detail.title : '',
      displayTitle: detail.title ? detail.title : '',
      tags: detail.tags ? detail.tags : [],
      displayTags: detail.tags ? detail.tags : [],
      displayImage: detail.featuredImage ? getImageUrl(detail.featuredImage) : NoImage,
      sns: detail.snsType,
    }));
  };

  const initDetailImages = (detail: GetSpiritDetailOutput) => {
    props.setDetailImages(() => {
      const images: ProjectImageRow[] = [];
      detail.detailImages
        ? detail.detailImages.forEach((image) => {
            images.push({
              id: image.id,
              objectKey: getImageUrl(image.objectKey),
            });
          })
        : [];
      return images;
    });
  };

  const initOverview = (detail: GetSpiritDetailOutput) => {
    setProjectOverviewProps((prev) => ({
      ...prev,
      radioBoxValue:
        detail.summary && detail.summary.rewardsType ? detail.summary.rewardsType.toString() : prev.radioBoxValue,
      tags: detail.summary && detail.summary.genres ? detail.summary.genres : prev.tags,
      displayTags: detail.summary && detail.summary.genres ? detail.summary.genres : prev.displayTags,
      description: detail.summary && detail.summary.description ? detail.summary.description : prev.description,
      displayDescription:
        detail.summary && detail.summary.description ? detail.summary.description : prev.displayDescription,
      displaySelectedPrefectures:
        detail.summary && detail.summary.prefectures ? detail.summary.prefectures : prev.displaySelectedPrefectures,
      selectedPrefectures:
        detail.summary && detail.summary.prefectures ? detail.summary.prefectures : prev.displaySelectedPrefectures,
      prefecturesRadioBoxValue: detail.summary && detail.summary.prefectures ? 'from_on' : 'from_off',
      recruitmentPlanCount:
        detail.summary && detail.summary.adoptionPlan ? detail.summary.adoptionPlan : prev.recruitmentPlanCount,
      displayRecruitmentPlanCount: detail.summary && detail.summary.adoptionPlan.toString(),
      rewardAmount: detail.summary && detail.summary.rewards ? detail.summary.rewards : prev.rewardAmount,
      displayRewardAmount:
        detail.summary && detail.summary.rewards ? detail.summary.rewards.toString() : prev.displayRewardAmount,
      project: {
        displayAdoption:
          detail.summary && detail.summary.adoptionPlan
            ? detail.summary.adoptionPlan.toString()
            : prev.project?.adoption,
        adoption:
          detail.summary && detail.summary.adoptionPlan
            ? detail.summary.adoptionPlan.toString()
            : prev.project?.adoption,
        reward: detail.summary && detail.summary.rewards ? detail.summary.rewards.toString() : prev.project?.reward,
        displayReward:
          detail.summary && detail.summary.rewards ? detail.summary.rewards.toString() : prev.project?.reward,
        displayFollower:
          detail.summary && detail.summary.minFollowers
            ? detail.summary.minFollowers.toString()
            : prev.project?.follower,
        follower:
          detail.summary && detail.summary.minFollowers
            ? detail.summary.minFollowers.toString()
            : prev.project?.follower,
        gender: detail.summary && detail.summary.gender ? detail.summary.gender.toString() : prev.project?.gender,
        displayGender:
          detail.summary && detail.summary.gender ? detail.summary.gender.toString() : prev.project?.gender,
        displayMinAge:
          detail.summary && detail.summary.minAge ? detail.summary.minAge.toString() : prev.project?.minAge,
        minAge: detail.summary && detail.summary.minAge ? detail.summary.minAge.toString() : prev.project?.minAge,
        displayMaxAge:
          detail.summary && detail.summary.maxAge ? detail.summary.maxAge.toString() : prev.project?.maxAge,
        maxAge: detail.summary && detail.summary.maxAge ? detail.summary.maxAge.toString() : prev.project?.maxAge,
        displayMinIncome:
          detail.summary && detail.summary.minAnnualIncome
            ? detail.summary.minAnnualIncome.toString()
            : prev.project?.minIncome,
        minIncome:
          detail.summary && detail.summary.minAnnualIncome
            ? detail.summary.minAnnualIncome.toString()
            : prev.project?.minIncome,
        displayMaxIncome:
          detail.summary && detail.summary.maxAnnualIncome
            ? detail.summary && detail.summary.maxAnnualIncome.toString()
            : prev.project?.maxIncome,
        maxIncome:
          detail.summary && detail.summary.maxAnnualIncome
            ? detail.summary.maxAnnualIncome.toString()
            : prev.project?.maxIncome,
        displayJob: detail.summary && detail.summary.job ? detail.summary.job.toString() : prev.project?.job,
        job: detail.summary && detail.summary.job ? detail.summary.job.toString() : prev.project?.job,
      },
    }));
  };

  const initOption = (detail: GetSpiritDetailOutput) => {
    setOption((prev) => ({
      ...prev,
      goodQuality: detail.options ? detail.options.goodQuality : 0,
      secondaryUse: detail.options ? detail.options.secondaryUse : 1,
      adoptionOutsourced: detail.options ? detail.options.adoptionOutsourced : 1,
    }));
  };

  const initPublicAttention = (detail: GetSpiritDetailOutput) => {
    setDetailSettingProps((prev) => ({
      ...prev,
      isSecret: detail.details && detail.details.isSecret ? detail.details.isSecret : false,
      notice: detail.details && detail.details.notice ? detail.details.notice : [],
      companyName: detail.details && detail.details.companyName ? detail.details.companyName : '',
    }));
  };

  const initSelectType: SelectType = {
    id: 0,
    key: props.snsType,
    name: SNS_MENUES[0],
    title: '',
    titleError: undefined,
    productInfo: '',
    productInfoError: undefined,
    scheduledDeliveryDate: undefined,
    scheduledDeliveryDateError: undefined,
    postPeriodBegin: undefined,
    postPeriodBeginError: undefined,
    postPeriodEnd: undefined,
    postPeriodEndError: undefined,
    postMethod: '1',
    postImageCount: 0,
    postMovieCount: 0,
    postMovieAndImageCountError: undefined,
    postRequestContent: '',
    postRequestContentError: undefined,
    advertisement: '',
    hashTag: '',
    hashTagsError: undefined,
    hashTags: [],
    hashTagError: undefined,
    optionalHashTag: '',
    optionalHashTagError: undefined,
    optionalHashTags: [],
    optionalHashTagsError: undefined,
    showToHireeOnlyHashTags: false,
    accountTag: '',
    accountTagError: undefined,
    accountTags: [],
    accountTagsError: undefined,
    showToHireeOnlyAccountTags: false,
    locationInfo: '',
    lacationInfoError: undefined,
    showToHireeOnlyLocationInfo: false,
    postUrl: '',
    postUrlError: undefined,
    showToHireeOnlyPostUrl: false,
    movieType: '',
    buyingPeriodStart: undefined,
    buyingPeriodStartError: undefined,
    buyingPeriodEnd: undefined,
    buyingPeriodEndError: undefined,
    storeInfo: '',
    storeInfoError: undefined,
    purchaseEvidenceSettings: '1',
    purchaseEvidenceText: '',
    purchaseEvidenceTextError: undefined,
    purchaseItemExplanation: '',
    purchaseItemExplanationError: undefined,
    purchaseItemPrice: '',
    purchaseItemPriceError: undefined,
    eventDatesStart: undefined,
    eventDatesStartError: undefined,
    eventDatesEnd: undefined,
    eventDatesEndError: undefined,
    eventDetails: '',
    eventDetailsError: undefined,
    shoppingUrl: '',
    shoppingUrlError: undefined,
    downloadUsagePeriodStart: undefined,
    downloadUsagePeriodStartError: undefined,
    downloadUsagePeriodEnd: undefined,
    downloadUsagePeriodEndError: undefined,
    downloadURL: '',
    downloadURLError: undefined,
    detail: '',
    detailError: undefined,
    productDetails: [],
    selectLists: [],
    highlight: '0',
    publicWeek: 1,
  };

  const initItemDetail: itemDetails = {
    itemTitle: '',
    itemTitleError: undefined,
    options: [],
    optionsError: undefined,
    noAddedOptionError: undefined,
    allowInfluencerProductPickup: '',
    maxSelectionCount: 0,
  };

  const initSelectItem: selectItem = {
    selectExplanation: '',
    selectExplanationError: undefined,
    selectType: [],
    selectTypeError: undefined,
    noAddedSelectTypeError: undefined,
    maxSelectionCount: 0,
  };

  const initFeedOperationDetail = (task: TaskInstagramFeed) => {
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[10],
      postImageCount: task.imageNum,
      postMovieCount: task.movieNum,
      locationInfo: task.location ? task.location : '',
      showToHireeOnlyLocationInfo: task.isSecretLocation,
      postPeriodBegin: new Date(task.startedAt),
      postPeriodEnd: new Date(task.endedAt),
      postRequestContent: task.body,
      advertisement: task.postingAd && task.postingAd !== undefined ? task.postingAd : '',
      hashTags: task.requiredHashTags && task.requiredHashTags !== undefined ? task.requiredHashTags : [],
      optionalHashTags: task.OptionalHashTags && task.OptionalHashTags !== undefined ? task.OptionalHashTags : [],
      showToHireeOnlyHashTags: task.isSecretHashTag,
      accountTags: task.accountTags ? task.accountTags : [],
      showToHireeOnlyAccountTags: task.isSecretAccountTag,
    };
    return selected;
  };

  const initStoriesOperationDetail = (task: TaskInstagramStories) => {
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[11],
      locationInfo: task.location ? task.location : '',
      showToHireeOnlyLocationInfo: task.isSecretLocation,
      postPeriodBegin: new Date(task.startedAt),
      postPeriodEnd: new Date(task.endedAt),
      postRequestContent: task.body,
      advertisement: task.postingAd && task.postingAd !== undefined ? task.postingAd : '',
      hashTags: task.requiredHashTags && task.requiredHashTags !== undefined ? task.requiredHashTags : [],
      optionalHashTags: task.OptionalHashTags && task.OptionalHashTags !== undefined ? task.OptionalHashTags : [],
      showToHireeOnlyHashTags: task.isSecretHashTag,
      accountTags: task.accountTags ? task.accountTags : [],
      showToHireeOnlyAccountTags: task.isSecretAccountTag,
      highlight: task.highlight ? '1' : '0',
      publicWeek: task.publicationWeek,
      showToHireeOnlyPostUrl: task.isSecretUrl,
      postUrl: task.url ? task.url : '',
    };
    return selected;
  };

  const initReelOperationDetail = (task: TaskInstagramReel) => {
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[12],
      locationInfo: task.location ? task.location : '',
      showToHireeOnlyLocationInfo: task.isSecretLocation,
      postPeriodBegin: new Date(task.startedAt),
      postPeriodEnd: new Date(task.endedAt),
      postRequestContent: task.body,
      advertisement: task.postingAd && task.postingAd !== undefined ? task.postingAd : '',
      hashTags: task.requiredHashTags && task.requiredHashTags !== undefined ? task.requiredHashTags : [],
      optionalHashTags: task.OptionalHashTags && task.OptionalHashTags !== undefined ? task.OptionalHashTags : [],
      showToHireeOnlyHashTags: task.isSecretHashTag,
      accountTags: task.accountTags ? task.accountTags : [],
      showToHireeOnlyAccountTags: task.isSecretAccountTag,
    };

    return selected;
  };

  const initXPostOperationDetail = (task: TaskXPost, taskType: number) => {
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[taskType],
      postMethod: task.postMethod.toString(),
      postImageCount: task.imageNum,
      postMovieCount: task.movieNum,
      postPeriodBegin: new Date(task.startedAt),
      postPeriodEnd: new Date(task.endedAt),
      postRequestContent: task.body,
      advertisement: task.postingAd && task.postingAd !== undefined ? task.postingAd : '',
      hashTags: task.requiredHashTags && task.requiredHashTags !== undefined ? task.requiredHashTags : [],
      optionalHashTags: task.OptionalHashTags && task.OptionalHashTags !== undefined ? task.OptionalHashTags : [],
      showToHireeOnlyHashTags: task.isSecretHashTag,
      accountTags: task.accountTags ? task.accountTags : [],
      showToHireeOnlyAccountTags: task.isSecretAccountTag,
      showToHireeOnlyPostUrl: task.isSecretUrl,
      postUrl: task.url ? task.url : '',
    };
    return selected;
  };

  const initTiktokFeedOperationDetail = (task: TaskTikTokFeed, taskType: number) => {
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[taskType],
      postPeriodBegin: new Date(task.startedAt),
      postPeriodEnd: new Date(task.endedAt),
      postRequestContent: task.body,
      advertisement: task.postingAd && task.postingAd !== undefined ? task.postingAd : '',
      hashTags: task.requiredHashTags && task.requiredHashTags !== undefined ? task.requiredHashTags : [],
      optionalHashTags: task.OptionalHashTags && task.OptionalHashTags !== undefined ? task.OptionalHashTags : [],
      showToHireeOnlyHashTags: task.isSecretHashTag,
      accountTags: task.accountTags ? task.accountTags : [],
      showToHireeOnlyAccountTags: task.isSecretAccountTag,
      locationInfo: task.location ? task.location : '',
      showToHireeOnlyLocationInfo: task.isSecretLocation,
      showToHireeOnlyPostUrl: task.isSecretUrl,
      postUrl: task.url ? task.url : '',
    };
    return selected;
  };

  const initYoutubeFeedOperationDetail = (task: TaskYoutubeFeed, taskType: number) => {
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[taskType],
      movieType: task.movieType.toString(),
      postPeriodBegin: new Date(task.startedAt),
      postPeriodEnd: new Date(task.endedAt),
      postRequestContent: task.body,
      advertisement: task.postingAd && task.postingAd !== undefined ? task.postingAd : '',
      hashTags: task.requiredHashTags && task.requiredHashTags !== undefined ? task.requiredHashTags : [],
      optionalHashTags: task.OptionalHashTags && task.OptionalHashTags !== undefined ? task.OptionalHashTags : [],
      showToHireeOnlyHashTags: task.isSecretHashTag,
      accountTags: task.accountTags ? task.accountTags : [],
      showToHireeOnlyAccountTags: task.isSecretAccountTag,
      showToHireeOnlyPostUrl: task.isSecretUrl,
      postUrl: task.url ? task.url : '',
    };
    return selected;
  };

  const initSamplingOperationDetail = (task: TaskSampling) => {
    const itemDetails: itemDetails[] = [];
    task.options.forEach((option) => {
      const itemDetail: itemDetails = {
        ...initItemDetail,
        itemTitle: option.description,
        options: option.options,
        allowInfluencerProductPickup: option.selectable ? '0' : '1',
        maxSelectionCount: option.amount ? option.amount : 0,
      };
      itemDetails.push(itemDetail);
    });

    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[1],
      title: task.title ? task.title : '',
      productInfo: task.summaryText,
      scheduledDeliveryDate: task.arrivalDate ? new Date(task.arrivalDate) : undefined,
      productDetails: itemDetails,
    };
    return selected;
  };

  const initBuyingShopOperationDetail = (task: TaskBuying) => {
    const itemDetails: itemDetails[] = [];
    task.options.forEach((option) => {
      const itemDetail: itemDetails = {
        ...initItemDetail,
        itemTitle: option.description,
        options: option.options,
        allowInfluencerProductPickup: option.selectable ? '0' : '1',
        maxSelectionCount: option.amount ? option.amount : 0,
      };
      itemDetails.push(itemDetail);
    });

    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[2],
      title: task.title,
      buyingPeriodStart: new Date(task.startedAt),
      buyingPeriodEnd: new Date(task.endedAt),
      purchaseItemExplanation: task.description,
      storeInfo: task.shopInformation ? task.shopInformation : '',
      purchaseEvidenceSettings: task.isEvidence ? '2' : '1',
      purchaseEvidenceText: task.evidenceNotice ? task.evidenceNotice : '',
      purchaseItemPrice: task.price.toString(),
      productDetails: itemDetails,
    };

    return selected;
  };

  const initBuyingEcOperationDetail = (task: TaskBuying) => {
    const itemDetails: itemDetails[] = [];
    task.options.forEach((option) => {
      const itemDetail: itemDetails = {
        ...initItemDetail,
        itemTitle: option.description,
        options: option.options,
        allowInfluencerProductPickup: option.selectable ? '0' : '1',
        maxSelectionCount: option.amount ? option.amount : 0,
      };
      itemDetails.push(itemDetail);
    });

    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[3],
      title: task.title,
      buyingPeriodStart: new Date(task.startedAt),
      buyingPeriodEnd: new Date(task.endedAt),
      purchaseItemExplanation: task.description,
      shoppingUrl: task.ecUrl ? task.ecUrl : '',
      purchaseEvidenceSettings: task.isEvidence ? '2' : '1',
      purchaseEvidenceText: task.evidenceNotice ? task.evidenceNotice : '',
      purchaseItemPrice: task.price.toString(),
      productDetails: itemDetails,
    };

    return selected;
  };

  const initEventOperationDetail = (task: TaskEvent) => {
    const selectItem: selectItem[] = [];
    task.options.forEach((option) => {
      const select: selectItem = {
        ...initSelectItem,
        selectExplanation: option.description,
        selectType: option.options,
        maxSelectionCount: option.amount ? option.amount : 0,
      };
      selectItem.push(select);
    });
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[4],
      title: task.title,
      eventDatesStart: new Date(task.startedAt),
      eventDatesEnd: new Date(task.endedAt),
      eventDetails: task.description,
      selectLists: selectItem,
    };

    return selected;
  };

  const initWebAppOperationDetail = (task: TaskWebApp) => {
    const selectItem: selectItem[] = [];
    task.options.forEach((option) => {
      const select: selectItem = {
        ...initSelectItem,
        selectExplanation: option.description,
        selectType: option.options,
        maxSelectionCount: option.amount ? option.amount : 0,
      };
      selectItem.push(select);
    });
    const selected: SelectType = {
      ...initSelectType,
      key: props.projectDetailSpirit.snsType,
      name: SNS_MENUES[5],
      title: task.title,
      downloadUsagePeriodStart: new Date(task.startedAt),
      downloadUsagePeriodEnd: new Date(task.endedAt),
      downloadURL: task.downloadUrl,
      purchaseEvidenceSettings: task.isEvidence ? '2' : '1',
      purchaseEvidenceText: task.evidenceNotice ? task.evidenceNotice : '',
      detail: task.description,
      selectLists: selectItem,
    };

    return selected;
  };

  const initOperationDetail = (detail: GetSpiritDetailOutput) => {
    const selected: SelectType[] = [];
    if (detail && detail.tasks) {
      detail.tasks.forEach((task) => {
        console.log('task', task);
        if (task.taskType === 10) {
          selected.push(initFeedOperationDetail(task.detail as TaskInstagramFeed));
        } else if (task.taskType === 11) {
          selected.push(initStoriesOperationDetail(task.detail as TaskInstagramStories));
        } else if (task.taskType === 12) {
          selected.push(initReelOperationDetail(task.detail as TaskInstagramReel));
        } else if (task.taskType === 20) {
          selected.push(initXPostOperationDetail(task.detail as TaskXPost, task.taskType));
        } else if (task.taskType === 21) {
          selected.push(initXPostOperationDetail(task.detail as TaskXPost, task.taskType));
        } else if (task.taskType === 30) {
          selected.push(initTiktokFeedOperationDetail(task.detail as TaskTikTokFeed, task.taskType));
        } else if (task.taskType === 40) {
          selected.push(initYoutubeFeedOperationDetail(task.detail as TaskYoutubeFeed, task.taskType));
        } else if (task.taskType === 1) {
          selected.push(initSamplingOperationDetail(task.detail as TaskSampling));
        } else if (task.taskType === 2) {
          selected.push(initBuyingShopOperationDetail(task.detail as TaskBuying));
        } else if (task.taskType === 3) {
          selected.push(initBuyingEcOperationDetail(task.detail as TaskBuying));
        } else if (task.taskType === 4) {
          selected.push(initEventOperationDetail(task.detail as TaskEvent));
        } else if (task.taskType === 5) {
          selected.push(initWebAppOperationDetail(task.detail as TaskWebApp));
        }
      });
    }
    setOperationDetailProps((prev) => ({
      ...prev,
      selected: selected,
      sns: props.snsType,
    }));
  };

  const getDetail = async () => {
    initRecruitingTitle(props.projectDetailSpirit);
    initDetailImages(props.projectDetailSpirit);
    initOverview(props.projectDetailSpirit);
    initOption(props.projectDetailSpirit);
    initPublicAttention(props.projectDetailSpirit);
    initOperationDetail(props.projectDetailSpirit);
  };

  useEffect(() => {
    getDetail();
    setProjectStatus(
      props.projectDetailSpirit.header ? props.projectDetailSpirit.header.projectStatus : ProjectStatus.Draft
    );
    setIsRequesting(projectStatus !== ProjectStatus.Draft);
  }, [props.projectDetailSpirit]);

  useEffect(() => {
    const getFechRewards = async () => {
      const response = await props.fetchSpiritProjectDetail();
      if (response) {
        setDisplayRewards(response.data.summary.rewards);
      }
    };
    getFechRewards();
  }, [displayRewards, props]);

  const dateToString = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}/${month}/${day}`;
  };

  const detailFeedOperationContent = (item: SelectType) => {
    const data: [string, string][] = [];
    data.push(['画像', item.postImageCount.toString()]);
    data.push(['動画', item.postMovieCount.toString()]);
    return (
      <>
        {descriptionItem(
          '投稿期間',
          item.postPeriodBegin && item.postPeriodEnd
            ? `${dateToString(item.postPeriodBegin)} ~ ${dateToString(item.postPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('投稿メディアの内容', data)}
        {descriptionItem('投稿依頼内容', item.postRequestContent)}
        {descriptionItem('投稿に含める広告表示', '#' + item.advertisement)}
        {descriptionHashTag(
          '投稿に含めるハッシュタグ(#)',
          item.hashTags.map((tag) => `#${tag}`),
          item.optionalHashTags.map((tag) => `#${tag}`),
          item.showToHireeOnlyHashTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるアカウントタグ(@)',
          item.accountTags.map((tag) => `@${tag}`),
          item.showToHireeOnlyAccountTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含める位置情報',
          item.locationInfo,
          item.showToHireeOnlyLocationInfo ? '採用者のみ公開' : '非表示'
        )}
      </>
    );
  };

  const detailStoriesOperationContent = (item: SelectType) => {
    const data: string[] = [];
    data.push(item.highlight === '0' ? 'なし' : 'あり');
    if (item.highlight === '1') {
      data.push('掲載期間：' + item.publicWeek.toString() + 'week');
    }
    return (
      <>
        {descriptionItem(
          '投稿期間',
          item.postPeriodBegin && item.postPeriodEnd
            ? `${dateToString(item.postPeriodBegin)} ~ ${dateToString(item.postPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('投稿依頼内容', item.postRequestContent)}
        {descriptionItem('ハイライト', data)}
        {descriptionItem('投稿に含める広告表示', '#' + item.advertisement)}
        {descriptionHashTag(
          '投稿に含めるハッシュタグ(#)',
          item.hashTags.map((tag) => `#${tag}`),
          item.optionalHashTags.map((tag) => `#${tag}`),
          item.showToHireeOnlyHashTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるアカウントタグ(@)',
          item.accountTags.map((tag) => `@${tag}`),
          item.showToHireeOnlyAccountTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含める位置情報',
          item.locationInfo,
          item.showToHireeOnlyLocationInfo ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるURL',
          item.postUrl,
          item.showToHireeOnlyPostUrl ? '採用者のみ公開' : '非表示'
        )}
      </>
    );
  };

  const detailReelOperationContent = (item: SelectType) => {
    return (
      <>
        {descriptionItem(
          '投稿期間',
          item.postPeriodBegin && item.postPeriodEnd
            ? `${dateToString(item.postPeriodBegin)} ~ ${dateToString(item.postPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('投稿依頼内容', item.postRequestContent)}
        {descriptionItem('投稿に含める広告表示', '#' + item.advertisement)}
        {descriptionHashTag(
          '投稿に含めるハッシュタグ(#)',
          item.hashTags.map((tag) => `#${tag}`),
          item.optionalHashTags.map((tag) => `#${tag}`),
          item.showToHireeOnlyHashTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるアカウントタグ(@)',
          item.accountTags.map((tag) => `@${tag}`),
          item.showToHireeOnlyAccountTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含める位置情報',
          item.locationInfo,
          item.showToHireeOnlyLocationInfo ? '採用者のみ公開' : '非表示'
        )}
      </>
    );
  };

  const detaiXPostOperationContent = (item: SelectType) => {
    const data: [string, string][] = [];
    data.push(['画像', item.postImageCount.toString()]);
    data.push(['動画', item.postMovieCount.toString()]);
    return (
      <>
        {descriptionItem(
          '投稿期間',
          item.postPeriodBegin && item.postPeriodEnd
            ? `${dateToString(item.postPeriodBegin)} ~ ${dateToString(item.postPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('投稿方法', item.postMethod === '1' ? 'テキストのみ' : data)}
        {descriptionItem('投稿依頼内容', item.postRequestContent)}
        {descriptionItem('投稿に含める広告表示', '#' + item.advertisement)}
        {descriptionHashTag(
          '投稿に含めるハッシュタグ(#)',
          item.hashTags.map((tag) => `#${tag}`),
          item.optionalHashTags.map((tag) => `#${tag}`),
          item.showToHireeOnlyHashTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるアカウントタグ(@)',
          item.accountTags.map((tag) => `@${tag}`),
          item.showToHireeOnlyAccountTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるURL',
          item.postUrl,
          item.showToHireeOnlyPostUrl ? '採用者のみ公開' : '非表示'
        )}
      </>
    );
  };

  const detaiTiktokPostOperationContent = (item: SelectType) => {
    const data: [string, string][] = [];
    data.push(['画像', item.postImageCount.toString()]);
    data.push(['動画', item.postMovieCount.toString()]);
    return (
      <>
        {descriptionItem(
          '投稿期間',
          item.postPeriodBegin && item.postPeriodEnd
            ? `${dateToString(item.postPeriodBegin)} ~ ${dateToString(item.postPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('投稿依頼内容', item.postRequestContent)}
        {descriptionItem('投稿に含める広告表示', '#' + item.advertisement)}
        {descriptionHashTag(
          '投稿に含めるハッシュタグ(#)',
          item.hashTags.map((tag) => `#${tag}`),
          item.optionalHashTags.map((tag) => `#${tag}`),
          item.showToHireeOnlyHashTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるアカウントタグ(@)',
          item.accountTags.map((tag) => `@${tag}`),
          item.showToHireeOnlyAccountTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含める位置情報',
          item.locationInfo,
          item.showToHireeOnlyLocationInfo ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるURL',
          item.postUrl,
          item.showToHireeOnlyPostUrl ? '採用者のみ公開' : '非表示'
        )}
      </>
    );
  };

  const detaiYoutubePostOperationContent = (item: SelectType) => {
    return (
      <>
        {descriptionItem(
          '投稿期間',
          item.postPeriodBegin && item.postPeriodEnd
            ? `${dateToString(item.postPeriodBegin)} ~ ${dateToString(item.postPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('投稿依頼内容', item.postRequestContent)}
        {descriptionItem('動画タイプ', item.movieType === '1' ? '動画（横位置）' : 'ショート（縦位置）')}
        {descriptionItem('投稿に含める広告表示', '#' + item.advertisement)}
        {descriptionHashTag(
          '投稿に含めるハッシュタグ(#)',
          item.hashTags.map((tag) => `#${tag}`),
          item.optionalHashTags.map((tag) => `#${tag}`),
          item.showToHireeOnlyHashTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるアカウントタグ(@)',
          item.accountTags.map((tag) => `@${tag}`),
          item.showToHireeOnlyAccountTags ? '採用者のみ公開' : '非表示'
        )}
        {descriptionItemBothSide(
          '投稿に含めるURL',
          item.postUrl,
          item.showToHireeOnlyPostUrl ? '採用者のみ公開' : '非表示'
        )}
      </>
    );
  };

  const createProductDetailContent = (productDetails: itemDetails[]) => {
    const descriptionItems = [];
    for (const prodactDetail of productDetails) {
      const data: string[] = [];
      data.push(prodactDetail.allowInfluencerProductPickup === '0' ? '選択可能' : '選択不可');
      if (prodactDetail.allowInfluencerProductPickup === '0') {
        data.push('選択可能数' + prodactDetail.maxSelectionCount.toString());
      }

      // JSX を配列に追加
      descriptionItems.push(
        <div>
          {descriptionItem('商品名', prodactDetail.itemTitle ? prodactDetail.itemTitle : '-')}
          {descriptionItem('商品の種類', prodactDetail.options)}
          {descriptionItem('インフルエンサーの商品選択可否', data)}
        </div>
      );
    }
    return <div>{descriptionItems}</div>;
  };

  const detailSamplingOperationContent = (item: SelectType) => {
    return (
      <>
        {descriptionItem('タイトル', item.title ? item.title : '-')}
        {descriptionItem('商品概要', item.productInfo ? item.productInfo : '-')}
        {descriptionItem('到着予定日', item.scheduledDeliveryDate ? dateToString(item.scheduledDeliveryDate) : '-')}
        {createProductDetailContent(item.productDetails)}
      </>
    );
  };

  const detailBuyingShopOperationContent = (item: SelectType) => {
    const data: string[] = [];
    data.push(item.purchaseEvidenceSettings === '2' ? 'あり' : 'なし');
    if (item.purchaseEvidenceSettings === '2') {
      data.push(item.purchaseEvidenceText);
    }
    return (
      <>
        {descriptionItem('タイトル', item.title ? item.title : '-')}
        {descriptionItem(
          '購入期間',
          item.buyingPeriodStart && item.buyingPeriodEnd
            ? `${dateToString(item.buyingPeriodStart)} ～ ${dateToString(item.buyingPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('店舗情報', item.storeInfo ? item.storeInfo : '-')}
        {descriptionItem('購入エビデンス', data)}
        {descriptionItem('買ってもらう商品の説明', item.purchaseItemExplanation ? item.purchaseItemExplanation : '-')}
        {descriptionItem(
          '買ってもらう商品の金額',
          item.purchaseItemPrice ? '￥' + addComma(item.purchaseItemPrice) : '-'
        )}
        {createProductDetailContent(item.productDetails)}
      </>
    );
  };

  const detailBuyingEcOperationContent = (item: SelectType) => {
    const data: string[] = [];
    data.push(item.purchaseEvidenceSettings === '2' ? 'あり' : 'なし');
    if (item.purchaseEvidenceSettings === '2') {
      data.push(item.purchaseEvidenceText);
    }
    return (
      <>
        {descriptionItem('タイトル', item.title ? item.title : '-')}
        {descriptionItem(
          '購入期間',
          item.buyingPeriodStart && item.buyingPeriodEnd
            ? `${dateToString(item.buyingPeriodStart)} ～ ${dateToString(item.buyingPeriodEnd)}`
            : '-'
        )}
        {descriptionItem('購入先URL', item.shoppingUrl ? item.shoppingUrl : '-')}
        {descriptionItem('購入エビデンス', data)}
        {descriptionItem('買ってもらう商品の説明', item.purchaseItemExplanation ? item.purchaseItemExplanation : '-')}
        {descriptionItem(
          '買ってもらう商品の金額',
          item.purchaseItemPrice ? '￥' + addComma(item.purchaseItemPrice) : '-'
        )}
        {createProductDetailContent(item.productDetails)}
      </>
    );
  };

  const createOptionContent = (selectItems: selectItem[]) => {
    const descriptionItems = [];
    for (const selectItem of selectItems) {
      const data: string[] = [];
      selectItem.selectType.forEach((type) => data.push(type));
      data.push(`選択可能数` + selectItem.maxSelectionCount.toString());

      // JSX を配列に追加
      descriptionItems.push(
        <div>
          {descriptionItem('選択肢の説明', selectItem.selectExplanation ? selectItem.selectExplanation : '-')}
          {descriptionItem('選択肢の種類', data)}
        </div>
      );
    }
    return <div>{descriptionItems}</div>;
  };

  const detailEventOperationContent = (item: SelectType) => {
    return (
      <>
        {descriptionItem('タイトル', item.title ? item.title : '-')}
        {descriptionItem(
          '開催期間',
          item.eventDatesStart && item.eventDatesEnd
            ? `${dateToString(item.eventDatesStart)} ～ ${dateToString(item.eventDatesEnd)}`
            : '-'
        )}
        {descriptionItem('イベント説明', item.eventDetails ? item.eventDetails : '-')}
        {createOptionContent(item.selectLists)}
      </>
    );
  };

  const detailWebServiceOperationContent = (item: SelectType) => {
    const data: string[] = [];
    data.push(item.purchaseEvidenceSettings === '2' ? 'あり' : 'なし');
    if (item.purchaseEvidenceSettings === '2') {
      data.push(item.purchaseEvidenceText);
    }
    return (
      <>
        {descriptionItem('タイトル', item.title ? item.title : '-')}
        {descriptionItem(
          'ダウンロード・利用期間',
          item.downloadUsagePeriodStart && item.downloadUsagePeriodEnd
            ? `${dateToString(item.downloadUsagePeriodStart)} ～ ${dateToString(item.downloadUsagePeriodEnd)}`
            : '-'
        )}
        {descriptionItem('ダウンロード・利用先URL', item.downloadURL ? item.downloadURL : '-')}
        {descriptionItem('詳細説明', item.detail ? item.detail : '-')}
        {descriptionItem('購入エビデンス', data)}
        {createOptionContent(item.selectLists)}
      </>
    );
  };

  const detailOperationContent = (item: SelectType) => {
    switch (item.name) {
      case SNS_MENUES[10]:
        return detailFeedOperationContent(item);
      case SNS_MENUES[11]:
        return detailStoriesOperationContent(item);
      case SNS_MENUES[12]:
        return detailReelOperationContent(item);
      case SNS_MENUES[20]:
        return detaiXPostOperationContent(item);
      case SNS_MENUES[21]:
        return detaiXPostOperationContent(item);
      case SNS_MENUES[30]:
        return detaiTiktokPostOperationContent(item);
      case SNS_MENUES[40]:
        return detaiYoutubePostOperationContent(item);
      case SNS_MENUES[1]:
        return detailSamplingOperationContent(item);
      case SNS_MENUES[2]:
        return detailBuyingShopOperationContent(item);
      case SNS_MENUES[3]:
        return detailBuyingEcOperationContent(item);
      case SNS_MENUES[4]:
        return detailEventOperationContent(item);
      case SNS_MENUES[5]:
        return detailWebServiceOperationContent(item);
      default:
        return <></>;
    }
  };
  const detailOperationIcon = (item: SelectType) => {
    switch (item.name) {
      case SNS_MENUES[10]:
        return SpiritDetailInstagramIcon;
      case SNS_MENUES[11]:
        return SpiritDetailInstagramIcon;
      case SNS_MENUES[12]:
        return SpiritDetailInstagramIcon;
      case SNS_MENUES[20]:
        return SpiritDetailXIcon;
      case SNS_MENUES[21]:
        return SpiritDetailXIcon;
      case SNS_MENUES[30]:
        return SpiritDetailTiktokIcon;
      case SNS_MENUES[40]:
        return SpiritDetailYoutubeIcon;
      case SNS_MENUES[1]:
        return SpiritDetailSampleIcon;
      case SNS_MENUES[2]:
        return SpiritDetailBuyingIcon;
      case SNS_MENUES[3]:
        return SpiritDetailBuyingIcon;
      case SNS_MENUES[4]:
        return SpiritDetailEventIcon;
      case SNS_MENUES[5]:
        return SpiritDetailWebAppIcon;
    }
  };
  // 小数点以下を削除する
  // const removeDecimal = (value: string): string => {
  //   return value.split('.')[0];
  // };
  // カンマを付与する
  const addComma = (target: string): string => `${target.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;

  // KPI 用の変数
  const KpiArgs = props.projectDetailSpirit.kpi
    ? props.projectDetailSpirit.kpi
    : {
        items: [{ comment: '-', isFixed: false, name: '-' }],
      };

  useEffect(() => {
    setIsRequesting(projectStatus !== ProjectStatus.Draft);
  }, [projectStatus]);

  const prefecturesJsonData = useAssetDataJson('/prefectures') ?? [];
  const jobJsonData = useAssetNumberJson('/jobs') ?? [];
  const followerJsonData = useAssetNumberJson('/followers') ?? [];
  const ageJsonData = useAssetNumberJson('/age') ?? [];
  const convertAge = (value: number): string => {
    return ageJsonData.find((item) => item.value === value)?.label ?? '-';
  };
  const annualIncomeJsonData = useAssetNumberJson('/annualIncome') ?? [];
  const convertAnnualIncome = (value: number): string => {
    return annualIncomeJsonData.find((item) => item.value === value)?.label ?? '-';
  };

  const createApplyCondition = (): [string, string][] => {
    const follower = projectOverviewProps.project?.displayFollower;
    const gender = projectOverviewProps.project?.displayGender;
    const minAge = projectOverviewProps.project?.displayMinAge;
    const maxAge = projectOverviewProps.project?.displayMaxAge;
    const minIncome = projectOverviewProps.project?.displayMinIncome;
    const maxIncome = projectOverviewProps.project?.displayMaxIncome;
    const job = projectOverviewProps.project?.displayJob;
    const prefectures = projectOverviewProps.displaySelectedPrefectures;

    const data: [string, string][] = [];
    data.push(['・フォロワー数', followerJsonData.find((item) => item.value === Number(follower))?.label ?? '-']);

    let genderDisplay = '-';
    if (gender == 'none') {
      genderDisplay = '指定なし';
    } else if (gender == '1') {
      genderDisplay = '男性';
    } else if (gender == '2') {
      genderDisplay = '女性';
    }

    data.push(['・性別', genderDisplay]);

    let ageRange = '-';
    if (minAge != null && maxAge != null && minAge != 'none' && maxAge != 'none') {
      ageRange = `${convertAge(Number(minAge))}～${convertAge(Number(maxAge))}`;
    } else if (minAge != null && minAge != 'none') {
      ageRange = `${convertAge(Number(minAge))}以上`;
    } else if (maxAge != null && maxAge != 'none') {
      ageRange = `${convertAge(Number(maxAge))}以下`;
    }
    data.push(['・年齢', ageRange]);

    if (prefecturesJsonData) {
      const labelsOfTargetValues = prefectures.map((prefecture) => {
        const targetItem = prefecturesJsonData.find((item) => item.value === prefecture);
        return targetItem ? targetItem.label : ''; // 見つからない場合は空文字列を返す
      });

      const labelOfTargetValue = labelsOfTargetValues.join('、');

      data.push(['・居住地', prefectures != null && prefectures.length != 0 ? labelOfTargetValue : '-']);
    }
    let incomeRange = '-';
    if (minIncome != null && maxIncome != null && minIncome != 'none' && maxIncome != 'none') {
      incomeRange = `${convertAnnualIncome(Number(minIncome))}～${convertAnnualIncome(Number(maxIncome))}`;
    } else if (minIncome != null && minIncome != 'none') {
      incomeRange = `${convertAnnualIncome(Number(minIncome))}以上`;
    } else if (maxIncome != null && maxIncome != 'none') {
      incomeRange = `${convertAnnualIncome(Number(maxIncome))}以下`;
    }

    data.push(['・年収', incomeRange]);

    if (jobJsonData) {
      const jobData = jobJsonData.find((item) => item.value == Number(job)) ?? { label: '' };
      data.push(['・職業', job != null && job != 'none' ? jobData.label : '-']);
    }
    return data;
  };

  const descriptionItemBothSide = (key: string, leftValue: string | string[], rightValue: string) => {
    return (
      <>
        <div className="flex flex-row items-center whitespace-pre pt-[16px]">
          <div className="min-h-[20px] w-[233px]">
            <span className="text-sm font-semibold leading-5 text-gray-700">{key}</span>
          </div>
          <div className="min-h-[20px] w-[445.75px] gap-2">
            {typeof leftValue === 'string' ? (
              <div className="flex">
                <div className="w-[300.75px] whitespace-pre-line break-words text-sm font-semibold leading-5 text-gray-900">
                  {leftValue}
                </div>
                <div className="ml-auto text-sm font-semibold leading-5 text-gray-900">{rightValue}</div>
              </div>
            ) : (
              leftValue.map((item, index) => {
                return (
                  <div key={index} className="flex pt-2">
                    <div className="inline-flex items-center rounded-md bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
                      {item}
                    </div>
                    {index === 0 && (
                      <div className="ml-auto pt-[10px] text-sm font-semibold leading-5 text-gray-900">
                        {rightValue}
                      </div>
                    )}
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="h-[1px] w-[678.75px] pt-[16px]">
          <div className="border-[1px] border-gray-200"></div>
        </div>
      </>
    );
  };

  const descriptionHashTag = (key: string, hashTags: string[], optionalHashTags: string[], rightValue: string) => {
    return (
      <>
        <div className="flex flex-row items-center whitespace-pre pt-[16px]">
          <div className="min-h-[20px] w-[233px]">
            <span className="text-sm font-semibold leading-5 text-gray-700">{key}</span>
          </div>
          <div className="min-h-[20px] w-[445.75px] gap-2">
            <div className="flex">
              <div className="pt-[10px] text-sm font-bold leading-5 text-gray-900">必須</div>
              <div className="ml-auto pt-[10px] text-sm font-semibold leading-5 text-gray-900">{rightValue}</div>
            </div>
            {hashTags.map((item, index) => {
              return (
                <div key={index} className="flex pt-2">
                  <div className="inline-flex items-center rounded-md bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
                    {item}
                  </div>
                </div>
              );
            })}
            <div className="flex">
              <div className="whitespace-pre-line break-words pt-[10px] text-sm font-semibold leading-5 text-gray-900">
                任意
              </div>
            </div>
            {optionalHashTags.map((item, index) => {
              return (
                <div key={index} className="flex pt-2">
                  <div className="inline-flex items-center rounded-md bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800">
                    {item}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="h-[1px] w-[678.75px] pt-[16px]">
          <div className="border-[1px] border-gray-200"></div>
        </div>
      </>
    );
  };

  const descriptionItem = (key: string, value: string | string[] | string[][] | undefined) => {
    return (
      <>
        <div className="flex flex-row items-center whitespace-pre pt-[16px]">
          <div className="min-h-[20px] w-[233px]">
            <span className="text-sm font-semibold leading-5 text-gray-700">{key}</span>
          </div>
          <div className="min-h-[20px] w-[445.75px] gap-2">
            {typeof value === 'string' ? (
              <div
                className={`${
                  key == '投稿に含める広告表示' && value !== '-'
                    ? 'inline-flex items-center rounded-md bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800'
                    : 'whitespace-pre-line break-words pt-[10px] text-sm font-semibold leading-5 text-gray-900'
                }`}
              >
                <p className="whitespace-pre-line break-words text-sm font-semibold leading-5 text-gray-900">{value}</p>
              </div>
            ) : (
              value !== undefined &&
              value.map((item, index) => {
                return (
                  <div key={index} className="pt-[10px]">
                    <div
                      className={`${
                        key == '歓迎条件' && item !== '-'
                          ? 'inline-flex items-center rounded-md bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800'
                          : 'whitespace-pre-line break-words pt-[10px] text-sm font-semibold leading-5 text-gray-900'
                      }`}
                    >
                      {Array.isArray(item) ? (
                        <>
                          {item[0]}：{item[1]}
                          <br /> {/* 改行を追加 */}
                        </>
                      ) : (
                        item
                      )}
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
        <div className="h-[1px] w-[678.75px] pt-[16px]">
          <div className="border-[1px] border-gray-200"></div>
        </div>
      </>
    );
  };

  const checkParams = (): boolean => {
    return !(
      // 案件募集タイトル
      (
        projectTitleState.displayTitle.length > 0 &&
        projectTitleState.displayTags !== undefined &&
        projectTitleState.displayTags.length > 0 &&
        projectTitleState.displayImage !== undefined &&
        projectTitleState.displayImage.length > 0 &&
        // 詳細画像
        props.detailImages.length > 0 &&
        // 案件概要
        projectOverviewProps.displayRecruitmentPlanCount.length > 0 && // 採用予定人数
        projectOverviewProps.displayRewardAmount.length > 0 && // 報酬
        projectOverviewProps.project?.displayFollower !== undefined && // フォロワー数
        // 案件概要:応募条件
        projectOverviewProps.displayDescription.length > 0
      ) // 案件説明;
    );
  };

  const handleAgreeClick = useCallback(async () => {
    const response = await props.fetchSpiritProjectDetail();
    const minimumReward = response?.data.estimate.calculateReward.minimum ?? 5000;
    const inputReward = response?.data.summary.rewards ?? 0;
    const tasks = response?.data.tasks ?? [];
    if (inputReward < minimumReward) {
      setAgreeErrorMessage(ValidationMessages.moreThanTargetMessage('インフルエンサー報酬額', minimumReward));
      return;
    }
    const typesToCheck = [1, 10, 11, 12, 20, 21, 30, 40];
    if (!tasks.length || !tasks.some((task) => typesToCheck.includes(task.taskType))) {
      setAgreeErrorMessage('投稿稼働は最低1つ指定してください');
      return;
    }
    setAgreeErrorMessage('');
    setFinishAgreeModalIsOpen(true);
  }, [projectOverviewProps?.rewardAmount]);

  const finalAgree = async (): Promise<void> => {
    await insertPostApplicationInfo(projectId);
    setFinishAgreeModalIsOpen(false);
    props.setIsRequesting(true);
    setIsRequesting(true);

    // 取得したprojectDetailSpiritのprojectStatusを更新
    const projectDetailOutput: GetSpiritDetailOutput = await getSpiritDetail(projectId);
    props.setProjectDetailSpirit(projectDetailOutput);
    setProjectStatus(projectDetailOutput.header.projectStatus);
  };

  const handleEditClick = (index: number) => {
    if (index === 0) {
      setProjectTitleState((prev) => {
        return { ...prev, open: true };
      });
      return;
    } else if (index === 1) {
      setImageModalIsOpen(true);
      return;
    } else if (index === 2) {
      setProjectOverviewProps((prev) => {
        return {
          ...prev,
          open: true,
          project: {
            ...prev.project,
            reward: displayRewards?.toString(),
          },
        };
      });
      return;
    } else if (index === 3) {
      return;
    } else if (index == 4) {
      setOperationDetailProps((prev) => ({ ...prev, open: true }));
      return;
    } else if (index == 5) {
      setDetailSettingProps((prev) => ({ ...prev, open: true }));
      return;
    }
  };

  const PutSpiritTitleUpdate = (projectId: string) => async (title: string, tags: string[], featuredImage?: File) => {
    const response: GetSpiritDetailOutput = await putSpiritTitle(projectId, title, tags, featuredImage);
    props.setProjectDetailSpirit(response);
  };

  const PutSpiritMedia = (projectId: string) => async (uploads?: File[], deleteIds?: string[]) => {
    const response: GetSpiritDetailOutput = await putSpiritMedia(projectId, uploads, deleteIds);
    props.setProjectDetailSpirit(response);
    props.setDetailImages(
      response.detailImages.map((image) => ({
        id: image.id,
        objectKey: getImageUrl(image.objectKey),
      }))
    );
  };

  const PutSpiritPostOption = async (option: OptionAllType) => {
    const response: GetSpiritDetailOutput = await putSpiritPostOption(projectId, option);
    setOption(option);
    props.setProjectDetailSpirit(response);
  };

  const PutSpiritProjectNotice = (projectId: string) => async (isPublic: boolean, notice: string[]) => {
    const response: GetSpiritDetailOutput = await putSpiritNotice(projectId, {
      isPublic: isPublic,
      notice: notice,
    });
    props.setProjectDetailSpirit(response);
  };

  const PutSpiritProjectOverview = (projectId: string) => async () => {
    const putSpiritSummaryInput: PutSpiritSummaryInput = {
      adoptionPlan: Number(projectOverviewProps.project?.adoption),
      rewardsType: Number(projectOverviewProps.radioBoxValue),
      rewards: Number(projectOverviewProps.project?.reward),
      // rewards: 7777,
      // rewards: displayRewards,
      genres: projectOverviewProps.tags.length == 0 ? null : projectOverviewProps.tags,
      description: projectOverviewProps.description,
      minFollowers: Number(projectOverviewProps.project?.follower),
      gender:
        projectOverviewProps.project?.gender === 'none'
          ? null
          : (Number(projectOverviewProps.project?.gender) as PutSpiritSummaryInputGenderEnum),
      minAge: projectOverviewProps.project?.minAge === 'none' ? null : Number(projectOverviewProps.project?.minAge),
      maxAge: projectOverviewProps.project?.maxAge === 'none' ? null : Number(projectOverviewProps.project?.maxAge),
      prefectures:
        projectOverviewProps.selectedPrefectures.length === 0 ||
        projectOverviewProps.prefecturesRadioBoxValue === 'from_off'
          ? null
          : projectOverviewProps.selectedPrefectures.map((prefecture) => prefecture),
      minAnnualIncome:
        projectOverviewProps.project?.minIncome === 'none' ? null : Number(projectOverviewProps.project?.minIncome),
      maxAnnualIncome:
        projectOverviewProps.project?.maxIncome === 'none' ? null : Number(projectOverviewProps.project?.maxIncome),
      job: projectOverviewProps.project?.job === 'none' ? null : Number(projectOverviewProps.project?.job),
    };
    const response: GetSpiritDetailOutput = await putSpiritSummary(projectId, putSpiritSummaryInput);
    props.setProjectDetailSpirit(response);
  };

  const optionModalMap: OptionMapType = new Map([
    [
      'goodQuality',
      {
        title: 'Good Qualityボーナス',
        radioValues: [
          { value: '0', label: 'なし' },
          { value: '1', label: '30,000' },
          { value: '2', label: '50,000' },
        ],
        tipContent:
          'Good Quality ボーナスは、インフルエンサーが投稿したコンテンツのクオリティが高い場合に支払われるボーナスです。',
      },
    ],
    [
      'secondaryUse',
      {
        title: '二次利用',
        radioValues: [
          { value: '1', label: 'あり' },
          { value: '0', label: 'なし' },
        ],
        tipContent: `インフルエンサーの投稿に関して、著作権等は投稿したインフルエンサーに帰属します。
    そのため、インフルエンサーの投稿を貴社にて二次利用をご希望される場合には、インフルエンサーに許諾をとった上で二次利用費が発生いたします。
    具体的な金額は広告のクリエイティブとして利用するのか、会社HPやECサイトで利用するのか、顔出しされているクリエイティブかどうかなどで変わってきますので、営業担当にお問い合わせください。`,
      },
    ],
    [
      'adoptionOutsourced',
      {
        title: 'インフルエンサー選定代行',
        radioValues: [
          { value: '1', label: 'あり' },
          { value: '0', label: 'なし' },
        ],
        tipContent: '別途費用がかかりますが、インフルエンサーの選定をLIDDELL側で代行することが可能です。',
      },
    ],
  ]);

  // 日付フォーマット
  const formatDateTime = (dateTimeString: string) => {
    return new Intl.DateTimeFormat('ja-JP', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    }).format(new Date(dateTimeString));
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="pl-16">
          <span className="pt-[9px] text-lg font-medium leading-6 text-gray-900">募集内容詳細</span>
        </div>
        <div className="pr-16">
          {/* TODO バックエンド作成時はすべて、コンストに移行 */}
          {projectStatus === ProjectStatus.Draft && !isRequesting && (
            <div className="flex flex-col items-end">
              <Button
                variant="primary"
                size="sm"
                style={{ margin: '10px' }}
                disabled={checkParams()}
                onClick={handleAgreeClick}
              >
                この内容で申請する
              </Button>
              {!!agreeErrorMessage && <p className="text-base text-red-400">{agreeErrorMessage}</p>}
            </div>
          )}
          {/* {(projectStatus == ProjectStatus.Review || isRequesting) && ( */}
          {projectStatus == ProjectStatus.Review && isRequesting && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button variant="primary" size="sm" style={{ margin: '10px' }} disabled={true}>
                申請中
              </Button>
            </div>
          )}
        </div>
      </div>
      {!isRequesting && !checkParams() ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="mr-[12px] h-[20px] w-[20px]">
            <img src={ErrorImage} alt="" />
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">
              募集内容を確認し、申請をしてください。
            </p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              申請されましたら、LIDDELL担当者が再確認の上、募集に入ります。
            </p>
          </div>
        </div>
      ) : projectStatus == ProjectStatus.Review && isRequesting ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="mr-[12px] h-[20px] w-[20px]">
            <img src={ErrorImage} alt="" />
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">申請を受付けました。</p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              LIDDELL担当者が確認しておりますので、お待ちください。
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="flex flex-row">
        <div>
          <div className="flex pl-[40px]">
            <div className="mt-[10px] h-auto w-[726.5px] bg-white pb-[16px]">
              <div className="h-[44+48px] w-[695px] pl-[24px] pt-[24px]">
                <div className="flex h-[44+16px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">案件募集タイトル</span>
                  </div>
                  {projectStatus === ProjectStatus.Draft && !isRequesting ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(0)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="flex flex-row pl-4 pt-6">
                <div>
                  <img className="h-[116px] w-[174.12px]" src={projectTitleState.displayImage ?? NoImage} alt="" />
                </div>
                <div className="flex h-[116+24px] w-[695px] gap-[10px] pl-[16px]">
                  <div className="h-[28px] w-[504.88px] content-center pl-[16px]">
                    <div className="text-base font-semibold leading-7 ">{projectTitleState.displayTitle}</div>
                    <div className="flex gap-x-[10px] pt-[13px]">
                      {projectTitleState.sns === SnsType.Instagram ? (
                        <div>
                          <img src={instagram} alt="instagram" className="h-[22.34px] w-[22.34px]" />
                        </div>
                      ) : projectTitleState.sns === SnsType.X ? (
                        <div>
                          <img src={x} alt="twitter" className="h-[22.34px] w-[22.34px]" />
                        </div>
                      ) : projectTitleState.sns === SnsType.Youtube ? (
                        <div>
                          <img src={youtube} alt="youtube" className="h-[22.34px] w-[22.34px]" />
                        </div>
                      ) : projectTitleState.sns === SnsType.TikTok ? (
                        <div>
                          <img src={tiktok} alt="tiktok" className="h-[22.34px] w-[22.34px]" />
                        </div>
                      ) : projectTitleState.sns === SnsType.Other ? (
                        <div>
                          <img src={instagram} alt="instagram" className="h-[22.34px] w-[22.34px]" />
                        </div>
                      ) : (
                        <></>
                      )}

                      <div className="flex gap-x-[10px]">
                        {projectTitleState.displayTags &&
                          projectTitleState.displayTags.map((tag, index) => {
                            return (
                              <div key={index} className="pb-2">
                                <Badge color="gray" round="xl" size="xs" className="w-fit px-2 py-3">
                                  {tag}
                                </Badge>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="h-[176+48px] w-[695px] pl-[24px] pt-[48px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">詳細画像</span>
                  </div>
                  {projectStatus === ProjectStatus.Draft && !isRequesting ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(1)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="flex h-[116+24px] w-[678.75px] gap-[24px] pt-[24px]">
                  <img
                    className="h-[116px] w-[174.12px]"
                    src={props.detailImages.length > 0 ? props.detailImages[0].objectKey : NoImage}
                    alt=""
                  />
                  <img
                    className="h-[116px] w-[174.12px]"
                    src={props.detailImages.length > 1 ? props.detailImages[1].objectKey : NoImage}
                    alt=""
                  />
                  <img
                    className="h-[116px] w-[174.12px]"
                    src={props.detailImages.length > 2 ? props.detailImages[2].objectKey : NoImage}
                    alt=""
                  />
                </div>
              </div>
              <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">案件概要</span>
                  </div>
                  {projectStatus === ProjectStatus.Draft && !isRequesting ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(2)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="h-[1px] w-[678.75px] pt-[16px]">
                  <div className="border-[1px] border-gray-200"></div>
                </div>

                {descriptionItem(
                  '採用予定人数',
                  projectOverviewProps.displayRecruitmentPlanCount == '' ||
                    Number(projectOverviewProps.displayRecruitmentPlanCount) <= -1
                    ? '-'
                    : addComma(String(projectOverviewProps.displayRecruitmentPlanCount))
                )}
                {descriptionItem(
                  'インフルエンサーへの報酬',
                  projectOverviewProps.displayRewardAmount == '' ||
                    Number(projectOverviewProps.displayRewardAmount) <= -1
                    ? '-'
                    : '￥' + addComma(String(displayRewards))
                )}
                {descriptionItem('応募条件', createApplyCondition())}
                {descriptionItem(
                  '歓迎条件',
                  projectOverviewProps.displayTags.length == 0 ? '-' : projectOverviewProps.displayTags
                )}

                {descriptionItem(
                  '案件説明',
                  projectOverviewProps.displayDescription == '' ? '-' : projectOverviewProps.displayDescription
                )}
              </div>
              {/*オプション設定*/}
              <>
                <Option
                  projectType={ProjectType.Spirit}
                  projectStatus={
                    projectStatus === ProjectStatus.Draft && !isRequesting ? ProjectStatus.Draft : ProjectStatus.Review
                  }
                  optionMap={optionModalMap}
                  option={option}
                  setOption={setOption}
                  PutPostOption={PutSpiritPostOption}
                  isRequesting={isRequesting}
                />
              </>

              <div className="h-[326+40px] w-[692.75px] pl-[24px] pt-[40px]">
                <div className="flex h-[28px] w-[685.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">稼働内容詳細</span>
                  </div>
                  {projectStatus === ProjectStatus.Draft && !isRequesting && (
                    <div className="pl-[10px]">
                      <Button
                        variant="text"
                        size="md"
                        onClick={() => handleEditClick(4)}
                        style={{ padding: '0px' }}
                        className="right-4 bg-transparent text-blue-500"
                      >
                        編集
                      </Button>
                    </div>
                  )}
                </div>
                <>
                  {operationDetailProps.selected &&
                    operationDetailProps.selected.map((item, index) => (
                      <div key={index}>
                        <div className="flex h-[36px] w-[344px] content-center pt-[10px]">
                          <img src={detailOperationIcon(item)} alt="instagram" className="h-[40px] w-[40px]" />
                          <span className="pt-[10px] text-sm font-semibold leading-5 text-gray-400">{item.name}</span>
                        </div>
                        <div className="h-[1px] w-[678.75px] pt-[16px]">
                          <div className="border-[1px] border-gray-200"></div>
                        </div>
                        {detailOperationContent(item)}
                      </div>
                    ))}
                </>
              </div>
              <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">詳細設定</span>
                  </div>
                  {projectStatus === ProjectStatus.Draft && !isRequesting ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(5)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="h-[1px] w-[678.75px] pt-[16px]">
                  <div className="border-[1px] border-gray-200"></div>
                </div>
                {descriptionItem(
                  '注意事項',
                  detailSettingProps && detailSettingProps.notice && detailSettingProps.notice.length > 0
                    ? detailSettingProps.notice.map((notice) => `・${notice}`)
                    : '-'
                )}
                {descriptionItem(
                  '企業名公開',
                  detailSettingProps ? (detailSettingProps.isSecret ? '採用者のみ公開' : '募集時から全体に公開') : '-'
                )}
              </div>

              <div className="h-[176+48px] w-[695px] pl-[24px] pt-[48px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">スケジュール</span>
                  </div>
                </div>
              </div>
              <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px]">
                <div className="h-[1px] w-[678.75px] pt-[16px]">
                  <div className="border-[1px] border-gray-200"></div>
                </div>

                {props.projectDetailSpirit && props.projectDetailSpirit.schedules ? (
                  props.projectDetailSpirit.schedules
                    .filter((schedule) => !schedule.label.includes('フィードバック期間'))
                    .map((schedule) => {
                      return descriptionItem(
                        schedule.label,
                        schedule.from && schedule.to
                          ? `${formatDateTime(schedule.from)}〜${formatDateTime(schedule.to)}`
                          : '-'
                      );
                    })
                ) : (
                  <></>
                )}
                {
                  <>
                    <div className="flex flex-row items-center whitespace-pre pt-[16px]">
                      <div className="flex min-h-[20px] w-[233px] flex-col">
                        <span className="pb-[16px] text-sm font-semibold leading-5 text-gray-700">
                          フィードバック期間
                        </span>
                        <div className="flex flex-col pl-[10px]">
                          <span className="text-sm font-medium leading-5 text-gray-700">インフルエンサー</span>
                          <span className="pt-[8px] text-sm font-medium leading-5 text-gray-700">企業</span>
                        </div>
                      </div>
                      <div className="min-h-[20px] w-[445.75px] gap-2 pt-[36px]">
                        {props.projectDetailSpirit && props.projectDetailSpirit.schedules ? (
                          props.projectDetailSpirit.schedules
                            .filter((schedule) => schedule.label.includes('フィードバック期間'))
                            .map((schedule, index) => {
                              return (
                                <p
                                  className={clsx(
                                    'whitespace-pre-line break-words text-sm font-semibold leading-5 text-gray-900',
                                    index !== 0 && 'pt-[8px]'
                                  )}
                                  key={schedule.label}
                                >
                                  {schedule.from && schedule.to
                                    ? `${formatDateTime(schedule.from)}〜${formatDateTime(schedule.to)}`
                                    : '-'}
                                </p>
                              );
                            })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="h-[1px] w-[678.75px] pt-[16px]">
                      <div className="border-[1px] border-gray-200"></div>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
        </div>
        <div className="h-[689.76px] w-[286.25px]">
          <div className="h-[28+24px] w-[240+40px] pl-[40px] pt-[24px] text-base font-semibold leading-7 text-gray-500">
            インフルエンサー画面プレビュー
          </div>
          <div>
            <img className="h-[597.76px] w-[326.25px] pl-[40px] pt-[16px]" src={SmartPhone} alt="" />
          </div>
        </div>
      </div>

      <div>
        <ProjectRecruitingTitle
          projectRecruitngState={projectTitleState}
          setProjectRecruitingState={setProjectTitleState}
          PutTitle={PutSpiritTitleUpdate(projectId)}
        ></ProjectRecruitingTitle>
      </div>

      <div>
        <DetailImages
          projectType={ProjectType.Spirit}
          isOpen={imageModalIsOpen}
          setIsOpen={setImageModalIsOpen}
          detailImages={props.detailImages}
          PutPostMedia={PutSpiritMedia(projectId)}
        ></DetailImages>
      </div>

      <div>
        <ProjectOverview
          projectOverviewProps={projectOverviewProps}
          setProjectOverviewProps={setProjectOverviewProps}
          PutProjectOverview={PutSpiritProjectOverview(projectId)}
          projectId={projectId}
          displayRewards={displayRewards}
          setDisplayRewards={setDisplayRewards}
        />
      </div>

      <div>
        <ProjectSplitOperationDetails
          Popup_DetailArgs={operationDetailProps}
          setPopup_DetailArgs={setOperationDetailProps}
          setProjectDetailSpirit={props.setProjectDetailSpirit}
          fetchSpiritProjectDetail={props.fetchSpiritProjectDetail}
        />
      </div>

      {/*props.advancedSettings はレンダリングを行う際に必ず必要なので削除しない*/}
      <div>
        <PublicAttention
          detailSettings={detailSettingProps}
          setDetailSettings={setDetailSettingProps}
          PutProjectNotice={PutSpiritProjectNotice(projectId)}
        />
      </div>

      <div className="pl-[40px]">{KpiComponent(KpiArgs.items)}</div>

      <div className="pl-[40px]">{Estimate(props.projectDetailSpirit.estimate)}</div>

      <div>
        <FinalAgreeModal
          open={finishAgreeModalIsOpen}
          onClose={() => setFinishAgreeModalIsOpen(false)}
          onClickAgree={() => finalAgree()}
        />
      </div>
    </div>
  );
}
