import { DefaultApi as CreationApi } from '@/api/creation';
import { EmeraldPostApi } from '@/api/emeraldPost';
import { JaneJohnApi } from '@/api/janeJohn';
import { DefaultApi as OtherApi } from '@/api/other';
import { DefaultApi as ProjectApi } from '@/api/project';
import { DefaultApi as PrstApi } from '@/api/prst';
import { SNSApi } from '@/api/snsAccount';
import { axiosInstance } from '@/utils/axiosInstance';

export const useApiClients = () => {
  const creationApi = new CreationApi(undefined, undefined, axiosInstance);
  const emeraldPostApi = new EmeraldPostApi(undefined, undefined, axiosInstance);
  const janeJohnApi = new JaneJohnApi(undefined, undefined, axiosInstance);
  const otherApi = new OtherApi(undefined, undefined, axiosInstance);
  const projectApi = new ProjectApi(undefined, undefined, axiosInstance);
  const prstApi = new PrstApi(undefined, undefined, axiosInstance);
  const snsAccountApi = new SNSApi(undefined, undefined, axiosInstance);

  return {
    creationApi,
    emeraldPostApi,
    janeJohnApi,
    otherApi,
    projectApi,
    prstApi,
    snsAccountApi,
  };
};
