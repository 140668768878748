import { DefaultApi } from '@/api/team/api';
import { axiosInstance } from '@/utils/axiosInstance';

export const PostMemberInvite = async (
  email: string,
  setMessageVal: React.Dispatch<
    React.SetStateAction<{
      successFlag: boolean;
      message: string;
    }>
  >
): Promise<void> => {
  const api = new DefaultApi(undefined, undefined, axiosInstance);
  await api.postTeamInvite({ email: email });
  setMessageVal({ successFlag: true, message: email });
};
