import React, { useState, createContext, useContext } from 'react';

import { ErrorMessage, ErrorType } from '@/components/Elements/ErrorMessage/ErrorMessage';

type props = {
  children: React.ReactNode;
};

interface ErrorContextType {
  showErrorMessage: (open: boolean, errorType: ErrorType) => void;
}

const ErrorContext = createContext<ErrorContextType>({
  showErrorMessage: () => {},
});

export function useErrorContext() {
  return useContext(ErrorContext);
}

export const ErrorProvider: React.FC<props> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [errorType, setEerrorType] = useState<ErrorType>();
  const showErrorMessage = (open: boolean, errorType: ErrorType) => {
    setOpen(open);
    setEerrorType(errorType);
    if (open) {
      setTimeout(() => {
        setOpen(false);
      }, 5000);
    }
  };

  return (
    <React.Fragment>
      <ErrorContext.Provider value={{ showErrorMessage }}>
        {children}
        {open && (
          <div className="absolute inset-x-1/2 top-[40px] z-[100]">
            <div className="flex justify-center">
              <ErrorMessage errorType={errorType}></ErrorMessage>
            </div>
          </div>
        )}
      </ErrorContext.Provider>
    </React.Fragment>
  );
};
