import { createContext, useContext, useState } from 'react';

const inistialState = true;

const AccountListStateContext = createContext<{
  state: boolean;
  setState: React.Dispatch<React.SetStateAction<boolean>>;
}>({
  state: inistialState,
  setState: () => {},
});

export const AccountListStateProvider = ({ children }: { children: React.ReactNode }) => {
  const [state, setState] = useState<boolean>(inistialState);

  return <AccountListStateContext.Provider value={{ state, setState }}>{children}</AccountListStateContext.Provider>;
};

export const useStateContext = () => {
  const context = useContext(AccountListStateContext);
  if (!context) {
    throw new Error('useStateContext must be used within an StateProvider');
  }
  return context;
};
