import { Combobox } from '@headlessui/react';
import clsx from 'clsx';
import React from 'react';

import { ProjectGroup } from '@/api/project';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements/Button';
import { Input } from '@/components/Elements/Input';

interface modalProps {
  isStatusOrder: boolean;
  handleClose: () => void;
  projectManagementName: string;
  projectName: string;
  setModalValue: (value: string, name: string) => void;
  projectGroups: Array<ProjectGroup>;
  handleSelectExistProject: (projectGroupId: string, name: string) => void;
  handleCreateProject: () => void;
  apiError?: string;
  projectNameError: boolean;
  projectNameErrorMessage: string;
  projectGroupNameError: boolean;
  inputProjectGroupName: string;
  selectedProjectGroupName: string;
}

export default function Order(props: modalProps): React.ReactElement {
  const handleChangeName = (value: string, name: string) => {
    props.setModalValue(value, name);
  };

  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="w-[512px] rounded-lg bg-white p-6">
        <div className="flex-none">
          <div className="flex w-[464px] text-center">
            <span className="text-lg font-medium leading-6">案件名を入力ください</span>
            <div className="ml-auto">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={props.handleClose} />
            </div>
          </div>
          <div className="pt-4">
            <span className="text-sm font-normal leading-5 text-[#6B7280]">
              案件を判別しやすいようにわかりやすい名前をつけてください。
            </span>
          </div>
          <div className="pt-4">
            <Input
              id="name"
              type="text"
              placeholder="案件名"
              label="案件名"
              className="w-[438px]"
              classnamelabel="pt-0"
              isPlaceHolderRight={false}
              value={props.projectName}
              onChange={(e: any) => handleChangeName(e.target.value, 'projectName')}
            ></Input>
            {props.projectNameError && <p className="text-red-500">{props.projectNameErrorMessage}</p>}
          </div>
          <div>
            <Combobox value={props.inputProjectGroupName}>
              <span className={clsx('block text-sm font-semibold text-gray-700')}>プロジェクト名</span>
              <Combobox.Input
                className="flex h-10 w-full items-center justify-center rounded-md border-[1px] border-gray-300 font-medium placeholder:text-gray-500 hover:opacity-80 focus:outline-none disabled:cursor-not-allowed disabled:opacity-70"
                placeholder="プロジェクト名"
                value={props.projectManagementName}
                onChange={(e: any) => handleChangeName(e.target.value, 'projectManagementName')}
              />
              <Combobox.Options className="scroll-py-2 overflow-y-auto  rounded-lg border border-gray-300 text-sm text-gray-800">
                {props.projectGroups.map((group) => (
                  <Combobox.Option
                    key={group.projectGroupId}
                    value={group.name}
                    className={({ active }) =>
                      clsx(
                        'cursor-default select-none px-4 py-2',
                        active &&
                          'bg-white text-sm font-normal leading-5 text-gray-900  hover:bg-gray-300 hover:font-semibold'
                      )
                    }
                    onClick={() => {
                      props.handleSelectExistProject(group.projectGroupId ?? '', group.name ?? '');
                    }}
                  >
                    {group.name}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
              {props.projectGroupNameError && (
                <p className="text-red-500">プロジェクト名は50文字以下で入力してください</p>
              )}
              <div className="w-[438px]">
                <span className="text-sm font-medium leading-5 text-[#9CA3AF]">
                  プロジェクトを設定することで、別の案件と紐付けて管理することができます
                </span>
              </div>
            </Combobox>
          </div>
        </div>
        <div className="">
          <div className="flex w-[464px] flex-row items-center justify-end gap-3 p-0">
            <Button variant="white" onClick={props.handleClose}>
              キャンセル
            </Button>
            <Button
              disabled={props.projectName.length == 0 || props.projectNameError || props.projectGroupNameError}
              onClick={props.handleCreateProject}
            >
              決定
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
