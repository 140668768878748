import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';

import { ReactComponent as ArrowDropDownIcon } from '@/assets/icons/ArrowDropDown.svg';
import { ReactComponent as ChevronLeftIcon, ReactComponent as ChevronLeft } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as ChevronsLeftIcon } from '@/assets/icons/bx-chevrons-left.svg';
import { ReactComponent as ChevronsRightIcon } from '@/assets/icons/bx-chevrons-right.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as SortUpIcon } from '@/assets/icons/bx-sort-up.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';
import { TextArea } from '@/components/Elements/TextArea';

import { Post, postScheduleDate, PostType } from '../interfaces/PRSTProductionManagementInterface';

import { useAccount } from './AccountContext';
import { ReactComponent as ArtBoardImage } from './assets/ArtBoard7.svg';
import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as GritIcon } from './assets/bx-grid.svg';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import { ReactComponent as Media0005Image } from './assets/MediaImage0005.svg';
import { ReactComponent as TeamWorkImage } from './assets/Team work_Two Color 2.svg';
import { useUI } from './UIContext';

const numbers = [1, 2, 3, 4, 5, 6, 7];

export const PostListView = () => {
  const [postTitle, setPostTitle] = useState('');
  const type = PostType.slect;
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
    setOpen1(false);
  };

  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen(false);
    setOpen1(true);
  };

  const [open2, setOpen2] = useState<boolean>(false);

  // const handleClick2 = () => {
  //   const newPost: Post = {
  //     postTitle,
  //     postType: type,
  //     postScheduleDate: postScheduleDate.postScheduleDate,
  //     imageProposal: [],
  //   };
  //   addPost(newPost);
  //   addSelectedPost(newPost);
  //   setPostTitle('');
  //   setOpen1(false);
  //   setOpen2(true);
  // };

  const { accounts, selectedAccount, addPost, addSelectedPost, selectedProject, selectedPost, changeSelectedPost } =
    useAccount();
  const { setIsProject, setIsProjectDetail, setIsImage } = useUI();

  const handleClick3 = () => {
    const newPost: Post = {
      postTitle: postTitle,
      postType: type,
      postScheduleDate: postScheduleDate.postScheduleDate,
      status: 1,
      // imageProposal: [],
      // contents: [],
      imageApproved: false,
      contentApproved: false,
    };
    addPost(newPost);
    addSelectedPost(newPost);
    setPostTitle('');
    setIsProjectDetail(true);
    setIsImage(true);
  };

  // const handleClick4 = () => {
  //   const newPost: Post = {
  //     postTitle: postTitle,
  //     postType: type,
  //     postScheduleDate: postScheduleDate.postScheduleDate,
  //     imageProposal: [],
  //   };
  //   addPost(newPost);
  //   addSelectedPost(newPost);
  //   setPostTitle('');
  //   setIsProjectDetail(true);
  //   setIsImage(false);
  // };

  const handleClick5 = () => {
    setIsProject(false);
    console.log('登録されたアカウント全て（施作一覧）:', accounts);
    console.log('選択中のアカウント（施作一覧）:', selectedAccount);
    console.log('登録されているプロジェクト一覧(施策一覧):', selectedAccount?.projects);
  };

  useEffect(() => {
    console.log('---PostListView.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
    console.log('作成されたアカウント全て（accounts）:', accounts);
  }, [selectedAccount, accounts, selectedProject, selectedPost]);

  const selectPost = (post: Post) => () => {
    changeSelectedPost(post);
    setIsProjectDetail(true);
    setIsImage(true);
  };

  const AddPostModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[512px] rounded-lg bg-white p-[24px]">
                  <div className="w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">新規投稿制作</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-[320px]">
                        <div className="mb-[4px] h-[20px] w-[70px]">
                          <span className="mb-[4px] text-sm font-semibold leading-5 text-gray-700">投稿予定日</span>
                        </div>
                        <DatePicker width="320px" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick1}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const AddPostCompletionModal = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[403px] w-[380px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[355px] w-[332px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[24px] w-[332px]">
                      <div className="flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">新規投稿制作</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={handleClick} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-auto mb-[32px] flex h-[189px] w-[311px] justify-center">
                      <div>
                        <ArtBoardImage />
                      </div>
                    </div>
                    <div className="h-[78px] w-[332px]">
                      <div className="mb-[16px] flex h-[42px] w-[332px] flex-row items-center justify-end gap-3">
                        <Button
                          variant="white"
                          size="md"
                          className="whitespace-nowrap text-sm font-medium leading-5"
                          onClick={handleClick3}
                        >
                          あとで
                        </Button>
                        <Button
                          variant="primary"
                          size="md"
                          className="whitespace-nowrap text-sm font-medium leading-5"
                          onClick={handleClick3}
                        >
                          イメージ案作成へ進む
                        </Button>
                      </div>
                      <div className="flex w-[332px] justify-end">
                        <button
                          className="whitespace-nowrap text-sm font-medium leading-5 text-gray-500"
                          onClick={handleClick3}
                        >
                          スキップしてコンテンツ制作からはじめる
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  const AddImageModal = () => {
    return (
      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[723px] w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[675px] w-[720px] bg-white">
                    <div className="mb-[24px] flex h-[613px] w-[720px]">
                      <div className="h-[613] w-[696]">
                        <div className="mb-[40px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="h-[549px] w-[696px]">
                          <div className="mb-[24px] h-[367px] w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-[261px] w-[693px]">
                              <div className="mb-[8px] flex h-[44px] w-[693px] items-center justify-start">
                                <span className="text-sm font-semibold leading-5 text-gray-700">1枚目</span>
                              </div>
                              <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                                  <div className="h-[120px] w-[220px] pb-[4px]">
                                    <div className="flex items-center justify-center">
                                      <div className="flex h-[48px] w-[48px] items-center justify-center">
                                        <AddIcon width={36} height={36} />
                                      </div>
                                    </div>
                                    <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                      <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-gray-600">
                                          ドラッグ&ドロップもしくは
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                          アップロード
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <span className="text-xs font-normal leading-4 text-gray-500">
                                        対応ファイルはPNG, JPG, GIF,mp4
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="h-[209px] w-[416px]">
                                  <div className="mb-[4px] h-[20px] w-[111px]">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">
                                      画像 / 動画の説明
                                    </span>
                                  </div>
                                  <TextArea className="h-[185px] w-[416px]" />
                                </div>
                              </div>
                              <div className="h-[70px] w-[693px] p-[16px]">
                                <Button
                                  startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                  variant="secoundary"
                                  size="smNopadding"
                                  className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                                  onClick={handleClick1}
                                >
                                  メディアイメージを追加する
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="h-[158px] w-[696px]">
                            <div className="mb-[4px] h-[20px] w-[56px]">
                              <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                            </div>
                            <TextArea className="h-[134px] w-[696px]" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen2(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen2(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div>
        <div className="flex w-[1181px] border-b border-gray-200 py-[18px] pl-[16px]">
          <button onClick={handleClick5}>
            <ChevronLeft width={24} height={24} fill="#9CA3AF" />
          </button>
          <p className="ml-[16px] text-base font-bold leading-6 text-gray-700">{selectedProject?.projectName}</p>
        </div>
        <div className="w-[940px] px-[24px] py-[16px]">
          <div>
            <div className="mb-[16px] flex w-[1133px] items-center justify-between">
              <p className="text-base font-semibold leading-7 text-gray-700">投稿制作一覧</p>
              <div className="flex justify-end">
                <Button
                  startIcon={<GritIcon className="h-[20px] w-[20px]" />}
                  size="md"
                  variant="white"
                  className="whitespace-nowrap text-sm font-medium leading-5"
                  style={{
                    marginRight: '16px',
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    paddingLeft: '10px',
                    paddingRight: '12px',
                  }}
                >
                  並び順プレビュー
                </Button>
                <Button
                  startIcon={<PlusImage className="h-[20px] w-[20px]" />}
                  size="md"
                  className="whitespace-nowrap text-sm font-medium leading-5"
                  style={{
                    paddingTop: '9px',
                    paddingBottom: '9px',
                    paddingLeft: '10px',
                    paddingRight: '12px',
                  }}
                  onClick={handleClick}
                >
                  投稿制作を新規追加
                </Button>
              </div>
            </div>
            {selectedProject?.posts.length !== 0 ? (
              <div className="h-[475px] w-[1133px] rounded-lg border border-gray-200 bg-[#FFFFFF] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
                <div className="h-[413px] w-[1133px]">
                  {/* 1列目 */}
                  <div className="flex h-[49px] w-full border-b border-gray-200">
                    <div className="h-[48px] w-[88px] px-[24px] py-[16px] text-xs font-normal leading-4 text-gray-500">
                      <div className="flex h-[16px] w-[43px] items-center justify-between">
                        <p className="h-[16px] w-[19px]">NO</p>
                        <SortUpIcon width={16} height={16} />
                      </div>
                    </div>
                    <div className="h-[48px] w-[218px] px-[24px] py-[16px]">
                      <div className="flex h-[16px] w-[87px] items-center justify-between">
                        <p className="h-[16px] w-[63px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                          投稿予定日
                        </p>
                        <SortUpIcon width={16} height={16} />
                      </div>
                    </div>
                    <p className="h-[48px] w-[141px] whitespace-nowrap px-[24px] py-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                      メディアイメージ
                    </p>
                    <p className="h-[48px] w-[234px] whitespace-nowrap px-[24px] py-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                      テキストイメージ
                    </p>
                    <div className="h-[48px] w-[118px] px-[24px] py-[16px]">
                      <div className="flex h-[16px] w-[62px] items-center justify-between">
                        <p className="h-[16px] w-[38px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                          タイプ
                        </p>
                        <SortUpIcon width={16} height={16} />
                      </div>
                    </div>
                    <div className="h-[48px] w-[157px] px-[24px] py-[16px]">
                      <div className="flex h-[16px] w-[87px] items-center justify-between">
                        <p className="h-[16px] w-[63px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                          ステータス
                        </p>
                        <SortUpIcon width={16} height={16} />
                      </div>
                    </div>
                    <div className="h-[48px] w-[122px] px-[24px] py-[16px]">
                      <div className="flex h-[16px] w-[74px] items-center justify-between">
                        <p className="h-[16px] w-[50px] whitespace-nowrap text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                          コメント
                        </p>
                        <SortUpIcon width={16} height={16} />
                      </div>
                    </div>
                  </div>
                  {/* 2列目 */}
                  {selectedProject?.posts.map((post: Post, index: number) => (
                    <button
                      className="flex h-[73px] w-full border-b border-gray-200"
                      key={index}
                      onClick={selectPost(post)}
                    >
                      <p className="h-[72px] w-[88px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                        {post.postTitle}
                      </p>
                      <p className="h-[72px] w-[218px] whitespace-nowrap px-[24px] py-[26px] text-sm font-normal leading-5 text-gray-700">
                        {post.postScheduleDate}
                      </p>
                      <div className="flex h-[72px] w-[141px] items-center px-[24px] py-[16px]">
                        <Media0005Image width={40} height={40} />
                      </div>
                      <div className="flex h-[72px] w-[234px] items-center px-[24px] py-[16px]">
                        <p className="align-middle text-xs font-normal leading-4 text-gray-700">
                          以下の強みを入れてください。...
                        </p>
                      </div>
                      <div className="h-[72px] w-[118px] px-[24px] py-[26px]">
                        <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                          {post.postType}
                        </p>
                      </div>
                      <div className="h-[72px] w-[157px] px-[24px] py-[26px]">
                        <p className="h-[20px] w-[128px] whitespace-nowrap rounded bg-gray-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-gray-700">
                          コンテンツ制作待ち
                        </p>
                      </div>
                      <p className="h-[72px] w-[120px] whitespace-nowrap px-[24px] py-[26px] text-center text-sm font-normal leading-5 text-gray-700">
                        1
                      </p>
                      <div className="flex h-[72px] w-[57px] items-center px-[9px] py-[24px]">
                        <DotsHorizontalIcon width={24} height={24} />
                      </div>
                    </button>
                  ))}
                  {/* 3列目 */}
                  {/* 4列目 */}
                  {/* 5列目 */}
                  {/* 6列目 */}
                </div>
                {/* aaa */}
                <div className="flex h-[62px] items-center justify-between px-[24px] py-[12px]">
                  <div className="flex h-[24px] w-[268px] items-center justify-between">
                    <div className="flex h-[24px] w-[172px] items-center justify-between">
                      <p className="h-[16px] w-[124px] align-middle text-xs font-normal leading-4 text-gray-500">
                        １ページあたりの行数:
                      </p>
                      <div className="flex h-[24px] w-[40px] items-center justify-between">
                        <p className="h-[16px] w-[16px] align-middle text-xs font-normal leading-4">30</p>
                        <ArrowDropDownIcon width={24} height={24} />
                      </div>
                    </div>
                    <p className="h-[20px] w-[88px] align-middle text-sm font-normal leading-5 text-gray-500">
                      31-60 of 300
                    </p>
                  </div>
                  <div className="flex h-[38px] items-center border-[1px] border-gray-200">
                    <div className="flex h-[38px] w-[40px] items-center justify-center border-r-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                      <ChevronsLeftIcon width={20} height={20} fill="#6B7280" />
                    </div>
                    <div className="flex h-[38px] w-[40px] items-center justify-center border-r-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                      <ChevronLeftIcon width={20} height={20} fill="#6B7280" />
                    </div>
                    {numbers.map((value: any, index: any) => (
                      <li
                        key={index}
                        className="flex h-[38px] w-[40px] items-center justify-center border-r-[1px] border-gray-200 text-sm font-medium leading-5 text-gray-500 hover:bg-[#E0F4FF]"
                      >
                        {value}
                      </li>
                    ))}
                    <div className="flex h-[38px] w-[40px] items-center justify-center hover:bg-[#E0F4FF]">
                      <ChevronRightIcon width={20} height={20} fill="#6B7280" />
                    </div>
                    <div className="flex h-[38px] w-[40px] items-center justify-center border-l-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                      <ChevronsRightIcon width={20} height={20} fill="#6B7280" />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="flex h-[525px] w-[1133px] items-center justify-center rounded shadow-[0_1px_3px_rgba(0,0,0,0.2),0_2px_1px_rgba(0,0,0,0.12),0_1px_1px_rgba(0,0,0,0.14)]">
                <div>
                  <TeamWorkImage />
                  <div className="flex justify-center">
                    <p className="w-[294px] text-center text-[14px] font-normal leading-6 text-[#6B7280]">
                      まだ施策がありません。
                      <br />
                      右上のボタンから施策を作成してみましょう！
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="relative">{AddPostModal()}</div>
        <div className="relative">{AddPostCompletionModal()}</div>
        <div className="relative">{AddImageModal()}</div>
      </div>
    </>
  );
};
