import React from 'react';

type LayoutProps = {
  children: React.ReactNode;
  showLoading: boolean;
};

export default function Layout({ children, showLoading }: LayoutProps) {
  return (
    <>
      <div>{children}</div>
      {showLoading ? (
        <div className="fixed left-0 top-0 flex h-screen w-screen items-center justify-center bg-black/25">
          <div className="h-12 w-12 animate-spin rounded-full border-4 border-gray-200 border-l-gray-500" />
        </div>
      ) : (
        <div></div>
      )}
    </>
  );
}
