import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useState, Fragment } from 'react';

import QueueImage from './assets/bx-add-to-queue.svg';
import AddImage from './assets/bx-plus.svg';
import SampleImage from './assets/Sample-image.png';
import DeletePopup from './DeletePopup';
import SvgTrash from './SvgTrash';

export default function ImageEdit(isImageEdit: boolean, setIsImageEdit: React.Dispatch<React.SetStateAction<boolean>>) {
  const setNgStatus = () => {
    setIsImageEdit(false);
  };

  const [open, setOpen] = useState(false);
  const change_status = () => {
    setOpen(true);
  };

  const contentsText =
    '①「Canon360」という商品名を入れてください。\n\n② イベント情報入れてださい。以下の内容をいれていただければ、自由に文章を作っていただいて大丈夫です！文章のトーンは、絵文字などを使って、読みやすく明るい感じでお願いします。\n\n「バレンタインデーフォトイベント」\n期間：2021/02/01 - 2/14\nあなたの「愛するもの」をCanonのカメラで撮ってください！\n愛するものはなんでも構いません。\nタグ付け@xxxと、フォローをしてください。（当選者連絡用のため）\n賞金15万円\n';

  return (
    <Transition.Root show={isImageEdit} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsImageEdit}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <Dialog.Panel className="w-64"> */}
              <Dialog.Panel className="relative w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      setNgStatus();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex">
                  <div className=" sm:flex sm:items-start">
                    <div className="text-center sm:text-left">
                      <Dialog.Title as="h3" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                        イメージ案を編集
                      </Dialog.Title>
                      <div className="mt-[24px] text-sm font-semibold text-gray-700">メディア</div>
                      {/* 1枚目↓ */}
                      <div className="mt-[8px] flex items-center justify-between">
                        <div className="text-sm font-semibold text-gray-700">1枚目</div>
                        <div className="flex items-center justify-center">
                          <ChevronDownIcon className="h-7 w-7" aria-hidden="true" />
                          <ChevronUpIcon className="ml-[10px] h-7 w-7 fill-gray-200" aria-hidden="true" />
                          <div className="items-center">
                            <SvgTrash className="ml-[10px] h-7 w-7 fill-gray-700 pt-[5px]" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="h-[210px] w-[240px]">
                          <img src={SampleImage} alt="" />
                        </div>
                        <div className="ml-[40px] flex h-[209px] w-[416px] flex-col">
                          <div className="text-xs leading-4">画像 / 動画の説明</div>
                          <textarea className="mt-[8px] h-[200px] rounded-md border-b-[1px] border-gray-400"></textarea>
                        </div>
                      </div>
                      {/* ボーダー↓ */}
                      <div className="my-[24px] border-b-[1px] border-gray-200 " />
                      {/* 2枚目↓ */}
                      <div className="mt-[8px] flex items-center justify-between">
                        <div className="text-sm font-semibold text-gray-700">2枚目</div>
                        <div className="flex items-center justify-center">
                          <ChevronDownIcon className="h-7 w-7 fill-gray-200" aria-hidden="true" />
                          <ChevronUpIcon className="ml-[10px] h-7 w-7 " aria-hidden="true" />
                          <div className="items-center">
                            <SvgTrash className="ml-[10px] h-7 w-7 fill-gray-700 pt-[5px]" aria-hidden="true" />
                          </div>
                        </div>
                      </div>
                      <div className="flex">
                        <div className="h-[210px] w-[240px] rounded-md border-[1px] border-dashed border-gray-400">
                          <div className="flex flex-col px-[26px] py-[22px]">
                            <div className="mt-[28px] flex justify-center">
                              <img src={QueueImage} alt="" />
                            </div>
                            <p className="text-center text-sm font-normal leading-5 text-gray-600">
                              ドラッグ&ドロップもしくは
                            </p>
                            <p className="text-center text-sm font-normal leading-4 text-blue-400">アップロード</p>
                            <p className="whitespace-nowrap  text-center text-xs font-normal leading-4 text-gray-500">
                              対応ファイルはPNG, JPG, GIF,mp4
                            </p>
                          </div>
                        </div>
                        <div className="ml-[40px] flex h-[209px] w-[416px] flex-col">
                          <div className="text-xs leading-4">画像 / 動画の説明</div>
                          <textarea className="mt-[8px] h-[200px] rounded-md border-b-[1px] border-gray-400"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="button"
                  className="mt-[19px] inline-flex h-[38px] w-[1８６px] items-center gap-x-1.5 rounded-md  border border-blue-500 px-[17px] py-[9px] text-xs font-semibold text-blue-500 shadow-sm"
                >
                  <img src={AddImage} alt="" className="h-5 w-5" />
                  メディアイメージを追加する
                </button>

                <div className="mt-[24px] flex h-[209px] w-[416px] flex-col items-start gap-[8px]">
                  <div>
                    <div className="text-sm font-semibold leading-5 text-gray-700">テキスト</div>
                    <textarea className="mt-[4px] h-[200px] w-[664px] whitespace-pre-wrap rounded-md border-b-[1px] border-gray-400 px-[16px] py-[8px] text-xs">
                      {contentsText}
                    </textarea>
                  </div>
                </div>

                <div className="mt-[32px]">
                  <a href="#" onClick={() => change_status()}>
                    <div className="pl-3 text-sm text-red-600">削除する</div>
                  </a>
                </div>
                <div>{DeletePopup(open, setOpen, 'イメージ案')}</div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      // 特別 Alert.tsx を表示させる
                      setNgStatus();
                    }}
                  >
                    保存する
                  </button>
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-black shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#fff' }}
                    onClick={() => {
                      // 特別 Alert.tsx を表示させる
                      setNgStatus();
                    }}
                  >
                    キャンセル
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
