import React from 'react';

import { FormData } from '../interfaces/project';

import projectDetail from './ProjectDetail';
import projectGuide from './ProjectGuide';
import projectInputDetail from './ProjectInputDetail';

export interface FormProps {
  payload: FormData;
}

export default function ConfirmForm(props: FormProps): React.ReactElement {
  return (
    <>
      <div id="input_frame" className="mx-auto w-[860px]">
        <div id="project_detail" className="pb-12">
          {projectDetail(props)}
        </div>
        <div id="input-data" className="mx-auto pb-12">
          {projectInputDetail(props)}
        </div>
        <div id="project-guide" className="mx-auto">
          {projectGuide()}
        </div>
      </div>
    </>
  );
}
