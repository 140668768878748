export type BackGroundedTextProps = {
  text: string;
};
export const BackGroundedText = (props: BackGroundedTextProps) => {
  return (
    <div className="w-full rounded-md bg-gray-50 px-[13px] py-[9px]">
      <span className="text-sm font-normal leading-5 text-gray-900">{props.text}</span>
    </div>
  );
};
