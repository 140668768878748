import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Arrow_Right from '@/assets/icons/Arrow_Right.png';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as LikeIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as UploadIcon } from '@/assets/icons/bx-upload.svg';
import { ReactComponent as StarStrokeIcon } from '@/assets/icons/star-stroke.svg';
import { ReactComponent as StarIcon } from '@/assets/icons/star.svg';
import youtube from '@/assets/youtube.png';
import { Badge } from '@/components/Elements/Badge';

import YoutubeSample from '../assets/yotubesample.png';
import { InsightPost } from '../youtube/summary';

import { modalYoutubeProps_Janejone_Other, modalYoutubeProps_SPIRIT, TabProps } from './modalInterface';
import AccountInsight from './tabs/AccountInsight';
import AccountInsightYoutube from './tabs/PostInsightYoutube';
import RecruitmentInformation from './tabs/RecruitmentInformation';
import FollowerAttribute from './tabs/youtube/FollwerAttributes';

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/modalCommon.tsx を使用してください
export default function ModalYoutube(props: modalYoutubeProps_Janejone_Other | modalYoutubeProps_SPIRIT) {
  const [tabKey, setTabKey] = useState(props.initialTabKey);
  const search = useLocation().search;
  const selectTab = (): TabProps[] => {
    const selectTabs = [
      { name: 'アカウントインサイト', key: 'accountInsight' },
      { name: 'チャンネル登録者属性', key: 'follower' },
      { name: '採用前情報', key: 'information' },
    ];
    const query = new URLSearchParams(search);
    const type = query.get('type');
    if (type == 'SPIRIT') {
      selectTabs.unshift({ name: '投稿インサイト', key: 'postInsight' });
    }
    return selectTabs;
  };
  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };

  const handeRightTab = () => {
    const index = selectTab().findIndex((tab: TabProps) => tab.key == tabKey);
    if (index == selectTab().length - 1) {
      setTabKey(selectTab()[0].key);
    } else {
      setTabKey(selectTab()[index + 1].key);
    }
  };

  const selectContent = () => {
    if (tabKey == 'postInsight') {
      return (
        <AccountInsightYoutube
          id={props.accountInformation.influencerId}
          image={YoutubeSample}
          selected="1回目（フィード）"
          insight={InsightPost('w-[312px]', 51987, 51987, 71897, 0.25, 51987)}
          video={false}
          URL={'https://www.tiktok.com/@elonmusk/video/6951951988220303366'}
        />
      );
    } else if (tabKey == 'accoutPost') {
      return <></>;
    } else if (tabKey == 'accountInsight') {
      return (
        <AccountInsight
          insights={[
            {
              left: {
                icon: <GroupIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'チャンネル登録者数',
                value: '51,897',
                className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
              },
              right: {
                icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
                label: '視聴回数',
                value: '51,897',
                className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
              },
            },
            {
              left: {
                icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'エンゲージメント率',
                value: '0.25%',
                className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
              },
              right: {
                icon: <HeartIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'エンゲージメント数',
                value: '1,000',
                className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
              },
            },
            {
              left: {
                icon: <LikeIcon width={24} height={24} fill="#FFFFFF" />,
                label: '高評価数（平均）',
                value: '71,897',
                className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
              },
              right: {
                icon: <CommentIcon width={24} height={24} fill="#FFFFFF" />,
                label: 'コメント数(平均)',
                value: '51,897',
                className: 'w-[519px] h-[100px] justify-center whitespace-pre-wrap',
              },
            },
            {
              left: {
                icon: <PlayIcon width={24} height={24} fill="#FFFFFF" />,
                label: '視聴回数率',
                value: '80.00%',
                className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
              },
              right: {
                icon: <UploadIcon width={24} height={24} fill="#FFFFFF" />,
                label: '動画数',
                value: '51,897',
                className: 'w-[519px] h-[120px] justify-center whitespace-pre-wrap',
              },
            },
          ]}
        />
      );
    } else if (tabKey == 'follower') {
      return <FollowerAttribute />;
    } else {
      return (
        <RecruitmentInformation
          enthusiasm={`普段から、ギフトチケットを利用しているので具体的かつ比較しながら、貴社のサービスを紹介できます！`}
          comments={`フォロワー数が多く、リーチ数が見込めます。`}
          introduction={`元美容部員の美容系動画クリエイター、美容ライターのアカウント。\nコスメ紹介、メイク動画をアップしています。`}
          strong={`プチプラからハイブランドまで幅広くコスメを紹介しています。\n元美容部員の経験を活かし、真似しやすいメイク方法をアップしています。`}
          achievements={`・アカウント運用から1年でフォロワー数1万人突破\n・2023年1月 株式会社○○のPR案件を担当\n・コスメブランド○○をプロデュース"`}
        />
      );
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-[75%] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto pb-[400px] pt-[140px]">
          <div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-[1096px] overflow-hidden rounded-lg bg-white p-6 text-left shadow-xl transition-all">
                <div className="flex h-8 items-center justify-between self-stretch">
                  <div className="flex items-start gap-4">
                    <span className="text-lg font-medium leading-6 text-gray-900">SNSアカウントの詳細</span>
                  </div>
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => props.setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex items-start gap-2 p-[1px]">
                  <div className="flex w-[1084px] flex-col gap-6 self-stretch">
                    {/* account information */}
                    <div className="flex h-[71px] items-start gap-[15px] self-stretch">
                      <img src={props.accountInformation.thumbnail} alt="" />
                      <div className="flex flex-col items-start gap-[10px] self-stretch">
                        <div className="flex items-center gap-1">
                          <span className="text-sm font-medium text-gray-500">
                            ID {props.accountInformation.influencerId}
                          </span>
                        </div>
                        <div className="flex items-center gap-[19px]">
                          <div className="flex items-center">
                            <div className="flex items-center gap-[3px]">
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarIcon height={16} width={16} fill="#ECC94B" />
                              <StarStrokeIcon height={16} width={16} fill="#ECC94B" />
                            </div>
                            <div>
                              <span className="text-sm font-medium text-gray-500">（13）</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-[2px]">
                            <span className="text-sm font-medium text-gray-500">SNS</span>
                            <img src={youtube} alt="" className="h-[17.9px] w-[17.9px]" />
                          </div>
                          <div className="flex items-center gap-[6px]">
                            <div className="flex items-start gap-1">
                              <span className="text-sm font-medium text-gray-500">チャンネル登録者</span>
                              <span className="text-sm font-medium text-gray-500">10,000人</span>
                            </div>
                          </div>
                          <div className="flex items-center gap-2">
                            <span className="text-sm font-medium text-gray-500">補欠採用</span>
                            <Badge color="green" round="xl" className="px-[10px]">
                              あり
                            </Badge>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* tabs */}
                    <div className="flex flex-col items-start gap-4 self-stretch">
                      <div className="inline-flex w-[1280px] items-start gap-8  border-b-[1px] border-gray-200">
                        {selectTab().map((tab: TabProps) => {
                          return (
                            <button
                              key={tab.key}
                              className={clsx('flex flex-col items-center justify-center')}
                              onClick={() => {
                                handleTabSelct(tab.key);
                              }}
                            >
                              <div
                                className={clsx(
                                  'flex items-center justify-center px-1 pb-4 pt-0',
                                  tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                )}
                              >
                                <span className="text-sm font-medium leading-5">{tab.name}</span>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    {/* contents */}
                    {selectContent()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div>
              <button className="pl-[35.8px]" onClick={() => handeRightTab()}>
                <img src={Arrow_Right} alt="" className="h-[40px] w-[40px]" />
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
