import clsx from 'clsx';
import * as React from 'react';

export type FeadBackProps = React.HTMLAttributes<HTMLElement> & {
  icon: React.ReactElement;
  actionText?: string;
  supportText?: string;
  button?: React.ReactElement;
};

export const FeeadBack = React.forwardRef<HTMLElement, FeadBackProps>(({ className = '', ...props }) => {
  return (
    <>
      <div className={clsx('flex flex-col items-center justify-center', className)}>
        <div className="flex flex-col items-center justify-center">
          {props.icon}
          {props.actionText && (
            <div className="flex flex-col items-center">
              <span className="text-gray-900">{props.actionText}</span>
            </div>
          )}
          {props.supportText && (
            <div className="flex flex-col items-center">
              <span className="text-gray-500">{props.supportText}</span>
            </div>
          )}
          {props.button && <div>{props.button}</div>}
        </div>
      </div>
    </>
  );
});

FeeadBack.displayName = 'FeeadBack';
