import React from 'react';

import { FormProps } from '../../interfaces/project';
import { isMeasure } from '../../utils/logic';

import Measure from './Measure';
import Priod from './Priod';

export default function Consideration(props: {
  payload: FormProps['payload'];
  setPayload: FormProps['setPayload'];
}): React.ReactElement {
  return <>{isMeasure(props.payload) ? <>{Measure(props)}</> : <>{Priod(props)}</>}</>;
}
