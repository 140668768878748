import { GetInfluencerSnsAccountOutput } from '@/api/influencer';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import NoImage from '@/assets/images/NoImage.png';
import { Stat } from '@/components/Elements/Stat';

import { AppealPostsProps, PostInfoModalProps, RecentPostsProps } from '../../Tiktok/TiktokAll_Interface';
import Modal from '../Modal';

interface modalInfoAllProps {
  appealPosts: AppealPostsProps[];
  recentPosts: RecentPostsProps[];
  postinfoModal: PostInfoModalProps;
  influencerInfo?: GetInfluencerSnsAccountOutput;
}

let applealPostsFlag = false;

export default function ApplicantInfo(props: modalInfoAllProps) {
  const handleOpen = (index: number, kind: string, isDeleteArrow?: boolean) => {
    applealPostsFlag = kind === 'appeal';
    props.postinfoModal.setModalIndex(index);
    props.postinfoModal.setPostType(kind);
    props.postinfoModal.setModalOpen(true);
    if (isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(true);
    } else if (!isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(false);
    }
  };

  let tiktok_modal_element_component: JSX.Element[];

  const formatLikeCounts = (likeCounts: number) => {
    if (likeCounts >= 1000) {
      return (likeCounts / 1000).toFixed(1) + 'K';
    }
    return likeCounts.toString();
  };

  if (props.postinfoModal.postType === 'appeal') {
    tiktok_modal_element_component = [
      <Stat
        key={0}
        icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
        lable="再生回数"
        value={props.appealPosts[props.postinfoModal.modalindex]?.viewCounts?.toLocaleString() || '0'}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.appealPosts[props.postinfoModal.modalindex]?.engagementRate?.toFixed(2) + '%' || '0%'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={props.appealPosts[props.postinfoModal.modalindex]?.engagementCounts?.toLocaleString() || '0'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="いいね数"
        value={props.appealPosts[props.postinfoModal.modalindex]?.likeCounts?.toLocaleString() || '0'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,

      <Stat
        key={4}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="コメント数"
        value={props.appealPosts[props.postinfoModal.modalindex]?.commentCounts?.toLocaleString() || '0'}
        className="h-[100px] w-[300px] rounded-lg  border border-slate-200"
      />,

      <Stat
        key={5}
        icon={<ShareIcon width={24} height={24} fill="#FFFFFF" />}
        lable="シェア数"
        value={props.appealPosts[props.postinfoModal.modalindex]?.shareCounts?.toLocaleString() || '0'}
        className="h-[100px] w-[300px] rounded-lg  border border-slate-200"
      />,
    ];
  } else {
    tiktok_modal_element_component = [
      <Stat
        key={0}
        icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
        lable="再生回数"
        value={props.recentPosts[props.postinfoModal.modalindex]?.viewCounts?.toLocaleString() || '0'}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.recentPosts[props.postinfoModal.modalindex]?.engagementRate?.toFixed(2) + '%' || '0%'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={props.recentPosts[props.postinfoModal.modalindex]?.engagementCounts?.toLocaleString() || '0'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="いいね数"
        value={props.recentPosts[props.postinfoModal.modalindex]?.likeCounts?.toLocaleString() || '0'}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={4}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="コメント数"
        value={props.recentPosts[props.postinfoModal.modalindex]?.commentCounts?.toLocaleString() || '0'}
        className="h-[100px] w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={5}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="シェア数"
        value={props.recentPosts[props.postinfoModal.modalindex]?.shareCounts?.toLocaleString() || '0'}
        className="h-[100px] w-[300px] rounded-lg  border border-slate-200"
      />,
    ];
  }

  return (
    <>
      <div className="w-[889px] pt-[10px]">
        {/* ボタンの css */}
        <>
          <div className="text-sm font-semibold leading-5 text-gray-900">アピール投稿</div>
          <div className="flex gap-[6px] pl-[40px] pt-[16px]">
            {props.appealPosts?.map((post, index) => {
              return (
                <>
                  <div role="presentation" onClick={() => handleOpen(index, 'appeal', true)} className="px-[20px]">
                    <div className="h-[288px] w-[240px]">
                      <img
                        src={props.appealPosts[index]?.postImage || NoImage}
                        alt=""
                        className="h-[100%] w-[100%] cursor-pointer object-cover"
                      />
                    </div>
                    <div className="flex gap-1 pt-1">
                      <HeartIcon width={20} height={20} fill="#000000" />
                      <span className="text-sm font-semibold leading-5">
                        {formatLikeCounts(props.appealPosts[index]?.likeCounts || 0)}
                      </span>
                    </div>
                    <div className="flex gap-1 pt-[2px]">
                      <div className="whitespace-pre-wrap text-sm font-normal leading-5 text-gray-700">
                        {props.appealPosts[index]?.title ? (
                          <span className="block w-[100%]">{props.appealPosts[index]?.title?.replace(/\n/g, '')}</span>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>

          <div className="pt-[40px]">
            {/* <div className="text-sm font-semibold leading-5 text-gray-900">{`直近の${props.recentPosts?.length}投稿`}</div> */}
            <div className="text-sm font-semibold leading-5 text-gray-900">直近の9投稿</div>
            <div className="px-[20px]">
              <div>
                {new Array(Math.ceil(props.recentPosts?.length / 3)).fill(0).map((_, rowIndex) => (
                  <div key={rowIndex} className="flex gap-[6px] pt-4">
                    {Array.from(Array(3).keys()).map((_, colIndex) => {
                      const postIndex = rowIndex * 3 + colIndex;
                      const post = props.recentPosts?.[postIndex];
                      if (post) {
                        return (
                          <div
                            key={postIndex}
                            role="presentation"
                            onClick={() => handleOpen(postIndex, 'recent')}
                            className="px-[20px]"
                          >
                            <div className="h-[288px] w-[240px]">
                              <img
                                src={post.postImage || NoImage}
                                alt=""
                                className="h-[100%] w-[100%] cursor-pointer object-cover"
                              />
                            </div>
                            <div className="flex items-center gap-1 pt-1">
                              <HeartIcon width={20} height={20} fill="#000000" />
                              <span className="pt-1 text-sm font-semibold leading-5">
                                {formatLikeCounts(post.likeCounts || 0)}
                              </span>
                            </div>
                            <div className="whitespace-pre-wrap text-sm font-normal leading-5 text-gray-700">
                              {post.title ? (
                                <span className="block w-[100%]">{post.title?.replace(/\n/g, '')}</span>
                              ) : (
                                ''
                              )}
                            </div>
                          </div>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>

        {/* モーダル */}
        {props.postinfoModal.modalOpen && (
          <div>
            <Modal
              open={props.postinfoModal.modalOpen}
              setOpen={props.postinfoModal.setModalOpen}
              accountInfo={applealPostsFlag ? props.appealPosts : props.recentPosts}
              modalIndex={props.postinfoModal.modalindex}
              setModalIndex={props.postinfoModal.setModalIndex}
              Component_Element={tiktok_modal_element_component}
              sns_name="tiktok"
              deleteFlag={props.postinfoModal.deleteFlag}
            />
          </div>
        )}
      </div>
    </>
  );
}
