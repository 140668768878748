import { RadioGroup } from '@headlessui/react';
import clsx from 'clsx';
import * as React from 'react';

interface bottonProps {
  label: string;
  value: string;
  className: string;
}
export type ButtonTabsProps = React.InputHTMLAttributes<HTMLButtonElement> & {
  value: string;
  buttons: bottonProps[];
  onChange: (value: string) => void;
};

export const ButtonTab = React.forwardRef<HTMLButtonElement, ButtonTabsProps>(({ className = '', ...props }, ref) => {
  return (
    <div className="flex items-center justify-center">
      <RadioGroup value={props.value} onChange={(e: any) => props.onChange(e.value)} className={className} ref={ref}>
        {props.buttons.map((option) => (
          <RadioGroup.Option
            key={option.value}
            value={option}
            className={clsx(
              option.value == props.value ? 'bg-white text-gray-900' : 'bg-gray-100 text-gray-700',
              'cursor-pointer',
              option.className
            )}
          >
            <span>{option.label}</span>
          </RadioGroup.Option>
        ))}
      </RadioGroup>
    </div>
  );
});

ButtonTab.displayName = 'ButtonTab';
