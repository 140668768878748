import { Link } from 'react-router-dom';

// Dupricated:共通版の src/components/Preview/previewCommon.tsx を使用してください
export default function PreviewTikTok(
  post: string,
  icon: string,
  location: string,
  body: string,
  video: boolean,
  URL: string
) {
  return (
    <div className="flex h-[1064px] w-[1049px] items-start gap-10">
      <div className="flex w-[414px] flex-col items-start">
        {video ? (
          <div>
            <img src={post} alt="" className="rounded-lg" />
            <div className="flex gap-4 pt-3">
              <div className="flex flex-col">
                <div>
                  <span className="whitespace-pre-wrap text-sm font-normal">{body}</span>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img src={post} alt="" className="h-[219px] w-[414px] rounded-lg object-cover" />
            <div className="flex gap-4 pt-3">
              <div className="flex flex-col">
                <div>
                  <span className="whitespace-pre-wrap text-sm font-normal">{body}</span>
                </div>
                <div className="mt-6">
                  <Link to={URL} target="_blank" className="break-all text-blue-500">
                    {URL}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
