import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment, useCallback } from 'react';
import Dropzone from 'react-dropzone';

import Photo from '@/assets/images/Photo.png';
import { Button } from '@/components/Elements/Button/Button';
import { UPLOAD_FILE_TYPES } from '@/config/index';

interface Props {
  open: boolean;
  file: File | undefined;
  setFile: React.Dispatch<React.SetStateAction<File | undefined>>;
  onClose: (isSave: boolean) => void;
  imageSize?: number;
}

export const ImageModal = ({ imageSize = 30, open, file, setFile, onClose }: Props) => {
  console.log('L19:file ', file);
  const handleChangeFile = useCallback(
    (files: File[]) => {
      files.forEach((file: File) => {
        if (file) {
          setFile(file);
        }
      });
    },
    [setFile]
  );

  const handleCancel = useCallback(() => {
    setFile(undefined);
    onClose(false);
  }, [onClose, setFile]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          onClose(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 opacity-30 transition-opacity"
            style={{ opacity: 1, background: 'rgba(107, 114, 128, 0.75)' }}
          />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[520px] w-[720px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      onClose(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="px-[20px] py-[30px] sm:flex sm:items-start">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-medium leading-6 text-gray-900">
                      ファイルをアップロード
                    </Dialog.Title>

                    <div className="col-span-full pt-10">
                      <Dropzone onDrop={(acceptedFiles) => handleChangeFile(acceptedFiles)} accept={UPLOAD_FILE_TYPES}>
                        {({ getRootProps, getInputProps }) => (
                          <div
                            className="flex w-[656px] justify-center rounded-lg border border-dashed border-gray-900/25 py-[35.5px]"
                            {...getRootProps()}
                          >
                            <div className="text-center">
                              <img
                                className={`mx-auto object-contain text-gray-400 ${
                                  file === undefined ? ' h-[36px] w-[36px]' : ' h-[140px] w-[344px]'
                                }`}
                                src={file ? URL.createObjectURL(file) : Photo}
                                alt=""
                              />
                              <div className="mt-4 flex text-sm leading-6 text-gray-600">
                                <p className="pl-1 text-sm font-medium">
                                  ドラッグ&ドロップもしくは
                                  <span
                                    className="relative cursor-pointer rounded-md bg-white text-sm font-medium  focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500"
                                    style={{ color: '#007CC2' }}
                                  >
                                    ファイルをアップロード
                                  </span>
                                </p>
                                <input {...getInputProps()} />
                              </div>
                              <p className="text-xs leading-5 text-gray-600">
                                対応ファイルは{imageSize}MB以内のPNG, JPG, GIF
                              </p>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                    </div>
                  </div>
                </div>

                <div className="mt-5 pt-4 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => {
                      onClose(true);
                    }}
                  >
                    アップロードを実行する
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={handleCancel}
                  >
                    キャンセル
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
