import * as React from 'react';

export type useFilesReturn = {
  files: File[];
  setFiles: React.Dispatch<React.SetStateAction<File[]>>;
};

export const useFiles = (): useFilesReturn => {
  const [files, setFiles] = React.useState<File[]>([]);

  return {
    files,
    setFiles,
  };
};
