import { Transition, Dialog } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';

import { ReactComponent as ArrowDropDownIcon } from '@/assets/icons/ArrowDropDown.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as ChevronsLeftIcon } from '@/assets/icons/bx-chevrons-left.svg';
import { ReactComponent as ChevronsRightIcon } from '@/assets/icons/bx-chevrons-right.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';

import { Project } from '../interfaces/PRSTProductionManagementInterface';

import { useAccount } from './AccountContext';
import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import { useUI } from './UIContext';

const numbers = [1, 2, 3, 4, 5, 6, 7];

export const ProjectList = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [projectName, setProjectName] = useState('');
  const { setIsProject } = useUI();

  const {
    accounts,
    selectedAccount,
    selectedProject,
    addProject,
    addSelectedProject,
    changeSelectedProject,
    selectedPost,
  } = useAccount();

  const handleClick = () => {
    setOpen(true);
  };

  const handleProjectNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setProjectName(e.target.value);
  };

  const handleSubmit = () => {
    const newProject: Project = {
      projectName: projectName,
      posts: [],
    };

    addProject(newProject);
    addSelectedProject(newProject);
    console.log('---ProjectList.tsx---');
    console.log('新しく作成されたproject:（newProject）:', newProject);
    // setProject(projectName);
    // setProjectName(projectName);
    setProjectName('');
    setOpen(false);
    setIsProject(true);
  };

  useEffect(() => {
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('作成されたアカウント全て（accounts）:', accounts);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
  }, [selectedAccount, accounts, selectedProject, selectedPost]);

  const selectProject = (project: Project) => () => {
    changeSelectedProject(project);
    setIsProject(true);
  };

  const ProjectAddModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[228px] w-[512px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[180px] w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[110px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">施策を追加</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="h-[62px] w-[440px]">
                        <div>
                          <label htmlFor="projectName" className="block text-sm font-medium leading-6 text-gray-900">
                            施策名
                          </label>
                          <div className="mt-2">
                            <input
                              type="text"
                              value={projectName}
                              onChange={handleProjectNameChange}
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleSubmit}
                      >
                        登録する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };
  return (
    <>
      <div className="w-[940px] bg-gray-50 px-[24px] py-[16px]">
        <div className="w-[840px] border-r border-gray-200">
          <div className="mb-[16px] flex h-[38px] w-[840px] justify-between">
            <p className="text-base font-semibold leading-7 text-gray-700">施策一覧</p>
            <Button
              startIcon={<PlusImage className="h-[20px] w-[20px]" />}
              size="md"
              className="whitespace-nowrap text-sm font-medium leading-5"
              style={{
                paddingTop: '9px',
                paddingBottom: '9px',
                paddingLeft: '10px',
                paddingRight: '12px',
              }}
              onClick={handleClick}
            >
              施策を追加
            </Button>
          </div>
          <div className="h-[525px] w-[840px] border-r border-gray-200 bg-[#FFFFFF] px-[19px] pt-[24px]">
            <div>
              {/* 1列目 */}
              <div className="flex h-[36px] w-full">
                <p className="h-[36px] w-[191px] py-[10px] pl-[16px] text-xs font-normal leading-4 text-gray-700">
                  施策名
                </p>
                <p className="h-[36px] w-[165px] py-[10px] pl-[16px] text-xs font-normal leading-4 text-gray-700">
                  作成日
                </p>
                <p className="h-[36px] w-[92px] py-[10px] pl-[16px] text-xs font-normal leading-4 text-gray-700">
                  投稿予定数
                </p>
                <p className="h-[36px] w-[92px] py-[10px] pl-[16px] text-xs font-normal leading-4 text-gray-700">
                  投稿完了数
                </p>
                <p className="h-[36px] w-[145px] py-[10px] pl-[16px] text-xs font-normal leading-4 text-gray-700">
                  クリエイター
                </p>
                <div className="flex h-[64px] w-[118px] items-center justify-end"></div>
              </div>
              {/* 2列目 */}
              {selectedAccount?.projects.map((project) => (
                <button
                  key={project.projectName}
                  className="flex h-[64px] w-full bg-gray-50"
                  onClick={selectProject(project)}
                >
                  <div className="flex">
                    <p className="h-[64px] w-[191px] whitespace-nowrap py-[24px] pl-[16px] text-sm font-normal leading-5 text-gray-700">
                      {project.projectName}
                    </p>
                    <p className="h-[64px] w-[165px] py-[24px] pl-[16px] text-sm font-normal leading-5 text-gray-700">
                      2021年2月14日(金)
                    </p>
                    <p className="h-[64px] w-[92px] py-[24px] pl-[16px] text-sm font-normal leading-5 text-gray-700">
                      5
                    </p>
                    <p className="h-[64px] w-[92px] py-[24px] pl-[16px] text-sm font-normal leading-5 text-gray-700">
                      5
                    </p>
                    <div className="flex h-[64px] w-[145px] items-center px-[16px] py-[20px]">
                      <div className="flex h-[24px] w-[121px] items-center justify-center align-middle">
                        <p className="h-[17px] w-[76px] whitespace-nowrap align-middle text-[12px] font-medium leading-5 text-gray-700">
                          {selectedAccount.accountName}
                        </p>
                      </div>
                    </div>
                    <div className="flex h-[64px] w-[118px] items-center justify-center">
                      <DotsHorizontalIcon width={24} height={24} />
                    </div>
                  </div>
                </button>
              ))}
            </div>
            <div className="flex h-[62px] items-center justify-between px-[24px] py-[12px]">
              <div className="flex h-[24px] w-[268px] items-center justify-between">
                <div className="flex h-[24px] w-[172px] items-center justify-between">
                  <p className="h-[16px] w-[124px] align-middle text-xs font-normal leading-4 text-gray-500">
                    １ページあたりの行数:
                  </p>
                  <div className="flex h-[24px] w-[40px] items-center justify-between">
                    <p className="h-[16px] w-[16px] align-middle text-xs font-normal leading-4">30</p>
                    <ArrowDropDownIcon width={24} height={24} />
                  </div>
                </div>
                <p className="h-[20px] w-[88px] align-middle text-sm font-normal leading-5 text-gray-500">
                  31-60 of 300
                </p>
              </div>
              <div className="flex h-[38px] items-center border-[1px] border-gray-200">
                <div className="flex h-[38px] w-[38px] items-center justify-center border-r-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                  <ChevronsLeftIcon width={20} height={20} fill="#6B7280" />
                </div>
                <div className="flex h-[38px] w-[38px] items-center justify-center border-r-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                  <ChevronLeftIcon width={20} height={20} fill="#6B7280" />
                </div>
                {numbers.map((value: any, index: any) => (
                  <li
                    key={index}
                    className="flex h-[38px] w-[38px] items-center justify-center border-r-[1px] border-gray-200 text-sm font-medium leading-5 text-gray-500 hover:bg-[#E0F4FF]"
                  >
                    {value}
                  </li>
                ))}
                <div className="flex h-[38px] w-[38px] items-center justify-center hover:bg-[#E0F4FF]">
                  <ChevronRightIcon width={20} height={20} fill="#6B7280" />
                </div>
                <div className="flex h-[38px] w-[38px] items-center justify-center border-l-[1px] border-gray-200 hover:bg-[#E0F4FF]">
                  <ChevronsRightIcon width={20} height={20} fill="#6B7280" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{ProjectAddModal()}</div>
    </>
  );
};
