import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

import PreviewImage1 from './assets/Preview-image1.svg';
import PreviewImage2 from './assets/Preview-image2.svg';
import PreviewImage3 from './assets/Preview-image3.svg';
import PreviewImage4 from './assets/Preview-image4.svg';
import PreviewImage5 from './assets/Preview-image5.svg';
import PreviewImage6 from './assets/Preview-image6.svg';
import PreviewImage7 from './assets/Preview-image7.svg';
import PreviewImage8 from './assets/Preview-image8.svg';

export default function Preview(isPreview: boolean, setIsPreview: React.Dispatch<React.SetStateAction<boolean>>) {
  const setNgStatus = () => {
    setIsPreview(false);
  };

  return (
    <Transition.Root show={isPreview} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsPreview}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {/* <Dialog.Panel className="w-64"> */}
              <Dialog.Panel className="relative w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      setNgStatus();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="flex">
                  <div className=" sm:flex sm:items-start">
                    <div className="text-center sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="h-[24px] w-[６９６px] text-lg font-medium leading-6 text-gray-900"
                      >
                        投稿横並びプレビュー
                      </Dialog.Title>
                      <div className="mb-5 mt-2">
                        <p className="text-xs font-normal leading-5 text-gray-500">
                          各コンテンツ制作で登録済みのメディアが投稿予定日順に表示されています。
                        </p>
                        <p className="text-xs font-normal leading-5 text-gray-500">
                          ドラッグで順番を変更して、並び順を確認できます。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container ml-24">
                  <div className="flex">
                    <img src={PreviewImage1} alt="" className="h-[173px] w-[173px]" />
                    <img src={PreviewImage2} alt="" className="h-[173px] w-[173px]" />
                    <img src={PreviewImage3} alt="" className="h-[173px] w-[173px]" />
                  </div>
                  <div className="flex">
                    <img src={PreviewImage4} alt="" className="h-[173px] w-[173px]" />
                    <img src={PreviewImage5} alt="" className="h-[173px] w-[173px]" />
                    <img src={PreviewImage6} alt="" className="h-[173px] w-[173px]" />
                  </div>
                  <div className="flex">
                    <img src={PreviewImage7} alt="" className="h-[173px] w-[173px]" />
                    <img src={PreviewImage8} alt="" className="h-[173px] w-[173px]" />
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    style={{ background: '#007CC2' }}
                    onClick={() => {
                      // 特別 Alert.tsx を表示させる
                      setNgStatus();
                    }}
                  >
                    終了する
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
