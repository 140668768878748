import { useEffect, useRef, useState } from 'react';

import { GetSpiritApplicantsOrderEnum, GetSpiritApplicantsRowsEnum } from '@/api/spirit/api';

import { AdoptionValue, GetProjectApplicantsOutput, ProjectApplicantItem } from '../ApplicantContent';
import Pagenation from '../common/Parts/Pagenation';
import { arrow_css_type, arrow_up_css_type } from '../common/Types/common_types';

import Tiktok from './Tiktok_Content_Row';

const Tiktok_Content = ({
  rows,
  status,
  changeFollowStatus,
  changeAdoption,
  showHearingModal,
  totalPageCount,
  getApplicantData,
  totalRows,
  sortData,
  setSortData,
  setOrderWord,
  applicantListCurrentPage,
  setApplicantListCurrentPage,
  setPerPage,
  sortStateArray,
  setSortStateArray,
}: {
  rows: ProjectApplicantItem[];
  setTiktokRows: React.Dispatch<React.SetStateAction<GetProjectApplicantsOutput>>;
  status: string;
  changeFollowStatus: (influencerId: string) => Promise<void>;
  changeAdoption: (influencerId: string, status: AdoptionValue) => Promise<void>;
  showHearingModal: (snsAccountId: string) => Promise<void>;
  totalPageCount: number;
  getApplicantData: (rows?: GetSpiritApplicantsRowsEnum, page?: number) => void;
  totalRows: number;
  sortData: GetSpiritApplicantsOrderEnum;
  setSortData: React.Dispatch<React.SetStateAction<GetSpiritApplicantsOrderEnum>>;
  setOrderWord: React.Dispatch<React.SetStateAction<string>>;
  applicantListCurrentPage: number;
  setApplicantListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage: React.Dispatch<React.SetStateAction<number>>;
  sortStateArray: string[];
  setSortStateArray: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const arrow_under_css: arrow_css_type = {
    display: 'block',
    width: '9px',
    height: '9px',
    borderTop: 'solid 2px #4B5563',
    borderRight: 'solid 2px #4B5563',
    borderColor: '#4B5563 #4B5563 transparent transparent',
    transform: 'rotate(135deg)',
  };
  const arrow_up_css: arrow_up_css_type = {
    display: 'block',
    width: '9px',
    height: '9px',
    borderTop: 'solid 2px #4B5563',
    borderRight: 'solid 2px #4B5563',
    borderColor: '#4B5563 #4B5563 transparent transparent',
    transform: 'rotate(315deg)',
    marginTop: '4px',
  };

  const headerRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);

  const [sortedData, setSortedData] = useState<ProjectApplicantItem[]>([]);

  useEffect(() => {
    setSortedData(rows);
  }, [rows]);

  const syncScroll = (e: React.UIEvent<HTMLDivElement>) => {
    if (headerRef.current && bodyRef.current) {
      headerRef.current.scrollLeft = e.currentTarget.scrollLeft;
      bodyRef.current.scrollLeft = e.currentTarget.scrollLeft;
    }
  };

  // const column_sort = (
  //   column_name: keyof ProjectApplicantItem,
  //   arrangement: 'ascending' | 'descending',
  //   array_index: number
  // ) => {
  //   const sortedRows = [...sortedData].sort((a, b) => {
  //     const aValue = a[column_name];
  //     const bValue = b[column_name];

  //     if (aValue == null || bValue == null) {
  //       return 0;
  //     }

  //     if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
  //       // true/falseの比較
  //       if (arrangement === 'ascending') {
  //         return aValue === bValue ? 0 : aValue ? 1 : -1;
  //       } else {
  //         return aValue === bValue ? 0 : aValue ? -1 : 1;
  //       }
  //     } else if (typeof aValue === 'number' && typeof bValue === 'number') {
  //       // 数値の比較
  //       if (arrangement === 'ascending') {
  //         return aValue - bValue;
  //       } else {
  //         return bValue - aValue;
  //       }
  //     } else {
  //       // その他の比較
  //       if (arrangement === 'ascending') {
  //         return aValue > bValue ? 1 : -1;
  //       } else {
  //         return aValue < bValue ? 1 : -1;
  //       }
  //     }
  //   });

  //   setSortedData(sortedRows);

  //   const newSortStateArray = new Array(17).fill('ascending');
  //   newSortStateArray[array_index] = arrangement === 'ascending' ? 'descending' : 'ascending';
  //   setSortStateArray(newSortStateArray);
  // };

  const handleSort = (word: string, array_index: number) => {
    setSortData((prev) => {
      return prev === 'asc' ? 'desc' : 'asc';
    });

    setOrderWord(word);

    const newSortStateArray = new Array(17).fill('ascending');
    newSortStateArray[array_index] = sortData === 'asc' ? 'descending' : 'ascending';
    setSortStateArray(newSortStateArray);
  };

  const url = window.location.search;
  const params = new URLSearchParams(url);
  const type = params.get('type');

  return (
    <div className="rounded-[8px] border-[1px] border-gray-200">
      <div className="overflow-auto rounded-[8px]" ref={headerRef} onScroll={syncScroll}>
        <table>
          <thead className="sticky top-0 border-b border-gray-300 bg-white">
            <tr className="text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">気になる</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[0] === 'ascending' ? (
                      <button onClick={() => handleSort('follow', 0)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('follow', 0)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">応募順</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[1] === 'ascending' ? (
                      <button onClick={() => handleSort('idx', 1)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('idx', 1)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">アイコン</th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">ID</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[2] === 'ascending' ? (
                      <button onClick={() => handleSort('id', 2)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('id', 2)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">評価</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[3] === 'ascending' ? (
                      <button onClick={() => handleSort('eval', 3)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('eval', 3)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">案件への意気込み</div>
                </div>
              </th>
              <th className="min-w-[126px] whitespace-nowrap px-6 py-3">弊社担当からのコメント</th>
              {type === 'SPIRIT' && (
                <th className="min-w-[126px] px-6 py-3">
                  <div className="flex items-center gap-[8px]">
                    <div className="whitespace-nowrap">ヒアリング項目</div>
                  </div>
                </th>
              )}
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">選定</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[5] === 'ascending' ? (
                      <button onClick={() => handleSort('selection', 5)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('selection', 5)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">補欠採用</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[6] === 'ascending' ? (
                      <button onClick={() => handleSort('isReserved', 6)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('isReserved', 6)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">フォロワー数</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[7] === 'ascending' ? (
                      <button onClick={() => handleSort('followers', 7)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('followers', 7)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">フォロー数</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[8] === 'ascending' ? (
                      <button onClick={() => handleSort('following', 8)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('following', 8)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">再生回数</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[9] === 'ascending' ? (
                      <button onClick={() => handleSort('views', 9)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('views', 9)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">エンゲージメント率</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[10] === 'ascending' ? (
                      <button onClick={() => handleSort('egRate', 10)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('egRate', 10)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">エンゲージメント数</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[11] === 'ascending' ? (
                      <button onClick={() => handleSort('engagements', 11)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('engagements', 11)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">いいね数（平均）</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[12] === 'ascending' ? (
                      <button onClick={() => handleSort('good', 12)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('good', 12)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">コメント数（平均）</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[13] === 'ascending' ? (
                      <button onClick={() => handleSort('comments', 13)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('comments', 13)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">シェア数（平均）</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[14] === 'ascending' ? (
                      <button onClick={() => handleSort('share', 14)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('share', 14)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">再生回数率</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[15] === 'ascending' ? (
                      <button onClick={() => handleSort('viewRate', 15)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('viewRate', 15)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
              <th className="min-w-[126px] px-6 py-3">
                <div className="flex items-center gap-[8px]">
                  <div className="whitespace-nowrap">投稿数</div>
                  <div className="h-[19px] w-[20px] rounded bg-gray-200 pl-[5.5px] pt-[3px]">
                    {sortStateArray[16] === 'ascending' ? (
                      <button onClick={() => handleSort('mediaCount', 16)} style={arrow_under_css}></button>
                    ) : (
                      <button onClick={() => handleSort('mediaCount', 16)} style={arrow_up_css}></button>
                    )}
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {sortedData.length === 0 ? (
              <tr className=" bg-white text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                <td colSpan={type === 'SPIRIT' ? 20 : 19}>
                  <div className="flex h-[200px] place-items-center pl-[300px]">
                    <span className="text-base font-normal leading-6 text-gray-500">
                      応募中のインフルエンサーはいません
                    </span>
                  </div>
                </td>
              </tr>
            ) : (
              sortedData.map((row: ProjectApplicantItem, index: number) => {
                return (
                  <Tiktok
                    Tiktok_Column_Content={row}
                    key={index}
                    project_status={status}
                    index={index}
                    changeFollowStatus={changeFollowStatus}
                    changeAdoption={changeAdoption}
                    showHearingModal={showHearingModal}
                  />
                );
              })
            )}
          </tbody>
        </table>
      </div>
      {sortedData.length !== 0 && (
        <Pagenation
          totalPageCount={totalPageCount}
          getApplicantData={getApplicantData}
          totalRows={totalRows}
          applicantListCurrentPage={applicantListCurrentPage}
          setApplicantListCurrentPage={setApplicantListCurrentPage}
          setPerPage={setPerPage}
        />
      )}
    </div>
  );
};

export default Tiktok_Content;
