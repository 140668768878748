import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import { GetAccountOutput, DefaultApi } from '@/api/member/api';
import { axiosInstance } from '@/utils/axiosInstance';

export const fetchMemberInfo = async (): Promise<GetAccountOutput> => {
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    const response: AxiosResponse<GetAccountOutput> = await api.getAccount();
    return response.data as GetAccountOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
