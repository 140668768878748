import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';

import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as SeparatorImage } from './assets/Seperator.svg';

export const StepPanel = () => {
  return (
    <>
      <div className="mb-[30px] mt-[16px] flex h-[45px] w-[1133px] items-center justify-between border-b border-gray-200 py-[11px]">
        <div className="flex h-[24px] w-[1149px] items-center">
          <div className="flex h-[24px] w-[628px] items-center justify-between">
            <ChevronLeftIcon width={24} height={24} fill="#9CA3AF" />
            <div className="flex h-[24px] w-[297px] items-center justify-between">
              <div className="flex h-[24px] w-[136px] items-center">
                <p className="h-[12px] w-[136px] whitespace-nowrap align-middle text-xs font-normal leading-3 text-gray-700">
                  10月分エメポスMVP施策
                </p>
              </div>
              <div className="flex h-[24px] w-[146px] items-center justify-between">
                <p className="h-[24px] w-[70px] text-base font-bold leading-6 text-gray-700">No.0004</p>
                <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-red-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-red-800">
                  フィード
                </p>
              </div>
            </div>
            <p className="h-[20px] w-[247px] whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
              投稿予定日：2021年2月12日(金) 18:00
            </p>
          </div>
        </div>
        <div>
          <DotsHorizontalIcon width={24} height={24} />
        </div>
      </div>
      <div className="h-[72px] w-[1133px] bg-[#FFFFFF]">
        {/* パネル↓ */}
        <div className="mb-[24px] flex h-[72px] w-[1133px] items-center rounded border-[1px] border-gray-300">
          {/* 01イメージ案制作 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-[#006199]">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-[#006199]">01</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-[#006199]">イメージ案制作</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 02イメージ案承認 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">02</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">イメージ案承認</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 03コンテンツ制作 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">03</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">コンテンツ制作</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 04コンテンツ承認 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">04</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">コンテンツ承認</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 05完了 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <div className="flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
                <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">05</p>
              </div>
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">完了</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
