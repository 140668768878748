import { ReactComponent as ErrorCircleIcon } from '@/assets/icons/bx-error-circle.svg';

export const AlertContentsApprove = () => {
  return (
    <>
      <div className="flex h-[52px] w-[1133px] items-center bg-blue-50 p-[16px]">
        <ErrorCircleIcon width={20} height={20} fill="#60A5FA" className="mr-[12px]" />
        <div className="flex h-[20px] w-[1069px] items-center">
          <span className="text-sm font-normal leading-5 text-blue-700">コンテンツを承認してください。</span>
        </div>
      </div>
    </>
  );
};
