import { useState, useEffect, useRef } from 'react';

import DotsHorizontalImage from './assets/DotsHorizontal.svg';

export function DotsDropdown1({ setSelected }: any): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const insideRef = useRef<HTMLButtonElement>(null);
  const options = ['招待メールを再送する', 'メンバーを削除する'];

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsActive(false);
      } else {
        return;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [insideRef]);

  return (
    <div className="relative inline-block h-[72px] text-left">
      <button
        ref={insideRef}
        className="mt-[35px] h-[4px] w-[16px] gap-x-1.5 bg-white text-sm font-medium leading-5 text-gray-700 hover:bg-gray-50"
        onClick={() => setIsActive(!isActive)}
      >
        <img src={DotsHorizontalImage} alt="" className="h-full w-full" />
      </button>
      {isActive && (
        <div className="absolute right-[26px] top-0 z-10 mt-[28px] w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <a
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                style={option === 'メンバーを削除する' ? { color: '#B91C1C' } : {}}
                href="#"
                key={option}
                onClick={() => {
                  setSelected(option);
                  setIsActive(false);
                }}
              >
                {option}
              </a>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
