import { PROJECT_TYPES } from '@/config';
import { secondSidebarSelect } from '@/config/sidebar';

export const selectedSecondSidebarValue = (projectType: string): string => {
  switch (projectType) {
    case PROJECT_TYPES.EMERALD_POST:
      return secondSidebarSelect.emeraldPost;
    case PROJECT_TYPES.SPIRIT:
      return secondSidebarSelect.spirit;
    case PROJECT_TYPES.PRST:
      return secondSidebarSelect.prst;
    case PROJECT_TYPES.JANE_JOHN:
      return secondSidebarSelect.janeJohn;
    case PROJECT_TYPES.OTHER:
      return secondSidebarSelect.other;
    default:
      return '';
  }
};
