import ArrowLeftImage from './assets/ArrowLeft.svg';
import BatteryImage from './assets/Battery.svg';
import InstagramImage from './assets/instagram.svg';
import MobileSignalImage from './assets/MobileSignal.svg';
import WideNoImage from './assets/NoImageWide.svg';
import NotchImage from './assets/Notch.svg';
import SmartphonePreviewImage from './assets/SmartphonePreview.svg';
import WifiImage from './assets/Wifi.svg';

export const SmartphoneView = (props: { readyForApplication: boolean }) => {
  return (
    <>
      <div className="ml-[40px] mt-[24px]">
        <p className="mb-[16px] mt-[24px] h-[24px] w-[240pxpx] text-base font-semibold leading-7 text-gray-500">
          インフルエンサー画面プレビュー
        </p>
        <div className="w-[286.25px] rounded-[39.29px] border-[11px] border-gray-800">
          <div className="flex w-[263.1]">
            <p className="mb-[5.85px] ml-[13.46px] mt-[9.45px] whitespace-nowrap pt-[0.68px] text-center text-[17px] font-semibold leading-[22px] text-[#010101]">
              9:41
            </p>
            <img src={NotchImage} alt="" className="ml-[26.79px] h-[21.59px] w-[110.64px]" />
            <div className="mt-[12.82px] flex">
              <img src={MobileSignalImage} alt="" className="ml-[20.46px] mt-[0.59px] h-[7.06px] w-[10.59px]" />
              <img src={WifiImage} alt="" className="ml-[4.71px] mt-[0.59px] h-[7.06px] w-[10px]" />
              <img src={BatteryImage} alt="" className="ml-[4.12px] h-[7.65px] w-[16.13px]" />
            </div>
          </div>
          <div className="flex h-[35.08px] w-[263.8px] items-center">
            <div className="h-[34.38px] w-[39.29px] px-[5.61px] py-[3.16px]">
              <div className="h-[28.06px] w-[28.06px]  px-[9.71px] py-[7.09px]">
                <img src={ArrowLeftImage} alt="" className="h-[13.89px] w-[8.19px]" />
              </div>
            </div>
            <p className="ml-[60px] whitespace-nowrap text-base font-bold leading-6">案件詳細</p>
          </div>
          <img src={props.readyForApplication ? WideNoImage : SmartphonePreviewImage} alt="" className="" />
          {/* メイン */}
          <div className="mt-[10.15px] px-[11px] pb-[11px] pt-[17px]">
            <div className="w-[240.65px] border-b-[1px] border-gray-300 pb-[23.84px]">
              <div className="flex">
                <img src={InstagramImage} alt="" className="w-[15.68px]" />
                <p className="ml-[6.19px] whitespace-nowrap rounded-[2.81px] bg-[#E0F2FE] px-[7px] py-[1.4px] text-center text-[10px] font-medium leading-4 text-[#075985]">
                  スキンケア
                </p>
                <p className="ml-[5.61px] whitespace-nowrap rounded-[2.81px] bg-[#E0F2FE] px-[7px] py-[1.4px] text-center text-[10px] font-medium leading-4 text-[#075985]">
                  エイジングケア
                </p>
                <p className="ml-[5.61px] whitespace-nowrap rounded-[2.81px] bg-[#E0F2FE] px-[7px] py-[1.4px] text-center text-[10px] font-medium leading-4 text-[#075985]">
                  コスメ
                </p>
              </div>
              <p className="ml-[22px] mt-[5.61px] w-[70px] whitespace-nowrap rounded-[2.81px] bg-[#E0F2FE] px-[7px] py-[1.4px] text-center text-[10px] font-medium leading-4 text-[#075985]">
                アンバサダー
              </p>
              <p className="mt-[16.84px] w-[240.65px] text-[11.22px] font-bold text-gray-900">
                さっと塗るだけ!お手軽エイジングケア! 究極の
                <br />
                オールインワン「カナデル プレミア ゼロ」を
                <br />
                InstagramでPRしてください♡
              </p>
              <p className="mt-[16.84px] w-[240.65px] text-[16.84px] font-bold text-gray-900">￥10,000</p>
            </div>
            <p className="mt-[17.56px] w-[241.35px] text-[12.63px] font-medium text-gray-900">案件概要</p>
            <div className="mt-[11.22px]">
              <p className="mt-[5.61px] w-[240.65px] text-[9.83px] font-bold text-[#8E8E8E]">応募条件</p>
              <p className="mt-[2.81px] w-[240.65px] text-[9.83px] font-normal text-gray-900">・ 5,000フォロワー以上</p>
            </div>
            <div className="mt-[16.84px]">
              <p className="mt-[5.61px] w-[240.65px] text-[9.83px] font-bold text-[#8E8E8E]">推奨条件</p>
              <p className="mt-[2.81px] w-[240.65px] text-[9.83px] font-normal text-gray-900">・ 女性</p>
            </div>
            <div className="mt-[16.84px]">
              <p className="mt-[5.61px] w-[240.65px] text-[9.83px] font-bold text-[#8E8E8E]">二次利用</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartphoneView;
