import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { Fragment, useState } from 'react';

import { ReactComponent as DataIcon } from '@/assets/icons/bx-data.svg';
import { ReactComponent as DesktopIcon } from '@/assets/icons/bx-desktop.svg';
import { ReactComponent as EditIcon } from '@/assets/icons/bx-edit.svg';
import { ReactComponent as EnvelopeIcon } from '@/assets/icons/bx-envelope.svg';
import error from '@/assets/icons/bx-error-yellow.svg';
import { ReactComponent as PaperPlaneIcon } from '@/assets/icons/bx-paper-plane.svg';
import { ReactComponent as XCircleIcon } from '@/assets/icons/bx-x-circle.svg';
import check from '@/assets/icons/check.svg';

interface Props {
  open: boolean;
  onClose: (isSave: boolean) => void;
  onClickAgree: () => void;
}

export const FinalAgreeModal = (props: Props) => {
  const [agreeCheck, setAgreeCheck] = useState<boolean>(false);

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => props.onClose(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative max-w-[991px] rounded-[8px] bg-white p-[40px] text-left shadow-xl transition-all">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                    onClick={() => {
                      props.onClose(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="w-full sm:flex sm:items-start">
                  <div className="w-full text-center sm:text-left">
                    <div className="mb-[32px] flex w-full rounded-[6px] bg-[#FFFBEB] p-[16px]">
                      <div className="pr-[12px] align-text-top">
                        <img src={error} alt="" />
                      </div>
                      <div>
                        <p className="pb-[6px] font-normal leading-5 text-[#92400E]">
                          下記ルールに同意した場合のみ応募してください。
                        </p>
                        <p className="text-sm font-normal leading-5 text-[#B45309]">
                          ルールをお守りいただけなかった場合、報酬のお支払いができかねますのでご注意ください。
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col gap-[24px]">
                      <div className="flex gap-[16px] self-stretch">
                        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-[#007CC2] p-3">
                          <EnvelopeIcon width={24} height={24} fill="#FFFFFF" />
                        </div>
                        <div className="pt-[12px] text-[16px] font-[600] text-[#111827]">
                          <span className="self-stretch leading-5">採用者へのSNSでのDM等を含む、</span>
                          <span className="self-stretch leading-5 text-[#BD2C40]">直接連絡や引き抜きは厳禁</span>
                          <span className="self-stretch leading-5">です。</span>
                        </div>
                      </div>
                      <div className="flex gap-[16px] self-stretch">
                        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-[#007CC2] p-3">
                          <DesktopIcon width={24} height={24} fill="#FFFFFF" />
                        </div>
                        <div className="pt-[12px] text-[16px] font-[600] text-[#111827]">
                          <span className="self-stretch leading-5">
                            インフルエンサーの事情により止むを得ず、途中辞退が発生する可能性がございます。
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-[16px] self-stretch">
                        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-[#007CC2] p-3">
                          <XCircleIcon width={24} height={24} fill="#FFFFFF" />
                        </div>
                        <div className="pt-[12px] text-[16px] font-[600] text-[#111827]">
                          <span className="self-stretch leading-5 text-[#BD2C40]">
                            募集開始後の下記変更は原則変更不可
                          </span>
                          <span className="self-stretch leading-5">ですので、予めご了承ください。 </span>
                          <span className="whitespace-pre leading-[24px]">
                            {`\n\n・キャンセル（キャンセルを行う場合、別途費用を頂戴いたします。）\n・採用人数を減らすこと\n・投稿回数\n・スケジュール\n・投稿に関する内容（途中で指定を入れること等）`}
                          </span>
                        </div>
                      </div>
                      <div className="flex gap-[16px] self-stretch">
                        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-[#007CC2] p-3">
                          <EditIcon width={24} height={24} fill="#FFFFFF" />
                        </div>
                        <div className="pt-[12px] text-[16px] font-[600] text-[#111827]">
                          <span className="self-stretch leading-5">PR投稿をSNS公式アカウントや公式HP等で</span>
                          <span className="self-stretch leading-5 text-[#BD2C40]">
                            二次利用する場合は承認が必要です。
                          </span>
                          <span className="self-stretch leading-5">（別途費用を頂戴いたします。）</span>
                        </div>
                      </div>
                      <div className="flex gap-[16px] self-stretch">
                        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-[#007CC2] p-3">
                          <PaperPlaneIcon width={24} height={24} fill="#FFFFFF" />
                        </div>
                        <div className="pt-[12px] text-[16px] font-[600] text-[#111827]">
                          <span className="self-stretch leading-5">インフルエンサーの投稿を</span>
                          <span className="self-stretch leading-5 text-[#BD2C40]">
                            第三者広告で配信する場合、各SNS媒体システム状況により、配信できないアカウントもございます
                          </span>
                          <span className="self-stretch leading-5">ので、予めご了承ください。</span>
                        </div>
                      </div>
                      <div className="flex gap-[16px] self-stretch">
                        <div className="flex h-[48px] w-[48px] items-center justify-center rounded-md bg-[#007CC2] p-3">
                          <DataIcon width={24} height={24} fill="#FFFFFF" />
                        </div>
                        <div className="pt-[12px] text-[16px] font-[600] text-[#111827]">
                          <span className="self-stretch leading-5 text-[#BD2C40]">
                            各SNS媒体のシステム状況により、投稿が消去されることやインサイト回収が反映されないなどの可能性がございます
                          </span>
                          <span className="self-stretch leading-5">ので予めご了承ください。</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-span-full pt-[24px]"></div>
                    <div className="flex flex-row justify-between pt-8"></div>
                  </div>
                </div>
                <div className="flex w-full flex-col items-center">
                  <div className="mb-[24px] flex flex-row items-center justify-center gap-2 p-0">
                    <label className="flex items-center justify-center">
                      <div className="relative flex justify-center">
                        {agreeCheck && <img className="absolute" src={check} alt="agree" />}
                        <input
                          type="checkbox"
                          value="agree"
                          id="agree"
                          className="rounded border-[#D1D5DB] bg-[#fff] text-[#fff] focus:ring-[#fff]"
                          checked={agreeCheck}
                          onClick={() => {
                            setAgreeCheck(!agreeCheck);
                            console.log(agreeCheck);
                          }}
                        />
                      </div>
                      <span className="ml-[8px] text-sm font-medium leading-5 text-gray-700">
                        案件募集のルールに同意する
                      </span>
                    </label>
                  </div>
                  <button
                    type="button"
                    className={clsx(
                      'inline-flex w-[343px] justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-[#fff] shadow-sm',
                      agreeCheck ? 'bg-[#007CC2]' : 'bg-[#D1D5DB]'
                    )}
                    onClick={() => {
                      props.onClickAgree();
                    }}
                    disabled={!agreeCheck}
                  >
                    この内容で申請する
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
