import { useState } from 'react';
import { useLocation } from 'react-router-dom';

import { TabInfo } from '../Interfaces/TabViewInterfaces';

export function TabView(props: { tabInfo: TabInfo[] }) {
  const search = useLocation().search;
  const defalutTab = props.tabInfo.filter((tab) => tab.isActive)[0];

  const [activeTab, setActiveTab] = useState<string>(defalutTab?.query || props.tabInfo[0].query);
  function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ');
  }

  const handleTabClick = (query: string) => {
    setActiveTab(query);
    const queryString = new URLSearchParams(search);
    queryString.set('tab', query);
    history.pushState(null, '', `?${queryString.toString()}`);
  };
  const content = () => {
    const tab = props.tabInfo.filter((tab: TabInfo) => tab.query === activeTab);
    if (tab.length == 0) return <></>;
    return tab[0].component(tab[0].componentProps);
  };

  return (
    <div>
      <div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8 pl-[40px]" aria-label="Tabs">
              {props.tabInfo.map((tab) => (
                <button
                  key={tab.title}
                  onClick={() => handleTabClick(tab.query)}
                  className={classNames(
                    tab.query === activeTab
                      ? 'border-[#007CC2] text-[#007CC2]'
                      : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'flex w-[120px] flex-row items-center justify-center whitespace-nowrap border-b-2 px-1 py-4 text-sm font-medium'
                  )}
                  aria-current={tab.query === activeTab ? 'page' : undefined}
                >
                  <tab.icon
                    className={classNames(
                      tab.query === activeTab ? 'text-[#007CC2]' : ' text-gray-400 group-hover:text-gray-500',
                      '-ml-0.5 mr-2 h-5 w-5',
                      tab.className && tab.className
                    )}
                    aria-hidden="true"
                  />
                  <span>{tab.title}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="pt-[24px]">{props.tabInfo.length != 0 && content()}</div>
    </div>
  );
}
