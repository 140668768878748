/* eslint-disable tailwindcss/no-custom-classname */
import { PhoneIcon, EnvelopeIcon } from '@heroicons/react/24/outline';
import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from '@/components/Elements';
import { useAssetDataJson } from '@/hooks/AssetDataJson';
import { createTeamInfo } from '@/hooks/TeamInfo';
import { CreateTeamInfoRequest } from '@/types/TeamInfo';
import { getImageUrl } from '@/utils/image';
import {
  validationMessageMinNumCheck,
  validationMessageMaxNumCheck,
  // validationHalfWidthCheck,
  validationInputHalfWidthCheck,
  validationInputHyphensCheck,
  validationInputNumberCheck,
  validationInputZeroStartCheck,
  isValidEmail,
  validationInputVoidCheck,
  urlFormatValidation,
} from '@/utils/inputMultiValidationCheck';
import { ValidationMessages } from '@/utils/message';

import Steps from '../components/Steps';

export function CreateTeam(): React.ReactElement {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [teamName, setTeamName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  // const location = useLocation();
  // const { teamName } = location.state as State;

  const [plan, setPlan] = useState('');

  // TODO プラン選択画面から情報を持ってきて設定
  // useEffect(() => {
  //   setPlan('light');
  // }, []);

  const [userName, setUserName] = useState('');
  const [division, setDivision] = useState('');
  const [post, setPost] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [mailAddress, setMailAddress] = useState('');
  // const plan_list = ['ライト', 'ベーシック', 'エンタープライズ'];
  // const price_list = [
  //   { year: '9,800', month: '14,700' },
  //   { year: '29,800', month: '41,720' },
  //   { year: '82,800', month: '107,640' },
  // ];
  const [teamNameError, setTeamNameError] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [companyNameError, setCompanyNameError] = useState(true);
  const [companyNameErrorMessage, setCompanyNameErrorMessage] = useState('');
  const [companyPhoneNumberError, setCompanyPhoneNumberError] = useState(true);
  const [companyPhoneNumberErrorMessage, setCompanyPhoneNumberErrorMessage] = useState('');
  const [companyUrlError, setCompanyUrlError] = useState(true);
  const [companyUrlErrorMessage, setCompanyUrlErrorMessage] = useState('');
  const [userNameError, setUserNameError] = useState(true);
  const [userNameErrorMessage, setUserNameErrorMessage] = useState('');
  const [divisionError, setDivisionError] = useState(true);
  const [divisionErrorMessage, setDivisionErrorMessage] = useState('');
  const [postError, setPostError] = useState(true);
  const [postErrorMessage, setPostErrorMessage] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState(true);
  const [phoneNumberErrorMessage, setPhoneNumberErrorMessage] = useState('');
  const [mailAddressError, setMailAddressError] = useState(true);
  const [mailAddressErrorMessage, setMailAddressErrorMessage] = useState('');

  const plans = [
    {
      name: 'light',
      display_name: 'ライトプラン',
      year: '9,800',
      total: '117,600',
      description: '基本的な機能を試していただくことが可能です。',
      month: '12,740',
      initial_cost: '200,000',
    },
    {
      name: 'basic',
      display_name: 'ベーシックプラン',
      year: '39,800',
      total: '477,600',
      description:
        '基本的な機能に加え、制限内で案件前後に活用できる追加機能も使用可能です。各SNS媒体も並行して活用ができます。',
      month: '55,720',
      initial_cost: '200,000',
    },
    {
      name: 'enterprise',
      display_name: 'エンタープライズプラン',
      year: '79,800',
      total: '957,600',
      description:
        '基本的な機能に加え、案件前後に活用できる追加機能使用数が増えます。さらに無制限にユーザーを追加でき、組織全体を包括的に管理できます。',
      month: '119,700',
      initial_cost: '200,000',
    },
  ];

  const addPostOption = useAssetDataJson('/post') ?? [];

  const teamNameValidation1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setTeamName(text);
    if (!validationMessageMaxNumCheck(text, 20)) {
      setTeamNameError(true);
      const errorMessage = ValidationMessages.strMaxLenMessage('チーム名', 20);
      setErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(text)) {
      setTeamNameError(true);
      const errorMessage = ValidationMessages.inputMessage('チーム名');
      setErrorMessage(errorMessage);
    } else {
      setTeamNameError(false);
    }
  };

  const teamNameValidation2 = () => {
    if (!validationInputVoidCheck(teamName)) {
      setTeamNameError(true);
      const errorMessage = ValidationMessages.inputMessage('チーム名');
      setErrorMessage(errorMessage);
    } else {
      setCurrentStep(3);
    }
  };

  const companyNameValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setCompanyName(text);
    if (!validationMessageMaxNumCheck(text, 50)) {
      setCompanyNameError(true);
      const errorMessage = ValidationMessages.strMaxLenMessage('企業名', 50);
      setCompanyNameErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(text)) {
      setCompanyNameError(true);
      const errorMessage = ValidationMessages.inputMessage('企業名');
      setCompanyNameErrorMessage(errorMessage);
    } else {
      setCompanyNameError(false);
    }
  };

  const companyUrlValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setCompanyUrl(text);
    if (!urlFormatValidation(text)) {
      setCompanyUrlError(true);
      const errorMessage = ValidationMessages.invalidUrlMessage('企業ウェブサイト');
      setCompanyUrlErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(text)) {
      setCompanyUrlError(true);
      const errorMessage = ValidationMessages.inputMessage('企業ウェブサイト');
      setCompanyUrlErrorMessage(errorMessage);
    } else {
      setCompanyUrlError(false);
    }
  };

  const comanyPhoneNumberValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;
    setCompanyPhoneNumber(number);
    if (!validationInputHyphensCheck(number)) {
      setCompanyPhoneNumberError(true);
      console.log('ハイフンエラー');
      const errorMessage = ValidationMessages.hyphenCheckMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else if (!validationInputNumberCheck(number)) {
      setCompanyPhoneNumberError(true);
      console.log('数字以外エラー');
      const errorMessage = ValidationMessages.numberCheckMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else if (!validationInputHalfWidthCheck(number)) {
      setCompanyPhoneNumberError(true);
      console.log('半角エラー');
      const errorMessage = ValidationMessages.halfWidthCheckMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else if (number.length > 0 && validationInputZeroStartCheck(number)) {
      setCompanyPhoneNumberError(true);
      console.log('0スタートエラー');
      console.log(number.length);
      const errorMessage = ValidationMessages.phoneNumberCheckMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else if (number.length > 0 && !validationMessageMaxNumCheck(number, 11)) {
      setCompanyPhoneNumberError(true);
      const errorMessage = ValidationMessages.phoneNumberCheckMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else if (number.length > 0 && validationMessageMinNumCheck(number, 10)) {
      setCompanyPhoneNumberError(true);
      const errorMessage = ValidationMessages.phoneNumberCheckMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(number)) {
      setCompanyPhoneNumberError(true);
      const errorMessage = ValidationMessages.inputMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    } else {
      setCompanyPhoneNumberError(false);
    }
  };

  const companyInfoValidation = () => {
    if (!validationInputVoidCheck(companyName)) {
      setCompanyNameError(true);
      const errorMessage = ValidationMessages.inputMessage('企業名');
      setCompanyNameErrorMessage(errorMessage);
    }
    if (!validationInputVoidCheck(companyUrl)) {
      setCompanyUrlError(true);
      const errorMessage = ValidationMessages.inputMessage('企業ウェブサイト');
      setCompanyUrlErrorMessage(errorMessage);
    }
    if (!validationInputVoidCheck(companyPhoneNumber)) {
      setCompanyPhoneNumberError(true);
      const errorMessage = ValidationMessages.inputMessage('企業電話番号');
      setCompanyPhoneNumberErrorMessage(errorMessage);
    }
    if (!companyNameError && !companyUrlError && !companyPhoneNumberError) {
      setCurrentStep(4);
      console.log('役職', post);
    }
  };

  const userNameValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setUserName(text);
    if (!validationMessageMaxNumCheck(text, 30)) {
      setUserNameError(true);
      const errorMessage = ValidationMessages.strMaxLenMessage('お名前', 30);
      setUserNameErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(text)) {
      setUserNameError(true);
      const errorMessage = ValidationMessages.inputMessage('お名前');
      setUserNameErrorMessage(errorMessage);
    } else {
      setUserNameError(false);
    }
  };

  const divisionValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setDivision(text);
    if (!validationMessageMaxNumCheck(text, 30)) {
      setDivisionError(true);
      const errorMessage = ValidationMessages.strMaxLenMessage('部署', 30);
      setDivisionErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(text)) {
      setDivisionError(true);
      const errorMessage = ValidationMessages.inputMessage('部署');
      setDivisionErrorMessage(errorMessage);
    } else {
      setDivisionError(false);
    }
  };

  const postValidation = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const text = e.target.value;
    setPost(text);
    if (!validationInputVoidCheck(text)) {
      setPostError(true);
      const errorMessage = ValidationMessages.inputMessage('役職');
      setPostErrorMessage(errorMessage);
    } else {
      setPostError(false);
    }
  };

  const phoneNumberValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const number = e.target.value;
    setPhoneNumber(number);
    if (!validationInputHyphensCheck(number)) {
      setPhoneNumberError(true);
      // console.log('ハイフンエラー');
      const errorMessage = ValidationMessages.hyphenCheckMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else if (!validationInputNumberCheck(number)) {
      setPhoneNumberError(true);
      // console.log('数字以外エラー');
      const errorMessage = ValidationMessages.numberCheckMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else if (!validationInputHalfWidthCheck(number)) {
      setPhoneNumberError(true);
      // console.log('半角エラー');
      const errorMessage = ValidationMessages.halfWidthCheckMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else if (number.length > 0 && validationInputZeroStartCheck(number)) {
      setPhoneNumberError(true);
      // console.log('0スタートエラー');
      // console.log(number.length);
      const errorMessage = ValidationMessages.phoneNumberCheckMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else if (number.length > 0 && !validationMessageMaxNumCheck(number, 11)) {
      setPhoneNumberError(true);
      const errorMessage = ValidationMessages.phoneNumberCheckMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else if (number.length > 0 && validationMessageMinNumCheck(number, 10)) {
      setPhoneNumberError(true);
      const errorMessage = ValidationMessages.phoneNumberCheckMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else if (!validationInputVoidCheck(number)) {
      setPhoneNumberError(true);
      const errorMessage = ValidationMessages.inputMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    } else {
      setPhoneNumberError(false);
    }
  };

  const mailAddressValidation = (e: React.ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setMailAddress(text);
    if (!isValidEmail(text)) {
      setMailAddressError(true);
      const errorMessage = ValidationMessages.formatMessage('メールアドレス');
      setMailAddressErrorMessage(errorMessage);
    } else if (!validationInputHalfWidthCheck(text)) {
      setMailAddressError(true);
      const errorMessage = ValidationMessages.halfWidthCheckMessage('メールアドレス');
      setMailAddressErrorMessage(errorMessage);
    } else if (!validationMessageMaxNumCheck(text, 256)) {
      setMailAddressError(true);
      const errorMessage = ValidationMessages.strMaxLenMessage('メールアドレス', 256);
      setMailAddressErrorMessage(errorMessage);
    } else {
      setMailAddressError(false);
    }
  };

  const createTeamValidation = async () => {
    console.log('createTeamValidation');
    if (!validationInputVoidCheck(userName)) {
      console.log('userName', userName);
      setUserNameError(true);
      console.log('userNameError', userNameError);
      const errorMessage = ValidationMessages.inputMessage('お名前');
      setUserNameErrorMessage(errorMessage);
    }
    if (!validationInputVoidCheck(division)) {
      setDivisionError(true);
      const errorMessage = ValidationMessages.inputMessage('部署');
      setDivisionErrorMessage(errorMessage);
    }
    if (!validationInputVoidCheck(post)) {
      setPostError(true);
      const errorMessage = ValidationMessages.inputMessage('役職');
      setPostErrorMessage(errorMessage);
    }
    if (!validationInputVoidCheck(phoneNumber)) {
      setPhoneNumberError(true);
      const errorMessage = ValidationMessages.inputMessage('電話番号');
      setPhoneNumberErrorMessage(errorMessage);
    }
    if (!validationInputVoidCheck(mailAddress)) {
      setMailAddressError(true);
      const errorMessage = ValidationMessages.inputMessage('メールアドレス');
      setMailAddressErrorMessage(errorMessage);
    }
    console.log(
      'userNameError',
      userNameError,
      'divisionError',
      divisionError,
      'phoneNumberError',
      phoneNumberError,
      'mailAddressError',
      mailAddressError,
      'postError',
      postError
    );
    console.log(!userNameError && !divisionError && !phoneNumberError && !mailAddressError && !postError);
    if (!userNameError && !divisionError && !phoneNumberError && !mailAddressError && !postError) {
      console.log('executeCreateTeamInfo exec');
      const createTeamInfoRequest: CreateTeamInfoRequest = {
        plan: plan,
        teamName: teamName,
        companyName: companyName,
        companyUrl: companyUrl,
        companyPhoneNumber: companyPhoneNumber,
        userName: userName,
        division: division,
        post: post,
        phoneNumber: phoneNumber,
        mailAddress: mailAddress,
      };

      console.log('createTeamInfoRequest:', createTeamInfoRequest);

      try {
        // チーム作成APIリクエスト
        const data = createTeamInfo(createTeamInfoRequest);
        console.log('executeCreateTeamInfo:', data);
        navigate('/home', { state: { shouldOpenModal: true } });
      } catch (error) {
        Sentry.captureException(error);
        console.error('API call failed:', error);
        return;
      }
    }
  };

  return (
    <>
      <div>
        <div id="header" className="flex h-10 flex-col items-center justify-center self-stretch bg-black">
          <img src={getImageUrl('assets/logo/row_white_2.png')} alt="" width="113.19" height="34.37" />
        </div>

        <div className="flex w-auto flex-row justify-center pt-[48px]">
          <div className="order-1">{Steps(currentStep)}</div>
        </div>

        {currentStep === 1 && (
          <div className="w-[1440px]">
            <div className="mb-[24px] mt-[33px] flex w-full items-center justify-center">
              <span className="h-[36px] w-[1280px] text-center text-3xl font-bold leading-9 ">
                まずは基本プランを選択してください
              </span>
            </div>
            <div className="mb-[49px] flex w-full flex-col items-center justify-center">
              <p className="text-center text-sm font-normal leading-5">
                この度、正式リリースに先駆けて、
                <br />
                皆様からの貴重なフィードバックを基に、
                <br />
                さらなる機能の充実を図るため
                <br />
                各プランの<span className="font-bold">ベータ版を期間限定で無料でご提供</span>いたします。
              </p>
              <p className="mt-4 text-center text-sm font-normal leading-5">
                3つのプランをご用意しており、どのプランもお客様のニーズに合わせて設計されていますが、
                <br />
                ベータ版期間中は、全プランの機能を無料でお試しいただけます。
              </p>
              <p className="mt-4 text-center text-sm font-normal leading-5">
                ※ベータ版の終了時期につきましては、別途ご案内いたします。
              </p>
            </div>
            <div className="ml-[130px] mr-[94px] flex">
              <div className="mb-[270px] w-[1216px] rounded-lg border-[1px] border-solid border-gray-200 p-6">
                <div className="flex border-b-[1px] border-solid border-gray-200 pb-4">
                  <div className="flex h-[41px] w-[304px] items-center justify-center"></div>
                  {plans.map((plan, index) => (
                    <div key={index} className="flex h-[41px] w-[304px] items-center justify-center">
                      <span className="h-[24px] w-[256px] text-lg font-semibold leading-6 text-gray-900">
                        {plan.display_name}
                      </span>
                    </div>
                  ))}
                </div>
                <div className="flex">
                  <div className="w-[304px] px-[24px] py-[32px]">
                    <span className="h-[20px] w-[256px] text-sm font-medium leading-5 text-gray-900">価格</span>
                  </div>
                  {plans.map((plan, index) => (
                    <div key={index} className="w-[23%] px-[24px] py-[32px]">
                      <div className="flex">
                        <p className="h-[40px] text-4xl font-extrabold leading-10 text-gray-900">¥{plan.year}</p>
                        <p className="ml-[4px] mt-[16px] h-[24px] text-base font-medium leading-6 text-gray-500">
                          /月(年払い)
                        </p>
                      </div>
                      <div className="mt-[4px] flex">
                        <p className="mt-[6px] h-[16px]  items-end text-center text-[12px] font-medium leading-[16px] text-[#888888]">
                          ¥
                        </p>
                        <p className="ml-[2px] h-[28px] items-end text-center text-[16px] font-semibold leading-[28px] text-[#888888]">
                          {plan.total}
                        </p>
                        <p className="mt-[5px] h-[18px] text-center text-[12px] font-normal leading-[18px] text-[#888888]">
                          /年
                        </p>
                      </div>
                      <p className="mt-[16px] h-[80px] text-sm font-normal leading-5 text-gray-500">
                        {plan.description}
                      </p>
                      <div className="mt-[16px] flex">
                        <p className="mt-[6px] h-[16px]  items-end text-center text-[12px] font-medium leading-[16px] text-[#888888]">
                          ¥
                        </p>
                        <p className="ml-[2px] h-[28px] items-end text-center text-[16px] font-semibold leading-[28px] text-[#888888]">
                          {plan.month}
                        </p>
                        <p className="mt-[5px] h-[18px] text-center text-[12px] font-normal leading-[18px] text-[#888888]">
                          /月(月払い)
                        </p>
                      </div>
                      <div className="mt-[16px] flex">
                        <p className="mt-[5px] h-[18px]  text-center text-[12px] font-normal leading-[18px] text-[#888888]">
                          初期費用
                        </p>
                        <p className="ml-[8px] mt-[6px] h-[16px] items-end text-center text-[12px] font-medium leading-[16px] text-[#888888]">
                          ¥
                        </p>
                        <p className="ml-[2px] h-[28px]  items-end text-center text-[16px] font-semibold leading-[28px] text-[#888888]">
                          {plan.initial_cost}
                        </p>
                      </div>
                      <Button
                        className="mt-[24px] h-[38px] w-[230px] text-sm font-medium leading-5"
                        style={{ background: '#007CC2' }}
                        onClick={() => {
                          setCurrentStep(2);
                          setPlan(plan.name);
                        }}
                      >
                        β版を無料で試す
                      </Button>
                    </div>
                  ))}
                </div>
                <div className="bg-white">
                  <div className="flex h-[45px] border-y border-[#E5E7EB] bg-gray-50">
                    <p className="ml-[16px] mt-[12px] h-[20px] text-sm font-medium leading-5 text-gray-900">
                      発行可能なアカウント数
                    </p>
                  </div>
                  <div className="flex h-[81px] border-b border-[#E5E7EB] py-5">
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">アカウント数</p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      4アカウント
                      <br />
                      ※管理者アカウント1つまで
                    </p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      15アカウント
                      <br />
                      ※管理者アカウント5つまで
                    </p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">無制限</p>
                  </div>
                  <div className="flex h-[45px] border-b border-[#E5E7EB] bg-gray-50">
                    <p className="ml-[16px] mt-[12px] h-[20px] text-sm font-medium leading-5 text-gray-900">
                      案件レポート・分析
                    </p>
                  </div>
                  <div className="flex h-[81px] border-b border-[#E5E7EB] py-5">
                    <p className="mx-[24px] w-[21%] pr-[22px] text-sm font-normal leading-5 text-gray-500">
                      インフルエンサーからのフィードバック
                    </p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                  </div>
                  {/* TODO:SNSアカウント分析はMVP後に表示
                  <div className="flex h-[60px] border-b border-[#E5E7EB]">
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      SNSアカウント分析
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      1アカウント
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      3アカウント
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      無制限
                    </p>
                  </div> */}
                  <div className="flex h-[61px] border-b border-[#E5E7EB] py-5">
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      レポートデータの保存期間
                    </p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">1年間</p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">無制限</p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">無制限</p>
                  </div>
                  <div className="flex h-[81px] border-b border-[#E5E7EB] py-5">
                    <p className="mx-[24px] w-[21%] pr-1 text-sm font-normal leading-5 text-gray-500">
                      レポートデータのエクスポート（CSV）
                    </p>
                    {/* Comming soonが解除されたら使用できるよう残しています */}
                    {/* <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">ー</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">ー</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p> */}
                    <p className="flex w-[91%] items-center justify-start pl-[162px] text-[22px] italic text-[#323f57] before:mr-10 before:w-[106px] before:border before:content-[''] after:ml-10 after:w-[106px] after:border after:content-['']">
                      Comming soon
                    </p>
                  </div>
                  <div className="flex h-[45px] border-b border-[#E5E7EB] bg-gray-50">
                    <p className="ml-[16px] mt-[12px] h-[20px] w-[272px] text-sm font-medium leading-5 text-gray-900">
                      案件無料実施特典
                    </p>
                  </div>
                  <div className="flex h-[61px] border-b border-[#E5E7EB] py-5">
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      Good Quality ボーナス
                    </p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">1回無料/年</p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">2回無料/年</p>
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">5回無料/年</p>
                  </div>
                  {/* Comming soonが解除されたら使用できるよう残しています */}
                  {/* <div className="flex h-[60px] border-b border-[#E5E7EB]">
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      EMERALD POST配信
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      1配信無料/年
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      2配信無料/年
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      3配信無料/年
                    </p>
                  </div>
                  <div className="flex h-[60px] border-b border-[#E5E7EB]">
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      スポットコンサル
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      ー
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      1回無料/年
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      2回無料/年
                    </p>
                  </div>
                  <div className="flex h-[60px] border-b border-[#E5E7EB]">
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      インフルエンサーアンケート
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      ー
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      1回無料/年
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      3回無料/年
                    </p>
                  </div> */}

                  <div className="flex items-center border-b border-[#E5E7EB]">
                    <div className="mr-[24px] flex w-[21%] flex-col">
                      <p className="h-[61px] border-b border-[#E5E7EB] py-5 pl-6 text-sm font-normal leading-5 text-gray-500">
                        EMERALD POST配信
                      </p>
                      <p className="h-[61px] border-b border-[#E5E7EB] py-5 pl-6 text-sm font-normal leading-5 text-gray-500">
                        スポットコンサル
                      </p>
                      <p className="h-[61px] py-5 pl-6 text-sm font-normal leading-5 text-gray-500">
                        インフルエンサーアンケート
                      </p>
                    </div>
                    <div className="w-[91%]">
                      <p className="flex w-full items-center justify-start pl-[176px] text-[22px] italic text-[#323f57] before:mr-10 before:w-[106px] before:border before:content-[''] after:ml-10 after:w-[106px] after:border after:content-['']">
                        Comming soon
                      </p>
                    </div>
                  </div>
                  <div className="flex h-[45px] border-b border-[#E5E7EB] bg-gray-50">
                    <p className="ml-[16px] mt-[12px] h-[20px] w-[272px] text-sm font-medium leading-5 text-gray-900">
                      その他
                    </p>
                  </div>
                  <div className="flex h-[61px] border-b border-[#E5E7EB] py-5">
                    <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">インフィード広告</p>
                    {/* Comming soonが解除されたら使用できるよう残しています */}
                    {/* <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">ー</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p> */}
                    <p className="flex w-[91%] items-center justify-start pl-[162px] text-[22px] italic text-[#323f57] before:mr-10 before:w-[106px] before:border before:content-[''] after:ml-10 after:w-[106px] after:border after:content-['']">
                      Comming soon
                    </p>
                  </div>
                  <div className="flex h-[60px]">
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      コミュニティ参加人数
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      無制限
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      無制限
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      無制限
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div>
            <div className="flex w-[1460px] justify-center pt-[32px]">
              <p className="text-3xl font-bold leading-9">チームを作成しましょう</p>
            </div>

            <div className="flex w-[1460px] justify-center pt-[16px]">
              <p className="h-[40px] w-[480px] text-sm font-normal leading-5">
                会社単位、広報チーム単位など、SNSマーケティングが管理しやすいグループでチームを作りましょう
              </p>
            </div>

            <div className="flex w-[1460px] justify-center pt-[32px]">
              <div>
                <div>
                  <label htmlFor="teamName" className="block text-sm font-semibold leading-6 text-gray-900">
                    チーム名
                  </label>
                  <div className="mt-2">
                    <input
                      type="text"
                      name="teamName"
                      id="teamName"
                      className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      placeholder="チーム名"
                      value={teamName}
                      // onChange={(e) => {
                      //   setTeamName(e.target.value);
                      // }}
                      onChange={teamNameValidation1}
                    />
                  </div>
                  <p className="mt-2 text-sm text-gray-500" id="email-description">
                    後ほど設定変更可能です。自由につけてみましょう
                  </p>
                  {teamNameError && (
                    <p className="mt-2 text-sm text-red-500" id="email-description">
                      {errorMessage}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex w-[1440px] justify-center pt-[48px]">
              <div className="ml-5 h-[38px] w-[62px] font-medium">
                <Button
                  variant="white"
                  size="sm"
                  className="h-[38px] w-[62px] whitespace-nowrap"
                  onClick={() => setCurrentStep(1)}
                >
                  戻る
                </Button>
              </div>
              <div className="h-[38px] w-[62px] font-medium last-of-type:ml-[358px]">
                <Button
                  variant="primary"
                  size="sm"
                  className="h-[38px] w-[62px] whitespace-nowrap"
                  onClick={teamNameValidation2}
                >
                  次へ
                </Button>
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div>
            <div className="flex w-[1460px] justify-center pt-[32px]">
              <p className="text-3xl font-bold leading-9">このチームが所属する企業は？</p>
            </div>

            <div>
              <form className="flex w-[1440px] flex-col items-center justify-center" action="" method="post">
                {/* flex-col でしか上手くいかない */}
                <div className="pt-[48px]">
                  <div>
                    <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                      企業名
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="companyName"
                        id="companyName"
                        className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="企業名"
                        value={companyName}
                        //
                        onChange={companyNameValidation}
                      />
                    </div>
                  </div>
                  {companyNameError && (
                    <p className="mt-2 text-sm text-red-500" id="email-description">
                      {companyNameErrorMessage}
                    </p>
                  )}
                </div>
                <div className="pt-[40px]">
                  <div>
                    <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                      企業ウェブサイト
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="companyUrl"
                        id="companyUrl"
                        className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="企業ウェブサイト"
                        value={companyUrl}
                        // onChange={(e) => {
                        //   setCompanyUrl(e.target.value);
                        // }}
                        onChange={companyUrlValidation}
                      />
                    </div>
                  </div>
                  {companyUrlError && (
                    <p className="mt-2 text-sm text-red-500" id="email-description">
                      {companyUrlErrorMessage}
                    </p>
                  )}
                </div>
                <div className="pt-[40px]">
                  <div>
                    <label htmlFor="number" className="block text-sm font-semibold leading-6 text-gray-900">
                      企業電話番号
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        // type="number"
                        name="companyPhoneNumber"
                        id="companyPhoneNumber"
                        className="block h-[38px] w-[480px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="企業電話番号"
                        value={companyPhoneNumber}
                        // onChange={(e) => {
                        //   setCompanyPhoneNumber(e.target.value);
                        // }}
                        onChange={comanyPhoneNumberValidation}
                      />
                    </div>
                  </div>
                  {companyPhoneNumberError && (
                    <p className="mt-2 text-sm text-red-500" id="email-description">
                      {companyPhoneNumberErrorMessage}
                    </p>
                  )}
                </div>
                {/* flex-col 入れる */}
              </form>
              <div className="flex w-[1440px] justify-center pt-[48px]">
                <div className="h-[38px] w-[62px] font-medium">
                  {/* <button
                    className="rounded-md border bg-sky-600 px-[16px] py-[9px] text-sm text-white"
                    style={{ background: '#FFFFFF', color: '#374151' }}
                    onClick={() => setCurrentStep(2)}
                  >
                    戻る
                  </button> */}
                  <Button variant="white" size="sm" className="whitespace-nowrap" onClick={() => setCurrentStep(2)}>
                    戻る
                  </Button>
                </div>
                <div className="h-[38px] w-[62px] font-medium last-of-type:ml-[356px]">
                  {/* <button
                    className="rounded-md border bg-sky-600 px-[16px] py-[9px] text-sm text-white"
                    style={{ background: '#007CC2' }}
                    // onClick={() => setCurrentStep(4)}
                    onClick={companyInfoValidation}
                  >
                    次へ
                  </button> */}
                  <Button variant="primary" size="sm" className="whitespace-nowrap" onClick={companyInfoValidation}>
                    次へ
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 4 && (
          <div>
            <div className="flex w-[1460px] justify-center pt-[32px]">
              <p className="text-3xl font-bold leading-9">最後に、チームの代表者について教えてください</p>
            </div>

            <div>
              <form className="flex w-[1440px] flex-col items-center justify-center" action="" method="post">
                {/* flex-col でしか上手くいかない */}
                <div className="pt-[48px]">
                  <div>
                    <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                      お名前
                    </label>
                    <div className="mt-2">
                      <input
                        type="text"
                        name="name"
                        id="name"
                        className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="お名前"
                        value={userName}
                        // onChange={(e) => {
                        //   setUserName(e.target.value);
                        // }}
                        onChange={userNameValidation}
                      />
                    </div>
                  </div>
                  {userNameError && <p className="mt-2 text-sm text-red-500">{userNameErrorMessage}</p>}
                </div>
                <div className="pt-[40px]">
                  <div className="flex">
                    <div>
                      <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                        部署
                      </label>
                      <div className="mt-2">
                        <input
                          type="text"
                          name="division"
                          id="division"
                          className="block h-[38px] w-[228px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                          placeholder="部署"
                          value={division}
                          // onChange={(e) => {
                          //   setDivision(e.target.value);
                          // }}
                          onChange={divisionValidation}
                        />
                      </div>
                      {divisionError && <p className="mt-2 text-sm text-red-500">{divisionErrorMessage}</p>}
                    </div>
                    <div className="ml-[24px]">
                      <div>
                        <label htmlFor="country" className="mb-2 block text-sm font-semibold leading-6 text-gray-900">
                          役職
                        </label>
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className={clsx(
                            'relative block h-[38px] w-[228px] rounded-md border-none px-[13px] py-[6px] text-gray-400 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6',
                            post === '' ? 'text-gray-400' : 'text-gray-900'
                          )}
                          value={post}
                          onChange={postValidation}
                        >
                          <option value="" disabled selected>
                            選択してください
                          </option>
                          {addPostOption.map((post) => (
                            <option key={post.value}>{post.label}</option>
                          ))}
                        </select>
                        {postError && <p className="mt-2 text-sm text-red-500">{postErrorMessage}</p>}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pt-[40px]">
                  <div>
                    <label htmlFor="phoneNumber" className="block text-sm font-semibold leading-6 text-gray-900">
                      電話番号
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        // type="number"
                        name="phoneNumber"
                        id="phoneNumber"
                        className="block h-[38px] w-[480px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="電話番号"
                        value={phoneNumber}
                        // onChange={(e) => {
                        //   setPhoneNumber(e.target.value);
                        // }}
                        onChange={phoneNumberValidation}
                      />
                    </div>
                    {phoneNumberError && <p className="mt-2 text-sm text-red-500">{phoneNumberErrorMessage}</p>}
                  </div>
                </div>
                <div className="pt-[40px]">
                  <div>
                    <label htmlFor="email" className="block text-sm font-semibold leading-6 text-gray-900">
                      メールアドレス
                    </label>
                    <div className="relative mt-2 rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <EnvelopeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                      </div>
                      <input
                        // type="email"
                        name="mailAddress"
                        id="mailAddress"
                        className="block h-[38px] w-[480px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        placeholder="メールアドレス"
                        value={mailAddress}
                        // onChange={(e) => {
                        //   setMailAddress(e.target.value);
                        // }}
                        onChange={mailAddressValidation}
                      />
                    </div>
                    {mailAddressError && <p className="mt-2 text-sm text-red-500">{mailAddressErrorMessage}</p>}
                  </div>
                </div>
                {/* flex-col 入れる */}
              </form>
              <div className="flex w-[1440px] justify-center pt-[48px]">
                <div className="h-[38px] w-[62px] font-medium">
                  {/* <button
                    className="rounded-md border bg-sky-600 px-[16px] py-[9px] text-sm text-white"
                    style={{ background: '#FFFFFF', color: '#374151' }}
                    onClick={() => setCurrentStep(3)}
                  >
                    戻る
                  </button> */}
                  <Button variant="white" size="sm" className="whitespace-nowrap" onClick={() => setCurrentStep(3)}>
                    戻る
                  </Button>
                </div>
                <div className="h-[38px] w-[146px] font-medium last-of-type:ml-[272px]">
                  {/* <input
                    className="rounded-md border bg-sky-600 px-[16px] py-[9px] text-sm text-white"
                    style={{ background: '#007CC2' }}
                    type="submit"
                    value="チームを作成する"
                    // onClick={async () => {
                    //   const success = await executeCreateTeamInfo();
                    //   if (success) {
                    //     navigate('/home', { state: { shouldOpenModal: true } });
                    //   }
                    // }}
                    onClick={createTeamValidation}
                  /> */}
                  <Button
                    variant="primary"
                    size="sm"
                    className="whitespace-nowrap"
                    type="submit"
                    onClick={createTeamValidation}
                  >
                    チームを作成する
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
