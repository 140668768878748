import clsx from 'clsx';
import * as React from 'react';

import { ReactComponent as ImageAdd } from '@/assets/icons/bx-image-add.svg';

export type FileUploadedTilekProps = React.HTMLAttributes<HTMLElement> & {
  height: number;
  width: number;
};

export const FileUploadedTile = React.forwardRef<HTMLElement, FileUploadedTilekProps>(
  ({ className = '', ...props }) => {
    return (
      <div
        className={clsx(
          'flex flex-col items-center justify-center gap-1 border-2 border-dashed border-gray-300',
          className
        )}
      >
        <div className="flex flex-col items-center justify-center">
          <ImageAdd fill="#9CA3AF" height={props.height} width={props.width} />
        </div>
      </div>
    );
  }
);
FileUploadedTile.displayName = 'FileUploadedTile';
