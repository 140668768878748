import { ReactComponent as CheckIcon } from '@/assets/icons/bx-check.svg';

import { phaseInfo } from '../Interfaces/PhaseNavigationInterfaces';

export function perPhase(pahse: phaseInfo, index: number) {
  const seq = `0${index + 1}`;
  if (pahse.isCurrent) {
    return (
      <div className="flex items-center px-6 py-4 text-sm font-medium" aria-current="step">
        <span className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full border-2 border-[#006199]">
          <span className="text-[#006199]">{seq}</span>
        </span>
        <span className="ml-4 w-[84px] text-sm font-medium text-[#006199]">{pahse.name}</span>
      </div>
    );
  } else if (pahse.isDone) {
    return (
      <div className="group flex w-full items-center">
        <span className="flex items-center px-6 py-4 text-sm font-medium">
          <span className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-[#006199]">
            <CheckIcon height={20} width={20} fill="#FFFFFF" />
          </span>
          <span className="ml-4 w-[84px] text-sm font-medium text-gray-900">{pahse.name}</span>
        </span>
      </div>
    );
  } else {
    return (
      <div className="group flex items-center">
        <span className="flex items-center px-6 py-4 text-sm font-medium">
          <span className="flex h-10 w-10 shrink-0 items-center justify-center rounded-full border-2 border-gray-300">
            <span className="text-gray-500">{seq}</span>
          </span>
          <span className="ml-4 w-[84px] text-sm font-medium text-gray-500">{pahse.name}</span>
        </span>
      </div>
    );
  }
}
