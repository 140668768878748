import 'chart.js/auto';

import * as Sentry from '@sentry/react';
import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { PostProjectsInput, GetProjectGroupsOutput, ProjectGroup, ProjectType } from '@/api/project';
import { SnsType } from '@/api/team';
import { Button } from '@/components/Elements/Button';
import { PROJECT_TYPES, SNS_TYPES } from '@/config';
import { ROUTES } from '@/config/path';
import { createProjects, fetchProjectGroups } from '@/hooks/ProjectInfo';
import { isMorehanValue } from '@/utils/inputMultiValidationCheck';

import ConfirmForm from '../components/Confirm';
import ProjectForm from '../components/Form';
import { Header, PageTitle } from '../components/Header';
import Delete from '../components/Modals/Delete';
import Order from '../components/Modals/Order';
import StepsNavigation from '../components/Step';
import { STEPS, PROJECT_IMAGE, PROJECT_IMPORTANT, PROJECT_GOAL } from '../constants/path';
import { FormData, StepInterface } from '../interfaces/project';
import {
  decideService,
  isMeasure,
  getRecruitmentInfluencers,
  displayTotalCost,
  getKpiValues,
  getKpiValuesByEngagement,
} from '../utils/logic';

export function Project(): React.ReactElement {
  const navigate = useNavigate();
  const [step, setStep] = useState<StepInterface>();
  const [open, setOpen] = useState<boolean>(false);
  const [payload, setPayload] = useState<FormData>({
    budget: undefined,
    endPeriod: '',
    startPeriod: '',
    sns: '',
    goal: '',
    image: '',
    important: '',
    period: undefined,
    isPeriodError: false,
    isBudgetError: false,
    budgetErrorMessage: '',
    periodErrorMessage: '',
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    kpi: {
      reach: 0,
      postNum: 0,
      engagement: 0,
      engagementRate: 0,
      addFollowerNum: 0,
      kpiUpdate: false,
    },
  });

  const [modalType, setModalType] = useState<string>('');
  // 最終確認モーダル
  const [projectManagementName, setProjectManagementName] = useState<string>('');
  const [projectName, setProjectName] = useState<string>('');
  const [projectGroups, setProjectGroups] = useState<Array<ProjectGroup>>([]);
  const [filterProjectGroups, setFilterProjectGroups] = useState<Array<ProjectGroup>>([]);
  const [projectGroupId, setProjectGroupId] = useState<string>();
  const [createApiError, setCreateApiError] = useState<string>();

  const [projectNameError, setProjectNameError] = useState(false);
  const [projectNameErrorMessage, setProjectNameErrorMessage] = useState<string>('');
  const [projectGroupNameError, setProjectGroupNameError] = useState(false);
  const [inputProjectGroupName, setInputProjectGroupName] = useState('');
  const [selectedProjectGroupName, setSelectedProjectGroupName] = useState('');

  const steps: Array<StepInterface> = [
    { name: 'Step 1', status: STEPS.GOAL, value: 1, size: '665px', isSkip: false },
    { name: 'Step 2', status: STEPS.IMAGE, value: 2, size: '576px', isSkip: false },
    { name: 'Step 3', status: STEPS.IMPORTANT, value: 3, size: '576px', isSkip: true, skipValue: 2 },
    { name: 'Step 4', status: STEPS.SNS, value: 4, size: '800px', isSkip: true, skipValue: 3 },
    { name: 'Step 5', status: STEPS.PERIOD, value: 5, size: '576px', isSkip: false },
    { name: 'Step 6', status: STEPS.BUDGET, value: 6, size: '576px', isSkip: false },
  ];

  const ref = useRef<StepInterface>();

  useEffect(() => {
    const tmpStep = step === undefined ? steps[0] : step;
    if (step == undefined) {
      setStep(tmpStep);
    }
    if (ref.current === undefined) {
      ref.current = tmpStep;
      return;
    } else if (ref.current.value == tmpStep.value) {
      return;
    }

    if (ref.current.value < tmpStep.value) {
      // 次のフォーム表示
      ref.current = tmpStep;
      if (tmpStep.isSkip && isSkip(tmpStep)) {
        nextForm();
      }
    } else if (ref.current.value > tmpStep.value) {
      // 前のフォーム表示
      ref.current = tmpStep;
      if (tmpStep.isSkip && isSkip(tmpStep)) {
        preForm();
      }
    }
  }, [step]);

  const fetchProjectGroupsMore = async (page: number, rows?: Array<ProjectGroup>) => {
    const data: GetProjectGroupsOutput = await fetchProjectGroups(100, page, 'asc');
    const tmpRows = rows ? rows.concat(data.rows) : data.rows;
    setProjectGroups(tmpRows);

    if (data.totalPages > page) {
      fetchProjectGroupsMore(page + 1, tmpRows);
    }
  };

  useEffect(() => {
    try {
      fetchProjectGroupsMore(1);
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  }, []);

  const handleBack = () => {
    preForm();
  };

  const preForm = () => {
    if (step) {
      if (step.value === 1) {
        navigate(ROUTES.HOME);
        return;
      }

      setStep(
        steps.filter((s: StepInterface) => {
          return s.value === step.value - 1;
        })[0]
      );
    }
  };

  const handleNext = () => {
    if (!canNextPage()) {
      return;
    }

    nextForm();
  };

  const nextForm = () => {
    if (step) {
      if (step.value == steps.length) {
        setStep({ name: '', status: STEPS.FINISH, value: 9, size: '', isSkip: false });
        return;
      }
      setStep(
        steps.filter((s: StepInterface) => {
          return s.value === step.value + 1;
        })[0]
      );
    }
  };

  /**
   * 次へボタンで進めるかの判定
   * @returns {boolean} true:進める false:進めない
   */
  const canNextPage = (): boolean => {
    const status = step?.status || '';
    if (status === STEPS.GOAL) {
      return payload.goal != '';
    } else if (status === STEPS.IMAGE) {
      return payload.image != '';
    } else if (status === STEPS.IMPORTANT) {
      return payload.important != '';
    } else if (status === STEPS.SNS) {
      return payload.sns != '';
    } else if (status === STEPS.PERIOD) {
      if (payload.isPeriodError) {
        //入力チェックエラーの場合、先に進めない
        return false;
      }

      if (!payload.period) {
        // 未入力の場合、進めない
        return false;
      }
      if (!isMorehanValue(payload.period, isMeasure(payload) ? 1 : 6)) {
        return false;
      }
      return true;
    } else if (status === STEPS.BUDGET) {
      if (payload.isBudgetError) {
        //入力チェックエラーの場合、先に進めない
        return false;
      }

      if (!payload.budget) {
        // 未入力の場合、進めない
        return false;
      }
      if (!isMorehanValue(payload.budget, 12000)) {
        return false;
      }
      return true;
    } else {
      return false;
    }
  };
  /**
   * ウィザードをスキップするかの判定
   * @param targetStep ステップ情報
   * @returns
   */
  const isSkip = (targetStep: StepInterface): boolean => {
    if (targetStep.status === STEPS.IMPORTANT) {
      return (
        [
          //PROJECT_IMAGE.IMPORTANT_QUANTITY,
          PROJECT_IMAGE.IMPORTANT_QUALITY,
          PROJECT_IMAGE.PR_BY_INFLUENCER,
          PROJECT_IMAGE.ADVICE_IMPORTANT_QUALITY,
        ].indexOf(payload.image) == -1
      );
    } else if (targetStep.status === STEPS.SNS) {
      if (payload.important === PROJECT_IMPORTANT.COST_PERFORMANCE) {
        return true;
      } else if (payload.image == PROJECT_IMAGE.IMPORTANT_QUANTITY) {
        return true;
      } else if (payload.image === PROJECT_IMAGE.CO_CREATE_INFLUENCER) {
        return false;
      }
      return false;
    } else {
      return true;
    }
  };
  /**
   * モーダルを開く(共通関数)
   * @param modalType 表示対象のモーダル
   */
  const hadleModalOpen = (modalType: 'order' | 'delete') => {
    setOpen(true);
    setModalType(modalType);
  };

  /**
   * モーダルを閉じる(共通関数)
   */
  const handleModalClose = () => {
    setOpen(false);
    setModalType('');
  };
  /**
   * 作成中の案件を削除する
   */
  const handleDelete = () => {
    setOpen(false);
    setModalType('');
    navigate(ROUTES.HOME);
  };

  const setModalValue = (value: string, name: string) => {
    if (name == 'projectName') {
      if (value.length > 50) {
        setProjectNameError(true);
        setProjectNameErrorMessage('案件管理名は50文字以下で入力してください');
      } else if (value.length == 0) {
        setProjectNameError(true);
        setProjectNameErrorMessage('案件管理名は必須項目です');
      } else {
        setProjectNameError(false);
      }
      setProjectName(value);
    } else {
      setProjectManagementName(value);
      const tmpFilterProjectGroups = projectGroups.filter((projectGroup: ProjectGroup) => {
        return value.length > 0 && projectGroup.name?.startsWith(value);
      });
      setFilterProjectGroups(tmpFilterProjectGroups);
      setProjectGroupId(undefined);
      if (value.length > 50) {
        setProjectGroupNameError(true);
      } else if (value.length == 0) {
        setProjectGroupNameError(false);
        setSelectedProjectGroupName('');
      } else {
        setProjectGroupNameError(false);
      }
      setInputProjectGroupName(value);
    }
  };

  /**
   * 再異臭確認モーダル プロジェクト名コンボボックス選択時
   * @param id グループID
   * @param name グループ名
   */
  const handleSelectExistProject = (id: string, name: string) => {
    setProjectManagementName(name);
    setFilterProjectGroups([]);
    setProjectGroupId(id);
  };

  /**
   * 最終確認モーダル 決定ボタンクリック処理
   */
  const handleCreateProject = async () => {
    try {
      await createProjects(decideApiAll());
      navigate(ROUTES.HOME);
    } catch (error: any) {
      console.log(error);
      setCreateApiError(error.toString());
    }
  };

  const selectModal = (type: string) => {
    if (type === 'order') {
      return Order({
        handleClose: handleModalClose,
        isStatusOrder: true,
        projectManagementName: projectManagementName,
        projectName: projectName,
        setModalValue: setModalValue,
        projectGroups: filterProjectGroups,
        handleSelectExistProject: handleSelectExistProject,
        handleCreateProject: handleCreateProject,
        apiError: createApiError,
        projectNameError: projectNameError,
        projectNameErrorMessage: projectNameErrorMessage,
        projectGroupNameError: projectGroupNameError,
        inputProjectGroupName: inputProjectGroupName,
        selectedProjectGroupName: selectedProjectGroupName,
      });
    } else if (type === 'delete') {
      return Delete({ handleClose: handleModalClose, handleDelete: handleDelete });
    } else {
      return <></>;
    }
  };
  const decideApiAll = (): PostProjectsInput => {
    const service = decideService(payload);
    let marketBackground = '';
    let problem = '';
    let purpose = '';
    let measure = '';
    let projectNameOther = '';
    let projectManagementNameOther = '';
    let items: { name: string; comment: string; isFixed: boolean }[] = [];
    const kpiValue = getKpiValues(payload)?.minValue || 0;
    if (service == PROJECT_TYPES.EMERALD_POST) {
      marketBackground = `現代社会では、多種多様な情報や商品が溢れており、従来の情報発信手段だけでは、企業のマーケティング活動はますます困難になっています。\r\n\r\n商品やサービスの「認知度」を向上させるために新たな戦略が求められている中で、多くの人々が情報収集をしているSNSが注目されています。\r\n\r\n多数のフォロワーを持つインフルエンサーが企業の最新情報をシェアすることで、その情報は広範囲に届き、効果的に認知度を拡大することが可能です。`;
      problem = `・SNSで効率良く認知を広げたい\r\n・新商品/リニューアルをSNS上で拡散したい\r\n・セール/プレゼントキャンペーン内容を拡散したい\r\n・イベント開催をもっと拡散していきたい\r\n・最新情報をすぐに配信したい・安価で広く情報を届けたい`;
      purpose = `・商品・ブランドの認知の拡大`;
      measure = `・インフルエンサーによるリリース情報の配信`;
      projectNameOther = `EMERALD POST営業確認`;
      projectManagementNameOther = 'EMERALD POST営業確認';
    } else if (service == PROJECT_TYPES.SPIRIT) {
      marketBackground =
        '現代の情報化社会では情報が豊富に溢れ、人々の価値観も多様化しています。この結果、従来のマスメディアや広告による企業主体の情報発信は消費者に対して十分に届きにくく、受け取られにくくなっています。\r\n\r\n一方で、SNSの普及は新たなマーケティングの形を生み出しています。特に、SNS上で大きな影響力を持つインフルエンサーは、自分の価値観に共感するフォロワーへ効果的に情報を届けることができます。\r\n\r\nさらに、インフルエンサーは「体験」情報を共有することで、従来の一方向的な広告とは異なり、フォロワーとの共感を引き出す内容を投稿できます。';
      problem = `・SNSを活用して宣伝する必要性を感じている\r\n・インフルエンサーの選び方がわからない\r\n・どんなインフルエンサーが効果的なのかわからない\r\n・UGCをもっと増やしていきたい\r\n・熱心なユーザーに宣伝してもらいたい\r\n・インフルエンサー施策の振り返り方がわからない\r\n・新しい広告宣伝の打ち手を見つけたい\r\n・届けたいターゲット層に届かない\r\n・施策進行が上手くできない`;
      purpose = `・自社SNSアカウントへの流入\r\n・商品・ブランドへのエンゲージメント（いいね、コメントなど）の獲得\r\n・商品・ブランドのリーチ数（投稿閲覧数）の獲得`;
      measure = `・インフルエンサーによるPR投稿（タイアップ施策）\r\n・アンバサダーによるPR投稿（アンバサダー施策）`;
    } else if (service == PROJECT_TYPES.PRST) {
      marketBackground = `SNSが広く普及していく中で、SNSは現代の消費者行動において中心的な役割を果たしています。多くの人々は、商品やサービスについての興味を持った後、会社のウェブサイトに移動するのではなく、そのままSNS内で公式情報を探す傾向があります。\r\n\r\nそのため、興味を持った人々がSNS上で公式情報が不足していると感じ、結果として離脱するといった機会損失を避けるためには、公式のSNSアカウントの充実が必要不可欠となっています。\r\n\r\nインフルエンサーがSNSアカウントを運用することで、その情報発信力と共感力を活用し、見込み客とのつながりを深めることが可能になります。`;
      problem = `・SNSの運用に手が回らない\r\n・投稿のアイディアがわからない\r\n・検索されるハッシュタグの付け方がわからない\r\n・SNSにマッチするテキスト内容が書けない\r\n・SNSユーザーに受け入れられる写真の撮り方や加工の仕方がわからない\r\n・継続した投稿を続けることができない\r\n・SNSアカウントの活用をしたいが、社内にノウハウが無い`;
      purpose = `・自社SNSアカウントのフォロワー数の増加\r\n・自社SNSアカウントのエンゲージメント（いいね、コメントなど）の獲得\r\n・自社SNSアカウントのリーチ数（投稿閲覧数）の獲得`;
      measure = `・インフルエンサーによるSNSアカウント運用`;
      projectNameOther = `PRST営業確認`;
      projectManagementNameOther = `PRST営業確認`;
    } else if (service == PROJECT_TYPES.JANE_JOHN) {
      marketBackground = `現代の社会は多様な価値観が広がり、また、数多くのコンテンツや商品が市場に出回っています。これは消費者にとっては選択肢が増える一方、企業にとっては競争が激化し、その中で差別化を図ることがますます難しくなっています。\r\n\r\nまた、SNSの普及により、口コミの影響力が非常に高まっています。消費者は企業からの一方的な情報発信よりも、信頼のおける人々の意見や体験に大きく影響を受ける傾向があります。その中でもインフルエンサーの言葉は、多くのフォロワーにとって信頼性が高く、売上に大きく寄与しています。\r\n\r\nこのような背景から、SNSのマーケターであるインフルエンサーへの調査は重要な意義があります。\r\nインフルエンサーは、その感度と言語化能力で他では得られないメッセージの抽出に成功できます。\r\nまた、インフルエンサーは常にフォロワーのニーズをリアルタイムで把握しているため、市場動向を深く理解しています。さらに、インフルエンサーは消費者の視点から商品を考えるため、その意見は消費者が実際に商品を購入して口コミを発信したくなるような商品づくりに貴重なヒントを提供することができます。\r\n`;
      problem = `・ユーザーが求めている商品がわからない\r\n・新規のユーザーが開拓できない\r\n・他社と差別化した商品を生み出したい\r\n・他社の広告に自社の訴求が埋もれてしまう\r\n・トレンドのインフルエンサーが何を考えているかわからない\r\n・これまでのアンケートに物足りなさを感じている`;
      purpose = `・インフルエンサーの生の声を集め、今後のマーケティング戦略や商品サービスに活用する`;
      measure = `・インフルエンサーへのグループインタビューの実施\r\n・インフルエンサーによるSNSアカウント相談\r\n・インフルエンサーへのアンケートの実施`;
      projectNameOther = `JANE JONE営業確認`;
      projectManagementNameOther = `JANE JONE営業確認`;
    }

    if (payload.goal === PROJECT_GOAL.REACH) {
      const valueForEgagementNum =
        payload.sns == SNS_TYPES.INSTAGRAM
          ? 0.01
          : payload.sns == SNS_TYPES.TWITTER
          ? 0.005
          : payload.sns == SNS_TYPES.TIK_TOK
          ? 0.02
          : 0.01;
      if (service == PROJECT_TYPES.EMERALD_POST) {
        items = [
          {
            name: 'リーチ数',
            comment: kpiValue.toString(),
            isFixed: true,
          },
          { name: '投稿数', comment: Math.floor(kpiValue / 6000).toString(), isFixed: true },
        ];
      } else if (service == PROJECT_TYPES.SPIRIT) {
        items = [
          {
            name: 'リーチ数',
            comment: Math.floor(kpiValue).toString(),
            isFixed: true,
          },
          {
            name: 'エンゲージメント数',
            comment: Math.floor(kpiValue * valueForEgagementNum).toString(),
            isFixed: true,
          },
          {
            name: 'エンゲージメント率',
            comment: '1.0%',
            isFixed: true,
          },
        ];
      } else {
        items = [
          {
            name: 'リーチ数',
            comment: kpiValue.toString() || '',
            isFixed: true,
          },
          {
            name: 'エンゲージメント数',
            comment: String(kpiValue * valueForEgagementNum),
            isFixed: true,
          },
          {
            name: 'エンゲージメント率',
            comment: '1.0%',
            isFixed: true,
          },
          {
            name: 'フォロワー増加数',
            comment: '-',
            isFixed: true,
          },
        ];
      }
    } else if (payload.goal === PROJECT_GOAL.ENGAGEMENT) {
      const engagementValue = getKpiValuesByEngagement(payload);
      const valueForReachNum =
        payload.sns == SNS_TYPES.INSTAGRAM
          ? 0.01
          : payload.sns == SNS_TYPES.TWITTER
          ? 0.005
          : payload.sns == SNS_TYPES.TIK_TOK
          ? 0.02
          : 0.01;

      if (service == PROJECT_TYPES.SPIRIT && engagementValue) {
        items = [
          {
            name: 'リーチ数',
            comment: (kpiValue / valueForReachNum).toString() || '',
            isFixed: true,
          },
          {
            name: 'エンゲージメント数',
            comment: kpiValue.toString() || '',
            isFixed: true,
          },
          {
            name: 'エンゲージメント率',
            comment: (valueForReachNum * 100).toString() + '%',
            isFixed: true,
          },
        ];
      } else if (service == PROJECT_TYPES.PRST && engagementValue) {
        items = [
          {
            name: 'リーチ数',
            comment: '-',
            isFixed: true,
          },
          {
            name: 'エンゲージメント数',
            comment: '-',
            isFixed: true,
          },
          {
            name: 'エンゲージメント率',
            comment: (valueForReachNum * 100).toString() + '%',
            isFixed: true,
          },
          {
            name: 'フォロワー増加数',
            comment: '-',
            isFixed: true,
          },
        ];
      } else if (service == PROJECT_TYPES.JANE_JOHN) {
        items = [
          {
            name: 'リーチ数',
            comment: '-',
            isFixed: true,
          },
          {
            name: 'エンゲージメント数',
            comment: '-',
            isFixed: true,
          },
          {
            name: 'エンゲージメント率',
            comment: '-',
            isFixed: true,
          },
          {
            name: 'フォロワー増加数',
            comment: '-',
            isFixed: true,
          },
        ];
      }
    } else if (payload.goal === PROJECT_GOAL.FOLLOWER && payload.period) {
      items = [
        {
          name: 'リーチ数',
          comment: '-',
          isFixed: true,
        },
        {
          name: 'エンゲージメント数',
          comment: '-',
          isFixed: true,
        },
        {
          name: 'エンゲージメント率',
          comment: '-',
          isFixed: true,
        },
        {
          name: 'フォロワー増加数',
          comment: (kpiValue * payload?.period).toString() || '',
          isFixed: true,
        },
      ];
    } else {
      items = [
        {
          name: 'リーチ数',
          comment: '-',
          isFixed: true,
        },
        {
          name: 'エンゲージメント数',
          comment: '-',
          isFixed: true,
        },
        {
          name: 'エンゲージメント率',
          comment: '-',
          isFixed: true,
        },
        {
          name: 'フォロワー増加数',
          comment: '-',
          isFixed: true,
        },
      ];
    }

    // sns表記をウィザード画面の「Instagram, TikTok, YouTube, X」のままだと案件登録時にhttpエラーが発生するため
    //httpエラー回避のためロジックで使用用の表記に戻す
    switch (payload.sns) {
      case 'Instagram':
        payload.sns = 'instagram';
        break;
      case 'TikTok':
        payload.sns = 'tik_tok';
        break;
      case 'YouTube':
        payload.sns = 'youtube';
        break;
      case 'X':
        payload.sns = 'x';
        break;
      default:
        payload.sns;
    }

    return {
      projectGroupName: projectManagementName ? projectManagementName : projectManagementNameOther,
      projectGroupId: projectGroupId || null,
      projectName: projectName ? projectName : projectNameOther,
      projectType: service as ProjectType,
      sns: payload.sns as SnsType,
      cost: parseInt(displayTotalCost(payload).replace(/¥|,/g, '')),
      durations: payload.period ? +payload.period : -1,
      adoptionPlan: getRecruitmentInfluencers(payload) || null,
      marketBackground: marketBackground,
      problem: problem,
      purpose: purpose,
      measure: measure,
      kpi: {
        items,
      },
    };
  };

  const selectedCase: string = decideService(payload);
  console.log(selectedCase);

  return (
    <>
      {open && (
        <div id="project_modal" className="fixed inset-x-0 top-0 z-50 h-full w-full bg-[#6B7280]">
          {selectModal(modalType)}
        </div>
      )}
      {Header()}
      <div className="container mx-auto">
        <div className="pb-6 pt-10">{StepsNavigation(step?.status || '', steps)}</div>
        <div id="container" className="mx-auto flex justify-center gap-24 xl:container">
          <div id="main_frame" className="w-[1280px]">
            {step?.status === STEPS.FINISH ? (
              <>
                <div className="pb-10">{PageTitle(step.status)}</div>
                <ConfirmForm payload={payload} />
              </>
            ) : (
              <ProjectForm step={step?.status || ''} payload={payload} setPayload={setPayload}></ProjectForm>
            )}
            <div className="pt-12">
              {step?.status === STEPS.FINISH ? (
                <div>
                  {selectedCase === 'emerald_post' || selectedCase === 'jane_john' || selectedCase === 'prst' ? (
                    <Button size="lg" className="mx-auto h-[38px] w-[600px]" onClick={handleCreateProject}>
                      営業担当に相談する
                    </Button>
                  ) : (
                    <Button
                      size="lg"
                      className="mx-auto h-[38px] w-[600px]"
                      onClick={() => {
                        hadleModalOpen('order');
                      }}
                    >
                      詳細へ進む
                    </Button>
                  )}

                  <div className="flex items-center justify-center pt-7">
                    <Button size="md" variant="textGray" onClick={() => hadleModalOpen('delete')}>
                      結果を削除してトップへ
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  className="mx-auto flex flex-row items-center justify-center gap-6"
                  style={{ width: step?.size || '' }}
                >
                  <div className="flex flex-row p-0">
                    <Button
                      variant="white"
                      size="sm"
                      className="h-[38px] w-[62px] whitespace-nowrap"
                      onClick={() => handleBack()}
                    >
                      戻る
                    </Button>
                  </div>
                  <div className="ml-auto flex flex-row items-center justify-center">
                    <Button onClick={handleNext}>次へ</Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
