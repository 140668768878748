import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import React, { Fragment, useEffect, useState } from 'react';

import { AnalysisSpiritPostTask } from '@/api/analysisSpirit';
import { GetAnalysisSpiritFeedbackAdoptersRowsEnum, GetAnalysisSpiritSummaryOutput } from '@/api/analysisSpirit/api';
import { FeedbackStatus } from '@/api/influencer';
import { GetProjectOverviewOutput } from '@/api/project';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as FileIcon } from '@/assets/icons/bx-file.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as ImagesIcon } from '@/assets/icons/bx-images.svg';
import { ReactComponent as NotepadIcon } from '@/assets/icons/bx-notepad.svg';
import { Badge } from '@/components/Elements/Badge';
import { PROJECT_TYPES } from '@/config/index';
import {
  getAnalysisSpiritFeedbackAdopters,
  getSummary,
  getSpritPostTasks,
  getSpritPostsByTaskId,
} from '@/hooks/Analysis';
import { getProjectOverviews } from '@/hooks/ProjectInfo';
import { getSpiritDetail } from '@/hooks/SPIRIT';
import { isInstagram, isTwitter, isTikTok } from '@/utils/sns';

import { postProps } from '../../interfaces/analysisInterface';
import AnalysisInstagram from '../Analysis/instargram';
import AnalysisResult from '../Analysis/result';
import AnalysisTikTok from '../Analysis/tiktok';
import AnalysisTwitter from '../Analysis/twiiter';
import AnalysisYoutube from '../Analysis/youtube';
import Feedback from '../feedback/feedback';
import FollowerAttributeInstagram from '../followerAttribute/instagram';
import FollowerAttributeTiktok from '../followerAttribute/tiktok';
import FollowerAttributeTwitter from '../followerAttribute/twiiter';
import FollowerAttributeYoutube from '../followerAttribute/youtube';
import PostInstagram from '../posts/SPIRIT_SNS/instagram';
import PostTiktok from '../posts/SPIRIT_SNS/tiktok';
import PostTwitter from '../posts/SPIRIT_SNS/twitter';
import PostYoutube from '../posts/SPIRIT_SNS/youtube';
import ProjectDetails from '../projectDetails/projectDetails';

export interface SummaryProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sns: string;
  projectType: string;
  id: string;
  project: string;
  projectName: string;
  span: string;
  isGoodQuantity: boolean;
}

interface TabProps {
  name: string;
  key: string;
  icon: React.ReactElement<any, any>;
  activeIcon: React.ReactElement<any, any>;
}

export default function Summary(props: SummaryProps) {
  const [tabKey, setTabKey] = useState<string>(
    props.projectType === PROJECT_TYPES.JANE_JOHN || props.projectType === PROJECT_TYPES.OTHER ? 'result' : 'summary'
  );

  const [summary, setSummary] = useState<GetAnalysisSpiritSummaryOutput>();
  const [projectOverview, setProjectOverview] = useState<GetProjectOverviewOutput>({
    marketBackground: '',
    problem: '',
    purpose: '',
    measure: '',
    kpi: [],
    budget: 0,
    scheduleStartedAt: '',
    scheduleEndedAt: '',
    orderInfo: {
      companyName: '',
      address: '',
      represent: '',
      established: '',
      capital: '',
      business: '',
    },
  });

  const [postTasks, setPostTasks] = useState<postProps[]>([]);
  const [isCompleteFeedback, setIsCompleteFeedback] = useState(false);

  const updateAdopters =
    (projectId: string) => async (rows?: GetAnalysisSpiritFeedbackAdoptersRowsEnum, page?: number) => {
      return getAnalysisSpiritFeedbackAdopters(projectId, rows, page);
    };

  useEffect(() => {
    if (props.id.length > 0) {
      (async () => {
        try {
          const spiritDetail = await getSpiritDetail(props.id);
          setIsCompleteFeedback(spiritDetail.header.feedbackStatus === FeedbackStatus.Admin ? true : false);

          setSummary(await getSummary(props.id));
          setProjectOverview(await getProjectOverviews(props.id));

          const tasks: AnalysisSpiritPostTask[] = await getSpritPostTasks(props.id);

          const tmpPosts: postProps[] = [];
          // 案件ステータスの修正
          for (const task of tasks) {
            const p = await getSpritPostsByTaskId(task.taskId);
            tmpPosts.push({
              task: task,
              posts: p,
            });
          }
          setPostTasks(tmpPosts);
        } catch (error: any) {
          setPostTasks([
            {
              task: {
                taskId: '',
                taskType: 12,
              },
              posts: {
                snsType: 'other',
                summary: {} as any,
                posts: [],
              },
            },
          ]);
          console.error(error);
        }
      })();
    }
  }, [props.isGoodQuantity, props.id]);

  const displanContent = () => {
    if (tabKey == 'summary') {
      return resultSummaryContent();
    } else if (tabKey == 'result') {
      return resultContent();
    } else if (tabKey == 'follwer') {
      return fllowerAttribute();
    } else if (tabKey == 'feedback') {
      return (
        <Feedback
          projectType={props.projectType}
          sns={props.sns}
          isCompleteFeedback={isCompleteFeedback}
          setIsCompleteFeedback={setIsCompleteFeedback}
          getAnalysisFeedbackAdopters={updateAdopters(props.id)}
          projectId={props.id}
        />
      );
    } else if (tabKey == 'post') {
      return postContent();
    } else {
      return projectDetailContent();
    }
  };

  const resultSummaryContent = () => {
    if (summary == undefined) return <></>;
    if (isInstagram(props.sns)) {
      return <AnalysisInstagram summary={summary} />;
    } else if (isTwitter(props.sns)) {
      return <AnalysisTwitter summary={summary} />;
    } else if (isTikTok(props.sns)) {
      return <AnalysisTikTok summary={summary} />;
    } else {
      return <AnalysisYoutube summary={summary} />;
    }
  };

  const resultContent = () => {
    return <AnalysisResult />;
  };

  const fllowerAttribute = () => {
    if (isInstagram(props.sns)) {
      return <FollowerAttributeInstagram />;
    } else if (isTwitter(props.sns)) {
      return <FollowerAttributeTwitter />;
    } else if (isTikTok(props.sns)) {
      return <FollowerAttributeTiktok />;
    } else {
      return <FollowerAttributeYoutube />;
    }
  };

  const postContent = () => {
    if (isInstagram(props.sns)) {
      return <PostInstagram postTasks={postTasks} />;
    } else if (isTwitter(props.sns)) {
      return <PostTwitter postTasks={postTasks} />;
    } else if (isTikTok(props.sns)) {
      return <PostTiktok postTasks={postTasks} />;
    } else {
      return <PostYoutube postTasks={postTasks} />;
    }
  };

  const projectDetailContent = () => {
    return (
      <ProjectDetails
        markeValue={projectOverview.marketBackground}
        theme={projectOverview.problem}
        purpose={projectOverview.purpose}
        description={projectOverview.measure}
        evaluationItem={projectOverview.kpi.join('\n')}
        budget={`￥${projectOverview.budget.toLocaleString()} （税別）`}
        supplierInformation={
          projectOverview.orderInfo.companyName +
          '\n' +
          projectOverview.orderInfo.address +
          '\n' +
          projectOverview.orderInfo.represent +
          '\n' +
          projectOverview.orderInfo.established +
          '\n' +
          projectOverview.orderInfo.capital +
          '\n' +
          projectOverview.orderInfo.business
        }
      />
    );
  };

  const selectTab = (): TabProps[] => {
    if (props.projectType === PROJECT_TYPES.SPIRIT) {
      return [
        {
          name: '結果サマリーと考察',
          key: 'summary',
          icon: <FileIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <FileIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: '投稿',
          key: 'post',
          icon: <ImagesIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <ImagesIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: 'インフルエンサーからのフィードバック',
          key: 'feedback',
          icon: <CommentIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <CommentIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: '案件概要',
          key: 'project',
          icon: <NotepadIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <NotepadIcon width={20} height={20} fill="#007CC2" />,
        },
      ];
    } else if (props.projectType === PROJECT_TYPES.JANE_JOHN || props.projectType === PROJECT_TYPES.OTHER) {
      return [
        {
          name: '結果',
          key: 'result',
          icon: <FileIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <FileIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: 'インフルエンサー一覧',
          key: 'post',
          icon: <GroupIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <GroupIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: 'インフルエンサーからのフィードバック',
          key: 'feedback',
          icon: <CommentIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <CommentIcon width={20} height={20} fill="#007CC2" />,
        },
        {
          name: '案件概要',
          key: 'project',
          icon: <NotepadIcon width={20} height={20} fill="#9CA3AF" />,
          activeIcon: <NotepadIcon width={20} height={20} fill="#007CC2" />,
        },
      ];
    } else {
      return [];
    }
  };
  const handleTabSelct = (key: string) => {
    setTabKey(key);
  };
  const onClose = () => {
    if (props.projectType === PROJECT_TYPES.SPIRIT) {
      setTabKey('summary');
    } else if (props.projectType === PROJECT_TYPES.JANE_JOHN || props.projectType === PROJECT_TYPES.OTHER) {
      setTabKey('result');
    }
    props.setOpen(false);
  };

  const projectTextOmission = (text: string) => {
    let newText = '';
    const textLength = 50;

    if (text.length > textLength) {
      newText = text?.substring(0, textLength) + '…';
      return newText;
    }
    return text;
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-[1110px]">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                    <div className="flex flex-col items-start gap-1 self-stretch p-6">
                      <div className="flex items-center gap-2 self-stretch">
                        <div className="flex flex-1 items-center justify-between self-stretch">
                          <div className="flex items-center gap-4">
                            <div className="flex flex-col items-start gap-1">
                              <div className="flex items-center gap-[10px]">
                                <span className="whitespace-nowrap text-xs font-normal leading-none text-gray-700">
                                  {projectTextOmission(props.project)}
                                </span>
                              </div>
                              <div className="flex items-center gap-2">
                                <span className="whitespace-nowrap text-sm font-bold leading-6 text-gray-700">
                                  {projectTextOmission(props.projectName)}
                                </span>
                                <Badge color="red" size="sm" round="custom" className="rounded-md p-2">
                                  {props.projectType.toUpperCase()}
                                </Badge>
                              </div>
                            </div>
                          </div>
                          <span className="text-sm font-normal leading-5 text-gray-500">実施期間：{props.span}</span>
                        </div>
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="relative rounded-md bg-white text-gray-400 hover:text-gray-500"
                            onClick={() => props.setOpen(false)}
                          >
                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-center self-stretch px-4 py-0">
                      <div className="flex h-[38px] items-center justify-center self-stretch">
                        <div className="flex h-[38px] w-[1041px] items-center gap-8 border-b-[1px]  border-gray-200 pl-0">
                          {selectTab().map((tab: TabProps) => {
                            return (
                              <button
                                key={tab.key}
                                className={clsx('flex flex-col items-center justify-center')}
                                disabled={tab.key == 'summary' ? false : summary?.summary === undefined ? true : false}
                                onClick={() => {
                                  handleTabSelct(tab.key);
                                }}
                              >
                                <div
                                  className={clsx(
                                    'flex items-center justify-center gap-2  px-1 pb-4 pt-0',
                                    tabKey == tab.key ? 'border-b-2 border-[#007CC2] text-[#007CC2]' : 'text-gray-500'
                                  )}
                                >
                                  {tabKey == tab.key ? tab.activeIcon : tab.icon}
                                  {tab.name}
                                </div>
                              </button>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                    <div id="main-frame" className="flex items-start gap-2 self-stretch p-6">
                      {displanContent()}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
