import React, { Fragment } from 'react';

import { UsageHistory, BillingUsageHistoryRow } from '@/api/billing';
export interface BillingProps {
  billngHisoties: Array<UsageHistory>;
}

export default function BillingTable(props: BillingProps): React.ReactElement {
  return (
    <div className="max-h-[473px] w-[1133px] overflow-y-auto rounded-lg border border-gray-200 bg-white">
      <table className="w-full">
        <tbody>
          {props.billngHisoties.map((billngHisoty: UsageHistory, index: number) => (
            <Fragment key={index}>
              <tr className="h-[56px] bg-gray-100 px-4 py-6">
                <th
                  colSpan={2}
                  scope="colgroup"
                  className="px-4 py-6 text-left text-base font-semibold leading-6 text-gray-900 sm:pl-3"
                >
                  {billngHisoty.month}
                </th>
                <th
                  colSpan={1}
                  scope="colgroup"
                  className="py-2 pl-4 pr-3 text-left text-base font-bold leading-6 text-gray-900 sm:pl-3"
                >
                  合計 ¥{billngHisoty.amount.toLocaleString()}
                </th>
              </tr>
              {billngHisoty.rows.map((history: BillingUsageHistoryRow, index: number) => (
                <tr key={index} className="h-[72px] px-4 py-6">
                  <td className="px-4 py-6 text-sm font-normal leading-5 text-gray-500">
                    {history.name === 'LIGHT'
                      ? 'ライトプラン'
                      : history.name === 'BASIC'
                      ? 'ベーシックプラン'
                      : history.name === 'ENTERPRISE'
                      ? 'エンタープライズプラン'
                      : history.name === 'SUSPENDED'
                      ? '停止中'
                      : history.name === 'TRIAL'
                      ? '無料プラン'
                      : history.name}
                  </td>
                  <td className="px-4 py-6 text-sm font-normal leading-5 text-gray-500">
                    {new Date(history.usedAt).getFullYear()}/{new Date(history.usedAt).getMonth() + 1}/
                    {new Date(history.usedAt).getDate()}
                  </td>
                  <td className="px-4 py-6 text-sm font-normal leading-5 text-gray-500">
                    ¥{history.amount.toLocaleString()}
                  </td>
                </tr>
              ))}
            </Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
