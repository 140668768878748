import ReactApexChart from 'react-apexcharts';

interface ApexOptions {
  annotations?: ApexAnnotations;
  chart?: ApexChart;
  colors?: any[];
  dataLabels?: ApexDataLabels;
  fill?: ApexFill;
  forecastDataPoints?: ApexForecastDataPoints;
  grid?: ApexGrid;
  labels?: string[];
  legend?: ApexLegend;
  markers?: ApexMarkers;
  noData?: ApexNoData;
  plotOptions?: ApexPlotOptions;
  responsive?: ApexResponsive[];
  series?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  states?: ApexStates;
  stroke?: ApexStroke;
  subtitle?: ApexTitleSubtitle;
  theme?: ApexTheme;
  title?: ApexTitleSubtitle;
  tooltip?: ApexTooltip;
  xaxis?: ApexXAxis;
  yaxis?: ApexYAxis | ApexYAxis[];
}

type seriesType = {
  name: string;
  data: number[];
};

export interface labelProps {
  yaxisValue: string;
  toolTipYaxisValue: string;
}
export default function BarChart(main_data: seriesType[], categories: string[], colors: string[], label: labelProps) {
  const data = {
    series: main_data,
  };

  const opt: ApexOptions = {
    legend: {
      show: true,
      position: 'bottom', // 凡例をグラフの下に配置
      horizontalAlign: 'right', // 凡例を右寄せに配置
      labels: {
        colors: ['#6B7280'], // 凡例ラベルの色
      },
      markers: {
        fillColors: ['#007CC2', '#80D0FF', '#D1D5DB'],
      },
      itemMargin: {
        horizontal: 15, // 凡例の間隔
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    colors: colors,
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (val: number) => {
          return val + label.toolTipYaxisValue;
        },
      },
    },
    xaxis: {
      categories: categories,
      offsetX: 9, // 軸とグラフの間隔
      labels: {
        style: {
          colors: '#6B7280', // 軸の色
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#6B7280', // 軸の色
        },
        formatter: (ageNum: number) => {
          return ageNum + label.yaxisValue;
        },
      },
    },
    // bar には data の label 値表示
    dataLabels: {
      enabled: false,
    },
    chart: {
      toolbar: {
        show: false,
      },
    },
  };

  return (
    <div>
      <ReactApexChart type="bar" height={300} width={450} series={data.series} options={opt} />
    </div>
  );
}
