import { useEffect } from 'react';

import { GetInfluencerSnsAccountOutput } from '@/api/influencer';
import { ReactComponent as BookmarkIcon } from '@/assets/icons/bx-bookmark.svg';
import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as MaleIcon } from '@/assets/icons/bx-male.svg';
import { ReactComponent as ShareIcon } from '@/assets/icons/bx-share.svg';
import { ReactComponent as ShowIcon } from '@/assets/icons/bx-show.svg';
import { ReactComponent as UserIcon } from '@/assets/icons/bx-user.svg';
import NoImage from '@/assets/images/NoImage.png';
import { Stat } from '@/components/Elements/Stat';
import { formatNumber } from '@/utils/format';

import { PostInfoModalProps, AppealPostsProps, RecentPostsProps } from '../../Instagram/InstagramAll_Interface';
import Modal from '../Modal';

interface modalInfoAllProps {
  appealPosts: AppealPostsProps[];
  recentPosts: RecentPostsProps[];
  postinfoModal: PostInfoModalProps;
  influencerInfo?: GetInfluencerSnsAccountOutput;
}

let applealPostsFlag = false;

export default function ApplicantInfo(props: modalInfoAllProps) {
  const handleOpen = (index: number, kind: string, isDeleteArrow?: boolean) => {
    applealPostsFlag = kind === 'appeal';
    props.postinfoModal.setModalIndex(index);
    props.postinfoModal.setPostType(kind);
    props.postinfoModal.setModalOpen(true);
    if (isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(true);
    } else if (!isDeleteArrow && props.postinfoModal.setDeleteFlag) {
      props.postinfoModal.setDeleteFlag(false);
    }
  };
  let instagram_modal_element_component: JSX.Element[];
  let caption: string;

  if (props.postinfoModal.postType === 'appeal') {
    caption = String(props.appealPosts[props.postinfoModal.modalindex]?.caption);

    instagram_modal_element_component = [
      <Stat
        key={0}
        icon={<ShowIcon width={24} height={24} fill="#FFFFFF" />}
        lable="インプレッション数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.impressions || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.appealPosts[props.postinfoModal.modalindex]?.engagementRate.toString() + '%' || '0%'}
        className="h-[100px] w-[300px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.engagementCounts || 0)}
        className="w-[300px] rounded-lg border border-slate-200"
      />,

      <Stat
        key={3}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="いいね数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.likeCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={4}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="コメント数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.commentCounts || 0)}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={5}
        icon={<ShareIcon width={24} height={24} fill="#FFFFFF" />}
        lable="シェア数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.shareCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={6}
        icon={<BookmarkIcon width={24} height={24} fill="#FFFFFF" />}
        lable="保存数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.savedCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={7}
        icon={<UserIcon width={24} height={24} fill="#FFFFFF" />}
        lable="ユニークアカウント数"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.reachCounts || 0)}
        className="h-[100px] w-[300px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
      />,
      <Stat
        key={8}
        icon={<MaleIcon width={24} height={24} fill="#FFFFFF" />}
        lable="フリークエンシー"
        value={formatNumber(props.appealPosts[props.postinfoModal.modalindex]?.frequency || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
    ];
  } else {
    caption = String(props.recentPosts[props.postinfoModal.modalindex]?.caption);

    instagram_modal_element_component = [
      <Stat
        key={0}
        icon={<ShowIcon width={24} height={24} fill="#FFFFFF" />}
        lable="インプレッション数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.impressions || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={1}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント率"
        value={props.recentPosts[props.postinfoModal.modalindex]?.engagementRate.toString() + '%' || '0%'}
        className="h-[100px] w-[300px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
      />,
      <Stat
        key={2}
        icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
        lable="エンゲージメント数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.engagementCounts || 0)}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={3}
        icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
        lable="いいね数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.likeCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={4}
        icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
        lable="コメント数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.commentCounts || 0)}
        className="w-[300px] rounded-lg border border-slate-200"
      />,
      <Stat
        key={5}
        icon={<ShareIcon width={24} height={24} fill="#FFFFFF" />}
        lable="シェア数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.shareCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={6}
        icon={<BookmarkIcon width={24} height={24} fill="#FFFFFF" />}
        lable="保存数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.savedCounts || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
      <Stat
        key={7}
        icon={<UserIcon width={24} height={24} fill="#FFFFFF" />}
        lable="ユニークアカウント数"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.reachCounts || 0)}
        className="h-[100px] w-[300px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
      />,
      <Stat
        key={8}
        icon={<MaleIcon width={24} height={24} fill="#FFFFFF" />}
        lable="フリークエンシー"
        value={formatNumber(props.recentPosts[props.postinfoModal.modalindex]?.frequency || 0)}
        className="w-[300px] rounded-lg  border border-slate-200"
      />,
    ];
  }

  useEffect(() => {
    console.log('props.appealPosts', props.appealPosts);
    console.log('props.recentPosts', props.recentPosts);
  }, [props.appealPosts, props.recentPosts]);

  return (
    <>
      <div className="pl-[10px]">
        {/* ボタンの css */}
        <div className="text-sm font-semibold leading-5 text-gray-900">アピール投稿</div>
        <div>
          <div className="flex gap-[6px] pt-[16px]">
            {Array.from(Array(props.appealPosts?.length).keys()).map((value1, index1) => {
              return (
                <div role="presentation" onClick={() => handleOpen(index1, 'appeal', true)} key={index1}>
                  {props.appealPosts[index1]?.postImage ? (
                    <img
                      src={props.appealPosts[index1]?.postImage || NoImage}
                      alt=""
                      className="h-[248px] w-[253px] cursor-pointer"
                    />
                  ) : props.appealPosts[index1]?.postVideo ? (
                    <video className="h-[248px] w-[253px] cursor-pointer">
                      <source src={props.appealPosts[index1]?.postVideo || NoImage} type="video/mp4" />
                      <track kind="captions" src="captions.vtt" label="English" />
                    </video>
                  ) : props.appealPosts[index1]?.postCarousel ? (
                    props.appealPosts[index1]?.postCarousel[0].endsWith('.mp4') ? (
                      <video className="h-[248px] w-[253px] cursor-pointer">
                        <source src={props.appealPosts[index1]?.postCarousel[0] || NoImage} type="video/mp4" />
                        <track kind="captions" src="captions.vtt" label="English" />
                      </video>
                    ) : (
                      <img
                        src={props.appealPosts[index1]?.postCarousel[0] || NoImage}
                        alt=""
                        className="h-[248px] w-[253px] cursor-pointer"
                      />
                    )
                  ) : (
                    <img src={NoImage} alt="" className="h-[248px] w-[253px] cursor-pointer" />
                  )}
                </div>
              );
            })}
          </div>

          <div className="pt-[40px]">
            {/* <div className="text-sm font-semibold leading-5 text-gray-900">{`直近の${props.recentPosts.length}投稿`}</div> */}
            <div className="text-sm font-semibold leading-5 text-gray-900">直近の9投稿</div>
            <div>
              <div>
                {new Array(Math.ceil(props.recentPosts?.length / 3)).fill(0).map((_, rowIndex) => (
                  <div key={rowIndex} className="flex gap-[6px] pt-2">
                    {Array.from(Array(3).keys()).map((_, colIndex) => {
                      const index2 = rowIndex;
                      const index3 = colIndex;
                      if (index2 * 3 + index3 < props.recentPosts?.length || index2 * 3 + index3 <= 9) {
                        const postIndex = index2 * 3 + index3;
                        const post = props.recentPosts?.[postIndex];
                        if (post) {
                          return (
                            <div key={postIndex} role="presentation" onClick={() => handleOpen(postIndex, 'recent')}>
                              {/* <img
                                src={post.postImage || NoImage}
                                alt=""
                                className="h-[248px] w-[253px] cursor-pointer"
                              /> */}
                              {post.postImage ? (
                                <img src={post.postImage} alt="" className="h-[248px] w-[253px] cursor-pointer" />
                              ) : post.postVideo ? (
                                <video className="h-[248px] w-[253px] cursor-pointer">
                                  <source src={post.postVideo || NoImage} type="video/mp4" />
                                  <track kind="captions" src="captions.vtt" label="English" />
                                </video>
                              ) : post.postCarousel ? (
                                post.postCarousel[0].endsWith('.mp4') ? (
                                  <video className="h-[248px] w-[253px] cursor-pointer">
                                    <source src={post.postCarousel[0] || NoImage} type="video/mp4" />
                                    <track kind="captions" src="captions.vtt" label="English" />
                                  </video>
                                ) : (
                                  <img
                                    src={post.postCarousel[0] || NoImage}
                                    alt=""
                                    className="h-[248px] w-[253px] cursor-pointer"
                                  />
                                )
                              ) : (
                                <img src={NoImage} alt="" className="h-[248px] w-[253px] cursor-pointer" />
                              )}
                            </div>
                          );
                        } else {
                          return null;
                        }
                      }
                    })}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {props.postinfoModal.modalOpen ? (
          <div>
            <Modal
              open={props.postinfoModal.modalOpen}
              setOpen={props.postinfoModal.setModalOpen}
              accountInfo={applealPostsFlag ? props.appealPosts : props.recentPosts}
              modalIndex={props.postinfoModal.modalindex}
              setModalIndex={props.postinfoModal.setModalIndex}
              Component_Element={instagram_modal_element_component}
              sns_name="instagram"
              caption={caption}
              deleteFlag={props.postinfoModal.deleteFlag}
            />
          </div>
        ) : null}
      </div>
    </>
  );
}
