import { useState, useEffect } from 'react';

import { STEPS } from '../constants/path';
import { StepInterface } from '../interfaces/project';

export default function StepsNavigation(status: string, steps: Array<StepInterface>) {
  const [value, setValue] = useState<number>(-1);
  useEffect(() => {
    const tmp: StepInterface = steps.filter((s) => {
      return s.status === status;
    })[0];
    if (tmp == undefined) return;
    setValue(tmp.value);
  }, [status]);
  if (status === STEPS.FINISH) {
    return <></>;
  } else {
    return (
      <nav className="flex items-center justify-center" aria-label="Progress">
        <p className="text-sm font-medium">
          Step {steps.findIndex((step) => step.status === status) + 1} of {steps.length}
        </p>
        <ol className="ml-8 flex items-center space-x-5">
          {steps.map((step) => (
            <li key={step.name}>
              {step.status === 'complete' ? (
                <div className="block h-2.5 w-2.5 rounded-full bg-indigo-600 hover:bg-indigo-900">
                  <span className="sr-only">{step.name}</span>
                </div>
              ) : step.status === status ? (
                <div className="relative flex items-center justify-center" aria-current="step">
                  <span className="absolute flex h-5 w-5 p-px" aria-hidden="true">
                    <span className="h-full w-full rounded-full bg-[#C2E9FF]" />
                  </span>
                  <span className="relative block h-2.5 w-2.5 rounded-full bg-[#007CC2]" aria-hidden="true" />
                  <span className="sr-only">{step.name}</span>
                </div>
              ) : step.value < value ? (
                <div className="block h-2.5 w-2.5 rounded-full bg-[#007CC2]">
                  <span className="sr-only">{step.name}</span>
                </div>
              ) : (
                <div className="block h-2.5 w-2.5 rounded-full bg-gray-200">
                  <span className="sr-only">{step.name}</span>
                </div>
              )}
            </li>
          ))}
        </ol>
      </nav>
    );
  }
}
