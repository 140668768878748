import { useAssetDataJson } from '@/hooks/AssetDataJson';

import Ec from './Operation/Buy/Ec';
import Store from './Operation/Buy/Store';
import Events from './Operation/Events';
import Feed from './Operation/Instagram/Feed';
import Reel from './Operation/Instagram/Reel';
import Stories from './Operation/Instagram/Stories';
import Sampling from './Operation/Sampling';
import TikTok from './Operation/TikTok';
import Post from './Operation/Twitter/Post';
import RePost from './Operation/Twitter/RePost';
import WebService from './Operation/WebService';
import Youtube from './Operation/Youtube';
import { SNS_MENUES, SelectType } from './PopupInterfaces';

export default function Loop_Menus(
  selected: SelectType[] | undefined,
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>
) {
  const advertisementOption = useAssetDataJson('/advertisement') ?? [];
  return (
    <>
      {selected &&
        selected.map((value, index) => {
          if (value.name == SNS_MENUES[1]) {
            return (
              <div className="my-[16px]" key={index}>
                {Sampling(selected, index, setSelected, index === 0, index === selected.length - 1)}
              </div>
            );
          } else if (value.name == SNS_MENUES[2]) {
            return (
              <div className="my-[16px]" key={index}>
                {Store(selected, index, setSelected, index === 0, index === selected.length - 1)}
              </div>
            );
          } else if (value.name == SNS_MENUES[3]) {
            return (
              <div className="my-[16px]" key={index}>
                {Ec(selected, index, setSelected, index === 0, index === selected.length - 1)}
              </div>
            );
          } else if (value.name == SNS_MENUES[4]) {
            return (
              <div className="my-[16px]" key={index}>
                {Events(selected, index, setSelected, index === 0, index === selected.length - 1)}
              </div>
            );
          } else if (value.name == SNS_MENUES[5]) {
            return (
              <div className="my-[16px]" key={index}>
                {WebService(selected, index, setSelected, index === 0, index === selected.length - 1)}
              </div>
            );
          } else if (value.name == SNS_MENUES[10]) {
            return (
              <div className="my-[16px]" key={index}>
                {Feed(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          } else if (value.name == SNS_MENUES[11]) {
            return (
              <div className="my-[16px]" key={index}>
                {Stories(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          } else if (value.name == SNS_MENUES[12]) {
            return (
              <div className="my-[16px]" key={index}>
                {Reel(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          } else if (value.name == SNS_MENUES[20]) {
            return (
              <div className="my-[16px]" key={index}>
                {Post(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          } else if (value.name == SNS_MENUES[21]) {
            return (
              <div className="my-[16px]" key={index}>
                {RePost(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          } else if (value.name == SNS_MENUES[30]) {
            return (
              <div className="my-[16px]" key={index}>
                {TikTok(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          } else if (value.name == SNS_MENUES[40]) {
            return (
              <div className="my-[16px]" key={index}>
                {Youtube(selected, index, setSelected, index === 0, index === selected.length - 1, advertisementOption)}
              </div>
            );
          }
        })}
    </>
  );
}
