import * as Sentry from '@sentry/react';
import { useState, createContext, Dispatch, useEffect } from 'react';

import { GetOtherDetailOutput, ProjectSummaryBase } from '@/api/other';
import { ProjectStatus, ProjectType } from '@/api/project';
import SmartPhone from '@/assets/images/SmartPhone.png';
import { Button } from '@/components/Elements';
import Estimate from '@/components/ProjectManagements/Components/Estimate';
import KpiComponent from '@/components/ProjectManagements/Components/KPI';
import { DetailImages as DetailImagesModal } from '@/components/ProjectManagements/Modals/DetailImages';
import { FinalAgreeModal } from '@/components/ProjectManagements/Modals/FinalAgreeModal';
import { ProjectOverview, ProjectOverviewProps } from '@/components/ProjectManagements/Modals/ProjectOverview';
import {
  ProjectRecruitingState,
  ProjectRecruitingTitle,
} from '@/components/ProjectManagements/Modals/ProjectRecruitingTitle';
import { PublicAttention, DetailSettings } from '@/components/ProjectManagements/Modals/PublicAttention';
import { PROJECT_TYPES } from '@/config';
import { getOtherDetail, putOtherMedia, putOtherNotice, putOtherTitle } from '@/hooks/Other';
import { PutOtherSummary } from '@/hooks/OtherProject';
import { insertPostApplicationInfo } from '@/hooks/project';
import { ValidationMessages } from '@/utils/message';

import ErrorImage from '../../assets/samples/bx-error-circle.svg';
import { projectDetail } from '../../interfaces/otherProjectInterface';

import { DetailImages } from './DetailImages';
import { DetailSetting } from './DetailSetting';
import { OverView } from './OverView';
import { ProjectTitle } from './ProjectTitle';
import { Schedule } from './Schedule';

export const PopupDetail_OTHER = createContext({
  buttonState: {},
  buttonDispatch: {} as Dispatch<any>,
});

const initDetailSetting: DetailSettings = {
  open: false,
  projectType: ProjectType.Other,
  companyName: '',
  isSecret: false,
  notice: [],
};

const initProjectOverview: ProjectOverviewProps = {
  open: false,
  recruitmentPlanCount: 0,
  displayRecruitmentPlanCount: '',
  rewardAmount: 0,
  displayRewardAmount: '',
  tags: [],
  displayTags: [],
  tag: '',
  description: '',
  displayDescription: '',
  projectName: PROJECT_TYPES.OTHER,
  radioBoxValue: '1',
  displaySelectedPrefectures: [],
  selectedPrefectures: [],
  prefecturesRadioBoxValue: 'from_off',
};

const initProjectRecruitingTitle: ProjectRecruitingState = {
  open: false,
  title: '',
  sns: '',
  file: '',
  displayTitle: '',
  tags: [],
  tag: '',
  displayTags: [],
  displayImage: '',
  projectName: PROJECT_TYPES.OTHER,
  titleError: false,
  tagError: false,
  imageError: false,
  validationTitleMessage: '',
  validationTagMessage: '',
  validationImageMessage: '',
};

export default function Recruitment(props: projectDetail) {
  const { projectId } = props;

  // 最終同意モーダル
  const [finishAgreeModalIsOpen, setFinishAgreeModalIsOpen] = useState(false);
  // 申請ボタン表示用のステート
  const [isRequesting, setIsRequesting] = useState(false);
  const [detailSetting, setDetailSetting] = useState<DetailSettings>(initDetailSetting);
  const [agreeErrorMessage, setAgreeErrorMessage] = useState('');
  const [projectOverviewProps, setProjectOverviewProps] = useState<ProjectOverviewProps>(initProjectOverview);
  const [projectTitleState, setProjectTitleState] = useState<ProjectRecruitingState>(initProjectRecruitingTitle);
  const [imageModalIsOpen, setImageModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    const projectOverviewProps: ProjectOverviewProps = {
      open: false,
      recruitmentPlanCount: props.otherDetail.summary.adoptionPlan ?? 0,
      displayRecruitmentPlanCount: props.otherDetail.summary.adoptionPlan
        ? `${props.otherDetail.summary.adoptionPlan}`
        : '-',
      rewardAmount: props.otherDetail.summary.rewards ?? 0,
      displayRewardAmount: props.otherDetail.summary.rewards.toString(),
      tags: props.otherDetail.summary.genres ?? [],
      displayTags: props.otherDetail.summary.genres ?? [],
      tag: '',
      description: props.otherDetail.summary.description ?? '',
      displayDescription: props.otherDetail.summary.description ?? '',
      projectName: PROJECT_TYPES.OTHER,
      radioBoxValue: '1',
      displaySelectedPrefectures: [],
      selectedPrefectures: [],
      prefecturesRadioBoxValue: 'from_off',
    };
    setProjectOverviewProps(projectOverviewProps);
  }, [props.otherDetail.summary]);

  useEffect(() => {
    const projectRecruitingTitleProps: ProjectRecruitingState = {
      open: false,
      title: props.otherDetail.title ? props.otherDetail.title : '',
      sns: props.otherDetail.snsType,
      file: props.otherDetail.featuredImage ?? '',
      displayTitle: props.otherDetail.title ? props.otherDetail.title : '',
      tags: props.otherDetail.tags ?? [],
      tag: '',
      displayTags: props.otherDetail.tags ?? [],
      displayImage: props.otherDetail.featuredImage ?? '',
      projectName: PROJECT_TYPES.OTHER,
      titleError: false,
      tagError: false,
      imageError: false,
      validationTitleMessage: '',
      validationTagMessage: '',
      validationImageMessage: '',
    };
    setProjectTitleState(projectRecruitingTitleProps);
  }, [
    props.otherDetail.title,
    props.otherDetail.snsType,
    props.otherDetail.tags,
    props.otherDetail.featuredImage,
    setProjectTitleState,
  ]);

  useEffect(() => {
    setIsRequesting(props.otherDetail.header.projectStatus !== ProjectStatus.Draft);
  }, [props.otherDetail.header.projectStatus]);

  useEffect(() => {
    setDetailSetting(() => {
      return {
        open: false,
        projectType: ProjectType.Other,
        companyName: props.otherDetail.details.companyName,
        isSecret: props.otherDetail.details.isSecret,
        notice: props.otherDetail.details.notice ?? [],
      };
    });
  }, [props.otherDetail.details]);

  const handleProjectTitle = () => {
    setProjectTitleState((prev) => {
      return { ...prev, open: true };
    });
  };

  const handleDetailImages = () => {
    setImageModalIsOpen(true);
  };

  const handleOverView = () => {
    setProjectOverviewProps((prev) => ({ ...prev, open: true }));
  };

  const handleDetailSetting = () => {
    setDetailSetting((prev) => ({ ...prev, open: true }));
  };

  const PutOtherProjectNotice = (projectId: string) => async (isPublic: boolean, notice: string[]) => {
    try {
      const response = await putOtherNotice(projectId, isPublic, notice);
      props.setOtherDetail((val) => {
        return { ...val, details: response.details };
      });
    } catch (error: any) {
      Sentry.captureException(error);
      // src/utils/axiosInstance.ts 内のIntercepterでエラー処理をしているのでここでは何もしない
      return;
    }
  };

  // 案件概要の更新
  const PutOtherProjectOverview = (projectId: string) => async () => {
    const putSpiritSummaryInput: ProjectSummaryBase = {
      adoptionPlan: Number(projectOverviewProps.recruitmentPlanCount),
      rewardsType: null,
      rewards: Number(projectOverviewProps.rewardAmount),
      genres: projectOverviewProps.tags.length == 0 ? null : projectOverviewProps.tags,
      description: projectOverviewProps.description,
    };

    const response = await PutOtherSummary(projectId, putSpiritSummaryInput);
    props.setOtherDetail((prev) => ({
      ...prev,
      summary: response.summary,
    }));
  };

  // 詳細画像更新
  const PutOtherMedia = (projectId: string) => async (uploads?: File[], deleteIds?: string[]) => {
    const response: GetOtherDetailOutput = await putOtherMedia(projectId, uploads, deleteIds);
    props.setOtherDetail((val) => {
      return { ...val, detailImages: response.detailImages };
    });
  };

  const PutOtherTitleUpdate = (projectId: string) => async (title: string, tags: string[], featuredImage?: File) => {
    const response: GetOtherDetailOutput = await putOtherTitle(projectId, title, tags, featuredImage);
    props.setOtherDetail((val) => {
      return { ...val, title: response.title, featuredImage: response.featuredImage, tags: response.tags };
    });
  };

  const checkParams = (): boolean => {
    return !(
      // 案件募集タイトル
      (
        projectTitleState.displayTitle.length > 0 &&
        projectTitleState.displayTags !== undefined &&
        projectTitleState.displayTags.length > 0 &&
        projectTitleState.displayImage !== undefined &&
        projectTitleState.displayImage.length > 0 &&
        // 詳細画像
        props.otherDetail.detailImages.length > 0 &&
        // 案件概要
        projectOverviewProps.displayRecruitmentPlanCount.length > 0 && // 採用予定人数
        projectOverviewProps.displayRewardAmount.length > 0 && // インフルエンサーへの報酬
        projectOverviewProps.displayDescription.length > 0
      )
    );
  };

  const handleAgreeClick = () => {
    if (props.otherDetail.summary.rewards < props.otherDetail.estimate.calculateReward.minimum) {
      setAgreeErrorMessage(
        ValidationMessages.moreThanTargetMessage(
          'インフルエンサー報酬額',
          props.otherDetail.estimate.calculateReward.minimum
        )
      );
      return;
    }
    setAgreeErrorMessage('');
    setFinishAgreeModalIsOpen(true);
  };

  const finalAgree = async (): Promise<void> => {
    await insertPostApplicationInfo(projectId);
    setFinishAgreeModalIsOpen(false);
    setIsRequesting(true);

    // 取得したprojectDetailSpiritのprojectStatusを更新
    const response: GetOtherDetailOutput = await getOtherDetail(projectId);
    props.setOtherDetail(response);
  };

  return (
    <div>
      <div className="pb-5 pl-12">
        <div className="flex w-[1179px] items-center justify-between pb-4 pr-14">
          <span className="text-lg font-medium leading-6 text-gray-900">募集内容詳細</span>
          <div className="pr-16">
            {/* TODO バックエンド作成時はすべて、constに移行 */}
            {!isRequesting && (
              <div>
                <Button
                  variant="primary"
                  size="sm"
                  style={{ margin: '10px' }}
                  disabled={checkParams()}
                  onClick={handleAgreeClick}
                >
                  この内容で申請する
                </Button>
                {!!agreeErrorMessage && <p className="text-base text-red-400">{agreeErrorMessage}</p>}
              </div>
            )}

            {props.otherDetail.header.projectStatus == ProjectStatus.Review && (
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="primary" size="sm" style={{ margin: '10px' }} disabled={true}>
                  申請中
                </Button>
              </div>
            )}
          </div>
        </div>
        <span className="block w-[1050px] border-b" style={{ borderColor: '#E5E7EB' }}></span>
      </div>
      {props.otherDetail.header.projectStatus == ProjectStatus.Draft ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="mr-[12px] h-[20px] w-[20px]">
            <img src={ErrorImage} alt="" />
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">
              募集内容を確認し、申請をしてください。
            </p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              申請されましたら、LIDDELL担当者が再確認の上、募集に入ります。
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      {/* Content */}
      <div className="flex">
        <div className="flex-row pl-[40px]">
          {/* 案件タイトル */}
          <ProjectTitle
            isEdit={!isRequesting}
            image={props.otherDetail.featuredImage ?? ''}
            title={props.otherDetail.title ?? ''}
            tags={props.otherDetail.tags ?? []}
            editFunc={handleProjectTitle}
          />
          {/* 詳細画像 */}
          <DetailImages
            isEdit={!isRequesting}
            images={
              props.otherDetail.detailImages.length ? props.otherDetail.detailImages.map((val) => val.objectKey) : []
            }
            editFunc={handleDetailImages}
          />
          {/* 案件概要 */}
          <OverView isEdit={!isRequesting} summary={props.otherDetail.summary} editFunc={handleOverView} />
          {/* 詳細設定 */}
          <DetailSetting isEdit={!isRequesting} detail={props.otherDetail.details} editFunc={handleDetailSetting} />
          {/* スケジュール */}
          <Schedule isEdit={!isRequesting} scheduleList={props.otherDetail.schedules} />
          {/* KPI */}
          {KpiComponent(props.otherDetail.kpi.items.map((val) => ({ ...val, isFontBold: true })))}
          {/* お見積もり */}
          {Estimate(props.otherDetail.estimate)}
        </div>
        {/* インフルエンサー画面プレビュー */}
        <div className="h-[689.76px] w-[286.25px]">
          <div className="h-[28+24px] w-[240+40px] pl-[40px] pt-[24px] text-base font-semibold leading-7 text-gray-500">
            インフルエンサー画面プレビュー
          </div>
          <div>
            <img className="h-[597.76px] w-[326.25px] pl-[40px] pt-[16px]" src={SmartPhone} alt="" />
          </div>
        </div>
      </div>

      <ProjectRecruitingTitle
        projectRecruitngState={projectTitleState}
        setProjectRecruitingState={setProjectTitleState}
        PutTitle={PutOtherTitleUpdate(projectId)}
      ></ProjectRecruitingTitle>
      <DetailImagesModal
        projectType={ProjectType.Other}
        isOpen={imageModalIsOpen}
        setIsOpen={setImageModalIsOpen}
        detailImages={props.otherDetail.detailImages}
        PutPostMedia={PutOtherMedia(projectId)}
      ></DetailImagesModal>
      <ProjectOverview
        projectOverviewProps={projectOverviewProps}
        setProjectOverviewProps={setProjectOverviewProps}
        PutProjectOverview={PutOtherProjectOverview(projectId)}
        projectId={projectId}
      ></ProjectOverview>
      <PublicAttention
        detailSettings={detailSetting}
        setDetailSettings={setDetailSetting}
        PutProjectNotice={PutOtherProjectNotice(projectId)}
      />
      <FinalAgreeModal
        open={finishAgreeModalIsOpen}
        onClose={() => setFinishAgreeModalIsOpen(false)}
        onClickAgree={() => finalAgree()}
      />
    </div>
  );
}
