import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Fragment } from 'react';

import { EmeraldPostPlan, PutEmeraldPostPlanInput, PutEmeraldPostPlanInputPlanIdEnum } from '@/api/emeraldPost';

import numberFormatComma from '../../uilts/NumberFormatComma';

interface Props {
  open: boolean;
  onClose: () => void;
  plans: EmeraldPostPlan[];
  selectPlanId: string | null;
  PutPostPlanUpdate: (request: PutEmeraldPostPlanInput) => Promise<void>;
}

export const PricingSettings = (props: Props) => {
  const isRequestPlan = (planId: string): planId is PutEmeraldPostPlanInputPlanIdEnum => {
    return (
      planId === PutEmeraldPostPlanInputPlanIdEnum.Plan001 ||
      planId === PutEmeraldPostPlanInputPlanIdEnum.Plan002 ||
      planId === PutEmeraldPostPlanInputPlanIdEnum.Plan003 ||
      planId === PutEmeraldPostPlanInputPlanIdEnum.Plan004 ||
      planId === PutEmeraldPostPlanInputPlanIdEnum.Plan005
    );
  };
  const onSaveButton = async (planId: string) => {
    if (isRequestPlan(planId)) {
      await props.PutPostPlanUpdate({ planId });
      props.onClose();
    } else {
      alert('プランが選択されていません');
      return;
    }
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.onClose();
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[499px] w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    // 「保存する」ボタンがないのでキャンセルボタンで保存処理を行う
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>

                <div className="h-auto w-[696px]">
                  {/* 料金設定content */}
                  <div className="mb-[32px] h-auto w-[696px]">
                    <p className="mb-[8px] h-[24px] w-[696px] text-[24px] font-bold leading-6 text-gray-900">
                      配信プラン設定
                    </p>
                    <p className="w-[656px] text-[14px] font-normal leading-6 text-[#000000]">
                      ご希望の配信プランを選択してください。
                    </p>
                  </div>
                  {/* `エントリー */}
                  {props.plans.map((plan, index: number) => {
                    return (
                      <div key={index} className="mb-[10px] flex h-[64px] w-[696px]">
                        <p className="flex h-[64px] w-[163px] items-center justify-center bg-gray-200 text-[16px] font-semibold leading-4">
                          {plan.name}
                        </p>
                        <div className="h-[64px] w-[133.25px] bg-gray-50 px-[15px] py-[10px]">
                          <p className="h-[16px] w-full text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                            金額
                          </p>
                          <p className="flex h-[28px] w-full items-center justify-center text-[16px] font-normal leading-4 text-gray-700">
                            {`¥${numberFormatComma(plan.price)}`}
                          </p>
                        </div>
                        <div className="h-[64px] w-[133.25px] bg-gray-50 px-[15px] py-[10px]">
                          <p className="h-[16px] w-full text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                            ハート数
                          </p>
                          <p className="flex h-[28px] w-full items-center text-[16px] font-medium leading-4 text-gray-700">
                            {'♥'.repeat(plan.heartCounts)}
                          </p>
                        </div>
                        <div className="h-[64px] w-[133.25px] bg-gray-50 px-[15px] py-[10px]">
                          <p className="h-[16px] w-full text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
                            予測リーチ
                          </p>
                          <p className="flex h-[28px] w-full items-center justify-end text-[16px] font-normal leading-4 text-gray-700">
                            {`${numberFormatComma(plan.minPredictReach)}~`}
                          </p>
                        </div>
                        <div className="h-[64px] w-[133.25px] bg-gray-50 px-[15px] py-[14px]">
                          <button
                            className={`flex h-[36px] w-full items-center justify-center rounded-md text-[16px] font-normal leading-4 ${
                              plan.id === props.selectPlanId
                                ? 'border-[1px] border-[#00A2FF] bg-[#007CC2] text-[#FFFFFF]'
                                : 'border-[1px] border-[#007CC2] text-[#007CC2]'
                            }`}
                            onClick={() => onSaveButton(plan.id)}
                          >
                            {plan.id === props.selectPlanId ? '選択中' : '選択'}
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
