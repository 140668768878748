import 'chart.js/auto';

import React from 'react';

// import { returnBudgetErrorMessage } from '../../../utils/inputMultiValidationCheck';
import { STEPS } from '../constants/path';
import { FormData } from '../interfaces/project';
// import { StepTag } from '../routes/Project';
import { isMeasure } from '../utils/logic';

import Budget from './Forms/Budget';
import Consideration from './Forms/Consideration';
import Goal from './Forms/Goal';
import Important from './Forms/Important';
import Sns from './Forms/Sns';
import SpecificImage from './Forms/SpecificImage';
import { PageTitle } from './Header';
export interface FormProps {
  step: string;
  payload: FormData;
  setPayload: React.Dispatch<React.SetStateAction<FormData>>;
}

export default function ProjectForm(props: FormProps): React.ReactElement {
  const selectInputForm = () => {
    if (props.step === STEPS.GOAL) {
      return <>{Goal({ payload: props.payload, setPayload: props.setPayload })}</>;
    } else if (props.step === STEPS.IMAGE) {
      return <>{SpecificImage({ payload: props.payload, setPayload: props.setPayload })}</>;
    } else if (props.step === STEPS.IMPORTANT) {
      return <>{Important({ payload: props.payload, setPayload: props.setPayload })}</>;
    } else if (props.step === STEPS.BUDGET) {
      return <>{Budget({ payload: props.payload, setPayload: props.setPayload })}</>;
    } else if (props.step === STEPS.PERIOD) {
      return <>{Consideration({ payload: props.payload, setPayload: props.setPayload })}</>;
    } else if (props.step === STEPS.SNS) {
      return <>{Sns({ payload: props.payload, setPayload: props.setPayload })}</>;
    }
  };

  return (
    <>
      <div id="input_frame">
        {PageTitle(props.step, isMeasure(props.payload) ? true : false)}
        {selectInputForm()}
      </div>
    </>
  );
}
