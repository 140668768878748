import * as Sentry from '@sentry/react';
import { useState, useEffect } from 'react';

import { GetBillingInformationOutput, UsageHistory } from '@/api/billing';
import { Button } from '@/components/Elements/Button';
import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
import { fetchBillingInfo } from '@/hooks/BillingInfo';

import BillingInfoModal from '../components/BillingInfoModal';
import BillingTable from '../components/BillingTable';

export const BillingManagement = () => {
  const [billingInfo, setBillingInfo] = useState<GetBillingInformationOutput | null>(null);
  const [companyName, setCompanyName] = useState<string>('');
  const [managerName, setManagerName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [billingHisotries, setBillingHistory] = useState<Array<UsageHistory>>();
  const [editBillingStatus, setEditBillingStatus] = useState<string>('');

  // modal
  const [open, setOpen] = useState<boolean>(false);
  const [modalCompanyName, setModalCompanyName] = useState<string>('');
  const [modalManagerName, setModalManagerName] = useState<string>('');
  const [modalEmail, setModalEmail] = useState<string>('');
  const [isRegistered, setIsRegistered] = useState<boolean>(false);

  const onSave = () => {
    setOpen(false);
    setCompanyName(modalCompanyName);
    setManagerName(modalManagerName);
    setEmail(modalEmail);
  };
  const onOpen = () => {
    setModalCompanyName(companyName);
    setModalManagerName(managerName);
    setModalEmail(email);

    setOpen(true);
  };

  // 請求先情報取得APIリクエスト
  useEffect(() => {
    const getBillingInfo = async () => {
      try {
        const data: GetBillingInformationOutput = await fetchBillingInfo();
        setBillingInfo(data);
        setIsRegistered(data?.billingTeamInfo.companyName ? true : false);
      } catch (error) {
        Sentry.captureException(error);
        console.error('API call failed:', error);
      }
    };

    getBillingInfo();
  }, []);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setCompanyName(billingInfo?.billingTeamInfo.companyName || '未登録');
      setManagerName(billingInfo?.billingTeamInfo.name || '未登録');
      setEmail(billingInfo?.billingTeamInfo.email || '未登録');
      if (billingInfo?.usageHistories && billingInfo?.usageHistories.length > 0) {
        setBillingHistory(billingInfo?.usageHistories);
      }
      setEditBillingStatus(billingInfo ? '請求先情報の変更' : '請求先情報の登録');
    }, 250);

    return () => clearTimeout(timerId);
  }, [billingInfo]);
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.basicInfo,
        secondSideBarselected: secondSidebarSelect.billing,
      }}
    >
      <div className="bg-gray-50">
        <div className="h-[76px] w-[1181px] border-b border-b-gray-200">
          <div className="px-[11px] py-4">
            <span className="text-base font-bold leading-7 text-gray-700">請求管理</span>
          </div>
        </div>
        <div className="w-[1181px]  pl-3 pt-6">
          <div className="flex flex-row justify-between p-6">
            <div>
              <div className="h-[128px]">
                <div className="flex flex-row">
                  <div>
                    <span className="text-2xl font-bold leading-7 text-gray-900">請求先情報</span>
                  </div>
                  <div>
                    <Button variant="text" size="sm" onClick={() => onOpen()}>
                      {editBillingStatus}
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="flex flex-row gap-14">
                    <div className="flex flex-col gap-2">
                      <span className="text-sm font-semibold leading-5 text-gray-700">会社名</span>
                      <span className="text-sm font-semibold leading-5 text-gray-700">担当者名</span>
                      <span className="text-sm font-semibold leading-5 text-gray-700">メールアドレス</span>
                    </div>
                    <div className="flex flex-col gap-2">
                      <span className="text-sm font-normal leading-5 text-gray-900">{companyName}</span>
                      <span className="text-sm font-normal leading-5 text-gray-900">{managerName}</span>
                      <span className="text-sm font-normal leading-5 text-gray-900">{email}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="pt-7">
              <div>
                <span className="text-sm font-semibold leading-5 text-gray-900">請求について</span>
              </div>
              <div>
                <div>
                  <span className="text-xs font-normal leading-5 text-gray-900">
                    ・毎月末に左記の請求先メールアドレスに、弊社から請求書を発行させていただきます。
                  </span>
                </div>
                <div>
                  <span className="text-xs font-normal leading-5 text-gray-900">
                    ・下記のサービス利用履歴での金額は、システム内で算出した見積り額となります。
                  </span>
                </div>
                <div>
                  <span className="text-xs font-normal leading-5 text-gray-900">
                    ・プランの変更・人数変更があった場合は、請求前に別途担当者から再見積りいたします。
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div>
              <span className="text-2xl font-bold leading-7">サービス利用履歴</span>
            </div>
            {billingHisotries ? (
              <>{BillingTable({ billngHisoties: billingHisotries })}</>
            ) : (
              <div className="h-[440px] w-[1133px] rounded-lg border border-gray-200 bg-white">
                <div className="flex h-full items-center justify-center">
                  <span className="text-base font-normal leading-6 text-gray-500">サービス利用履歴はありません</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <BillingInfoModal
        open={open}
        setOpen={setOpen}
        companyName={modalCompanyName}
        setCompanyName={setModalCompanyName}
        managerName={modalManagerName}
        setMangerName={setModalManagerName}
        email={modalEmail}
        setEmail={setModalEmail}
        onSave={onSave}
        isRegistered={isRegistered}
        setIsRegistered={setIsRegistered}
      />
    </MainLayout>
  );
};
