import { ReactComponent as CoinIcon } from '@/assets/icons/bx-coin.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as PencilImage } from '@/assets/icons/bx-pencil.svg';
import { Stat } from '@/components/Elements/Stat';

import LeadingContent from './LeadingContent';

export interface AnalysisInstagaramProps {
  id: string;
  isAggregating: boolean;
}

export default function AnalysisInstagram(props: AnalysisInstagaramProps) {
  return (
    <div className="flex flex-1 flex-col items-start gap-10">
      <div id="kpi-section" className="flex flex-col items-start gap-3 self-stretch">
        <div className="flex items-center gap-[16px] self-stretch">
          <span>計測値</span>
          <span className="text-sm">※実施期間終了後、1週間程度は数値が変動します。</span>
        </div>
        <div className="flex items-start gap-4 self-stretch">
          <div className="flex flex-col items-start gap-2">
            <LeadingContent
              icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
              label="総フォロワー数"
              value={props.isAggregating ? '集計中' : '32,257'}
            />
            <div className="flex w-[362px] items-start gap-[10px] rounded-lg bg-gray-100 p-4">
              {props.isAggregating ? (
                <span className="flex-1 text-sm font-medium leading-5 text-gray-700">担当者の入力待ち</span>
              ) : (
                <span className="flex-1 whitespace-pre-wrap text-sm font-medium leading-5 text-gray-700">
                  {
                    '10月～11月配信にてGA計上でのダイレクトCVは3件となった。\nしかしながらビュースルー広告でのCVは多く創出されており、3ヶ月でCV数は4,078件CPAが平均¥104での数値で着地した事から広告配信自体は失敗ではなかったと考える。'
                  }
                </span>
              )}
            </div>
          </div>
          <div className="flex flex-col items-start gap-2">
            <LeadingContent
              icon={<PencilImage fill="#FFFFFF" />}
              label="投稿数"
              value={props.isAggregating ? '集計中' : '15'}
              type="post"
              postValue={{
                X: 5,
                instagramStories: 5,
                instagram: 5,
              }}
            />
            <div className="flex w-[362px] items-start gap-[10px] rounded-lg bg-gray-100 p-4">
              {props.isAggregating ? (
                <span className="flex-1 text-sm font-medium leading-5 text-gray-700">担当者の入力待ち</span>
              ) : (
                <span className="flex-1 whitespace-pre-wrap text-sm font-medium leading-5 text-gray-700">
                  {
                    '10月～11月配信にてGA計上でのダイレクトCVは3件となった。\nしかしながらビュースルー広告でのCVは多く創出されており、3ヶ月でCV数は4,078件CPAが平均¥104での数値で着地した事から広告配信自体は失敗ではなかったと考える。'
                  }
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div id="insight-section" className="flex w-[740px] flex-col items-start gap-4">
        <div className="flex flex-col items-start gap-2 self-stretch">
          <div className="flex flex-col items-start gap-1">
            <span>インサイト</span>
          </div>
          <div className="flex items-center gap-4">
            <span className="text-sm font-normal leading-5 text-gray-500">
              ※案件サマリーのインサイトの項目は、全投稿の共通項目のみ表示されます。ユニークアカウント数は、全投稿の合算のため本来のユニーク数ではありません。
            </span>
          </div>
        </div>
        <div className="flex w-[740px] flex-col items-start gap-10">
          <div id="insight" className="flex flex-col items-start gap-2">
            <div className="flex w-[740px] items-center gap-2">
              <Stat
                icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
                lable="平均フォロワー数"
                value={props.isAggregating ? '集計中' : '2,257'}
                className="w-[366px]"
              />
              <Stat
                icon={<CoinIcon width={24} height={24} fill="#FFFFFF" />}
                lable="フォロワー単価"
                value={props.isAggregating ? '集計中' : '¥ 7'}
                className="w-[366px]"
              />
            </div>
            <div className="flex w-[740px] items-center gap-2">
              <Stat
                icon={<CoinIcon width={24} height={24} fill="#FFFFFF" />}
                lable="広告換算額"
                value={props.isAggregating ? '集計中' : '￥2,257'}
                className="w-[366px]"
              />
            </div>
          </div>
        </div>
        <div className="flex w-[740px] flex-col items-start gap-10">
          <div className="flex flex-col items-start gap-6 self-stretch">
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex h-6 items-center justify-between self-stretch">
                <span className="text-sm font-semibold leading-5 text-gray-900">総括</span>
              </div>
              <div className="flex items-start gap-[10px] self-stretch rounded-lg bg-gray-100 p-4">
                {props.isAggregating ? (
                  <span className="text-sm font-medium leading-5 text-gray-700">担当者の入力待ち</span>
                ) : (
                  <span className="whitespace-pre-wrap text-sm font-medium leading-5 text-gray-700">
                    {
                      '10月～11月配信にてGA計上でのダイレクトCVは3件となった。\nしかしながらビュースルー広告でのCVは多く創出されており、3ヶ月でCV数は4,078件CPAが平均¥104での数値で着地した事から広告配信自体は失敗ではなかったと考える。\n\n気づきとしては以下の3点が挙げられる。\n・SNS上でのanySiSとの親和性が高いインフルエンサー、クリエイティブのジャンルが明確になってきた。\n・顔出し無しのクリエイティブが良化傾向にある。\n・CV目的/トラフィック目的、共に【興味関心】のターゲティングの反応が良い。'
                    }
                  </span>
                )}
              </div>
            </div>
            <div className="flex flex-col items-start gap-2 self-stretch">
              <div className="flex h-6 items-center justify-between self-stretch">
                <span className="text-sm font-semibold leading-5 text-gray-900">次回のご提案</span>
              </div>
              <div className="flex items-start gap-[10px] self-stretch rounded-lg bg-gray-100 p-4">
                {props.isAggregating ? (
                  <span className="text-sm font-medium leading-5 text-gray-700">担当者の入力待ち</span>
                ) : (
                  <span className="whitespace-pre-wrap text-sm font-medium leading-5 text-gray-700">
                    {
                      'ダイレクトCVを目的に広告配信を行なってきたが、GA計測でダイレクトのCVは測りつつ、サイト流入を促進させクリック数に振り切るアクションをご提案。\n今後はSNSでの広告との役割を変更し、マーケティングのファネルに沿った運用を行なっていきたい。\n体験コンテンツ広告 = ブランドの親和性の高いインフルエンサー/クリエイティブでサイト流入を増。\n上記広告から、リターゲティングにて潜在層への刈り取りを実施。\n検討事項としては、クリエイティブをECのものだけではなくインフルエンサーのクリエイティブの二次利用も検討していきたい。'
                    }
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
