import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  DefaultApi,
  GetOtherApplicantsOrderEnum,
  GetOtherApplicantsRowsEnum,
  GetOtherApplicantsStatusEnum,
  GetOtherDetailOutput,
  GetOtherListListTypeEnum,
  GetOtherListOrderEnum,
  GetOtherListOutput,
  GetOtherListRowsEnum,
  GetOtherListStatusEnum,
  OtherProjectSchedule,
  PutOtherNoticeInput,
} from '@/api/other';
import { axiosInstance } from '@/utils/axiosInstance';

export const GetOtherApplicantsOrderByEnum = {
  Follow: 'follow',
  Idx: 'idx',
  Id: 'id',
  Eval: 'eval',
  Enthusiasm: 'enthusiasm',
  OpeComment: 'opeComment',
  Selection: 'selection',
  IsReserved: 'isReserved',
  FinalConfirm: 'finalConfirm',
  Followers: 'followers',
  Following: 'following',
  Impressions: 'impressions',
  EgRate: 'egRate',
  Engagements: 'engagements',
  Good: 'good',
  Comments: 'comments',
  Share: 'share',
  Preserves: 'preserves',
  Reach: 'reach',
  ReachRate: 'reachRate',
  Frequency: 'frequency',
  MediaCount: 'mediaCount',
  Reply: 'reply',
  Repost: 'repost',
  Views: 'views',
  ViewRate: 'viewRate',
  Like: 'like',
} as const;
export type GetOtherApplicantsOrderByEnum =
  (typeof GetOtherApplicantsOrderByEnum)[keyof typeof GetOtherApplicantsOrderByEnum];

const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * 案件注意事項/公開設定
 * @param projectId
 * @param putSpiritNoticeInput
 * @returns
 * 詳細画像設定API
 */
export const putOtherMedia = async (projectId: string, uploads?: File[], deleteIds?: string[]) => {
  try {
    const response: AxiosResponse<GetOtherDetailOutput> = await api.putOtherMedia(projectId, uploads, deleteIds);
    return response.data as GetOtherDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * Other案件詳細取得API
 */
export const getOtherDetail = async (projectId: string) => {
  try {
    const response: AxiosResponse<GetOtherDetailOutput> = await api.getOtherDetail(projectId);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * Other 案件一覧取得API
 */
export const getOtherSchedule = async (projectId: string) => {
  try {
    const response: AxiosResponse<OtherProjectSchedule[]> = await api.getOtherSchedule(projectId);
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/*
 * Other案件募集タイトル作成/編集API
 */
export const putOtherTitle = async (projectId: string, title: string, tags: string[], featuredImage?: File) => {
  try {
    const response: AxiosResponse<GetOtherDetailOutput> = await api.putOtherTitle(
      projectId,
      title,
      tags,
      featuredImage
    );
    return response.data as GetOtherDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

export const getOtherList = async (
  status?: GetOtherListStatusEnum,
  listType?: GetOtherListListTypeEnum,
  rows?: GetOtherListRowsEnum,
  page?: number,
  order?: GetOtherListOrderEnum,
  orderby?: string,
  noCache?: boolean
) => {
  try {
    const response: AxiosResponse<GetOtherListOutput> = await api.getOtherList(
      status,
      listType,
      rows,
      page,
      order,
      orderby,
      noCache
        ? {
            params: {
              date: new Date().toISOString(),
            },
          }
        : undefined
    );
    return response.data as GetOtherListOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 案件注意事項/公開設定API
 */
export const putOtherNotice = async (
  projectId: string,
  isPublic: boolean,
  notice: string[]
): Promise<GetOtherDetailOutput> => {
  try {
    const input: PutOtherNoticeInput = {
      isPublic: isPublic,
      notice: notice,
    };
    const response: AxiosResponse<GetOtherDetailOutput> = await api.putOtherNotice(projectId, input);
    return response.data as GetOtherDetailOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(',')}`);
  }
};

export const getApplicantData = async (
  projectId: string,
  rows: GetOtherApplicantsRowsEnum,
  page: number,
  order: GetOtherApplicantsOrderEnum,
  orderBy?: string,
  selectionStatus?: GetOtherApplicantsStatusEnum
) => {
  try {
    const response = await api.getOtherApplicants(projectId, rows, page, order, orderBy, selectionStatus);
    return response.data;
  } catch (error) {
    console.error('応募者データの取得に失敗しました。', error);
  }
};
