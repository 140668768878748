/* eslint-disable tailwindcss/no-custom-classname */
//import { useState } from 'react';

import { PhoneIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Steps from '../components/Steps';
// import TestBox from '../components/TextBox';
// import TestBox_PhoneIcon from '../components/TextBox_PhoneIcon';

interface State {
  teamName: string;
}

export function AskTeam(): React.ReactElement {
  const [companyName, setCompanyName] = useState('');
  const [companyPhoneNumber, setCompanyPhoneNumber] = useState('');
  const [companyUrl, setCompanyUrl] = useState('');
  const location = useLocation();
  const { teamName } = location.state as State;

  return (
    <>
      <div className="container">
        <div className="flex h-[40px] w-[1440px] justify-center bg-black">
          <p className="h-[29px] w-[82px] pt-1.5 text-xl font-extrabold leading-7 text-white">Influfect</p>
        </div>

        <div className="flex w-[1460px] flex-row justify-center pt-[48px]">
          <div className="order-1">{Steps()}</div>
        </div>

        <div className="flex w-[1460px] justify-center pt-[32px]">
          <p className="text-3xl font-bold leading-9">このチームが所属する企業は？</p>
        </div>

        <div>
          <form className="flex w-[1440px] flex-col items-center justify-center" action="" method="post">
            {/* flex-col でしか上手くいかない */}
            <div className="pt-[48px]">
              <div>
                <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                  企業名
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="企業名"
                    value={companyName}
                    onChange={(e) => {
                      setCompanyName(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-[40px]">
              <div>
                <label htmlFor="text" className="block text-sm font-semibold leading-6 text-gray-900">
                  企業ウェブサイト
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="companyUrl"
                    id="companyUrl"
                    className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="企業ウェブサイト"
                    value={companyUrl}
                    onChange={(e) => {
                      setCompanyUrl(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="pt-[40px]">
              <div>
                <label htmlFor="number" className="block text-sm font-semibold leading-6 text-gray-900">
                  企業電話番号
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <PhoneIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="number"
                    name="companyPhoneNumber"
                    id="companyPhoneNumber"
                    className="block h-[38px] w-[480px] rounded-md border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="企業電話番号"
                    value={companyPhoneNumber}
                    onChange={(e) => {
                      setCompanyPhoneNumber(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            {/* flex-col 入れる */}
          </form>
          <div className="flex w-[1440px] justify-center pt-[48px]">
            <div className="h-[38px] w-[62px] font-medium">
              <Link to="../../make_team">
                <input
                  className="rounded-md border bg-sky-600 px-[17px] py-[9px] text-sm text-white"
                  style={{ background: '#FFFFFF', color: '#374151' }}
                  type="button"
                  value="戻る"
                />
              </Link>
            </div>
            <div className="h-[38px] w-[62px] font-medium last-of-type:ml-[356px]">
              <Link
                to="../../inform_leader"
                state={{
                  companyName: companyName,
                  companyPhoneNumber: companyPhoneNumber,
                  companyUrl: companyUrl,
                  teamName: teamName,
                }}
              >
                <input
                  className="rounded-md border bg-sky-600 px-[17px] py-[9px] text-sm text-white"
                  style={{ background: '#007CC2' }}
                  type="submit"
                  value="次へ"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
