import clsx from 'clsx';
import * as React from 'react';

export type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  inputId: string;
  inputValue: string;
  label: string;
  labelClassName?: string;
  isSelect: boolean;
};

export const Radio = React.forwardRef<HTMLInputElement, RadioProps>(({ className = '', ...props }, ref) => {
  return (
    <>
      <div className="flex flex-row items-center gap-2 pb-4">
        <input type="radio" ref={ref} value={props.inputValue} id={props.inputId} className="hidden" {...props} />
        <label
          htmlFor={props.inputId}
          className={clsx(
            'box-border items-center gap-4 rounded-lg border bg-[#FFFFFF] shadow-sm',
            props.isSelect ? 'border-[#007CC2]' : 'border-gray-300',
            className
          )}
        >
          <div className={clsx('px-7 py-4 text-sm font-medium leading-5', props.labelClassName)}>{props.label}</div>
        </label>
      </div>
    </>
  );
});

Radio.displayName = 'Radio';
