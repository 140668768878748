import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import { NavigateFunction } from 'react-router-dom';

import { teamCheckout, teamJoin } from '@/hooks/TeamInfo';
import { TeamCheckoutRequest } from '@/types/TeamInfo';
import { getImageUrl } from '@/utils/image';
export interface teamProps {
  teams: teamInformation[];
  handleAddTeam: (teamName: string) => void;
}
export interface teamInformation {
  teamId: string;
  image: string;
  teamName: string;
  members: number;
  isBelong: boolean;
  invitation: string;
}

const executeTeamJoin = async (teamId: string, invitation: string) => {
  try {
    // チーム参加APIリクエスト
    await teamJoin(teamId, { invitation: invitation });
  } catch (error) {
    Sentry.captureException(error);
    console.error('API call failed:', error);
  }
};

const executeTeamCheckout = async (teamId: string) => {
  const teamCheckoutRequest: TeamCheckoutRequest = { teamId };
  console.log('teamCheckoutRequest:', teamCheckoutRequest);

  let checkedOuted = false;
  try {
    // チーム切替APIリクエスト
    const data = await teamCheckout(teamCheckoutRequest);
    console.log('teamCheckoutRequest:', data);
    checkedOuted = data.checkedOut;
  } catch (error) {
    Sentry.captureException(error);
    console.error('API call failed:', error);
  }
  return checkedOuted;
};

export function Team(props: teamProps, navigate: NavigateFunction) {
  return (
    <div className="rounded-lg border border-[#E8E8E8]">
      {props.teams.map((team: teamInformation, index: number) => (
        <div
          key={index}
          className={clsx(
            'h-[104px] gap-2   bg-white p-6',
            index == 0 ? 'rounded-t-lg' : '',
            index == props.teams.length - 1 ? 'rounded-b-lg' : '',
            index == props.teams.length - 1 ? '' : 'border-b border-[#E8E8E8]'
          )}
        >
          <div className="flex h-[54px] flex-row items-center gap-4">
            <div className="gap-4">
              <img src={getImageUrl(team.image)} alt="" className="h-14 w-14 rounded-[10px]" />
            </div>
            <div className={clsx('flex flex-col', team.isBelong ? 'w-[992px]' : 'w-[974px]')}>
              <div>
                <span>{team.teamName}</span>
              </div>
              <div>
                <span>メンバー数：{team.members}</span>
              </div>
            </div>
            <div>
              {team.isBelong ? (
                <button
                  type="button"
                  className="rounded-md bg-[#007CC2] px-4 py-2 text-base font-medium leading-6 text-white shadow-sm hover:bg-[#007CC2] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#007CC2]"
                  onClick={() => {
                    // チーム切替APIをリクエストする
                    executeTeamCheckout(team.teamId);
                    navigate('/home', { state: { shouldOpenModal: false } });
                  }}
                >
                  開く
                </button>
              ) : (
                <button
                  type="button"
                  className="rounded-md  bg-white px-3 py-2 text-base font-medium leading-6 text-[#007CC2] shadow-sm ring-1 ring-inset ring-[#007CC2] hover:bg-gray-50"
                  onClick={() => {
                    // チーム参加APIをリクエストする
                    executeTeamJoin(team.teamId, team.invitation);
                    props.handleAddTeam(team.teamName);
                  }}
                >
                  参加する
                </button>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
