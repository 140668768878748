import * as Sentry from '@sentry/react';

import {
  GetAnalysisOtherFeedbackAdoptersOutput,
  GetAnalysisOtherFeedbackAdoptersRowsEnum,
  DefaultApi,
} from '@/api/analysisOther/api';
import { axiosInstance } from '@/utils/axiosInstance';

const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * 案件分析 - フィードバック採用者一覧取得API
 */
export const getAnalysisOtherFeedbackAdopters = async (
  projectId: string,
  rows?: GetAnalysisOtherFeedbackAdoptersRowsEnum,
  page?: number
): Promise<GetAnalysisOtherFeedbackAdoptersOutput> => {
  try {
    const response = await api.getAnalysisOtherFeedbackAdopters(projectId, rows, page);
    const responseData: GetAnalysisOtherFeedbackAdoptersOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
