import * as Sentry from '@sentry/react';
import axios, { AxiosInstance } from 'axios';
import { useMemo, useState } from 'react';

type JsonPath =
  | '/follower_attr/age_gender'
  | '/follower_attr/genres'
  | '/follower_attr/values'
  | '/genres'
  | '/prefectures'
  | '/post'
  | '/advertisement';

export type ResponseDefaultJson = {
  label: string;
  value: string;
}[];

export type ResponseNumberJson = {
  label: string;
  value: number;
}[];

export type ResponseAreaListJson = {
  [key: string]: {
    key: string;
    name: string;
  }[];
};

// Create a base instance of axios
const apiClient: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_ASSETS_URL,
});

export const useAssetDataJson = (path: JsonPath) => {
  const [data, setData] = useState<ResponseDefaultJson | undefined>();
  useMemo(async () => {
    try {
      const response = await apiClient.get<ResponseDefaultJson>(`${path}.json`);
      setData(response.data);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error:', error);
    }
  }, [path]);
  return data;
};

export const useAssetNumberJson = (path: '/age' | '/annualIncome' | '/followers' | '/jobs') => {
  const [data, setData] = useState<ResponseNumberJson | undefined>();
  useMemo(async () => {
    try {
      const response = await apiClient.get<ResponseNumberJson>(`${path}.json`);
      setData(response.data);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error:', error);
    }
  }, [path]);
  return data;
};

export const useAssetAriaListDataJson = (path: '/areaList') => {
  const [data, setData] = useState<ResponseAreaListJson | undefined>();
  useMemo(async () => {
    try {
      const response = await apiClient.get<ResponseAreaListJson>(`${path}.json`);
      setData(response.data);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error:', error);
    }
  }, [path]);
  return data;
};
