import { apiRequest } from '@/api/ApiUtils';
import {
  GetEmeraldPostListOrderByEnum,
  GetEmeraldPostListOrderEnum,
  GetEmeraldPostListRowsEnum,
  GetEmeraldPostListStatusEnum,
  EmeraldPostSummaryOption,
  GetEmeraldPostOutput,
  PutEmeraldPostPlanInput,
  EmeraldPostInformation,
  EmeraldPostSnsDetail,
  EmeraldPostTerm,
  GetEmeraldPostListOutput,
} from '@/api/emeraldPost';
import { FetchEmeraldPostListResponse, FetchEmeraldPostQueryParamater } from '@/types/EmeraldPost';

import { useApiClients } from './useApiClients';

// list取得API
export const GetEmeraldPostList = async (param?: {
  status?: GetEmeraldPostListStatusEnum;
  rows?: GetEmeraldPostListRowsEnum;
  page?: number;
  order?: GetEmeraldPostListOrderEnum;
  orderBy?: GetEmeraldPostListOrderByEnum;
}): Promise<GetEmeraldPostListOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.getEmeraldPostList(
    param?.status,
    param?.rows,
    param?.page,
    param?.order,
    param?.orderBy
  );
  return response.data;
};
export const FetchEmeraldPostListInfo = async (
  query: FetchEmeraldPostQueryParamater
): Promise<FetchEmeraldPostListResponse> => {
  const queryParam = {};
  query.status && Object.assign(queryParam, { status: query.status.toString() });
  Object.assign(queryParam, { rows: query.rows.toString() });
  Object.assign(queryParam, { page: query.page.toString() });
  Object.assign(queryParam, { order: query.order.toString() });
  if (query.noChache) {
    Object.assign(queryParam, { date: new Date().toISOString() });
  }
  query.orderBy && Object.assign(queryParam, { orderBy: query.orderBy.toString() });
  const queryString = new URLSearchParams(queryParam).toString();
  return apiRequest<FetchEmeraldPostListResponse>(`/projects/emerald-post?${queryString}`, {
    method: 'GET',
  });
};

// project取得API
export const GetEmeraldPost = async (projectId: string): Promise<GetEmeraldPostOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.getEmeraldPost(projectId);
  return response.data;
};

// プラン設定API
export const putEmeraldPostPlan = (projectId: string) => async (request: PutEmeraldPostPlanInput) => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.putEmeraldPostPlan(projectId, request);
  return response.data;
};

// お知らせ内容詳細設定API
export const PutEmeraldPostInformation = async (
  projectId: string,
  request: EmeraldPostInformation
): Promise<GetEmeraldPostOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.putEmeraldPostInformation(projectId, request);
  return response.data;
};

// SNS配信詳細設定API
export const PutEmeraldPostSnsDetail = async (
  projectId: string,
  request: EmeraldPostSnsDetail
): Promise<GetEmeraldPostOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.putEmeraldPostSnsDetail(projectId, request);
  return response.data;
};

// 期間設定API
export const PutEmeraldPostTerm = async (
  projectId: string,
  request: EmeraldPostTerm
): Promise<GetEmeraldPostOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.putEmeraldPostTerm(projectId, request);
  return response.data;
};

// 画像設定API
export const PutEmeraldPostMedia = async (
  projectId: string,
  uploads?: File[],
  deleteIds?: string[]
): Promise<GetEmeraldPostOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.putEmeraldPostMedia(projectId, uploads, deleteIds);
  return response.data;
};

// オプション設定API
export const PutEmeraldPostOption = async (
  projectId: string,
  request: EmeraldPostSummaryOption
): Promise<GetEmeraldPostOutput> => {
  const { emeraldPostApi } = useApiClients();
  const response = await emeraldPostApi.putEmeraldPostOption(projectId, request);
  return response.data;
};
