import React from 'react';

import DotsDropdown from './DotsDropdown';
interface PostInfoProps {
  postNo: string;
  postPlanDate: string;
  mediaSrc: string;
  textImage: string;
  type: string;
  status: string;
  comment: number;
}

export const PostInfo: React.FC<PostInfoProps> = ({
  postNo,
  postPlanDate,
  mediaSrc,
  textImage,
  type,
  status,
  comment,
}) => {
  const getTypeColor = (type: string) => {
    switch (type) {
      case 'フィード':
        return 'bg-red-100 text-red-800';
      case 'ストーリー':
        return 'bg-blue-100 text-blue-800';
      case 'リール':
        return 'bg-yellow-100 text-yellow-800';
      default:
        return 'bg-red-100 text-red-800';
    }
  };

  const getStatsuColor = (status: string) => {
    switch (status) {
      case 'コンテンツ制作待ち':
        return 'bg-pink-100 text-pink-800';
      case 'コンテンツ承認待ち':
        return 'bg-blue-100 text-blue-800';
      case '完了':
        return 'bg-green-100 text-green-800';
      default:
        return 'bg-red-100 text-red-800';
    }
  };

  return (
    <>
      <div className="flex bg-white">
        <div className="inline-flex h-[72px] w-[88px] items-center py-[16px] pl-[24px]">
          <div className="text-sm font-normal leading-5 tracking-wider text-gray-500">{postNo}</div>
        </div>
        <div className="inline-flex h-[72px] w-[218px] items-center whitespace-nowrap py-[16px] pl-[24px]">
          <div className="text-sm font-normal leading-5 tracking-wider text-gray-500">{postPlanDate}</div>
        </div>
        <div className="inline-flex h-[72px] w-[141px] items-center py-[16px] pl-[24px]">
          {mediaSrc === '' ? (
            <div className="text-gray-500">-</div>
          ) : (
            <img src={mediaSrc} alt="" className="h-[40px] w-[40px]" />
          )}
        </div>
        <div className="inline-flex h-[72px] w-[234px] items-center py-[16px] pl-[24px]">
          <div className="text-xs font-normal leading-4 tracking-wider text-gray-500">{textImage}</div>
        </div>
        <div className="inline-flex h-[72px] w-[118px] items-center py-[16px] pl-[24px]">
          <div className={`${getTypeColor(type)} rounded px-2 py-0.5 text-xs font-medium leading-4`}>{type}</div>
        </div>
        <div className="inline-flex h-[72px] w-[157px] items-center py-[16px] pl-[24px]">
          <div
            className={`${getStatsuColor(
              status
            )} whitespace-nowrap rounded-xl px-2 py-0.5 text-xs font-medium leading-4`}
          >
            {status}
          </div>
        </div>
        <div className="inline-flex h-[72px] w-[120px] items-center py-[16px] pl-[24px]">
          <div className="text-sm font-normal leading-5 tracking-wider text-gray-500">{comment}</div>
        </div>
        <div className="inline-flex h-[72px] w-[57px] items-center py-[16px] pl-[24px]">
          <DotsDropdown />
        </div>
      </div>
      <div className="h-[1px] w-[1133px] bg-gray-200"></div>
    </>
  );
};
