type BackGroundColorType = 'bg-red-50' | 'bg-amber-50' | 'bg-emerald-50';
type FontColorType = 'text-red-500' | 'text-red-400' | 'text-red-300' | 'text-amber-500' | 'text-emerald-500';
export type QuestionaireColor = {
  backGroundColor: BackGroundColorType;
  fontColor: FontColorType;
};
export type QuestionaireColorMappingType = {
  min: number;
  max: number;
  questionaireColor: QuestionaireColor;
};

const questionaireColorMappingList: QuestionaireColorMappingType[] = [
  {
    min: 0,
    max: 1,
    questionaireColor: {
      backGroundColor: 'bg-red-50',
      fontColor: 'text-red-500',
    },
  },
  {
    min: 2,
    max: 3,
    questionaireColor: {
      backGroundColor: 'bg-red-50',
      fontColor: 'text-red-400',
    },
  },
  {
    min: 4,
    max: 5,
    questionaireColor: {
      backGroundColor: 'bg-red-50',
      fontColor: 'text-red-300',
    },
  },
  {
    min: 6,
    max: 7,
    questionaireColor: {
      backGroundColor: 'bg-amber-50',
      fontColor: 'text-amber-500',
    },
  },
  {
    min: 8,
    max: 10,
    questionaireColor: {
      backGroundColor: 'bg-emerald-50',
      fontColor: 'text-emerald-500',
    },
  },
];

export const getRangeQuestionareColor = (value: number): QuestionaireColor => {
  const defaultColor = {
    backGroundColor: 'bg-red-50',
    fontColor: 'text-red-500',
  } as QuestionaireColor;

  const questionaireColorMapping = questionaireColorMappingList.find((m) => {
    return value >= m.min && value <= m.max;
  });

  return questionaireColorMapping ? questionaireColorMapping.questionaireColor : defaultColor;
};
