import { ContentView } from './ContentView';
import { ImageView } from './ImageView';
import { PostListView } from './PostListView';
import { useUI } from './UIContext';

export const ProjectView = () => {
  const { isProjectDetail, isImage } = useUI();

  return <>{isProjectDetail ? isImage ? <ImageView /> : <ContentView /> : <PostListView />}</>;
};
