export const firstSidebarSelect = {
  home: 'home',
  calendar: 'calendar',
  analysis: 'analysis',
  notification: 'notification',
  basicInfo: 'basicInfo',
  help: 'help',
};

export const secondSidebarSelect = {
  schedule: 'schedule',
  basicInfo: 'basicInfo',
  member: 'member',
  plan: 'plan',
  billing: 'billing',
  personal: 'personal',
  spirit: 'spirit',
  emeraldPost: 'emeraldPost',
  prst: 'prst',
  prstPost: 'prstPost',
  janeJohn: 'janeJohn',
  other: 'other',
};
