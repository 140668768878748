import SortImage from './assets/bx-sort-up.svg';
import { PostInfo } from './PostInfo';

export default function PostList() {
  const list_text_style = 'pl-[24px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500';

  return (
    <>
      <div className="bg-white">
        <div className="flex">
          {/* header 部分 → 施策名 作成日 ... */}
          <div className="flex h-[48px] w-[232px] flex-row items-center">
            <div className={list_text_style}>施策名</div>
            <img className="h-[16px] w-[16px]" alt="" src={SortImage} />
          </div>
          <div className="flex h-[48px] w-[232px] flex-row items-center">
            <div className={list_text_style}>作成日</div>
            <img className="h-[16px] w-[16px]" alt="" src={SortImage} />
          </div>
        </div>
      </div>
      {/* 1列目 */}
      <PostInfo manageName="10月分エメポスMVP施策" createDate="2021年2月14日(金) " />
      {/* 2列目 */}
      <PostInfo manageName="2021年1月投稿分" createDate="2021年2月14日(金) " />
      {/* 3列目 */}
      <PostInfo manageName="2021年12月投稿分" createDate="2021年2月14日(金) " />
      {/* 4列目 */}
      <PostInfo manageName="2021年12月投稿分" createDate="2021年2月14日(金) " />
      {/* 5列目 */}
      <PostInfo manageName="2021年12月投稿分" createDate="2021年2月14日(金) " />
    </>
  );
}
