import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as Notepad } from '@/assets/icons/bx-notepad.svg';
import { ReactComponent as RepostIcon } from '@/assets/icons/bx-repost.svg';
import { ReactComponent as UserPlusIcon } from '@/assets/icons/bx-user-plus.svg';
//import { ReactComponent as TrashIcon } from '@/assets/icons/bx-trash.svg';
import { Stat } from '@/components/Elements/Stat';
import { formatNumber } from '@/utils/format';

import { TwitterInsightProps } from '../../Twitter/TwitterAll_Interface';

interface Props {
  insightPosts: TwitterInsightProps;
  recentPostNum?: number;
}

export default function ApplicantInfo({ insightPosts, recentPostNum }: Props) {
  return (
    <>
      <div className="pb-[52px]">
        <div className="w-[333px] text-sm font-semibold leading-5 text-gray-900">{`直近${recentPostNum}投稿での平均インサイト`}</div>
        <div>
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-4">
              <Stat
                lable={`フォロワー数`}
                value={formatNumber(insightPosts.followers || 0)}
                icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable={`フォロー数`}
                value={formatNumber(insightPosts.followings || 0)}
                icon={<UserPlusIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
          {/* 2 行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-4">
              <Stat
                lable="エンゲージメント率"
                value={(Number(insightPosts.engagementRate) || 0) + '%'}
                icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable="エンゲージメント数"
                value={formatNumber(insightPosts.engagements || 0)}
                icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
          {/* 3行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-4">
              <Stat
                lable="いいね数（平均）"
                value={formatNumber(insightPosts.goodAvg || 0)}
                icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable="返信数（平均）"
                value={formatNumber(insightPosts.replyAvg || 0)}
                icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
          {/* 4行目 */}
          <div className="pt-[8px]">
            <div className="flex h-[100px] w-[780px] justify-between gap-[8px] pt-4">
              <Stat
                lable={`リポスト数（平均）`}
                value={formatNumber(insightPosts.repostAvg || 0)}
                icon={<RepostIcon width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
              <Stat
                lable={`ポスト数`}
                value={formatNumber(insightPosts.postCounts || 0)}
                icon={<Notepad width={24} height={24} fill="#FFFFFF" />}
                className="h-[100px] w-[383px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
