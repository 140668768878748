import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { MemberListAdmin } from '../components/MemberListAdmin';

export function MemberAdmin(): React.ReactElement {
  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.basicInfo,
        secondSideBarselected: secondSidebarSelect.member,
      }}
    >
      <MemberListAdmin />
    </MainLayout>
  );
}
