import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';

import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/bx-trash.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';

import { ReactComponent as DotsHorizontalIcon } from './assets/bx-dots-horizontal-rounded.svg';
import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
import { ReactComponent as SeparatorImage } from './assets/Seperator.svg';
import StepImage from './assets/Step.svg';

export const Steppanel_ContentsApproved = () => {
  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState<boolean>(false);
  const handleClick2 = () => {
    setOpen1(false);
    setOpen2(true);
  };

  const DropdownMenu = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog
          onClose={setOpen1}
          as="div"
          className="absolute left-[1269px] top-[52px] z-30 h-[81px] w-[128px] bg-[#FFFFFF] py-[4px] shadow-[0_4px_6px_rgba(0,0,0,0.05),0_10px_15px_rgba(0,0,0,0.1)]"
        >
          <button
            className="flex h-[36px] w-[128px] items-center justify-between px-[16px] py-[8px]"
            onClick={handleClick2}
          >
            <EditIcon width={20} height={20} />
            <span className="h-[20px] w-[56px] text-sm font-normal leading-5 text-gray-700">編集する</span>
          </button>
          <button className="flex h-[36px] w-[128px] items-center justify-between px-[16px] py-[8px]">
            <TrashIcon width={20} height={20} fill="#EF4444" />
            <span className="h-[20px] w-[56px] text-sm font-normal leading-5 text-red-600">削除する</span>
          </button>
        </Dialog>
      </Transition.Root>
    );
  };

  const ProductionEditModal = () => {
    return (
      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => setOpen2(false)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[512px] rounded-lg bg-white p-[24px]">
                  <div className="w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">投稿概要編集</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen2(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-[320px]">
                        <div className="mb-[4px] h-[20px] w-[70px]">
                          <span className="mb-[4px] text-sm font-semibold leading-5 text-gray-700">投稿予定日</span>
                        </div>
                        <DatePicker width="320px" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen2(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen2(false)}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div className="relative mb-[30px] mt-[16px] flex h-[45px] w-[1133px] items-center justify-between border-b border-gray-200 py-[11px]">
        <div className="flex h-[24px] w-[1149px] items-center">
          <div className="flex h-[24px] w-[628px] items-center justify-between">
            <ChevronLeftIcon width={24} height={24} fill="#9CA3AF" />
            <div className="flex h-[24px] w-[297px] items-center justify-between">
              <div className="flex h-[24px] w-[136px] items-center">
                <p className="h-[12px] w-[136px] whitespace-nowrap align-middle text-xs font-normal leading-3 text-gray-700">
                  10月分エメポスMVP施策
                </p>
              </div>
              <div className="flex h-[24px] w-[146px] items-center justify-between">
                <p className="h-[24px] w-[70px] text-base font-bold leading-6 text-gray-700">No.0004</p>
                <p className="h-[20px] w-[68px] whitespace-nowrap rounded bg-red-100 px-[10px] py-[2px] text-xs font-medium leading-4 text-red-800">
                  フィード
                </p>
              </div>
            </div>
            <p className="h-[20px] w-[247px] whitespace-nowrap text-sm font-normal leading-5 text-gray-500">
              投稿予定日：2021年2月12日(金) 18:00
            </p>
          </div>
        </div>
        <button onClick={handleClick1}>
          <DotsHorizontalIcon width={24} height={24} />
        </button>
      </div>
      <div className="mb-[25px] h-[72px] w-[1133px] bg-[#FFFFFF]">
        {/* パネル↓ */}
        <div className="mb-[24px] flex h-[72px] w-[1133px] items-center rounded border-[1px] border-gray-300">
          {/* 01イメージ案制作 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <img src={StepImage} alt="" className="h-[40px] w-[40px]" />
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-900">イメージ案制作</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 02イメージ案承認 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <img src={StepImage} alt="" className="h-[40px] w-[40px]" />
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-900">イメージ案承認</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 03コンテンツ制作 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <img src={StepImage} alt="" className="h-[40px] w-[40px]" />
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-900">コンテンツ制作</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 04コンテンツ承認 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <img src={StepImage} alt="" className="h-[40px] w-[40px]" />
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-[#006199]">コンテンツ承認</p>
            </div>
            <SeparatorImage />
          </div>
          {/* 05完了 */}
          <div className="flex items-center">
            <div className="flex h-[72px] w-[202px] items-center px-[24px] py-[16px]">
              <img src={StepImage} alt="" className="h-[40px] w-[40px]" />
              <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-[#006199]">完了</p>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{DropdownMenu()}</div>
      <div className="relative">{ProductionEditModal()}</div>
    </>
  );
};
