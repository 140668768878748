import mobile from '@/assets/images/mobile.jpg';
import instagram from '@/assets/instagram.png';

import sample from '../../assets/samples/sample.png';

export function MobilePreview() {
  return (
    <div className="relative">
      <img className="absolute h-[597.76px] w-[326.25px] pl-[40px] pt-[16px]" src={mobile} alt="" />
      <div className="w-[244px]">
        <img className="absolute left-[51px] top-[96px]" src={sample} alt="" style={{ width: '225px' }} />
      </div>
      <div className="absolute left-[51px] top-[220px] pl-[11px]">
        <div>
          <div className="left-[11.45px] flex w-[240.65px] flex-row items-start ">
            <div className="pt-[4px]">
              <img src={instagram} alt="" style={{ width: '15.68px', height: '15.68px' }} />
            </div>
            <div className="flex flex-row flex-wrap items-start">
              <div className="px-[2px] pt-[4px]">
                <div className="flex flex-row items-center justify-center rounded-sm bg-[#E0F2FE] px-[7px] py-[1.4px]">
                  <span className="text-[3px] leading-4 text-[#075985]">スキンケア</span>
                </div>
              </div>
              <div className="px-[2px] pt-[4px]">
                <div className="flex flex-row items-center justify-center rounded-sm bg-[#E0F2FE] px-[7px] py-[1.4px]">
                  <span className="text-[3px] leading-4 text-[#075985]">エイジングケア</span>
                </div>
              </div>
              <div className="px-[2px] pt-[4px]">
                <div className="flex flex-row items-center justify-center rounded-sm bg-[#E0F2FE] px-[7px] py-[1.4px]">
                  <span className="text-[3px] leading-4 text-[#075985]">コスメ</span>
                </div>
              </div>
              <div className="px-[2px] pt-[4px]">
                <div className="flex flex-row items-center justify-center rounded-sm bg-[#E0F2FE] px-[7px] py-[1.4px]">
                  <span className="text-[3px] leading-4 text-[#075985]">アンバサダー</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div className="left-[20.45px] flex w-[209px] flex-row items-start">
            <span className="text-[11px] text-gray-900">
              さっと塗るだけ!お手軽エイジングケア! 究極のオールインワン「カナデル プレミア
              ゼロ」をInstagramでPRしてください♡
            </span>
          </div>
        </div>
        <div className="pt-4">
          <div className="left-[20.45px] flex w-[209px] flex-row items-start">
            <span className="font-bold　text-gray-900">￥10,000</span>
          </div>
        </div>
        <div className="h-[1px] w-[209px] pt-6">
          <div className="border-[1px] border-gray-300"></div>
        </div>
        <div>
          <div className="pt-[17px]">
            <span className="text-[12px] text-gray-900">案件概要</span>
          </div>
        </div>
        <div className="pt-4">
          <div>
            <div>
              <span className="text-[9.8px] text-[#8E8E8E]">応募条件</span>
            </div>
          </div>
          <div>
            <div>
              <span className="text-[9.8px] text-gray-900">5,000フォロワー以上</span>
            </div>
          </div>
        </div>
        <div className="pt-4">
          <div>
            <div>
              <span className="text-[9.8px] text-[#8E8E8E]">推奨条件</span>
            </div>
          </div>
          <div>
            <div>
              <span className="text-[9.8px] text-gray-900">女性</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
