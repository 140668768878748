import { ReactComponent as CommentIcon } from '@/assets/icons/bx-comment-detail.svg';
import { ReactComponent as GroupIcon } from '@/assets/icons/bx-group.svg';
import { ReactComponent as HeartIcon } from '@/assets/icons/bx-heart.svg';
import { ReactComponent as GoodIcon } from '@/assets/icons/bx-like.svg';
import { ReactComponent as PlayIcon } from '@/assets/icons/bx-play-circle.svg';
import { ReactComponent as UploadIcon } from '@/assets/icons/bx-upload.svg';
import { Stat } from '@/components/Elements/Stat';
import { formatNumber } from '@/utils/format';

import { YoutubeInsightProps } from '../../Youtube/YoutubeAll_Interface';

export default function ApplicantInfo(insightPosts: YoutubeInsightProps, recentPostNum: number) {
  return (
    <>
      <div>
        <div className="text-sm font-semibold leading-5 text-gray-900">{`直近${recentPostNum}投稿での平均インサイト`}</div>
        <div className="flex h-[100px] w-[708px] justify-between gap-[8px] pt-[8px]">
          <Stat
            lable="チャンネル登録者数"
            value={formatNumber(insightPosts.subscribers || 0)}
            icon={<GroupIcon width={24} height={24} fill="#FFFFFF" />}
            className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
          />
          <Stat
            lable="視聴回数"
            value={formatNumber(insightPosts.play || 0)}
            icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
            className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
          />
        </div>
        {/* 2 行目 */}
        <div className="pt-[8px]">
          <div className="flex h-[100px] w-[708px] justify-between gap-[8px] pt-[8px]">
            <Stat
              lable="エンゲージメント率"
              value={(Number(insightPosts.engagementRate) || 0) + '%'}
              icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
            <Stat
              lable="エンゲージメント数"
              value={formatNumber(insightPosts.engagements || 0)}
              icon={<HeartIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
          </div>
        </div>
        {/* 3行目 */}
        <div className="pt-[8px]">
          <div className="flex h-[100px] w-[708px] justify-between gap-[8px] pt-[8px]">
            <Stat
              lable="高評価数（平均）"
              value={formatNumber(insightPosts.goodAvg || 0)}
              icon={<GoodIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
            <Stat
              lable="コメント数（平均）"
              value={formatNumber(insightPosts.commentsAvg || 0)}
              icon={<CommentIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
          </div>
        </div>
        {/* 4行目 */}
        <div className="pt-[8px]">
          <div className="flex h-[100px] w-[708px] justify-between gap-[8px] pt-[8px]">
            <Stat
              lable="視聴回数率"
              value={(Number(insightPosts.playRate) || 0) + '%'}
              icon={<PlayIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
            <Stat
              lable="動画数"
              value={formatNumber(insightPosts.movies || 0)}
              icon={<UploadIcon width={24} height={24} fill="#FFFFFF" />}
              className="h-[100px] w-[350px] justify-center whitespace-pre-wrap rounded-lg border border-slate-200"
            />
          </div>
        </div>
      </div>
    </>
  );
}
