import clsx from 'clsx';
import * as React from 'react';

export type ErrorType = 'client' | 'server' | undefined;

export type ErrorMessageProps = React.HTMLAttributes<HTMLElement> & {
  errorType: ErrorType;
  title?: string;
  description?: string;
};

export const ErrorMessage = React.forwardRef<HTMLElement, ErrorMessageProps>(({ className = '', ...props }) => {
  return (
    <>
      <div className={clsx(className, 'h-[89px] w-[451px] shrink-0 rounded-lg border border-red-500')}>
        <div className="h-[44px] w-[449px] shrink-0 rounded-t-lg bg-red-500 py-[10px] pl-4">
          <span className="text-base font-bold not-italic text-white">
            {props.title ?? '通信エラーが発生しました。'}
            {props.errorType ? (props.errorType == 'client' ? '（F）' : '（B）') : ''}
          </span>
        </div>
        <div className="h-[43px] w-[449px] shrink-0 rounded-b-lg bg-red-100 py-[10px] pl-4">
          <span className="text-xs font-normal not-italic text-red-600">
            {props.description ?? 'しばらく時間をおいて再度お試しください。'}
          </span>
        </div>
      </div>
    </>
  );
});

ErrorMessage.displayName = 'ErrorMessage';
