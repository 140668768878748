export const API_URL = process.env.REACT_APP_API_URL as string;
export const ASSETS_URL = process.env.REACT_APP_ASSETS_URL as string;
export const JWT_SECRET = '123456' as string;

// プロジェクト管理
export const PROJECT_TYPES = {
  SPIRIT: 'spirit',
  PRST: 'prst',
  EMERALD_POST: 'emerald_post',
  JANE_JOHN: 'jane_john',
  OTHER: 'other',
};

// プロジェクトステータス
export const PROJECT_STATUS = {
  ALL: 'all',
  POST_DRAFT: 'post_draft', // 募集内容作成（下書き）
  POST_REVIEW: 'post_review', // 募集内容作成（申請中）
  DRAFT: 'draft', // 募集内容作成（下書き） emerald_post以外
  REVIEW: 'review', // 募集内容作成（申請中） emerald_post以外
  APPROVED: 'approved', // 募集〜選考
  FINAL_CONFIRMATION: 'final_confirmation', // 最終確認
  IMPLEMENTATION: 'implementation', // 実施中
  COMPLETION: 'completion', // 完了 募集完了
  WAIT_POST: 'wait_post', // 配信待ち
  POSTING: 'posting', // 配信中
  POST_COMPLETION: 'post_completion', // 配信完了
};

export const UPLOAD_FILE_TYPES = {
  'image/jpeg': ['jpg', 'jpeg'],
  'image/png': ['png'],
  'image/gif': ['gif'],
};

export const SNS_TYPES = {
  INSTAGRAM: 'instagram',
  TWITTER: 'x',
  YOUTUBE: 'youtube',
  TIK_TOK: 'tik_tok',
  OTHER: 'other',
};

export const ENV_PREFIX = {
  LOCAL: 'local',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};
