import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';

import ArrowDropDown from '@/assets/icons/ArrowDropDown.svg';

const MAX_PAGE_NUMBER = 7;
export const MIN_PAGE_NUMBER = 1;

export type PageSize = 10 | 30 | 50 | 100;
const pageSizeList: PageSize[] = [10, 30, 50, 100];

const displayPageRange = (pageNumber: number, pageSize: PageSize, total: number): string => {
  const from = (pageNumber - 1) * pageSize + 1;
  const to = Math.min(pageNumber * pageSize, total);

  return `${from}-${to} of ${total}`;
};

// ページ選択部分に表示するページ番号の取得
const getVisiblePages = (totalPageNumber: number, currentPageNumber: number) => {
  const visiblePages: number[] = [];

  // 全体のページ数が最大ページ以下の場合
  if (totalPageNumber <= MAX_PAGE_NUMBER) {
    for (let i = MIN_PAGE_NUMBER; i <= totalPageNumber; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  }

  // 選択中のページ数が最大ページ数以下の場合
  if (currentPageNumber < MAX_PAGE_NUMBER) {
    for (let i = MIN_PAGE_NUMBER; i <= MAX_PAGE_NUMBER; i++) {
      visiblePages.push(i);
    }
    return visiblePages;
  }

  const endPage = totalPageNumber == currentPageNumber ? currentPageNumber : currentPageNumber + 1;
  const startPage = endPage - MAX_PAGE_NUMBER + 1;

  for (let i = startPage; i <= endPage; i++) {
    visiblePages.push(i);
  }

  return visiblePages;
};

type PagenationProps = {
  currentPageNumber: number;
  currentPageSize: PageSize;
  togalDataCount: number;
  totalPageNumber: number;
  setPageNumber: React.Dispatch<React.SetStateAction<number>>;
  setPageSize: React.Dispatch<React.SetStateAction<PageSize>>;
};

export const Pagenation = (props: PagenationProps) => {
  return (
    <div className="flex items-center justify-between rounded-b-lg border-t border-gray-200 bg-white px-4 py-1 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="flex flex-row items-center gap-2">
          <span className="text-xs font-normal leading-4 text-[#374151]">１ページあたりの行数:</span>
          <div className="text-xs font-normal leading-4 sm:col-span-3">
            <select
              id="perPage"
              name="perPage"
              className="appearance-none border-none pr-8 text-xs font-normal leading-4"
              style={{ backgroundImage: `url(${ArrowDropDown})`, backgroundSize: '24px auto' }}
              defaultValue={props.currentPageSize}
              onChange={(e) => {
                props.setPageSize(e.target.value as unknown as PageSize);
                if (
                  props.currentPageNumber > 1 &&
                  props.currentPageNumber * (e.target.value as unknown as PageSize) > props.togalDataCount
                ) {
                  props.setPageNumber(1);
                }
              }}
            >
              {pageSizeList.map((pageSize) => {
                return (
                  <option value={pageSize} key={pageSize}>
                    {pageSize}
                  </option>
                );
              })}
            </select>
          </div>
          <span className="text-xs font-normal text-[#374151]">
            {/** 表示件数番号from - 表示件数番号to of 全体件数*/}
            {displayPageRange(props.currentPageNumber, props.currentPageSize, props.togalDataCount)}
          </span>
        </div>
        {props.totalPageNumber > MIN_PAGE_NUMBER && (
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={() => {
                  if (props.currentPageNumber === MIN_PAGE_NUMBER) return;
                  props.setPageNumber(MIN_PAGE_NUMBER);
                }}
                className={clsx(
                  'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                  /** 現在のページ数が1の場合は表示しない */
                  props.currentPageNumber == MIN_PAGE_NUMBER ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronDoubleLeftIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.currentPageNumber == MIN_PAGE_NUMBER ? '#D1D5DB' : '#6B7280'}
                />
              </button>
              <button
                onClick={() => {
                  if (props.currentPageNumber === MIN_PAGE_NUMBER) return;
                  props.setPageNumber((prev) => prev - 1);
                }}
                className={clsx(
                  'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                  props.currentPageNumber == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronLeftIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.currentPageNumber == MIN_PAGE_NUMBER ? '#D1D5DB' : '#6B7280'}
                />
              </button>
              {/** 表示するページ番号 */}
              {getVisiblePages(props.totalPageNumber, props.currentPageNumber).map((num) => (
                <button
                  key={num}
                  className={`relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                    num == props.currentPageNumber ? 'border border-[#007CC2] bg-[#E0F4FF]' : ''
                  }`}
                  onClick={() => {
                    props.setPageNumber(num);
                  }}
                >
                  <span className="text-sm font-medium leading-5 text-gray-500">{num}</span>
                </button>
              ))}
              <button
                onClick={() => {
                  props.setPageNumber((prev) => prev + 1);
                }}
                className={clsx(
                  'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                  props.currentPageNumber == props.totalPageNumber
                    ? ''
                    : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.currentPageNumber == props.totalPageNumber ? '#D1D5DB' : '#6B7280'}
                />
              </button>
              <button
                onClick={() => {
                  props.setPageNumber(props.totalPageNumber);
                }}
                className={clsx(
                  'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                  props.currentPageNumber == props.totalPageNumber
                    ? ''
                    : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.currentPageNumber == props.totalPageNumber ? '#D1D5DB' : '#6B7280'}
                />
              </button>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
};
