import { Dialog, Transition } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import { Fragment, useState } from 'react';

import { PutBillingInformationInput } from '@/api/billing';
import { Input } from '@/components/Elements/Input';
import { upsertBillingInfo } from '@/hooks/BillingInfo';
import { validationInputVoidCheck, isMorehanValue, isValidEmail } from '@/utils/inputMultiValidationCheck';
import { ValidationMessages } from '@/utils/message';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  companyName: string;
  setCompanyName: React.Dispatch<React.SetStateAction<string>>;
  managerName: string;
  setMangerName: React.Dispatch<React.SetStateAction<string>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
  onSave: () => void;
  isRegistered: boolean;
  setIsRegistered: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function BillingInfoModal(props: Props) {
  const [step, setStep] = useState<number>(1);
  const [isErrorCompanyName, setIsErrorCompanyName] = useState<boolean>(false);
  const [isErrorManagerName, setIsErrorManagerName] = useState<boolean>(false);
  const [isErrorEmail, setIsErroEmail] = useState<boolean>(false);

  const changeState = () => {
    setIsErrorCompanyName(false);
    setIsErrorManagerName(false);
    setIsErroEmail(false);
    props.setOpen(false);
  };

  const handleNext = () => {
    const tmpIsErrorCompanyName =
      !validationInputVoidCheck(props.companyName) || isMorehanValue(props.companyName.length, 50);
    const tmpIsErrorManagerName =
      !validationInputVoidCheck(props.managerName) || isMorehanValue(props.managerName.length, 50);
    const tmpIsErrorEmail = !validationInputVoidCheck(props.email) || !isValidEmail(props.email);
    setIsErrorCompanyName(tmpIsErrorCompanyName);
    setIsErrorManagerName(tmpIsErrorManagerName);
    setIsErroEmail(tmpIsErrorEmail);
    if (tmpIsErrorCompanyName || tmpIsErrorManagerName || tmpIsErrorEmail) {
      return;
    }
    setStep(step + 1);
  };

  const executeUpsertBillingInfo = async () => {
    const upsertBillingInfoRequest: PutBillingInformationInput = {
      companyName: props.companyName,
      name: props.managerName,
      email: props.email,
    };

    try {
      // 請求先情報更新/登録APIリクエスト
      const data = await upsertBillingInfo(upsertBillingInfoRequest);
      props.setIsRegistered(true);
      console.log('executeUpsertBillingInfo:', data);
    } catch (error) {
      Sentry.captureException(error);
      console.error('API call failed:', error);
    }
  };

  // エラーメッセージ
  const getComanyNameErrorMessage = () => {
    const label = '会社名';
    if (!validationInputVoidCheck(props.companyName)) {
      return ValidationMessages.inputMessage(label);
    } else if (isMorehanValue(props.companyName.length, 51)) {
      return ValidationMessages.strMaxLenMessage(label, 50);
    } else {
      return '';
    }
  };

  const getManagerNameErrorMessage = () => {
    const label = '担当者';
    if (!validationInputVoidCheck(props.managerName)) {
      return ValidationMessages.inputMessage(label);
    } else if (isMorehanValue(props.managerName.length, 51)) {
      return ValidationMessages.strMaxLenMessage(label, 50);
    } else {
      return '';
    }
  };

  const getEMailErrorMessage = () => {
    const label = 'メールアドレス';
    if (!validationInputVoidCheck(props.companyName)) {
      return ValidationMessages.inputMessage(label);
    } else if (!isValidEmail(props.companyName)) {
      return ValidationMessages.emailFormatErrorMessage();
    } else {
      return '';
    }
  };

  const inputForm = () => {
    return (
      <>
        <div className="pt-4">
          <Input
            label="会社名"
            value={props.companyName}
            onChange={(e: any) => {
              const value = e.target.value;
              setIsErrorCompanyName(!validationInputVoidCheck(value) || isMorehanValue(value.length, 51));
              props.setCompanyName(e.target.value);
            }}
            isError={isErrorCompanyName}
          />
          {isErrorCompanyName && (
            <span className="my-[5px] text-sm font-semibold leading-5 text-red-400">{getComanyNameErrorMessage()}</span>
          )}
        </div>
        <div className="pt-4">
          <Input
            label="担当者名"
            value={props.managerName}
            onChange={(e: any) => {
              const value = e.target.value;
              setIsErrorManagerName(!validationInputVoidCheck(value) || isMorehanValue(value.length, 51));
              props.setMangerName(value);
            }}
            isError={isErrorManagerName}
          />
          {isErrorManagerName && (
            <span className="my-[5px] text-sm font-semibold leading-5 text-red-400">
              {getManagerNameErrorMessage()}
            </span>
          )}
        </div>
        <div className="pt-4">
          <Input
            label="メールアドレス"
            value={props.email}
            onChange={(e: any) => {
              const value = e.target.value;
              setIsErroEmail(!validationInputVoidCheck(value) || !isValidEmail(value));
              props.setEmail(value);
            }}
            isError={isErrorEmail}
          />
          {isErrorEmail && (
            <span className="my-[5px] text-sm font-semibold leading-5 text-red-400">{getEMailErrorMessage()}</span>
          )}
        </div>
        <div className="flex w-full flex-row-reverse pt-4">
          <div className="pl-3">
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-[#007CC2] px-[17px] py-[9px] text-sm font-medium leading-5 text-white"
              onClick={handleNext}
            >
              入力内容を確認
            </button>
          </div>
          <div>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-700"
              onClick={changeState}
            >
              キャンセル
            </button>
          </div>
        </div>
      </>
    );
  };
  const labelValueSet = (label: string, value: string) => {
    return (
      <div className="pt-4">
        <div>
          <span className="text-sm font-semibold leading-5 text-gray-700">{label}</span>
        </div>
        <div>
          <span className="text-sm font-normal leading-5 text-gray-700">{value}</span>
        </div>
      </div>
    );
  };

  const confrim = () => {
    const handleSave = () => {
      executeUpsertBillingInfo();
      props.onSave();
      setStep(1);
    };
    return (
      <>
        {labelValueSet('会社名', props.companyName)}
        {labelValueSet('担当者名', props.managerName)}
        {labelValueSet('メールアドレス', props.email)}
        <div className="flex w-full flex-row-reverse pt-4">
          <div className="pl-3">
            <button
              type="button"
              className="inline-flex justify-center rounded-md bg-[#007CC2] px-[17px] py-[9px] text-sm font-medium leading-5 text-white"
              onClick={handleSave}
            >
              {props.isRegistered ? '変更する' : '登録する'}
            </button>
          </div>
          <div>
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-700"
              onClick={() => {
                setStep(1);
              }}
            >
              キャンセル
            </button>
          </div>
        </div>
      </>
    );
  };

  const form = () => {
    if (step == 1) {
      return inputForm();
    } else {
      return confrim();
    }
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.setOpen(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[392px] w-[464px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:p-6">
                <div className="flex items-center justify-between pt-4">
                  <span className="text-sm font-semibold leading-5 text-gray-700">請求先情報</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    onClick={() => {
                      props.setOpen(false);
                    }}
                  >
                    <path
                      d="M6.5 18L18.5 6M6.5 6L18.5 18"
                      stroke="#9CA3AF"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                {form()}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
