import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ENV_PREFIX } from '@/config';
import { ROUTES } from '@/config/path';
import { secondSidebarSelect } from '@/config/sidebar';
export type SecondSideBarProps = React.HTMLAttributes<HTMLElement> & {
  selected: string;
};
export function SecondSidebar(props: SecondSideBarProps) {
  return (
    <>
      <div className="top-0 h-screen w-[200px] border-r border-gray-200 bg-gray-100">
        <div className="h-[432px] w-[200px]">
          <div className="w-[196px] border-b border-gray-200 p-[16px]">
            <p className="text-lg font-bold leading-7 text-[#000000]">案件管理</p>
          </div>
          <div className="w-[200px] px-[8px] pt-[16px]">
            <Link to={ROUTES.CALENDAR}>
              <div
                className={clsx(
                  'mb-[4px] p-[8px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.schedule ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="text-[14px] font-semibold leading-5 text-gray-500">スケジュール</p>
              </div>
            </Link>
            <div className="mb-[4px] p-[8px] ">
              <p className="text-[14px] font-semibold leading-5 text-gray-500">案件一覧</p>
            </div>
            {/* <Link to={ROUTES.PROJECT_LIST_EMERALD_POST}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.emeraldPost ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">EMERALD POST</p>
                <p className="text-xs font-normal leading-4 text-gray-400">広報</p>
              </div>
            </Link> */}
            <Link to={ROUTES.PROJECT_LIST_SPIRIT}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.spirit ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">SPIRIT</p>
                <p className="text-xs font-normal leading-4 text-gray-400">広告</p>
              </div>
            </Link>
            {/* リリースの際に条件式を外す */}
            {process.env.REACT_APP_ENV_PREFIX !== ENV_PREFIX.PRODUCTION && (
              <Link to={ROUTES.PROJECT_LIST_OTHER}>
                <div
                  className={clsx(
                    'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                    props.selected === secondSidebarSelect.other ? 'rounded-md bg-[#141730]/[.08]' : ''
                  )}
                >
                  <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">その他の案件</p>
                </div>
              </Link>
            )}
            {/* リリースの際に条件式を外す */}
            {process.env.REACT_APP_ENV_PREFIX !== ENV_PREFIX.PRODUCTION && (
              <Link to={ROUTES.PROJECT_LIST_JANE_JOHN}>
                <div
                  className={clsx(
                    'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                    props.selected == secondSidebarSelect.janeJohn ? 'rounded-md bg-[#141730]/[.08]' : ''
                  )}
                >
                  <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">JANE JOHN</p>
                  <p className="text-xs font-normal leading-4 text-gray-400">調査</p>
                </div>
              </Link>
            )}
            {/* <div className="mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px]">
              <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">PRST</p>
              <p className="text-xs font-normal leading-4 text-gray-400">制作</p>
            </div>
            <Link to={ROUTES.PROJECT_LIST_PRST}>
              <div
                className={clsx(
                  'mb-[4px] py-[8px] pl-[40px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.prst ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="text-[14px] font-semibold leading-5 text-gray-500">募集管理</p>
              </div>
            </Link>
            <Link to={ROUTES.PROJECT_LIST_PROST_POST}>
              <div
                className={clsx(
                  'mb-[4px] py-[8px] pl-[40px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected == secondSidebarSelect.prstPost ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="text-[14px] font-semibold leading-5 text-gray-500">制作管理</p>
              </div>
            </Link> */}
          </div>
        </div>
      </div>
    </>
  );
}
