// import { FirstSidebar } from '@/components/Elements/FirstSideBar';
// import { SecondSidebar } from '@/components/Elements/SecondSideBar';
import { MainLayout } from '@/components/Layout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';

import { AccountProvider } from '../components/AccountContext';
import { MainView } from '../components/MainView';
import { UIProvider } from '../components/UIContext';

export const Management = () => {
  return (
    <>
      <div className="flex">
        <AccountProvider>
          <UIProvider>
            {/* <FirstSidebar /> */}
            {/* <SecondSidebar /> */}
            <MainLayout
              sidebarProps={{
                firstSideBarselected: firstSidebarSelect.calendar,
                secondSideBarselected: secondSidebarSelect.prstPost,
              }}
            >
              <MainView />
            </MainLayout>
          </UIProvider>
        </AccountProvider>
      </div>
    </>
  );
};
