import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { TextArea } from '@/components/Elements/TextArea';

import { AlertImageApprove } from './AlertImageApprove';
import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as BoldIcon } from './assets/bx-bold.svg';
import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
import ErrorImage from './assets/bx-error.svg';
import { ReactComponent as FontcolorIcon } from './assets/bx-font-color.svg';
import { ReactComponent as ImageAddIcon } from './assets/bx-image-add.svg';
import { ReactComponent as ItalicIcon } from './assets/bx-italic.svg';
import { ReactComponent as MenuLeftIcon } from './assets/bx-menu-alt-left.svg';
import { ReactComponent as TrashIcon } from './assets/bx-trash-alt.svg';
import { ReactComponent as UnderlineIcon } from './assets/bx-underline.svg';
import CommentIcon from './assets/CommentIcon.png';
import SampleMediaImage from './assets/SampleMediaImage.png';
import { CalendarInput } from './CalendarInput';
import { PostingtypeInput } from './PostingtypeInput';
import { StepPanel_ImageApproving } from './StepPanel_ImageApproving';

export const PRSTProductionManagementImageApprovingView = () => {
  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen1(true);
  };
  const [open2, setOpen2] = useState<boolean>(false);
  const handleClick2 = () => {
    setOpen1(false);
    setOpen2(true);
  };
  const [comment, setComment] = useState<boolean>(false);
  const handleClick3 = () => {
    setComment(true);
  };
  const [del, setDel] = useState<boolean>(false);
  const handleClick4 = () => {
    setDel(true);
  };

  const AddImageModal1 = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を編集</span>
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="mb-[32px] h-[188px] w-[320px]">
                            <div className="mb-[16px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">投稿概要</span>
                            </div>
                            <div className="h-[152px] w-[320px]">
                              <div className="mb-[16px]">
                                <PostingtypeInput />
                              </div>
                              <div>
                                <CalendarInput />
                              </div>
                            </div>
                          </div>
                          <div className="h-full w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-[577px] w-[693px]">
                              <div className="mb-[16px] h-[262px] w-[693px] border-b border-gray-200 pb-[16px]">
                                <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                  <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">1枚目</span>
                                  </div>
                                  <div className="flex w-[110px] items-center justify-around ">
                                    <ChevronDownIcon width={28} height={28} fill="#374151" />
                                    <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
                                    <TrashIcon width={22} height={22} fill="#6B7280" />
                                  </div>
                                </div>
                                <div className="flex h-[210px] w-[695px] items-center justify-between">
                                  <img src={SampleMediaImage} alt="" />
                                  <div className="h-[209px] w-[416px]">
                                    <div className="mb-[4px] h-[20px] w-[111px]">
                                      <span className="text-sm font-semibold leading-5 text-gray-700">
                                        画像 / 動画の説明
                                      </span>
                                    </div>
                                    <TextArea className="h-[185px] w-[416px]" />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-[16px] h-[245px] w-[693px]">
                                <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                  <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">2枚目</span>
                                  </div>
                                  <div className="flex w-[110px] items-center justify-around ">
                                    <ChevronDownIcon width={28} height={28} fill="#374151" />
                                    <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
                                    <TrashIcon width={22} height={22} fill="#6B7280" />
                                  </div>
                                </div>
                                <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                  <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                                    <div className="h-[120px] w-[220px] pb-[4px]">
                                      <div className="flex items-center justify-center">
                                        <div className="flex h-[48px] w-[48px] items-center justify-center">
                                          <AddIcon width={36} height={36} />
                                        </div>
                                      </div>
                                      <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                        <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                          <span className="text-sm font-medium leading-5 text-gray-600">
                                            ドラッグ&ドロップもしくは
                                          </span>
                                        </div>
                                        <div className="flex items-center justify-center">
                                          <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                            アップロード
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-xs font-normal leading-4 text-gray-500">
                                          対応ファイルはPNG, JPG, GIF,mp4
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="h-[209px] w-[416px]">
                                    <div className="mb-[4px] h-[20px] w-[111px]">
                                      <span className="text-sm font-semibold leading-5 text-gray-700">
                                        画像 / 動画の説明
                                      </span>
                                    </div>
                                    <TextArea className="h-[185px] w-[416px]" />
                                  </div>
                                </div>
                              </div>
                              <Button
                                startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                variant="secoundary"
                                size="smNopadding"
                                className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                              >
                                メディアイメージを追加する
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-[32px] h-[216px] w-[693px]">
                          <div className="mb-[4px] h-[20px] w-[56px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                          </div>
                          <TextArea className="h-[192px] w-[693px] px-[16px] py-[8px]" />
                        </div>
                        <div className="flex items-end justify-start">
                          <button
                            className="h-[20px] w-[56px] text-sm font-medium leading-5 text-red-700"
                            onClick={handleClick4}
                          >
                            削除する
                          </button>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen1(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick2}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen1(false)}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const ConfirmationModal = () => {
    return (
      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full w-[512px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-full w-[464px] bg-white">
                    <div className="mb-[24px] flex">
                      <div className="flex h-full w-[464px] items-center justify-start">
                        <div className="flex h-[48px] w-[269px] items-center">
                          <span className="whitespace-nowrap text-left text-[18px] font-medium leading-6 text-[#111827]">
                            編集内容が保存されていません。
                            <br /> 保存しますか？
                          </span>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen2(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[464px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[146px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen2(false)}
                      >
                        保存しないで戻る
                      </Button>

                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen2(false)}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const CommentWindow = () => {
    return (
      <>
        <div className="absolute left-[1036px] top-0 z-20 h-[840px] w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
          <div className="mb-[22px] flex w-full justify-end">
            <button className="mr-[0px]">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setComment(false)} />
            </button>
          </div>
          <form className="h-[184px] w-[358px]">
            <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
              イメージ案へのコメント
            </label>
            <div className="my-[24px] h-[108px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
              <div className="h-[92px] w-[326px]">
                <textarea
                  className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                  placeholder="新規コメント"
                  defaultValue={''}
                />
                <div className="flex h-[34px] w-[326px] items-end justify-between">
                  <div className="flex h-[24px] w-[164px] items-center justify-between">
                    <button className="h-[16px] w-[16px]">
                      <ImageAddIcon width={16} height={16} fill="#000000" />
                    </button>
                    <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
                    <div className="flex h-[17px] w-[117px] items-center justify-between">
                      <button className="h-[16px] w-[16px]">
                        <BoldIcon width={16} height={16} fill="#000000" />
                      </button>
                      <button className="h-[16px] w-[16px]">
                        <ItalicIcon width={16} height={16} fill="#000000" />
                      </button>
                      <button className="h-[16px] w-[16px]">
                        <UnderlineIcon width={17} height={17} fill="#000000" />
                      </button>
                      <button className="h-[16px] w-[16px]">
                        <FontcolorIcon width={17} height={17} fill="#000000" />
                      </button>
                    </div>
                  </div>
                  <Button className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4">
                    送信
                  </Button>
                </div>
              </div>
            </div>
            <div className="h-[1px] w-[358px] border-t-[1px] border-[#DDDDDD]"></div>
          </form>
        </div>
      </>
    );
  };

  const DeleteModal = () => {
    return (
      <Transition.Root show={del} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 opacity-30 transition-opacity"
              style={{ background: 'rgba(107, 114, 128)' }}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <div>
                      <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setDel(false)} />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-[12px] h-[40px] w-[40px]">
                      <img src={ErrorImage} alt="" />
                    </div>
                    <div className=" sm:flex sm:items-start">
                      <div className="text-center sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="h-[24px] w-[408px] text-lg font-medium leading-6 text-gray-900"
                        >
                          このイメージ案を削除してよろしいですか？
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm font-normal leading-5 text-gray-500">この作業は取り戻せません。</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                      style={{ background: '#DC2626' }}
                      onClick={() => setDel(false)}
                    >
                      削除する
                    </button>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDel(false)}
                    >
                      キャンセル
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <>
      <div className="relative h-[1676px] w-[1181px] bg-gray-50">
        <div className="h-[1458px] w-[1181px] px-[24px]">
          <StepPanel_ImageApproving />
          <AlertImageApprove />
          <div className="mt-[25px] h-[755px] w-[1133px] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[707px] w-[1085px]">
              <div className="mb-[24px] flex h-[38px] w-[1085px] items-center justify-between">
                <div className="flex h-[28px] w-[92px] items-center">
                  <span className="text-lg font-semibold leading-7 text-gray-500">イメージ案1</span>
                </div>
                <div className="flex h-[38px] w-[206px] items-center justify-between">
                  <Link to="/PRST_production_management/image_approved">
                    <Button className="text-sm font-medium leading-5">承認する</Button>
                  </Link>
                  <button onClick={handleClick1}>
                    <EditIcon width={24} height={24} fill="#6B7280" />
                  </button>
                  <button onClick={handleClick3}>
                    <img src={CommentIcon} alt="" />
                  </button>
                </div>
              </div>
              <div className="h-[32px] w-[1085px]">
                <div className="mb-[8px] flex h-[20px] w-[56px] items-center">
                  <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                </div>
                <div className="mb-[8px] flex h-[28px] w-[162px] items-center">
                  <div className="mr-[5px] flex h-[20px] w-[56px] items-center">
                    <span className="text-sm font-semibold leading-5 text-gray-700">1 / 2枚目</span>
                  </div>
                  <div className="h-[28px] w-[101px]">
                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                  </div>
                </div>
                <div className="mb-[24px] flex h-[298px] w-[1085px] justify-between py-[10px]">
                  <div className="w-[290px]">
                    <img src={SampleMediaImage} alt="" className="w-[290px]" />
                  </div>
                  <div className="h-[236px] w-[715px] py-[10px]">
                    <div className="mb-[20px] flex h-[20px] w-[124px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <div className="flex h-[16px] w-[96px] items-center">
                        <span className="text-xs font-normal leading-4 text-gray-800">画像 / 動画の説明</span>
                      </div>
                    </div>
                    <div className="h-[176px] w-[715px] text-xs font-normal leading-4 text-gray-800">
                      <span>
                        人物画でカメラを持った状態など、いきいきと撮影している場面が想像できるような写真を用意していただきたいです！
                        <br />
                        <br />
                        以下のことはおやめください。
                        <br />
                        <br />
                        ・危険な場所での撮影
                        <br />
                        ・他社製品と撮影すること
                        <br />
                        ・暗い場所での撮影（明るいイメージを希望しているため）
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-[24px] h-[1px] w-[1085px] border-b border-[#DDDDDD]"></div>
                <div className="h-[242px] w-[1085px]">
                  <div className="mb-[8px] h-[20px] w-[1085px] px-[16px]">
                    <span className="h-[20px] w-[56px] text-sm font-semibold leading-5 text-gray-800">テキスト</span>
                  </div>
                  <div className="h-[214px] w-[1085px] p-[16px]">
                    <span className="h-[182px] w-[1053px] text-xs font-normal leading-4 text-gray-800">
                      ①「Canon360」という商品名を入れてください。
                      <br />
                      ②
                      イベント情報入れてださい。以下の内容をいれていただければ、自由に文章を作っていただいて大丈夫です！文章のトーンは、絵文字などを使って、読みやすく明るい感じでお願いします。
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません。
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{AddImageModal1()}</div>
      <div className="relative">{ConfirmationModal()}</div>
      <div className="relative">{DeleteModal()}</div>
      {comment ? <div className="absolute">{CommentWindow()}</div> : null}
    </>
  );
};
