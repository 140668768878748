import { CheckCircleIcon } from '@heroicons/react/20/solid';

import { MemberInviteProps } from '../../../features/member_admin/components/MemberModal';

export default function ResendMessage(
  mailAddress: string,
  setMessageVal: React.Dispatch<
    React.SetStateAction<{
      successFlag: boolean;
      message: string;
    }>
  >,
  memberInviteArgs: MemberInviteProps
) {
  async function offMessageValFlag() {
    await new Promise((resolve) => setTimeout(resolve, 5000));
    setMessageVal((prev) => ({ ...prev, successFlag: false }));
  }
  offMessageValFlag();
  return (
    <div className="fixed left-80 z-10 mt-4 w-[900px] rounded-[6px] border-l-4 border-green-400 bg-green-50 p-4">
      <div className="flex">
        <div className="shrink-0">
          <CheckCircleIcon className="h-5 w-5 text-green-400" aria-hidden="true" />
        </div>
        <div className="ml-3">
          {memberInviteArgs.invite ? (
            <p className="text-sm font-medium leading-5 text-green-700">「{mailAddress}」に招待メールを送信しました </p>
          ) : (
            <p className="text-sm font-medium leading-5 text-green-700">「{mailAddress}」に招待メールを再送しました </p>
          )}
        </div>
      </div>
    </div>
  );
}
