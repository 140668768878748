import { FollowerAttributeTop3 } from '@/api/influencer';

export default function FollowerAttribute({ top3 }: { top3?: FollowerAttributeTop3 }) {
  const ageGender = top3?.ageGender ?? [];
  const genre = top3?.genre ?? [];
  const values = top3?.values ?? [];
  const rankingContent = (label: string, values: string[]) => {
    return (
      <div className="flex flex-1 flex-col items-start gap-2">
        <span className="text-sm font-semibold leading-5 text-gray-900">{label}</span>
        {values.map((value: string, index: number) => {
          return (
            <div className="flex items-center gap-2 self-stretch " key={index}>
              <span className="text-sm font-semibold leading-5">{index + 1}.</span>
              <div className="flex h-8 flex-1 items-center px-[13px] py-[9px]">
                <span className="text-sm font-medium leading-4">{value}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="flex flex-col items-start gap-[64px] self-stretch">
      <div className="flex flex-col items-start gap-6 self-stretch">
        {top3 === undefined && (
          <div>
            <span className="text-sm font-bold">フォロワーのタイプ</span>
          </div>
        )}
        <div className="flex items-start gap-10 self-stretch">
          {/* ランキング 年代・性別 */}
          {rankingContent('年代・性別', ageGender)}
          {/* ランキング ジャンル */}
          {rankingContent('ジャンル', genre)}
          {/* ランキング 価値観 */}
          {rankingContent('価値観', values)}
        </div>
      </div>
    </div>
  );
}
