import Chart from 'react-apexcharts';

import donutChart, { labelColor } from './donutChart';

interface Props {
  project_title?: string;
}

export default function FollowerAttributeInstagram({ project_title = 'SPIRIT' }: Props) {
  const folloerSeries = [25, 21, 17, 13, 8, 4, 2, 2, 2, 2, 2, 2];
  const byAgeSeries = [
    { name: ' 男性', data: [160, 120, 110, 80, 50] },
    { name: ' 女性', data: [120, 110, 180, 30, 30] },
    { name: ' その他', data: [60, 40, 30, 20, 35] },
  ];
  const byAgeOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'right',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['13-17歳', '18-24歳', '25-34歳', '35-44歳', '45-54歳'],
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    yaxis: {
      min: 0,
      max: 200,
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    colors: ['#007CC2', '#80D0FF', '#D1D5DB'],
    legend: {
      horizontalAlign: 'right' as 'right' | 'center' | 'left' | undefined,
      labels: {
        colors: ['#6B7280', '#6B7280', '#6B7280'],
      },
      itemMargin: {
        horizontal: 12,
        vertical: 0,
      },
    },
  };

  const prefecturesSeries = [{ data: [190, 160, 120, 70, 50, 40, 20] }];

  const prefecturesOptions = {
    chart: {
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: true,
        dataLabels: {
          position: 'top',
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['東京都', '神奈川県', '大阪府', '愛知県', '埼玉県', '千葉県', '福岡県'],
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    yaxis: {
      min: 0,
      max: 200,
      labels: {
        style: {
          colors: ['#6B7280'],
        },
      },
    },
    colors: ['#007CC2'],
  };

  const raitionSeries = [29580, 14709, 4903];
  const raitionLabels: labelColor[] = [
    {
      label: '男性 (60%)',
      color: '#004A75',
    },
    {
      label: '女性 (30%)',
      color: '#007CC2',
    },
    {
      label: 'その他 (10%)',
      color: '#D1D5DB',
    },
  ];

  const followerLabels: labelColor[] = [
    {
      label: '10代女性 (25%)',
      color: '#004A75',
    },
    {
      label: '20代女性 (21%)',
      color: '#006199',
    },
    {
      label: '30代女性 (17%)',
      color: '#007CC2',
    },
    {
      label: '40代女性 (13%)',
      color: '#00A2FF',
    },
    {
      label: '50代女性 (8%)',
      color: '#42BAFF',
    },
    {
      label: '60代以上女性 (4%)',
      color: '#80D0FF',
    },
    {
      label: '10代男性 (2%)',
      color: '#C2E9FF',
    },
    {
      label: '20代男性(2%)',
      color: '#D1D5DB',
    },
    {
      label: '30代男性 (2%)',
      color: '#D1D5DB',
    },
    {
      label: '40代男性 (2%)',
      color: '#D1D5DB',
    },
    {
      label: '50代男性 (2%)',
      color: '#D1D5DB',
    },
    {
      label: '60代以上男性 (2%)',
      color: '#D1D5DB',
    },
  ];

  const genreSeries = [24, 20, 16, 12, 8, 4, 2, 4, 4, 4];
  const genreLabels: labelColor[] = [
    {
      label: 'ライフスタイル (24%)',
      color: '#004A75',
    },
    {
      label: 'ママ・ファミリー (20%)',
      color: '#006199',
    },
    {
      label: 'ライフスタイル (16%)',
      color: '#007CC2',
    },
    {
      label: 'ビューティー (12%)',
      color: '#00A2FF',
    },
    {
      label: 'ファッション (8%)',
      color: '#42BAFF',
    },
    {
      label: 'ママ・ファミリー (4%)',
      color: '#80D0FF',
    },
    {
      label: 'ライフスタイル (2%)',
      color: '#C2E9FF',
    },
    {
      label: 'ビューティー (4%)',
      color: '#D1D5DB',
    },
    {
      label: 'トラベル (4%)',
      color: '#D1D5DB',
    },
    {
      label: 'ファッション (4%)',
      color: '#D1D5DB',
    },
  ];

  const valuesSeries = [24, 20, 16, 12, 8, 4, 2, 4, 4, 4];
  const valuesLabels: labelColor[] =
    project_title == 'JANEJOHN' || project_title == 'OTHER'
      ? [
          {
            label: '完全でありたい人 (24%)',
            color: '#004A75',
          },
          {
            label: '成功を追い求める人 (20%)',
            color: '#006199',
          },
          {
            label: '人の助けになりたい人 (16%)',
            color: '#007CC2',
          },
          {
            label: '特別な存在であろうとする人 (12%)',
            color: '#00A2FF',
          },
          {
            label: '成功を追い求める人 (8%)',
            color: '#42BAFF',
          },
          {
            label: '人の助けになりたい人 (4%)',
            color: '#80D0FF',
          },
          {
            label: '完全でありたい人 (2%)',
            color: '#C2E9FF',
          },
          {
            label: '成功を追い求める人 (4%)',
            color: '#D1D5DB',
          },
          {
            label: '知識を得て観察する人 (4%)',
            color: '#D1D5DB',
          },
          {
            label: '完全でありたい人 (4%)',
            color: '#D1D5DB',
          },
        ]
      : [
          {
            label: '完全でありたい人 (24%)',
            color: '#004A75',
          },
          {
            label: '成功を追い求める人 (20%)',
            color: '#006199',
          },
          {
            label: '特別な存在であろうとする人 (12%)',
            color: '#007CC2',
          },
          {
            label: '成功を追い求める人 (8%)',
            color: '#00A2FF',
          },
          {
            label: '完全でありたい人 (2%)',
            color: '#42BAFF',
          },
          {
            label: '成功を追い求める人 (4%)',
            color: '#80D0FF',
          },
          {
            label: '知識を得て観察する人 (4%)',
            color: '#C2E9FF',
          },
          {
            label: '完全でありたい人 (4%)',
            color: '#D1D5DB',
          },
        ];

  return (
    <div className="flex flex-col items-start gap-10 self-stretch p-6">
      {project_title === 'SPIRIT' && (
        <span>
          <div className="flex items-start gap-8 self-stretch">
            <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
              <span className="text-sm font-semibold leading-5 text-gray-900">フォロワーの年齢分布</span>
              <div className="flex h-[258px] w-[424px] gap-8">
                <Chart type="bar" options={byAgeOptions} series={byAgeSeries} height={259} width={417} />
              </div>
            </div>
            {donutChart({
              title: 'フォロワーの男女比',
              series: raitionSeries,
              labels: raitionLabels,
              totalInformation: {
                label: '合計',
                value: '49,303',
              },
            })}
          </div>
          <div className="flex items-start gap-8 self-stretch">
            <div className="flex h-[302px] w-[424px] flex-col items-start gap-6">
              <span className="text-sm font-semibold leading-5 text-gray-900">フォロワーの都道府県</span>
              <div className="flex h-[258px] w-[424px] gap-8">
                <Chart type="bar" options={prefecturesOptions} series={prefecturesSeries} height={231} width={417} />
              </div>
            </div>
            <></>
          </div>
        </span>
      )}
      <div className="flex items-start gap-8 self-stretch">
        {donutChart({
          title: 'フォロワーの男女比/年齢分布',
          series: folloerSeries,
          labels: followerLabels,
        })}
        {donutChart({
          title: 'フォロワーのジャンル',
          series: genreSeries,
          labels: genreLabels,
        })}
      </div>
      <div className="flex items-start gap-8 self-stretch">
        {donutChart({
          title: '価値観',
          series: valuesSeries,
          labels: valuesLabels,
        })}
        <></>
      </div>
    </div>
  );
}
