import React from 'react';

export default function Tab(
  setSnsStatus: React.Dispatch<React.SetStateAction<string>>,
  button_color_list: string[],
  setButtonColorList: React.Dispatch<React.SetStateAction<string[]>>,
  snsType: string
) {
  const statuses = ['applicant_info', 'insight', 'follower', 'accountPR'];

  const change_button_status = (index: number) => {
    setSnsStatus(statuses[index]);
    setButtonColorList(button_color_list.map((value, i) => (i === index ? blue_button_color : normal_button_color)));
  };

  const normal_button_color = 'text-sm font-medium leading-5 text-gray-500';
  const blue_button_color = 'text-sm font-medium leading-5 text-blue-500';

  // tab の内容
  const menu_list = ['投稿情報', 'インサイト', 'フォロワー属性', 'アカウントPRなど'];

  // tab の長さ
  const menu_blue_list_length = ['w-[56px]', 'w-[69px]', 'w-[98px]', 'w-[120px]'];
  const connect_length_css = (index: number) => {
    return 'h-[2px] ' + menu_blue_list_length[index];
  };
  return (
    <>
      {/* tab の切り替え */}
      {snsType === 'Tiktok' || snsType === 'youtube' ? (
        <div className="absolute -z-10 mt-[40px] h-[2px] w-[889px] bg-gray-200"></div>
      ) : snsType === 'Instagram' ? (
        <div className="absolute -z-10 mt-[40px] h-[2px] w-[794px] bg-gray-200"></div>
      ) : (
        <div className="absolute -z-10 mt-[40px] h-[2px] w-[750px] bg-gray-200"></div>
      )}
      <div className="flex gap-[36px]">
        {menu_list.map((value, index) => {
          return (
            <div key={index}>
              <div>
                <button className={button_color_list[index]} onClick={() => change_button_status(index)}>
                  {value}
                </button>
              </div>
              <div>
                {button_color_list[index] === blue_button_color ? (
                  <div className="z-10 flex pt-[16px]">
                    <div className={connect_length_css(index)} style={{ background: '#007CC2' }}></div>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}
