/* tslint:disable */
/* eslint-disable */
/**
 * Influfect クライアント API
 * Influfect クライアント APIドキュメント
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AccountFollowerAttributes
 */
export interface AccountFollowerAttributes {
    /**
     * 
     * @type {Array<FollowerAttrDistribution>}
     * @memberof AccountFollowerAttributes
     */
    'ageDistribution': Array<FollowerAttrDistribution>;
    /**
     * 
     * @type {Array<FollowerAttrDistribution>}
     * @memberof AccountFollowerAttributes
     */
    'genreDistribution': Array<FollowerAttrDistribution>;
    /**
     * 
     * @type {Array<FollowerAttrDistribution>}
     * @memberof AccountFollowerAttributes
     */
    'valueDistribution': Array<FollowerAttrDistribution>;
}
/**
 * 
 * @export
 * @interface AffectOutput
 */
export interface AffectOutput {
    /**
     * 
     * @type {string}
     * @memberof AffectOutput
     */
    'affected': string;
}
/**
 * 
 * @export
 * @interface ApiResponseError
 */
export interface ApiResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseError
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResponseError
     */
    'messages'?: Array<string>;
}
/**
 * 承認ステータス
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    Review: 'review',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {Array<EvaluationRate>}
     * @memberof Evaluation
     */
    'evaluationRates': Array<EvaluationRate>;
}
/**
 * 
 * @export
 * @interface EvaluationRate
 */
export interface EvaluationRate {
    /**
     * 
     * @type {number}
     * @memberof EvaluationRate
     */
    'rate': number;
    /**
     * 1〜5の整数値
     * @type {number}
     * @memberof EvaluationRate
     */
    'value': number;
}
/**
 * 
 * @export
 * @interface FeedbackAdopterCommon
 */
export interface FeedbackAdopterCommon {
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'adoptedInfluencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'applyingInfluencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'snsAccountThumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'influencerId': string;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'evaluationId': string;
    /**
     * 評価値
     * @type {number}
     * @memberof FeedbackAdopterCommon
     */
    'evaluation': FeedbackAdopterCommonEvaluationEnum;
    /**
     * 
     * @type {string}
     * @memberof FeedbackAdopterCommon
     */
    'feedbackComment': string;
}

export const FeedbackAdopterCommonEvaluationEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
    NUMBER_5: 5
} as const;

export type FeedbackAdopterCommonEvaluationEnum = typeof FeedbackAdopterCommonEvaluationEnum[keyof typeof FeedbackAdopterCommonEvaluationEnum];

/**
 * フィードバックステータス
 * @export
 * @enum {string}
 */

export const FeedbackStatus = {
    Client: 'client',
    Admin: 'admin'
} as const;

export type FeedbackStatus = typeof FeedbackStatus[keyof typeof FeedbackStatus];


/**
 * 
 * @export
 * @interface FollowerAttrDistribution
 */
export interface FollowerAttrDistribution {
    /**
     * 
     * @type {string}
     * @memberof FollowerAttrDistribution
     */
    'code': string;
    /**
     * 
     * @type {number}
     * @memberof FollowerAttrDistribution
     */
    'count': number;
}
/**
 * 端数処理種別
 * @export
 * @enum {string}
 */

export const FractionType = {
    Floor: 'floor',
    Ceiling: 'ceiling',
    Round: 'round'
} as const;

export type FractionType = typeof FractionType[keyof typeof FractionType];


/**
 * 
 * @export
 * @interface GetAccountOutput
 */
export interface GetAccountOutput {
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'memberId': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'teamId': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'phoneNumber': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'thumbnail': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'companyName': string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAccountOutput
     */
    'role': GetAccountOutputRoleEnum;
    /**
     * 案件通知とお知らせ通知の未読数の合計
     * @type {number}
     * @memberof GetAccountOutput
     */
    'unreadCount': number;
}

export const GetAccountOutputRoleEnum = {
    Administrator: 'administrator',
    Member: 'member'
} as const;

export type GetAccountOutputRoleEnum = typeof GetAccountOutputRoleEnum[keyof typeof GetAccountOutputRoleEnum];

/**
 * 
 * @export
 * @interface GetMembers400Response
 */
export interface GetMembers400Response {
    /**
     * 
     * @type {string}
     * @memberof GetMembers400Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetMembers400Response
     */
    'messages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface Member
 */
export interface Member {
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'memberId': string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'thumbnail': string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'companyName': string;
    /**
     * 
     * @type {string}
     * @memberof Member
     */
    'role': MemberRoleEnum;
}

export const MemberRoleEnum = {
    Administrator: 'administrator',
    Member: 'member'
} as const;

export type MemberRoleEnum = typeof MemberRoleEnum[keyof typeof MemberRoleEnum];

/**
 * 
 * @export
 * @interface MyMember
 */
export interface MyMember {
    /**
     * 招待メンバーはnullが入ります
     * @type {string}
     * @memberof MyMember
     */
    'memberId': string | null;
    /**
     * 招待メンバーはnullが入ります
     * @type {string}
     * @memberof MyMember
     */
    'name': string | null;
    /**
     * 
     * @type {string}
     * @memberof MyMember
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof MyMember
     */
    'thumbnail': string;
    /**
     * member：一般 administrator：管理者
     * @type {string}
     * @memberof MyMember
     */
    'role': MyMemberRoleEnum;
    /**
     * 参加済みのメンバーはnull
     * @type {string}
     * @memberof MyMember
     */
    'invitationId': string | null;
    /**
     * 参加済みのメンバーはnull, pending：招待中 expired：招待期限切れ
     * @type {string}
     * @memberof MyMember
     */
    'status': MyMemberStatusEnum | null;
}

export const MyMemberRoleEnum = {
    Administrator: 'administrator',
    Member: 'member'
} as const;

export type MyMemberRoleEnum = typeof MyMemberRoleEnum[keyof typeof MyMemberRoleEnum];
export const MyMemberStatusEnum = {
    Pending: 'pending',
    Expired: 'expired'
} as const;

export type MyMemberStatusEnum = typeof MyMemberStatusEnum[keyof typeof MyMemberStatusEnum];

/**
 * 
 * @export
 * @interface PaginationOutput
 */
export interface PaginationOutput {
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface PatchAccountInput
 */
export interface PatchAccountInput {
    /**
     * 
     * @type {string}
     * @memberof PatchAccountInput
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchAccountInput
     */
    'phoneNumber'?: string;
    /**
     * 
     * @type {string}
     * @memberof PatchAccountInput
     */
    'companyName'?: string;
}
/**
 * 
 * @export
 * @interface PatchMemberRoleInput
 */
export interface PatchMemberRoleInput {
    /**
     * 
     * @type {string}
     * @memberof PatchMemberRoleInput
     */
    'role': PatchMemberRoleInputRoleEnum;
}

export const PatchMemberRoleInputRoleEnum = {
    Administrator: 'administrator',
    Member: 'member'
} as const;

export type PatchMemberRoleInputRoleEnum = typeof PatchMemberRoleInputRoleEnum[keyof typeof PatchMemberRoleInputRoleEnum];

/**
 * 
 * @export
 * @enum {string}
 */

export const PostingType = {
    Reel: 'reel',
    Feed: 'feed',
    Stories: 'stories',
    XImagePost: 'x_image_post',
    XPost: 'x_post',
    YoutubeShort: 'youtube_short',
    Youtube: 'youtube',
    TikTok: 'tik_tok',
    OtherVideo: 'other_video',
    Other: 'other'
} as const;

export type PostingType = typeof PostingType[keyof typeof PostingType];


/**
 * 
 * @export
 * @interface ProjectApplicantCount
 */
export interface ProjectApplicantCount {
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'applicant': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'approved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'reserved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantCount
     */
    'canBeReserver': number;
}
/**
 * 
 * @export
 * @interface ProjectApplicantItem
 */
export interface ProjectApplicantItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'influencerId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'thumbnail': string | null;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluation': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'follow': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'enthusiasm': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'operatorComment': string | null;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'selectionStatus': number;
    /**
     * 最終確認 unconfirmed: 未確認 confirming: 確認中 confirmed_ok: 確認OK confirmed_ng: 確認NG
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'isFinalConfirmation'?: ProjectApplicantItemIsFinalConfirmationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isGoodQuality'?: boolean | null;
    /**
     * フォロワー数/チャンネル登録者数
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'followers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'following'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'impressions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reach'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reachRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'preservations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagements'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagementRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'frequency'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reply'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'repost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'goodAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'shareAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'views'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'viewRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'commentsAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'highEvaluationAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'mediaCount'?: number | null;
}

export const ProjectApplicantItemIsFinalConfirmationEnum = {
    Unconfirmed: 'unconfirmed',
    Confirming: 'confirming',
    ConfirmedOk: 'confirmed_ok',
    ConfirmedNg: 'confirmed_ng'
} as const;

export type ProjectApplicantItemIsFinalConfirmationEnum = typeof ProjectApplicantItemIsFinalConfirmationEnum[keyof typeof ProjectApplicantItemIsFinalConfirmationEnum];

/**
 * 
 * @export
 * @interface ProjectCalculateReward
 */
export interface ProjectCalculateReward {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectCalculateReward
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'standard': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'minimum': number;
}
/**
 * 
 * @export
 * @interface ProjectDetailHeader
 */
export interface ProjectDetailHeader {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectName': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectDetailHeader
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectDetailHeader
     */
    'projectStatus': ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleStartedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleEndedAt': string | null;
    /**
     * 
     * @type {FeedbackStatus}
     * @memberof ProjectDetailHeader
     */
    'feedbackStatus': FeedbackStatus | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerName': string | null;
}


/**
 * 
 * @export
 * @interface ProjectDetailMediaInput
 */
export interface ProjectDetailMediaInput {
    /**
     * 
     * @type {Array<File>}
     * @memberof ProjectDetailMediaInput
     */
    'uploads'?: Array<File>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDetailMediaInput
     */
    'deleteIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectEstimate
 */
export interface ProjectEstimate {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectEstimate
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {ProjectEstimateItemGroups}
     * @memberof ProjectEstimate
     */
    'items': ProjectEstimateItemGroups;
    /**
     * オプション選択肢配列
     * @type {Array<ProjectOptionChoice>}
     * @memberof ProjectEstimate
     */
    'optionChoices': Array<ProjectOptionChoice>;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'taxRate': number;
    /**
     * 
     * @type {FractionType}
     * @memberof ProjectEstimate
     */
    'fractionType': FractionType;
    /**
     * 
     * @type {ProjectCalculateReward}
     * @memberof ProjectEstimate
     */
    'calculateReward': ProjectCalculateReward;
}


/**
 * 
 * @export
 * @interface ProjectEstimateItem
 */
export interface ProjectEstimateItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectEstimateItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'counts': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ProjectEstimateItemGroups
 */
export interface ProjectEstimateItemGroups {
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'fixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFreeItems': Array<ProjectEstimateItem>;
}
/**
 * 
 * @export
 * @interface ProjectImageRow
 */
export interface ProjectImageRow {
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'objectKey': string;
}
/**
 * 
 * @export
 * @interface ProjectKpi
 */
export interface ProjectKpi {
    /**
     * KPI項目配列
     * @type {Array<ProjectKpiItem>}
     * @memberof ProjectKpi
     */
    'items': Array<ProjectKpiItem>;
}
/**
 * 
 * @export
 * @interface ProjectKpiItem
 */
export interface ProjectKpiItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'comment': string;
    /**
     * POST時isFixedは指定しなくてもtrueになります。
     * @type {boolean}
     * @memberof ProjectKpiItem
     */
    'isFixed': boolean;
}
/**
 * 
 * @export
 * @interface ProjectOptionChoice
 */
export interface ProjectOptionChoice {
    /**
     * 
     * @type {string}
     * @memberof ProjectOptionChoice
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectOptionChoice
     */
    'price': number;
}
/**
 * 最終確定日など期間ではないものはfromにnullを設定する
 * @export
 * @interface ProjectSchedule
 */
export interface ProjectSchedule {
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'to': string | null;
}
/**
 * 案件スケジュールステータス
 * @export
 * @enum {string}
 */

export const ProjectScheduleStatus = {
    Before: 'before',
    During: 'during',
    Ending: 'ending'
} as const;

export type ProjectScheduleStatus = typeof ProjectScheduleStatus[keyof typeof ProjectScheduleStatus];


/**
 * <table> <tr><td>募集内容作成（下書き）</td><td>draft</td></tr> <tr><td>募集内容作成（申請中）</td><td>review</td></tr> <tr><td>募集〜選考</td><td>approved</td></tr> <tr><td>最終確認</td><td>final_confirmation</td></tr> <tr><td>実施中</td><td>implementation</td></tr> <tr><td>完了</td><td>completion</td></tr> <tr><td>募集完了(PRST)</td><td>accept_completion</td></tr> <tr><td>配信詳細作成（下書き）</td><td>post_draft</td></tr> <tr><td>配信詳細作成（申請中）</td><td>post_review</td></tr> <tr><td>配信待ち(EMERALD POST)</td><td>wait_post</td></tr> <tr><td>配信中(EMERALD POST)</td><td>posting</td></tr> <tr><td>配信完了(EMERALD POST)</td><td>post_completion</td></tr> </table>
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Draft: 'draft',
    Review: 'review',
    Approved: 'approved',
    FinalConfirmation: 'final_confirmation',
    WaitPost: 'wait_post',
    Implementation: 'implementation',
    PostDraft: 'post_draft',
    PostReview: 'post_review',
    Posting: 'posting',
    Completion: 'completion',
    PostCompletion: 'post_completion',
    AcceptCompletion: 'accept_completion'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface ProjectSummaryApplicationCondition
 */
export interface ProjectSummaryApplicationCondition {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minFollowers': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'gender': ProjectSummaryApplicationConditionGenderEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAge': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAge': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryApplicationCondition
     */
    'prefectures': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'job': number | null;
}

export const ProjectSummaryApplicationConditionGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProjectSummaryApplicationConditionGenderEnum = typeof ProjectSummaryApplicationConditionGenderEnum[keyof typeof ProjectSummaryApplicationConditionGenderEnum];

/**
 * 
 * @export
 * @interface ProjectSummaryBase
 */
export interface ProjectSummaryBase {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'adoptionPlan': number;
    /**
     * 0:適正相場 1:カスタム設定 （Spirit以外では無視されます）
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewardsType': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewards': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryBase
     */
    'genres': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryBase
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDetails
 */
export interface ProjectSummaryDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryDetails
     */
    'notice': Array<string> | null;
    /**
     * true:非公開 false:公開
     * @type {boolean}
     * @memberof ProjectSummaryDetails
     */
    'isSecret': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDetails
     */
    'companyName': string;
}
/**
 * 
 * @export
 * @interface ProjectTitleInput
 */
export interface ProjectTitleInput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTitleInput
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTitleInput
     */
    'tags': Array<string>;
    /**
     * 
     * @type {File}
     * @memberof ProjectTitleInput
     */
    'featuredImage'?: File;
}
/**
 * 案件タイプ
 * @export
 * @enum {string}
 */

export const ProjectType = {
    Spirit: 'spirit',
    EmeraldPost: 'emerald_post',
    Prst: 'prst',
    JaneJohn: 'jane_john',
    Other: 'other'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * 
 * @export
 * @interface ProjectTypeItem
 */
export interface ProjectTypeItem {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeItem
     */
    'no': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'endDate': string | null;
}
/**
 * 
 * @export
 * @interface ProjectTypeListCounts
 */
export interface ProjectTypeListCounts {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'draft': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'review': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'approved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'finalConfirmation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'implementation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'completion': number;
}
/**
 * SNSタイプ
 * @export
 * @enum {string}
 */

export const SnsType = {
    Instagram: 'instagram',
    X: 'x',
    TikTok: 'tik_tok',
    Youtube: 'youtube',
    Other: 'other'
} as const;

export type SnsType = typeof SnsType[keyof typeof SnsType];


/**
 * 
 * @export
 * @interface UploadedImageOutput
 */
export interface UploadedImageOutput {
    /**
     * 
     * @type {string}
     * @memberof UploadedImageOutput
     */
    'uploadedFilePath'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定したメンバーをチームから削除します。<br>管理者権限を持つユーザーのみ実行可能です。
         * @summary メンバー削除API
         * @param {string} memberId メンバーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMember: async (memberId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('deleteMember', 'memberId', memberId)
            const localVarPath = `/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログイン中のユーザーの情報を取得します。 <br>auth0に登録されていても、システムにユーザー未登録の場合はnullを返却します。
         * @summary アカウント取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したメンバーの詳細情報を返却します。
         * @summary メンバー詳細取得API
         * @param {string} memberId メンバーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember: async (memberId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('getMember', 'memberId', memberId)
            const localVarPath = `/members/{memberId}`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 選択しているチームのメンバーの一覧を返却します。
         * @summary メンバー一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/members`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログイン中のユーザーの情報を更新します。
         * @summary アカウント更新API
         * @param {PatchAccountInput} patchAccountInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAccount: async (patchAccountInput: PatchAccountInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'patchAccountInput' is not null or undefined
            assertParamExists('patchAccount', 'patchAccountInput', patchAccountInput)
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchAccountInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 現在選択中のチーム内の指定したメンバーのロールを変更します。<br> 管理者権限を持つユーザーのみ実行可能です。
         * @summary メンバーロール更新API
         * @param {string} memberId メンバーID
         * @param {PatchMemberRoleInput} patchMemberRoleInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMemberRole: async (memberId: string, patchMemberRoleInput: PatchMemberRoleInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'memberId' is not null or undefined
            assertParamExists('patchMemberRole', 'memberId', memberId)
            // verify required parameter 'patchMemberRoleInput' is not null or undefined
            assertParamExists('patchMemberRole', 'patchMemberRoleInput', patchMemberRoleInput)
            const localVarPath = `/members/{memberId}/role`
                .replace(`{${"memberId"}}`, encodeURIComponent(String(memberId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchMemberRoleInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * アカウント情報を登録するAPIです
         * @summary アカウント情報登録API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccount: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ログイン中のアカウントの画像をアップロードします。
         * @summary アカウント画像アップロードAPI
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountUploadImage: async (file: File, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'file' is not null or undefined
            assertParamExists('postAccountUploadImage', 'file', file)
            const localVarPath = `/accounts/upload-image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定したメンバーをチームから削除します。<br>管理者権限を持つユーザーのみ実行可能です。
         * @summary メンバー削除API
         * @param {string} memberId メンバーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMember(memberId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMember(memberId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteMember']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ログイン中のユーザーの情報を取得します。 <br>auth0に登録されていても、システムにユーザー未登録の場合はnullを返却します。
         * @summary アカウント取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAccount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetAccountOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAccount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定したメンバーの詳細情報を返却します。
         * @summary メンバー詳細取得API
         * @param {string} memberId メンバーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMember(memberId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Member>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMember(memberId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMember']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 選択しているチームのメンバーの一覧を返却します。
         * @summary メンバー一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMembers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<MyMember>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMembers(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getMembers']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ログイン中のユーザーの情報を更新します。
         * @summary アカウント更新API
         * @param {PatchAccountInput} patchAccountInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchAccount(patchAccountInput: PatchAccountInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchAccount(patchAccountInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 現在選択中のチーム内の指定したメンバーのロールを変更します。<br> 管理者権限を持つユーザーのみ実行可能です。
         * @summary メンバーロール更新API
         * @param {string} memberId メンバーID
         * @param {PatchMemberRoleInput} patchMemberRoleInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMemberRole(memberId: string, patchMemberRoleInput: PatchMemberRoleInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMemberRole(memberId, patchMemberRoleInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchMemberRole']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * アカウント情報を登録するAPIです
         * @summary アカウント情報登録API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccount(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccount(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * ログイン中のアカウントの画像をアップロードします。
         * @summary アカウント画像アップロードAPI
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postAccountUploadImage(file: File, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UploadedImageOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postAccountUploadImage(file, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postAccountUploadImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 指定したメンバーをチームから削除します。<br>管理者権限を持つユーザーのみ実行可能です。
         * @summary メンバー削除API
         * @param {string} memberId メンバーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMember(memberId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.deleteMember(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * ログイン中のユーザーの情報を取得します。 <br>auth0に登録されていても、システムにユーザー未登録の場合はnullを返却します。
         * @summary アカウント取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(options?: any): AxiosPromise<GetAccountOutput> {
            return localVarFp.getAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したメンバーの詳細情報を返却します。
         * @summary メンバー詳細取得API
         * @param {string} memberId メンバーID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMember(memberId: string, options?: any): AxiosPromise<Member> {
            return localVarFp.getMember(memberId, options).then((request) => request(axios, basePath));
        },
        /**
         * 選択しているチームのメンバーの一覧を返却します。
         * @summary メンバー一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMembers(options?: any): AxiosPromise<Array<MyMember>> {
            return localVarFp.getMembers(options).then((request) => request(axios, basePath));
        },
        /**
         * ログイン中のユーザーの情報を更新します。
         * @summary アカウント更新API
         * @param {PatchAccountInput} patchAccountInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchAccount(patchAccountInput: PatchAccountInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchAccount(patchAccountInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 現在選択中のチーム内の指定したメンバーのロールを変更します。<br> 管理者権限を持つユーザーのみ実行可能です。
         * @summary メンバーロール更新API
         * @param {string} memberId メンバーID
         * @param {PatchMemberRoleInput} patchMemberRoleInput 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMemberRole(memberId: string, patchMemberRoleInput: PatchMemberRoleInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchMemberRole(memberId, patchMemberRoleInput, options).then((request) => request(axios, basePath));
        },
        /**
         * アカウント情報を登録するAPIです
         * @summary アカウント情報登録API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccount(options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postAccount(options).then((request) => request(axios, basePath));
        },
        /**
         * ログイン中のアカウントの画像をアップロードします。
         * @summary アカウント画像アップロードAPI
         * @param {File} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postAccountUploadImage(file: File, options?: any): AxiosPromise<UploadedImageOutput> {
            return localVarFp.postAccountUploadImage(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 指定したメンバーをチームから削除します。<br>管理者権限を持つユーザーのみ実行可能です。
     * @summary メンバー削除API
     * @param {string} memberId メンバーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMember(memberId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteMember(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログイン中のユーザーの情報を取得します。 <br>auth0に登録されていても、システムにユーザー未登録の場合はnullを返却します。
     * @summary アカウント取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getAccount(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したメンバーの詳細情報を返却します。
     * @summary メンバー詳細取得API
     * @param {string} memberId メンバーID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMember(memberId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMember(memberId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 選択しているチームのメンバーの一覧を返却します。
     * @summary メンバー一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getMembers(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getMembers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログイン中のユーザーの情報を更新します。
     * @summary アカウント更新API
     * @param {PatchAccountInput} patchAccountInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchAccount(patchAccountInput: PatchAccountInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchAccount(patchAccountInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 現在選択中のチーム内の指定したメンバーのロールを変更します。<br> 管理者権限を持つユーザーのみ実行可能です。
     * @summary メンバーロール更新API
     * @param {string} memberId メンバーID
     * @param {PatchMemberRoleInput} patchMemberRoleInput 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchMemberRole(memberId: string, patchMemberRoleInput: PatchMemberRoleInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchMemberRole(memberId, patchMemberRoleInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * アカウント情報を登録するAPIです
     * @summary アカウント情報登録API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postAccount(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postAccount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ログイン中のアカウントの画像をアップロードします。
     * @summary アカウント画像アップロードAPI
     * @param {File} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postAccountUploadImage(file: File, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postAccountUploadImage(file, options).then((request) => request(this.axios, this.basePath));
    }
}



