import {
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { useEffect, useState } from 'react';

import { GetSpiritApplicantsRowsEnum } from '@/api/spirit/api';

import ArrowDropDown from '../Pic/ArrowDropDown.svg';

const MAX_PAGE_NUMBER = 7;
const MIN_PAGE_NUMBER = 1;

// 選択可能な1ページあたりに表示可能な行数
const availableRows = Object.keys(GetSpiritApplicantsRowsEnum).map((key) => {
  return GetSpiritApplicantsRowsEnum[key as keyof typeof GetSpiritApplicantsRowsEnum];
});

function convertRowEnumValue(value: string): GetSpiritApplicantsRowsEnum | null {
  const parsedValue = parseInt(value || '');
  if (isNaN(parsedValue)) return null;
  const selectRow = Object.values(GetSpiritApplicantsRowsEnum).find((val) => val == parsedValue);
  return selectRow ? selectRow : null;
}

export type PagenationProps = {
  totalPageCount: number;
  totalRows: number;
  getApplicantData: (rows?: GetSpiritApplicantsRowsEnum, page?: number) => void;
  applicantListCurrentPage: number;
  setApplicantListCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setPerPage?: React.Dispatch<React.SetStateAction<number>>;
};
// ページネーションコンポーネント
export default function Pagenation(props: PagenationProps) {
  const [selectPageRow, setSelectPageRow] = useState<GetSpiritApplicantsRowsEnum>(
    GetSpiritApplicantsRowsEnum.NUMBER_10
  );

  // 表示するページ番号の取得
  const getVisiblePages = () => {
    const visiblePages: number[] = [];

    // 全体のページ数が最大ページ以下の場合
    if (props.totalPageCount <= MAX_PAGE_NUMBER) {
      for (let i = MIN_PAGE_NUMBER; i <= props.totalPageCount; i++) {
        visiblePages.push(i);
      }
      return visiblePages;
    }

    // 選択中のページ数が最大ページ数以下の場合
    if (props.applicantListCurrentPage < MAX_PAGE_NUMBER) {
      for (let i = MIN_PAGE_NUMBER; i <= MAX_PAGE_NUMBER; i++) {
        visiblePages.push(i);
      }
      return visiblePages;
    }

    const endPage =
      props.totalPageCount == props.applicantListCurrentPage
        ? props.applicantListCurrentPage
        : props.applicantListCurrentPage + 1;
    const startPage = endPage - MAX_PAGE_NUMBER + 1;

    for (let i = startPage; i <= endPage; i++) {
      visiblePages.push(i);
    }

    return visiblePages;
  };

  const handlePageFirst = () => {
    props.setApplicantListCurrentPage(MIN_PAGE_NUMBER);
  };

  const handlePageDown = () => {
    if (props.applicantListCurrentPage != MIN_PAGE_NUMBER) {
      props.setApplicantListCurrentPage(props.applicantListCurrentPage - 1);
    }
  };

  const handlePageUp = () => {
    if (props.applicantListCurrentPage != props.totalPageCount) {
      props.setApplicantListCurrentPage(props.applicantListCurrentPage + 1);
    }
  };

  const handlePageLast = () => {
    props.setApplicantListCurrentPage(props.totalPageCount);
  };

  // 表示ページ変更処理
  useEffect(() => {
    const queryString = new URLSearchParams(window.location.search);
    queryString.set('rows', selectPageRow.toString());
    queryString.set('page', props.applicantListCurrentPage.toString());
    history.pushState(null, '', `?${queryString.toString()}`);
    props.getApplicantData(selectPageRow, props.applicantListCurrentPage);
  }, [selectPageRow, props.applicantListCurrentPage]);

  return (
    <div className="flex items-center justify-between rounded-b-lg border-t border-gray-200 bg-white px-4 py-1 sm:px-6">
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between">
        <div className="flex flex-row items-center gap-2">
          <span className="text-xs font-normal leading-4 text-[#374151]">１ページあたりの行数:</span>
          <div className="text-xs font-normal leading-4 sm:col-span-3">
            <select
              id="perPage"
              name="perPage"
              className="appearance-none border-none pr-8 text-xs font-normal leading-4"
              style={{ backgroundImage: `url(${ArrowDropDown})`, backgroundSize: '10px auto' }}
              defaultValue={selectPageRow}
              onChange={(e) => {
                const selectValue = convertRowEnumValue(e.target.value);
                if (selectValue) {
                  setSelectPageRow(selectValue);
                  if (props.setPerPage) {
                    props.setPerPage(selectValue as number);
                  }
                  // 行数を変更した場合は最初のページに戻る
                  props.setApplicantListCurrentPage(MIN_PAGE_NUMBER);
                }
              }}
            >
              {availableRows.map((val, index) => {
                return (
                  <option key={index} value={val}>
                    {val}
                  </option>
                );
              })}
            </select>
          </div>
          <span className="text-xs font-normal text-[#374151]">
            {`${props.applicantListCurrentPage * selectPageRow - (selectPageRow - 1)}-${
              props.applicantListCurrentPage * selectPageRow
            } of ${props.totalRows}`}
          </span>
        </div>
        {props.totalPageCount > MIN_PAGE_NUMBER && (
          <div>
            <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
              <button
                onClick={handlePageFirst}
                className={clsx(
                  'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                  props.applicantListCurrentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronDoubleLeftIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.applicantListCurrentPage == 1 ? '#D1D5DB' : '#6B7280'}
                />
              </button>
              <button
                onClick={handlePageDown}
                className={clsx(
                  'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                  props.applicantListCurrentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronLeftIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.applicantListCurrentPage == 1 ? '#D1D5DB' : '#6B7280'}
                />
              </button>
              {getVisiblePages().map((num) => (
                <button
                  key={num}
                  className={`relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0 ${
                    num == props.applicantListCurrentPage ? 'border border-[#007CC2] bg-[#E0F4FF]' : ''
                  }`}
                  onClick={() => {
                    props.setApplicantListCurrentPage(num);
                  }}
                >
                  <span className="text-sm font-medium leading-5 text-gray-500">{num}</span>
                </button>
              ))}
              <button
                onClick={handlePageUp}
                className={clsx(
                  'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                  props.applicantListCurrentPage == props.totalPageCount
                    ? ''
                    : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.applicantListCurrentPage == props.totalPageCount ? '#D1D5DB' : '#6B7280'}
                />
              </button>
              <button
                onClick={handlePageLast}
                className={clsx(
                  'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                  props.applicantListCurrentPage == props.totalPageCount
                    ? ''
                    : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
                )}
              >
                <ChevronDoubleRightIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill={props.applicantListCurrentPage == props.totalPageCount ? '#D1D5DB' : '#6B7280'}
                />
              </button>
            </nav>
          </div>
        )}
      </div>
    </div>
  );
}
