import * as Sentry from '@sentry/react';
import { AxiosResponse } from 'axios';

import {
  AffectOutput,
  DefaultApi,
  GetInfluencerSnsAccountOutput,
  PatchInfluencerBonusInput,
  PutInfluencerEvaluationInput,
  PatchInfluencerFollowOutput,
} from '@/api/influencer/api';
import { axiosInstance } from '@/utils/axiosInstance';

/**
 * インフルエンサー応募者詳細取得
 */
export const fetchInfluencerApplicantDetail = async (applicantId: string): Promise<GetInfluencerSnsAccountOutput> => {
  let response: AxiosResponse<GetInfluencerSnsAccountOutput> = {} as AxiosResponse<GetInfluencerSnsAccountOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.getApplyingInfluencerDetail(applicantId);
    return response.data as GetInfluencerSnsAccountOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * 気になるAPI
 */
export const patchInfluencerFollow = async (
  projectId: string,
  applyingInfluencerIds: string[]
): Promise<PatchInfluencerFollowOutput> => {
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    const response: AxiosResponse<PatchInfluencerFollowOutput> = await api.patchInfluencerFollow(projectId, {
      applyingInfluencerIds,
    });
    return response.data;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * インフルエンサー評価
 */
export const putInfluencerEvaluation = async (
  adoptedInfluencerId: string,
  request: PutInfluencerEvaluationInput
): Promise<AffectOutput> => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.putInfluencerEvaluation(adoptedInfluencerId, request);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};

/**
 * インフルエンサー評価API
 */
export const patchInfluencerBonus = async (
  snsAccountId: string,
  patchInfluencerBonusInput?: PatchInfluencerBonusInput
): Promise<AffectOutput> => {
  let response: AxiosResponse<AffectOutput> = {} as AxiosResponse<AffectOutput>;
  try {
    const api = new DefaultApi(undefined, undefined, axiosInstance);
    response = await api.patchInfluencerBonus(snsAccountId, patchInfluencerBonusInput);
    return response.data as AffectOutput;
  } catch (error: any) {
    Sentry.captureException(error);
    // Errorをキャッチしないとそのままthrowされるので、エラーをハンドリングした場合はここでキャッチしてthrowする
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
