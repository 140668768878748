import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { ENV_PREFIX, PROJECT_TYPES } from '@/config/index';
import { ROUTES } from '@/config/path';
interface sidebarProps {
  selected: string;
}
export function SecondSideBarAnalysis(props: sidebarProps): React.ReactElement {
  return (
    <>
      <div className="top-0 h-screen w-[196px] border-r border-gray-200 bg-gray-100">
        <div className="h-[432px] w-[196px]">
          <div className="w-[196px] border-b border-gray-200 p-[16px]">
            <p className="text-lg font-bold leading-7 text-[#000000]">分析</p>
          </div>
          <div className="w-[196px] px-[8px] pt-[16px]">
            <div className="mb-[4px] p-[8px]">
              <p className="text-[14px] font-semibold leading-5 text-gray-500">案件結果分析</p>
            </div>
            {/* <Link to={ROUTES.PROJECT_ANALYSIS_EMERALD_POST}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected === PROJECT_TYPES.EMERALD_POST ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">EMERALD POST</p>
                <p className="text-xs font-normal leading-4 text-gray-400">広報</p>
              </div>
            </Link> */}
            <Link to={ROUTES.PROJECT_ANALYSIS_SPIRIT}>
              <div
                className={clsx(
                  'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                  props.selected === PROJECT_TYPES.SPIRIT ? 'rounded-md bg-[#141730]/[.08]' : ''
                )}
              >
                <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">SPIRIT</p>
                <p className="text-xs font-normal leading-4 text-gray-400">広告</p>
              </div>
            </Link>
            {/* リリースの際に条件式を外す */}
            {process.env.REACT_APP_ENV_PREFIX !== ENV_PREFIX.PRODUCTION && (
              <Link to={ROUTES.PROJECT_ANALYSIS_OTHER}>
                <div
                  className={clsx(
                    'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                    props.selected === PROJECT_TYPES.OTHER ? 'rounded-md bg-[#141730]/[.08]' : ''
                  )}
                >
                  <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">その他の案件</p>
                </div>
              </Link>
            )}
            {/* リリースの際に条件式を外す */}
            {process.env.REACT_APP_ENV_PREFIX !== ENV_PREFIX.PRODUCTION && (
              <Link to={ROUTES.PROJECT_ANALYSIS_JANE_JOHN}>
                <div
                  className={clsx(
                    'mb-[4px] flex items-center justify-start py-[8px] pl-[24px] pr-[12px] hover:rounded-md hover:bg-[#141730]/[.08]',
                    props.selected === PROJECT_TYPES.JANE_JOHN ? 'rounded-md bg-[#141730]/[.08]' : ''
                  )}
                >
                  <p className="mr-[4px] text-[14px] font-semibold leading-5 text-gray-500">JANE JOHN</p>
                  <p className="text-xs font-normal leading-4 text-gray-400">調査</p>
                </div>
              </Link>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
