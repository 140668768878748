/* eslint-disable tailwindcss/no-custom-classname */
//import { useState } from 'react';

import { useState } from 'react';
import { Link } from 'react-router-dom';

import Steps from '../components/Steps';

export function MakeTeam(): React.ReactElement {
  const [teamName, setTeamName] = useState('');

  return (
    <>
      <div className="container">
        <div className="flex h-[40px] w-[1440px] justify-center bg-black">
          <p className="h-[29px] w-[82px] pt-1.5 text-xl font-extrabold leading-7 text-white">Influfect</p>
        </div>

        <div className="flex w-[1460px] flex-row justify-center pt-[37px]">
          <div className="order-1">{Steps()}</div>
        </div>

        <div className="flex w-[1460px] justify-center pt-[32px]">
          <p className="text-3xl font-bold leading-9">チームを作成しましょう</p>
        </div>

        <div className="flex w-[1460px] justify-center pt-[16px]">
          <p className="h-[40px] w-[480px] text-sm font-normal leading-5">
            会社単位、広報チーム単位など、SNSマーケティングが管理しやすいグループでチームを作りましょう
          </p>
        </div>

        <div className="flex w-[1460px] justify-center pt-[32px]">
          <div>
            <div>
              <label htmlFor="teamName" className="block text-sm font-semibold leading-6 text-gray-900">
                チーム名
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="teamName"
                  id="teamName"
                  className="block h-[38px] w-[480px] rounded-md border-0 px-[13px] py-[9px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="チーム名"
                  value={teamName}
                  onChange={(e) => {
                    setTeamName(e.target.value);
                  }}
                />
              </div>
              <p className="mt-2 text-sm text-gray-500" id="email-description">
                後ほど設定変更可能です。自由につけてみましょう
              </p>
            </div>
          </div>
        </div>

        <div className="flex w-[1460px] justify-center pt-[48px]">
          <div className="h-[38px] w-[62px] font-medium last-of-type:ml-[418px]">
            <Link to="../../ask_team" state={{ teamName: teamName }}>
              <input
                className="rounded-md border bg-sky-600 px-[17px] py-[9px] text-sm text-white"
                style={{ background: '#007CC2' }}
                type="submit"
                value="次へ"
              />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}
