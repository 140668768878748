import { getImageUrlIfRelative } from '@/utils/image';
import { isInstagram, isTikTok, isTwitter, isYouTube } from '@/utils/sns';

import { InstagramContent, SNSContent, TikTokContent, XContent, YoutubeContent } from './modalInterface';

export interface MediaData {
  mediaType: 'image' | 'video' | 'carousel' | 'multipleMedia' | null;
  sources: string[];
}

// SNSContentとsnsTypeからメディアの種類とソースを取得する
export const getMediaData = (snsType: string, content: SNSContent): MediaData => {
  if (isInstagram(snsType)) {
    const instagramContent = content as InstagramContent;
    switch (instagramContent.mediaType) {
      case 'IMAGE':
        return { mediaType: 'image', sources: [instagramContent.postImage || ''] };
      case 'VIDEO':
        return { mediaType: 'video', sources: [instagramContent.postVideo || ''] };
      case 'CAROUSEL_ALBUM':
        return { mediaType: 'carousel', sources: instagramContent.carouselImages || [] };
      default:
        return { mediaType: null, sources: [] };
    }
  } else if (isTikTok(snsType)) {
    const tikTokContent = content as TikTokContent;
    return { mediaType: 'image', sources: [tikTokContent.postImage || ''] };
  } else if (isTwitter(snsType)) {
    const xContent = content as XContent;
    const postImage = xContent.postImage ? getImageUrlIfRelative(xContent.postImage) : '';
    const postVideo = xContent.postVideo ? getImageUrlIfRelative(xContent.postVideo) : '';
    const multiMediaImages = xContent.multiMediaImages || [];
    const urlMultiMediaImages = multiMediaImages ? multiMediaImages.map((image) => getImageUrlIfRelative(image)) : [];
    switch (xContent.mediaType) {
      case 'photo':
        return { mediaType: 'image', sources: [postImage] };
      case 'video':
        return { mediaType: 'video', sources: [postVideo] };
      case 'multipleMedia':
        return { mediaType: 'multipleMedia', sources: urlMultiMediaImages };
      default:
        return { mediaType: null, sources: [] };
    }
  } else if (isYouTube(snsType)) {
    const youtubeContent = content as YoutubeContent;
    return { mediaType: 'image', sources: [youtubeContent.postImage || ''] };
  } else {
    return { mediaType: null, sources: [] };
  }
};

export const addCommasToNumberString = (input: string): string => {
  // 数字に変換
  const number = Number(input);

  // 数字かどうかを確認
  if (isNaN(number)) {
    return 'Invalid input: not a number';
  }

  // 数字をカンマ区切りでフォーマット
  return number.toLocaleString();
};
