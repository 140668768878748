import clsx from 'clsx';
import * as React from 'react';

import { Spinner } from '@/components/Elements/Spinner';

const variants = {
  primary: 'bg-[#007CC2] text-white shadow-sm rounded-md',
  secoundary: 'bg-white text-[#007CC2] border-[#007CC2] shadow-sm rounded-md',
  white: 'bg-white text-gray-700 border-gray-300 shadow-sm rounded-md',
  text: 'bg-transparent text-[#007CC2] border-0 shadow-none drop-shadow-none px-0',
  textGray: 'bg-transparent text-gray-500 border-0 shadow-none drop-shadow-none px-0',
  red: 'bg-red-600 text-white shadow-sm',
  icon: 'rounded-full py-2 px-2',
};

const hovers = {
  primary: 'hover:bg-[#006199] hover:text-white',
  secoundary: 'hover:bg-white hover:text-[#006199] hover:border-[#006199]',
  white: 'hover:bg-gray-50  hober:text-gray-700 hover:border-gray-300',
  text: 'hover:text-[#006199]',
  textGray: 'hover:text-gray-600',
  red: 'hover:bg-red-700 hover:text-white',
  icon: 'hover:bg-gray-100',
};

const actions = {
  primary: 'active:bg-[#007CC2] shadow-sm active:ring-2',
  secoundary: 'active:bg-white active:border-[#007CC2] active:ring-2 active:text-[#007CC2]',
  white: 'active:bg-white active:border-gray-300 active:ring-2',
  text: 'active:text-[##007CC2] active:ring-2 active:rounded-full',
  textGray: 'active:text-gray-500 active:ring-2 active:rounded-full',
  red: 'active:bg-red-600 active:text-white active:ring-2',
  icon: 'active:bg-white active:ring-2',
};

const disalbles = {
  primary: 'disabled:bg-gray-300 disabled:text-white',
  secoundary: 'disabled:bg-white disabled:border-gray-300 disabled:text-gray-300',
  white: 'disabled:bg-white disabled:border-gray-300 disabled:text-gray-300',
  text: 'disabled:text-gray-300',
  textGray: 'disabled:text-gray-300',
  red: 'disabled:bg-gray-300 disabled:text-white',
  icon: '',
};

const sizes = {
  sm: 'py-2 px-4 text-sm',
  md: 'py-2 px-6 text-md',
  lg: 'py-3 px-8 text-lg',
  smNopadding: 'text-sm',
  mdNopadding: 'text-md',
  xsNopadding: 'text-xs',
};

type IconProps =
  | { startIcon: React.ReactElement; endIcon?: never }
  | { endIcon: React.ReactElement; startIcon?: never }
  | { endIcon?: undefined; startIcon?: undefined };

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  variant?: keyof typeof variants;
  size?: keyof typeof sizes;
  isLoading?: boolean;
} & IconProps;

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      type = 'button',
      className = '',
      variant = 'primary',
      size = 'md',
      isLoading = false,
      startIcon,
      endIcon,
      ...props
    },
    ref
  ) => {
    return (
      <button
        ref={ref}
        type={type}
        className={clsx(
          'flex items-center justify-center border font-medium focus:outline-none focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-white disabled:cursor-not-allowed disabled:ring-0',
          variant === 'icon' ? '' : sizes[size],
          variants[variant],
          hovers[variant],
          actions[variant],
          disalbles[variant],
          className
        )}
        {...props}
      >
        {isLoading && <Spinner size="sm" className="text-current" />}
        {!isLoading && startIcon}
        <span className={variant === 'icon' ? '' : 'mx-2'}>{props.children}</span> {!isLoading && endIcon}
      </button>
    );
  }
);

Button.displayName = 'Button';
