// import * as CSS from 'csstype';
import React from 'react';

/* eslint-disable */
export const Header = React.forwardRef<HTMLElement>(() => {
  return (
    <div id="header" className="flex h-10 flex-col items-center bg-[#111827]">
      <div className="flex flex-row items-center justify-center pb-0 pl-1 pr-4 pt-1">
        <span className="align-bottom text-xl font-extrabold not-italic text-white">Influfect</span>
      </div>
    </div>
  );
});
/* eslint-enable */
