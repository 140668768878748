import clsx from 'clsx';
import { useEffect, useState, useRef } from 'react';

import { ReactComponent as ChenverDown } from '@/assets/icons/chevron-down.svg';
import { Badge } from '@/components/Elements/Badge';
import { PROJECT_TYPES } from '@/config';
import { projectsGanttChartType } from '@/types/project';

interface dayInformation {
  day: number;
  month: number;
  year: number;
  date: Date;
  isToday: boolean;
  isStartDay: boolean;
}

interface ganttChartInformation {
  projectGroupId: string;
  name: string;
  projectInformation: projectInfromation;
  isOpen: boolean;
}

interface projectInfromation {
  isOpen: boolean;
  project: projectsGanttChartType;
}

interface ganttChartProps {
  isEmpty: boolean;
  isHomeView: boolean;
  year?: number;
  month?: number;
}

export function GanttChart(props: ganttChartProps): React.ReactElement {
  const [daysInformation, setDaysInformation] = useState<dayInformation[]>([]);
  const moveRef_header = useRef<HTMLDivElement>(null);
  const moveRef_body = useRef<HTMLDivElement>(null);
  const [scrollLimit, setScrollLimit] = useState(0);
  const [ganttCharts, setGanttCharts] = useState<ganttChartInformation[]>([]);
  const chartWidth = props.isHomeView ? 'w-[1008px]' : 'w-[1149px]';
  const chartBodyWidth = props.isHomeView ? 'w-[481px]' : 'w-[622px]';

  const scroll_event = (e: any) => {
    console.log(e);
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
      moveRef_body.current.scrollLeft = e.target.scrollLeft < scrollLimit ? e.target.scrollLeft : scrollLimit;
      if (moveRef_body.current.scrollLeft + moveRef_body.current.clientWidth == moveRef_body.current.scrollWidth) {
        // 右端に到達したので、カレンダーの表示領域を増やす
        setDaysDataset(daysInformation[daysInformation.length - 1].date, 30, true);
      }
    }
  };

  const handleScrollReset = () => {
    if (moveRef_header.current && moveRef_body.current) {
      moveRef_header.current.scrollLeft = 0;
      moveRef_body.current.scrollLeft = 0;
    }
  };

  const setDaysDataset = (criteriaDate: Date, displayDays: number, isAddDays: boolean, startDate?: Date) => {
    const days: dayInformation[] = [];

    for (let i = 0; i <= displayDays; i++) {
      days.push({
        day: criteriaDate.getDate(),
        month: criteriaDate.getMonth() + 1 == 13 ? 1 : criteriaDate.getMonth() + 1,
        year: criteriaDate.getFullYear(),
        date: new Date(criteriaDate.getTime()),
        isToday: criteriaDate.toDateString() == new Date().toDateString(),
        isStartDay: startDate
          ? startDate.getFullYear() == criteriaDate.getFullYear() &&
            startDate.getMonth() == criteriaDate.getMonth() &&
            startDate.getDate() == criteriaDate.getDate()
          : false,
      });

      criteriaDate.setDate(criteriaDate.getDate() + 1);
    }
    if (isAddDays) {
      setScrollLimit(scrollLimit + 51 * days.length);
      setDaysInformation(daysInformation.concat(days));
    } else {
      setScrollLimit(51 * days.length);
      setDaysInformation(days);
    }
  };

  // TODO API ダミーデータ
  const dummyData = (): projectsGanttChartType[] => {
    const currentDate = new Date();
    currentDate.setMonth(currentDate.getMonth() + 1);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1 == 13 ? 1 : currentDate.getMonth() + 1;
    return [
      {
        projectGroupId: '1',
        name: '2023 新生活応援キャンペーン',
        projects: [
          {
            projectId: 'p1',
            projectName: '商品PR投稿',
            projectType: PROJECT_TYPES.SPIRIT,
            schedules: [
              {
                label: '募集期間',
                from: `${currentYear}/${currentMonth}/1`,
                to: `${currentYear}/${currentMonth}/5`,
              },
              {
                label: '選定期間',
                from: `${currentYear}/${currentMonth}/6`,
                to: `${currentYear}/${currentMonth}/28`,
              },
            ],
          },
        ],
      },
      {
        projectGroupId: '2',
        name: '第1回 〇〇商品開発のためのリサーチ',
        projects: [
          {
            projectId: 'p2',
            projectName: '商品PR投稿',
            projectType: PROJECT_TYPES.EMERALD_POST,
            schedules: [
              {
                label: '募集期間',
                from: `${currentYear}/${currentMonth}/2`,
                to: `${currentYear}/${currentMonth}/6`,
              },
              {
                label: '選定期間',
                from: `${currentYear}/${currentMonth}/7`,
                to: `${currentYear}/${currentMonth}/12`,
              },
            ],
          },
        ],
      },
      {
        projectGroupId: '3',
        name: '〇〇PRプロジェクト',
        projects: [
          {
            projectId: 'p3',
            projectName: '商品PR投稿',
            projectType: PROJECT_TYPES.JANE_JOHN,
            schedules: [
              {
                label: '募集期間',
                from: `${currentYear}/${currentMonth}/2`,
                to: `${currentYear}/${currentMonth}/6`,
              },
              {
                label: '選定期間',
                from: `${currentYear}/${currentMonth}/7`,
                to: `${currentYear}/${currentMonth}/12`,
              },
            ],
          },
        ],
      },
    ];
  };

  const handleSwitchDisplay = (projectGroupId: string) => {
    setGanttCharts((prev) =>
      prev.map((project) => {
        if (project.projectGroupId === projectGroupId) {
          return {
            ...project,
            isOpen: !project.isOpen,
          };
        } else {
          return project;
        }
      })
    );
  };

  const handleSwitchDisplayChild = (projectGroupId: string) => {
    setGanttCharts((prev) =>
      prev.map((project) => {
        if (project.projectGroupId === projectGroupId) {
          return {
            ...project,
            projectInformation: {
              ...project.projectInformation,
              isOpen: !project.projectInformation.isOpen,
            },
          };
        } else {
          return project;
        }
      })
    );
  };

  const projectGroup = (index: number, label: string, isOpen: boolean, groupId: string) => {
    return (
      <div className="flex border-b border-gray-200">
        <div className="sticky left-0 h-[40px] w-[81px]">
          <div className="flex h-[40px] w-[81px] items-center justify-center self-stretch pb-[11px] pt-[9px] text-center">
            <span className="text-sm font-normal leading-5 text-gray-500">{index}</span>
          </div>
        </div>
        <div className="sticky left-[81px] h-[40px] w-[316px]">
          <div className="flex h-[40px] w-[316px] items-center justify-between">
            <span className="text-base font-normal leading-7 text-gray-500">{label}</span>
            <ChenverDown
              width={20}
              height={20}
              stroke="#374151"
              onClick={() => handleSwitchDisplay(groupId)}
              className={isOpen ? '' : '-rotate-90'}
            ></ChenverDown>
          </div>
        </div>
        <div className="sticky left-[395x] h-[40px] w-[130px]">
          <div className="h-[40px] w-[130px]"></div>
        </div>
        <div className="flex w-[622px] self-stretch">
          {daysInformation.map((day) => {
            return (
              <div className="h-[40px] w-[51px]" key={`${day.year}-${day.month}-${day.day}-${index}`}>
                <div className="w-[51px]"></div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const projectsElement = (ganttChart: ganttChartInformation, major: number) => {
    if (!ganttChart.isOpen) return <></>;
    return ganttChart.projectInformation.project.projects.map((project, index: number) => {
      return (
        <div className="flex flex-col" key={`${major}.${index + 1}`}>
          <div className="flex border-b border-gray-200">
            <div className="sticky left-0 z-[100] w-[81px] bg-white ">
              <div className="flex h-[40px] w-[81px] items-center justify-center self-stretch pb-[11px] pt-[9px] text-center">
                <span className="w-[81px] text-sm font-normal leading-5 text-gray-500">
                  {major}.{index + 1}
                </span>
              </div>
            </div>
            <div className="sticky left-[81px] z-[100] w-[316px]  bg-white">
              <div className="flex h-[40px] w-[316px] items-center justify-between">
                <span className="text-base font-normal leading-7 text-gray-500">{project.projectName}</span>
                <ChenverDown
                  width={20}
                  height={20}
                  stroke="#374151"
                  onClick={() => handleSwitchDisplayChild(ganttChart.projectGroupId)}
                  className={ganttChart.projectInformation.isOpen ? 'z-[100]' : 'z-[100]  -rotate-90'}
                ></ChenverDown>
              </div>
            </div>
            <div className="sticky left-[395px] z-[100] flex h-[40px]  w-[130px] items-center border-r border-gray-200  bg-white">
              <div className="w-[130px]">{projectTypeBadge(project.projectType)}</div>
            </div>
            <div
              className={clsx('flex overflow-auto border-gray-200 bg-white scrollbar-hide', chartWidth)}
              role="presentation"
              onScroll={(event) => scroll_event(event)}
            >
              {daysInformation.map((day) => {
                const firstDate = new Date(project.schedules[0].from ?? 0);
                const lastDate = new Date(project.schedules[project.schedules.length - 1].to ?? 0);
                return firstDate.getTime() <= day.date.getTime() && day.date.getTime() <= lastDate.getTime() ? (
                  perDay(
                    project.projectType,
                    day.date.toDateString() == firstDate.toDateString(),
                    day.date.toDateString() == lastDate.toDateString()
                  )
                ) : (
                  <div className="h-[40px] w-[51px] border-r border-gray-200">
                    <div className="w-[51px]"></div>
                  </div>
                );
              })}
            </div>
          </div>

          {ganttChart.projectInformation.isOpen &&
            project.schedules?.map((schedule, mainer: number) => {
              return (
                <div className="flex border-b border-gray-200" key={`${major}.${index + 1}.${mainer + 1}`}>
                  <div className="sticky left-0 z-[100] w-[81px] bg-white">
                    <div className=" flex h-[40px] w-[81px] items-center justify-center self-stretch pb-[11px] pt-[9px]  text-center">
                      <span className="w-[81px] text-sm font-normal leading-5 text-gray-500">
                        {major}.{index + 1}.{mainer + 1}
                      </span>
                    </div>
                  </div>
                  <div className="sticky left-[81px]  z-[100] w-[316px] bg-white">
                    <div className="flex h-[40px] w-[316px] items-center justify-between">
                      <span className="text-base font-normal leading-7 text-gray-500">{schedule.label}</span>
                    </div>
                  </div>
                  <div className="sticky left-[395px] z-[100] h-[40px] w-[130px] border-r border-gray-200 bg-white">
                    <div className="w-[130px]"></div>
                  </div>
                  <div className="flex h-[40px] items-center self-stretch border-r border-gray-200">
                    <span
                      className={clsx('flex overflow-auto border-gray-200 bg-white scrollbar-hide', chartWidth)}
                      onScroll={(event) => scroll_event(event)}
                    >
                      {daysInformation.map((day) => {
                        const firstDate = new Date(schedule.from ?? 0);
                        const lastDate = new Date(schedule.to ?? 0);
                        return firstDate.getTime() <= day.date.getTime() && day.date.getTime() <= lastDate.getTime() ? (
                          perDay(
                            project.projectType,
                            day.date.toDateString() == firstDate.toDateString(),
                            day.date.toDateString() == lastDate.toDateString()
                          )
                        ) : (
                          <div className="h-[40px] w-[51px] border-r border-gray-200">
                            <div className="h-[40px] w-[51px]"></div>
                          </div>
                        );
                      })}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      );
    });
  };

  const perDay = (projectType: string, isFirst: boolean, isLast: boolean) => {
    const backgroundColor =
      projectType === PROJECT_TYPES.EMERALD_POST
        ? 'bg-green-400 border border-green-400'
        : projectType === PROJECT_TYPES.SPIRIT
        ? 'bg-pink-400 border border-pink-400'
        : projectType === PROJECT_TYPES.JANE_JOHN
        ? 'bg-blue-400 border border-blue-400'
        : projectType === PROJECT_TYPES.PRST
        ? 'bg-yellow-400 border border-yellow-400'
        : 'bg-gray-400 border border-gray-400';
    return (
      <div className="relative flex h-[40px] w-[51px] items-center border-r border-gray-200">
        <div
          className={clsx(
            backgroundColor,
            isFirst ? 'rounded-l-[4px]' : '',
            isLast ? 'rounded-r-[4px]' : '',
            'absolute h-4 w-[51px]'
          )}
        ></div>
      </div>
    );
  };

  const projectTypeBadge = (projectType: string) => {
    const color =
      projectType === PROJECT_TYPES.EMERALD_POST
        ? 'green'
        : projectType === PROJECT_TYPES.SPIRIT
        ? 'pink'
        : projectType === PROJECT_TYPES.JANE_JOHN
        ? 'blue'
        : projectType === PROJECT_TYPES.PRST
        ? 'yellow'
        : 'gray';

    const width =
      projectType === PROJECT_TYPES.EMERALD_POST
        ? 'w-[116px]'
        : projectType === PROJECT_TYPES.SPIRIT
        ? 'w-[58px]'
        : projectType === PROJECT_TYPES.JANE_JOHN
        ? 'w-[116px]'
        : projectType === PROJECT_TYPES.PRST
        ? 'w-[58px]'
        : 'w-[58px]';

    return (
      <Badge color={color} size="sm" round="normal" className={width}>
        {projectType}
      </Badge>
    );
  };

  const displayGnatChart = () => {
    return (
      <div className={clsx('h-[369px] overflow-auto rounded-b-lg border-x border-gray-200 bg-white', chartWidth)}>
        {ganttCharts?.map((project, index) => {
          return (
            <div key={index} style={{ width: 51 * daysInformation.length }} ref={moveRef_body}>
              {projectGroup(index + 1, project.name, project.isOpen, project.projectGroupId)}
              {projectsElement(project, index + 1)}
            </div>
          );
        })}
      </div>
    );
  };

  /**
   * ガントチャート表示時に表示する日付情報を作成する
   */
  const handleCurrentMonth = () => {
    const displayDate = new Date();
    // 表示日の2日前をスタートとする
    displayDate.setDate(displayDate.getDate() - 2);

    const InitialDate = new Date();
    InitialDate.setFullYear(new Date().getFullYear());
    InitialDate.setMonth(new Date().getMonth());

    if (InitialDate.getDate() < displayDate.getDate()) {
      // displayDateは3日前の日付なので、 new Date().getDate()より数字が大きい場合、前月が表示されている。
      InitialDate.setDate(1);
    } else {
      InitialDate.setDate(displayDate.getDate());
    }

    const firstDate = new Date(displayDate.getFullYear(), displayDate.getMonth(), displayDate.getDate());
    const lastDate = new Date();
    lastDate.setMonth(lastDate.getMonth() + 3);
    // 表示する日数が作成される
    const displayDays = Math.floor((lastDate.getTime() - firstDate.getTime()) / 86400000);
    setDaysDataset(firstDate, displayDays, false, InitialDate);
    handleScrollReset();
  };

  useEffect(() => {
    if (props.year && props.month) {
      const displayMonthDate = new Date(props.year, props.month - 1, 1);
      const lastDate = new Date(props.year, props.month - 1, 0);
      lastDate.setMonth(lastDate.getMonth() + 3);
      const displayDays = Math.floor((lastDate.getTime() - displayMonthDate.getTime()) / 86400000);
      setDaysDataset(displayMonthDate, displayDays, false);
      handleScrollReset();
    } else {
      handleCurrentMonth();
      setGanttCharts(
        dummyData().map((project) => {
          return {
            projectGroupId: project.projectGroupId,
            name: project.name,
            projectInformation: { project: project, isOpen: false },
            isOpen: false,
          };
        })
      );
    }
  }, [props.year, props.month]);

  const emptyDataContent = () => {
    return (
      <div
        className={clsx('flex rounded-b-lg border-x border-b border-gray-200 bg-white', chartWidth)}
        onScroll={(event) => scroll_event(event)}
        ref={moveRef_body}
      >
        <div className="flex h-[552px] flex-1 items-center justify-center self-stretch">
          <span className="whitespace-pre-wrap text-center text-base font-normal leading-6 text-gray-500">
            {`まだ案件がありません。 \n右上のボタンから案件を作成してみましょう！`}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div id="gantt-chart" className={chartWidth}>
      <div className="flex flex-col items-center self-center">
        <div
          className={clsx(
            'flex items-start self-stretch overflow-auto rounded-t-lg border border-gray-200 bg-white scrollbar-hide',
            chartWidth
          )}
          ref={moveRef_header}
          onScroll={(event) => scroll_event(event)}
        >
          <div className="sticky left-0 h-[73px] w-[81px] rounded-tl-lg bg-white py-7 text-center">
            <span className="text-xs font-normal leading-4 text-gray-500">No</span>
          </div>
          <div className="sticky left-[81px] h-[73px] w-[316px]  items-start bg-white py-7">
            <span className="text-xs font-normal leading-4 text-gray-500">案件</span>
          </div>
          <div className="sticky left-[395px] h-[73px] w-[130px]  items-center border-r border-gray-200 bg-white py-7">
            <span className="text-xs font-normal leading-4 text-gray-500">タイプ</span>
          </div>
          <div className={clsx('flex h-[73px] rounded-tr-lg bg-white py-2 pl-[5px]', chartBodyWidth)}>
            {daysInformation.map((day, index) => {
              return (
                <div key={index} className="flex flex-col ">
                  <div className="h-[26px] w-[51px] whitespace-nowrap">
                    {(day.day == 1 || day.isStartDay) && (
                      <span className="text-base font-semibold  leading-7 text-gray-500">
                        {day.year}年{day.month}月
                      </span>
                    )}
                  </div>
                  <div className="flex self-stretch">
                    <div className="w-[51px]">
                      {day.isToday ? (
                        <div className="w-[51px]">
                          <div className="flex h-6 w-6 flex-col items-center justify-center rounded-[20px] bg-[#007CC2]">
                            <span className="text-sm font-medium leading-4 text-white">{day.day}</span>
                          </div>
                        </div>
                      ) : (
                        <div className="w-[51px]">
                          <span className="text-sm font-normal leading-5 text-gray-500">{day.day}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {props.isEmpty ? emptyDataContent() : displayGnatChart()}
      </div>
    </div>
  );
}
