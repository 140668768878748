import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as TrashIcon } from '@/assets/icons/bx-trash.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { TextArea } from '@/components/Elements/TextArea';

import { AlertContentsApprove } from './AlertContentsApprove';
import AvatarImage from './assets/Avatar2.png';
import AvatarCommentImage from './assets/AvatarComment.png';
import AvatarCommentImage2 from './assets/AvatarComment2.png';
import { ReactComponent as BookmarkIcon } from './assets/Bookmark.svg';
import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as BoldIcon } from './assets/bx-bold.svg';
import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
import ErrorImage from './assets/bx-error.svg';
import { ReactComponent as FontcolorIcon } from './assets/bx-font-color.svg';
import { ReactComponent as HeartIcon } from './assets/bx-heart.svg';
import { ReactComponent as ImageAddIcon } from './assets/bx-image-add.svg';
import { ReactComponent as ItalicIcon } from './assets/bx-italic.svg';
import { ReactComponent as MenuLeftIcon } from './assets/bx-menu-alt-left.svg';
import { ReactComponent as MessageIcon } from './assets/bx-message-rounded.svg';
import { ReactComponent as PaperPlaneIcon } from './assets/bx-paper-plane.svg';
import { ReactComponent as UnderlineIcon } from './assets/bx-underline.svg';
import CommentIcon from './assets/CommentIcon.png';
import { ReactComponent as RedDotIcon } from './assets/RedDot.svg';
import ReplyDetailImage from './assets/ReplyDetailImage.png';
import SampleMediaImage from './assets/SampleMediaImage.png';
import SampleMediaImage2 from './assets/SampleMediaImage2.png';
import { Steppanel_ContentsApproving } from './Steppanel_ContentsApproving';

export const PRSTProductionManagementContentsApprovingView = () => {
  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen1(true);
  };

  const [comment, setComment] = useState<boolean>(false);
  const handleClick3 = () => {
    setComment(true);
  };

  const [reply, setReply] = useState<boolean>(false);
  const handleClick4 = () => {
    setReply(true);
  };

  const [replyDetail, setReplyDetail] = useState<boolean>(false);
  const handleClick5 = () => {
    setReplyDetail(true);
  };
  const [del, setDel] = useState<boolean>(false);
  const handleClick6 = () => {
    setDel(true);
  };

  const EditContentsModal = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[40px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">コンテンツを編集</span>
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="mb-[32px] h-full w-[320px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">画像/動画</span>
                            </div>
                            <div className="flex w-[498px] items-center justify-between">
                              <div className="w-[211px]">
                                <div className="mb-[8px] flex w-[211px] justify-between">
                                  <span className="h-[19px] w-[35px] text-sm font-semibold leading-5 text-gray-700">
                                    1枚目
                                  </span>
                                  <div className="flex h-[28px] w-[64px] items-center justify-between">
                                    <ChevronLeftIcon width={28} height={28} fill="#E5E7EB" />
                                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                                  </div>
                                </div>
                                <div className="h-[209px] w-[211px]">
                                  <img src={SampleMediaImage2} alt="" className="h-[209px] w-[211px]" />
                                </div>
                              </div>
                              <div className="h-[168px] border-l border-gray-200"></div>
                              <div className="w-[239px]">
                                <div className="mb-[8px] flex w-[211px] justify-between">
                                  <span className="h-[19px] w-[35px] whitespace-nowrap text-sm font-semibold leading-5 text-gray-700">
                                    2枚目
                                  </span>
                                  <div className="flex h-[28px] w-[64px] items-center justify-between">
                                    <ChevronLeftIcon width={28} height={28} fill="#374151" />
                                    <ChevronRightIcon width={28} height={28} fill="#E5E7EB" />
                                  </div>
                                </div>
                                <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-300">
                                  <div className="h-[120px] w-[220px] pb-[4px]">
                                    <div className="flex items-center justify-center">
                                      <div className="flex h-[48px] w-[48px] items-center justify-center">
                                        <AddIcon width={36} height={36} />
                                      </div>
                                    </div>
                                    <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                      <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-gray-600">
                                          ドラッグ&ドロップもしくは
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                          アップロード
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <span className="text-xs font-normal leading-4 text-gray-500">
                                        対応ファイルはPNG, JPG, GIF,mp4
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="w-[693px]">
                          <div className="mb-[4px] h-[20px] w-[56px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                          </div>
                          <TextArea className="h-[192px] w-[693px] px-[16px] py-[8px]" helperText="#ハッシュタグ数 0" />
                          <button className="mt-[24px] flex items-center justify-start" onClick={handleClick6}>
                            <span className="text-sm font-medium leading-5 text-red-700">削除する</span>
                          </button>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen1(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen1(false)}
                      >
                        キャンセル
                      </Button>
                      <Link to="/PRST_production_management/contents_approving">
                        <Button
                          variant="primary"
                          size="md"
                          className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                          onClick={() => setOpen1(false)}
                        >
                          保存する
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const DeleteModal = () => {
    return (
      <Transition.Root show={del} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setDel}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="fixed inset-0 opacity-30 transition-opacity"
              style={{ background: 'rgba(107, 114, 128)' }}
            />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <div>
                      <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setDel(false)} />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="mr-[12px] h-[40px] w-[40px]">
                      <img src={ErrorImage} alt="" />
                    </div>
                    <div className=" sm:flex sm:items-start">
                      <div className="text-center sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="h-[24px] w-[408px] text-lg font-medium leading-6 text-gray-900"
                        >
                          コンテンツを削除してよろしいですか？
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm font-normal leading-5 text-gray-500">この作業は取り戻せません。</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                      style={{ background: '#DC2626' }}
                      onClick={() => setDel(false)}
                    >
                      削除する
                    </button>

                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={() => setDel(false)}
                    >
                      キャンセル
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const CommentWindow = () => {
    return (
      <>
        <div className="absolute left-[1036px] top-0 z-20 h-[840px] w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
          <div className="mb-[22px] flex w-full justify-end">
            <button className="mr-[0px]">
              <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setComment(false)} />
            </button>
          </div>
          <form className="h-[385px] w-[358px]">
            <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
              コンテンツへのコメント
            </label>
            <div className="my-[24px] h-[45px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
              <div className="h-[92px] w-[326px]">
                <textarea
                  className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                  placeholder="新規コメント"
                  defaultValue={''}
                />
              </div>
            </div>
            <div className="mb-[24px] h-[1px] w-[358px] border-t-[1px] border-[#DDDDDD]"></div>
            <div
              className={
                replyDetail
                  ? 'mb-[16px] h-[108px] w-[358px] px-[0px] py-[8px]'
                  : 'mb-[24px] h-[108px] w-[358px] px-[0px] py-[8px]'
              }
            >
              <div className="mb-[8px] flex h-[60px] w-[358px] items-start justify-between py-[8px]">
                <img src={AvatarCommentImage} alt="" />
                <div className="h-[44px] w-[322px]">
                  <div className="mb-[4px] flex h-[20px] w-[322px] items-center justify-between">
                    <div className="h-[20px] w-[108px]">
                      <span className="h-[20px] w-[108px] text-sm font-medium leading-5 text-gray-900">
                        Whitney Francis
                      </span>
                    </div>
                    <div className="h-[20px] w-[49px]">
                      <span className="h-[20px] w-[49px] text-sm font-normal leading-5 text-gray-500">1時間前</span>
                    </div>
                  </div>
                  <div className="h-[20px] w-[290px]">
                    <span className="h-[20px] w-[290px] text-sm font-normal leading-5 text-gray-700">
                      写真の加工をもう少し明るくしてください。
                    </span>
                  </div>
                </div>
              </div>
              {replyDetail ? (
                <button
                  type="button"
                  onClick={() => setReplyDetail(false)}
                  className="flex h-[24px] w-[116px] items-center justify-between"
                >
                  <ChevronDownIcon width={24} height={24} fill="#6B7280" />
                  <div className="flex h-[20px] w-[84px] items-center justify-between">
                    <div className="h-[20px] w-[84px] text-sm font-medium leading-5 text-gray-500">返信を閉じる</div>
                  </div>
                </button>
              ) : (
                <button
                  type="button"
                  onClick={handleClick5}
                  className="flex h-[24px] w-[109px] items-center justify-between"
                >
                  <ChevronDownIcon width={24} height={24} fill="#007CC2" />
                  <div className="flex h-[20px] w-[77px] items-center justify-between">
                    <div className="h-[20px] w-[65px] text-sm font-medium leading-5 text-[#007CC2]">返信（2）</div>
                    <RedDotIcon width={8} height={8} />
                  </div>
                </button>
              )}
            </div>
            {replyDetail ? (
              <>
                <div className="mb-[16px] flex h-[261px] w-[358px] justify-between py-[8px] pl-[24px]">
                  <img src={AvatarCommentImage} alt="" className="h-[24px] w-[24px]" />
                  <div className="h-[245px] w-[298px]">
                    <div className="mb-[4px] flex h-[20px] w-[298px] items-center justify-between">
                      <div className="h-[20px] w-[108px] text-sm font-medium leading-5 text-gray-900">
                        Whitney Francis
                      </div>
                      <div className="h-[20px] w-[15px] text-sm font-normal leading-5 text-gray-500">1h</div>
                    </div>
                    <div className="mb-[4px] h-[20px] w-[298px] text-sm font-normal leading-5 text-gray-700">
                      ここもレタッチ。
                    </div>
                    <div className="mb-[4px] h-[16px] w-[258px] text-xs font-normal leading-4 text-gray-500">
                      スクリーンショット 2023-03-17 12.00.22.png
                    </div>
                    <img src={ReplyDetailImage} alt="" />
                  </div>
                </div>
                <div className="mb-[16px] flex h-[80px] w-[358px] justify-between py-[8px] pl-[24px]">
                  <img src={AvatarCommentImage2} alt="" className="h-[24px] w-[24px]" />
                  <div className="h-[64px] w-[298px]">
                    <div className="mb-[4px] flex h-[20px] w-[298px] items-center justify-between">
                      <div className="h-[20px] w-[108px] text-sm font-medium leading-5 text-gray-900">
                        Whitney Francis
                      </div>
                      <div className="h-[20px] w-[15px] text-sm font-normal leading-5 text-gray-500">1h</div>
                    </div>
                    <div className="mb-[4px] h-[20px] w-[298px] text-sm font-normal leading-5 text-gray-700">
                      了解です。
                    </div>
                    <div className="flex h-[16px] w-[298px] items-center justify-end">
                      <div className="flex h-[16px] w-[48px] items-center justify-between">
                        <EditIcon width={16} height={16} fill="#6B7280" />
                        <TrashIcon width={16} height={16} fill="#6B7280" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-[16px] h-[92px] w-[358px] pl-[24px]">
                  <div className="mb-[8px] flex h-[60px] w-[334px] justify-between py-[8px]">
                    <img src={AvatarCommentImage2} alt="" className="h-[24px] w-[24px]" />
                    <div className="h-[44px] w-[298px]">
                      <div className="mb-[4px] flex h-[20px] w-[298px] items-center justify-between">
                        <div className="h-[20px] w-[108px] text-sm font-medium leading-5 text-gray-900">
                          Whitney Francis
                        </div>
                        <div className="h-[20px] w-[15px] text-sm font-normal leading-5 text-gray-500">1h</div>
                      </div>
                      <div className="mb-[8px] h-[20px] w-[298px] text-sm font-normal leading-5 text-gray-700">
                        ありがとうございます！
                      </div>
                    </div>
                  </div>
                  <div className="flex h-[24px] w-[60px] items-center justify-end">
                    <div className="h-[20px] w-[24px] whitespace-nowrap text-sm font-semibold leading-5 text-gray-500">
                      返信
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <div className="w-[358px] px-[0px] py-[8px]">
                <div className="mb-[8px] flex h-[60px] w-[358px] items-start justify-between py-[8px]">
                  <img src={AvatarCommentImage} alt="" />
                  <div className="h-[44px] w-[322px]">
                    <div className="mb-[4px] flex h-[20px] w-[322px] items-center justify-between">
                      <div className="h-[20px] w-[108px]">
                        <span className="h-[20px] w-[108px] text-sm font-medium leading-5 text-gray-900">
                          Whitney Francis
                        </span>
                      </div>
                      <div className="h-[20px] w-[135px]">
                        <span className="h-[20px] w-[135px] text-sm font-normal leading-5 text-gray-500">
                          3時間前（編集済み）
                        </span>
                      </div>
                    </div>
                    <div className="h-[20px] w-[290px]">
                      <span className="h-[20px] w-[290px] text-sm font-normal leading-5 text-gray-700">
                        文章の----を少なくしてください。
                      </span>
                    </div>
                  </div>
                </div>
                {reply ? (
                  <div className="h-[124px] w-[358px] py-[8px] pl-[24px]">
                    <div className="h-[108px] w-[334px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
                      <div className="h-[92px] w-[302px]">
                        <textarea
                          className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
                          placeholder="返信"
                          defaultValue={''}
                        />
                        <div className="flex h-[34px] w-[302px] items-end justify-between">
                          <div className="flex h-[24px] w-[164px] items-center justify-between">
                            <button className="h-[16px] w-[16px]">
                              <ImageAddIcon width={16} height={16} fill="#000000" />
                            </button>
                            <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
                            <div className="flex h-[17px] w-[117px] items-center justify-between">
                              <button className="h-[16px] w-[16px]">
                                <BoldIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <ItalicIcon width={16} height={16} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <UnderlineIcon width={17} height={17} fill="#000000" />
                              </button>
                              <button className="h-[16px] w-[16px]">
                                <FontcolorIcon width={17} height={17} fill="#000000" />
                              </button>
                            </div>
                          </div>
                          <Button
                            onClick={() => setReply(false)}
                            className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
                          >
                            送信
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="flex h-[24px] w-[60px] items-center justify-end">
                    <button className="h-[20px] w-[28px]" onClick={handleClick4}>
                      <span className="h-[20px] w-[28px] text-sm font-semibold leading-5 text-gray-500">返信</span>
                    </button>
                  </div>
                )}
              </div>
            )}
          </form>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="h-[1828px] w-[1179px] bg-gray-50">
        <div className="w-[1181px] px-[24px]">
          <Steppanel_ContentsApproving />
          <AlertContentsApprove />
          <div className="mt-[25px] w-[1131px] px-[40px] py-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="mb-[24px] flex h-[31px] w-[1051px] items-center justify-between">
              <div className="flex h-[28px] w-[92px] items-center">
                <span className="text-lg font-semibold leading-7 text-gray-500">コンテンツ</span>
              </div>
              <div className="flex h-[38px] w-[206px] items-center justify-between">
                <Link to="/PRST_production_management/contents_approved">
                  <Button className="text-sm font-medium leading-5">承認する</Button>
                </Link>
                <button onClick={handleClick1}>
                  <EditIcon width={24} height={24} fill="#6B7280" />
                </button>
                <button onClick={handleClick3}>
                  <img src={CommentIcon} alt="" />
                </button>
              </div>
            </div>
            <div className="w-[1051px]">
              <span className="mb-[4px] h-[20px] w-[70px] text-sm font-semibold leading-5 text-gray-700">
                プレビュー
              </span>
              <div className="mb-[4px] flex h-[28px] items-center">
                <span className="mr-[5px] text-sm font-semibold leading-5 text-gray-700">1 / 2枚目</span>
                <div className="h-[28px]">
                  <ChevronRightIcon width={28} height={28} fill="#374151" />
                </div>
              </div>
              <div className="flex w-[1051px] justify-between py-[10px]">
                <div className="w-[290px]">
                  <div className="flex w-[290px] items-center justify-between rounded-t-sm border-x border-t border-x-gray-200 border-t-gray-200 px-[6px] py-[13px]">
                    <img src={AvatarImage} alt="" className="mr-[5px]" />
                    <span className="w-full">1122329737</span>
                  </div>
                  <div className="w-[290px]">
                    <img src={SampleMediaImage2} alt="" className="w-[290px]" />
                  </div>
                  <div className="flex h-[100px] w-[290px] items-center justify-center rounded-b-sm border-x border-b border-x-gray-200 border-b-gray-200">
                    <div className="mb-[13px] mt-[6px] w-[280px]">
                      <div className="mb-[9px] flex w-[275px] items-center justify-between">
                        <div className="flex w-[66px] items-center justify-between">
                          <HeartIcon fill="#000000" />
                          <MessageIcon fill="#000000" />
                          <PaperPlaneIcon fill="#000000" />
                        </div>
                        <BookmarkIcon />
                      </div>
                      <div className="w-[280px]">
                        <div className="mb-[4px] h-[23px] w-[280px] text-xs font-normal leading-4 text-gray-800">
                          「Canon360」
                        </div>
                        <div className="mb-[4px] h-[13px] w-[280px] text-[9px] font-medium text-gray-300">
                          続きを読む
                        </div>
                        <div className="h-[13px] w-[280px] text-[9px] font-medium text-gray-400">
                          2022年8月29日 10:58
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[433px] border-l border-gray-200"></div>
                <div className="w-[681px]">
                  <div className="w-[681px]">
                    <div className="mb-[15px] flex h-[20px] w-[76px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <span className="text-xs font-normal leading-4 text-gray-800">テキスト</span>
                    </div>
                    <div className="mb-[40px] h-[256px] w-[681px] text-xs font-normal leading-4 text-gray-800">
                      「Canon360」
                      <br />
                      <br />
                      来週からイベントを開催！
                      <br />
                      賞金もあります...💌
                      <br />
                      <br />
                      --------------------------------------------------------------------------------
                      <br />
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません!
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                      <br />
                      <br />
                      -----------------------------------------------------------------------------------
                      <br />
                      ぜひ参加してくださいね！
                    </div>
                    <div className="h-[16px] w-[681px] text-xs font-normal leading-4 text-gray-700">
                      #LANVINenBleu,#LANVINenBleu2023ss,#new,#newcollection
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-[25px] h-[755px] w-[1133px] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[707px] w-[1085px]">
              <div className="mb-[24px] flex h-[38px] w-[1085px] items-center justify-between">
                <div className="flex h-[28px] w-[92px] items-center">
                  <span className="text-lg font-semibold leading-7 text-gray-500">イメージ案</span>
                </div>
                <div className="flex h-[38px] w-[206px] items-center justify-between ">
                  <div className="flex h-[24px] w-[76px] items-center justify-between bg-green-100 px-[8px] py-[2px]">
                    <span className="text-sm font-medium leading-5 text-green-800">承認済</span>
                    <button>
                      <ChevronDownIcon fill="#10B981" width={16} height={16} />
                    </button>
                  </div>
                  <EditIcon width={24} height={24} fill="#6B7280" />
                  <img src={CommentIcon} alt="" />
                </div>
              </div>
              <div className="h-[32px] w-[1085px]">
                <div className="mb-[8px] flex h-[20px] w-[56px] items-center">
                  <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                </div>
                <div className="mb-[8px] flex h-[28px] w-[162px] items-center">
                  <div className="mr-[5px] flex h-[20px] w-[56px] items-center">
                    <span className="text-sm font-semibold leading-5 text-gray-700">1 / 2枚目</span>
                  </div>
                  <div className="h-[28px] w-[101px]">
                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                  </div>
                </div>
                <div className="mb-[24px] flex h-[298px] w-[1085px] justify-between py-[10px]">
                  <div className="w-[290px]">
                    <img src={SampleMediaImage} alt="" className="w-[290px]" />
                  </div>
                  <div className="h-[236px] w-[715px] py-[10px]">
                    <div className="mb-[20px] flex h-[20px] w-[124px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <div className="flex h-[16px] w-[96px] items-center">
                        <span className="text-xs font-normal leading-4 text-gray-800">画像 / 動画の説明</span>
                      </div>
                    </div>
                    <div className="h-[176px] w-[715px] text-xs font-normal leading-4 text-gray-800">
                      <span>
                        人物画でカメラを持った状態など、いきいきと撮影している場面が想像できるような写真を用意していただきたいです！
                        <br />
                        <br />
                        以下のことはおやめください。
                        <br />
                        <br />
                        ・危険な場所での撮影
                        <br />
                        ・他社製品と撮影すること
                        <br />
                        ・暗い場所での撮影（明るいイメージを希望しているため）
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-[24px] h-[1px] w-[1085px] border-b border-[#DDDDDD]"></div>
                <div className="h-[242px] w-[1085px]">
                  <div className="mb-[8px] h-[20px] w-[1085px] px-[16px]">
                    <span className="h-[20px] w-[56px] text-sm font-semibold leading-5 text-gray-800">テキスト</span>
                  </div>
                  <div className="h-[214px] w-[1085px] p-[16px]">
                    <span className="h-[182px] w-[1053px] text-xs font-normal leading-4 text-gray-800">
                      ①「Canon360」という商品名を入れてください。
                      <br />
                      ②
                      イベント情報入れてださい。以下の内容をいれていただければ、自由に文章を作っていただいて大丈夫です！文章のトーンは、絵文字などを使って、読みやすく明るい感じでお願いします。
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません。
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{DeleteModal()}</div>

      <div className="relative">{EditContentsModal()}</div>
      {comment ? <div className="absolute">{CommentWindow()}</div> : null}
    </>
  );
};
