import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { GetInfluencerSnsAccountOutput, PostDetailYoutube, SnsAccountInsightYoutube } from '@/api/influencer';
import { fetchInfluencerApplicantDetail } from '@/hooks/Influencer';
import { getImageUrlIfRelative } from '@/utils/image';

import common_top from '../common/Parts/common_modal_top_part';
import tab from '../common/Parts/TabForInfluencerInfo';
import AccountPR from '../common/Youtube_Modal_Parts/AccountPR';
import Applicant_Info from '../common/Youtube_Modal_Parts/Applicant_Info';
import Follower, { MasterJsonData } from '../common/Youtube_Modal_Parts/Follower';
import Insight from '../common/Youtube_Modal_Parts/Insight';
import {
  AppealPostsProps,
  RecentPostsProps,
  PostInfoModalProps,
  YoutubeInsightProps,
  AccountFollowerAttributes,
  AccountPr,
} from '../Youtube/YoutubeAll_Interface';

export default function AccountDetail() {
  const [Youtube_Status, setYoutubeStatus] = useState('applicant_info');
  const [modalIndex, setModalIndex] = useState(0);
  const [deleteArrow, setDeleteArrow] = useState(false);
  const color_num = 4;
  const tmp_button_color_list = new Array(color_num - 1).fill('text-sm font-medium leading-5 text-gray-500');
  const [button_color_list, setButtonColorList] = useState([
    'text-sm font-medium leading-5 text-blue-500',
    ...tmp_button_color_list,
  ]);

  const search = useLocation().search;
  const influencerApplicantId = new URLSearchParams(search).get('id') || '';
  const [influencerInfo, setInfluencerInfo] = useState<GetInfluencerSnsAccountOutput>(
    {} as GetInfluencerSnsAccountOutput
  );
  const [accountGenreJsonData, setAccountGenreJsonData] = useState<MasterJsonData[]>([]);
  const [ageGenderJsonData, setAgeGenderJsonData] = useState<MasterJsonData[]>([]);
  const [genreJsonData, setGenreJsonData] = useState<MasterJsonData[]>([]);
  const [valueJsonData, setValueJsonData] = useState<MasterJsonData[]>([]);
  // const [mainGenre, setMainGenre] = useState<string>('');
  // const [subGenre, setSubGenre] = useState<string>('');

  // const changeValueToAccountGenreLabel = (genreCodes: string) => {
  //   return accountGenreJsonData?.find((genre) => genre.value === genreCodes)?.label || '';
  // };

  useEffect(() => {
    const getInfluencerInfo = async () => {
      try {
        const getInfluencerSnsAccountOutput = await fetchInfluencerApplicantDetail(influencerApplicantId);
        setInfluencerInfo(getInfluencerSnsAccountOutput);
      } catch (error) {
        Sentry.captureException(error);
        console.log(error);
      }
    };

    const fetchAccountGenreData = async () => {
      try {
        const getAccountGenreResponse = await axios.get(
          `${process.env.REACT_APP_CLOUDFRONT_URL}/assets/data/genres.json`
        );
        console.log('アカウントジャンル取得したデータ_YouTube:', getAccountGenreResponse.data); // デバッグ用のログ
        setAccountGenreJsonData(getAccountGenreResponse.data);
      } catch (error) {
        Sentry.captureException(error);
        console.error('データを取得できませんでした:', error);
      }
    };

    const fetchAgeGenderData = async () => {
      try {
        const getAgeGenderResponse = await axios.get(
          `${process.env.REACT_APP_CLOUDFRONT_URL}/assets/data/follower_attr/age_gender.json`
        );
        setAgeGenderJsonData(getAgeGenderResponse.data);
      } catch (error) {
        Sentry.captureException(error);
        console.error('データを取得できませんでした:', error);
      }
    };

    const fetchGenreData = async () => {
      try {
        const getGenreResponse = await axios.get(
          `${process.env.REACT_APP_CLOUDFRONT_URL}/assets/data/follower_attr/genres.json`
        );
        console.log('ジャンル取得したデータ_YouTube:', getGenreResponse.data); // デバッグ用のログ
        setGenreJsonData(getGenreResponse.data);
      } catch (error) {
        Sentry.captureException(error);
        console.error('データを取得できませんでした:', error);
      }
    };

    const fetchValueData = async () => {
      try {
        const getValueResponse = await axios.get(
          `${process.env.REACT_APP_CLOUDFRONT_URL}/assets/data/follower_attr/values.json`
        );
        setValueJsonData(getValueResponse.data);
      } catch (error) {
        Sentry.captureException(error);
        console.error('データを取得できませんでした:', error);
      }
    };

    if (influencerApplicantId.length > 0) {
      getInfluencerInfo();
    }
    fetchAccountGenreData();
    fetchAgeGenderData();
    fetchGenreData();
    fetchValueData();
  }, [influencerApplicantId]);

  // PostInfo の引数定義 (Modal の為に定義)
  const [ModalOpen, setModalOpen] = useState(false);

  const [postType, setPostType] = useState('appealPosts'); // [appealPosts, recentPosts
  const postinfoModal: PostInfoModalProps = {
    modalOpen: ModalOpen,
    setModalOpen: setModalOpen,
    modalindex: modalIndex,
    setModalIndex: setModalIndex,
    deleteFlag: deleteArrow,
    setDeleteFlag: setDeleteArrow,
    postType,
    setPostType,
  };

  const appealPosts: AppealPostsProps[] = ((influencerInfo?.posts?.appealPosts as PostDetailYoutube[]) || []).map(
    (post) => ({
      id: post.id,
      // postImage: post.postImage ?? null,
      postImage: post.postImage ? getImageUrlIfRelative(post.postImage) : null,
      postedAt: post.postedAt ?? null,
      title: post.title ?? null,
      caption: post.caption ?? null,
      url: post.url,
      viewCounts: post.viewCounts ?? null,
      likeCounts: post.likeCounts ?? null,
      commentCounts: post.commentCounts ?? null,
      engagementCounts: post.engagementCounts ?? null,
      engagementRate: post.engagementRate ?? null,
    })
  );

  const recentPosts: RecentPostsProps[] = ((influencerInfo?.posts?.recentPosts as PostDetailYoutube[]) || []).map(
    (post) => ({
      id: post.id,
      // postImage: post.postImage ?? null,
      postImage: post.postImage ? getImageUrlIfRelative(post.postImage) : null,
      postedAt: post.postedAt ?? null,
      title: post.title ?? null,
      caption: post.caption ?? null,
      url: post.url,
      viewCounts: post.viewCounts ?? null,
      likeCounts: post.likeCounts ?? null,
      commentCounts: post.commentCounts ?? null,
      engagementCounts: post.engagementCounts ?? null,
      engagementRate: post.engagementRate ?? null,
    })
  );

  const insightPosts: YoutubeInsightProps = (influencerInfo?.insight as SnsAccountInsightYoutube) || {};
  const followerAttribute: AccountFollowerAttributes = influencerInfo?.followerAttributes || {};
  const accountPR: AccountPr = influencerInfo?.accountPr || {};

  useEffect(() => {
    console.log('influencerInfo_YouTube:', influencerInfo);
    console.log('influencerInfo.follwerAttributes:', influencerInfo.followerAttributes);
  }, [influencerInfo]);

  return (
    <div className="flex h-[500px] justify-center">
      <span className="pt-[40px]">
        <div>{common_top(undefined, influencerInfo || {}, accountGenreJsonData)}</div>
        {/* <div>{common_top(undefined, influencerInfo || {}, mainGenre, subGenre)}</div> */}
        {/*共通の tab パーツ*/}
        <div className="pt-[40px]">{tab(setYoutubeStatus, button_color_list, setButtonColorList, 'youtube')}</div>

        <div className="pb-[40px] pt-[16px]">
          {Youtube_Status == 'applicant_info' ? (
            <div>
              <Applicant_Info
                appealPosts={appealPosts}
                recentPosts={recentPosts}
                postinfoModal={postinfoModal}
                influencerInfo={influencerInfo}
              />
            </div>
          ) : (
            <div></div>
          )}
          {Youtube_Status == 'insight' ? (
            <div>{Insight(insightPosts, influencerInfo?.posts.recentPosts.length)}</div>
          ) : (
            <div></div>
          )}
          {Youtube_Status == 'follower' ? (
            <div>{Follower(followerAttribute, ageGenderJsonData, genreJsonData, valueJsonData)}</div>
          ) : (
            <div></div>
          )}
          {Youtube_Status == 'accountPR' ? <div>{AccountPR(accountPR)}</div> : <div></div>}
        </div>
      </span>
    </div>
  );
}
