import { Transition } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import { useState, useEffect, useCallback } from 'react';

import { GetAccountOutput, MyMember } from '@/api/member/api';
import NoImage from '@/assets/images/NoImage.png';
import { Button } from '@/components/Elements';
import { getMemberList } from '@/hooks/GetMemberList';
import { fetchBillingInfo } from '@/hooks/GetTeamBillingInfo';
import { fetchMemberInfo } from '@/hooks/memberInvite';
import { getImageUrl } from '@/utils/image';
import { ValidationMessages } from '@/utils/message';

import Snackbar from '../../Components/Snackbar';
import ResendMessage from '../../other_project/components/ResendMessage';

import MemberAddImage from './assets/MemberAdd.svg';
import { DotsDropdown1 } from './DotsDropdown1'; //administrator
import { Dropdown, HumanProps } from './Dropdown';
import { MemberModal, MemberInviteProps } from './MemberModal';

export enum MemberRole {
  administrator = 'administrator',
  member = 'member',
}

export function MemberListAdmin(): React.ReactElement {
  const [account, setAccount] = useState<GetAccountOutput>();
  const [messageVal, setMessageVal] = useState({ successFlag: false, message: '' });
  const [humanState, setHumanState] = useState<HumanProps[]>([]);
  const [dropDownPlanError, setDropDownPlanError] = useState<boolean[]>([]);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [deleteMailAdress, setDeleteMailAdress] = useState<string>('');

  const memberInviteProps: MemberInviteProps = {
    open: false,
    mailAddress: '',
    displayMailAddress: '',
    invite: false,
    errorProps: {
      modalError: false,
      planError: false,
      errorMessage: '',
      planErrorMessage: '',
    },
    teamBillingInfo: null,
  };
  const [memberInviteArgs, setMemberInviteArgs] = useState<MemberInviteProps>(memberInviteProps);

  const fetchData = useCallback(async () => {
    try {
      const accountData = await fetchMemberInfo();
      setAccount(accountData);

      const memberListData = await getMemberList();
      const tmpBillingInfo = await fetchBillingInfo();
      setMemberInviteArgs((prev) => ({ ...prev, teamBillingInfo: tmpBillingInfo }));

      const dataPromises = memberListData?.map(async (member: MyMember) => {
        const thumbnail = await getImageUrl(member.thumbnail);
        const parts = member.thumbnail.split('/');
        return {
          name: member.name ?? '',
          memberId: member.memberId ?? '',
          mailAddress: member.email,
          adminRole: member.role === MemberRole.administrator,
          selected: '',
          authority: member.role === MemberRole.administrator ? '管理者' : '一般',
          avatarImage:
            parts[parts.length - 1] == 'default-member.png' || parts[parts.length - 1] == '' ? NoImage : thumbnail,
          status: member.status ?? '',
        };
      });

      const data = await Promise.all(dataPromises);
      setHumanState(data);
      console.log('L71: ', humanState);
    } catch (error) {
      Sentry.captureException(error);
      console.error('Error fetching data:', error);
    }
  }, []);

  useEffect(() => {
    // fetchDataを実行する
    fetchData();
  }, [fetchData]);

  const updatePartHumanSelectedState = (partState: HumanProps, selected: string) => {
    const newHumanState = [...humanState];
    newHumanState[humanState.indexOf(partState)].selected = selected;
    setHumanState(newHumanState);
  };

  const updatePartHumanStatusState = (partState: HumanProps, status: string) => {
    const newHumanState = [...humanState];
    newHumanState[humanState.indexOf(partState)].status = status;
    setHumanState(newHumanState);
  };

  const deletePartHumanState = (partState: HumanProps) => {
    const newHumanState = humanState.filter((human) => human !== partState);
    setHumanState(newHumanState);
  };

  const humanStatus: { [key: string]: string } = {
    expired: '招待期限切れ',
    pending: '招待中',
  };

  const status = ['pending', 'expired'];

  return (
    <>
      <div className="h-[749px] w-[1181px] bg-gray-50">
        <div className="flex h-[76px] w-[1181px] border-b border-gray-200">
          <p className="ml-[16px] mt-[24px] h-[28px] w-[127px] text-base font-semibold leading-7 text-gray-700">
            チーム - メンバー
          </p>
          <div>{messageVal.successFlag && ResendMessage(messageVal.message, setMessageVal, memberInviteArgs)}</div>
          <Transition
            show={snackbar}
            enter="transition-opacity ease-in-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-in-out duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            className="fixed top-0 z-50 w-full"
          >
            <Snackbar text={'「' + deleteMailAdress + '」' + 'をメンバーから削除しました'} />
          </Transition>
          <Button
            type="button"
            className="ml-[864px] mt-[19px] inline-flex h-[38px] w-[158px] items-center gap-x-1.5 rounded-md bg-[#007CC2] px-[5px] py-[9px] text-sm font-semibold text-white shadow-sm"
            onClick={() => {
              setMemberInviteArgs((prev) => ({ ...prev, open: true, invite: true }));
            }}
          >
            <span className="flex gap-2">
              <img src={MemberAddImage} alt="" className="h-5 w-5" />
              <div>メンバーを招待</div>
            </span>
          </Button>
        </div>
        <div className="ml-[24px] mt-[24px] min-h-[486px] w-[1059px] bg-white">
          <div className="flex h-[48px] w-[1059px] border-b border-gray-200">
            <p className="h-[48px] w-[353px] pl-[24px] pt-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              名前
            </p>
            <p className="h-[48px] w-[317px] pl-[24px] pt-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              権限
            </p>
            <p className="h-[48px] w-[317px] pl-[24px] pt-[16px] text-xs font-medium uppercase leading-4 tracking-wider text-gray-500">
              ステータス
            </p>
          </div>

          <div className="h-[486px] overflow-y-scroll">
            {humanState.map((human, index) => (
              <div key={index}>
                <div className="flex h-[72px] w-[1059px] border-b border-gray-200" key={index}>
                  <div className="flex h-[72px] w-[353px]">
                    <img
                      src={human.avatarImage}
                      alt=""
                      className="ml-[24px] mt-[16px] h-[40px] w-[40px] rounded-full"
                    />

                    <div className="my-[16px] ml-[16px]">
                      {human.name === '' ? (
                        <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">-</p>
                      ) : (
                        <p className="h-[20px] w-[249px] text-sm font-medium leading-5 text-gray-900">{human.name}</p>
                      )}
                      <p className="h-[20px] w-[249px] text-sm font-normal leading-5 text-gray-500">
                        {human.mailAddress}
                      </p>
                    </div>
                  </div>
                  <div className="h-[72px] w-[317px] pl-[24px] pt-[17px]">
                    {account?.role === MemberRole.administrator &&
                    account?.email != human.mailAddress &&
                    humanState[index].status != 'pending' &&
                    humanState[index].status != 'expired' ? (
                      <Dropdown
                        updatePartHumanSelectedState={updatePartHumanSelectedState}
                        index={index}
                        humans={humanState}
                        planError={dropDownPlanError}
                        setPlanError={setDropDownPlanError}
                        teamBillingInfo={memberInviteArgs.teamBillingInfo || undefined}
                      />
                    ) : (
                      <p className="h-[72px] w-[317px] pt-[10px] text-sm font-normal leading-5 text-gray-500">
                        {human.adminRole ? '管理者' : '一般'}
                      </p>
                    )}
                    {dropDownPlanError[index] && (
                      <p className="h-[72px] w-[317px] text-sm font-normal leading-5 text-red-500">
                        {ValidationMessages.overAdminRoleLimitMessage()}
                      </p>
                    )}
                  </div>
                  <p className="h-[72px] w-[317px] pl-[24px] pt-[26px] text-sm font-normal leading-5 text-gray-500">
                    {humanStatus[String(human?.status)]}
                  </p>

                  {account?.role === MemberRole.administrator &&
                    account?.email != human.mailAddress &&
                    humanState[index].status === status[0] && <></>}
                  {account?.role === MemberRole.administrator &&
                    account?.email != human.mailAddress &&
                    humanState[index].status === status[1] && (
                      <DotsDropdown1
                        human={humanState[index]}
                        updatePartHumanState={updatePartHumanStatusState}
                        deletePartHumanState={deletePartHumanState}
                        option_flag={1}
                        humanNum={humanState.length}
                        setMessageVal={setMessageVal}
                        snackbar={snackbar}
                        setSnackbar={setSnackbar}
                        deleteMailAdress={deleteMailAdress}
                        setDeleteMailAdress={setDeleteMailAdress}
                      />
                    )}

                  {account?.role == MemberRole.administrator &&
                    account?.email != human.mailAddress &&
                    !status.includes(humanState[index].status) && (
                      <DotsDropdown1
                        human={humanState[index]}
                        updatePartHumanState={updatePartHumanStatusState}
                        deletePartHumanState={deletePartHumanState}
                        option_flag={2}
                        humanNum={humanState.length}
                        setMessageVal={setMessageVal}
                        snackbar={snackbar}
                        setSnackbar={setSnackbar}
                        deleteMailAdress={deleteMailAdress}
                        setDeleteMailAdress={setDeleteMailAdress}
                      />
                    )}

                  {account?.role == MemberRole.member &&
                    account?.email != human.mailAddress &&
                    humanState[index].status === status[0] && <></>}
                  {account?.role == MemberRole.member &&
                    account?.email != human.mailAddress &&
                    humanState[index].status === status[1] && (
                      <DotsDropdown1
                        human={humanState[index]}
                        updatePartHumanState={updatePartHumanStatusState}
                        deletePartHumanState={deletePartHumanState}
                        option_flag={1}
                        humanNum={humanState.length}
                        setMessageVal={setMessageVal}
                        snackbar={snackbar}
                        setSnackbar={setSnackbar}
                        deleteMailAdress={deleteMailAdress}
                        setDeleteMailAdress={setDeleteMailAdress}
                      />
                    )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <MemberModal
        memberInviteArgs={memberInviteArgs}
        setMemberInviteArgs={setMemberInviteArgs}
        setMessageVal={setMessageVal}
        fetchData={fetchData}
        humanState={humanState}
      />
    </>
  );
}
