import { Transition, Dialog } from '@headlessui/react';
import { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { DatePicker } from '@/components/Elements/DatePicker/DatePicker';
import { TextArea } from '@/components/Elements/TextArea';

// import { AccountContext } from './AccountContext';
import { ReactComponent as ArtBoardImage } from './assets/ArtBoard7.svg';
import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
import { ReactComponent as GritIcon } from './assets/bx-grid.svg';
import { ReactComponent as PlusImage } from './assets/bx-plus.svg';
import { ReactComponent as TrashIcon } from './assets/bx-trash-alt.svg';
import SampleMediaImage from './assets/SampleMediaImage.png';
import { ReactComponent as TeamWorkImage } from './assets/Team work_Two Color 2.svg';

export const PRSTProductionManagementListView = () => {
  const [open, setOpen] = useState<boolean>(false);
  const handleClick = () => {
    setOpen(true);
    setOpen1(false);
  };

  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen(false);
    setOpen1(true);
  };

  const [open2, setOpen2] = useState<boolean>(false);
  const handleClick2 = () => {
    setOpen1(false);
    setOpen2(true);
  };

  const [open3, setOpen3] = useState<boolean>(false);
  const handleClick3 = () => {
    setOpen2(false);
    setOpen3(true);
  };

  // const accountInfo = useContext(AccountContext);

  // const projectName = accountInfo.accountInfo[0].projectName;

  const SNSAccountRegisterModal = () => {
    return (
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[512px] rounded-lg bg-white p-[24px]">
                  <div className="w-[464px] rounded-lg bg-white">
                    <div className="mb-[32px] w-[464px]">
                      <div className="mb-[24px] flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">新規投稿制作</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen(false)} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-[320px]">
                        <div className="mb-[4px] h-[20px] w-[70px]">
                          <span className="mb-[4px] text-sm font-semibold leading-5 text-gray-700">投稿予定日</span>
                        </div>
                        <DatePicker width="320px" />
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-end gap-3">
                      <Button
                        variant="white"
                        size="md"
                        className="h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick1}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const SNSAccountRegisterModal1 = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[380px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="w-[332px] rounded-lg bg-white">
                    <div className="mb-[32px] h-[24px] w-[332px]">
                      <div className="flex flex-col p-0">
                        <div className="flex flex-row items-center">
                          <div className="flex w-full flex-col">
                            <div className="flex text-center">
                              <span className="text-lg font-medium leading-6">新規投稿制作</span>
                              <div className="ml-auto">
                                <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={handleClick} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="m-auto flex h-[189px] w-[311px] justify-center">
                      <div>
                        <ArtBoardImage />
                      </div>
                    </div>
                    <div className="m-auto mb-[32px] flex justify-center">
                      <span className="text-sm">投稿制作が追加されました！</span>
                    </div>
                    <div className="h-[78px] w-[332px]">
                      <div className="mb-[16px] flex h-[42px] w-[332px] flex-row items-center justify-end gap-3">
                        <Link to="/PRST_production_management/noimage">
                          <Button variant="white" size="md" className="whitespace-nowrap text-sm font-medium leading-5">
                            あとで
                          </Button>
                        </Link>
                        <Link to="/PRST_production_management/nocontent">
                          <Button
                            variant="primary"
                            size="md"
                            className="whitespace-nowrap text-sm font-medium leading-5"
                          >
                            イメージ案作成へ進む
                          </Button>
                        </Link>
                      </div>
                      <div className="flex w-[332px] justify-end">
                        <button
                          className="whitespace-nowrap text-sm font-medium leading-5 text-gray-500"
                          onClick={handleClick2}
                        >
                          スキップしてコンテンツ制作からはじめる
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const AddImageModal = () => {
    return (
      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[723px] w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[675px] w-[720px] bg-white">
                    <div className="mb-[24px] flex h-[613px] w-[720px]">
                      <div className="h-[613] w-[696]">
                        <div className="mb-[40px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="h-[549px] w-[696px]">
                          <div className="mb-[24px] h-[367px] w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-[261px] w-[693px]">
                              <div className="mb-[8px] flex h-[44px] w-[693px] items-center justify-start">
                                <span className="text-sm font-semibold leading-5 text-gray-700">1枚目</span>
                              </div>
                              <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                                  <div className="h-[120px] w-[220px] pb-[4px]">
                                    <div className="flex items-center justify-center">
                                      <div className="flex h-[48px] w-[48px] items-center justify-center">
                                        <AddIcon width={36} height={36} />
                                      </div>
                                    </div>
                                    <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                      <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-gray-600">
                                          ドラッグ&ドロップもしくは
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                          アップロード
                                        </span>
                                      </div>
                                    </div>
                                    <div className="flex items-center justify-center">
                                      <span className="text-xs font-normal leading-4 text-gray-500">
                                        対応ファイルはPNG, JPG, GIF,mp4
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="h-[209px] w-[416px]">
                                  <div className="mb-[4px] h-[20px] w-[111px]">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">
                                      画像 / 動画の説明
                                    </span>
                                  </div>
                                  <TextArea className="h-[185px] w-[416px]" />
                                </div>
                              </div>
                              <div className="h-[70px] w-[693px] p-[16px]">
                                <Button
                                  startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                  variant="secoundary"
                                  size="smNopadding"
                                  className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                                  onClick={handleClick1}
                                >
                                  メディアイメージを追加する
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="h-[158px] w-[696px]">
                            <div className="mb-[4px] h-[20px] w-[56px]">
                              <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                            </div>
                            <TextArea className="h-[134px] w-[696px]" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen2(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleClick3}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const AddImageModal1 = () => {
    return (
      <Transition.Root show={open3} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen3}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-[1003px] w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-[955px] w-[720px] bg-white">
                    <div className="mb-[16px] flex h-[900px] w-[720px]">
                      <div className="h-[900px] w-[720px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="h-[853px] w-[696px]">
                          <div className="mb-[32px] h-[605px] w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-[577px] w-[693px]">
                              <div className="mb-[16px] h-[262px] w-[693px] border-b border-gray-200 pb-[16px]">
                                <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                  <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">1枚目</span>
                                  </div>
                                  <div className="flex w-[110px] items-center justify-around ">
                                    <ChevronDownIcon width={28} height={28} fill="#374151" />
                                    <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
                                    <TrashIcon width={22} height={22} fill="#6B7280" />
                                  </div>
                                </div>
                                <div className="flex h-[210px] w-[695px] items-center justify-between">
                                  <img src={SampleMediaImage} alt="" />
                                  <div className="h-[209px] w-[416px]">
                                    <div className="mb-[4px] h-[20px] w-[111px]">
                                      <span className="text-sm font-semibold leading-5 text-gray-700">
                                        画像 / 動画の説明
                                      </span>
                                    </div>
                                    <TextArea className="h-[185px] w-[416px]" />
                                  </div>
                                </div>
                              </div>
                              <div className="mb-[16px] h-[245px] w-[693px]">
                                <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
                                  <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
                                    <span className="text-sm font-semibold leading-5 text-gray-700">2枚目</span>
                                  </div>
                                  <div className="flex w-[110px] items-center justify-around ">
                                    <ChevronDownIcon width={28} height={28} fill="#374151" />
                                    <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
                                    <TrashIcon width={22} height={22} fill="#6B7280" />
                                  </div>
                                </div>
                                <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
                                  <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
                                    <div className="h-[120px] w-[220px] pb-[4px]">
                                      <div className="flex items-center justify-center">
                                        <div className="flex h-[48px] w-[48px] items-center justify-center">
                                          <AddIcon width={36} height={36} />
                                        </div>
                                      </div>
                                      <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
                                        <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                                          <span className="text-sm font-medium leading-5 text-gray-600">
                                            ドラッグ&ドロップもしくは
                                          </span>
                                        </div>
                                        <div className="flex items-center justify-center">
                                          <span className="text-sm font-medium leading-5 text-[#007CC2]">
                                            アップロード
                                          </span>
                                        </div>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <span className="text-xs font-normal leading-4 text-gray-500">
                                          対応ファイルはPNG, JPG, GIF,mp4
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="h-[209px] w-[416px]">
                                    <div className="mb-[4px] h-[20px] w-[111px]">
                                      <span className="text-sm font-semibold leading-5 text-gray-700">
                                        画像 / 動画の説明
                                      </span>
                                    </div>
                                    <TextArea className="h-[185px] w-[416px]" />
                                  </div>
                                </div>
                              </div>
                              <Button
                                startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                variant="secoundary"
                                size="smNopadding"
                                className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                              >
                                メディアイメージを追加する
                              </Button>
                            </div>
                          </div>
                          <div className="h-[216px] w-[693px]">
                            <div className="mb-[4px] h-[20px] w-[56px]">
                              <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                            </div>
                            <TextArea className="h-[192px] w-[693px]" />
                          </div>
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen3(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                      >
                        キャンセル
                      </Button>
                      <Link to="/PRST_production_management/image">
                        <Button
                          variant="primary"
                          size="md"
                          className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        >
                          保存する
                        </Button>
                      </Link>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  return (
    <div>
      <div className="flex w-[1181px] border-b border-gray-200 py-[18px] pl-[16px]">
        <ChevronLeft width={24} height={24} fill="#9CA3AF" />
        <p className="ml-[16px] text-base font-bold leading-6 text-gray-700">aaaaa</p>
      </div>
      <div className="w-[940px] px-[24px] py-[16px]">
        <div>
          <div className="mb-[16px] flex w-[1133px] items-center justify-between">
            <p className="text-base font-semibold leading-7 text-gray-700">投稿制作一覧</p>
            <div className="flex justify-end">
              <Button
                startIcon={<GritIcon className="h-[20px] w-[20px]" />}
                size="md"
                variant="white"
                className="whitespace-nowrap text-sm font-medium leading-5"
                style={{
                  marginRight: '16px',
                  paddingTop: '9px',
                  paddingBottom: '9px',
                  paddingLeft: '10px',
                  paddingRight: '12px',
                }}
              >
                並び順プレビュー
              </Button>
              <Button
                startIcon={<PlusImage className="h-[20px] w-[20px]" />}
                size="md"
                className="whitespace-nowrap text-sm font-medium leading-5"
                style={{
                  paddingTop: '9px',
                  paddingBottom: '9px',
                  paddingLeft: '10px',
                  paddingRight: '12px',
                }}
                onClick={handleClick}
              >
                投稿制作を新規追加
              </Button>
            </div>
          </div>
          <div className="flex h-[525px] w-[1133px] items-center justify-center rounded shadow-[0_1px_3px_rgba(0,0,0,0.2),0_2px_1px_rgba(0,0,0,0.12),0_1px_1px_rgba(0,0,0,0.14)]">
            <div>
              <TeamWorkImage />
              <div className="flex justify-center">
                <p className="w-[294px] text-center text-[14px] font-normal leading-6 text-[#6B7280]">
                  まだ施策がありません。
                  <br />
                  右上のボタンから施策を作成してみましょう！
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="relative">{SNSAccountRegisterModal()}</div>
      <div className="relative">{SNSAccountRegisterModal1()}</div>
      <div className="relative">{AddImageModal()}</div>
      <div className="relative">{AddImageModal1()}</div>
    </div>
  );
};
