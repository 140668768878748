const ExpectedProjectDurationDisplay = (from: string, to: string): string => {
  let fromDate;
  let toDate;
  const options: Intl.DateTimeFormatOptions = {
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
    weekday: 'short',
  };

  if (from) {
    fromDate = new Date(from);
  } else {
    fromDate = new Date(); // 現在の日付を使用する
  }

  if (to) {
    toDate = new Date(to);
  } else {
    toDate = new Date(); // 現在の日付を使用する
  }

  const formatter = new Intl.DateTimeFormat('ja-JP', options);
  const fromDateString = formatter.format(fromDate);
  const toDateString = formatter.format(toDate);

  if (!from) {
    return toDateString;
  }

  if (!to) {
    return fromDateString;
  }

  return `${fromDateString}〜${toDateString}`;
};

export default ExpectedProjectDurationDisplay;
