import {
  GetInfluencerSnsAccountOutput,
  SnsAccountInsightInstagram,
  SnsAccountInsightTikTok,
  SnsAccountInsightX,
  SnsAccountInsightYoutube,
} from '@/api/influencer';
import { SNS_TYPES } from '@/config';

import { MasterJsonData } from '../Instagram_Modal_Parts/Follower';
import Instagram_Icon from '../Pic/Instagram_Icon.png';
import Star from '../Pic/star.png';
import Star_no_color from '../Pic/star_no_color.png';
import Tiktok_Icon from '../Pic/TikTok_Icon.png';
import Twitter_Icon from '../Pic/Twitter_Icon.png';
import Youtube_Icon1 from '../Pic/Youtube1.png';
import Youtube_Icon2 from '../Pic/Youtube2.png';

export default function Modal(
  SNS_INDEX?: number,
  influencerInfo?: GetInfluencerSnsAccountOutput,
  accountGenreJsonData?: MasterJsonData[]
  // mainGenre?: string,
  // subGenre?: string
) {
  let star_num: number[] = [];

  if (influencerInfo?.evaluation) {
    star_num = new Array(influencerInfo.evaluation.evaluation).fill(0);
  } else {
    star_num = [0, 0, 0, 0, 0];
  }

  const changeValueToAccountGenreLabel = (genreCodes: string) => {
    return accountGenreJsonData?.find((genre) => genre.value === genreCodes)?.label || genreCodes;
  };

  const mainGenre: string = changeValueToAccountGenreLabel(influencerInfo?.mainGenre || '');
  const subGenre: string = changeValueToAccountGenreLabel(influencerInfo?.subGenre || '');

  // 全体のバーの幅
  const totalWidth = 146;

  return (
    <>
      <div>
        <div className="text-lg font-medium leading-6">SNSアカウントの詳細</div>
      </div>

      <div className="flex pt-[20px]">
        <div>
          <div className="translate-x-[0.5px] translate-y-[-1px] pl-[1px]">
            <img src={influencerInfo?.thumbnailUrl || ''} alt="" className="h-[68px] w-[68px]" />
          </div>
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex">
            <div className="pl-[15.5px]">
              <span className="text-sm font-normal leading-5 text-gray-500">SNS</span>
            </div>
            <div className="pl-[6px] pt-[4px]">
              {influencerInfo?.snsType == SNS_TYPES.INSTAGRAM ? (
                <img src={Instagram_Icon} alt="" className="h-[18px] w-[18px]" />
              ) : influencerInfo?.snsType == SNS_TYPES.TWITTER ? (
                <img src={Twitter_Icon} alt="" className="h-[18px] w-[18px]" />
              ) : influencerInfo?.snsType == SNS_TYPES.TIK_TOK ? (
                <img src={Tiktok_Icon} alt="" className="h-[18px] w-[18px]" />
              ) : influencerInfo?.snsType == SNS_TYPES.YOUTUBE ? (
                <div>
                  <img src={Youtube_Icon1} alt="" className="h-[18px] w-[18px]" />
                  <div className="ml-[6.5px] mt-[-13px]">
                    <img src={Youtube_Icon2} alt="" className="h-[8px] w-[7px]" />
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>
            <div className="pl-[15px]">
              <span className="text-sm font-normal leading-5 text-gray-500">フォロワー</span>
            </div>
            <div className="pl-[4px]">
              <span className="text-sm font-normal leading-5 text-gray-500">
                {influencerInfo?.snsType == SNS_TYPES.YOUTUBE
                  ? `${new Intl.NumberFormat('ja-JP').format(
                      (influencerInfo?.insight as SnsAccountInsightYoutube)?.subscribers || 0
                    )}人`
                  : `${new Intl.NumberFormat('ja-JP').format(
                      (
                        influencerInfo?.insight as
                          | SnsAccountInsightInstagram
                          | SnsAccountInsightX
                          | SnsAccountInsightTikTok
                      )?.followers || 0
                    )}人`}
              </span>
            </div>

            <div className="pl-[19px]">
              <span className="text-sm font-normal leading-5 text-gray-500">補欠採用</span>
            </div>
            <div className="pl-[8px]">
              {influencerInfo?.applyingComment?.reserved ? (
                <span className="inline-flex items-center rounded-full bg-green-100 px-2 py-1 text-xs font-medium text-green-800 ring-1 ring-inset ring-gray-500/10">
                  <span>あり</span>
                </span>
              ) : (
                <span className="inline-flex items-center rounded-full bg-gray-100 px-2 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-500/10">
                  <span>なし</span>
                </span>
              )}
            </div>
          </div>

          <div>
            <div className="flex gap-[8px] pl-[15.5px] pt-[2px]">
              <div>
                {/* <span className="inline-flex items-center rounded-md bg-gray-100 px-3 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-500/10">
                  {influencerInfo?.mainGenre}
                </span> */}
                <span className="inline-flex items-center rounded-md bg-gray-100 px-3 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-500/10">
                  {mainGenre}
                </span>
              </div>
              <div>
                {/* <span className="inline-flex items-center rounded-md bg-gray-100 px-3 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-500/10">
                  {influencerInfo?.subGenre}
                </span> */}
                <span className="inline-flex items-center rounded-md bg-gray-100 px-3 py-1 text-xs font-medium text-gray-800 ring-1 ring-inset ring-gray-500/10">
                  {subGenre}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-[23px] pt-[25px]">
        <div className="h-[168px] w-[245px]">
          <div className="flex">
            <div className="flex">
              {star_num.map((value, index) => {
                return <img src={Star} alt="" className="h-[20px] w-[20px]" key={index} />;
              })}
              {new Array(5 - star_num.length).fill(0).map((value, index) => {
                return <img src={Star_no_color} alt="" className="h-[20px] w-[20px]" key={`reverse_${index}`} />;
              })}
            </div>
            <div className="h-[20px] w-[50px] pl-[5px]">{`(${influencerInfo?.evaluation?.evaluationCounts})`}</div>
          </div>
          <div className="pt-[10px]"></div>

          {influencerInfo?.evaluation?.evaluationRates
            .slice()
            .reverse()
            .map((value, index) => {
              return (
                <div className="flex" key={`reverse${index}`}>
                  <div className="pt-[7px]">{5 - index}</div>
                  <div className="pl-[9px] pt-[9px]">
                    <img src={Star} alt="" className="h-[20px] w-[20px]" />
                  </div>
                  <div className="flex pl-[12px] pt-[14px]">
                    <div
                      className="h-[12px] rounded-s-md bg-yellow-300"
                      style={{ width: `${(totalWidth * value.rate) / 100}px` }}
                    ></div>
                    <div
                      className="h-[12px] rounded-s-md bg-gray-100"
                      // 全体の幅 - (全体の幅 * レート) / 100 →  全体の幅 - 黄色のバー
                      style={{ width: `${totalWidth - (totalWidth * value.rate) / 100}px` }}
                    ></div>
                  </div>
                  <div className="pl-[19px] pt-[10px] text-sm font-medium leading-5 text-gray-600">{`${value.rate}%`}</div>
                </div>
              );
            })}
        </div>
        <div className="h-[245px] w-[1px] bg-gray-200"></div>
        <div>
          <div className="h-[48px] w-[502px]">
            <div className="text-sm font-semibold leading-5 text-black">ID</div>
            <div className="text-sm font-normal leading-5 text-black">{influencerInfo?.snsAccountNo}</div>
          </div>
          <div className="h-[88px] w-[502px] pt-[23px]">
            <div className="text-sm font-semibold leading-5 text-black">案件応募時の意気込み</div>
            <div className="text-sm font-normal leading-5 text-black">
              {influencerInfo?.applyingComment?.influencerComment}
            </div>
          </div>
          <div className="h-[48px] w-[502px] pt-[23px]">
            <div className="text-sm font-semibold leading-5 text-black">弊社担当からのコメント</div>
            <div className="text-sm font-normal leading-5 text-black">
              {influencerInfo?.applyingComment?.operatorComment}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
