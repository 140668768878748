import { AccountPr } from '../../Youtube/YoutubeAll_Interface';

export default function ApplicantInfo(accountPR: AccountPr) {
  return (
    <>
      <div>
        <div>
          <div className="text-sm font-semibold leading-5 text-gray-700">SNSアカウントの特徴紹介</div>
        </div>
        <div className="mt-[8px] h-[99px] w-[870px] rounded-lg bg-gray-100 ">
          <div className="h-[48px] w-[590px] pl-[10px]">
            <div className="whitespace-pre-wrap text-base font-normal leading-6 text-gray-600">
              {accountPR?.characteristic || ''}
            </div>
          </div>
        </div>
        <div className="pt-[24px]">
          <div className="text-sm font-semibold leading-5 text-gray-700">SNSアカウントの強み</div>
        </div>
        <div className="mt-[8px] h-[99px] w-[870x] rounded-lg bg-gray-100 ">
          <div className="h-[48px] w-[590px] pl-[10px]">
            <div className="whitespace-pre-wrap text-base font-normal leading-6 text-gray-600">
              {accountPR?.strongPoint || ''}
            </div>
          </div>
        </div>
        <div className="pt-[24px]">
          <div className="text-sm font-semibold leading-5 text-gray-700">SNSアカウントの実績</div>
        </div>
        <div className="mt-[8px] h-[99px] w-[870x] rounded-lg bg-gray-100 ">
          <div className="h-[72px] w-[590px] pl-[10px]">
            <div className="whitespace-pre-wrap text-base font-normal leading-6 text-gray-600">
              {accountPR?.actualResults || ''}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
