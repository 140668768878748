/* tslint:disable */
/* eslint-disable */
/**
 * Influfect クライアント API
 * Influfect クライアント APIドキュメント
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AffectOutput
 */
export interface AffectOutput {
    /**
     * 
     * @type {string}
     * @memberof AffectOutput
     */
    'affected': string;
}
/**
 * 
 * @export
 * @interface ApiResponseError
 */
export interface ApiResponseError {
    /**
     * 
     * @type {string}
     * @memberof ApiResponseError
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiResponseError
     */
    'messages'?: Array<string>;
}
/**
 * 承認ステータス
 * @export
 * @enum {string}
 */

export const ApprovalStatus = {
    Review: 'review',
    Approved: 'approved',
    Rejected: 'rejected'
} as const;

export type ApprovalStatus = typeof ApprovalStatus[keyof typeof ApprovalStatus];


/**
 * 
 * @export
 * @interface Creation
 */
export interface Creation {
    /**
     * 
     * @type {number}
     * @memberof Creation
     */
    'no': number;
    /**
     * 
     * @type {string}
     * @memberof Creation
     */
    'postScheduledAt': string;
    /**
     * 
     * @type {string}
     * @memberof Creation
     */
    'mediaImage': string;
    /**
     * 
     * @type {string}
     * @memberof Creation
     */
    'textImage': string;
    /**
     * 
     * @type {PostingType}
     * @memberof Creation
     */
    'type': PostingType;
    /**
     * 
     * @type {CreationStatus}
     * @memberof Creation
     */
    'status': CreationStatus;
    /**
     * コメント数（イメージ案のコメント数）
     * @type {number}
     * @memberof Creation
     */
    'comments': number;
}


/**
 * 
 * @export
 * @interface CreationAccountMeasure
 */
export interface CreationAccountMeasure {
    /**
     * 
     * @type {string}
     * @memberof CreationAccountMeasure
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CreationAccountMeasure
     */
    'createdAt': string;
    /**
     * 
     * @type {number}
     * @memberof CreationAccountMeasure
     */
    'postsPlan': number;
    /**
     * 
     * @type {number}
     * @memberof CreationAccountMeasure
     */
    'postsResult': number;
    /**
     * 
     * @type {string}
     * @memberof CreationAccountMeasure
     */
    'creatorName': string;
    /**
     * 
     * @type {string}
     * @memberof CreationAccountMeasure
     */
    'thumbnail': string;
}
/**
 * 
 * @export
 * @interface CreationAccountMeasureRows
 */
export interface CreationAccountMeasureRows {
    /**
     * 
     * @type {Array<CreationAccountMeasure>}
     * @memberof CreationAccountMeasureRows
     */
    'rows': Array<CreationAccountMeasure>;
}
/**
 * 
 * @export
 * @interface CreationAccountRow
 */
export interface CreationAccountRow {
    /**
     * 
     * @type {string}
     * @memberof CreationAccountRow
     */
    'id': string;
    /**
     * 
     * @type {SnsType}
     * @memberof CreationAccountRow
     */
    'sns': SnsType;
    /**
     * 
     * @type {string}
     * @memberof CreationAccountRow
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface CreationContents
 */
export interface CreationContents {
    /**
     * 
     * @type {string}
     * @memberof CreationContents
     */
    'contentId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CreationContents
     */
    'media': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CreationContents
     */
    'body': string | null;
    /**
     * 
     * @type {number}
     * @memberof CreationContents
     */
    'comments': number;
}
/**
 * 
 * @export
 * @interface CreationDraft
 */
export interface CreationDraft {
    /**
     * 
     * @type {string}
     * @memberof CreationDraft
     */
    'contentId': string;
    /**
     * 
     * @type {string}
     * @memberof CreationDraft
     */
    'postScheduledAt': string;
    /**
     * 
     * @type {PostingType}
     * @memberof CreationDraft
     */
    'type': PostingType;
    /**
     * 
     * @type {Array<CreationDraftMedia>}
     * @memberof CreationDraft
     */
    'images': Array<CreationDraftMedia> | null;
    /**
     * 
     * @type {number}
     * @memberof CreationDraft
     */
    'comments': number;
    /**
     * 
     * @type {string}
     * @memberof CreationDraft
     */
    'description': string;
}


/**
 * 
 * @export
 * @interface CreationDraftMedia
 */
export interface CreationDraftMedia {
    /**
     * 
     * @type {string}
     * @memberof CreationDraftMedia
     */
    'mediaImage': string | null;
    /**
     * 
     * @type {string}
     * @memberof CreationDraftMedia
     */
    'textImage': string | null;
}
/**
 * 
 * @export
 * @interface CreationPreviewItem
 */
export interface CreationPreviewItem {
    /**
     * 
     * @type {string}
     * @memberof CreationPreviewItem
     */
    'image': string;
    /**
     * image:画像, multi_image:複数枚画像, movie:動画; image以外はアイコン表示
     * @type {string}
     * @memberof CreationPreviewItem
     */
    'type': CreationPreviewItemTypeEnum;
}

export const CreationPreviewItemTypeEnum = {
    Image: 'image',
    MultiImage: 'multi_image',
    Movie: 'movie'
} as const;

export type CreationPreviewItemTypeEnum = typeof CreationPreviewItemTypeEnum[keyof typeof CreationPreviewItemTypeEnum];

/**
 * 
 * @export
 * @interface CreationRows
 */
export interface CreationRows {
    /**
     * 
     * @type {Array<Creation>}
     * @memberof CreationRows
     */
    'rows': Array<Creation>;
}
/**
 * <table> <tr><td>初期状態</td><td>INITIALIZED</td></tr> <tr><td>イメージ案下書き</td><td>DRAFT</td></tr> <tr><td>イメージ案承認済</td><td>DRAFT_APPROVED</td></tr> <tr><td>コンテンツ案下書き</td><td>CONTENTS</td></tr> <tr><td>コンテンツ案承認済</td><td>CONTENTS_APPROVED</td></tr> <tr><td>完了</td><td>COMPLETED</td></tr> </table>
 * @export
 * @enum {string}
 */

export const CreationStatus = {
    Initialized: 'initialized',
    Draft: 'draft',
    DraftApproved: 'draft_approved',
    Contents: 'contents',
    ContentsApproved: 'contents_approved',
    Completed: 'completed'
} as const;

export type CreationStatus = typeof CreationStatus[keyof typeof CreationStatus];


/**
 * 
 * @export
 * @interface Evaluation
 */
export interface Evaluation {
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluation': number;
    /**
     * 
     * @type {number}
     * @memberof Evaluation
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {Array<EvaluationRate>}
     * @memberof Evaluation
     */
    'evaluationRates': Array<EvaluationRate>;
}
/**
 * 
 * @export
 * @interface EvaluationRate
 */
export interface EvaluationRate {
    /**
     * 
     * @type {number}
     * @memberof EvaluationRate
     */
    'rate': number;
    /**
     * 1〜5の整数値
     * @type {number}
     * @memberof EvaluationRate
     */
    'value': number;
}
/**
 * 端数処理種別
 * @export
 * @enum {string}
 */

export const FractionType = {
    Floor: 'floor',
    Ceiling: 'ceiling',
    Round: 'round'
} as const;

export type FractionType = typeof FractionType[keyof typeof FractionType];


/**
 * 
 * @export
 * @interface GetCreationAccountMeasuresOutput
 */
export interface GetCreationAccountMeasuresOutput {
    /**
     * 
     * @type {number}
     * @memberof GetCreationAccountMeasuresOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationAccountMeasuresOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationAccountMeasuresOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationAccountMeasuresOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationAccountMeasuresOutput
     */
    'totalPages': number;
    /**
     * 
     * @type {Array<CreationAccountMeasure>}
     * @memberof GetCreationAccountMeasuresOutput
     */
    'rows': Array<CreationAccountMeasure>;
}
/**
 * 
 * @export
 * @interface GetCreationAccounts400Response
 */
export interface GetCreationAccounts400Response {
    /**
     * 
     * @type {string}
     * @memberof GetCreationAccounts400Response
     */
    'error': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCreationAccounts400Response
     */
    'messages'?: Array<string>;
}
/**
 * 
 * @export
 * @interface GetCreationOutput
 */
export interface GetCreationOutput {
    /**
     * 
     * @type {number}
     * @memberof GetCreationOutput
     */
    'no': number;
    /**
     * 
     * @type {CreationStatus}
     * @memberof GetCreationOutput
     */
    'status': CreationStatus;
    /**
     * 
     * @type {CreationDraft}
     * @memberof GetCreationOutput
     */
    'draft': CreationDraft;
    /**
     * 
     * @type {CreationContents}
     * @memberof GetCreationOutput
     */
    'contents': CreationContents;
}


/**
 * 
 * @export
 * @interface GetCreationsOutput
 */
export interface GetCreationsOutput {
    /**
     * 
     * @type {number}
     * @memberof GetCreationsOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationsOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationsOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationsOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof GetCreationsOutput
     */
    'totalPages': number;
    /**
     * 
     * @type {Array<Creation>}
     * @memberof GetCreationsOutput
     */
    'rows': Array<Creation>;
}
/**
 * 
 * @export
 * @interface PaginationOutput
 */
export interface PaginationOutput {
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'from': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'to': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'perPage': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalRows': number;
    /**
     * 
     * @type {number}
     * @memberof PaginationOutput
     */
    'totalPages': number;
}
/**
 * 
 * @export
 * @interface PatchCreationContentsInput
 */
export interface PatchCreationContentsInput {
    /**
     * 
     * @type {Array<string>}
     * @memberof PatchCreationContentsInput
     */
    'media'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PatchCreationContentsInput
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface PatchCreationDraftMedia
 */
export interface PatchCreationDraftMedia {
    /**
     * 
     * @type {string}
     * @memberof PatchCreationDraftMedia
     */
    'mediaImage'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PatchCreationDraftMedia
     */
    'textImage'?: string | null;
}
/**
 * 
 * @export
 * @interface PatchCreationImageInput
 */
export interface PatchCreationImageInput {
    /**
     * 
     * @type {Array<PatchCreationDraftMedia>}
     * @memberof PatchCreationImageInput
     */
    'images'?: Array<PatchCreationDraftMedia>;
    /**
     * 
     * @type {string}
     * @memberof PatchCreationImageInput
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @interface PatchCreationInput
 */
export interface PatchCreationInput {
    /**
     * 
     * @type {string}
     * @memberof PatchCreationInput
     */
    'title'?: string;
    /**
     * 
     * @type {PostingType}
     * @memberof PatchCreationInput
     */
    'type': PostingType;
    /**
     * 
     * @type {string}
     * @memberof PatchCreationInput
     */
    'postingDate'?: string;
}


/**
 * 
 * @export
 * @interface PatchMeasureInput
 */
export interface PatchMeasureInput {
    /**
     * 
     * @type {string}
     * @memberof PatchMeasureInput
     */
    'name': string;
}
/**
 * 
 * @export
 * @interface PostCreationAccountInput
 */
export interface PostCreationAccountInput {
    /**
     * 
     * @type {SnsType}
     * @memberof PostCreationAccountInput
     */
    'sns': SnsType;
    /**
     * 
     * @type {string}
     * @memberof PostCreationAccountInput
     */
    'name': string;
}


/**
 * 
 * @export
 * @interface PostCreationApproveInput
 */
export interface PostCreationApproveInput {
    /**
     * 
     * @type {string}
     * @memberof PostCreationApproveInput
     */
    'measureId': string;
}
/**
 * 
 * @export
 * @interface PostCreationApproveOutput
 */
export interface PostCreationApproveOutput {
    /**
     * <table> <tr><td>承認済</td><td>approved</td></tr> </table>
     * @type {string}
     * @memberof PostCreationApproveOutput
     */
    'result': PostCreationApproveOutputResultEnum;
    /**
     * 
     * @type {CreationStatus}
     * @memberof PostCreationApproveOutput
     */
    'status': CreationStatus;
}

export const PostCreationApproveOutputResultEnum = {
    Approved: 'approved'
} as const;

export type PostCreationApproveOutputResultEnum = typeof PostCreationApproveOutputResultEnum[keyof typeof PostCreationApproveOutputResultEnum];

/**
 * 
 * @export
 * @interface PostCreationContentsInput
 */
export interface PostCreationContentsInput {
    /**
     * 
     * @type {string}
     * @memberof PostCreationContentsInput
     */
    'measureId': string;
    /**
     * 
     * @type {string}
     * @memberof PostCreationContentsInput
     */
    'contentsId': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PostCreationContentsInput
     */
    'media'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof PostCreationContentsInput
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface PostCreationImageInput
 */
export interface PostCreationImageInput {
    /**
     * 
     * @type {string}
     * @memberof PostCreationImageInput
     */
    'measureId': string;
    /**
     * 
     * @type {Array<CreationDraftMedia>}
     * @memberof PostCreationImageInput
     */
    'images'?: Array<CreationDraftMedia> | null;
    /**
     * 
     * @type {string}
     * @memberof PostCreationImageInput
     */
    'movieUrl'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCreationImageInput
     */
    'movieDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof PostCreationImageInput
     */
    'description': string | null;
}
/**
 * 
 * @export
 * @interface PostCreationInput
 */
export interface PostCreationInput {
    /**
     * 
     * @type {string}
     * @memberof PostCreationInput
     */
    'title': string;
    /**
     * 
     * @type {PostingType}
     * @memberof PostCreationInput
     */
    'type': PostingType;
    /**
     * 
     * @type {string}
     * @memberof PostCreationInput
     */
    'postingDate': string;
}


/**
 * 
 * @export
 * @interface PostMeasureInput
 */
export interface PostMeasureInput {
    /**
     * 
     * @type {string}
     * @memberof PostMeasureInput
     */
    'name': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const PostingType = {
    Reel: 'reel',
    Feed: 'feed',
    Stories: 'stories',
    XImagePost: 'x_image_post',
    XPost: 'x_post',
    YoutubeShort: 'youtube_short',
    Youtube: 'youtube',
    TikTok: 'tik_tok',
    OtherVideo: 'other_video',
    Other: 'other'
} as const;

export type PostingType = typeof PostingType[keyof typeof PostingType];


/**
 * 
 * @export
 * @interface ProjectApplicantItem
 */
export interface ProjectApplicantItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'id': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'applicantIndex': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountNo': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'snsAccountId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'thumbnail': string | null;
    /**
     * 採用されてない場合はnull
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'adoptedInfluencerId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluation': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'evaluationCounts': number;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'follow': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'enthusiasm': string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isAlternate': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'operatorComment': string | null;
    /**
     * 採用:1、補欠:2、不採用:3
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'selectionStatus': number;
    /**
     * 最終確認 unconfirmed: 未確認 confirming: 確認中 confirmed_ok: 確認OK confirmed_ng: 確認NG
     * @type {string}
     * @memberof ProjectApplicantItem
     */
    'isFinalConfirmation'?: ProjectApplicantItemIsFinalConfirmationEnum;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectApplicantItem
     */
    'isGoodQuality'?: boolean | null;
    /**
     * フォロワー数/チャンネル登録者数
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'followers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'following'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'impressions'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reach'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reachRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'preservations'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagements'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'engagementRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'frequency'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'reply'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'repost'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'goodAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'shareAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'views'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'viewRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'commentsAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'highEvaluationAvg'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectApplicantItem
     */
    'mediaCount'?: number | null;
}

export const ProjectApplicantItemIsFinalConfirmationEnum = {
    Unconfirmed: 'unconfirmed',
    Confirming: 'confirming',
    ConfirmedOk: 'confirmed_ok',
    ConfirmedNg: 'confirmed_ng'
} as const;

export type ProjectApplicantItemIsFinalConfirmationEnum = typeof ProjectApplicantItemIsFinalConfirmationEnum[keyof typeof ProjectApplicantItemIsFinalConfirmationEnum];

/**
 * 
 * @export
 * @interface ProjectCalculateReward
 */
export interface ProjectCalculateReward {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectCalculateReward
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'standard': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectCalculateReward
     */
    'minimum': number;
}
/**
 * 
 * @export
 * @interface ProjectDetailHeader
 */
export interface ProjectDetailHeader {
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'projectName': string;
    /**
     * 
     * @type {ProjectType}
     * @memberof ProjectDetailHeader
     */
    'projectType': ProjectType;
    /**
     * 
     * @type {ProjectStatus}
     * @memberof ProjectDetailHeader
     */
    'projectStatus': ProjectStatus;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleStartedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'scheduleEndedAt': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerId': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDetailHeader
     */
    'managerName': string | null;
}


/**
 * 
 * @export
 * @interface ProjectDetailMediaInput
 */
export interface ProjectDetailMediaInput {
    /**
     * 
     * @type {Array<File>}
     * @memberof ProjectDetailMediaInput
     */
    'uploads'?: Array<File>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectDetailMediaInput
     */
    'deleteIds'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ProjectEstimate
 */
export interface ProjectEstimate {
    /**
     * 
     * @type {boolean}
     * @memberof ProjectEstimate
     */
    'isCalculated': boolean;
    /**
     * 
     * @type {ProjectEstimateItemGroups}
     * @memberof ProjectEstimate
     */
    'items': ProjectEstimateItemGroups;
    /**
     * オプション選択肢配列
     * @type {Array<ProjectOptionChoice>}
     * @memberof ProjectEstimate
     */
    'optionChoices': Array<ProjectOptionChoice>;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'subtotal': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'tax': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'total': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimate
     */
    'taxRate': number;
    /**
     * 
     * @type {FractionType}
     * @memberof ProjectEstimate
     */
    'fractionType': FractionType;
    /**
     * 
     * @type {ProjectCalculateReward}
     * @memberof ProjectEstimate
     */
    'calculateReward': ProjectCalculateReward;
}


/**
 * 
 * @export
 * @interface ProjectEstimateItem
 */
export interface ProjectEstimateItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectEstimateItem
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'counts': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'price': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectEstimateItem
     */
    'total': number;
}
/**
 * 
 * @export
 * @interface ProjectEstimateItemGroups
 */
export interface ProjectEstimateItemGroups {
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'fixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFixedItems': Array<ProjectEstimateItem>;
    /**
     * 案件見積明細配列
     * @type {Array<ProjectEstimateItem>}
     * @memberof ProjectEstimateItemGroups
     */
    'optionFreeItems': Array<ProjectEstimateItem>;
}
/**
 * 
 * @export
 * @interface ProjectImageRow
 */
export interface ProjectImageRow {
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectImageRow
     */
    'objectKey': string;
}
/**
 * 
 * @export
 * @interface ProjectKpi
 */
export interface ProjectKpi {
    /**
     * KPI項目配列
     * @type {Array<ProjectKpiItem>}
     * @memberof ProjectKpi
     */
    'items': Array<ProjectKpiItem>;
}
/**
 * 
 * @export
 * @interface ProjectKpiItem
 */
export interface ProjectKpiItem {
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectKpiItem
     */
    'comment': string;
    /**
     * POST時isFixedは指定しなくてもtrueになります。
     * @type {boolean}
     * @memberof ProjectKpiItem
     */
    'isFixed': boolean;
}
/**
 * 
 * @export
 * @interface ProjectOptionChoice
 */
export interface ProjectOptionChoice {
    /**
     * 
     * @type {string}
     * @memberof ProjectOptionChoice
     */
    'name': string;
    /**
     * 
     * @type {number}
     * @memberof ProjectOptionChoice
     */
    'price': number;
}
/**
 * 最終確定日など期間ではないものはfromにnullを設定する
 * @export
 * @interface ProjectSchedule
 */
export interface ProjectSchedule {
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'from': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSchedule
     */
    'to': string | null;
}
/**
 * 案件スケジュールステータス
 * @export
 * @enum {string}
 */

export const ProjectScheduleStatus = {
    Before: 'before',
    During: 'during',
    Ending: 'ending'
} as const;

export type ProjectScheduleStatus = typeof ProjectScheduleStatus[keyof typeof ProjectScheduleStatus];


/**
 * <table> <tr><td>募集内容作成（下書き）</td><td>draft</td></tr> <tr><td>募集内容作成（申請中）</td><td>review</td></tr> <tr><td>募集〜選考</td><td>approved</td></tr> <tr><td>最終確認</td><td>final_confirmation</td></tr> <tr><td>実施中</td><td>implementation</td></tr> <tr><td>完了</td><td>completion</td></tr> <tr><td>募集完了(PRST)</td><td>accept_completion</td></tr> <tr><td>配信詳細作成（下書き）</td><td>post_draft</td></tr> <tr><td>配信詳細作成（申請中）</td><td>post_review</td></tr> <tr><td>配信待ち(EMERALD POST)</td><td>wait_post</td></tr> <tr><td>配信中(EMERALD POST)</td><td>posting</td></tr> <tr><td>配信完了(EMERALD POST)</td><td>post_completion</td></tr> </table>
 * @export
 * @enum {string}
 */

export const ProjectStatus = {
    Draft: 'draft',
    Review: 'review',
    Approved: 'approved',
    FinalConfirmation: 'final_confirmation',
    WaitPost: 'wait_post',
    Implementation: 'implementation',
    PostDraft: 'post_draft',
    PostReview: 'post_review',
    Posting: 'posting',
    Completion: 'completion',
    PostCompletion: 'post_completion',
    AcceptCompletion: 'accept_completion'
} as const;

export type ProjectStatus = typeof ProjectStatus[keyof typeof ProjectStatus];


/**
 * 
 * @export
 * @interface ProjectSummaryApplicationCondition
 */
export interface ProjectSummaryApplicationCondition {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minFollowers': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'gender': ProjectSummaryApplicationConditionGenderEnum | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAge': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAge': number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryApplicationCondition
     */
    'prefectures': Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'minAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'maxAnnualIncome': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryApplicationCondition
     */
    'job': number | null;
}

export const ProjectSummaryApplicationConditionGenderEnum = {
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type ProjectSummaryApplicationConditionGenderEnum = typeof ProjectSummaryApplicationConditionGenderEnum[keyof typeof ProjectSummaryApplicationConditionGenderEnum];

/**
 * 
 * @export
 * @interface ProjectSummaryBase
 */
export interface ProjectSummaryBase {
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'adoptionPlan': number;
    /**
     * 0:適正相場 1:カスタム設定 （Spirit以外では無視されます）
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewardsType': number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryBase
     */
    'rewards': number;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryBase
     */
    'genres': Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryBase
     */
    'description': string;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDetails
 */
export interface ProjectSummaryDetails {
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectSummaryDetails
     */
    'notice': Array<string> | null;
    /**
     * true:非公開 false:公開
     * @type {boolean}
     * @memberof ProjectSummaryDetails
     */
    'isSecret': boolean;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDetails
     */
    'companyName': string;
}
/**
 * 
 * @export
 * @interface ProjectTitleInput
 */
export interface ProjectTitleInput {
    /**
     * 
     * @type {string}
     * @memberof ProjectTitleInput
     */
    'title': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ProjectTitleInput
     */
    'tags': Array<string>;
    /**
     * 
     * @type {File}
     * @memberof ProjectTitleInput
     */
    'featuredImage'?: File;
}
/**
 * 案件タイプ
 * @export
 * @enum {string}
 */

export const ProjectType = {
    Spirit: 'spirit',
    EmeraldPost: 'emerald_post',
    Prst: 'prst',
    JaneJohn: 'jane_john',
    Other: 'other'
} as const;

export type ProjectType = typeof ProjectType[keyof typeof ProjectType];


/**
 * 
 * @export
 * @interface ProjectTypeItem
 */
export interface ProjectTypeItem {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeItem
     */
    'no': number;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectId': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectGroupName': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'projectName': string;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'startDate': string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectTypeItem
     */
    'endDate': string | null;
}
/**
 * 
 * @export
 * @interface ProjectTypeListCounts
 */
export interface ProjectTypeListCounts {
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'all': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'draft': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'review': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'approved': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'finalConfirmation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'implementation': number;
    /**
     * 
     * @type {number}
     * @memberof ProjectTypeListCounts
     */
    'completion': number;
}
/**
 * SNSタイプ
 * @export
 * @enum {string}
 */

export const SnsType = {
    Instagram: 'instagram',
    X: 'x',
    TikTok: 'tik_tok',
    Youtube: 'youtube',
    Other: 'other'
} as const;

export type SnsType = typeof SnsType[keyof typeof SnsType];


/**
 * 
 * @export
 * @interface UploadedImageOutput
 */
export interface UploadedImageOutput {
    /**
     * 
     * @type {string}
     * @memberof UploadedImageOutput
     */
    'uploadedFilePath'?: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した投稿制作の削除を行うAPIです。
         * @summary 【Mock】投稿制作削除API
         * @param {string} creationId 投稿制作ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreation: async (creationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('deleteCreation', 'creationId', creationId)
            const localVarPath = `/creations/{creationId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コンテンツを削除するAPIです。 <br>コンテンツIDを指定してコンテンツを削除します。
         * @summary 【Mock】コンテンツ削除API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreationContent: async (creationId: string, contentsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('deleteCreationContent', 'creationId', creationId)
            // verify required parameter 'contentsId' is not null or undefined
            assertParamExists('deleteCreationContent', 'contentsId', contentsId)
            const localVarPath = `/creations/{creationId}/contents/{contentsId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)))
                .replace(`{${"contentsId"}}`, encodeURIComponent(String(contentsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イメージ案を削除するAPIです。 <br>イメージ案IDを指定してイメージ案を削除します。
         * @summary 【Mock】イメージ案削除API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreationImage: async (creationId: string, contentsId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('deleteCreationImage', 'creationId', creationId)
            // verify required parameter 'contentsId' is not null or undefined
            assertParamExists('deleteCreationImage', 'contentsId', contentsId)
            const localVarPath = `/creations/{creationId}/image/{contentsId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)))
                .replace(`{${"contentsId"}}`, encodeURIComponent(String(contentsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した施策の削除を行うAPIです。
         * @summary 【Mock】施策削除API
         * @param {string} measureId 制作管理施策ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeasure: async (measureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measureId' is not null or undefined
            assertParamExists('deleteMeasure', 'measureId', measureId)
            const localVarPath = `/measures/{measureId}`
                .replace(`{${"measureId"}}`, encodeURIComponent(String(measureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿制作詳細を取得するAPIです。 <br>投稿制作詳細を返却します。
         * @summary 【Mock】投稿制作詳細取得API
         * @param {string} creationId 投稿制作ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreation: async (creationId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('getCreation', 'creationId', creationId)
            const localVarPath = `/creations/{creationId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 制作管理施策一覧を取得するAPIです。 <br>制作管理施策一覧を返却します。
         * @summary 【Mock】制作管理施策一覧取得API
         * @param {string} accountId 制作管理アカウントID
         * @param {GetCreationAccountMeasuresRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetCreationAccountMeasuresOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationAccountMeasures: async (accountId: string, rows?: GetCreationAccountMeasuresRowsEnum, page?: number, order?: GetCreationAccountMeasuresOrderEnum, orderBy?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountId' is not null or undefined
            assertParamExists('getCreationAccountMeasures', 'accountId', accountId)
            const localVarPath = `/creation-accounts/{accountId}/measures`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rows !== undefined) {
                localVarQueryParameter['rows'] = rows;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 制作管理アカウント一覧を取得するAPIです。 <br>制作管理アカウント一覧を返却します。
         * @summary 【Mock】制作管理アカウント一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/creation-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿制作の施策内のプレビューをします。
         * @summary 【Mock】投稿制作並び順プレビューAPI
         * @param {string} measureId 制作管理施策ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationPreview: async (measureId: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measureId' is not null or undefined
            assertParamExists('getCreationPreview', 'measureId', measureId)
            const localVarPath = `/measures/{measureId}/preview`
                .replace(`{${"measureId"}}`, encodeURIComponent(String(measureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿制作一覧を取得するAPIです。 <br>投稿制作一覧を返却します。
         * @summary 【Mock】投稿制作一覧取得API
         * @param {string} measureId 制作管理施策ID
         * @param {GetCreationsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetCreationsOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreations: async (measureId: string, rows?: GetCreationsRowsEnum, page?: number, order?: GetCreationsOrderEnum, orderBy?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measureId' is not null or undefined
            assertParamExists('getCreations', 'measureId', measureId)
            const localVarPath = `/measures/{measureId}/creations`
                .replace(`{${"measureId"}}`, encodeURIComponent(String(measureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (rows !== undefined) {
                localVarQueryParameter['rows'] = rows;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (order !== undefined) {
                localVarQueryParameter['order'] = order;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['orderBy'] = orderBy;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿制作の更新を行うAPIです。
         * @summary 【Mock】投稿制作更新API
         * @param {string} creationId 投稿制作ID
         * @param {PatchCreationInput} [patchCreationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCreation: async (creationId: string, patchCreationInput?: PatchCreationInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('patchCreation', 'creationId', creationId)
            const localVarPath = `/creations/{creationId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCreationInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コンテンツを編集するAPIです。 <br>コンテンツIDを指定してコンテンツを編集します。
         * @summary 【Mock】コンテンツ編集API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PatchCreationContentsInput} [patchCreationContentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCreationContents: async (creationId: string, contentsId: string, patchCreationContentsInput?: PatchCreationContentsInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('patchCreationContents', 'creationId', creationId)
            // verify required parameter 'contentsId' is not null or undefined
            assertParamExists('patchCreationContents', 'contentsId', contentsId)
            const localVarPath = `/creations/{creationId}/contents/{contentsId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)))
                .replace(`{${"contentsId"}}`, encodeURIComponent(String(contentsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCreationContentsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イメージ案を編集するAPIです。 <br>イメージ案IDを指定してイメージ案を編集します。
         * @summary 【Mock】イメージ案編集API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PatchCreationImageInput} [patchCreationImageInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCreationImage: async (creationId: string, contentsId: string, patchCreationImageInput?: PatchCreationImageInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('patchCreationImage', 'creationId', creationId)
            // verify required parameter 'contentsId' is not null or undefined
            assertParamExists('patchCreationImage', 'contentsId', contentsId)
            const localVarPath = `/creations/{creationId}/image/{contentsId}`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)))
                .replace(`{${"contentsId"}}`, encodeURIComponent(String(contentsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchCreationImageInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 制作管理施策を更新するAPIです。 <br>制作管理施策を更新します。
         * @summary 【Mock】制作管理施策更新API
         * @param {string} measureId 制作管理施策ID
         * @param {PatchMeasureInput} [patchMeasureInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMeasure: async (measureId: string, patchMeasureInput?: PatchMeasureInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measureId' is not null or undefined
            assertParamExists('patchMeasure', 'measureId', measureId)
            const localVarPath = `/measures/{measureId}`
                .replace(`{${"measureId"}}`, encodeURIComponent(String(measureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(patchMeasureInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿制作を作成するAPIです。 <br>投稿制作を作成します。
         * @summary 【Mock】投稿制作作成API
         * @param {string} measureId 制作管理施策ID
         * @param {PostCreationInput} [postCreationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreation: async (measureId: string, postCreationInput?: PostCreationInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'measureId' is not null or undefined
            assertParamExists('postCreation', 'measureId', measureId)
            const localVarPath = `/measures/{measureId}/creations`
                .replace(`{${"measureId"}}`, encodeURIComponent(String(measureId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreationInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 制作管理アカウントを登録するAPIです。 <br>制作管理アカウントを登録します。
         * @summary 【Mock】制作管理アカウント登録API
         * @param {PostCreationAccountInput} [postCreationAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationAccount: async (postCreationAccountInput?: PostCreationAccountInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/creation-accounts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreationAccountInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿制作を承認するAPIです。 <br>コンテンツIDを指定して投稿制作を承認します。
         * @summary 【Mock】投稿制作承認API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PostCreationApproveInput} [postCreationApproveInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationApprove: async (creationId: string, contentsId: string, postCreationApproveInput?: PostCreationApproveInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('postCreationApprove', 'creationId', creationId)
            // verify required parameter 'contentsId' is not null or undefined
            assertParamExists('postCreationApprove', 'contentsId', contentsId)
            const localVarPath = `/creations/{creationId}/contents/{contentsId}/approve`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)))
                .replace(`{${"contentsId"}}`, encodeURIComponent(String(contentsId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreationApproveInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * コンテンツを作成するAPIです。 <br>コンテンツを作成します。
         * @summary 【Mock】コンテンツ作成API
         * @param {string} creationId 投稿制作ID
         * @param {PostCreationContentsInput} [postCreationContentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationContents: async (creationId: string, postCreationContentsInput?: PostCreationContentsInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('postCreationContents', 'creationId', creationId)
            const localVarPath = `/creations/{creationId}/contents`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreationContentsInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * イメージ案を作成するAPIです。 <br>イメージ案を作成します。
         * @summary 【Mock】イメージ案作成API
         * @param {string} creationId 投稿制作ID
         * @param {PostCreationImageInput} [postCreationImageInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationImage: async (creationId: string, postCreationImageInput?: PostCreationImageInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'creationId' is not null or undefined
            assertParamExists('postCreationImage', 'creationId', creationId)
            const localVarPath = `/creations/{creationId}/image`
                .replace(`{${"creationId"}}`, encodeURIComponent(String(creationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postCreationImageInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 制作管理施策を登録するAPIです。 <br>制作管理施策を登録します。
         * @summary 【Mock】制作管理施策登録API
         * @param {PostMeasureInput} [postMeasureInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeasure: async (postMeasureInput?: PostMeasureInput, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/measures`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearer required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(postMeasureInput, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した投稿制作の削除を行うAPIです。
         * @summary 【Mock】投稿制作削除API
         * @param {string} creationId 投稿制作ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCreation(creationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCreation(creationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteCreation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * コンテンツを削除するAPIです。 <br>コンテンツIDを指定してコンテンツを削除します。
         * @summary 【Mock】コンテンツ削除API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCreationContent(creationId: string, contentsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCreationContent(creationId, contentsId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteCreationContent']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * イメージ案を削除するAPIです。 <br>イメージ案IDを指定してイメージ案を削除します。
         * @summary 【Mock】イメージ案削除API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteCreationImage(creationId: string, contentsId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteCreationImage(creationId, contentsId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteCreationImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定した施策の削除を行うAPIです。
         * @summary 【Mock】施策削除API
         * @param {string} measureId 制作管理施策ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteMeasure(measureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteMeasure(measureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.deleteMeasure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 投稿制作詳細を取得するAPIです。 <br>投稿制作詳細を返却します。
         * @summary 【Mock】投稿制作詳細取得API
         * @param {string} creationId 投稿制作ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreation(creationId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreationOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreation(creationId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCreation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 制作管理施策一覧を取得するAPIです。 <br>制作管理施策一覧を返却します。
         * @summary 【Mock】制作管理施策一覧取得API
         * @param {string} accountId 制作管理アカウントID
         * @param {GetCreationAccountMeasuresRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetCreationAccountMeasuresOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationAccountMeasures(accountId: string, rows?: GetCreationAccountMeasuresRowsEnum, page?: number, order?: GetCreationAccountMeasuresOrderEnum, orderBy?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreationAccountMeasuresOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationAccountMeasures(accountId, rows, page, order, orderBy, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCreationAccountMeasures']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 制作管理アカウント一覧を取得するAPIです。 <br>制作管理アカウント一覧を返却します。
         * @summary 【Mock】制作管理アカウント一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreationAccountRow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationAccounts(options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCreationAccounts']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 投稿制作の施策内のプレビューをします。
         * @summary 【Mock】投稿制作並び順プレビューAPI
         * @param {string} measureId 制作管理施策ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreationPreview(measureId: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CreationPreviewItem>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreationPreview(measureId, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCreationPreview']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 投稿制作一覧を取得するAPIです。 <br>投稿制作一覧を返却します。
         * @summary 【Mock】投稿制作一覧取得API
         * @param {string} measureId 制作管理施策ID
         * @param {GetCreationsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetCreationsOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCreations(measureId: string, rows?: GetCreationsRowsEnum, page?: number, order?: GetCreationsOrderEnum, orderBy?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetCreationsOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCreations(measureId, rows, page, order, orderBy, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.getCreations']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 指定した投稿制作の更新を行うAPIです。
         * @summary 【Mock】投稿制作更新API
         * @param {string} creationId 投稿制作ID
         * @param {PatchCreationInput} [patchCreationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCreation(creationId: string, patchCreationInput?: PatchCreationInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCreation(creationId, patchCreationInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchCreation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * コンテンツを編集するAPIです。 <br>コンテンツIDを指定してコンテンツを編集します。
         * @summary 【Mock】コンテンツ編集API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PatchCreationContentsInput} [patchCreationContentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCreationContents(creationId: string, contentsId: string, patchCreationContentsInput?: PatchCreationContentsInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCreationContents(creationId, contentsId, patchCreationContentsInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchCreationContents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * イメージ案を編集するAPIです。 <br>イメージ案IDを指定してイメージ案を編集します。
         * @summary 【Mock】イメージ案編集API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PatchCreationImageInput} [patchCreationImageInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchCreationImage(creationId: string, contentsId: string, patchCreationImageInput?: PatchCreationImageInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchCreationImage(creationId, contentsId, patchCreationImageInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchCreationImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 制作管理施策を更新するAPIです。 <br>制作管理施策を更新します。
         * @summary 【Mock】制作管理施策更新API
         * @param {string} measureId 制作管理施策ID
         * @param {PatchMeasureInput} [patchMeasureInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async patchMeasure(measureId: string, patchMeasureInput?: PatchMeasureInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.patchMeasure(measureId, patchMeasureInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.patchMeasure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 投稿制作を作成するAPIです。 <br>投稿制作を作成します。
         * @summary 【Mock】投稿制作作成API
         * @param {string} measureId 制作管理施策ID
         * @param {PostCreationInput} [postCreationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreation(measureId: string, postCreationInput?: PostCreationInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreation(measureId, postCreationInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postCreation']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 制作管理アカウントを登録するAPIです。 <br>制作管理アカウントを登録します。
         * @summary 【Mock】制作管理アカウント登録API
         * @param {PostCreationAccountInput} [postCreationAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreationAccount(postCreationAccountInput?: PostCreationAccountInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreationAccount(postCreationAccountInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postCreationAccount']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 投稿制作を承認するAPIです。 <br>コンテンツIDを指定して投稿制作を承認します。
         * @summary 【Mock】投稿制作承認API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PostCreationApproveInput} [postCreationApproveInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreationApprove(creationId: string, contentsId: string, postCreationApproveInput?: PostCreationApproveInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PostCreationApproveOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreationApprove(creationId, contentsId, postCreationApproveInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postCreationApprove']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * コンテンツを作成するAPIです。 <br>コンテンツを作成します。
         * @summary 【Mock】コンテンツ作成API
         * @param {string} creationId 投稿制作ID
         * @param {PostCreationContentsInput} [postCreationContentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreationContents(creationId: string, postCreationContentsInput?: PostCreationContentsInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreationContents(creationId, postCreationContentsInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postCreationContents']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * イメージ案を作成するAPIです。 <br>イメージ案を作成します。
         * @summary 【Mock】イメージ案作成API
         * @param {string} creationId 投稿制作ID
         * @param {PostCreationImageInput} [postCreationImageInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postCreationImage(creationId: string, postCreationImageInput?: PostCreationImageInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postCreationImage(creationId, postCreationImageInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postCreationImage']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
        /**
         * 制作管理施策を登録するAPIです。 <br>制作管理施策を登録します。
         * @summary 【Mock】制作管理施策登録API
         * @param {PostMeasureInput} [postMeasureInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async postMeasure(postMeasureInput?: PostMeasureInput, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffectOutput>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.postMeasure(postMeasureInput, options);
            const index = configuration?.serverIndex ?? 0;
            const operationBasePath = operationServerMap['DefaultApi.postMeasure']?.[index]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, operationBasePath || basePath);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 指定した投稿制作の削除を行うAPIです。
         * @summary 【Mock】投稿制作削除API
         * @param {string} creationId 投稿制作ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreation(creationId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.deleteCreation(creationId, options).then((request) => request(axios, basePath));
        },
        /**
         * コンテンツを削除するAPIです。 <br>コンテンツIDを指定してコンテンツを削除します。
         * @summary 【Mock】コンテンツ削除API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreationContent(creationId: string, contentsId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.deleteCreationContent(creationId, contentsId, options).then((request) => request(axios, basePath));
        },
        /**
         * イメージ案を削除するAPIです。 <br>イメージ案IDを指定してイメージ案を削除します。
         * @summary 【Mock】イメージ案削除API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCreationImage(creationId: string, contentsId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.deleteCreationImage(creationId, contentsId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した施策の削除を行うAPIです。
         * @summary 【Mock】施策削除API
         * @param {string} measureId 制作管理施策ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteMeasure(measureId: string, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.deleteMeasure(measureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿制作詳細を取得するAPIです。 <br>投稿制作詳細を返却します。
         * @summary 【Mock】投稿制作詳細取得API
         * @param {string} creationId 投稿制作ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreation(creationId: string, options?: any): AxiosPromise<GetCreationOutput> {
            return localVarFp.getCreation(creationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 制作管理施策一覧を取得するAPIです。 <br>制作管理施策一覧を返却します。
         * @summary 【Mock】制作管理施策一覧取得API
         * @param {string} accountId 制作管理アカウントID
         * @param {GetCreationAccountMeasuresRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetCreationAccountMeasuresOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationAccountMeasures(accountId: string, rows?: GetCreationAccountMeasuresRowsEnum, page?: number, order?: GetCreationAccountMeasuresOrderEnum, orderBy?: string, options?: any): AxiosPromise<GetCreationAccountMeasuresOutput> {
            return localVarFp.getCreationAccountMeasures(accountId, rows, page, order, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 制作管理アカウント一覧を取得するAPIです。 <br>制作管理アカウント一覧を返却します。
         * @summary 【Mock】制作管理アカウント一覧取得API
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationAccounts(options?: any): AxiosPromise<Array<CreationAccountRow>> {
            return localVarFp.getCreationAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿制作の施策内のプレビューをします。
         * @summary 【Mock】投稿制作並び順プレビューAPI
         * @param {string} measureId 制作管理施策ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreationPreview(measureId: string, options?: any): AxiosPromise<Array<CreationPreviewItem>> {
            return localVarFp.getCreationPreview(measureId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿制作一覧を取得するAPIです。 <br>投稿制作一覧を返却します。
         * @summary 【Mock】投稿制作一覧取得API
         * @param {string} measureId 制作管理施策ID
         * @param {GetCreationsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
         * @param {number} [page] 
         * @param {GetCreationsOrderEnum} [order] 
         * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCreations(measureId: string, rows?: GetCreationsRowsEnum, page?: number, order?: GetCreationsOrderEnum, orderBy?: string, options?: any): AxiosPromise<GetCreationsOutput> {
            return localVarFp.getCreations(measureId, rows, page, order, orderBy, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿制作の更新を行うAPIです。
         * @summary 【Mock】投稿制作更新API
         * @param {string} creationId 投稿制作ID
         * @param {PatchCreationInput} [patchCreationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCreation(creationId: string, patchCreationInput?: PatchCreationInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchCreation(creationId, patchCreationInput, options).then((request) => request(axios, basePath));
        },
        /**
         * コンテンツを編集するAPIです。 <br>コンテンツIDを指定してコンテンツを編集します。
         * @summary 【Mock】コンテンツ編集API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PatchCreationContentsInput} [patchCreationContentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCreationContents(creationId: string, contentsId: string, patchCreationContentsInput?: PatchCreationContentsInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchCreationContents(creationId, contentsId, patchCreationContentsInput, options).then((request) => request(axios, basePath));
        },
        /**
         * イメージ案を編集するAPIです。 <br>イメージ案IDを指定してイメージ案を編集します。
         * @summary 【Mock】イメージ案編集API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PatchCreationImageInput} [patchCreationImageInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchCreationImage(creationId: string, contentsId: string, patchCreationImageInput?: PatchCreationImageInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchCreationImage(creationId, contentsId, patchCreationImageInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 制作管理施策を更新するAPIです。 <br>制作管理施策を更新します。
         * @summary 【Mock】制作管理施策更新API
         * @param {string} measureId 制作管理施策ID
         * @param {PatchMeasureInput} [patchMeasureInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        patchMeasure(measureId: string, patchMeasureInput?: PatchMeasureInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.patchMeasure(measureId, patchMeasureInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿制作を作成するAPIです。 <br>投稿制作を作成します。
         * @summary 【Mock】投稿制作作成API
         * @param {string} measureId 制作管理施策ID
         * @param {PostCreationInput} [postCreationInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreation(measureId: string, postCreationInput?: PostCreationInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postCreation(measureId, postCreationInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 制作管理アカウントを登録するAPIです。 <br>制作管理アカウントを登録します。
         * @summary 【Mock】制作管理アカウント登録API
         * @param {PostCreationAccountInput} [postCreationAccountInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationAccount(postCreationAccountInput?: PostCreationAccountInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postCreationAccount(postCreationAccountInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿制作を承認するAPIです。 <br>コンテンツIDを指定して投稿制作を承認します。
         * @summary 【Mock】投稿制作承認API
         * @param {string} creationId 投稿制作ID
         * @param {string} contentsId コンテンツID
         * @param {PostCreationApproveInput} [postCreationApproveInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationApprove(creationId: string, contentsId: string, postCreationApproveInput?: PostCreationApproveInput, options?: any): AxiosPromise<PostCreationApproveOutput> {
            return localVarFp.postCreationApprove(creationId, contentsId, postCreationApproveInput, options).then((request) => request(axios, basePath));
        },
        /**
         * コンテンツを作成するAPIです。 <br>コンテンツを作成します。
         * @summary 【Mock】コンテンツ作成API
         * @param {string} creationId 投稿制作ID
         * @param {PostCreationContentsInput} [postCreationContentsInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationContents(creationId: string, postCreationContentsInput?: PostCreationContentsInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postCreationContents(creationId, postCreationContentsInput, options).then((request) => request(axios, basePath));
        },
        /**
         * イメージ案を作成するAPIです。 <br>イメージ案を作成します。
         * @summary 【Mock】イメージ案作成API
         * @param {string} creationId 投稿制作ID
         * @param {PostCreationImageInput} [postCreationImageInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postCreationImage(creationId: string, postCreationImageInput?: PostCreationImageInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postCreationImage(creationId, postCreationImageInput, options).then((request) => request(axios, basePath));
        },
        /**
         * 制作管理施策を登録するAPIです。 <br>制作管理施策を登録します。
         * @summary 【Mock】制作管理施策登録API
         * @param {PostMeasureInput} [postMeasureInput] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        postMeasure(postMeasureInput?: PostMeasureInput, options?: any): AxiosPromise<AffectOutput> {
            return localVarFp.postMeasure(postMeasureInput, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 指定した投稿制作の削除を行うAPIです。
     * @summary 【Mock】投稿制作削除API
     * @param {string} creationId 投稿制作ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCreation(creationId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCreation(creationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コンテンツを削除するAPIです。 <br>コンテンツIDを指定してコンテンツを削除します。
     * @summary 【Mock】コンテンツ削除API
     * @param {string} creationId 投稿制作ID
     * @param {string} contentsId コンテンツID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCreationContent(creationId: string, contentsId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCreationContent(creationId, contentsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * イメージ案を削除するAPIです。 <br>イメージ案IDを指定してイメージ案を削除します。
     * @summary 【Mock】イメージ案削除API
     * @param {string} creationId 投稿制作ID
     * @param {string} contentsId コンテンツID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteCreationImage(creationId: string, contentsId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteCreationImage(creationId, contentsId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した施策の削除を行うAPIです。
     * @summary 【Mock】施策削除API
     * @param {string} measureId 制作管理施策ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteMeasure(measureId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).deleteMeasure(measureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿制作詳細を取得するAPIです。 <br>投稿制作詳細を返却します。
     * @summary 【Mock】投稿制作詳細取得API
     * @param {string} creationId 投稿制作ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCreation(creationId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCreation(creationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 制作管理施策一覧を取得するAPIです。 <br>制作管理施策一覧を返却します。
     * @summary 【Mock】制作管理施策一覧取得API
     * @param {string} accountId 制作管理アカウントID
     * @param {GetCreationAccountMeasuresRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
     * @param {number} [page] 
     * @param {GetCreationAccountMeasuresOrderEnum} [order] 
     * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCreationAccountMeasures(accountId: string, rows?: GetCreationAccountMeasuresRowsEnum, page?: number, order?: GetCreationAccountMeasuresOrderEnum, orderBy?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCreationAccountMeasures(accountId, rows, page, order, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 制作管理アカウント一覧を取得するAPIです。 <br>制作管理アカウント一覧を返却します。
     * @summary 【Mock】制作管理アカウント一覧取得API
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCreationAccounts(options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCreationAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿制作の施策内のプレビューをします。
     * @summary 【Mock】投稿制作並び順プレビューAPI
     * @param {string} measureId 制作管理施策ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCreationPreview(measureId: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCreationPreview(measureId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿制作一覧を取得するAPIです。 <br>投稿制作一覧を返却します。
     * @summary 【Mock】投稿制作一覧取得API
     * @param {string} measureId 制作管理施策ID
     * @param {GetCreationsRowsEnum} [rows] ページ行数を指定しない場合、API仕様に応じてデフォルトのページ行数で返却されます。
     * @param {number} [page] 
     * @param {GetCreationsOrderEnum} [order] 
     * @param {string} [orderBy] ソート項目を指定しない場合、API仕様に応じてデフォルトのソート項目でソートされます。
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getCreations(measureId: string, rows?: GetCreationsRowsEnum, page?: number, order?: GetCreationsOrderEnum, orderBy?: string, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).getCreations(measureId, rows, page, order, orderBy, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿制作の更新を行うAPIです。
     * @summary 【Mock】投稿制作更新API
     * @param {string} creationId 投稿制作ID
     * @param {PatchCreationInput} [patchCreationInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchCreation(creationId: string, patchCreationInput?: PatchCreationInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchCreation(creationId, patchCreationInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コンテンツを編集するAPIです。 <br>コンテンツIDを指定してコンテンツを編集します。
     * @summary 【Mock】コンテンツ編集API
     * @param {string} creationId 投稿制作ID
     * @param {string} contentsId コンテンツID
     * @param {PatchCreationContentsInput} [patchCreationContentsInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchCreationContents(creationId: string, contentsId: string, patchCreationContentsInput?: PatchCreationContentsInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchCreationContents(creationId, contentsId, patchCreationContentsInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * イメージ案を編集するAPIです。 <br>イメージ案IDを指定してイメージ案を編集します。
     * @summary 【Mock】イメージ案編集API
     * @param {string} creationId 投稿制作ID
     * @param {string} contentsId コンテンツID
     * @param {PatchCreationImageInput} [patchCreationImageInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchCreationImage(creationId: string, contentsId: string, patchCreationImageInput?: PatchCreationImageInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchCreationImage(creationId, contentsId, patchCreationImageInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 制作管理施策を更新するAPIです。 <br>制作管理施策を更新します。
     * @summary 【Mock】制作管理施策更新API
     * @param {string} measureId 制作管理施策ID
     * @param {PatchMeasureInput} [patchMeasureInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public patchMeasure(measureId: string, patchMeasureInput?: PatchMeasureInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).patchMeasure(measureId, patchMeasureInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿制作を作成するAPIです。 <br>投稿制作を作成します。
     * @summary 【Mock】投稿制作作成API
     * @param {string} measureId 制作管理施策ID
     * @param {PostCreationInput} [postCreationInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCreation(measureId: string, postCreationInput?: PostCreationInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postCreation(measureId, postCreationInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 制作管理アカウントを登録するAPIです。 <br>制作管理アカウントを登録します。
     * @summary 【Mock】制作管理アカウント登録API
     * @param {PostCreationAccountInput} [postCreationAccountInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCreationAccount(postCreationAccountInput?: PostCreationAccountInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postCreationAccount(postCreationAccountInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿制作を承認するAPIです。 <br>コンテンツIDを指定して投稿制作を承認します。
     * @summary 【Mock】投稿制作承認API
     * @param {string} creationId 投稿制作ID
     * @param {string} contentsId コンテンツID
     * @param {PostCreationApproveInput} [postCreationApproveInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCreationApprove(creationId: string, contentsId: string, postCreationApproveInput?: PostCreationApproveInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postCreationApprove(creationId, contentsId, postCreationApproveInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * コンテンツを作成するAPIです。 <br>コンテンツを作成します。
     * @summary 【Mock】コンテンツ作成API
     * @param {string} creationId 投稿制作ID
     * @param {PostCreationContentsInput} [postCreationContentsInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCreationContents(creationId: string, postCreationContentsInput?: PostCreationContentsInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postCreationContents(creationId, postCreationContentsInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * イメージ案を作成するAPIです。 <br>イメージ案を作成します。
     * @summary 【Mock】イメージ案作成API
     * @param {string} creationId 投稿制作ID
     * @param {PostCreationImageInput} [postCreationImageInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postCreationImage(creationId: string, postCreationImageInput?: PostCreationImageInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postCreationImage(creationId, postCreationImageInput, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 制作管理施策を登録するAPIです。 <br>制作管理施策を登録します。
     * @summary 【Mock】制作管理施策登録API
     * @param {PostMeasureInput} [postMeasureInput] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public postMeasure(postMeasureInput?: PostMeasureInput, options?: RawAxiosRequestConfig) {
        return DefaultApiFp(this.configuration).postMeasure(postMeasureInput, options).then((request) => request(this.axios, this.basePath));
    }
}

/**
 * @export
 */
export const GetCreationAccountMeasuresRowsEnum = {
    NUMBER_10: 10,
    NUMBER_30: 30,
    NUMBER_50: 50,
    NUMBER_100: 100
} as const;
export type GetCreationAccountMeasuresRowsEnum = typeof GetCreationAccountMeasuresRowsEnum[keyof typeof GetCreationAccountMeasuresRowsEnum];
/**
 * @export
 */
export const GetCreationAccountMeasuresOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCreationAccountMeasuresOrderEnum = typeof GetCreationAccountMeasuresOrderEnum[keyof typeof GetCreationAccountMeasuresOrderEnum];
/**
 * @export
 */
export const GetCreationsRowsEnum = {
    NUMBER_10: 10,
    NUMBER_30: 30,
    NUMBER_50: 50,
    NUMBER_100: 100
} as const;
export type GetCreationsRowsEnum = typeof GetCreationsRowsEnum[keyof typeof GetCreationsRowsEnum];
/**
 * @export
 */
export const GetCreationsOrderEnum = {
    Asc: 'asc',
    Desc: 'desc'
} as const;
export type GetCreationsOrderEnum = typeof GetCreationsOrderEnum[keyof typeof GetCreationsOrderEnum];


