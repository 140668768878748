import { DefaultApi } from '@/api/member/api';
import { axiosInstance } from '@/utils/axiosInstance';

export const updateMemberRole = async (memberId: string, choice_role: string): Promise<void> => {
  type Role = 'administrator' | 'member';
  type PatchMemberRoleInput = {
    role: Role;
  };

  const roleInput: PatchMemberRoleInput = {
    role: choice_role as Role,
  };

  const api = new DefaultApi(undefined, undefined, axiosInstance);
  api.patchMemberRole(memberId, { role: roleInput.role });
};
