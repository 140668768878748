import ReactApexChart from 'react-apexcharts';

export default function DonutChart(main_data: number[], colors: string[]) {
  const total: number = main_data.reduce((sum, element) => sum + element, 0);
  const data = {
    series: main_data,
    options: {
      colors: colors,
      labels: [
        '男性 (' + String(Math.round((main_data[0] / total) * 100)) + '%)',
        '女性 (' + String(Math.round((main_data[1] / total) * 100)) + '%)',
        'その他 (' + String(Math.round((main_data[2] / total) * 100)) + '%)',
      ],

      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              name: {
                // チャートの各属性にフロートした際の上文字表示
                show: true,
                label: total.toString(),
                color: '#6B7280',
                fontSize: '27px',
                fontWeight: 700,
                fontStyle: 'normal',
                fontFamily: 'Inter',
                formatter: function (val: any) {
                  return val;
                },
              },
              value: {
                // チャートの各属性にフロートした際の下文字表示
                show: true,
                label: total.toString(),
                color: '#6B7280',
                fontSize: '14px',
                fontWeight: 700,
                fontStyle: 'normal',
                fontFamily: 'Inter',
                formatter: function (val: any) {
                  return val;
                },
              },
              total: {
                // チャートのデフォルトの下文字表示
                show: true,
                label: total.toString(),
                color: '#6B7280',
                fontSize: '27px',
                fontWeight: 700,
                fontFamily: 'Inter',
                formatter: function () {
                  return '合計';
                },
              },
            },
          },
        },
      },
      // bar には data の label 値表示
      dataLabels: {
        enabled: false,
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },

            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <div>
      <ReactApexChart type="donut" height={300} width={450} series={data.series} options={data.options} />
    </div>
  );
}
