// import { FetchNoticesInfoResponse } from '@/types/NoticeInfo';

import { GetNotificationsOutput } from '@/api/notification';

import { RowData } from '../../components/OneList';
import Pagenation, { projectTableInformation } from '../../components/Pagenation';
import { dateConvert } from '../../utils/dateUtils';

export function Related_Notification(
  tableInformation: projectTableInformation,
  selectedTab: string,
  fetchNotices: GetNotificationsOutput | null,
  noticeSubscriptioned: boolean,
  handleRedirect: (projectType: string, projectId: string) => void
): React.ReactElement {
  return (
    <>
      <ul className="h-[413px] divide-y divide-gray-200 overflow-x-hidden overflow-y-scroll border-t border-gray-200 bg-white">
        {fetchNotices?.rows?.map((item, index) => (
          <li key={index} className="flex h-[60px]">
            <RowData
              item_contents={item.body}
              selectedTab={selectedTab}
              date={dateConvert(item.createdAt)}
              subscriptioned={noticeSubscriptioned}
              onClick={() => {
                handleRedirect(item.projectType, item.projectId);
              }}
            ></RowData>
          </li>
        ))}
      </ul>
      <div className="ml-[50px] h-[1px] w-[calc(100%_-_50px)] bg-gray-200"></div>
      <div className="w-full">
        <div>{Pagenation(tableInformation)}</div>
      </div>
    </>
  );
}
