import { EmeraldPostSummaryOption } from '@/api/emeraldPost';
import { SpiritSummaryOption } from '@/api/spirit';
import { RadioValueProps } from '@/components/Elements/RadioBox/RadioBox';

export interface ProjectItemsType {
  project_item: any[];
  project_item_left_flag: Array<number>;
}

export interface ProjectItemsOldType {
  project_item: any[];
  project_item_left_flag: Array<number>;
  project_item_right_flag: Array<number>;
}

// オプション設定
export interface optionInterface {
  bonus?: string;
  isBonus?: boolean;
  isSecoundaryUse?: boolean;
  isProxyDecision?: boolean;
  peoples?: number;
  price?: number;
}

/*export interface Popup_OptionArgsType {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  option_total: number[];
  setOption_total: React.Dispatch<React.SetStateAction<number[]>>;
  option: optionInterface | undefined;
  setOption: React.Dispatch<React.SetStateAction<optionInterface | undefined>>;
  overview_bonus: number | string;
  setOverview_bonus: React.Dispatch<React.SetStateAction<number | string>>;
  overview_secoundary: string;
  setOverview_secoundary: React.Dispatch<React.SetStateAction<string>>;
  overview_proxy: string;
  setOverview_proxy: React.Dispatch<React.SetStateAction<string>>;
}*/

export type OptionEmeraldType = EmeraldPostSummaryOption & {
  defaultOption?: number;
};

export type OptionSpiritType = SpiritSummaryOption & {
  defaultOption?: number;
};

export type OptionType<T> = T extends EmeraldPostSummaryOption ? OptionEmeraldType : OptionSpiritType;

export type OptionAllType = OptionEmeraldType & OptionSpiritType;

export type OptionKeysType = keyof OptionAllType;

export type OptionMapType = Map<
  OptionKeysType,
  {
    title: string;
    radioValues: RadioValueProps[];
    tipContent?: string;
  }
>;

export function SortByOption(arr: [number, OptionKeysType][]) {
  const weight = (val: string): number => {
    switch (val) {
      case 'goodQuality':
        return 1;
      case 'rewrite':
        return 2;
      case 'secondaryUse':
        return 3;
      case 'deliverImage':
        return 4;
      case 'adoptionOutsourced':
        return 5;
      default:
        return 0;
    }
  };
  return arr.sort((a, b) => {
    return weight(a[1]) - weight(b[1]);
  });
}
