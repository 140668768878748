import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { Button } from '@/components/Elements/Button';
import { PROJECT_TYPES } from '@/config/index';
import { ROUTES } from '@/config/path';

export function ListHeader(projectType: string, otherRegistModalOpen: () => void): React.ReactElement {
  let ProjectAddBtnFlag = false;
  if (projectType === PROJECT_TYPES.SPIRIT || projectType === PROJECT_TYPES.OTHER) {
    ProjectAddBtnFlag = true;
  }
  const title = () => {
    if (projectType === PROJECT_TYPES.SPIRIT) {
      return 'SPIRIT(広告案件) 案件リスト';
    } else if (projectType === PROJECT_TYPES.EMERALD_POST) {
      return 'EMERALD POST(広報案件) 案件リスト';
    } else if (projectType === PROJECT_TYPES.PRST) {
      return 'PRST(制作案件) 案件リスト';
    } else if (projectType === PROJECT_TYPES.JANE_JOHN) {
      return 'JANE JOHN(調査案件) 案件リスト';
    } else if (projectType === PROJECT_TYPES.OTHER) {
      return 'その他 案件リスト';
    } else {
      return '';
    }
  };
  const navigate = useNavigate();
  const handleNewProject = () => {
    navigate(ROUTES.PROJECT);
  };

  return (
    <div>
      <div className="flex h-[60px] w-[1149px] items-center justify-between border-b pt-[10px]">
        <div className="flex flex-row items-center">
          <div className="flex h-[28px] flex-row items-center">
            <span className="text-base font-semibold leading-7 text-gray-700">{title()}</span>
          </div>
        </div>
        {ProjectAddBtnFlag &&
          (projectType === PROJECT_TYPES.SPIRIT ? (
            <Button
              size="smNopadding"
              className="h-[38px] p-3 font-bold"
              startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
              onClick={handleNewProject}
            >
              <span className="text-sm font-medium leading-5 text-white">新しい案件を作成</span>
            </Button>
          ) : (
            <Button
              size="smNopadding"
              className="h-[38px] p-3 font-bold"
              startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
              onClick={otherRegistModalOpen}
            >
              <span className="text-sm font-medium leading-5 text-white">新しいその他の案件を作成する</span>
            </Button>
          ))}
      </div>
    </div>
  );
}
