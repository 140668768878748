import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useState, useEffect } from 'react';

import { ReactComponent as ChevronDownIcon } from '@/assets/icons/bx-chevron-down.svg';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as PencilImage } from '@/assets/icons/bx-pencil.svg';
import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements';
import { TextArea } from '@/components/Elements/TextArea';

// import { Comment, Reply } from '../interfaces/PRSTProductionManagementInterface';

import { useAccount } from './AccountContext';
import { AlertContentsApprove } from './AlertContentsApprove';
import { AlertImageApprove } from './AlertImageApprove';
import AvatarImage from './assets/Avatar.png';
import { ReactComponent as BookarkIcon } from './assets/Bookmark.svg';
import { ReactComponent as AddIcon } from './assets/bx-add-to-queue.svg';
// import { ReactComponent as BoldIcon } from './assets/bx-bold.svg';
import { ReactComponent as EditIcon } from './assets/bx-edit-alt.svg';
// import { ReactComponent as FontcolorIcon } from './assets/bx-font-color.svg';
import { ReactComponent as HeartIcon } from './assets/bx-heart.svg';
// import { ReactComponent as ImageAddIcon } from './assets/bx-image-add.svg';
// import { ReactComponent as ItalicIcon } from './assets/bx-italic.svg';
import { ReactComponent as MenuLeftIcon } from './assets/bx-menu-alt-left.svg';
import { ReactComponent as MessageIcon } from './assets/bx-message-rounded.svg';
import { ReactComponent as PaperPlaneIcon } from './assets/bx-paper-plane.svg';
import { ReactComponent as TrashIcon } from './assets/bx-trash-alt.svg';
// import { ReactComponent as UnderlineIcon } from './assets/bx-underline.svg';
// import { ReactComponent as CommentAvatarIcon } from './assets/Comment_Avatar.svg';
import CommentIcon from './assets/CommentIcon.png';
import { ReactComponent as InformationImage } from './assets/Information carousel_Two Color 1.svg';
import SampleMediaImage1 from './assets/SampleMediaImage.png';
import SampleMediaImage2 from './assets/SampleMediaImage2.png';
import { useUI } from './UIContext';

export const ImageCreateView = () => {
  const { selectedProject, selectedPost, selectedAccount, accounts, contentCommentReplys } = useAccount();

  const projectStatus: number = selectedPost?.status || 0;

  const [open1, setOpen1] = useState<boolean>(false);
  const handleClick1 = () => {
    setOpen1(true);
  };

  const [open2, setOpen2] = useState<boolean>(false);
  const handleClick2 = () => {
    setOpen2(true);
  };

  const [image1, setImage1] = useState<boolean>(false);
  const handleImage1 = () => {
    setImage1(true);
  };

  const [image2, setImage2] = useState<boolean>(false);
  const handleImage2 = () => {
    setImage2(true);
  };

  const [imageApproved, setImageApproved] = useState<boolean>(false);
  const [contentApproved, setContentApproved] = useState<boolean>(false);

  const handleImageApproving = () => {
    if (selectedPost) {
      selectedPost.status = 2;
      setOpen1(false);
    }
  };

  const handleImageApproved = () => {
    if (selectedPost) {
      selectedPost.status = 3;
      setImageApproved(true);
      selectedPost.imageApproved = true;
    }
  };

  const handleContentApproving = () => {
    if (selectedPost) {
      selectedPost.status = 4;
      setOpen2(false);
    }
  };
  const handleContentApproved = () => {
    if (selectedPost) {
      selectedPost.status = 5;
      setContentApproved(true);
      selectedPost.contentApproved = true;
      setOpen2(false);
    }
  };

  const { setImageComment, setContentComment } = useUI();

  const handleImageComment = () => {
    setImageComment(true);
  };

  const [mediaView1, setMediaView1] = useState<number>(1);
  const addMediaView1 = () => {
    setMediaView1(mediaView1 + 1);
  };

  const [mediaView2, setMediaView2] = useState<number>(1);
  const addMediaView2 = () => {
    setMediaView2(mediaView2 + 1);
  };

  useEffect(() => {
    console.log('---ImageCreateView.tsx---');
    console.log('選択されたアカウント（selectedAccount）:', selectedAccount);
    console.log('選択されたプロジェクト（selectedProject）:', selectedProject);
    console.log('選択されたプロジェクト（selectedPost）:', selectedPost);
    console.log('作成されたアカウント全て（accounts）:', accounts);
    console.log('プロジェクトのステータス（projectStatus）:', projectStatus);
    console.log('追加された全ての返信', contentCommentReplys);
  }, [selectedAccount, accounts, selectedProject, selectedPost, projectStatus, contentCommentReplys]);

  const MediaView1 = () => {
    return (
      <>
        <div className="mb-[16px] h-[245px] w-[693px]">
          <div className="mb-[8px] flex h-[28px] w-[693px] items-center justify-end">
            <div className="mb-[8px] flex h-[20px] w-[693px] items-center justify-start">
              <span className="text-sm font-semibold leading-5 text-gray-700">n枚目</span>
            </div>
            <div className="flex w-[110px] items-center justify-around ">
              <ChevronDownIcon width={28} height={28} fill="#374151" />
              <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
              <TrashIcon width={22} height={22} fill="#6B7280" />
            </div>
          </div>
          <div className="mb-[8px] flex h-[209px] w-[693px] justify-between">
            <ImageAddView1 />
            <div className="h-[209px] w-[416px]">
              <div className="mb-[4px] h-[20px] w-[111px]">
                <span className="text-sm font-semibold leading-5 text-gray-700">画像 / 動画の説明</span>
              </div>
              <TextArea className="h-[185px] w-[416px]" />
            </div>
          </div>
        </div>
      </>
    );
  };

  const MediaView2 = () => {
    return (
      <>
        <div className="mb-[16px] mr-[24px] h-[245px] w-[211px]">
          <div className="mb-[8px] flex h-[28px] w-[211px] items-center justify-end">
            <div className="mb-[8px] flex h-[20px] w-[211px] items-center justify-start">
              <span className="h-[19px] w-[35px] text-sm font-semibold leading-5 text-gray-700">1枚目</span>
              <div className="flex h-[28px] w-[64px] items-center justify-between">
                <ChevronLeftIcon width={28} height={28} fill="#E5E7EB" />
                <ChevronRightIcon width={28} height={28} fill="#374151" />
              </div>
            </div>
            {/* <div className="flex w-[110px] items-center justify-around ">
              <ChevronDownIcon width={28} height={28} fill="#374151" />
              <ChevronDownIcon width={28} height={28} fill="#E5E7EB" className="rotate-180" />
              <TrashIcon width={22} height={22} fill="#6B7280" />
            </div> */}
          </div>
          <div className="mb-[8px] flex h-[209px] w-full justify-between">
            <div>
              <ImageAddView2 />
            </div>
            {/* <div className="h-[209px] w-[416px]">
              <div className="mb-[4px] h-[20px] w-[111px]">
                <span className="text-sm font-semibold leading-5 text-gray-700">画像 / 動画の説明</span>
              </div>
              <TextArea className="h-[185px] w-[416px]" />
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const components1: JSX.Element[] = [];
  for (let i = 0; i < mediaView1; i++) {
    components1.push(<MediaView1 />);
  }

  const components2: JSX.Element[] = [];
  for (let i = 0; i < mediaView2; i++) {
    components2.push(<MediaView2 />);
  }

  const NoImageView1 = () => {
    return (
      <>
        <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
          <div className="h-[120px] w-[220px] pb-[4px]">
            <div className="flex items-center justify-center">
              <div className="flex h-[48px] w-[48px] items-center justify-center">
                <AddIcon width={36} height={36} />
              </div>
            </div>
            <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
              <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                <span className="text-sm font-medium leading-5 text-gray-600">ドラッグ&ドロップもしくは</span>
              </div>
              <div className="flex items-center justify-center">
                <button onClick={handleImage1}>
                  <span className="text-sm font-medium leading-5 text-[#007CC2]">アップロード</span>
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <span className="text-xs font-normal leading-4 text-gray-500">対応ファイルはPNG, JPG, GIF,mp4</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ImageView1 = () => {
    return (
      <>
        <img src={SampleMediaImage1} alt="" />
      </>
    );
  };

  const ImageAddView1 = () => {
    return <>{image1 ? <ImageView1 /> : <NoImageView1 />}</>;
  };

  const NoImageView2 = () => {
    return (
      <>
        <div className="flex h-[209px] w-[239px] items-center justify-center border-2 border-dashed border-gray-200">
          <div className="h-[120px] w-[220px] pb-[4px]">
            <div className="flex items-center justify-center">
              <div className="flex h-[48px] w-[48px] items-center justify-center">
                <AddIcon width={36} height={36} />
              </div>
            </div>
            <div className="mx-auto mb-[4px] h-[44px] w-[178px]">
              <div className="mb-[4px] flex h-[20px] w-[178px] items-center justify-center">
                <span className="text-sm font-medium leading-5 text-gray-600">ドラッグ&ドロップもしくは</span>
              </div>
              <div className="flex items-center justify-center">
                <button onClick={handleImage2}>
                  <span className="text-sm font-medium leading-5 text-[#007CC2]">アップロード</span>
                </button>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <span className="text-xs font-normal leading-4 text-gray-500">対応ファイルはPNG, JPG, GIF,mp4</span>
            </div>
          </div>
        </div>
      </>
    );
  };

  const ImageView2 = () => {
    return (
      <>
        <img src={SampleMediaImage2} alt="" />
      </>
    );
  };

  const ImageAddView2 = () => {
    return <>{image2 ? <ImageView2 /> : <NoImageView2 />}</>;
  };

  const AddImageModal1 = () => {
    return (
      <Transition.Root show={open1} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen1}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="h-full w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-full w-[693px]">
                              {components1}
                              <Button
                                startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                variant="secoundary"
                                size="smNopadding"
                                className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                                onClick={addMediaView1}
                              >
                                メディアイメージを追加する
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-[32px] h-[216px] w-[693px]">
                          <div className="mb-[4px] h-[20px] w-[56px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                          </div>
                          <TextArea className="h-[192px] w-[693px] px-[16px] py-[8px]" />
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen1(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen1(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleImageApproving}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const AddImageModal2 = () => {
    return (
      <Transition.Root show={open2} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen2}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#6B7280BF] bg-opacity-[/75] transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative h-full w-[768px] overflow-hidden rounded-lg bg-white p-[24px]">
                  <div className="h-full w-[720px] bg-white">
                    <div className="mb-[16px] flex h-full w-[720px]">
                      <div className="h-full w-[696px]">
                        <div className="mb-[24px] flex h-[24px] w-[696px] items-center justify-start">
                          <span className="text-lg font-medium leading-6 text-gray-900">イメージ案を追加</span>
                        </div>
                        <div className="mb-[32px] h-full w-[693px]">
                          <div className="h-full w-[693px]">
                            <div className="mb-[8px] flex h-[20px] w-[218px] items-center justify-start">
                              <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                            </div>
                            <div className="h-full">
                              <div className="flex">{components2}</div>
                              <Button
                                startIcon={<PlusIcon width={20} height={20} fill="#007CC2" />}
                                variant="secoundary"
                                size="smNopadding"
                                className="h-[38px] w-[241px] whitespace-nowrap text-sm font-medium leading-5"
                                onClick={addMediaView2}
                              >
                                メディアイメージを追加する
                              </Button>
                            </div>
                          </div>
                        </div>
                        <div className="mb-[32px] h-[216px] w-[693px]">
                          <div className="mb-[4px] h-[20px] w-[56px]">
                            <span className="text-sm font-semibold leading-5 text-gray-700">テキスト</span>
                          </div>
                          <TextArea className="h-[192px] w-[693px] px-[16px] py-[8px]" />
                        </div>
                      </div>
                      <div>
                        <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setOpen2(false)} />
                      </div>
                    </div>
                    <div className="flex h-[38px] w-[720px] items-center justify-end">
                      <Button
                        variant="white"
                        size="md"
                        className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={() => setOpen2(false)}
                      >
                        キャンセル
                      </Button>
                      <Button
                        variant="primary"
                        size="md"
                        className="h-[38px] w-[90px] whitespace-nowrap text-sm font-medium leading-5"
                        onClick={handleContentApproving}
                      >
                        保存する
                      </Button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  // const CommentWindow1 = () => {
  //   return (
  //     <>
  //       <div className="absolute left-[1036px] top-0 z-20 h-[840px] w-[406px] bg-white px-[24px] pt-[40px] shadow-[0_10px_10px_rgba(0,0,0,0.04),0_20px_25px_rgba(0,0,0,0.1)]">
  //         <div className="mb-[22px] flex w-full justify-end">
  //           <button className="mr-[0px]">
  //             <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={() => setContentComment(false)} />
  //           </button>
  //         </div>
  //         <form className="mb-[24px] h-[184px] w-[358px]">
  //           <label htmlFor="comment" className="h-[28px] w-[220px] text-xl font-bold leading-7">
  //             コンテンツへのコメント
  //           </label>
  //           <div className="my-[24px] h-[108px] w-[358px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
  //             <div className="h-[92px] w-[326px]">
  //               <textarea
  //                 className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
  //                 placeholder="新規コメント"
  //                 value={comment}
  //                 onChange={handleContentCommentsChange}
  //                 defaultValue={''}
  //               />
  //               <div className="flex h-[34px] w-[326px] items-end justify-between">
  //                 <div className="flex h-[24px] w-[164px] items-center justify-between">
  //                   <button className="h-[16px] w-[16px]">
  //                     <ImageAddIcon width={16} height={16} fill="#000000" />
  //                   </button>
  //                   <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
  //                   <div className="flex h-[17px] w-[117px] items-center justify-between">
  //                     <button className="h-[16px] w-[16px]">
  //                       <BoldIcon width={16} height={16} fill="#000000" />
  //                     </button>
  //                     <button className="h-[16px] w-[16px]">
  //                       <ItalicIcon width={16} height={16} fill="#000000" />
  //                     </button>
  //                     <button className="h-[16px] w-[16px]">
  //                       <UnderlineIcon width={17} height={17} fill="#000000" />
  //                     </button>
  //                     <button className="h-[16px] w-[16px]">
  //                       <FontcolorIcon width={17} height={17} fill="#000000" />
  //                     </button>
  //                   </div>
  //                 </div>
  //                 <Button
  //                   onClick={handleSubmit}
  //                   className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
  //                 >
  //                   コ送信
  //                 </Button>
  //               </div>
  //             </div>
  //           </div>
  //           <div className="h-[1px] w-[358px] border-t-[1px] border-[#DDDDDD]"></div>
  //         </form>
  //         <div className="w-[358px]">
  //           {contentComments.map((imageComment, index) => (
  //             <div key={index} className="mb-[24px] py-[8px]">
  //               <div className="mb-[8px] flex h-[60px] w-[358px] justify-between py-[8px]">
  //                 <div>
  //                   <CommentAvatarIcon width={24} height={24} />
  //                 </div>
  //                 <div className="h-[44px] w-[322px]">
  //                   <div className="flex justify-between">
  //                     <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">Whitney Francis</div>
  //                     <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
  //                   </div>
  //                   <p className="text-sm font-normal leading-5 text-gray-700">{imageComment.comment}</p>
  //                 </div>
  //               </div>
  //               {imageComment.replys.length > 0 ? (
  //                 replyWindow2 === index ? (
  //                   <div>
  //                     <button className="text-sm font-medium leading-5" onClick={() => handleSubmit2(index)}>
  //                       <div className="flex h-[24px] w-[116px] justify-between">
  //                         <ChevronDownIcon width={24} height={24} fill="#6B7280" />
  //                         <div className="h-[20px] w-[84px] items-center text-sm font-medium leading-5 text-gray-500">
  //                           返信を閉じる
  //                         </div>
  //                       </div>
  //                     </button>
  //                     <div>
  //                       {contentComments[index].replys.map((reply, i) => (
  //                         <div key={i} className="mb-[8px] w-[358px] py-[8px] pl-[24px]">
  //                           <div className="mb-[8px] flex justify-between py-[8px]">
  //                             <div>
  //                               <CommentAvatarIcon width={24} height={24} />
  //                             </div>
  //                             <div className="w-[298px]">
  //                               <div className="flex justify-between">
  //                                 <div className="h-[20px] text-sm font-medium leading-5 text-gray-900">
  //                                   Whitney Francis
  //                                 </div>
  //                                 <div className="text-sm font-normal leading-5 text-gray-500">1時間前</div>
  //                               </div>
  //                               <p className="text-sm font-normal leading-5 text-gray-700">{reply.reply}</p>
  //                             </div>
  //                           </div>
  //                         </div>
  //                       ))}
  //                     </div>
  //                   </div>
  //                 ) : (
  //                   <div>
  //                     <button
  //                       className="text-sm font-medium leading-5 text-[#007CC2]"
  //                       onClick={() => handleReply2(index)}
  //                     >
  //                       <div className="flex h-[24px] w-[109px]">
  //                         <ChevronDownIcon width={24} height={24} fill="#007CC2" />
  //                         <div className="h-[20px] w-[77px] items-center text-sm font-medium leading-5">
  //                           返信（{imageComment.replys.length}）
  //                         </div>
  //                       </div>
  //                     </button>
  //                   </div>
  //                 )
  //               ) : null}
  //               {replyWindow === index ? (
  //                 <div className="h-[124px] w-[358px] py-[8px] pl-[24px]">
  //                   <div className="h-[108px] w-[334px] rounded-md border border-[#007CC2] px-[16px] py-[8px]">
  //                     <div className="h-[92px] w-[302px]">
  //                       <textarea
  //                         className="block h-[58px] w-full resize-none border-0 bg-transparent px-[0px] pb-[0px] pt-[4px] ring-0 placeholder:text-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm sm:leading-4"
  //                         placeholder="返信"
  //                         value={reply}
  //                         onChange={handleContentCommentReplysChange}
  //                         defaultValue={''}
  //                       />
  //                       <div className="flex h-[34px] w-[302px] items-end justify-between">
  //                         <div className="flex h-[24px] w-[164px] items-center justify-between">
  //                           <button className="h-[16px] w-[16px]">
  //                             <ImageAddIcon width={16} height={16} fill="#000000" />
  //                           </button>
  //                           <div className="h-[24px] w-[1px] border-l border-[#0000001A] "></div>
  //                           <div className="flex h-[17px] w-[117px] items-center justify-between">
  //                             <button className="h-[16px] w-[16px]">
  //                               <BoldIcon width={16} height={16} fill="#000000" />
  //                             </button>
  //                             <button className="h-[16px] w-[16px]">
  //                               <ItalicIcon width={16} height={16} fill="#000000" />
  //                             </button>
  //                             <button className="h-[16px] w-[16px]">
  //                               <UnderlineIcon width={17} height={17} fill="#000000" />
  //                             </button>
  //                             <button className="h-[16px] w-[16px]">
  //                               <FontcolorIcon width={17} height={17} fill="#000000" />
  //                             </button>
  //                           </div>
  //                         </div>
  //                         <Button
  //                           onClick={() => handleSubmit1(index)}
  //                           className="h-[34px] w-[54px] whitespace-nowrap rounded-md px-[13px] py-[9px] text-sm font-medium leading-4"
  //                         >
  //                           R送信
  //                         </Button>
  //                       </div>
  //                     </div>
  //                   </div>
  //                 </div>
  //               ) : null}
  //               {(contentComments[index].replys.length === 0 || replyWindow2 === index) && replyWindow !== index ? (
  //                 <div className="flex h-[24px] w-[60px] justify-between">
  //                   <div className="h-[24px] w-[24px]"></div>
  //                   <button
  //                     className="w-[28px] text-sm font-semibold leading-5 text-gray-500"
  //                     onClick={() => handleReply(index)}
  //                   >
  //                     返信
  //                   </button>
  //                 </div>
  //               ) : null}
  //               {/* {replyWindow !== index && <button onClick={() => handleReply(index)}>返信</button>} */}

  //               {/* {imageCommentReplys.map((reply, index) => (
  //                 <div key={index}>
  //                   <p>{reply.accountName}</p>
  //                   <p>{reply.reply}</p>
  //                 </div>
  //               ))} */}
  //             </div>
  //           ))}
  //         </div>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <>
      {selectedPost?.status === 2 ? (
        <div className="mt-[25px] px-[24px]">
          <AlertImageApprove />
        </div>
      ) : null}
      {selectedPost?.status === 4 ? (
        <div className="mt-[25px] px-[24px]">
          <AlertContentsApprove />
        </div>
      ) : null}
      {selectedPost?.status && selectedPost?.status === 3 ? (
        <div className="w-[1133px] p-[25px]">
          <div className="flex h-[525px] w-[1133px] items-center justify-center rounded bg-[#FFFFFF] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[478px] w-[1085px]">
              <div className="mb-[24px] h-[28px] w-[92px]">
                <p className="text-lg font-semibold leading-7 text-gray-500">コンテンツ</p>
              </div>
              <div className="flex h-[326px] w-[1085px] items-center justify-center py-[48px]">
                <div className="h-[230px] w-[252px] pb-[4px]">
                  <div className="mb-[24px] h-[164px] w-[252px]">
                    <InformationImage width={252} height={136} />
                    <div className="mt-[8px] flex h-[20px] w-[252px] justify-center">
                      <p className="w-[252px] text-center text-[14px] font-normal leading-5 text-[#6B7280]">
                        コンテンツがまだ制作されていません。
                      </p>
                    </div>
                  </div>
                  <Button
                    startIcon={<PencilImage fill="#FFFFFF" className="h-[20px] w-[20px]" />}
                    onClick={handleClick2}
                  >
                    コンテンツを制作する
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {selectedPost?.status && selectedPost?.status > 3 ? (
        <div className="px-[24px]">
          <div className="mt-[25px] w-[1131px] px-[40px] py-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="mb-[24px] flex h-[31px] w-[1051px] items-center justify-between">
              <div className="flex h-[28px] w-[92px] items-center">
                <span className="text-lg font-semibold leading-7 text-gray-500">コンテンツ</span>
              </div>
              <div className="flex h-[38px] w-[206px] items-center justify-between">
                {selectedPost?.status > 4 && (selectedPost.contentApproved || contentApproved) ? (
                  <div className="flex h-[24px] w-[76px] items-center justify-between bg-green-100 px-[8px] py-[2px]">
                    <span className="text-sm font-medium leading-5 text-green-800">承認済</span>
                    <button>
                      <ChevronDownIcon fill="#10B981" width={16} height={16} />
                    </button>
                  </div>
                ) : (
                  <Button className="text-sm font-medium leading-5" onClick={handleContentApproved}>
                    承認する
                  </Button>
                )}
                <button>
                  <EditIcon width={24} height={24} fill="#6B7280" />
                </button>
                <button onClick={() => setContentComment(true)}>
                  {/* <CommentIcon width={24} height={24} /> */}
                  <img src={CommentIcon} alt="" />
                </button>
              </div>
            </div>
            <div className="w-[1051px]">
              <span className="mb-[4px] h-[20px] w-[70px] text-sm font-semibold leading-5 text-gray-700">
                プレビュー
              </span>
              <div className="mb-[4px] flex h-[28px] items-center">
                <span className="mr-[5px] text-sm font-semibold leading-5 text-gray-700">1 / 2枚目</span>
                <div className="h-[28px]">
                  <ChevronRightIcon width={28} height={28} fill="#374151" />
                </div>
              </div>
              <div className="flex h-[453px] w-[1051px] justify-between py-[10px]">
                <div className="w-[290px]">
                  <div className="flex w-[290px] items-center justify-between rounded-t-sm border-x border-t border-x-gray-200 border-t-gray-200 px-[6px] py-[13px]">
                    <img src={AvatarImage} alt="" className="" />
                    <span className="w-full">1122329737</span>
                  </div>
                  <div className="w-[290px]">
                    <img src={SampleMediaImage2} alt="" className="w-[290px]" />
                  </div>
                  <div className="flex h-[100px] w-[290px] items-center justify-center rounded-b-sm border-x border-b border-x-gray-200 border-b-gray-200">
                    <div className="mb-[13px] mt-[6px] w-[280px]">
                      <div className="mb-[9px] flex w-[275px] items-center justify-between">
                        <div className="flex w-[66px] items-center justify-between">
                          <HeartIcon fill="#000000" />
                          <MessageIcon fill="#000000" />
                          <PaperPlaneIcon fill="#000000" />
                        </div>
                        <BookarkIcon />
                      </div>
                      <div className="w-[280px]">
                        <div className="mb-[4px] h-[23px] w-[280px] text-xs font-normal leading-4 text-gray-800">
                          「Canon360」
                        </div>
                        <div className="mb-[4px] h-[13px] w-[280px] text-[9px] font-medium text-gray-300">
                          続きを読む
                        </div>
                        <div className="h-[13px] w-[280px] text-[9px] font-medium text-gray-400">
                          2022年8月29日 10:58
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h-[433px] border-l border-gray-200"></div>
                <div className="w-[681px]">
                  <div className="w-[681px]">
                    <div className="mb-[15px] flex h-[20px] w-[76px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <span className="text-xs font-normal leading-4 text-gray-800">テキスト</span>
                    </div>
                    <div className="mb-[40px] h-[256px] w-[681px] text-xs font-normal leading-4 text-gray-800">
                      「Canon360」
                      <br />
                      <br />
                      来週からイベントを開催！
                      <br />
                      賞金もあります...💌
                      <br />
                      <br />
                      --------------------------------------------------------------------------------
                      <br />
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません!
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                      <br />
                      <br />
                      -----------------------------------------------------------------------------------
                      <br />
                      ぜひ参加してくださいね！
                    </div>
                    <div className="h-[16px] w-[681px] text-xs font-normal leading-4 text-gray-700">
                      #LANVINenBleu,#LANVINenBleu2023ss,#new,#newcollection
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {selectedPost?.status && selectedPost.status > 1 ? (
        <div className="px-[24px]">
          <div className="mt-[25px] h-[755px] w-[1133px] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[707px] w-[1085px]">
              <div className="mb-[24px] flex h-[38px] w-[1085px] items-center justify-between">
                <div className="flex h-[28px] w-[92px] items-center">
                  <span className="text-lg font-semibold leading-7 text-gray-500">イメージ案</span>
                </div>
                <div className="flex h-[38px] w-[206px] items-center justify-between">
                  {selectedPost.status > 2 && (selectedPost.imageApproved || imageApproved) ? (
                    <div className="flex h-[24px] w-[76px] items-center justify-between bg-green-100 px-[8px] py-[2px]">
                      <span className="text-sm font-medium leading-5 text-green-800">承認済</span>
                      <button>
                        <ChevronDownIcon fill="#10B981" width={16} height={16} />
                      </button>
                    </div>
                  ) : (
                    <Button onClick={handleImageApproved} className="text-sm font-medium leading-5">
                      承認する
                    </Button>
                  )}
                  <button onClick={handleClick1}>
                    <EditIcon width={24} height={24} fill="#6B7280" />
                  </button>
                  <button onClick={handleImageComment}>
                    {/* <CommentIcon width={24} height={24} /> */}
                    <img src={CommentIcon} alt="" />
                  </button>
                </div>
              </div>
              <div className="h-[32px] w-[1085px]">
                <div className="mb-[8px] flex h-[20px] w-[56px] items-center">
                  <span className="text-sm font-semibold leading-5 text-gray-700">メディア</span>
                </div>
                <div className="mb-[8px] flex h-[28px] w-[162px] items-center">
                  <div className="mr-[5px] flex h-[20px] w-[56px] items-center">
                    <span className="text-sm font-semibold leading-5 text-gray-700">1 / n枚目</span>
                  </div>
                  <div className="h-[28px] w-[101px]">
                    <ChevronRightIcon width={28} height={28} fill="#374151" />
                  </div>
                </div>
                <div className="mb-[24px] flex h-[298px] w-[1085px] justify-between py-[10px]">
                  <div className="w-[290px]">
                    <img src={SampleMediaImage1} alt="" className="w-[290px]" />
                  </div>
                  <div className="h-[236px] w-[715px] py-[10px]">
                    <div className="mb-[20px] flex h-[20px] w-[124px] items-center justify-between">
                      <MenuLeftIcon width={20} height={20} fill="#000000" />
                      <div className="flex h-[16px] w-[96px] items-center">
                        <span className="text-xs font-normal leading-4 text-gray-800">画像 / 動画の説明</span>
                      </div>
                    </div>
                    <div className="h-[176px] w-[715px] text-xs font-normal leading-4 text-gray-800">
                      <span>
                        人物画でカメラを持った状態など、いきいきと撮影している場面が想像できるような写真を用意していただきたいです！
                        <br />
                        <br />
                        以下のことはおやめください。
                        <br />
                        <br />
                        ・危険な場所での撮影
                        <br />
                        ・他社製品と撮影すること
                        <br />
                        ・暗い場所での撮影（明るいイメージを希望しているため）
                      </span>
                    </div>
                  </div>
                </div>
                <div className="mb-[24px] h-[1px] w-[1085px] border-b border-[#DDDDDD]"></div>
                <div className="h-[242px] w-[1085px]">
                  <div className="mb-[8px] h-[20px] w-[1085px] px-[16px]">
                    <span className="h-[20px] w-[56px] text-sm font-semibold leading-5 text-gray-800">テキスト</span>
                  </div>
                  <div className="h-[214px] w-[1085px] p-[16px]">
                    <span className="h-[182px] w-[1053px] text-xs font-normal leading-4 text-gray-800">
                      ①「Canon360」という商品名を入れてください。
                      <br />
                      ②
                      イベント情報入れてださい。以下の内容をいれていただければ、自由に文章を作っていただいて大丈夫です！文章のトーンは、絵文字などを使って、読みやすく明るい感じでお願いします。
                      <br />
                      「バレンタインデーフォトイベント」
                      <br />
                      期間：2021/02/01 - 2/14
                      <br />
                      あなたの「愛するもの」をCanonのカメラで撮ってください！
                      <br />
                      愛するものはなんでも構いません。
                      <br />
                      タグ付け@xxxと、フォローをしてください。（当選者連絡用のため）
                      <br />
                      賞金15万円
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="w-[1133px] px-[24px] py-[25px]">
          <div className="flex h-[525px] w-[1133px] items-center justify-center rounded bg-[#FFFFFF] p-[24px] shadow-[0_1px_2px_rgba(0,0,0,0.06),0_1px_3px_rgba(0,0,0,0.1)]">
            <div className="h-[478px] w-[1085px]">
              <div className="mb-[24px] h-[28px] w-[92px]">
                <p className="text-lg font-semibold leading-7 text-gray-500">イメージ案</p>
              </div>
              <div className="flex h-[326px] w-[1085px] items-center justify-center py-[48px]">
                <div className="h-[230px] w-[252px] pb-[4px]">
                  <div className="mb-[24px] h-[164px] w-[252px]">
                    <InformationImage width={252} height={136} />
                    <div className="mt-[8px] flex h-[20px] w-[252px] justify-center">
                      <p className="w-[252px] text-center text-[14px] font-normal leading-5 text-[#6B7280]">
                        イメージ案がまだ制作されていません。
                      </p>
                    </div>
                  </div>
                  <Button
                    startIcon={<PencilImage fill="#FFFFFF" className="h-[20px] w-[20px]" />}
                    onClick={handleClick1}
                  >
                    イメージ案を制作する
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="relative">{AddImageModal1()}</div>
      <div className="relative">{AddImageModal2()}</div>
      {/* {contentComment ? <div className="absolute">{CommentWindow1()}</div> : null} */}
    </>
  );
};
