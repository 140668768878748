import React from 'react';

import { Radio } from '@/components/Elements/Radio';

import { SNS, STEPS } from '../../constants/path';
import { FormProps, SelectProps } from '../../interfaces/project';
import { Graph } from '../Graph';

export default function Sns(props: FormProps): React.ReactElement {
  const snses: SelectProps[] = [
    {
      label: SNS.INSTAGRAM,
      value: SNS.INSTAGRAM,
    },
    {
      label: SNS.TWITTER,
      value: SNS.TWITTER,
    },
    {
      label: SNS.YOUTUBE,
      value: SNS.YOUTUBE,
    },
    {
      label: SNS.TIK_TOK,
      value: SNS.TIK_TOK,
    },
  ];

  const selectDom = () => {
    return (
      <>
        {snses.map((item: SelectProps) => {
          return (
            <Radio
              inputId={item.value}
              inputValue={item.value}
              label={item.label}
              isSelect={props.payload.sns === item.value}
              onClick={() => {
                props.setPayload((state) => {
                  return {
                    ...state,
                    sns: item.value,
                    period: undefined,
                    isPeriodError: false,
                    periodErrorMessage: '',
                    budget: undefined,
                    isBudgetError: false,
                    budgetErrorMessage: '',
                  };
                });
              }}
              className="w-96"
              key={item.value}
            ></Radio>
          );
        })}
      </>
    );
  };

  return (
    <div id="project_sns" className="mx-auto flex w-[800px] flex-row items-start justify-between pt-12">
      <div>{selectDom()}</div>
      <div style={{ width: '370px' }}>{Graph({ step: STEPS.SNS })}</div>
    </div>
  );
}
