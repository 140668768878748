import { Button } from '@/components/Elements/Button';

import DeleteImage from './assets/Delete.svg';

export function MemberListInvitation(): React.ReactElement {
  return (
    <>
      <div className="h-[790px] w-[1440px] bg-[#6B7280BF]/75">
        {/* 以下モーダルとしてコンポーネント化する */}
        <div className="absolute z-20 ml-[480px] mt-[241px] flex h-[268px] w-[480px] rounded-lg bg-[#FFFFFF] p-[24px] shadow-xl">
          <div>
            <div className="mb-[8px] flex items-center">
              <p className="h-[24px] w-[408px] text-lg font-medium leading-6 text-gray-900">新しいメンバーを招待</p>
              <button className="ml-[6px] h-[12px] w-[12px]">
                <img src={DeleteImage} alt="" className="w-full" />
              </button>
            </div>
            <p className="mb-[24px] w-[408px] self-stretch text-sm font-normal leading-5 text-gray-500">
              メンバーを招待することで様々な方とのコラボレーションが可能になります。
            </p>

            <div className="mb-[24px]">
              <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                Email
              </label>
              <div className="mt-[4px]">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-[320px] rounded-md border-0 py-1.5 pl-[13px] text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-900 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="hanako.ito@liddell.tokyo"
                />
              </div>
            </div>

            <div className="mr-0 flex h-[38px] w-[432px] justify-end">
              <Button
                size="md"
                variant="white"
                className="mr-[12px] h-[38px] w-[104px] whitespace-nowrap px-[16px] text-sm font-medium leading-5 text-gray-700"
              >
                キャンセル
              </Button>
              <Button
                size="md"
                variant="primary"
                className="h-[38px] w-[62px] whitespace-nowrap px-[16px] text-sm font-medium leading-5"
                style={{ backgroundColor: '#007CC2' }}
              >
                決定
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
