/**
 * CloudFront経由で画像のURLを取得する
 * @param path S3のパス
 * @returns 画像のパス
 */
export const getImageUrl = (path: string) => {
  const cfdomain = process.env.REACT_APP_CLOUDFRONT_URL;
  if (cfdomain) {
    return `${cfdomain}/${path}`;
  } else {
    return path;
  }
};

/**
 * CloudFront経由で画像のURLを、フルURLでなければ取得する
 * @param path S3のパス
 * @returns 画像のパス
 */
export const getImageUrlIfRelative = (path: string) => {
  const cfdomain = process.env.REACT_APP_CLOUDFRONT_URL;
  const isFullUrl = /^https?:\/\//i.test(path);

  if (isFullUrl) {
    return path;
  } else if (cfdomain) {
    return `${cfdomain}/${path}`;
  } else {
    return path;
  }
};
