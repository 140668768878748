import * as Sentry from '@sentry/react';
import { Auth } from 'aws-amplify';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export function SignOutPost(): React.ReactElement {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        await Auth.signOut();
        navigate('/');
      } catch (ex) {
        //TODO: 表示方法
        alert('エラーが発生しました。');
        Sentry.captureException(ex);
        console.error(ex);
      }
    })();
  }, [navigate]);

  //TODO: 表示方法
  return <></>;
}
