import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { format, subDays, addDays } from 'date-fns';
import { Fragment, useState } from 'react';

import { Button } from '@/components/Elements/Button/Button';
import { Input } from '@/components/Elements/Input';
import '../css/calendar.css';
import { PutEmeraldPostTerm } from '@/hooks/EmeraldPost';
import { EmeraldPostTerm } from '@/types/EmeraldPost';
import { validatePeriodFormat, validatePeriodInput, validatePeriodStarted } from '@/utils/inputMultiValidationCheck';

interface Props {
  open: boolean;
  onClose: (isSave: boolean) => void;
  postTerm: EmeraldPostTerm;
  setPostTerm: React.Dispatch<React.SetStateAction<EmeraldPostTerm>>;
}

interface PeriodDatas {
  periodFrom: string | null;
  periodTo: string | null;
  periodStartedVaild: string;
  periodFormatVaild: string;
  periodInputVaild: string;
}

export const DeliveryPeriod = (props: Props) => {
  const [periodDatas, setPeriodDatas] = useState<PeriodDatas>({
    periodFrom: props.postTerm.startedAt,
    periodTo: props.postTerm.endedAt,
    periodStartedVaild: '',
    periodFormatVaild: '',
    periodInputVaild: '',
  });
  const handleOnSave = async (startedAt: string | null, endedAt: string | null) => {
    const inputStartedAt = startedAt ? new Date(startedAt) : null;
    const inputEndedAt = endedAt ? new Date(endedAt) : null;
    const periodStartedVaild = validatePeriodStarted(inputStartedAt);
    const periodFormatVaild = validatePeriodFormat(inputStartedAt, inputEndedAt, 7);
    const periodInputVaild = validatePeriodInput(startedAt, endedAt);
    setPeriodDatas((prev) => {
      return {
        ...prev,
        periodStartedVaild,
        periodFormatVaild,
        periodInputVaild,
      };
    });
    if (periodFormatVaild === '' && periodInputVaild === '' && periodStartedVaild === '') {
      const searchParams = new URLSearchParams(window.location.search);
      const projectId = searchParams.get('projectId') || '';
      if (!projectId) {
        alert('プロジェクトIDが取得できませんでした');
        return;
      }

      // ボタンの表示条件より、日付が存在することが保証されている
      const response = await PutEmeraldPostTerm(projectId, {
        startedAt: format(new Date(startedAt || ''), 'yyyy-MM-dd HH:mm'),
        endedAt: format(new Date(endedAt || ''), 'yyyy-MM-dd HH:mm'),
      });
      console.log(response);
      props.setPostTerm(response.postTerm);
      props.onClose(true);
      setPeriodDatas({
        periodFrom: response.postTerm.startedAt,
        periodTo: response.postTerm.endedAt,
        periodStartedVaild: '',
        periodFormatVaild: '',
        periodInputVaild: '',
      });
    }
    return;
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.onClose(false);
          setPeriodDatas({
            periodFrom: props.postTerm.startedAt,
            periodTo: props.postTerm.endedAt,
            periodFormatVaild: '',
            periodStartedVaild: '',
            periodInputVaild: '',
          });
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[250px] w-[768px] overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      props.onClose(false);
                      setPeriodDatas({
                        periodFrom: props.postTerm.startedAt,
                        periodTo: props.postTerm.endedAt,
                        periodFormatVaild: '',
                        periodStartedVaild: '',
                        periodInputVaild: '',
                      });
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>

                <div>
                  <div className="w-[656px]">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-xl font-semibold leading-6 text-gray-900">
                      配信期間
                    </Dialog.Title>
                    <div className="h-[32px] w-[616px]"></div>
                    <div className="mb-[16px] text-lg font-bold leading-5 text-gray-700">期間</div>
                    <div className="flex h-[44px] w-[696px] items-center justify-center">
                      {/* 配信開始日 */}
                      <div className="h-[44px]">
                        <div className="flex h-[44px] items-center">
                          <p className="mr-[14px] h-[20px] w-[80px] whitespace-nowrap text-[16px] font-normal leading-5 text-gray-700">
                            配信開始日
                          </p>
                          <div className="h-[44px] w-[199px]">
                            <div className="flex h-[44px] w-[199px] items-center justify-center rounded-md border-gray-300">
                              <Input
                                id="title"
                                type="datetime-local"
                                step="10"
                                className="h-[24px] w-[80px] text-[15px] font-normal leading-6 text-gray-700"
                                onChange={(e: any) =>
                                  setPeriodDatas((prev) => {
                                    return {
                                      ...prev,
                                      periodFrom: e.target.value,
                                    };
                                  })
                                }
                                value={periodDatas.periodFrom ? periodDatas.periodFrom : ''}
                                min={
                                  periodDatas.periodTo
                                    ? format(subDays(new Date(periodDatas.periodTo), 7), 'yyyy-MM-dd HH:mm').replace(
                                        ' ',
                                        'T'
                                      )
                                    : format(new Date(), 'yyyy-MM-dd HH:mm').replace(' ', 'T')
                                }
                                max={
                                  periodDatas.periodTo
                                    ? format(new Date(periodDatas.periodTo), 'yyyy-MM-dd HH:mm').replace(' ', 'T')
                                    : undefined
                                }
                                placeholder="yyyymmddhhmm"
                                isError={periodDatas.periodInputVaild !== '' || periodDatas.periodStartedVaild !== ''}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mx-8">~</div>
                      {/* 配信終了日 */}
                      <div className="h-[44px]">
                        <div className="flex h-[44px] items-center">
                          <p className="mr-[14px] h-[20px] w-[80px] whitespace-nowrap text-[16px] font-normal leading-5 text-gray-700">
                            配信終了日
                          </p>
                          <div className="h-[44px] w-[199px]">
                            <div className="flex h-[44px] w-[199px] items-center justify-center rounded-md border-gray-300">
                              <Input
                                id="title"
                                type="datetime-local"
                                className="h-[24px] w-[80px] text-[15px] font-normal leading-6 text-gray-700"
                                step="10"
                                onChange={(e: any) => {
                                  setPeriodDatas((prev) => {
                                    return {
                                      ...prev,
                                      periodTo: e.target.value,
                                    };
                                  });
                                }}
                                min={
                                  periodDatas.periodFrom
                                    ? format(new Date(periodDatas.periodFrom), 'yyyy-MM-dd HH:mm').replace(' ', 'T')
                                    : format(new Date(), 'yyyy-MM-dd HH:mm').replace(' ', 'T')
                                }
                                max={
                                  periodDatas.periodFrom
                                    ? format(addDays(new Date(periodDatas.periodFrom), 7), 'yyyy-MM-dd HH:mm').replace(
                                        ' ',
                                        'T'
                                      )
                                    : undefined
                                }
                                value={periodDatas.periodTo ? periodDatas.periodTo : ''}
                                isError={periodDatas.periodInputVaild !== '' || periodDatas.periodFormatVaild !== ''}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="text-[14px] text-[#F00]">{periodDatas.periodInputVaild || ''}</p>
                    <p className="text-[14px] text-[#F00]">{periodDatas.periodStartedVaild || ''}</p>
                    <p className="text-[14px] text-[#F00]">{periodDatas.periodFormatVaild || ''}</p>
                  </div>
                </div>

                <div className="mt-[24px] sm:flex sm:flex-row-reverse">
                  <Button
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => {
                      handleOnSave(periodDatas.periodFrom, periodDatas.periodTo);
                    }}
                  >
                    保存する
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      props.onClose(false);
                      setPeriodDatas({
                        periodFrom: props.postTerm.startedAt,
                        periodTo: props.postTerm.endedAt,
                        periodFormatVaild: '',
                        periodStartedVaild: '',
                        periodInputVaild: '',
                      });
                    }}
                  >
                    キャンセル
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
