import { AnalysisFeedbackEvaluation } from '@/api/analysis/api';
import { getRangeQuestionareColor } from '@/utils/questionaire';

import { QuestionaireContent, QuestionaireResult } from './QuestionaireContent';

export type QuestionaireList = QuestionaireResult[];

export type QuestionaireProps = {
  questionaireList: QuestionaireList;
};

export const createQuestionaireResultList = (feedbackList: AnalysisFeedbackEvaluation[]): QuestionaireList => {
  return feedbackList.map((feedback, index) => {
    const result: QuestionaireResult = {
      evaluation: feedback.answer ?? 0,
      questionaireColor: getRangeQuestionareColor(feedback.answer ?? 0),
      content: feedback.question.content,
      description: `0「${feedback.question.lowText}」から、10「${feedback.question.highText}」の11段階`,
      key: index,
    };
    return result;
  });
};

/**
 * アンケートコンポーネント
 */
export const Questionaire = (props: QuestionaireProps) => {
  return (
    <div className="flex flex-col gap-4">
      <div className="mb-2 flex items-start self-stretch">
        <span className="text-sm font-semibold leading-5">アンケート結果</span>
      </div>
      {props.questionaireList.map((questionaire: QuestionaireResult, index: number) => {
        return (
          <QuestionaireContent
            key={index}
            evaluation={questionaire.evaluation}
            questionaireColor={questionaire.questionaireColor}
            content={questionaire.content}
            description={questionaire.description}
          />
        );
      })}
      <svg xmlns="http://www.w3.org/2000/svg" width="1049" height="1" viewBox="0 0 1049 1" fill="none">
        <path d="M0 0H1049V1H0V0Z" fill="#E5E7EB" />
      </svg>
    </div>
  );
};
