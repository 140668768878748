// 案件に関連する処理をまとめたモジュール
import { ProjectStatus } from '@/api/project';
import { PROJECT_STATUS } from '@/config/index';

/**
 * SPIRIT案件ステータスを和名ラベルに変換する
 * @param status 案件ステータス
 * @returns 和名ラベル
 */
export function convertStatusToDisplayLabelForSpirit(status: string): string {
  switch (status) {
    case ProjectStatus.Draft:
      return '募集内容作成(下書き)';
    case ProjectStatus.Review:
      return '募集内容作成(下書き)';
    case ProjectStatus.Approved:
      return '募集〜選考';
    case ProjectStatus.FinalConfirmation:
      return '最終確認';
    case ProjectStatus.Implementation:
      return '実施中';
    case ProjectStatus.Completion:
      return '完了';
    default:
      return '';
  }
}

/**
 * EmeraldPost案件ステータスを和名ラベルに変換する
 * @param status 案件ステータス
 * @returns 和名ラベル
 */
export function convertStatusToDisplayLabelForEmeraldPost(status: string): string {
  if (ProjectStatus.PostDraft == status) {
    return '配信詳細作成(下書き)';
  } else if (ProjectStatus.PostReview == status || ProjectStatus.Review == status) {
    return '申請中';
  } else if (ProjectStatus.WaitPost == status) {
    return '配信待ち';
  } else if (ProjectStatus.Posting == status) {
    return '配信中';
  } else if (ProjectStatus.PostCompletion == status) {
    return '配信完了';
  } else {
    return '';
  }
}

/**
 * Prst案件ステータスを和名ラベルに変換する
 * @param status 案件ステータス
 * @returns 和名ラベル
 */
export function convertStatusToDisplayLabelForPrst(status: string): string {
  if (PROJECT_STATUS.DRAFT == status || PROJECT_STATUS.REVIEW == status) {
    return '募集内容作成(下書き)';
  } else if (PROJECT_STATUS.APPROVED == status) {
    return '募集〜選考';
  } else if (PROJECT_STATUS.FINAL_CONFIRMATION == status) {
    return '最終確認';
  } else if (PROJECT_STATUS.IMPLEMENTATION == status) {
    return '募集完了';
  } else {
    return '';
  }
}

/**
 * JaneJohn案件ステータスを和名ラベルに変換する
 * @param status 案件ステータス
 * @returns 和名ラベル
 */
export function convertStatusToDisplayLabelForJaneJohn(status: string): string {
  if (PROJECT_STATUS.DRAFT == status || PROJECT_STATUS.REVIEW == status) {
    return '募集内容作成(下書き)';
  } else if (PROJECT_STATUS.APPROVED == status) {
    return '募集〜選考';
  } else if (PROJECT_STATUS.FINAL_CONFIRMATION == status) {
    return '最終確認';
  } else if (PROJECT_STATUS.IMPLEMENTATION == status) {
    return '実施中';
  } else if (PROJECT_STATUS.COMPLETION == status) {
    return '完了';
  } else {
    return '';
  }
}

/**
 * その他案件ステータスを和名ラベルに変換する
 * @param status 案件ステータス
 * @returns 和名ラベル
 */
export function convertStatusToDisplayLabelForOther(status: string): string {
  switch (status) {
    case ProjectStatus.Draft:
      return '募集内容作成(下書き)';
    case ProjectStatus.Review:
      return '募集内容作成(下書き)';
    case ProjectStatus.Approved:
      return '募集〜選考';
    case ProjectStatus.FinalConfirmation:
      return '最終確認';
    case ProjectStatus.Implementation:
      return '実施中';
    case ProjectStatus.Completion:
      return '完了';
    default:
      return '';
  }
}

/**
 * 案件のステータスを順番に変換する
 * @param status 対象となるステータス
 * @returns ステータス変換した数値
 */
export function convertStatusToStep(status: string): number {
  switch (status) {
    case PROJECT_STATUS.POST_DRAFT:
      return 1;
    case PROJECT_STATUS.POST_REVIEW:
      return 1;
    case PROJECT_STATUS.APPROVED:
      return 2;
    case PROJECT_STATUS.FINAL_CONFIRMATION:
      return 3;
    case PROJECT_STATUS.IMPLEMENTATION:
      return 4;
    case PROJECT_STATUS.COMPLETION:
      return 5;
    default:
      return -1;
  }
}

/**
 * 案件のステータスを順番に変換する
 * @param status 対象となるステータス
 * @returns ステータス変換した数値
 */
export function convertStatusToStepForJaneJohn(status: string): number {
  switch (status) {
    case PROJECT_STATUS.DRAFT:
      return 1;
    case PROJECT_STATUS.REVIEW:
      return 1;
    case PROJECT_STATUS.APPROVED:
      return 2;
    case PROJECT_STATUS.FINAL_CONFIRMATION:
      return 3;
    case PROJECT_STATUS.IMPLEMENTATION:
      return 4;
    case PROJECT_STATUS.COMPLETION:
      return 5;
    default:
      return -1;
  }
}

/**
 * Emerald Post 案件のステータスを順番に変換する
 * @param status 対象となるステータス
 * @returns ステータス変換した数値
 */
export function convertStatusToStepForEmeraldPost(status: string): number {
  switch (status) {
    case PROJECT_STATUS.POST_DRAFT:
      return 1;
    case PROJECT_STATUS.POST_REVIEW:
      return 2;
    case PROJECT_STATUS.REVIEW:
      return 2;
    case PROJECT_STATUS.WAIT_POST:
      return 3;
    case PROJECT_STATUS.POSTING:
      return 4;
    case PROJECT_STATUS.COMPLETION:
      return 5;
    default:
      return -1;
  }
}

/**
 * Prst 案件のステータスを順番に変換する
 * @param status 対象となるステータス
 * @returns ステータス変換した数値
 */
export function convertStatusToStepForPrst(status: string): number {
  switch (status) {
    case PROJECT_STATUS.DRAFT:
      return 1;
    case PROJECT_STATUS.REVIEW:
      return 1;
    case PROJECT_STATUS.APPROVED:
      return 2;
    case PROJECT_STATUS.FINAL_CONFIRMATION:
      return 3;
    case PROJECT_STATUS.COMPLETION:
      return 4;
    default:
      return -1;
  }
}

/**
 * その他 案件のステータスを順番に変換する
 * @param status 対象となるステータス
 * @returns ステータス変換した数値
 */
export function convertStatusToStepForOther(status: string): number {
  switch (status) {
    case PROJECT_STATUS.DRAFT:
      return 1;
    case PROJECT_STATUS.REVIEW:
      return 1;
    case PROJECT_STATUS.APPROVED:
      return 2;
    case PROJECT_STATUS.FINAL_CONFIRMATION:
      return 3;
    case PROJECT_STATUS.IMPLEMENTATION:
      return 4;
    case PROJECT_STATUS.COMPLETION:
      return 5;
    default:
      return -1;
  }
}
