import Sidebar, { SidebarProps } from '@/components/Sidebar/Sidebar';
export type MainLayoutProps = {
  children: React.ReactNode;
  sidebarProps: SidebarProps;
};

export const MainLayout = (props: MainLayoutProps) => {
  return (
    <div className="flex h-full min-h-screen bg-gray-100">
      <Sidebar {...props.sidebarProps} />
      {props.children}
    </div>
  );
};
