import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';

import { GetTeamInvoiceOutput } from '@/api/team';
import { Button } from '@/components/Elements';
import { MainLayout } from '@/components/Layout/MainLayout';
import { firstSidebarSelect, secondSidebarSelect } from '@/config/sidebar';
// import { getImageUrl } from '@/utils/image';

import { fetchTeamInvoiceInfo } from '../../../hooks/TeamInfo';

export function PlanSelect(): React.ReactElement {
  const plans = [
    {
      name: 'light',
      display_name: 'ライトプラン',
      year: '9,800',
      total: '117,600',
      description: '基本的な機能を試していただくことが可能です。',
      month: '12,740',
      initial_cost: '200,000',
    },
    {
      name: 'basic',
      display_name: 'ベーシックプラン',
      year: '39,800',
      total: '477,600',
      description:
        '基本的な機能に加え、制限内で案件前後に活用できる追加機能も使用可能です。各SNS媒体も並行して活用ができます。',
      month: '55,720',
      initial_cost: '200,000',
    },
    {
      name: 'enterprise',
      display_name: 'エンタープライズプラン',
      year: '79,800',
      total: '957,600',
      description:
        '基本的な機能に加え、案件前後に活用できる追加機能使用数が増えます。さらに無制限にユーザーを追加でき、組織全体を包括的に管理できます。',
      month: '119,700',
      initial_cost: '200,000',
    },
  ];

  // チームの請求情報取得
  const [teamInvoice, setTeamInvoice] = useState<GetTeamInvoiceOutput | null>(null);
  useEffect(() => {
    const getTeamInvoice = async () => {
      try {
        const data: GetTeamInvoiceOutput = await fetchTeamInvoiceInfo();
        setTeamInvoice(data);
      } catch (error) {
        Sentry.captureException(error);
        console.error('API call failed:', error);
      }
    };
    getTeamInvoice();
  }, []);

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.basicInfo,
        secondSideBarselected: secondSidebarSelect.plan,
      }}
    >
      <div className="w-[1181px] bg-gray-100">
        {/* <div id="header" className="mb-[49px] flex h-10 flex-col items-center justify-center self-stretch bg-black">
          <img className="h-6" src={getImageUrl('assets/logo/row_white_2.png')} alt="" />
        </div> */}
        <div className="Title">
          <p className="TitleText">プラン</p>
        </div>

        <div className="flex w-[100%]">
          <div className="mx-6 mb-[270px] mt-12 w-[1133px] rounded-lg bg-white p-6">
            <div className="flex">
              <div className="h-[41px] w-[23.9%] px-[24px] pb-[16px] pt-[1px]"></div>
              {plans.map((plan, index) => (
                <div key={index} className="h-[41px] w-[23%] px-[24px] pb-[16px] pt-[1px]">
                  <span className="h-[24px] w-[256px] text-lg font-semibold leading-6 text-gray-900">
                    {plan.display_name}
                  </span>
                </div>
              ))}
            </div>
            <div className="border-b-[1px] border-gray-200"></div>
            <div className="flex">
              <div className="h-[297px] w-[23.9%] px-[24px] py-[32px]">
                <span className="h-[20px] w-[256px] text-sm font-medium leading-5 text-gray-900">価格</span>
              </div>
              {plans.map((plan, index) => (
                <div key={index} className="w-[23.9%] py-[32px] pl-[24px]">
                  <div className="flex">
                    <p className="h-[40px] text-4xl font-extrabold leading-10 text-gray-900">¥{plan.year}</p>
                    <p className="ml-[4px] mt-[16px] h-[24px] text-sm font-medium leading-6 text-gray-500">
                      /月(年払い)
                    </p>
                  </div>
                  <div className="mt-[4px] flex">
                    <p className="mt-[6px] h-[16px]  items-end text-center text-[12px] font-medium leading-[16px] text-[#888888]">
                      ¥
                    </p>
                    <p className="ml-[2px] h-[28px] items-end text-center text-[16px] font-semibold leading-[28px] text-[#888888]">
                      {plan.total}
                    </p>
                    <p className="mt-[5px] h-[18px] text-center text-[12px] font-normal leading-[18px] text-[#888888]">
                      /年
                    </p>
                  </div>
                  <p className="mt-[16px] h-[80px] text-sm font-normal leading-5 text-gray-500">{plan.description}</p>
                  <div className="mt-[16px] flex">
                    <p className="mt-[6px] h-[16px]  items-end text-center text-[12px] font-medium leading-[16px] text-[#888888]">
                      ¥
                    </p>
                    <p className="ml-[2px] h-[28px] items-end text-center text-[16px] font-semibold leading-[28px] text-[#888888]">
                      {plan.month}
                    </p>
                    <p className="mt-[5px] h-[18px] text-center text-[12px] font-normal leading-[18px] text-[#888888]">
                      /月(月払い)
                    </p>
                  </div>
                  <div className="mt-[16px] flex">
                    <p className="mt-[5px] h-[18px]  text-center text-[12px] font-normal leading-[18px] text-[#888888]">
                      初期費用
                    </p>
                    <p className="ml-[8px] mt-[6px] h-[16px] items-end text-center text-[12px] font-medium leading-[16px] text-[#888888]">
                      ¥
                    </p>
                    <p className="ml-[2px] h-[28px]  items-end text-center text-[16px] font-semibold leading-[28px] text-[#888888]">
                      {plan.initial_cost}
                    </p>
                  </div>
                  {teamInvoice === null ? (
                    <Button
                      className="mt-[24px] h-[38px] w-[230px] text-sm font-medium leading-5"
                      style={{ background: '#007CC2' }}
                    >
                      見積もりを依頼する
                    </Button>
                  ) : (
                    teamInvoice.plan === plan.name && (
                      <Button className="mt-[24px] h-[38px] w-[230px] text-sm font-medium leading-5" disabled>
                        利用中
                      </Button>
                    )
                  )}
                </div>
              ))}
            </div>
            <div className="bg-white">
              <div className="flex h-[45px] border-y border-[#E5E7EB] bg-gray-50">
                <p className="ml-[16px] mt-[12px] h-[20px] text-sm font-medium leading-5 text-gray-900">
                  発行可能なアカウント数
                </p>
              </div>
              {/* <div className="flex h-[60px] border-b border-[#E5E7EB]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  管理者アカウント数
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  1アカウント
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  5アカウント
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  無制限
                </p>
              </div> */}
              <div className="flex h-[81px] border-b border-[#E5E7EB] py-5">
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">アカウント数</p>
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  4アカウント
                  <br />
                  ※管理者アカウント1つまで
                </p>
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  15アカウント
                  <br />
                  ※管理者アカウント5つまで
                </p>
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">無制限</p>
              </div>
              <div className="flex h-[45px] border-b border-[#E5E7EB] bg-gray-50">
                <p className="ml-[16px] mt-[12px] h-[20px] text-sm font-medium leading-5 text-gray-900">
                  案件レポート・分析
                </p>
              </div>
              <div className="flex h-[81px] border-b border-[#E5E7EB] py-5">
                <p className="mx-[24px] w-[21%] pr-[2px] text-sm font-normal leading-5 text-gray-500">
                  インフルエンサーからのフィードバック
                </p>
                <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
              </div>
              {/* TODO:SNSアカウント分析はMVP後に表示
                  <div className="flex h-[60px] border-b border-[#E5E7EB]">
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      SNSアカウント分析
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      1アカウント
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      3アカウント
                    </p>
                    <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                      無制限
                    </p>
                  </div> */}
              <div className="flex h-[61px] border-b border-[#E5E7EB] py-5">
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  レポートデータの保存期間
                </p>
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">1年間</p>
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">無制限</p>
                <p className="mx-[24px] w-[21%] text-sm font-normal leading-5 text-gray-500">無制限</p>
              </div>
              <div className="flex h-[81px] border-b border-[#E5E7EB] py-5">
                <p className="ml-[24px] mr-[2px] w-[21%] pr-1 text-sm font-normal leading-5 text-gray-500">
                  レポートデータのエクスポート（CSV）
                </p>
                {/* Comming soonが解除されたら使用できるよう残しています */}
                {/* <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">ー</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">ー</p>
                    <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p> */}
                <p className="ml-[-20px] flex w-[91%] items-center justify-start pl-[180px] text-[22px] italic text-[#323f57] before:mr-10 before:w-[106px] before:border before:content-[''] after:ml-10 after:w-[106px] after:border after:content-['']">
                  Comming soon
                </p>
              </div>
              <div className="flex h-[45px] border-b border-[#E5E7EB] bg-gray-50">
                <p className="ml-[16px] mt-[12px] h-[20px] w-[272px] text-sm font-medium leading-5 text-gray-900">
                  案件無料実施特典
                </p>
              </div>
              <div className="flex h-[60px] border-b border-[#E5E7EB]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  Good Quality ボーナス
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  1回無料/年
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  2回無料/年
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  5回無料/年
                </p>
              </div>
              {/* <div className="flex h-[60px] border-b border-[#E5E7EB]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  EMERALD POST配信
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  1配信無料/年
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  2配信無料/年
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  3配信無料/年
                </p>
              </div>
              <div className="flex h-[60px] border-b border-[#E5E7EB]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  スポットコンサル
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">ー</p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  1回無料/年
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  2回無料/年
                </p>
              </div>
              <div className="flex h-[60px] border-b border-[#E5E7EB]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  インフルエンサーアンケート
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">ー</p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  1回無料/年
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  3回無料/年
                </p>
              </div> */}

              <div className="flex items-center border-b border-[#E5E7EB]">
                <div className="mr-6 flex w-[23%] flex-col">
                  <p className="h-[61px] border-b border-[#E5E7EB] py-5 pl-6 text-sm font-normal leading-5 text-gray-500">
                    EMERALD POST配信
                  </p>
                  <p className="h-[61px] border-b border-[#E5E7EB] py-5 pl-6 text-sm font-normal leading-5 text-gray-500">
                    スポットコンサル
                  </p>
                  <p className="h-[61px] py-5 pl-6 text-sm font-normal leading-5 text-gray-500">
                    インフルエンサーアンケート
                  </p>
                </div>
                <div className="w-[91%]">
                  <p className="ml-[-20px] flex w-full items-center justify-start pl-[166px] text-[22px] italic text-[#323f57] before:mr-10 before:w-[106px] before:border before:content-[''] after:ml-10 after:w-[106px] after:border after:content-['']">
                    Comming soon
                  </p>
                </div>
              </div>

              <div className="flex h-[45px] border-b border-[#E5E7EB] bg-gray-50">
                <p className="ml-[16px] mt-[12px] h-[20px] w-[272px] text-sm font-medium leading-5 text-gray-900">
                  その他
                </p>
              </div>
              <div className="flex h-[60px] border-b border-[#E5E7EB]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  インフィード広告
                </p>
                {/* <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">ー</p>
                <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p>
                <p className="mx-[24px] flex w-[21%] items-center text-xl text-gray-300">○</p> */}
                <p className="ml-[-34px] flex w-[91%] items-center justify-start pl-[178px] text-[22px] italic text-[#323f57] before:mr-10 before:w-[106px] before:border before:content-[''] after:ml-10 after:w-[106px] after:border after:content-['']">
                  Comming soon
                </p>
              </div>
              <div className="flex h-[60px]">
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  コミュニティ参加人数
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  無制限
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  無制限
                </p>
                <p className="mx-[24px] mt-[20px] h-[20px] w-[21%] text-sm font-normal leading-5 text-gray-500">
                  無制限
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
