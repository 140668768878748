import * as Sentry from '@sentry/react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import { GetTeamOutput } from '@/api/team';
import { Button } from '@/components/Elements/Button';
import { Screen } from '@/components/Elements/Screen';
import { ROUTES } from '@/config/path';
import { fetchAccountInfo, insertAccountInfo } from '@/hooks/AccountInfo';
import { teamJoin, fetchTeamInfo } from '@/hooks/TeamInfo';
import { AuthAPI } from '@/lib/auth';
import { useErrorContext } from '@/providers/errorProvider';
import { InsertAccountInfoRequest } from '@/types/AccountInfo';
import { getImageUrl } from '@/utils/image';
import storage from '@/utils/storage';

const auth: AuthAPI = new AuthAPI('auth0Container');

const headerLogo = getImageUrl('assets/logo/row_white_2.png');
const bodyLogo = getImageUrl('assets/logo/row.png');

/**
 * サイン画面の要素
 * @returns {React.ReactElement} サインイン後画面
 */
export function SignInPost(): React.ReactElement {
  const { showErrorMessage } = useErrorContext();
  const navigate = useNavigate();
  const [isUnverifiedEmail, setIsUnverifiedEmail] = useState<boolean>(false);
  const [retry, setRetry] = useState<boolean>(false);
  const refFirstRef = useRef(true);
  const search = useLocation().search;
  const queryString = new URLSearchParams(search);
  const invite: string | undefined = queryString.get('invite') || undefined;
  const teamId: string | undefined = queryString.get('team_id') || undefined;
  const invitation: string | undefined = queryString.get('invitation') || undefined;

  const handleTopRedirect = () => {
    navigate('/');
  };

  const executeInsertAccountInfo = async () => {
    try {
      const insertAccountInfoRequest: InsertAccountInfoRequest = {};
      console.log('insertAccountInfoRequest:', insertAccountInfoRequest);

      // アカウント登録APIリクエスト
      const data = await insertAccountInfo(insertAccountInfoRequest);
      console.log('executeInsertAccountInfo:', data);
    } catch (error) {
      Sentry.captureException(error);
      console.error('executeInsertAccountInfo failed:', error);
    }
  };

  const executeAccountData = async () => {
    let data = {};
    try {
      // アカウント取得APIリクエスト
      data = await fetchAccountInfo();
      console.log('executeGetAccountInfo:', data);
      if (!data) {
        await executeInsertAccountInfo();
      }
    } catch (error) {
      showErrorMessage(false, undefined);
      Sentry.captureException(error);
      console.error('executeGetAccountInfo failed:', error);
      await executeInsertAccountInfo();
    }

    return data;
  };

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      if (refFirstRef.current) {
        refFirstRef.current = false;
        return;
      }
    }
    (async () => {
      let result;
      try {
        result = await auth.isAuthenticated();
      } catch (ex: any) {
        Sentry.captureException(ex);
        console.log(ex);
        const exCode = ex.code;
        setIsUnverifiedEmail(ex.description === 'UNVERIFIED_EMAIL');
        if (exCode === 'unauthorized') {
          console.log(ex);
          return;
        } else if (exCode === 'invalid_token') {
          setRetry(!retry);
          return;
        } else if (exCode === 'login_required') {
          setRetry(!retry);
          storage.clearToken();
          console.log(ex);
          navigate(ROUTES.AUTH_LOGIN);
        }
      }

      if (result) {
        storage.setToken(result?.accessToken);
        try {
          await executeAccountData();
          try {
            // 招待されている場合、チーム参加してホーム画面へ遷移
            if (invite) {
              await teamJoin(teamId ?? '', { invitation: invitation ?? '' });
              const teamInfo: GetTeamOutput = await fetchTeamInfo(teamId ?? '');
              navigate(ROUTES.HOME, {
                state: { shouldOpenModal: true, modalMessage: `「${teamInfo.teamName}」に参加しました。` },
              });
              return;
            }
          } catch (ex) {
            Sentry.captureException(ex);
            console.error(ex);
          }
          navigate('/team-select');
        } catch (ex) {
          Sentry.captureException(ex);
          console.error(ex);
        }
      }
    })();
  }, [retry]);

  return isUnverifiedEmail ? (
    <Screen className="w-[1440px]">
      <div className="flex h-10 items-center justify-center bg-black">
        <img src={headerLogo} alt="INFLUFECTロゴ" className="h-[34.37px] w-[113.19px]" />
      </div>
      <div className="mx-auto mt-10 w-[1100px] items-center bg-gray-50 px-0 py-20">
        <div className="grid grid-cols-1 gap-16">
          <div className="grid grid-cols-1 gap-10">
            <div className="flex justify-center">
              <img src={bodyLogo} alt="INFLUFECTロゴ" className="h-[57.29px] w-[188.65px]" />
            </div>
            <p className="text-center text-2xl">確認メールを送信しました</p>
            <div className="text-center">
              <p>ご登録いただいたメールアドレスに確認メールを送信しました。</p>
              <p>メール内のURLからメールアドレスの認証を完了後、再度ログインしてください。</p>
            </div>
            <p className="text-center">見つからない場合、迷惑メールフォルダに入っている可能性があります。</p>
          </div>
          <div className="flex justify-center">
            <Button onClick={handleTopRedirect}>トップページへ戻る</Button>
          </div>
          <p className="text-center text-xs">©︎ 2023 LIDDELL Inc.</p>
        </div>
      </div>
    </Screen>
  ) : (
    <></>
  );
}
