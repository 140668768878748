import React from 'react';

import AppProvider from '@/providers/app';
import { ErrorProvider } from '@/providers/errorProvider';
import AppRoutes from '@/routes';
import { AxiosInterceptor } from '@/utils/axiosInstance';

import ScrollToTop from './components/Scroll/ScrollToTop';

function App(): React.ReactElement {
  return (
    <AppProvider>
      <ErrorProvider>
        <AxiosInterceptor>
          <ScrollToTop />
          <AppRoutes />
        </AxiosInterceptor>
      </ErrorProvider>
    </AppProvider>
  );
}

export default App;
