import * as Sentry from '@sentry/react';
import React, { useState, createContext, Dispatch, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { ProjectType, DefaultApi as ProjectApi } from '@/api/project';
import { DefaultApi, ProjectImageRow } from '@/api/prst';
import { ReactComponent as ErrorCircleIcon } from '@/assets/icons/bx-error-circle.svg';
import NoImage from '@/assets/images/NoImage.png';
import SmartPhone from '@/assets/images/SmartPhone.png';
import { Button } from '@/components/Elements';
import Estimate from '@/components/ProjectManagements/Components/Estimate';
import KpiComponent from '@/components/ProjectManagements/Components/KPI';
import { DetailImages } from '@/components/ProjectManagements/Modals/DetailImages';
import { FinalAgreeModal } from '@/components/ProjectManagements/Modals/FinalAgreeModal';
import { ProjectOverview, ProjectOverviewProps } from '@/components/ProjectManagements/Modals/ProjectOverview';
import {
  ProjectRecruitingState,
  ProjectRecruitingTitle,
} from '@/components/ProjectManagements/Modals/ProjectRecruitingTitle';
import { PublicAttention, DetailSettings } from '@/components/ProjectManagements/Modals/PublicAttention';
import { PROJECT_STATUS } from '@/config';
import { axiosInstance } from '@/utils/axiosInstance';
import { getImageUrl } from '@/utils/image';
import { snsIcon } from '@/utils/sns';

//import { projectOverview } from '../../../../components/ProjectManagements/ProjectOverview/Interfaces/ProjectOverViewInterfaces';
//import { displayRecruitmentInfluencers } from '../../../project/utils/logic';
import { popupInputData } from '../../interface/prstProjectInterface';
import expectedProjectDurationDisplay from '../../utils/ExpectedProjectDurationDisplay';
//import NumberFormatComma from '../../utils/NumberFormatComma';
import DetailCommentImage from '../assets/bx-comment-detail.svg';
import SmartphoneView from '../SmartphoneView';

export const PopupDetail_PRST = createContext({
  buttonState: {},
  buttonDispatch: {} as Dispatch<any>,
});

export default function Recruitment(props: popupInputData) {
  const location = useLocation();
  const beforeDisplayTitle = location.state?.beforeDisplayTitle;

  const beforeDisplayDescription = location.state?.beforeDisplayDescription;

  const [finishAgreeModalIsOpen, setFinishAgreeModalIsOpen] = useState(false);
  const [isRequesting, setIsRequesting] = useState<boolean>(false);

  const [status, setStatus] = useState<string>(props.status);

  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    // getMonth()は0が1月を意味するため、1を加える
    const month = date.getMonth() + 1;
    const day = date.getDate();

    // 月と日が1桁の場合、先頭に0を付けて2桁にする
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;

    return `${year}-${formattedMonth}-${formattedDay}`;
  };

  const detailSettingArgs: DetailSettings = {
    open: false,
    projectType: ProjectType.Prst,
    companyName: props.companyName,
    isSecret: props.isSecret,
    notice: props.notice,
  };

  interface DetailImage {
    id: string;
    objectKey: string;
  }

  const [detailImages, setDetailImages] = useState<DetailImage[]>([]);

  useEffect(() => {
    const images: DetailImage[] = [];

    props.images.map((image: ProjectImageRow) => {
      images.push({ id: image.id, objectKey: getImageUrl(image.objectKey) });
    });
    setDetailImages(images);
  }, [props.images]);

  const [detailSettingProps, setDetailSettingProps] = useState<DetailSettings>(detailSettingArgs);

  const descriptionItem = (key: string, value: string | string[]) => {
    value = value ? value : [];
    return (
      <>
        <div className="flex flex-row items-center whitespace-pre pt-[16px]">
          <div className="min-h-[20px] w-[233px]">
            <span className="text-sm font-semibold leading-5 text-gray-700">{key}</span>
          </div>
          <div className="flex min-h-[20px] w-[445.75px] flex-wrap items-center gap-2">
            {typeof value == 'string' ? (
              <p className="whitespace-pre-line text-sm font-semibold leading-5 text-gray-900">{value}</p>
            ) : value.length > 0 ? (
              value.map((item) => {
                return (
                  <div key={item}>
                    <div
                      className={`${
                        key === '歓迎条件'
                          ? 'inline-flex items-center rounded-xl bg-gray-100 px-3 py-0.5 text-sm font-medium text-gray-800'
                          : 'whitespace-pre-line text-sm font-semibold leading-5 text-gray-900'
                      }`}
                    >
                      {key === '歓迎条件' ? item : `・${item}`}
                    </div>
                  </div>
                );
              })
            ) : (
              '-'
            )}
          </div>
        </div>
        <div className="h-[1px] w-[678.75px] pt-[16px]">
          <div className="border-[1px] border-gray-200"></div>
        </div>
      </>
    );
  };

  const handleEditClick = (index: number) => {
    if (index === 0) {
      setProjectTitleState((prev) => {
        return { ...prev, open: true };
      });
      return;
    } else if (index === 1) {
      setImageModalIsOpen(true);
    } else if (index === 2) {
      setProjectOverviewState((prev) => {
        return { ...prev, open: true };
      });
    } else if (index === 3) {
      setDetailSettingProps((prev) => {
        return { ...prev, open: true };
      });
    }
  };

  const selectSNS = props.selectSNS;

  // 申請ボタンを押せる条件の関数
  const isApplicationButtonEnable = () => {
    if (
      // 案件募集タイトル
      projectTitleState.displayTitle.length > 0 &&
      projectTitleState.displayTags &&
      projectTitleState.displayTags.length > 0 &&
      projectTitleState.displayImage &&
      projectTitleState.displayImage.length > 0 &&
      // 詳細画像
      //detailImageArgs.displayImages &&
      //detailImageArgs.displayImages.filter((image) => image !== NoImage).length > 0 &&
      // 案件概要
      projectOverviewState.displayRecruitmentPlanCount.length > 0 &&
      projectOverviewState.displayRewardAmount.length > 0 &&
      //projectOverviewState.displayTags.length > 0 &&
      projectOverviewState.displayDescription.length > 0
      // projectOverviewState.creatorImage.length > 0 &&
      // projectOverviewState.displayStart.length > 0 &&
      // projectOverviewState.displayEnd.length > 0
    ) {
      return false;
    } else {
      return true;
    }
  };

  const displayValue = (beforeDisplayValue: string, dbGetValue: string | number) => {
    if (!dbGetValue) {
      dbGetValue = '';
    } else {
      dbGetValue = dbGetValue.toString();
    }
    return beforeDisplayValue || dbGetValue || '-';
  };

  const finalAgree = async (): Promise<void> => {
    try {
      const api: any = new ProjectApi(undefined, undefined, axiosInstance);
      await api.postProjectApplication(props.projectId);

      setStatus(PROJECT_STATUS.REVIEW);

      props.fetchProjectInfo();

      setFinishAgreeModalIsOpen(false);
      props.setIsRequesting(true);
      setIsRequesting(true);
    } catch (error) {
      Sentry.captureException(error);
      console.error('案件内容の申請に失敗しました。', error);
    }
  };

  const projectRecruitingTitleProps: ProjectRecruitingState = {
    open: false,
    title: '',
    sns: 'Instagram',
    file: '',
    displayTitle: props.title ? props.title : '-',
    tags: [],
    tag: '',
    displayTags: [],
    displayImage: '',
    projectName: 'prst',
    titleError: false,
    tagError: false,
    imageError: false,
    validationTitleMessage: '',
    validationTagMessage: '',
    validationImageMessage: '',
  };

  const [projectTitleState, setProjectTitleState] = useState<ProjectRecruitingState>(projectRecruitingTitleProps);

  useEffect(() => {
    const fetchImage = async () => {
      setProjectTitleState((prev) => ({
        ...prev,
        open: false,
        displayTitle: props.title ? props.title : '-',
        title: props.title ? props.title : '',
        tags: props.titleTags ? props.titleTags : [],
        displayTags: props.titleTags ? props.titleTags : [],
        file: props.image ? getImageUrl(props.image) : '',
        displayImage: props.image ? getImageUrl(props.image) : '',
      }));
    };
    fetchImage();
    setStatus(props.status);
  }, [props.title, props.image, props.titleTags, props.status]);

  useEffect(() => {
    setDetailSettingProps((prev) => {
      return {
        ...prev,
        open: false,
        companyName: props.companyName,
        isSecret: props.isSecret,
        notice: props.notice,
      };
    });
  }, [props.notice, props.isSecret]);

  const [imageModalIsOpen, setImageModalIsOpen] = useState<boolean>(false);

  const [projectOverviewState, setProjectOverviewState] = useState<ProjectOverviewProps>({
    open: false,
    rewardAmount: props.summary?.rewards ? props.summary?.rewards : 0,
    displayRewardAmount: props.summary?.rewards ? String(props.summary?.rewards) : '',
    recruitmentPlanCount: props.summary?.adoptionPlan ? props.summary?.adoptionPlan : 0,
    displayRecruitmentPlanCount: props.summary?.adoptionPlan ? String(props.summary?.adoptionPlan) : '',
    tags: props.summary?.genres ? props.summary?.genres : [],
    displayTags: props.summary?.genres ? props.summary?.genres : [],
    description: props.summary?.description ? props.summary?.description : '',
    displayDescription: props.summary?.description ? props.summary?.description : '',
    tag: '',
    radioBoxValue: '',
    selectedPrefectures: [],
    prefecturesRadioBoxValue: '',
    creatorImage: props.summary?.creatorImage || '',
    displayCreatorImage: props.summary?.creatorImage || '',
    contractFrom: props.summary?.contractFrom || formatDate(new Date()),
    displayContractFrom: props.summary?.contractFrom || '',
    contractTo: props.summary?.contractTo || formatDate(new Date()),
    displayContractTo: props.summary?.contractTo || '',
    projectName: 'prst',
    displaySelectedPrefectures: [],
  });

  useEffect(() => {
    setProjectOverviewState((prev) => ({
      ...prev,
      open: false,
      rewardAmount: props.summary?.rewards ? props.summary?.rewards : 0,
      displayRewardAmount: props.summary?.rewards ? String(props.summary?.rewards) : '',
      recruitmentPlanCount: props.summary?.adoptionPlan ? props.summary?.adoptionPlan : 0,
      displayRecruitmentPlanCount: props.summary?.adoptionPlan ? String(props.summary?.adoptionPlan) : '',
      tags: props.summary?.genres ? props.summary?.genres : [],
      displayTags: props.summary?.genres ? props.summary?.genres : [],
      description: props.summary?.description ? props.summary?.description : '',
      displayDescription: props.summary?.description ? props.summary?.description : '',
      tag: '',
      radioBoxValue: '',
      selectedPrefectures: [],
      prefecturesRadioBoxValue: '',
      creatorImage: props.summary?.creatorImage || '',
      displayCreatorImage: props.summary?.creatorImage || '',
      contractFrom: props.summary?.contractFrom || formatDate(new Date()),
      displayContractFrom: props.summary?.contractFrom || '',
      contractTo: props.summary?.contractTo || formatDate(new Date()),
      displayContractTo: props.summary?.contractTo || '',
    }));
  }, [props.summary?.rewards, props.summary?.adoptionPlan, props.summary?.genres, props.summary?.description]);

  const PutPrstProjectNotice = (projectId: string) => async (isPublic: boolean, notice: string[]) => {
    const api: any = new DefaultApi(undefined, undefined, axiosInstance);
    const response = await api.putPrstNotice(projectId, {
      isPublic,
      notice,
    });
    setDetailSettingProps((prev) => {
      return {
        ...prev,
        open: response.status === 200 ? false : true,
      };
    });
    props.fetchProjectInfo();
  };

  const PutPrstPostMediaUpdate = (projectId: string) => async (uploads?: File[], deleteIds?: string[]) => {
    const api: any = new DefaultApi(undefined, undefined, axiosInstance);
    const response = await api.putPrstMedia(projectId, uploads, deleteIds);
    const images: DetailImage[] = [];

    response.data.detailImages.map((image: any) => {
      images.push({ id: image.id, objectKey: getImageUrl(image.objectKey) });
      setDetailImages(images);
    });
    props.fetchProjectInfo();
  };

  const putPrstProjectTitle = (projectId: string) => async (title: string, tags: string[], file: File | undefined) => {
    //TODO propsのprojectRecruitingTitlePropsから値を取得してAPIを叩く
    const api: any = new DefaultApi(undefined, undefined, axiosInstance);

    try {
      const result = await api.putPrstTitle(projectId, title, tags, file);
      setProjectTitleState((prev) => {
        return {
          ...prev,
          open: result.status === 200 ? false : true,
        };
      });
      props.fetchProjectInfo();
    } catch (error) {
      Sentry.captureException(error);
      console.log(error);
    }
  };

  const PutPrstProjectOverview = (projectId: string) => async () => {
    //TODO propsのprojectOverviewPropsから値を取得してAPIを叩く
    const api: any = new DefaultApi(undefined, undefined, axiosInstance);
    const result = await api.putPrstSummary(projectId, {
      adoptionPlan: projectOverviewState.recruitmentPlanCount,
      rewards: projectOverviewState.rewardAmount,
      genres: projectOverviewState.tags,
      description: projectOverviewState.description,
      creatorImage: projectOverviewState.creatorImage,
      contractFrom: projectOverviewState.contractFrom,
      contractTo: projectOverviewState.contractTo,
    });
    setProjectOverviewState((prev) => {
      return {
        ...prev,
        open: result.status === 200 ? false : true,
      };
    });
    props.fetchProjectInfo();
  };
  const displayImage = (images: DetailImage[]) => {
    console.log(images);
    {
      return (
        <div className="flex">
          <img src={images[0]?.objectKey || NoImage} alt="" className="mr-[16px] h-[140px] w-[219px]" />
          <img src={images[1]?.objectKey || NoImage} alt="" className="mr-[16px] h-[140px] w-[219px]" />
          <img src={images[2]?.objectKey || NoImage} alt="" className="mr-[16px] h-[140px] w-[219px]" />
        </div>
      );
    }
  };

  return (
    <div className="w-[1181px]">
      <div className="flex items-center justify-between border-b-[1px] border-gray-200 pb-[20px]">
        <div className="pl-16">
          <span className="pt-[9px] text-lg font-medium leading-6 text-gray-900">募集内容詳細</span>
        </div>
        <div className="pr-16">
          {/* TODO バックエンド作成時はすべて、コンストに移行 */}
          {status == PROJECT_STATUS.DRAFT && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="primary"
                size="sm"
                style={{ margin: '10px' }}
                disabled={isApplicationButtonEnable()}
                onClick={() => {
                  setFinishAgreeModalIsOpen(true);
                }}
              >
                この内容で申請する
              </Button>
            </div>
          )}
          {status == PROJECT_STATUS.REVIEW && (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                variant="primary"
                size="sm"
                style={{ margin: '10px' }}
                disabled={true}
                onClick={() => {
                  setFinishAgreeModalIsOpen(true);
                }}
              >
                申請中
              </Button>
            </div>
          )}
        </div>
      </div>
      {status == PROJECT_STATUS.DRAFT ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="pl-4 pr-3 pt-4">
            <ErrorCircleIcon width={20} height={20} fill="#60A5FA"></ErrorCircleIcon>
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">
              募集内容を確認し、申請をしてください。
            </p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              申請されましたら、LIDDELL担当者が再確認の上、募集に入ります。
            </p>
          </div>
        </div>
      ) : status == PROJECT_STATUS.DRAFT && isRequesting === true ? (
        <div className="mb-[10px] ml-[40px] mt-[24px] flex h-[80px] w-[1053px] bg-blue-50 p-[16px]">
          <div className="pl-4 pr-3 pt-4">
            <ErrorCircleIcon width={20} height={20} fill="#60A5FA"></ErrorCircleIcon>
          </div>
          <div className="h-[48px] w-[989px] text-blue-800">
            <p className="mb-[8px] h-[20px] w-[989px] text-sm font-medium leading-5">申請を受付けました。</p>
            <p className="h-[20px] w-[989px] text-sm font-medium leading-5">
              LIDDELL担当者が確認しておりますので、お待ちください。
            </p>
          </div>
        </div>
      ) : (
        ''
      )}
      <div className="flex flex-row">
        <div>
          <div className="flex pl-[40px] pt-[24px]">
            <div className="h-auto w-[726.5px] bg-white pb-[16px]">
              <div className="h-[44+48px] w-[695px] pl-[24px] pt-[24px]">
                <div className="flex h-[44+16px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-base font-semibold leading-7 text-gray-500">案件募集タイトル</span>
                  </div>
                  {status == PROJECT_STATUS.DRAFT ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(0)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="flex flex-row gap-x-3 pl-6 pt-6">
                <div>
                  <img className="h-[116px] w-[174.12px]" src={projectTitleState.displayImage || NoImage} alt="" />
                </div>
                <div className="flex h-[116+24px] w-[527px]">
                  <div className="h-[28px] w-[504.88px] content-center pl-[16px]">
                    <div className="text-base font-semibold leading-7 ">
                      {displayValue(beforeDisplayTitle, projectTitleState.title)}
                    </div>
                    <div className="flex pt-[13px]">
                      {snsIcon(selectSNS)}
                      {projectTitleState.displayTags &&
                        projectTitleState.displayTags.map((tag: string, index: number) => {
                          return (
                            <div key={index} className="pl-2">
                              <span className="inline-flex rounded-full  bg-gray-100 px-2 py-0.5 text-xs font-medium text-gray-800">
                                {tag}
                              </span>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  {status == PROJECT_STATUS.POST_REVIEW ? (
                    <button className="h-[32px] w-[32px] p-[4px]">
                      <div className="h-[24px] w-[24px]">
                        <img src={DetailCommentImage} alt="" className="w-full" />
                      </div>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
              <div className="h-[176+48px] w-[695px] pl-[24px] pt-[48px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-base font-semibold leading-7 text-gray-500">詳細画像</span>
                  </div>
                  {status == PROJECT_STATUS.DRAFT ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(1)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                  {status == PROJECT_STATUS.POST_REVIEW ? (
                    <button className="h-[32px] w-[32px] p-[4px]">
                      <div className="h-[24px] w-[24px]">
                        <img src={DetailCommentImage} alt="" className="w-full" />
                      </div>
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                {displayImage(detailImages)}
              </div>
              <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">案件概要</span>
                  </div>
                  {status == PROJECT_STATUS.DRAFT ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(2)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="h-[1px] w-[678.75px] pt-[16px]">
                  <div className="border-[1px] border-gray-200"></div>
                </div>
                {descriptionItem('採用予定人数', projectOverviewState.displayRecruitmentPlanCount || '-')}
                {descriptionItem(
                  'インフルエンサーへの報酬',
                  projectOverviewState.displayRewardAmount == '' ||
                    Number(projectOverviewState.displayRewardAmount) <= -1
                    ? ''
                    : `￥${projectOverviewState.displayRewardAmount}`
                )}
                {descriptionItem('歓迎条件', projectOverviewState.displayTags)}
                {descriptionItem(
                  '案件説明',
                  displayValue(beforeDisplayDescription, projectOverviewState.displayDescription)
                )}
                {descriptionItem(
                  '募集クリエイターイメージ',
                  displayValue(beforeDisplayDescription, projectOverviewState.displayCreatorImage || '')
                )}
                {descriptionItem(
                  '契約期間',
                  displayValue(
                    beforeDisplayDescription,
                    `${projectOverviewState.displayContractFrom}~${projectOverviewState.displayContractTo}`
                  )
                )}
              </div>
              <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">詳細設定</span>
                  </div>
                  {status == PROJECT_STATUS.DRAFT ? (
                    <button className="right-4 bg-transparent text-blue-500" onClick={() => handleEditClick(3)}>
                      編集
                    </button>
                  ) : (
                    ''
                  )}
                </div>
                <div className="h-[1px] w-[678.75px] pt-[16px]">
                  <div className="border-[1px] border-gray-200"></div>
                </div>
                {descriptionItem('注意事項', detailSettingProps.notice.length > 0 ? detailSettingProps.notice : '-')}
                {descriptionItem('企業名公開', detailSettingProps.isSecret ? '採用者のみ公開' : '募集時から全体に公開')}
              </div>
              <div className="h-[326+40px] w-[678.75px] gap-[16px] pl-[24px] pt-[40px]">
                <div className="flex h-[28px] w-[678.75px] justify-between">
                  <div className="h-[28px] w-[344px] content-center">
                    <span className="text-lg font-semibold leading-7 text-gray-500">スケジュール</span>
                  </div>
                </div>
                <div className="h-[1px] w-[678.75px] pt-[16px]">
                  <div className="border-[1px] border-gray-200"></div>
                </div>
                {props.schedules &&
                  props.schedules.map((schedule) => {
                    return descriptionItem(
                      schedule.label,
                      !schedule.from || !schedule.to ? '-' : expectedProjectDurationDisplay(schedule.from, schedule.to)
                    );
                  })}
              </div>
              {props.kpiList &&
                KpiComponent(
                  props.kpiList.items.length > 0
                    ? props.kpiList.items.map((item, index) => ({
                        key: item.name || index,
                        name: item.name || '-',
                        comment: item.comment || '-',
                      }))
                    : []
                )}
              {props.estimate ? Estimate(props.estimate) : ''}
            </div>
          </div>
        </div>
        <div className="h-[689.76px] w-[286.25px]">
          {status == PROJECT_STATUS.DRAFT ? (
            <div>
              <p className="h-[28+24px] w-[240+40px] pl-[40px] pt-[24px] text-base font-semibold leading-7 text-gray-500">
                インフルエンサー画面プレビュー
              </p>
              <img className="h-[597.76px] w-[326.25px] pl-[40px] pt-[16px]" src={SmartPhone} alt="" />
            </div>
          ) : (
            <SmartphoneView readyForApplication={true} />
          )}
        </div>
      </div>
      <ProjectRecruitingTitle
        projectRecruitngState={projectTitleState}
        setProjectRecruitingState={setProjectTitleState}
        PutTitle={putPrstProjectTitle(props.projectId)}
      />
      <DetailImages
        projectType={ProjectType.Prst}
        isOpen={imageModalIsOpen}
        setIsOpen={setImageModalIsOpen}
        detailImages={detailImages}
        PutPostMedia={PutPrstPostMediaUpdate(props.projectId)}
      ></DetailImages>

      <ProjectOverview
        projectOverviewProps={projectOverviewState}
        setProjectOverviewProps={setProjectOverviewState}
        PutProjectOverview={PutPrstProjectOverview(props.projectId)}
      ></ProjectOverview>

      <PublicAttention
        detailSettings={detailSettingProps}
        setDetailSettings={setDetailSettingProps}
        PutProjectNotice={PutPrstProjectNotice(props.projectId)}
      />
      <FinalAgreeModal
        open={finishAgreeModalIsOpen}
        onClose={() => setFinishAgreeModalIsOpen(false)}
        onClickAgree={() => finalAgree()}
      />
    </div>
  );
}
