import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { GetInformationDetailOutput, GetInformationOutput, GetInformationRowsEnum } from '@/api/information';
import { GetNotificationsOutput } from '@/api/notification';
import { MainLayout } from '@/components/Layout/MainLayout';
import { PROJECT_TYPES } from '@/config/index';
import { firstSidebarSelect } from '@/config/sidebar';
import { fetchInformationInfo, fetchInformationsInfo } from '@/hooks/InfomationInfo';
import { fetchNoticesInfo, noticeSubscription } from '@/hooks/NoticeInfo';

import NotificationListNone from '../components/NotificationListNone';
import { projectTableInformation } from '../components/Pagenation';

import { Notification } from './menu_contents/Notification';
import { Related_Notification } from './menu_contents/Related_Notification';

export function NotificationList(): React.ReactElement {
  const navigate = useNavigate();
  const tabs = [
    { name: '案件関連通知', current: true, value: 'related_notification' },
    { name: 'お知らせ', current: false, value: 'notification' },
  ];

  // pagenation 用の変数。ここから
  const [totalRows, setTotalRows] = useState<number>(0);
  const [totalPages, setTotalPages] = useState<number>(0);
  const [perPage, setPerPage] = useState<GetInformationRowsEnum>(30);
  const [startPageNumber, setStartPageNumber] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  // pagenation 用の変数。ここまで

  const [selectedTab, setSelectedTab] = useState('related_notification');

  const setValue = (value: string) => {
    const selectedValue = tabs.filter((tab) => tab.value == value)[0].value;
    setSelectedTab(selectedValue);
  };

  const tableInformation: projectTableInformation = {
    totalRows: totalRows,
    totalPages: totalPages,
    perPage: perPage,
    setPerPage: setPerPage,
    startPageNumber: startPageNumber,
    setStartPageNumber: setStartPageNumber,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
  };

  const [isDisplayListView, setIsDisplayListView] = useState(true);
  const [isProjectInformationList, setIsProjectInformationList] = useState(true);
  const [fetchNotices, setFetchNotices] = useState<GetNotificationsOutput | null>(null);
  const [fetchInformations, setFetchInformations] = useState<GetInformationOutput | null>(null);
  // お知らせ詳細
  const [notice, setNotice] = useState<GetInformationDetailOutput>();
  const [noticeSubscriptioned, setNoticeSubscriptioned] = useState(false);
  const [informationSubscriptioned, setInformationSubscriptioned] = useState(false);

  // 案件関連通知一覧取得API
  useEffect(() => {
    if (!isProjectInformationList) return; // お知らせの場合処理しない
    const executeFetchNoticesInfo = async () => {
      const data = await fetchNoticesInfo(perPage, currentPage, 'asc');
      setFetchNotices(data);
      setTotalRows(data.totalRows);
      setTotalPages(data.totalPages);
      console.log('setFetchNotices:', data);
    };
    executeFetchNoticesInfo();
  }, [perPage, currentPage, isProjectInformationList]);

  // お知らせ一覧取得API
  useEffect(() => {
    if (isProjectInformationList) return; // 案件関連通知の場合処理しない
    const executeFetchInformationsInfo = async () => {
      const tmpFetchInformationIds: string[] = [];
      const data = await fetchInformationsInfo(perPage, currentPage, 'asc');
      setFetchInformations(data);
      setTotalRows(data.totalRows);
      setTotalPages(data.totalPages);
      console.log('setFetchInformations:', data);
      data?.rows?.map((item) => {
        tmpFetchInformationIds.push(item.id);
      });
    };
    executeFetchInformationsInfo();
  }, [perPage, currentPage, isProjectInformationList]);

  // 通知購読API実行
  useEffect(() => {
    const executeNoticeSubscription = async () => {
      const data = await noticeSubscription();
      console.log('executeNoticeSubscription:', data);
      setNoticeSubscriptioned(data.subscribed);
    };
    executeNoticeSubscription();
  }, []);

  // お知らせ購読API実行
  useEffect(() => {
    const executeInformationSubscription = async () => {
      const data = await noticeSubscription();
      console.log('executeInformationSubscription:', data);
      setInformationSubscriptioned(data.subscribed);
      return true;
    };
    executeInformationSubscription();
  }, []);

  const fetchNoticesIsExists = () => {
    const fetchNoticesLength = fetchNotices?.rows?.length || 0;
    return fetchNoticesLength > 0;
  };

  const fetchInformationsIsExists = () => {
    const fetchInformationsLength = fetchInformations?.rows?.length || 0;
    return fetchInformationsLength > 0;
  };

  const handleSelectNotification = async (id: string) => {
    const data = await fetchInformationInfo(id);
    console.log('information_id:' + id);
    console.log('tmpFetchDetailInformations:', data);
    setNotice(data);
    if (data.url) {
      window.open(data.url, '_blank');
    } else {
      setIsDisplayListView(false);
    }
  };

  /** お知らせ詳細リダイレクト */
  const handleRedirect = (projectType: string, projectId: string) => {
    if (projectType === PROJECT_TYPES.SPIRIT) {
      navigate(`/spirit?projectId=${projectId}`);
    } else if (projectType === PROJECT_TYPES.EMERALD_POST) {
      navigate(`/emerald_project?type=EmeraldPost?projectId=${projectId}`);
    } else if (projectType === PROJECT_TYPES.PRST) {
      navigate(`/PRST_project?type=PRST$?projectId=${projectId}`);
    } else if (projectType === PROJECT_TYPES.JANE_JOHN) {
      navigate(`/jane_john?type=JANEJOHN?projectId=${projectId}`);
    }
  };

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.notification,
        secondSideBarselected: '',
      }}
    >
      <div className="ml-[-80px] flex min-h-[1076px] w-[1182px] shrink-0 flex-col items-start bg-gray-100">
        <div className="flex w-full flex-col items-center gap-6 self-stretch pl-6 pt-8">
          <div className="flex w-full flex-col items-center gap-6">
            <div className="flex w-full flex-col items-center gap-4">
              <div className="flex h-[32px] w-[1059px] items-start gap-[10px]">
                <span className="text-2xl font-bold leading-8 text-gray-900">
                  {isProjectInformationList ? '通知一覧' : isDisplayListView ? '通知一覧' : 'お知らせ'}
                </span>
              </div>
              <div>
                {/* タブのレンダリング部分 */}
                <nav className="-mb-px flex w-[1060px] space-x-8 border-b border-gray-200" aria-label="Tabs">
                  {tabs.map((tab) => (
                    <a
                      key={tab.name}
                      role="presentation"
                      className={clsx(
                        tab.value === selectedTab
                          ? 'cursor-pointer border-[#007CC2] text-[#007CC2]'
                          : 'cursor-pointer border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                        'group inline-flex items-center border-b-2 px-1 py-4 text-sm font-medium'
                      )}
                      onClick={() => {
                        setValue(tab.value);
                        setIsProjectInformationList(tab.value == 'related_notification');
                        setCurrentPage(1);
                      }}
                      aria-current={tab.current ? 'page' : undefined}
                    >
                      <span>{tab.name}</span>
                    </a>
                  ))}
                </nav>
                {/*タブのレンダリング ここまで*/}
              </div>
              <div className="w-full">
                {selectedTab == 'related_notification' ? (
                  <div className="ml-[50px]">
                    {fetchNoticesIsExists()
                      ? Related_Notification(
                          tableInformation,
                          selectedTab,
                          fetchNotices,
                          noticeSubscriptioned,
                          handleRedirect
                        )
                      : NotificationListNone('通知')}
                  </div>
                ) : (
                  <div>
                    {fetchInformationsIsExists()
                      ? Notification(
                          tableInformation,
                          selectedTab,
                          setSelectedTab,
                          isDisplayListView,
                          setIsDisplayListView,
                          handleSelectNotification,
                          fetchInformations,
                          informationSubscriptioned,
                          notice,
                          setNotice
                        )
                      : NotificationListNone('お知らせ')}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
