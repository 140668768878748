import React, { useState } from 'react';

import { SelectType } from '../PopupInterfaces';

import Header from './Header';

export interface layoutProps {
  titile: string;
  isTop: boolean;
  isLast: boolean;
  children: React.ReactElement;
  selected: SelectType[] | undefined;
  index: number;
  setSelected: React.Dispatch<React.SetStateAction<SelectType[] | undefined>>;
}

export interface stepProps {
  seq: string;
  title: string;
}

export default function Layout(props: layoutProps): React.ReactElement {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Header
        title={props.titile}
        isOpen={open}
        isTop={props.isTop}
        isLast={props.isLast}
        handleOpen={handleOpen}
        handleClose={handleClose}
        selected={props.selected}
        index={props.index}
        setSelected={props.setSelected}
      ></Header>
      <div>{open ? props.children : <></>}</div>
    </div>
  );
}

export function Step(props: stepProps): React.ReactElement {
  return (
    <div className="flex flex-row items-center gap-4 p-0">
      <div className="flex h-[40px] w-[40px] flex-col items-center justify-center rounded-[20px] border-[2px] border-[#007CC2] p-0">
        <span className="text-sm font-medium leading-4 text-[#007CC2]">{props.seq}</span>
      </div>
      <div>
        <span>{props.title}</span>
      </div>
      <div>
        <div className="h-[1px] w-[440px] bg-gray-200"></div>
      </div>
    </div>
  );
}
