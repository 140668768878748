import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Editor as UIEditor } from '@tinymce/tinymce-react';
import { Fragment, useState } from 'react';
import { Editor } from 'tinymce';

import { Button } from '@/components/Elements/Button/Button';
import { TextArea } from '@/components/Elements/TextArea';
import { EmeraldPostInformation } from '@/types/EmeraldPost';

import { useNoticeContentInsertImage } from '../../hooks/detailEdit/recruitmentDetail/useNoticeContentInsertImage';

import { NoticeContentInsertImageDialog } from './NoticeContentInsertImageDialog';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

interface Props {
  open: boolean;
  text: EmeraldPostInformation;
  PutPostNoticeUpdate: (annouce: EmeraldPostInformation) => Promise<void>;
  onClose: (isSave: boolean) => void;
}

export const AnnouncementDetails = (props: Props) => {
  const [displayNotice, setDisplayNotice] = useState<EmeraldPostInformation>(props.text);
  const [targetEditor, setTargetEditor] = useState<Editor | null>(null);
  const useInsertImageDialog = useNoticeContentInsertImage(targetEditor);
  const { handleOpenInsertImageDialog } = useInsertImageDialog;

  const handleEditorChange = (content: string) => {
    setDisplayNotice((info) => ({ ...info, notice: content }));
  };

  const handleEditorSave = async (announce: EmeraldPostInformation) => {
    await props.PutPostNoticeUpdate({
      title: announce.title ? announce.title : '',
      notice: announce.notice ? announce.notice : '',
    });
    props.onClose(true);
  };
  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          props.onClose(false);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 opacity-30 transition-opacity" style={{ background: 'rgba(107, 114, 128)' }} />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative h-[622px] w-[768px] overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      props.onClose(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-[24px] w-[24px]" aria-hidden="true" />
                  </button>
                </div>
                <div className="">
                  <div className="text-center sm:text-left">
                    <Dialog.Title as="h2" className="h-[24px] w-[696px] text-lg font-semibold leading-6 text-gray-900">
                      お知らせ内容詳細
                    </Dialog.Title>
                    <p className="mt-[8px] w-[696px] text-[14px] font-normal leading-5 text-[#6B7280]">
                      お知らせしたい内容の詳細をご記載ください。インフルエンサーがお知らせされた内容の詳細を知りたい際に参考にする部分となります。※記載例：キャンペーン情報の場合はキャンペーン詳細、お知らせする内容のメルマガやプレスリリースをご用意している場合はそちらをご記載ください。
                    </p>
                    <div className="my-[24px]">
                      <TextArea
                        title=""
                        helperText=""
                        placeholder="タイトルを入力してください。"
                        maxLength={100}
                        className="h-[60px] w-[696px] rounded-md border-[1px] border-gray-300 p-[15px] pr-[60px] text-xl font-bold leading-7 placeholder:text-gray-400"
                        rows={1}
                        onChange={(e: { target: { value: any } }) =>
                          setDisplayNotice((info) => ({ ...info, title: e.target.value }))
                        }
                        value={displayNotice.title ? displayNotice.title : ''}
                        count={true}
                      ></TextArea>
                    </div>
                    <UIEditor
                      apiKey="5am37le76ekcdrbkc4g186lax164o6ae7z6lwhmnzhravrls"
                      value={displayNotice.notice ? displayNotice.notice : ''}
                      init={{
                        width: '696px',
                        height: '301px',
                        language: 'ja',
                        toolbar:
                          'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table | imageLibrary',
                        remove_script_host: false,
                        content_style: 'img {max-width: 300px; max-height: 300px;}',
                        plugins: ['link paste'],
                        paste_as_text: true,
                        branding: false,
                        setup: function (editor) {
                          setTargetEditor(editor);
                          editor.ui.registry.addButton('imageLibrary', {
                            text: '画像を挿入',
                            onAction: function () {
                              handleOpenInsertImageDialog();
                            },
                          });
                        },
                      }}
                      onEditorChange={handleEditorChange}
                    />
                    <NoticeContentInsertImageDialog {...useInsertImageDialog} />
                  </div>
                </div>

                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <Button
                    className="inline-flex w-full justify-center rounded-md px-[17px] py-[9px] text-sm font-medium leading-5 text-white shadow-sm sm:ml-3 sm:w-auto"
                    onClick={() => handleEditorSave(displayNotice)}
                  >
                    保存する
                  </Button>
                  <Button
                    variant="white"
                    className="inline-flex w-full justify-center rounded-md bg-white px-[17px] py-[9px] text-sm font-medium leading-5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => {
                      props.onClose(false);
                    }}
                  >
                    キャンセル
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
