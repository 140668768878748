import {
  FeedbackAdopterCommon as FeedbackAdopterJJCommon,
  GetAnalysisJJFeedbackAdoptersOutput,
} from '@/api/analysisJaneJohn';
import { FeedbackAdopterCommon, GetAnalysisOtherFeedbackAdoptersOutput } from '@/api/analysisOther';
import { AnalysisSpiritFeedbackAdopterRow, GetAnalysisSpiritFeedbackAdoptersOutput } from '@/api/analysisSpirit';
import { ProjectType } from '@/api/influencer';

import FeedbackCommon from './feedbackCommon';
export type GetAnalysisFeedbackAdoptersOutput =
  | GetAnalysisSpiritFeedbackAdoptersOutput
  | GetAnalysisOtherFeedbackAdoptersOutput
  | GetAnalysisJJFeedbackAdoptersOutput;

export type AnalysisFeedbackAdopterRow =
  | AnalysisSpiritFeedbackAdopterRow
  | FeedbackAdopterCommon
  | FeedbackAdopterJJCommon;
export interface FeedbackProps {
  projectType: string;
  sns: string;
  isCompleteFeedback: boolean;
  setIsCompleteFeedback: React.Dispatch<React.SetStateAction<boolean>>;
  getAnalysisFeedbackAdopters: (rows?: 10 | 30 | 50 | 100, page?: number) => Promise<GetAnalysisFeedbackAdoptersOutput>;
  projectId: string;
}
export default function Feedback(props: FeedbackProps) {
  return props.projectType === ProjectType.Spirit ||
    props.projectType === ProjectType.JaneJohn ||
    props.projectType === ProjectType.Other ? (
    <FeedbackCommon
      projectType={props.projectType}
      sns={props.sns}
      isCompleteFeedback={props.isCompleteFeedback}
      setIsCompleteFeedback={props.setIsCompleteFeedback}
      getAnalysisFeedbackAdopters={props.getAnalysisFeedbackAdopters}
      projectId={props.projectId}
    />
  ) : (
    <></>
  );
}
