import * as Sentry from '@sentry/react';

import {
  GetAnalysisJJFeedbackAdoptersOutput,
  GetAnalysisJJFeedbackAdoptersRowsEnum,
  DefaultApi,
} from '@/api/analysisJaneJohn/api';
import { axiosInstance } from '@/utils/axiosInstance';

const api = new DefaultApi(undefined, undefined, axiosInstance);

/**
 * 案件分析 - フィードバック採用者一覧取得API
 */
export const getAnalysisJaneJohnFeedbackAdopters = async (
  projectId: string,
  rows?: GetAnalysisJJFeedbackAdoptersRowsEnum,
  page?: number
): Promise<GetAnalysisJJFeedbackAdoptersOutput> => {
  try {
    const response = await api.getAnalysisJJFeedbackAdopters(projectId, rows, page);
    const responseData: GetAnalysisJJFeedbackAdoptersOutput = response.data;
    return responseData;
  } catch (error: any) {
    Sentry.captureException(error);
    throw new Error(`HTTP error! status: ${error.response.status}, text: ${error.response.data.messages?.join(', ')}`);
  }
};
