import { Transition } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import { ChangeEvent, FormEvent, useCallback, useEffect, useState } from 'react';

import { GetAccountOutput } from '@/api/member';
import { Button } from '@/components/Elements';
import { Input } from '@/components/Elements/Input';
import { ImageModal } from '@/components/ImageModal/ImageModal';
// import Alert from '@/features/PRST_project/components/Alert';
// eslint-disable-next-line no-restricted-imports
import { MemberRole } from '@/features/member_admin/components/MemberListAdmin';
import { fetchAccountInfo } from '@/hooks/AccountInfo';
import { fetchTeamInfo, updateTeamInfo, uploadTeamImage } from '@/hooks/TeamInfo';
import { getImageUrl } from '@/utils/image';
import {
  isValidEmail,
  isValidPhoneNumber,
  isValidUrl,
  validationInputVoidCheck,
  validationMaxCheck,
} from '@/utils/inputMultiValidationCheck';
import { MessageNewPlanString, ValidationMessages } from '@/utils/message';

import Snackbar from '../../Components/Snackbar';

import phoneImage from './assets/bx-phone.svg';
import mailImage from './assets/Vector2.svg';

import './BasicInfoView.css';

export type BasicInfo = {
  teamId: string;
  teamName: string;
  thumbnail: string;
  memberCount: number;
  companyName: string;
  companyUrl: string;
  companyPhoneNumber: string;
  userName: string;
  division: string;
  post: string;
  phoneNumber: string;
  mailAddress: string;
};

interface Errors {
  teamName: string;
  companyName: string;
  companyUrl: string;
  companyPhoneNumber: string;
  userName: string;
  phoneNumber: string;
  mailAddress: string;
}

function BasicInfoView() {
  const [open, setOpen] = useState<boolean>(false);
  const [basicInfo, setBasicInfo] = useState<BasicInfo>({
    teamId: '',
    teamName: '',
    thumbnail: '',
    memberCount: 0,
    companyName: '',
    companyUrl: '',
    companyPhoneNumber: '',
    userName: '',
    division: '',
    post: '',
    phoneNumber: '',
    mailAddress: '',
  });

  const [errors, setErrors] = useState<Errors>({
    teamName: '',
    companyName: '',
    companyUrl: '',
    companyPhoneNumber: '',
    userName: '',
    phoneNumber: '',
    mailAddress: '',
  });

  const [accountInfo, setAccountInfo] = useState<GetAccountOutput>();

  useEffect(() => {
    const getBasicInfo = async () => {
      try {
        const fetchAccount = await fetchAccountInfo();
        console.log('アカウント情報', fetchAccount);
        setAccountInfo(fetchAccount);
        const data = await fetchTeamInfo(fetchAccount.teamId || '');
        console.log('チーム情報', data);
        setBasicInfo({
          ...basicInfo,
          teamId: data.teamId,
          teamName: data.teamName,
          thumbnail: data.thumbnail || '',
          memberCount: data.memberCount,
          companyName: data.companyName,
          companyUrl: data.companyUrl,
          companyPhoneNumber: data.companyPhoneNumber,
          userName: data.userName,
          division: data.division,
          post: data.post,
          phoneNumber: data.phoneNumber,
          mailAddress: data.mailAddress,
        });
      } catch (error) {
        Sentry.captureException(error);
        console.error(error);
      }
    };
    getBasicInfo();
  }, []);

  const [file, setFile] = useState<File>();

  const onClose = async (isSave: boolean) => {
    if (isSave && file) {
      uploadTeamImage(file);
    }
    setOpen(false);
  };

  const getStrMaxLenMessage = useCallback((label: string, target: string) => validationMaxCheck(target, 50, label), []);

  const getsValidPhoneNumberMessage = useCallback((label: string, target: string) => {
    if (isValidPhoneNumber(target)) return '';
    return ValidationMessages.phoneNumberCheckMessage(label);
  }, []);

  // チーム名のバリデーションチェック
  const checkTeamName = (teamName: string) => {
    const label = 'チーム名';
    if (!validationInputVoidCheck(teamName)) {
      setErrors({ ...errors, teamName: ValidationMessages.inputMessage(label) });
    } else {
      setErrors({ ...errors, teamName: getStrMaxLenMessage(label, teamName) });
    }
    setBasicInfo({ ...basicInfo, teamName });
  };

  // 企業名のバリデーションチェック
  const checkCompanyName = (companyName: string) => {
    const label = '企業名';
    if (!validationInputVoidCheck(companyName)) {
      setErrors({ ...errors, companyName: ValidationMessages.inputMessage(label) });
    } else {
      setErrors({ ...errors, companyName: getStrMaxLenMessage(label, companyName) });
    }
    setBasicInfo({ ...basicInfo, companyName });
  };

  // 企業Webサイトのバリデーションチェック
  const checkCompanyUrl = (companyUrl: string) => {
    if (!validationInputVoidCheck(companyUrl)) {
      setErrors({ ...errors, companyUrl: ValidationMessages.inputMessage('企業Webサイト') });
    } else if (!isValidUrl(companyUrl)) {
      setErrors({ ...errors, companyUrl: ValidationMessages.invalidUrlMessage('企業Webサイト') });
    } else {
      setErrors({ ...errors, companyUrl: '' });
    }
    setBasicInfo({ ...basicInfo, companyUrl });
  };

  // 企業電話番号のバリデーションチェック
  const checkCompanyPhoneNumber = (companyPhoneNumber: string) => {
    const label = '企業電話番号';
    if (!validationInputVoidCheck(companyPhoneNumber)) {
      setErrors({ ...errors, companyPhoneNumber: ValidationMessages.inputMessage(label) });
    } else {
      setErrors({ ...errors, companyPhoneNumber: getsValidPhoneNumberMessage(label, companyPhoneNumber) });
    }
    setBasicInfo({ ...basicInfo, companyPhoneNumber });
  };

  // 代表者の方のお名前のバリデーションチェック
  const checkUserName = (userName: string) => {
    const label = '代表者の方のお名前';
    if (!validationInputVoidCheck(userName)) {
      setErrors({ ...errors, userName: ValidationMessages.inputMessage(label) });
    } else {
      setErrors({ ...errors, userName: getStrMaxLenMessage(label, userName) });
    }
    setBasicInfo({ ...basicInfo, userName });
  };

  // 電話番号のバリデーションチェック
  const checkPhoneNumber = (phoneNumber: string) => {
    const label = '代表者の方の電話番号';
    if (!validationInputVoidCheck(phoneNumber)) {
      setErrors({ ...errors, phoneNumber: ValidationMessages.inputMessage(label) });
    } else {
      setErrors({ ...errors, phoneNumber: getsValidPhoneNumberMessage(label, phoneNumber) });
    }
    setBasicInfo({ ...basicInfo, phoneNumber });
  };

  // メールアドレスのバリデーションチェック
  const checkMailAddress = (mailAddress: string) => {
    if (!validationInputVoidCheck(mailAddress)) {
      setErrors({ ...errors, mailAddress: ValidationMessages.inputMessage('代表者の方のメールアドレス') });
    } else if (!isValidEmail(mailAddress)) {
      setErrors({ ...errors, mailAddress: MessageNewPlanString.mailmessage });
    } else {
      setErrors({ ...errors, mailAddress: '' });
    }
    setBasicInfo({ ...basicInfo, mailAddress });
  };

  const [snackbar, setSnackbar] = useState<boolean>(false);

  const showSnackbar = () => {
    setSnackbar(true);
    setTimeout(() => {
      setSnackbar(false);
    }, 3000);
  };

  const hadleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (Object.values(errors).some((error) => error !== '')) return;

      await updateTeamInfo({
        teamName: basicInfo.teamName,
        companyName: basicInfo.companyName,
        companyUrl: basicInfo.companyUrl,
        companyPhoneNumber: basicInfo.companyPhoneNumber,
        userName: basicInfo.userName,
        division: basicInfo.division,
        post: basicInfo.post,
        phoneNumber: basicInfo.phoneNumber,
        mailAddress: basicInfo.mailAddress,
        thumbnail: basicInfo.thumbnail,
      });

      showSnackbar();
      scrollTop();
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  };

  // 変更を保存をクリックした際に画面トップにスクロールする;
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // console.log('accountInfo::', accountInfo);

  return (
    <div className="BasicInfoView">
      <Transition
        show={snackbar}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 z-50 w-full"
      >
        <Snackbar text="変更を保存しました。" />
      </Transition>
      <div className="Title">
        <p className="TitleText">チーム - 基本情報</p>
      </div>
      <form className="Setting" onSubmit={(e) => hadleSubmit(e)}>
        <div className="SettingTitle">
          <p className="SettingTitleText">チーム情報</p>
        </div>
        <div className="teamName">
          <p className="teamNameText">チーム名</p>
          <div className="w-[320px]">
            {accountInfo?.role === MemberRole.administrator ? (
              <>
                <Input
                  value={basicInfo.teamName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkTeamName(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-3 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.teamName !== ''}
                />
                <p className="inputError">{errors.teamName}</p>
              </>
            ) : (
              <p className="teamNameText">{basicInfo.teamName}</p>
            )}
          </div>
        </div>
        <div className="teamImage">
          <p className="teamImageText">チーム画像</p>
          <div className="teamImageData h-14 w-14 overflow-hidden">
            {file || basicInfo.thumbnail ? (
              <img
                src={file ? URL.createObjectURL(file) : getImageUrl(basicInfo.thumbnail)}
                alt=""
                className="h-14 w-14 rounded-[5px]"
              />
            ) : (
              <p>ABC</p>
            )}
          </div>
          {accountInfo?.role === MemberRole.administrator && (
            <Button
              variant="white"
              className="teamImageButton"
              onClick={() => {
                setOpen(true);
              }}
            >
              変更
            </Button>
          )}
        </div>
        <div className="companyName">
          <p className="companyNameText">企業名</p>
          <div className="w-[320px]">
            {accountInfo?.role === MemberRole.administrator ? (
              <>
                <Input
                  value={basicInfo.companyName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkCompanyName(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-3 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.companyName !== ''}
                />
                <p className="inputError">{errors.companyName}</p>
              </>
            ) : (
              <p className="companyNameText">{basicInfo.companyName}</p>
            )}
          </div>
        </div>
        <div className="companyWebSite">
          <p className="companyWebSiteText">企業Webサイト</p>
          <div className="w-[320px]">
            {accountInfo?.role === MemberRole.administrator ? (
              <>
                <Input
                  value={basicInfo.companyUrl}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkCompanyUrl(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-3 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.companyUrl !== ''}
                />
                <p className="inputError">{errors.companyUrl}</p>
              </>
            ) : (
              <p className="companyWebSiteText">{basicInfo.companyUrl}</p>
            )}
          </div>
        </div>
        <div className="companyTel">
          <p className="companyTelText">企業電話番号</p>
          {accountInfo?.role === MemberRole.administrator ? (
            <>
              <img src={phoneImage} alt="" className="absolute z-10 ml-[282px] mt-[31px]" />
              <div className="w-[320px]">
                <Input
                  type="tel"
                  value={basicInfo.companyPhoneNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkCompanyPhoneNumber(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-10 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.companyPhoneNumber !== ''}
                />
                <p className="inputError">{errors.companyPhoneNumber}</p>
              </div>
            </>
          ) : (
            <p className="companyTelText">{basicInfo.companyPhoneNumber}</p>
          )}
        </div>
        <div className="SettingTitle">
          <p className="mb-5 ml-0 mt-10 h-6 w-[1027px] font-['Inter'] text-[18px] font-medium not-italic leading-6 text-[#111827]">
            チームの代表者情報
          </p>
        </div>
        <div className="companyName">
          <p className="companyTelText">代表者の方のお名前</p>
          <div className="w-[320px]">
            {accountInfo?.role === MemberRole.administrator ? (
              <>
                <Input
                  value={basicInfo.userName}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkUserName(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-3 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.userName !== ''}
                />
                <p className="inputError">{errors.userName}</p>
              </>
            ) : (
              <p className="companyTelText">{basicInfo.userName}</p>
            )}
          </div>
        </div>
        <div className="companyName">
          <p className="companyTelText">代表者の方の電話番号</p>
          {accountInfo?.role === MemberRole.administrator ? (
            <>
              <img src={phoneImage} alt="" className="absolute z-10 ml-[282px] mt-[31px]" />
              <div className="w-[320px]">
                <Input
                  type="tel"
                  value={basicInfo.phoneNumber}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkPhoneNumber(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-10 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.phoneNumber !== ''}
                />
                <p className="inputError">{errors.phoneNumber}</p>
              </div>
            </>
          ) : (
            <p className="companyTelText">{basicInfo.phoneNumber}</p>
          )}
        </div>
        <div className="companyName">
          <p className="companyTelText">代表者の方のメールアドレス</p>
          {accountInfo?.role === MemberRole.administrator ? (
            <>
              <img src={mailImage} alt="" className="absolute z-10 ml-[285px] mt-[33px]" />
              <div className="w-[320px]">
                <Input
                  type="email"
                  value={basicInfo.mailAddress}
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    checkMailAddress(e.target.value);
                  }}
                  className="textInput mt-[20px] w-[320px] indent-10 font-['Inter'] text-[14px] font-normal not-italic leading-5 text-gray-900"
                  isError={errors.mailAddress !== ''}
                />
                <p className="inputError">{errors.mailAddress}</p>
              </div>
            </>
          ) : (
            <p className="companyTelText">{basicInfo.mailAddress}</p>
          )}
        </div>
        <div>
          {accountInfo?.role === MemberRole.administrator && (
            <Button
              type="submit"
              className="changeButton ml-6 mt-6 h-[40px] w-[106px] px-[17px] py-[9px] text-sm font-medium"
            >
              変更を保存
            </Button>
          )}
        </div>
      </form>
      <ImageModal open={open} file={file} setFile={setFile} onClose={onClose} />
    </div>
  );
}

export default BasicInfoView;
