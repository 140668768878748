import CheckImage from './assets/Check.svg';
import SeperatorImage from './assets/Seperator.svg';

export default function Step(status: number) {
  const getStep = (step: number, stepName: string, status: number) => {
    if (step < status) {
      return (
        <>
          <div className="ml-[23px] flex h-[40px] w-[40px] items-center justify-center rounded-full bg-[#006199]">
            <img src={CheckImage} alt="" className="h-[16px] w-[16px]" />
          </div>
          <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4">{stepName}</p>
        </>
      );
    } else if (step > status) {
      return (
        <>
          <div className="ml-[23px] flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-gray-300">
            <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4 text-gray-500">0{step}</p>
          </div>
          <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-gray-500">{stepName}</p>
        </>
      );
    } else if (step === status) {
      return (
        <>
          <div className="ml-[23px] flex h-[40px] w-[40px] items-center justify-center rounded-full border-[2px] border-[#006199]">
            <p className="h-[16px] w-[18px] text-center text-sm font-medium leading-4">0{step}</p>
          </div>
          <p className="ml-[16px] whitespace-nowrap text-sm font-medium leading-4 text-[#006199]">{stepName}</p>
        </>
      );
    } else {
      return null;
    }
  };

  return (
    <div className="mb-[24px] flex h-[72px] w-[1133px] items-center rounded border-[1px] border-gray-300 bg-white px-[12px]">
      {/* ✔︎イメージ案制作 */}
      {getStep(1, 'イメージ案制作', status)}
      <img src={SeperatorImage} alt="" className="ml-[20px] h-[72px]" />
      {/* 02イメージ案承認 */}
      {getStep(2, 'イメージ案承認', status)}
      <img src={SeperatorImage} alt="" className="ml-[20px] h-[72px]" />
      {/* 03コンテンツ制作 */}
      {getStep(3, 'コンテンツ制作', status)}
      <img src={SeperatorImage} alt="" className="ml-[20px] h-[72px]" />
      {/* 04コンテンツ承認 */}
      {getStep(4, 'コンテンツ承認', status)}
      <img src={SeperatorImage} alt="" className="ml-[20px] h-[72px]" />
      {/* 05完了 */}
      {getStep(5, '完了', status)}
    </div>
  );
}
