//import clsx from 'clsx';
import React from 'react';

import { Input } from '@/components/Elements/Input/Input';
import { isMorehanValue, validationHalfWidthCheck, validationIntegerCheck } from '@/utils/inputMultiValidationCheck';
import { MessageNewPlanString } from '@/utils/message';

import measures from '../../assets/measures.png';
import { MEASURE_CONTENT } from '../../constants/path';
import { FormProps } from '../../interfaces/project';
import { decideMeasureContent } from '../../utils/logic';
import { Recomend } from '../Recomend';

export default function Measure(props: FormProps): React.ReactElement {
  const measure_detail = decideMeasureContent(props.payload);
  let lowest_times = 1;
  let standard_times = 2;
  let recommend_times = 3;
  if (measure_detail === MEASURE_CONTENT.HEART5) {
    lowest_times = 1;
    standard_times = 3;
    recommend_times = 12;
  } else if (measure_detail === MEASURE_CONTENT.TIE_UP) {
    lowest_times = 1;
    standard_times = 6;
    recommend_times = 12;
  } else if (measure_detail === MEASURE_CONTENT.GROUP_INTERVIEW || measure_detail === MEASURE_CONTENT.SPOT_CONSULTING) {
    lowest_times = 1;
    standard_times = 3;
    recommend_times = 5;
  }

  const validationCheck = (value: string) => {
    let isError = false;
    let errorMessage = '';
    let setValue: number | undefined = undefined;
    if (!validationHalfWidthCheck(value) || !validationIntegerCheck(Number(value))) {
      isError = true;
      errorMessage = MessageNewPlanString.integralMessage;
    } else if (!isMorehanValue(+value, lowest_times)) {
      isError = true;
      errorMessage = MessageNewPlanString.lowestMeasureMessage;
      setValue = +value;
    } else {
      setValue = value == '' ? undefined : +value;
    }

    props.setPayload((state) => {
      return {
        ...state,
        isPeriodError: isError,
        periodErrorMessage: errorMessage,
        period: setValue,
      };
    });
  };

  return (
    <div className="mx-auto flex w-[800px] flex-col items-center gap-16">
      <div className="pt-12">
        {Recomend({
          src: measures,
          title: '推奨実施回数',
          value: recommend_times.toString(),
          unit: '回',
          description: '推奨実施回数は他社を含めた過去の案件実施の実績に基づいて算出されています。',
        })}
      </div>

      <div className="mx-auto flex w-[800px] flex-col items-center  gap-y-[20px] text-xs font-normal leading-5">
        <span className="text-xs font-normal leading-5 text-gray-400">最低回数:{lowest_times}回</span>
        <span className="text-xs font-normal leading-5 text-gray-400">標準回数:{standard_times}回</span>
        <span className="text-xs font-normal leading-5 text-blue-500">推奨回数:{recommend_times}回</span>
      </div>

      <div className="mx-auto flex w-[800px] items-center justify-center gap-4  text-gray-600">
        <span className="w-auto text-sm font-normal leading-8">予定している施策回数</span>
        <span>
          <span>
            <Input
              type="text"
              className="h-[48px] gap-2 rounded border-gray-300 text-2xl text-gray-500 outline-none focus:border-white"
              onChange={(e: any) => {
                validationCheck(e.target.value);
              }}
              value={props.payload.period == undefined ? '' : props.payload.period.toString()}
              width="63px"
              isError={props.payload.isPeriodError}
            ></Input>
          </span>
        </span>
        <span className="text-sm font-normal leading-8">回</span>
      </div>
      {props.payload.isPeriodError && (
        <span className="m-auto mt-[-53px] flex w-[800px] justify-center pb-0 pl-[135px] text-red-400">
          {props.payload.periodErrorMessage}
        </span>
      )}
    </div>
  );
}
