import clsx from 'clsx';
import * as React from 'react';

export type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  helper?: string;
  classnamelabel?: string;
  classNameLHelper?: string;
  isPlaceHolderRight?: boolean;
  unit?: string;
  placeholder?: string;
  width?: string;
  onChange?: any;
  value?: any;
  initialWord?: string;
  initialWordPosition?: string;
  lastWord?: string;
  isError?: boolean;
};

export const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className = '', isPlaceHolderRight = true, initialWordPosition = '30px', ...props }, ref) => {
    return (
      <>
        {props.label && (
          <label
            className={clsx(
              'block text-sm font-semibold text-gray-700',
              props.classnamelabel ? props.classnamelabel : ''
            )}
          >
            {props.label}
          </label>
        )}

        <span className={clsx('relative', props.width ? 'w-[' + props.width + ']' : 'w-full', 'flex', 'items-center')}>
          {props.initialWord && (
            <span style={{ left: initialWordPosition }} className="absolute z-10 text-sm font-normal text-gray-500">
              {props.initialWord}
            </span>
          )}

          <input
            ref={ref}
            // eslint-disable-next-line tailwindcss/no-custom-classname
            className={clsx(
              'flex h-10 w-full items-center justify-center rounded-md border-[1px] border-gray-300 font-medium placeholder:text-gray-500 hover:opacity-80 focus:outline-none disabled:cursor-not-allowed disabled:opacity-70',
              isPlaceHolderRight ? 'placeholder:text-right' : 'placeholder:text-left',
              props.isError ? 'border-red-400 ring-red-400 focus:ring-red-400 active:ring-red-400' : '',
              className
            )}
            {...props}
          ></input>
          {props.lastWord && (
            <span className="z-10 ml-[-35px] text-sm font-normal text-gray-500">{props.lastWord}</span>
          )}
        </span>

        {props.helper && (
          <div className={props.classNameLHelper ? props.classNameLHelper : ''}>
            <span className="text-sm font-medium leading-5 text-[#9CA3AF]">{props.helper}</span>
          </div>
        )}
      </>
    );
  }
);

Input.displayName = 'Input';
