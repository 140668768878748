import clsx from 'clsx';

import { GetInformationRowsEnum } from '@/api/information';
import { ReactComponent as ChevronLeftIcon } from '@/assets/icons/bx-chevron-left.svg';
import { ReactComponent as ChevronRightIcon } from '@/assets/icons/bx-chevron-right.svg';
import { ReactComponent as ChevronsLeftIcon } from '@/assets/icons/bx-chevrons-left.svg';
import { ReactComponent as ChevronsRightIcon } from '@/assets/icons/bx-chevrons-right.svg';

export interface projectTableInformation {
  totalRows: number;
  totalPages: number;
  perPage: number;
  currentPage: number;
  startPageNumber: number;
  setPerPage: React.Dispatch<React.SetStateAction<GetInformationRowsEnum>>;
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>;
  setStartPageNumber: React.Dispatch<React.SetStateAction<number>>;
}

export default function Pagenation(props: projectTableInformation) {
  const pageNationCount = props.totalPages > 7 ? 7 : props.totalPages;
  const endpageNumber = pageNationCount + props.startPageNumber;

  const pages: number[] = [];
  for (let i = 0; i < pageNationCount; i++) {
    if (props.totalPages < props.startPageNumber + i) break;
    pages.push(props.startPageNumber + i);
  }

  const handlePageFirst = () => {
    if (props.currentPage == 1) return;
    props.setStartPageNumber(1);
    props.setCurrentPage(1);
  };

  const handlePagePrev = () => {
    if (props.currentPage == 1) return;
    if (props.currentPage == props.startPageNumber) {
      props.setStartPageNumber(props.startPageNumber - 1);
    }
    const tmpPageNumber = props.currentPage - 1;

    props.setCurrentPage(tmpPageNumber);
  };

  const handlePageNext = () => {
    if (props.currentPage == props.totalPages) return;
    if (props.currentPage == endpageNumber - 1) {
      props.setStartPageNumber(props.startPageNumber + 1);
    }

    const tmpPageNumber = props.currentPage + 1;
    props.setCurrentPage(tmpPageNumber);
  };

  const handlePageLast = () => {
    if (props.currentPage == props.totalPages) return;
    if (props.totalPages - 7 > 0) {
      props.setStartPageNumber(props.totalPages - 6);
    } else {
      props.setStartPageNumber(1);
    }

    props.setCurrentPage(props.totalPages);
  };

  return (
    <div className="flex items-center justify-between rounded-b-lg border-gray-200 bg-white px-4 py-3 sm:px-6">
      <div className="content-center sm:flex sm:flex-1">
        <div className="pt-[8px] text-xs font-normal leading-4 text-gray-500">１ページあたりの行数:</div>
        <div className="text-xs font-normal leading-4 text-gray-500">
          <select
            id="perPage"
            name="perPage"
            className="border-none text-xs font-normal leading-4 text-gray-500"
            defaultValue={props.perPage}
            onChange={(e) => {
              props.setCurrentPage(1);
              props.setPerPage(Number(e.target.value) as unknown as GetInformationRowsEnum);
            }}
          >
            <option>30</option>
            <option>50</option>
            <option>100</option>
          </select>
          {/* <span className="font-medium">{`${(props.startPageNumber - 1) * props.perPage + 1} - ${
            (props.startPageNumber - 1) * props.perPage + props.perPage
          }  of ${props.totalRows}`}</span> */}
          {/* <span className="text-sm text-gray-500">{` ${(props.currentPage - 1) * props.perPage + 1} - ${
            (props.currentPage - 1) * props.perPage + props.perPage
          } of ${props.totalRows}`}</span> */}
          <span className="text-sm text-gray-500">{` ${(props.currentPage - 1) * props.perPage + 1} - ${
            props.currentPage * props.perPage > props.totalRows ? props.totalRows : props.currentPage * props.perPage
          } of ${props.totalRows}`}</span>
        </div>
      </div>
      {props.totalRows > 30 && (
        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              className={clsx(
                'relative inline-flex items-center rounded-l-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300',
                props.currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
              )}
              onClick={handlePageFirst}
            >
              <ChevronsLeftIcon
                width={20}
                height={20}
                fill={props.currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                aria-hidden="true"
              />
            </button>
            <button
              className={clsx(
                'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                props.currentPage == 1 ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
              )}
              onClick={handlePagePrev}
            >
              <ChevronLeftIcon
                width={20}
                height={20}
                fill={props.currentPage == 1 ? '#D1D5DB' : '#6B7280'}
                aria-hidden="true"
              />
            </button>
            {pages.map((page) => {
              if (page == props.currentPage) {
                return (
                  <button
                    key={page}
                    className="relative z-10 inline-flex items-center border border-[#007CC2] bg-[#E0F4FF] px-4 py-2"
                  >
                    <span className="text-sm font-medium leading-5 text-gray-500">{page}</span>
                  </button>
                );
              } else {
                return (
                  <button
                    key={page}
                    className="relative inline-flex items-center px-4 py-2 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
                    onClick={() => {
                      props.setCurrentPage(page);
                    }}
                  >
                    <nav className="text-sm font-medium leading-5 text-gray-500">{page}</nav>
                  </button>
                );
              }
            })}

            <button
              className={clsx(
                'relative inline-flex items-center p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                props.currentPage == props.totalPages ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
              )}
              onClick={handlePageNext}
            >
              <ChevronRightIcon
                width={20}
                height={20}
                fill={props.currentPage == props.totalPages ? '#D1D5DB' : '#6B7280'}
                aria-hidden="true"
              />
            </button>
            <button
              className={clsx(
                'relative inline-flex items-center rounded-r-md p-2 text-gray-400 ring-1 ring-inset ring-gray-300 ',
                props.currentPage == props.totalPages ? '' : 'hover:bg-gray-50 focus:z-20 focus:outline-offset-0'
              )}
              onClick={handlePageLast}
            >
              <ChevronsRightIcon
                width={20}
                height={20}
                fill={props.currentPage == props.totalPages ? '#D1D5DB' : '#6B7280'}
                aria-hidden="true"
              />
            </button>
          </nav>
        </div>
      )}
    </div>
  );
}
