import { useState, useEffect, useRef } from 'react';

import { GetTeamInvoiceOutput } from '@/api/team';
import { updateMemberRole } from '@/hooks/UpdateMemberRole';

import DropdownImage from './assets/Dropdown.svg';
import { checkPlanConditionChangeRole, checkLiddellMailAddress } from './MemberModal';
export interface HumanProps {
  name: string;
  memberId: string;
  mailAddress: string;
  adminRole: boolean;
  selected: string;
  authority: string;
  avatarImage: string;
  status: string;
}

interface Props {
  updatePartHumanSelectedState: (partState: HumanProps, selected: string) => void;
  index: number;
  humans: HumanProps[];
  planError: boolean[];
  setPlanError: React.Dispatch<React.SetStateAction<boolean[]>>;
  teamBillingInfo: GetTeamInvoiceOutput | undefined;
}

export function Dropdown(props: Props): React.ReactElement {
  const [isActive, setIsActive] = useState(false);
  const insideRef = useRef<HTMLDivElement>(null);
  const options = ['管理者', '一般'];

  useEffect(() => {
    const el = insideRef.current;
    if (!el) return;

    const handleClickOutside = (e: MouseEvent) => {
      if (!el?.contains(e.target as Node)) {
        setIsActive(false);
      } else {
        return;
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [insideRef]);

  const updateMemberRoleProcess = async (option: string) => {
    props.setPlanError(new Array(props.humans.length).fill(false));

    if (
      checkPlanConditionChangeRole(props.teamBillingInfo?.plan, props.humans, option) ||
      checkLiddellMailAddress(props.humans[props.index].mailAddress)
    ) {
      props.updatePartHumanSelectedState(props.humans[props.index], option);
      updateMemberRole(props.humans[props.index].memberId, option === '管理者' ? 'administrator' : 'member');
      setIsActive(false);
    } else {
      props.setPlanError((prev) => {
        const newError = [...prev];
        newError[props.index] = true;
        return newError;
      });
    }
  };

  const buttonRedCss = props.planError[props.index]
    ? 'border-red-400 ring-red-400 focus:ring-red-400 active:ring-red-400'
    : '';
  return (
    <div ref={insideRef} className="relative inline-block text-left">
      <button
        className={`inline-flex h-[38px] w-full items-center justify-center gap-x-1.5 rounded-md bg-white px-[19px] py-[8px] text-sm font-medium leading-5 text-gray-700 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 ${buttonRedCss}`}
        onClick={() => setIsActive(!isActive)}
      >
        {props.humans[props.index].selected == ''
          ? props.humans[props.index].authority
          : props.humans[props.index].selected}
        <img src={DropdownImage} alt="" className="ml-[5.24px] h-[5px] w-[9px]" />
      </button>
      {isActive && (
        <div className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-[0.05] focus:outline-none">
          <div className="py-1">
            {options.map((option) => (
              <button
                className="block w-56 py-3 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                key={option}
                onClick={() => {
                  updateMemberRoleProcess(option);
                }}
              >
                <span className="flex justify-start pl-6">{option}</span>
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
