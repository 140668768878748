import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import '../../../../../Preview/assets/Swiper.css';
import { Video } from '../../../../../Preview/Video';

export type SliderProps = {
  mediaDatas: string[];
  index: number;
  height: number;
  width: number;
};
export const Slider = (props: SliderProps) => {
  const heightCss = `h-[${props.height}px]`;
  const widthCss = `w-[${props.width}px]`;
  const index = props.index;

  console.log(props.mediaDatas);

  const disableClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div>
      <Swiper
        modules={[Pagination]}
        pagination={{ clickable: true, el: `#swiper-pagination${index}`, type: 'bullets' }}
        spaceBetween={50}
        slidesPerView={'auto'}
        direction={'horizontal'}
      >
        <div className={`flex items-center justify-center`}>
          {props.mediaDatas.map((src, index) => {
            const isVideo = src.endsWith('.mp4');
            const content = isVideo ? (
              <Video url={src} className={`${widthCss} ${heightCss}`} />
            ) : (
              <img src={src} alt={`media-${index}`} className={`w-[100%] rounded-lg object-contain`} />
            );
            return <SwiperSlide key={index}>{content}</SwiperSlide>;
          })}
        </div>
      </Swiper>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
      <div className="swiper-pagination" id={`swiper-pagination${index}`} onClick={disableClick} />
      {/* <div className="swiper-pagination" /> */}
    </div>
  );
};
