import { PreviewPost } from '@/components/Preview/twitter';

import { modalArgsEnum, stringToEnum } from '../../../../interfaces/modalInterface';
import { getMediaData } from '../modalCommonParts';
import { MediaProps, SNSContent } from '../modalInterface';

export interface accountInsightProps {
  id: string;
  avator: string;
  insight: JSX.Element;
  selected: string;
  body: string;
  postTime: string;
  posted_method: modalArgsEnum;
  mediaProps?: MediaProps;
}

// Dupricated:共通版の src/features/project_analysis/components/posts/modals/tabs/PostInsightCommon.tsx を使用してください
export default function AccountInsightTwitter(props: accountInsightProps) {
  const href = window.location.href;
  const searchParams = new URLSearchParams(window.location.search);
  const type = searchParams.get('type');
  const mediaData = getMediaData(
    props.mediaProps?.snsType.toString() || '',
    (props.mediaProps?.content as SNSContent) ?? ''
  );
  const mediaType = mediaData.mediaType ?? '';
  const mediaTypeEnum = stringToEnum(mediaType) ?? '';
  return (
    <div className="flex w-[1094px] flex-col items-start gap-10">
      <div className="flex flex-col items-start gap-6 self-stretch">
        <span className="text-base font-semibold leading-6">{props.selected}</span>
        {href.includes('project-analysis') && type === 'emerald_post' ? (
          <div className="flex h-[938px] items-start justify-center gap-10 self-stretch">
            {/* preview */}
            {PreviewPost(props.body, mediaTypeEnum)}

            {/* stat */}
            <div className="hidden w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
          </div>
        ) : (
          <div className="flex h-[938px] items-start gap-10 self-stretch">
            {/* preview */}
            {PreviewPost(props.body, mediaTypeEnum)}

            {/* stat */}
            <div className="flex w-[577px] flex-1 flex-col items-start gap-4">{props.insight}</div>
          </div>
        )}
      </div>
    </div>
  );
}
