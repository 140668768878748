import { Transition } from '@headlessui/react';
import * as Sentry from '@sentry/react';
import axios from 'axios';
import { useEffect, useState, FormEvent } from 'react';

import { GetAccountOutput, PatchAccountInput } from '@/api/member';
import { ReactComponent as CheckIcon } from '@/assets/icons/bx-check-circle.svg';
import helpCircle from '@/assets/icons/bx-help-circle.png';
import config from '@/auth0-config';
import { Button } from '@/components/Elements';
import { Input } from '@/components/Elements/Input/Input';
import { Tooltip } from '@/components/Elements/Tooltip/Tooltip';
import { ImageModal } from '@/components/ImageModal/ImageModal';
import { fetchAccountInfo, updateAccountInfo, uploadThumbnail } from '@/hooks/AccountInfo';
import { AuthAPI } from '@/lib/auth';
import { getImageUrl } from '@/utils/image';
import {
  validationInputVoidCheck,
  isValidPhoneNumber,
  isMorehanValue,
  validationHalfWidthCheck,
  isUseNumber,
  hasHyphenCahracter,
  isValidEmail,
} from '@/utils/inputMultiValidationCheck';
import { ValidationMessages } from '@/utils/message';

import Snackbar from '../../Components/Snackbar';

import accountImage from './assets/Avatar.svg';

import './AccountSetting.css';

const auth: AuthAPI = new AuthAPI('auth0Container');

function AccountSettingView() {
  const [open, setOpen] = useState<boolean>(false);
  const [thumbnailUrl, setThumbnailUrl] = useState<string>();
  const [file, setFile] = useState<File>();
  const [retry, setRetry] = useState<boolean>(false);
  const [isMailAuthAccount, setIsMailAuthAccount] = useState<boolean>(false);
  const [accountName, setAccountName] = useState<string>('');
  const [isErrorAccountName, setIsErrorAccountName] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [isErrorPhone, setIsErrorPhone] = useState<boolean>(false);
  const [company, setCompany] = useState<string>('');
  const [isErrorCompany, setIsErrorCompany] = useState<boolean>(false);
  const [alert, setAlert] = useState<boolean>(false);

  const [snackbar, setSnackbar] = useState<boolean>(false);

  const showSnackbar = () => {
    setSnackbar(true);
    setTimeout(() => {
      setSnackbar(false);
    }, 3000);
  };

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isTmpErrorAccountName = !validationInputVoidCheck(accountName) || isMorehanValue(accountName.length, 51);
    const isTmpErrorEmail = !validationInputVoidCheck(email) || !isValidEmail(email);
    const isTmpErrorPhone =
      !validationInputVoidCheck(phone) ||
      hasHyphenCahracter(phone) ||
      !isUseNumber(phone) ||
      !validationHalfWidthCheck(phone) ||
      !isValidPhoneNumber(phone);
    const isTmpErrorCompany = !validationInputVoidCheck(company) || isMorehanValue(company.length, 51);

    setIsErrorAccountName(isTmpErrorAccountName);
    setIsErrorPhone(isTmpErrorPhone);
    setIsErrorCompany(isTmpErrorCompany);
    if (isTmpErrorAccountName || isTmpErrorEmail || isTmpErrorPhone || isTmpErrorCompany) {
      // エラーあり 処理中断
      return;
    }
    updateAccountInfo({
      name: accountName,
      email: email,
      companyName: company,
      phoneNumber: phone,
    } as PatchAccountInput);

    showSnackbar();

    scrollTop();
    return;
  };

  // アカウント名のエラーメッセージ表示
  const accountNameErrorMessage = (): string => {
    const label = 'アカウント名';
    return validationInputVoidCheck(company)
      ? ValidationMessages.strMaxLenMessage(label, 50)
      : ValidationMessages.inputMessage(label);
  };

  // 電話番号のエラーメッセージ表示
  const phoneErrorMessage = (): string => {
    const label = '電話番号';
    if (validationInputVoidCheck(phone)) {
      return ValidationMessages.inputMessage(label);
    } else if (hasHyphenCahracter(phone)) {
      return ValidationMessages.foramtHypenMessage(label);
    } else if (!isUseNumber(phone)) {
      return ValidationMessages.foramtNumberOnlyMessage(label);
    } else if (!validationHalfWidthCheck(phone)) {
      return ValidationMessages.foramtHalfMessage(label);
    } else if (!isValidPhoneNumber(phone)) {
      return ValidationMessages.foramtPhoneMessage(label);
    }
    return '';
  };

  // 会社名のエラーメッセージ表示
  const companyErrorMessage = (): string => {
    const label = '会社名';
    return validationInputVoidCheck(company)
      ? ValidationMessages.strMaxLenMessage(label, 50)
      : ValidationMessages.inputMessage(label);
  };

  const onClose = (isSave: boolean) => {
    if (isSave) {
      if (file) {
        uploadThumbnail(file);
      }
    }
    setOpen(false);
  };
  // 認証情報を取得する。取得に若干の時間を要するため、画面読み込み後に画面表示内容が変動する可能性あり
  useEffect(() => {
    (async () => {
      let result;
      try {
        result = await auth.isAuthenticated();
      } catch (ex: any) {
        Sentry.captureException(ex);
        console.log(ex);
        if (ex.code === 'unauthorized') {
          console.log(ex);
          return;
        } else if (ex.code === 'invalid_token') {
          setRetry(!retry);
          return;
        }
      }

      setIsMailAuthAccount(!result?.idTokenPayload.sub.includes('google-oauth2|'));
      // setEmail(result?.idTokenPayload.email);
      fetchAccountInfo().then((data: GetAccountOutput) => {
        data.name && setAccountName(data.name);
        data.email && setEmail(data.email);
        data.phoneNumber && setPhone(data.phoneNumber);
        data.companyName && setCompany(data.companyName);
        // 例 "thumbnail": "assets/cl/thumbnail/12c85f2b050f82be.png"
        data.thumbnail && setThumbnailUrl(getImageUrl(data.thumbnail));
      });
    })();
  }, [retry]);

  const resetPassword = async () => {
    await axios.post(
      `https://${config.domain}/dbconnections/change_password`,
      {
        client_id: config.clientId,
        email: email,
        connection: 'Username-Password-Authentication',
      },
      { headers: { 'Content-Type': 'application/json' } }
    );
    setAlert(true);
  };

  const toast = () => {
    return (
      <div className="absolute w-full">
        <div className="flex h-[52px] w-[896px] flex-row items-center rounded-md bg-green-50 p-0">
          <CheckIcon fill="#34D399" className="ml-[20px]"></CheckIcon>
          <span className="ml-[13px] text-sm font-medium leading-5 text-green-800">メールを送信しました。</span>
        </div>
      </div>
    );
  };

  // 変更を保存をクリックした際に画面トップにスクロールする;
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className="mx-auto w-[1181px] bg-gray-100">
      <Transition
        show={snackbar}
        enter="transition-opacity ease-in-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        className="fixed top-0 z-50 w-full"
      >
        <Snackbar text="変更を保存しました。" />
      </Transition>
      <div className="border-b-[1px] border-gray-200 ">
        <p className="my-[24px] ml-[16px] h-[28px] w-[112px] text-base font-semibold leading-7 text-gray-700">
          アカウント設定
        </p>
      </div>
      {alert && <div>{toast()}</div>}
      <form onSubmit={onSubmit}>
        <div className="my-[24px] ml-[24px]">
          <p className="text-sm font-semibold leading-5">画像</p>
          <div className="mt-[4px] flex w-[64px] items-center">
            <img
              src={file ? URL.createObjectURL(file) : thumbnailUrl ? thumbnailUrl : accountImage}
              alt=""
              className="h-16 w-16"
            />
            <button
              className="ml-[20px] h-[34px] w-[152px] whitespace-nowrap rounded-md border-[1px] border-gray-300 px-[13px] py-[9px] text-sm font-medium leading-4 text-gray-700"
              type="button"
              onClick={() => {
                setOpen(true);
              }}
            >
              変更
            </button>
          </div>
        </div>
        <div className="ml-[24px] mt-[24px]">
          <Input
            type="text"
            inputMode="tel"
            className="ph-left mt-[4px] w-[463px] rounded-md py-[9px] pl-[13px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-left"
            width="463px"
            value={accountName}
            label="アカウント名"
            onChange={(e: any) => {
              const value = e.target.value;
              setIsErrorAccountName(!validationInputVoidCheck(value) || isMorehanValue(value.length, 51));
              setAccountName(value);
            }}
            isError={isErrorAccountName}
          ></Input>
          {isErrorAccountName && (
            <span className="my-[5px] text-sm font-semibold leading-5 text-red-400">{accountNameErrorMessage()}</span>
          )}
        </div>
        <div className="ml-[24px] mt-[24px]">
          <div className="w-[118px]">
            <div className="flex items-center">
              <p className="text-sm font-semibold leading-5 text-gray-700">メールアドレス</p>
              <div className="group relative ml-[4px] ">
                <img src={helpCircle} alt="" className="w-[16px]" />
                <Tooltip
                  isSpeechBubble={true}
                  variant="gray"
                  toolTipClassName="px-3 py-2"
                  inner={
                    <div>
                      <span className="text-sm font-normal">
                        メールアドレスを変更したい場合はLIDDELLまでお問合せください。 お問合せは
                      </span>
                      <a
                        href={process.env.REACT_APP_ZENDESK_URL}
                        className="text-sm font-normal leading-5 text-[#007CC2]"
                        target="_blank"
                        rel="noreferrer"
                      >
                        こちら
                      </a>
                    </div>
                  }
                  className="absolute left-[-44px] top-[-72px] hidden w-[400px] text-xs group-hover:inline-block"
                ></Tooltip>
              </div>
            </div>
          </div>
          <p className="mt-[4px] text-[14px] font-normal leading-5 text-gray-900">{email}</p>
        </div>
        <div className="ml-[24px] mt-[24px]">
          <Input
            type="text"
            inputMode="tel"
            className="ph-left mt-[4px] w-[463px] rounded-md py-[9px] pl-[13px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-left "
            width="463px"
            value={phone}
            label="電話番号"
            onChange={(e: any) => {
              const value = e.target.value;
              setIsErrorPhone(
                !validationInputVoidCheck(value) ||
                  hasHyphenCahracter(value) ||
                  !isUseNumber(value) ||
                  !validationHalfWidthCheck(value) ||
                  !isValidPhoneNumber(value)
              );
              setPhone(value);
            }}
            isError={isErrorPhone}
          ></Input>
          {isErrorPhone && (
            <span className="my-[5px] text-sm font-semibold leading-5 text-red-400">{phoneErrorMessage()}</span>
          )}
        </div>
        <div className="ml-[24px] mt-[24px]">
          <Input
            type="text"
            className="ph-left mt-[4px] w-[463px] rounded-md py-[9px] pl-[13px] text-[14px] font-normal leading-5 text-gray-900 placeholder:text-left "
            width="463px"
            value={company}
            label="会社名"
            onChange={(e: any) => {
              const value = e.target.value;
              setIsErrorCompany(!validationInputVoidCheck(value) || isMorehanValue(value.length, 51));
              setCompany(value);
            }}
            isError={isErrorCompany}
          ></Input>
          {isErrorCompany && (
            <p className="my-[5px] text-sm font-semibold leading-5 text-red-400">{companyErrorMessage()}</p>
          )}
        </div>
        {isMailAuthAccount && (
          <div className="ml-[24px] mt-[24px]">
            <p className="text-sm font-semibold leading-5">パスワード</p>
            <a href="#" onClick={resetPassword} className="text-sm font-normal leading-5 text-[#007CC2]">
              パスワードの変更
            </a>
          </div>
        )}
        <div className="ml-[24px] mt-[24px]">
          <Button
            className="h-[40px] w-[106px] whitespace-nowrap px-[17px] py-[9px] text-sm font-medium leading-5"
            style={{ backgroundColor: '#007CC2' }}
            type="submit"
          >
            変更を保存
          </Button>
        </div>
      </form>
      <ImageModal open={open} file={file} setFile={setFile} onClose={onClose} imageSize={30} />
    </div>
  );
}

export default AccountSettingView;
