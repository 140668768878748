import React from 'react';

import { ReactComponent as ErrorIcon } from '@/assets/icons/bx-error.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/bx-x.svg';
import { Button } from '@/components/Elements/Button';

interface modalProps {
  handleClose: () => void;
  handleDelete: () => void;
}

export default function Delete(props: modalProps): React.ReactElement {
  return (
    <div className="flex h-screen w-screen items-center justify-center">
      <div className="h-[148px] w-[536px] rounded-lg bg-white">
        <div className="flex-none pl-6">
          <div className="flex flex-row items-center">
            <div className="flex h-10 w-10 items-center justify-center rounded-3xl bg-[#FEE2E2]">
              <ErrorIcon width={24} height={24} fill="#DC2626" />
            </div>
            <div className="pl-4">
              <div className="flex w-[408px] pt-4 text-center">
                <span className="text-lg font-medium leading-6">案件提案結果を削除しててよろしいですか？</span>
                <div className="ml-auto">
                  <CloseIcon width={24} height={24} fill="#9CA3AF" onClick={props.handleClose} />
                </div>
              </div>
              <div className="pt-2">
                <span className="text-sm font-normal leading-5 text-[#6B7280]">案件内容のご提案結果を削除します。</span>
              </div>
            </div>
          </div>
        </div>
        <div className="pl-6 pt-2.5">
          <div className="flex w-[464px] flex-row items-center justify-end gap-3 p-0">
            <Button variant="white" onClick={props.handleClose}>
              キャンセル
            </Button>
            <Button variant="red" onClick={props.handleDelete}>
              結果を削除する
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
