import clsx from 'clsx';
import * as React from 'react';

import { ReactComponent as RemoveIcon } from '@/assets/icons/remove.svg';

const colors = {
  gray: 'bg-gray-100 text-gray-800',
  red: 'bg-red-100 text-red-800',
  yellow: 'bg-yellow-100 text-yellow-800',
  green: 'bg-green-100 text-green-800',
  blue: 'bg-blue-100 text-blue-800',
  indigo: 'bg-indigo-100 text-indigo-800',
  purple: 'bg-purple-100 text-purple-800',
  pink: 'bg-pink-100 text-pink-800',
  primary: 'bg-[#C2E9FF] text-[#00304D]',
  white: 'bg-white',
};

const rounded = {
  custom: 'rounded-[10px]',
  sm: 'rounded-sm',
  xl: 'rounded-xl',
  normal: 'rounded',
};

const textsize = {
  xs: 'text-xs leading-4 font-medium',
  sm: 'text-sm leading-5 font-medium',
};

export type BadgeProps = React.HTMLAttributes<HTMLDivElement> & {
  color?: keyof typeof colors;
  round?: keyof typeof rounded;
  size?: keyof typeof textsize;
  isRemovable?: boolean;
  // handleRemove?: (target: string) => void;
  handleRemove?: () => void;
};

export const Badge = React.forwardRef<HTMLDivElement, BadgeProps>(
  ({ className = '', color = 'primary', round = 'sm', size = 'xs', isRemovable = false, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={clsx(
          'flex flex-row items-center justify-center gap-1 py-[2px]',
          colors[color],
          rounded[round],
          className
        )}
        {...props}
      >
        <span className={clsx(textsize[size])}>{props.children}</span>
        {isRemovable && (
          <div className="flex flex-row items-start gap-2 p-1">
            <RemoveIcon onClick={props.handleRemove}></RemoveIcon>
          </div>
        )}
      </div>
    );
  }
);

Badge.displayName = 'Badge';
