import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '@/assets/icons/bx-plus.svg';
import { Button } from '@/components/Elements';
import { MainLayout } from '@/components/Layout/MainLayout';
import CompleteModal from '@/components/ProjectManagements/Modals/CompleteMadal';
import { firstSidebarSelect } from '@/config/sidebar';

export function Home(): React.ReactElement {
  const location = useLocation();
  const navigate = useNavigate();
  const shouldOpenModal = location.state?.shouldOpenModal;
  const modalMessage = location.state?.modalMessage || 'チームを作成しました';
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    if (shouldOpenModal) {
      setOpenModal(true);
      navigate('.', { state: { shouldOpenModal: false, modalMessage: modalMessage } });
    }
  }, [navigate, shouldOpenModal, modalMessage]);

  const handleNewProject = () => {
    navigate('/project');
  };

  // TODO 後ほど削除
  const linksList = [
    { label: 'チーム作成', path: '/make_team' },
    { label: '通知一覧', path: '/notification_list' },
    { label: 'チーム - 基本情報', path: '/basicinfo' },
    { label: 'プラン選択', path: '/plan_select' },
    { label: 'チーム - メンバー(管理者)', path: '/member_admin' },
    { label: 'チーム - メンバー(一般)', path: '/member_general' },
    { label: 'チーム - メンバー(削除)', path: '/member_delete' },
    { label: 'チーム - メンバー(招待)', path: '/member_invitation' },
    { label: 'アカウント設定', path: '/account_setting' },
    { label: 'SPIRIT リファクタリング共通コンポーネント', path: '/spirit' },
    { label: 'SPIRIT リファクタリング共通コンポーネント status=02', path: '/spirit?status=02' },
    { label: 'SPIRIT リファクタリング共通コンポーネント status=03', path: '/spirit?status=03' },
    { label: 'SPIRIT リファクタリング共通コンポーネント status=04', path: '/spirit?status=04' },
    { label: 'SPIRIT 募集フェーズ - 応募者タブ（Twitter）', path: '/spirit?status=02&tab=applicant&sns=twitter' },
    { label: 'SPIRIT 募集フェーズ - 応募者タブ（TikTok）', path: '/spirit?status=02&tab=applicant&sns=tiktok' },
    { label: 'SPIRIT 募集フェーズ - 応募者タブ（Instagram）', path: '/spirit?status=02&tab=applicant&sns=instagram' },
    { label: 'SPIRIT 募集フェーズ - 応募者タブ（YouTube）', path: '/spirit?status=02&tab=applicant&sns=youtube' },
    { label: 'PRST 詳細', path: '/PRST_project' },
    { label: 'JANE JOHN 詳細(募集内容作成(下書き))', path: '/jane_john' },
    { label: 'JANE JOHN 詳細(募集・選考)', path: '/jane_john?status=2' },
    { label: 'JANE JOHN 詳細(最終確認)', path: '/jane_john?status=3' },
    { label: 'プロジェクト管理一覧共通（SPIRIT）', path: '/project_management/list?type=SPIRIT' },
    { label: 'プロジェクト管理一覧共通（EMERALD POST）', path: '/project_management/list?type=EmeraldPost' },
    { label: 'プロジェクト管理一覧共通（PRST）', path: '/project_management/list?type=PRST' },
    { label: 'プロジェクト管理一覧共通（JANEJOHN）', path: '/project_management/list?type=JANEJOHN&size=300' },
    { label: 'プロジェクト管理一覧共通（その他）', path: '/project_management/list?type=OTHER' },
    { label: '請求管理', path: '/billing' },
    { label: '請求管理 サービス利用履歴なし', path: '/billing?nodata=1' },
    { label: '請求管理 未登録', path: '/billing?nodata=1&init=1' },
    { label: 'ログイン後画面(チーム所属済み)', path: '/team-select?joinTeam=true' },
    { label: 'ログイン後画面(チーム未所属)', path: '/team-select' },
    { label: 'SPIRIT案件 分析一覧 (データなし)', path: '/project-analysis/list?type=SPIRIT&nodata=true' },
    { label: 'SPIRIT案件 分析一覧 （データあり）', path: '/project-analysis/list?type=SPIRIT&rows=50&size=200' },
    {
      label: 'SPIRIT案件 分析一覧画像 （データあり）',
      path: '/project-analysis/list?type=SPIRIT&rows=50&size=200&posted_method=image',
    },
    { label: 'EMERALD POST分析一覧', path: '/project-analysis/list?type=EmeraldPost&rows=50&size=200' },
    {
      label: 'JANE JOHN分析一覧',
      path: '/project-analysis/list?type=JANEJOHN&rows=50&size=200',
    },
    { label: 'スケジュール カレンダー データなし', path: '/schedule/calendar?condition=empty' },
    { label: 'スケジュール カレンダー', path: '/schedule/calendar' },
    { label: 'スケジュール ガントチャート データなし', path: '/schedule/gantt-chart?condition=empty' },
    { label: 'スケジュール ガントチャート', path: '/schedule/gantt-chart' },
    { label: 'スケジュール ガントチャート（11月30日表示）', path: '/schedule/gantt-chart?year=2023&&month=11&day=30' },
    { label: 'スケジュール ガントチャート（12月1 日表示）', path: '/schedule/gantt-chart?year=2023&&month=12&day=1' },
    {
      label: 'プロジェクト管理一覧共通 データなし（SPIRIT）',
      path: '/project_management/list?type=SPIRIT&nodata=true',
    },
    {
      label: 'プロジェクト管理一覧共通 データなし（EMERALD POST）',
      path: '/project_management/list?type=EmeraldPost&nodata=true',
    },
    { label: 'プロジェクト管理一覧共通 データなし（PRST）', path: '/project_management/list?type=PRST&nodata=true' },
    {
      label: 'プロジェクト管理一覧共通 データなし（JANEJOHN）',
      path: '/project_management/list?type=JANEJOHN&nodata=true',
    },
    {
      label: 'チーム作成(修正版)',
      path: '/create_team',
    },
  ];

  return (
    <MainLayout
      sidebarProps={{
        firstSideBarselected: firstSidebarSelect.home,
        secondSideBarselected: '',
      }}
    >
      <div className="container mx-auto">
        <div>
          <span className="text-2xl font-bold leading-8">ホーム</span>
        </div>
        <div id="main-frame">
          <div id="project-schedule">
            <div>
              <div>案件スケジュール</div>
              <Button
                size="sm"
                className="h-9 w-[210px] font-bold"
                startIcon={<PlusIcon width={20} height={20} fill="white"></PlusIcon>}
                onClick={handleNewProject}
              >
                新しい案件を作成する
              </Button>
            </div>
            <div className="py-6">
              {/* 確認用 URLは環境変数へ変更 */}
              <a
                href="https://liddellinflufect1684404730.zendesk.com/auth/v2/login/signin?return_to=https%3A%2F%2Fliddellinflufect1684404730.zendesk.com%2F&theme=hc&locale=67&brand_id=7008150188943&auth_origin=7008150188943%2Cfalse%2Ctrue"
                target="_blank"
                rel="noreferrer"
                className="text-sm text-blue-600"
              >
                Zendeskサイトへのアクセスはこちら
              </a>
            </div>
          </div>
          <div>
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  <th scope="col">No</th>
                  <th scope="col">リンク先</th>
                </tr>
              </thead>
              <tbody>
                {linksList.map((link, index) => {
                  return (
                    <tr key={index} className="even:bg-gray-50">
                      <td className="text-center">{index + 1}</td>
                      <td className="px-3 py-4 text-sm text-blue-600">
                        <a href={link.path}>{link.label}</a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <CompleteModal
        open={openModal}
        setOpen={setOpenModal}
        modalText={modalMessage}
        buttonText="閉じる"
      ></CompleteModal>
    </MainLayout>
  );
}
